<app-wizard
  [steps]="steps"
  [current]="currentStep"
  [bodyContentTpl]="managerInfoForm"
  [nextDisabled]="this.formGroup?.invalid"
  (onClickNext)="save($event)"
>
</app-wizard>

<ng-template #managerInfoForm>
  <div *ngIf="showContent" class="manager-form flex justify-content-center flex-column mb-8" [formGroup]="formGroup">
    <div class="field">
      <label>Nombre del Responsable del Proyecto</label>
      <input
        id="leadName"
        pInputText
        [ngClass]="{ 'ng-invalid ng-dirty': form.leadName.errors && form.leadName.touched }"
        formControlName="leadName"
        placeholder="Nombre del Responsable del Proyecto"
        type="text"
        class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
      />
      <div *ngIf="form.leadName.errors">
        <p *ngIf="form.leadName.errors?.required && form.leadName.touched" class="text-danger">*Campo requerido</p>
      </div>
    </div>

    <div class="field">
      <label>Cargo del Responsable del Proyecto</label>
      <input
        id="leadJobtitle"
        pInputText
        [ngClass]="{ 'ng-invalid ng-dirty': form.leadJobtitle.errors && form.leadJobtitle.touched }"
        formControlName="leadJobtitle"
        placeholder="Cargo del Responsable del Proyecto"
        type="text"
        class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
      />
      <div *ngIf="form.leadJobtitle.errors">
        <p *ngIf="form.leadJobtitle.errors?.required && form.leadJobtitle.touched" class="text-danger">
          *Campo requerido
        </p>
      </div>
    </div>

    <div class="field">
      <app-input-file
        id="leadMemoFile"
        [label]="'Memoradum de Designación como Responsable Técnico del Proyecto'"
        [multiple]="false"
        [maxFiles]="1"
        [defaultFiles]="leadMemoFile"
        [accept]="'application/pdf, image/jpg, image/jpeg, image/png'"
        (onUploaded)="onUploaded($event)"
        (onRemove)="onRemoveFile($event)"
      >
      </app-input-file>

      <input id="leadMemoFileId" formControlName="leadMemoFileId" type="hidden" />
      <div *ngIf="form.leadMemoFileId.errors">
        <p *ngIf="form.leadMemoFileId.errors?.required && form.dirty" class="text-danger">*Campo requerido</p>
      </div>
    </div>

    <div class="field">
      <label>Nro.de Celular</label>
      <input
        id="leadCellPhone"
        pInputText
        [ngClass]="{ 'ng-invalid ng-dirty': form.leadCellPhone.errors && form.leadCellPhone.touched }"
        formControlName="leadCellPhone"
        placeholder="Nro.de Celular del Responsable del Proyecto"
        type="text"
        (keypress)="onKeyPressNumbers($event)"
        class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
      />
      <div *ngIf="form.leadCellPhone.errors">
        <p *ngIf="form.leadCellPhone.errors?.required && form.leadCellPhone.touched" class="text-danger">
          *Campo requerido
        </p>
        <p *ngIf="form.leadCellPhone.errors && form.leadCellPhone.touched" class="text-danger">
          *Debe ser un numero de celular valido
        </p>
      </div>
    </div>

    <div class="field">
      <label>Correo Electrónico</label>
      <input
        id="leadEmail"
        pInputText
        [ngClass]="{ 'ng-invalid ng-dirty': form.leadEmail.errors && form.leadEmail.touched }"
        formControlName="leadEmail"
        placeholder="Correo Electrónico del Responsable del Proyecto"
        type="text"
        class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
      />
      <div *ngIf="form.leadEmail.errors">
        <p *ngIf="form.leadEmail.errors?.required && form.leadEmail.touched" class="text-danger">*Campo requerido</p>

        <p *ngIf="form.leadEmail.errors?.email && form.contactEmail.touched" class="text-danger">
          Introduzcs una correo electrónico válido
        </p>
      </div>
    </div>
  </div>
</ng-template>
