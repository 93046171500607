<div>
  <p-breadcrumb [model]="breadcrumbs"></p-breadcrumb>
  <div class="card">
    <p-tabView>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <span>Valoración</span>
        </ng-template>

        <p-toolbar styleClass="mb-4 w-full clean-toolbar">
          <div class="flex justify-content-between flex-column align-items-center sm:flex-row w-full">
            <div class="p-input-icon-left mb-2 searcher">
              <i class="pi pi-search"></i>
              <input
                pInputText
                type="text"
                class="w-full"
                (input)="setFilter($event, 'filterGlobal', 'contains')"
                placeholder="Buscar..."
              />
            </div>
            <div class="my-2">
              <button
                appPermission
                [resource]="resource"
                [enableActions]="'canView'"
                pButton
                pRipple
                label="Actualizar"
                icon="pi pi-refresh"
                class="p-button-info mr-2"
                (click)="getAll()"
              ></button>
            </div>
          </div>
        </p-toolbar>

        <p-table
          #dtProjects
          [value]="data"
          [loading]="loading"
          [paginator]="true"
          [rows]="6"
          styleClass="p-datatable-gridlines"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="{first} al {last} de {totalRecords} items"
          [rowsPerPageOptions]="[6, 12, 24]"
          [globalFilterFields]="['name']"
        >
          <ng-template pTemplate="header">
            <tr>
              <th class="bg-primary">Proyecto</th>
              <th class="bg-primary">Entidad Solicitante</th>
              <th class="bg-primary">MAE</th>
              <th class="bg-primary"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-project>
            <tr>
                <td><div class="large-cell">{{ project.name }}</div></td>
              <td>
                <div class="description-cell">{{ project.iTCPRequest.entity.nombre }}</div>
              </td>
              <td>{{ project.iTCPRequest.seoName }}</td>
              <td class="text-center">
                <button
                  pButton
                  pRipple
                  label="Valorar"
                  appPermission
                  [resource]="resource"
                  [enableActions]="'isSuperAdmin'"
                  pTooltip="Valorar proyecto"
                  tooltipPosition="top"
                  type="button"
                  icon="pi pi-check-square"
                  class="p-button p-button-outlined"
                  (click)="retrieveProjectAssessment(project.id)"
                ></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
