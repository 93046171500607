import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from 'src/app/shared/services';
import {IProjectBudget} from "../models/interfaces/project-budget.interface";

@Injectable({
  providedIn: 'root'
})
export class ProjectBudgetService extends BaseService<IProjectBudget>{

    constructor(private readonly httpService: BaseHttpService) {
        super(httpService, 'project-budgets-items');
    }
}
