import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from '../../../../shared';
import { IProjectAssessment } from '../interfaces/project-assessment.interface';

@Injectable({
  providedIn: 'root',
})
export class ProjectAssessmentsService extends BaseService<IProjectAssessment> {
  constructor(private readonly httpService: BaseHttpService) {
    super(httpService, 'project-assessments');
  }
}
