<div class='grid'>
  <p-breadcrumb [model]='breadcrumbs'></p-breadcrumb>
  <div class='col-12'>
    <div class='card'>
      <p-toolbar styleClass='mb-4 w-full clean-toolbar'>
        <div class='flex justify-content-between flex-column align-items-center sm:flex-row w-full'>
          <div class='p-input-icon-left mb-2 searcher'>
            <i class='pi pi-search'></i>
            <input
              pInputText
              type='text'
              class='w-full'
              (input)="setFilter($event, 'filterGlobal', 'contains')"
              placeholder='Buscar...'
            />
          </div>
          <div class='my-2'>
            <button
              appPermission
              [resource]='resource'
              [enableActions]="'canView'"
              pButton
              pRipple
              label='Actualizar'
              icon='pi pi-refresh'
              class='p-button-info mr-2'
              (click)='getAll()'
            ></button>
          </div>
        </div>
      </p-toolbar>

      <p-table
        #dt
        [value]='projects'
        styleClass='p-datatable-gridlines'
        [loading]='loading'
        [resizableColumns]='true'
        [paginator]='true'
        [rows]='6'
        [showCurrentPageReport]='true'
        currentPageReportTemplate='{first} al {last} de {totalRecords} items'
        [rowsPerPageOptions]='[6, 12, 24]'
        responsiveLayout='scroll'
        [filterDelay]='0'
        [globalFilterFields]="['entityId', 'name', 'description']"
      >
        <ng-template pTemplate='header'>
          <tr>
            <th class='bg-primary text-center'>NRO</th>
            <th class='bg-primary text-center'>PROYECTO</th>
            <th class='bg-primary text-center'>ENTIDAD SOLICITANTE</th>
            <th class='bg-primary text-center'>CALIFICACIÓN</th>
            <th class='bg-primary text-center'>ESTADO</th>
            <th class='bg-primary text-center'>NOTIFICACIÓN</th>
            <th class='bg-primary text-center'>APROBAR/RECHAZAR</th>
          </tr>
        </ng-template>

        <ng-template pTemplate='body' let-item let-ri='rowIndex'>
          <tr>
            <td>{{ ri + 1 }}</td>
            <td>
              <div class='large-cell'>{{ item.name }}</div>
            </td>
            <td>{{ item.iTCPRequest.entity.nombre }}</td>
            <td class='text-center font-bold text-xl'>
              <div [class.text-danger]='item.score < 81'>{{ item.score }}</div>
            </td>
            <td class='text-center'>
              {{ item.score < SCORES.EDTP_APPROVE ? 'DESHABILITADO' : 'HABILITADO'}}<br />
              <p-badge *ngIf='approved(item.status)' value='APROBADO' severity='success'></p-badge>
              <p-badge *ngIf='rejected(item.status)' value='RECHAZADO' severity='danger'></p-badge>
            </td>
            <td>
              <button
                pButton
                pRipple
                label='Notificar'
                appPermission
                [resource]='resource'
                [enableActions]="'isSuperAdmin'"
                pTooltip='Notificar'
                tooltipPosition='top'
                type='button'
                icon='pi pi-check-square'
                class='p-button p-button-outlined'
                (click)='openDialogModal(item)'
              ></button>
            </td>
            <td class='text-center'>
              <button
                *ngIf='!wasSentToTracking(item.status)'
                pButton
                pRipple
                appPermission
                [resource]='resource'
                [enableActions]="'canUpdate'"
                pTooltip='Aprobar Proyecto'
                tooltipPosition='top'
                type='button'
                icon='pi pi-check'
                class='p-button p-button-success p-button-text p-button-rounded'
                (click)='approveRejectProject(item, true)'
              ></button>
              <button
                *ngIf='!wasSentToTracking(item.status)'
                pButton
                pRipple
                appPermission
                [resource]='resource'
                [enableActions]="'canUpdate'"
                pTooltip='Rechazar Proyecto'
                tooltipPosition='top'
                type='button'
                icon='pi pi-times'
                class='p-button p-button-danger p-button-text p-button-rounded'
                (click)='approveRejectProject(item, false)'
              ></button>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <div *ngIf='dialogVisible'>
        <p-dialog header='Notificar' [(visible)]='dialogVisible' [modal]='true' [style]="{  minHeight: '500px' }"
                  [draggable]='false' [resizable]='false'>
          <ng-template pTemplate='header'>
            <span class='text-xl font-bold'>Notificar</span>
          </ng-template>

          <div *ngIf='sending' class='px-8 py-4'>
            <label class='text-base'>Enviando.....</label>
            <p-progressBar mode='indeterminate' [style]="{ height: '6px' }"></p-progressBar>
          </div>

          <div class='w-full flex flex-wrap gap-4 justify-content-center align-items-center  px-6'>
            <p-editor
              [(ngModel)]='textNotification'
              [style]="{ height: '320px'}"
            >
            </p-editor>
          </div>
          <p class='font-italic text-sm'>
            <b>* "{{ '{{'}} contactName {{'}}' }}"</b>: No modificar este texto, es la clave donde se replazará el
            nombre.<br />
          </p>

          <div class='field lg:col-12 md:col-12 sm:col-12'>
            <app-input-file
              [label]="'Archivo de la Notificacion'"
              [multiple]='false'
              [maxFiles]='1'
              [accept]="'*'"
              (onUploaded)='onUploaded($event, selectedProject)'
            >
            </app-input-file>
          </div>
          <ng-template pTemplate='footer'>
            <button pButton type='button' label='Cerrar' class='p-button-secondary'
                    (click)='dialogVisible = false'></button>
            <button pButton type='button' label='Enviar Notificación' class='p-button-success'
                    (click)='notifyProject(selectedProject)'
                    [disabled]='sending || getProjectForm(selectedProject)?.invalid'></button>
          </ng-template>

        </p-dialog>
      </div>
    </div>
  </div>
</div>
