import {IAttachment} from "../../../shared";

export interface IProjectStartOrder {
    id?: number | string;
    projectId: number;
    signDate?: Date;
    startOrderDate?: Date;
    firstPaymentDate?: Date;
    secondPaymentDate?: Date;
    thirdPaymentDate?: Date;
    signPrice: number;
    firstPayment?: number;
    secondPayment?: number;
    thirdPayment?: number;
    verifiables?: any[];// returned by query to populate in files
    signFilesIds?: number[];
    startOrderFilesIds?: number[];
    firstPaymentFilesIds?: number[];
    secondPaymentFilesIds?: number[];
    thirdPaymentFilesIds?: number[];
    signFiles?: IAttachment[];
    startOrderFiles?: IAttachment[];
    firstPaymentFiles?: IAttachment[];
    secondPaymentFiles?: IAttachment[];
    thirdPaymentFiles?: IAttachment[];
}
