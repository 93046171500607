import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from 'src/app/shared/services';
import {
    IProjectActivityBudgetMonth,
    IProjectStartOrder,
} from '../models';
import {lastValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ProjectStartOrderService extends BaseService<IProjectStartOrder>{

    constructor(private readonly httpService: BaseHttpService) {
        super(httpService, 'project-process-orders');
    }

    async calculateDateActivities(projectId: number): Promise<boolean> {
        return lastValueFrom(this._baseHttpService.post(`${this.namespace}/calc/${projectId}`, {}));
    }
}
