<div class='grid align-items-start p-4'>
    <div *ngIf='!showContent'  class='flex flex-wrap justify-content-start align-content-start col-12 md:col-4'>
        <div class='col-6'><p-skeleton  width='100%' height='36px'></p-skeleton></div>
        <div class='col-6'><p-skeleton  width='100%' height='36px'></p-skeleton></div>
        <div class='col-6'><p-skeleton  width='100%' height='36px'></p-skeleton></div>
        <div class='col-6'><p-skeleton  width='100%' height='36px'></p-skeleton></div>
        <div class='col-6'><p-skeleton  width='100%' height='36px'></p-skeleton></div>
        <div class='col-12'><p-skeleton  width='100%' height='200px'></p-skeleton></div>
    </div>

    <div *ngIf='showContent' class='grid col-12 md:col-4 mt-4'>
        <div class='col-6'>
            <label class="font-semibold">Departamento</label>
            <p-autoComplete
                [suggestions]="cities"
                [dropdown]='true'
                (completeMethod)="filterCities($event)"
                (onSelect)='onSelectGeo($event, "CITY")'
                field='dep'
                placeholder='Departamento'
                [minLength]='3'>
            </p-autoComplete>
        </div>

        <div class='col-6'>
            <label  class="font-semibold">Provincia</label>
            <p-autoComplete
                [suggestions]="provinces"
                [dropdown]='true'
                (completeMethod)="filterProvinces($event)"
                (onSelect)='onSelectGeo($event, "PROVINCE")'
                field='prov'
                placeholder='Provincia'
                [minLength]='3'>
                <ng-template let-province pTemplate="item">
                    <div class="province-item">
                        <div>{{province.prov}}</div>
                        <div class='text-xs text-bluegray-300'>
                            {{province.dep}}
                        </div>
                    </div>
                </ng-template>

            </p-autoComplete>
        </div>

        <div class='col-6'>
            <label class="font-semibold">Municipio</label>
            <p-autoComplete
                [suggestions]="towns"
                [dropdown]='true'
                (completeMethod)="filterTowns($event)"
                (onSelect)='onSelectGeo($event, "TOWN")'
                field='mun'
                placeholder='Municipio'
                [minLength]='3'>
                <ng-template let-town pTemplate="item">
                    <div class="town-item">
                        <div>{{town.mun}}</div>
                        <div class='text-xs text-bluegray-300'>
                            {{town.dep}} - {{town.prov}}
                        </div>
                    </div>
                </ng-template>

            </p-autoComplete>
        </div>

        <div class='col-6'>
            <label class="font-semibold">Barrio</label>
            <p-autoComplete
                [suggestions]="neighborhoods"
                [dropdown]='true'
                (completeMethod)="filterNeighborhoods($event)"
                (onSelect)='onSelectGeo($event, "NEIGHBORHOOD")'
                field='nom_bar'
                placeholder='Barrio'
                [minLength]='3'>
                <ng-template let-neighborhood pTemplate="item">
                    <div class="neighborhood-item">
                        <div>{{neighborhood.nom_bar}}</div>
                        <div class='text-xs text-bluegray-300'>
                            {{neighborhood.dep}} - {{neighborhood.prov}} - {{neighborhood.mun}}
                        </div>
                    </div>
                </ng-template>

            </p-autoComplete>
        </div>

        <div class='col-6'>
            <label class="font-semibold">Calle</label>
            <p-autoComplete
                [suggestions]="streets"
                [dropdown]='true'
                (completeMethod)="filterStreets($event)"
                (onSelect)='onSelectGeo($event, "STREET")'
                field='nom_ca'
                placeholder='Calle'
                [minLength]='3'>
                <ng-template let-street pTemplate="item">
                    <div class="street-item">
                        <div>{{street.nom_ca}}</div>
                        <div class='text-xs text-bluegray-300'>
                            {{street.dep}} - {{street.prov}} - {{street.mun}} - {{street.nom_bar}}
                        </div>
                    </div>
                </ng-template>

            </p-autoComplete>
        </div>
        <div class='col-12'>
            <label class="font-semibold">Importar Capa</label>
            <span class='ml-2 text-sm'>(El Comprimido .zip debe contener los archivos shp, shx y dbf)</span>

            <div class="mt-4">
                <p-fileUpload chooseLabel="Seleccione un archivo" accept="application/zip" (onSelect)="onUpload($event)" [showUploadButton]="false" [showCancelButton]="false">
                    <ng-template let-file pTemplate="file">
                        <div>{{file.name}}</div>
                    </ng-template>
                </p-fileUpload>
            </div>
        </div>

        <div class="col-12 grid gap-4">
            <div class="col-12">
                <label class="font-semibold">UTM</label>
                <div class='flex flex-wrap justify-content-start gap-2'>
                    <div class='flex gap-1 align-items-center'>
                        <label class='text-sm'>x</label>
                        <input pInputText type='number'
                               placeholder='Coordenada x'
                               [(ngModel)]="coordinatesUTM.x"
                               [ngModelOptions]="{ standalone: true }"
                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                               required />
                    </div>
                    <div class='flex gap-1 align-items-center'>
                        <label class='text-sm'>y</label>
                        <input pInputText type='number'
                               placeholder='Coordenada y'
                               [(ngModel)]="coordinatesUTM.y"
                               [ngModelOptions]="{ standalone: true }"
                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                               required />
                    </div>
                    <div class='flex gap-1 align-items-center'>
                        <label class='text-sm'>zona</label>
                        <input pInputText type='number'
                               placeholder='Zona'
                               [(ngModel)]="coordinatesUTM.zone"
                               [ngModelOptions]="{ standalone: true }"
                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                               required />
                    </div>
                    <div class='flex gap-1 align-items-center'>
                        <p-radioButton name="pizza" value="S" [(ngModel)]="coordinatesUTM.hemisphere" [ngModelOptions]="{ standalone: true }" inputId="h1"></p-radioButton>
                        <label for="h1" class="mx-2">S</label>
                        <p-radioButton name="pizza" value="N" [(ngModel)]="coordinatesUTM.hemisphere" [ngModelOptions]="{ standalone: true }" inputId="h2"></p-radioButton>
                        <label for="h2" class="mx-2">N</label>
                    </div>
                    <div class='flex gap-1 align-items-center'>
                        <button
                            pButton
                            (click)="findUTM($event)"
                        >
                            Buscar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class='col-12 md:col-8 relative'>
        <div class='modal-container'>
            <div class='map-container'>
                <div class='map-frame'>
                    <div id='map'></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class='p-dialog-footer'>
    <button pButton type='button' label='Cerrar' class='p-button-secondary' (click)='closeModal()'></button>
    <button pButton type='button' label='Guardar Coordenadas' class='p-button-success' (click)='saveGeoLocation()'></button>
</div>

