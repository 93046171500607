<app-wizard [steps]='steps' [current]='currentStep' [bodyContentTpl]='projectForm'
    [nextDisabled]='this.formGroup?.invalid' (onClickNext)='save($event)' [layout]='true'>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column' [formGroup]='formGroup'>
        <div class='grid'>
            <div class="field col-12 text-2xl text-primary uppercase">
                {{currentStep?.text}}
            </div>

            <div class="field col-12">

                <button
                    [disabled]='isSubmitted'
                    class='p-button p-button-success'
                    pButton pTooltip="Agregar" tooltipPosition="top"
                    (click)='onNew()'
                >
                    <i class="pi pi-plus"></i>
                </button>

                <p-table [value]="c1Items" dataKey="id" editMode="row" [tableStyle]="{'width': '100%'}" #dt>
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="bg-primary" style="width:25%">Materiales</th>
                            <th class="bg-primary" style="width:20%">Requerido</th>
                            <th class="bg-primary" style="width:20%">Cantidad Año 1</th>
                            <th *ngIf="isValidYear(2)" class="bg-primary" style="width:20%">Cantidad Año 2</th>
                            <th *ngIf="isValidYear(3)" class="bg-primary" style="width:20%">Cantidad Año 3</th>
                            <th class="bg-primary" style="width:10%"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-obj let-editing="editing" let-ri="rowIndex">
                        <tr [pEditableRow]="obj">
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-dropdown
                                            [options]="components"
                                            [(ngModel)]="obj.description" [ngModelOptions]="{standalone: true}"
                                            placeholder="Seleccione"
                                            [group]="true"
                                            [style]="{'width':'100%'}"
                                            scrollHeight="200px"
                                            [filter]="true"
                                            filterBy="value"
                                            optionLabel="value"
                                            optionValue="value"
                                            (onChange)='onSelectComponent($event, obj)'
                                        >
                                            <ng-template let-group pTemplate="group">
                                                <div class="flex align-items-center">
                                                    <span>{{group.label}}</span>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{obj.description}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText
                                               required
                                               min='0'
                                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                                               type="number" [(ngModel)]="obj.quantityRequired" [ngModelOptions]="{standalone: true}">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{obj.quantityRequired}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText
                                               required
                                               min='0'
                                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                                            type="number" [(ngModel)]="obj.quantityYear1"
                                            [ngModelOptions]="{standalone: true}">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{obj.quantityYear1}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>

                            <td *ngIf="isValidYear(2)">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText
                                               required
                                               min='0'
                                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                                               type="number" [(ngModel)]="obj.quantityYear2" [ngModelOptions]="{standalone: true}">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{obj.quantityYear2}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>

                            <td *ngIf="isValidYear(3)">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText
                                               required
                                               min='0'
                                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                                               type="number" [(ngModel)]="obj.quantityYear3" [ngModelOptions]="{standalone: true}">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{obj.quantityYear3}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <div *ngIf="!isSubmitted" class="flex align-items-center justify-content-center gap-2">
                                    <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow
                                        icon="pi pi-pencil" (click)="onRowEditInit(obj)"
                                        class="p-button-rounded p-button-text"></button>
                                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow
                                        icon="pi pi-check" (click)="onRowEditSave(obj)"
                                        class="p-button-rounded p-button-text p-button-success mr-2"></button>
                                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow
                                        icon="pi pi-times" (click)="onRowEditCancel(obj, ri)"
                                        class="p-button-rounded p-button-text p-button-danger"></button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</ng-template>
