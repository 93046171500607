export * from './formulation.component';
export * from './goals/goals.component';
export * from './line';
export * from './component1';
export * from './component2';
export * from './component3';
export * from './component4';
export * from './done/done.component';
export * from './budget/budget.component';
export * from './budget-comp/budget-comp.component';
export * from './budget-vipfe/budget-vipfe.component';
export * from './costs/costs.component';
export * from './structure/structure.component';
export * from './budget/budget-component/budget-component.component';
