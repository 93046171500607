import { Component, OnDestroy, OnInit } from '@angular/core';
import {ITCPRequest} from "../../../models";
import { ActivatedRoute } from '@angular/router';
import {ITCPRequestService} from "../../../../itcp";
import { ToasterService } from '../../../../shared/services/toaster.service';

@Component({
    selector: 'app-done',
    templateUrl: './done.component.html',
    styleUrls: ['./done.component.scss']
})
export class DoneComponent extends ITCPRequest implements OnInit, OnDestroy {

    private step: number = 5;
    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly iTCPRequestService: ITCPRequestService,
        private tService: ToasterService
    ) {
        super(activatedRoute, iTCPRequestService, tService);
    }

    ngOnInit(): void {
        this.readParams();
        this.setActive(this.step)
    }
    ngOnDestroy() {
        this.cleanParams();
    }

}
