<div class="card border-0">
    <p-skeleton *ngIf="!showForm" width="100%" height="150px"></p-skeleton>
    <div *ngIf="showForm" >
        <form class="form-signin" [formGroup]="modalForm">
            <div class="grid">
                <div class="field lg:col-12 md:col-12 sm:col-12">
                    <label>Proyecto</label>
                    <p-dropdown
                        [options]="projects"
                        [style]="{'width':'100%'}"
                        scrollHeight="300px"
                        [filter]="true"
                        filterBy="primerNombre,segundoNombre,apellidoPaterno,apellidoMaterno"
                        [resetFilterOnHide]="true"
                        formControlName="projectId"
                        optionValue="id"
                        [required]="true"
                        (onChange)="filterActs($event)"
                    >
                        <ng-template let-item pTemplate="selectedItem">
                            <div class="large-content">{{ item.name }}</div>
                            <div class="text-xs text-gray-600">{{item.iTCPRequest.entity.nombre}}</div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div class="item-drop1">
                                <div class="large-content">{{ item.name }}</div>
                                <div class="text-xs text-gray-600">{{item.iTCPRequest.entity.nombre}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>

                <div class="field lg:col-12 md:col-12 sm:col-12">
                    <label>Comunidad</label>
                    <p-dropdown
                        [options]="acts"
                        [style]="{'width':'100%'}"
                        scrollHeight="300px"
                        [filter]="true"
                        filterBy="primerNombre,segundoNombre,apellidoPaterno,apellidoMaterno"
                        [resetFilterOnHide]="true"
                        optionValue="id"
                        optionLabel="community"
                        formControlName="actId"
                        [disabled] = '!enableCommunity'
                    >
                    </p-dropdown>

                    <div *ngIf="form.actId.errors">
                        <p *ngIf="form.actId.errors?.required && form.actId.touched" class="text-danger">
                            *Campo requerido
                        </p>
                    </div>
                </div>

                <div class="field lg:col-12 md:col-12 sm:col-12">
                    <label>Técnico Verificador</label>
                    <p-dropdown
                        [options]="users"
                        [style]="{'width':'100%'}"
                        scrollHeight="300px"
                        [filter]="true"
                        filterBy="primerNombre,segundoNombre,apellidoPaterno,apellidoMaterno"
                        [resetFilterOnHide]="true"
                        formControlName="userId"
                        optionValue="id"
                    >
                        <ng-template let-item pTemplate="selectedItem">
                            {{getFullName(item)}}
                            <div class="text-xs text-gray-600">{{item.usuarioRoles[0]?.rol?.descripcionRol}}</div>
                        </ng-template>
                        <ng-template let-user pTemplate="item">
                            <div class="item-drop1">
                                <span class="item-value1">{{getFullName(user)}}</span>
                                <div class="text-xs text-gray-600">{{user.usuarioRoles[0]?.rol?.descripcionRol}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>

                <div class="field col-12 md:col-6">
                    <label>Fecha de Visita</label>
                    <p-calendar
                        placeholder="Fecha de Visita"
                        dateFormat="yy-mm-dd"
                        [style]="{ width: '100%' }"
                        [showIcon]="true"
                        [yearNavigator]="true"
                        [monthNavigator]="true"
                        appendTo="body"
                        [readonlyInput]="true"
                        dataType="string"
                        [minDate]="minDate"
                        [ngClass]="{ 'ng-invalid ng-dirty': form.visitDate.errors && form.visitDate.dirty }"
                        formControlName="visitDate"
                    ></p-calendar>
                    <div *ngIf="form.visitDate.errors">
                        <p *ngIf="form.visitDate.errors.required && form.visitDate.touched" class="text-danger">*Campo requerido</p>
                    </div>
                </div>

                <div class="field lg:col-12 md:col-12 sm:col-12">
                    <label>Descripción</label>
                    <textarea
                        id="description"
                        rows="6"
                        pInputText
                        formControlName="description"
                        [ngClass]="{ 'ng-invalid ng-dirty': form.description.errors && form.description.touched }"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                    >
        </textarea>
                    <div *ngIf="form.description.errors">
                        <p *ngIf="form.description.errors.required && form.description.touched" class="text-danger">
                            *Campo requerido
                        </p>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="p-dialog-footer">
    <button pButton type="button" label="Cancelar" class="p-button-secondary" (click)="closeModal()"></button>
    <button pButton type="button" label="Guardar" (click)="save()" [disabled]="this.modalForm?.invalid"></button>
</div>
