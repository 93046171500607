import { ProjectService } from '../../itcp';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from '../../shared/services/toaster.service';
import {CryptoService, SettingsService} from '../../shared';
import {ProjectMonitoringModel} from "./project-monitoring.model";

export abstract class ProjectEvaluationModel extends ProjectMonitoringModel{

    protected constructor(route: ActivatedRoute, service: ProjectService, tService: ToasterService, setService: SettingsService, crypto: CryptoService) {
        super(route, service, tService, setService, crypto);
    }

    override setSteps() {

        this.steps = [
            {
                step: 1,
                text: 'Evaluación del Reporte Físico',
                icon: 'pi pi-sliders-v',
                path: `/tracking/evaluation/${this.params['id']}/physical`,
            },
            {
                step: 2,
                text: 'Evaluación del Reporte Financiero',
                icon: 'pi pi-dollar',
                path: `/tracking/evaluation/${this.params['id']}/financial`,
            }
        ]
    }
}
