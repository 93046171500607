import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IProject, ITCPRequestService, ProjectService } from '../../../itcp';
import { Constants } from '../../../shared/constants';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CartographyService, ICity, ProjectFlow, ProjectStagesEnum } from '../../../shared';
import { BudgetSummaryHelper, ProjectBudgetService } from '../../../edtp';
import { IProjectBudget } from '../../../edtp/models/interfaces/project-budget.interface';
import { PdfBuilder, PDFOrientation } from '../../../shared/lib/pdf-builder/pdf-builder';
import { substrToCharsLength } from '../../utils/stringUtils';

const ProjectStatusesMapped: any = {
    APPROVED: 'Aprobado', //ITCP, EDTP user decides if project approves
    REJECTED: 'Rechazado' //ITCP, EDTP user decides if project is rejected
};

@Component({
    selector: 'app-edtp',
    templateUrl: './edtp.component.html',
    styleUrls: ['./edtp.component.scss']
})
export class EdtpComponent implements OnInit {
    loading = false;
    showData = false;
    totalApproved: number = 0;
    totalRejected: number = 0;
    flattedDataPerTown: any[] = [];
    filteredFlattedDataPerTown: any[] = [];
    flattedDataByComponent: any[] = [];
    filteredFlattedDataByComponent: any[] = [];
    totalOwnerCost: number = 0;
    totalEntityCost: number = 0;
    totalOwnerEntityCost: number = 0;
    stackedOptions: any = {};
    financialChartData: any = {};
    projectEfficiencyChartData: any = {};
    projectBudgetChartData: any = {};
    private _projects: IProject[] = [];
    private _projectBudgets: IProjectBudget[] = [];
    filteredProjectBudgets: any[] = [];
    filteredProjectBudgetsVIPFEE: any[] = [];
    cities: ICity[] = [];
    towns: any[] = [];
    projectNames: any[] = [];
    componentNames: any[] = [];
    projectStatuses: any = { ...ProjectStatusesMapped };
    private onDestroy$ = new Subject();
    projectForm!: FormGroup;
    projectCostForm!: FormGroup;
    projectBudgetsForm!: FormGroup;
    breadcrumbs: MenuItem[] = [{ label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home'] }, { label: ' Reportes' }];
    @ViewChild('dtTable') table!: Table;
    @ViewChild('dtSpecific') specificTable!: Table;
    @ViewChild('dtCostEfficiencyTable') dtCostEfficiencyTable!: Table;
    @ViewChild('dtProjectBudgetsTable') dtProjectBudgetsTable!: Table;
    @ViewChild('dtProjectBudgetsVIPFEETable') dtProjectBudgetsVIPFEETable!: Table;
    @ViewChild('dtBudget') budgetTable!: Table;
    @ViewChild('chartPDFRef') chartPDFRef!: ElementRef;
    @ViewChild('financialChartDataRef') financialChartDataRef!: ElementRef;
    @ViewChild('specificChartRef') specificChartRef!: ElementRef;
    @ViewChild('costEfficiencyChartRef') costEfficiencyChartRef!: ElementRef;
    @ViewChild('projectBudgetChartRef') projectBudgetChartRef!: ElementRef;
    cityDropdownSettings: IDropdownSettings = {};
    projectCostsDropdownSettings: IDropdownSettings = {};
    barChartOptions: any = {};
    approvedRejectedChartData: any = {};
    generatingPDF = false;

    constructor(
        private fb: FormBuilder,
        private readonly itcpRequestService: ITCPRequestService,
        private readonly service: ProjectService,
        private readonly projectBudgetsService: ProjectBudgetService,
        private readonly cartographyService: CartographyService
    ) {
    }

    get projectBudgets(): IProjectBudget[] {
        return this._projectBudgets;
    }

    set projectBudgets(value: IProjectBudget[]) {
        this._projectBudgets = value;
        BudgetSummaryHelper.setBudgetAmounts(this.projectBudgets);
        this.filteredProjectBudgets = this.projectBudgets;
        this.setBudgetChartData();

        this.addVipfeeData(this._projectBudgets);
    }

    get projects(): IProject[] {
        return this._projects;
    }

    addVipfeeData(value: any[]) {
        const results = [...value];
        const componentTypes = ['COMPONENT1', 'COMPONENT2', 'COMPONENT3', 'COMPONENT4', 'COMPONENT5'];
        const projectIds = [...new Set(value.map((v) => v.projectId))];

        for (const projectId of projectIds) {
            for (const componentType of componentTypes) {
                const componentTotal: any = {
                    projectId,
                    type: '',
                    componentType,
                    totals: { owner: 0, entity: 0, entityInKind: 0, total: 0 }
                };
                const relatedBudgets = value.filter((v) => v.projectId === projectId && v.componentType === componentType);
                for (const budget of relatedBudgets) {
                    componentTotal.totals.owner += (budget.totals?.owner || 0);
                    componentTotal.totals.entity += (budget.totals?.entity || 0);
                    componentTotal.totals.entityInKind += (budget.totals?.entityInKind || 0);
                    componentTotal.totals.total += (budget.totals?.total || 0);
                    componentTotal.project = { name: budget?.project?.name };
                }

                results.push(componentTotal);
            }
        }

        results.sort((r1, r2) => (r1.projectId + r1.componentType).localeCompare((r2.projectId + r2.componentType)));

        this.filteredProjectBudgetsVIPFEE = results;
    }

    set projects(value: IProject[]) {
        this.showData = false;
        this.flattedDataPerTown = [];
        this.filteredFlattedDataPerTown = [];
        this.flattedDataByComponent = [];
        this.totalOwnerCost = 0;
        this.totalEntityCost = 0;
        this.totalOwnerEntityCost = 0;
        this.totalApproved = 0;
        this.totalRejected = 0;
        const projectNames: Set<string> = new Set();
        const componentNames: Set<string> = new Set();
        this._projects = value.map((project) => {
            const {
                iTCPRequest: {
                    entity: { city, town, province }
                },
                beneficiaries = [],
                status,
                projectCostEfficiency = []
            } = project as any;

            const menBeneficiary = beneficiaries.reduce((a: any, b: any) => {
                return a + b.men;
            }, 0);
            const womenBeneficiary = beneficiaries.reduce((a: any, b: any) => {
                return a + b.women;
            }, 0);
            const totalBeneficiaries = menBeneficiary + womenBeneficiary;

            if (status === Constants.PROJECT_STATUSES.REJECTED) this.totalRejected++;
            else this.totalApproved++;

            const parsedTownData = {
                cityName: city?.dep,
                provinceName: province?.prov,
                townName: town?.mun,
                ownerAmount: project.totalOwnerInversionAmount,
                entityAmount: project.totalEntityInversionAmount,
                totalAmount: project.totalInversionAmount,
                totalTrainedMen: project.totalTrainedMen,
                totalTrainedWomen: project.totalTrainedWomen,
                totalAssistedMen: project.totalAssistedMen,
                totalAssistedWomen: project.totalAssistedWomen,
                totalBenefitedMen: project.totalBenefitedMen,
                totalBenefitedWomen: project.totalBenefitedWomen
            };

            for (const costEfficiency of projectCostEfficiency) {
                const parsedComponentObject = {
                    projectName: project.name,
                    ...costEfficiency
                };

                componentNames.add(costEfficiency.componentType);
                this.flattedDataByComponent.push(parsedComponentObject);
            }

            projectNames.add(project?.name || '');

            this.totalEntityCost += project?.totalEntityInversionAmount || 0;
            this.totalOwnerCost += project.totalOwnerInversionAmount || 0;
            this.totalOwnerEntityCost += project.totalInversionAmount || 0;

            this.flattedDataPerTown.push(parsedTownData);

            return {
                ...project,
                status: status === Constants.PROJECT_STATUSES.REJECTED ? Constants.PROJECT_STATUSES.REJECTED : Constants.PROJECT_STATUSES.APPROVED,
                menBeneficiary,
                womenBeneficiary,
                totalBeneficiaries
            };
        });

        this.filteredFlattedDataPerTown = this.flattedDataPerTown;
        this.filteredFlattedDataByComponent = this.flattedDataByComponent;
        this.projectNames = Array.from(projectNames)
            .sort()
            .map((p) => ({ name: p }));
        this.componentNames = Array.from(componentNames)
            .sort()
            .map((c) => ({ name: c }));
        this.setupDataPerTown();
        this.setupDataPerComponent();
        this.setChartData();
        this.setEfficiencyChartData();
        this.showData = true;
    }

    setupDataPerTown() {
        this.flattedDataPerTown.sort((a: any, b: any) => a.cityName.localeCompare(b.cityName));
        const townNames = this.flattedDataPerTown.map((town: any) => town.townName);
        this.towns = [...new Set(townNames)]
            .map((t) => ({ name: t }))
            .sort((a: any, b: any) => a.name.localeCompare(b.name));
    }

    setupDataPerComponent() {
        this.flattedDataByComponent.sort((a, b) => a.projectName.localeCompare(b.projectName));
    }

    setChartData() {
        this.approvedRejectedChartData = {
            labels: ['Aprobado', 'Deshabilitado'],
            datasets: [
                {
                    label: 'Reporte EDTP Aprobados - Deshabilitados',
                    data: [this.totalApproved, this.totalRejected],
                    backgroundColor: ['rgb(52,168,71)', 'rgb(1,44,1)'],
                    hoverOffset: 4
                }
            ]
        };
        const ownerAmounts: any[] = [];
        const entityAmounts: any[] = [];
        const labels: any[] = [];
        this.towns.forEach(({ name: town }) => {
            const relevantData = this.filteredFlattedDataPerTown.filter((d) => d.townName === town);
            const filteredTownNames = this.filteredFlattedDataPerTown.map((f) => f.townName);
            let ownerAmount = 0;
            let entityAmount = 0;
            relevantData.forEach((data) => {
                ownerAmount = ownerAmount + (data.ownerAmount || 0);
                entityAmount = entityAmount + (data.entityAmount || 0);
            });
            if (filteredTownNames.includes(town)) {
                labels.push(town);
                ownerAmounts.push(ownerAmount);
                entityAmounts.push(entityAmount);
            }
        });

        this.financialChartData = {
            labels: labels,
            datasets: [
                {
                    type: 'bar',
                    label: 'Presupuesto FONABOSQUE',
                    backgroundColor: '#42A5F5',
                    data: ownerAmounts
                },
                {
                    type: 'bar',
                    label: 'Presupuesto ENTIDAD',
                    backgroundColor: '#09144f',
                    data: entityAmounts
                }
            ]
        };
    }

    ngOnInit(): void {
        this.projectForm = this.fb.group({
            cityIds: [[], Validators.required]
        });
        this.projectCostForm = this.fb.group({
            projectNames: [[]],
            componentNames: [[]]
        });
        this.projectBudgetsForm = this.fb.group({
            projectNames: [[]],
            componentNames: [[]]
        });
        this.cartographyService
            .getCities()
            .then((resp) => {
                this.cities = [...resp];
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => (this.loading = false));

        this.cityDropdownSettings = {
            singleSelection: false,
            idField: 'gid',
            textField: 'dep',
            selectAllText: 'Todos',
            unSelectAllText: 'Ninguno',
            itemsShowLimit: 4,
            allowSearchFilter: true
        };
        this.projectCostsDropdownSettings = {
            singleSelection: false,
            idField: 'name',
            textField: 'name',
            selectAllText: 'Todos',
            unSelectAllText: 'Ninguno',
            itemsShowLimit: 4,
            allowSearchFilter: true
        };

        this.barChartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    ticks: {
                        maxRotation: 90,
                        minRotation: 0,
                        autoSkip: true,
                        maxTicksLimit: 8
                    }
                },
                y: {
                    ticks: {
                        beginAtZero: true
                    }
                }
            },
            plugins: {
                legend: {
                    display: false
                }
            }
        };
    }

    get form() {
        return this.projectForm.controls || {};
    }

    getAll(): void {
        this.loading = true;
        const postData: any = this.projectForm.value;
        let { cityIds = [] } = postData;
        cityIds = cityIds.map((a: any) => parseInt(a.gid));
        this.service
            .getAll({
                params: {
                    stage: ['EDTP', 'TRACKING'],
                    status: [...ProjectFlow.edtpStatusesForReport()],
                    cityIds,
                    projectBudgets: true,
                    withTrainedDetails: true,
                    withCostEfficiency: true
                }
            })
            .then((response) => {
                this.projects = response;
                const projectIds = response.map((p) => parseInt(String(p.id) || '0'));
                return projectIds.length ? this.projectBudgetsService.getAll({ params: { projectId: projectIds } }) : Promise.resolve([]);
            })
            .then((projectBudgets) => {
                this.projectBudgets = projectBudgets;
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => (this.loading = false));
    }

    reload() {
        this.getAll();
    }

    filterByProjectAndComponentNames() {
        const data: any = this.projectCostForm.value;
        const { projectNames, componentNames } = data;
        if (!projectNames.length && !componentNames.length) {
            this.filteredFlattedDataByComponent = this.flattedDataByComponent;
            return;
        }

        const projects = projectNames.map((p: any) => p.name);
        const components = componentNames.map((c: any) => c.name);
        this.filteredFlattedDataByComponent = this.flattedDataByComponent.filter((d) => {
            let hasValidProjectName = true;
            let hasValidComponentName = true;

            if (projects.length) hasValidProjectName = projects.includes(d.projectName);
            if (components.length) hasValidComponentName = components.includes(d.componentType);

            return hasValidComponentName && hasValidProjectName;
        });

        this.setEfficiencyChartData();
    }

    filterBudgetsByProjectNameAndComponentType() {
        const data: any = this.projectBudgetsForm.value;
        const { projectNames, componentNames } = data;
        if (!projectNames.length && !componentNames.length) {
            this.filteredProjectBudgets = this.projectBudgets;
            return;
        }

        const projects = projectNames.map((p: any) => p.name);
        const components = componentNames.map((c: any) => c.name);
        this.filteredProjectBudgets = this.projectBudgets.filter((b: any) => {
            let hasValidProjectName = true;
            let hasValidComponentName = true;

            if (projects.length) hasValidProjectName = projects.includes(b.project?.name);
            if (components.length) hasValidComponentName = components.includes(b.componentType);

            return hasValidComponentName && hasValidProjectName;
        });

        this.setBudgetChartData();
    }

    setEfficiencyChartData() {
        const labels: any[] = [];
        const values: number[] = [];
        this.filteredFlattedDataByComponent.forEach((d) => {
            labels.push([substrToCharsLength(d.projectName), d.componentType]);
            values.push(d.cost / d.quantity);
        });

        this.projectEfficiencyChartData = {
            labels,
            datasets: [
                {
                    type: 'bar',
                    label: 'Eficiencia',
                    backgroundColor: '#f5f242',
                    data: values
                }
            ]
        };
    }

    setBudgetChartData() {
        const labels: any[] = [];
        const fonabosque: number[] = [];
        const entity: number[] = [];
        const entityInKind: number[] = [];
        this.filteredProjectBudgets.forEach((d) => {
            labels.push([substrToCharsLength(d.project.name), d.componentType]);
            fonabosque.push(d.totals.owner);
            entity.push(d.totals.entity);
            entityInKind.push(d.totals.entityInKind);
        });

        this.projectBudgetChartData = {
            labels,
            datasets: [
                {
                    type: 'bar',
                    label: 'Fonabosque',
                    backgroundColor: '#0e4819',
                    data: fonabosque
                },
                {
                    type: 'bar',
                    label: 'Entidad',
                    backgroundColor: '#f5f242',
                    data: entity
                },
                {
                    type: 'bar',
                    label: 'Entidad en Especie',
                    backgroundColor: '#48c0ab',
                    data: entityInKind
                }
            ]
        };
    }

    generateFinancialPDF() {
        this.generatingPDF = true;

        setTimeout(async () => {
            const pdf = new PdfBuilder('EDTP APROBADOS', 'EDTP');

            // Main table
            const tableElement = this.table.tableViewChild.nativeElement;
            await pdf.addTable(tableElement, 'Listado de Municipios con EDTP Aprobado');

            // Pie Chart
            const chartElement = this.chartPDFRef.nativeElement;
            await pdf.addImage(chartElement, 'El cuadro y gráfico muestra la lista de municipios que se encuentran con el EDTP aprobado');

            // Specific Table Chart
            const specificElement = this.specificTable.tableViewChild.nativeElement;
            await pdf.addTable(specificElement, 'Listado de Municipio con EDTP aprobados total, por provincia y departamento con costo total del Proyecto, monto cofinanciado por Fonabosque y monto del GAM en Bs');

            // Last chart
            const financialElement = this.financialChartDataRef.nativeElement;
            await pdf.addImage(financialElement, 'Grafico del Listado de Municipios con los presupuestos correspondientes');

            pdf.generate('fonabosque_reporte_presupuesto');
            this.generatingPDF = false;
        }, 10);
    }

    generateCostEfficiencyPDF() {
        this.generatingPDF = true;

        setTimeout(async () => {
            const pdf = new PdfBuilder('Coeficientes de Evaluación económica - EDTP', 'EDTP');
            // Main table
            if (this.filteredFlattedDataByComponent.length) {
                const tableElement = this.dtCostEfficiencyTable.tableViewChild.nativeElement;
                await pdf.addTable(tableElement, 'Reporte de coeficientes de Evaluación económica por proyecto (todos)');
            }

            // Pie Chart
            const chartElement = this.costEfficiencyChartRef.nativeElement;
            await pdf.addImage(chartElement, 'El cuadro y gráfico muestra los resultados de la evaluación económica de los proyectos');

            pdf.generate('fonabosque_reporte_edtp_costo_eficiencia');
            this.generatingPDF = false;
        }, 10);
    }

    generateProjectBudgetsPDF() {
        this.generatingPDF = true;
        let currentHeight = 0;

        setTimeout(async () => {
            const pdf = new PdfBuilder('PRESUPUESTO EDTP', 'EDTP', PDFOrientation.LANDSCAPE);

            // Main table
            if (this.filteredFlattedDataByComponent.length) {
                const tableElement = this.dtProjectBudgetsTable.tableViewChild.nativeElement;
                await pdf.addTable(tableElement, 'Listado de Municipios con EDTP Aprobado, monto total, monto de cofinanciamiento y monto GAM (Efectivo y Especie)', {
                    columnStyles: {
                        1: {
                            valign: 'middle',
                            halign: 'center',
                            cellWidth: 80
                        }
                    }
                });
            }

            // Pie Chart
            const chartElement = this.projectBudgetChartRef.nativeElement;
            await pdf.addImage(chartElement, 'El cuadro y gráfico muestra la lista de municipios que se encuentran con el EDTP aprobado, y los montos financiados por el FONABOSQUE y la entidad solicitante');

            const vipfeeTableElement = this.dtProjectBudgetsVIPFEETable.tableViewChild.nativeElement;
            const totalRows: any[] = [];
            await pdf.addTable(vipfeeTableElement, 'Reporte VIPFEE', {
                columnStyles: {
                    1: {
                        valign: 'middle',
                        halign: 'center',
                        cellWidth: 80
                    }
                },
                allSectionHooks: true,
                didParseCell: function(data: any) {
                    const cellTextArray = data?.cell?.text || [];
                    const cellText = cellTextArray[0];
                    if (cellText.startsWith('TOTAL')) {
                        totalRows.push(data.row.index);
                        data.cell.styles.fillColor = [11, 117, 114];
                        data.cell.styles.textColor = 'white';
                    } else if (totalRows.includes(data.row.index) && data.column.index > 1) {
                        data.cell.styles.fillColor = [11, 117, 114];
                        data.cell.styles.textColor = 'white';
                    }
                }
            });

            pdf.generate('fonabosque_reporte_edtp_presupuesto');
            this.generatingPDF = false;
        }, 10);
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(undefined);
        this.onDestroy$.complete();
    }
}
