<p-table [value]="budgets" dataKey="id" [tableStyle]="{'min-width': '100%'}">
    <ng-template pTemplate="header">
        <tr>
            <th class="bg-secondary border-0" colspan="2">{{title}}</th>
            <th class="bg-secondary border-0" style="width:15%"></th>
            <th class="bg-secondary border-0" style="width:15%"></th>
            <th class="bg-secondary border-0" style="width:15%"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-obj let-rowIndex="rowIndex">
        <tr>
            <td [className]='!obj.type ? "bg-primary border-0 thin" : ""'>{{ obj.type ? (rowIndex + 1) : ''}}</td>
            <td [className]='!obj.type ? "bg-primary border-0 thin font-bold" : ""'>{{obj.description}}</td>
            <td [className]='!obj.type ? "bg-primary border-0 thin font-bold" : ""'>{{obj.owner | number: '1.2'}}</td>
            <td [className]='!obj.type ? "bg-primary border-0 thin font-bold" : ""'>{{obj.entity | number: '1.2'}}</td>
            <td [className]='!obj.type ? "bg-primary border-0 thin font-bold" : ""'><b>{{obj.total | number: '1.2'}}</b></td>
        </tr>
    </ng-template>
</p-table>
