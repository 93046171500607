import { User } from '../../../core/user/interfaces';

export interface IProjectAssignment {
  id?: number;
  projectId: number;
  userId: number;
  permissions: string;
  stage: string;
  status: string;
  user?: User;
}
