<app-wizard
    [steps]='steps'
    [stepsSize]="5"
    [current]='currentStep'
    [bodyContentTpl]='projectForm'
    [nextDisabled]='this.formGroup?.invalid'
    (onClickNext)='saveSkipValidation($event, true)'
    [layout]='true'
>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column mb-8' [formGroup]='formGroup'>
        <div class='grid'>
            <div class="field col-12 text-2xl text-primary uppercase">
                {{currentStep?.text}}
            </div>

            <div class="field col-12">
                <p-table [value]="results" dataKey="id" editMode="row" [tableStyle]="{'min-width': '200%'}"  styleClass="p-datatable-gridlines" #dt>
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="bg-primary border-x-1 text-center" style="width:60px">NRO.</th>
                            <th class="bg-primary border-x-1 text-center" style="width:300px">ACTIVIDAD</th>
                            <th class="bg-primary border-x-1 text-center">INDICADOR DE ACTIVIDAD</th>
                            <th class="bg-primary border-x-1 text-center">UNIDAD DE MEDIDA</th>
                            <th class="bg-primary border-x-1 text-center">MEDIOS DE VERIFICACIÓN</th>
                            <th class="bg-primary border-x-1 text-center">DURACIÓN EN SEMANAS</th>
                            <th class="bg-primary border-x-1 text-center">% VALOR VARIABLE PONDERADO</th>
                            <th class="bg-primary border-x-1 text-center">META GLOBAL</th>
                            <th class="bg-primary border-x-1 text-center">
                                META PROG.<br>
                                <p-dropdown
                                    [options]="selectableProjectYears"
                                    optionLabel="label"
                                    optionValue="id"
                                    [(ngModel)]="selectedProjectYear"
                                    [ngModelOptions]="{ standalone: true }"
                                    (onChange)="setSelectedYear($event)"
                                >
                                </p-dropdown>
                            </th>
                            <th class="bg-primary border-x-1 text-center">% PROG. PONDERADO<br>GESTIÓN {{selectedProjectYear}}</th>
                            <th class="bg-primary" style="width:100px"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-obj let-editing="editing" let-index="rowIndex">
                        <tr [pEditableRow]="obj">
                            <td colspan='100%' class='p-0 m-0'>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <app-result-activities
                                            [group]='obj'
                                            [goals]="goals"
                                            [canProgram]="!programmerReadOnly()"
                                            [selectedYear]="selectedProjectYear"
                                        ></app-result-activities>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <app-result-activities
                                            [group]='obj'
                                            [canProgram]="!programmerReadOnly()"
                                            [totalProjectDays]='totalProjectDays'
                                            [selectedYear]="selectedProjectYear"
                                            (onActivitiesChange)="onActivitiesChange($event)"
                                        ></app-result-activities>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</ng-template>

