<app-wizard
    [steps]='steps'
    [current]='currentStep'
    [bodyContentTpl]='projectForm'
    [nextDisabled]='this.formGroup?.invalid'
    (onClickNext)='save($event)'
    [layout]='true'
>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column' [formGroup]='formGroup'>
        <div class='grid'>

            <div class='field col-12'>
                <h3>
                    ACTAS DE COMPROMISO SOCIAL DOCUMENTADO QUE VIABILICE SU EJECUCION
                </h3>
            </div>
            <div class="field col-12">
                <button
                    class='p-button p-button-success mb-2'
                    pButton pTooltip="Agregar Acta de Compromiso" tooltipPosition="top"
                    (click)='onNew()'
                    [disabled]='isSubmitted || editing'
                >
                    <i class='pi pi-plus'></i>
                </button>
                <p-table [value]="acts" dataKey="id" editMode="row" [tableStyle]="{'min-width': '100%'}" #dt>
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width:30%">Comunidad Beneficiaria</th>
                            <th style="width:60%">Acta de Compromiso</th>
                            <th style="width:10%"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item let-editing="editing" let-ri="rowIndex">
                        <tr [pEditableRow]="item">
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText
                                               required
                                               placeholder='Comunidad beneficiaria'
                                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                                               type="text" [(ngModel)]="item.community" [ngModelOptions]="{standalone: true}">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{item.community}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <app-input-file
                                            [multiple]="false"
                                            [small]="true"
                                            [maxFiles]="1"
                                            [accept]="'application/pdf, image/jpg, image/jpeg, image/png'"
                                            (onUploaded)="onUploaded($event)"
                                        >
                                        </app-input-file>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <a [href]="attachmentService.getFileUrl(item.actFile?.key)"
                                           [download]="item.actFile?.filename" target="_blank">
                                            {{item.actFile?.filename}}
                                        </a>

                                    </ng-template>
                                </p-cellEditor>
                            </td>

                            <td>
                                <div *ngIf="!isSubmitted" class="flex align-items-center justify-content-center gap-2">
                                    <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(item)" class="p-button-rounded p-button-text"></button>
                                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(item)" class="p-button-rounded p-button-text p-button-success mr-2" [disabled]="!getForm(item)?.valid"></button>
                                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(item, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</ng-template>

