import { Component, Input, OnInit } from '@angular/core';
import { IITCPRequest } from '../../../models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToasterService } from '../../../../shared/services/toaster.service';
import * as moment from 'moment';
import { RoleService } from '../../../../core/role/role.service';
import { Constants } from '../../../../shared/constants';
import { UserService } from '../../../../core/user/user.service';
import { User } from '../../../../core/user/interfaces';
import { ITCPRequestService } from '../../../services';

@Component({
  selector: 'app-generate-user',
  templateUrl: './generate-user.component.html',
  styleUrls: ['./generate-user.component.scss']
})
export class GenerateUserComponent implements OnInit {

  @Input() public id!: number;
  @Input() public data!: IITCPRequest;

  public formGroup!: FormGroup;
  public showForm = false;
  public emailSent = false;
  entityUser: User | undefined;
  private tmpResponse!: IITCPRequest;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private fb: FormBuilder,
    private toasterService: ToasterService,
    private roleService: RoleService,
    private userService: UserService,
    private itcpService: ITCPRequestService
  ) {
  }

    ngOnInit(): void {
        const {user} = this.config.data || {};

        if (user) {
            this.verifyEmailContact(user);
        } else {
            this.generateUser();
        }

    }

  setUserForm(user: any, includeRole = true) {
      let data: any = {
          nombreUsuario: [user.nombreUsuario, Validators.required],
          correo: [user.correo, [Validators.required, Validators.email]],
          primerNombre: [user.primerNombre, Validators.required],
          apellidoPaterno: [user.apellidoPaterno, Validators.required],
          apellidoMaterno: [user.apellidoMaterno, Validators.required],
          ci: [user.ci, Validators.required],
          expiracionCuenta: [user.expiracionCuenta, Validators.required],
          telefono: [user.telefono],
          idEntidad: [user.idEntidad, Validators.required]
      }

      if (includeRole) {
            data = {
                ...data,
                idRole: [user.idRole, Validators.required],
            }
      }

      this.formGroup = this.fb.group(data);
  }

  verifyEmailContact(user: any) {
      const { contactEmail } = this.config.data || {};
      if (contactEmail !== user.email) {
          this.setUserForm({
              ...user,
              correo: contactEmail,
          }, false);

          this.userService.updateUser(this.formGroup.value, user.id).then((resp) => {
             if (resp) {
                 this.formGroup.disable();
                 this.showForm = true;
             }
          });
      } else {
          this.setUserForm(user);
          this.formGroup.disable();
          this.showForm = true;
      }
  }

  generateUser() {
    const { id, contactEmail, entity, contactPhone } = this.config.data || {};
    const username = contactEmail?.toLowerCase()?.replace('@', '_')?.split('.');
    username.pop();

    this.userService.getByEmail(contactEmail).then((resp) => {
       if (resp?.id) {
           this.setUserForm({
               ...resp,
               expiracionCuenta: moment(new Date()).add(1, 'year').toDate(),
           }, false);

           this.userService.updateUser(this.formGroup.value, resp.id).then((resp) => {
               if (resp?.id) {
                   this.userService.aproveRejectUser({ status: true }, resp.id).then((approved) => {
                       this.itcpService.updatePartialData(this.config.data, {
                           userId: resp.id
                       }, id).then((request) => {
                           this.tmpResponse = request;
                           this.entityUser = resp;
                           this.formGroup.disable();
                           this.showForm = true;
                           this.emailSent = true;
                       });
                   });
               }
           });
       } else {
           this.roleService.getByName(Constants.ROLES.REQUESTING_ENTITY).then((role) => {
                   const user = {
                       nombreUsuario: username.join('.'),
                       correo: contactEmail,
                       primerNombre: entity?.nombre,
                       apellidoPaterno: ' ',
                       apellidoMaterno: ' ',
                       ci: '0',
                       expiracionCuenta: moment(new Date()).add(1, 'year').toDate(),
                       telefono: contactPhone,
                       idRole: role.id,
                       idEntidad: entity?.id,
                   }

                   this.setUserForm(user);
           }).then(() => {
               this.userService.storeUser(this.formGroup.value).then((user) => {
                       if (user.id) {
                         this.userService.aproveRejectUser({ status: true }, user.id).then((approved) => {
                           this.itcpService.updatePartialData(this.config.data, {
                             userId: user.id
                           }, id).then((request) => {
                             this.tmpResponse = request;
                             this.entityUser = user;
                           });
                         });
                       }
               });
           }).finally(() => {
               this.showForm = true;
               this.formGroup.disable();
               this.emailSent = true;
           });
        }
    });
  }

  resendEmail() {
    this.showForm = false;
    this.emailSent = true;
    const { user } = this.config.data || {};
    this.userService.enableDisableUser({ status: true }, user.id)
      .then((sent) => {
      })
      .finally(() => {
        this.showForm = true;
      });
  }

  closeModal(): void {
    this.ref.close(this.tmpResponse);
  }

}
