<input
  id="{{ name }}"
  pInputText
  [ngClass]="{ 'ng-invalid ng-dirty': !!errors }"
  placeholder="{{ placeholder }}"
  type="text"
  (keypress)="onKeyPressNumbers($event)"
  class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
/>
<div *ngIf="!!errors">
  <p *ngIf="errors?.required" class="text-danger">*Campo requerido</p>
  <p *ngIf="!!errors" class="text-danger">*Debe ser un numero de celular valido</p>
</div>
