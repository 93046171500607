import { AssignmentComponent } from './components/assignment/assignment.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AnnouncementComponent,
  ITCPRequestComponent,
  ProjectComponent,
  BasicInfoComponent,
  JustifyComponent,
  IdeaComponent,
  GoalsComponent,
  AdvantageComponent,
  DealsComponent,
  PropertyComponent,
  EnvironmentComponent,
  RisksComponent,
  OthersComponent,
  BudgetComponent,
  ConclusionsComponent,
  SwornComponent,
  DoneComponent,
  PdfComponent,
} from './components';
import { Constants } from '../shared/constants';
import { ProjectAssessmentComponent } from './components/project-assessment/project-assessment.component';
import { RequirementComponent } from './components/project-assessment/requirement/requirement.component';
import { AssessmentComponent } from './components/project-assessment/assessment/assessment.component';
import { RankingComponent } from './components/ranking/ranking.component';

const routes: Routes = [
  { path: '', component: AnnouncementComponent, data: { moduleNameCode: Constants.RESOURCES.ANNOUNCEMENTS } },
  {
    path: 'announcements',
    component: AnnouncementComponent,
    data: { moduleNameCode: Constants.RESOURCES.ANNOUNCEMENTS },
  },
  { path: 'requests', component: ITCPRequestComponent, data: { moduleNameCode: Constants.RESOURCES.ITCP_REQUESTS } },
  {
    path: 'assignments',
    component: AssignmentComponent,
    data: { moduleNameCode: Constants.RESOURCES.ITCP_ASSIGNMENT },
  },
  {
    path: 'project-assessments',
    data: { moduleNameCode: Constants.RESOURCES.ITCP_EVAL },
    children: [
      { path: '', component: ProjectAssessmentComponent },
      { path: ':id/requirements', component: RequirementComponent },
      { path: ':id/assessments', component: AssessmentComponent },
    ],
  },
  {
    path: 'project-rankings',
    component: RankingComponent,
    data: { moduleNameCode: Constants.RESOURCES.ITCP_RANKING },
  },
  {
    path: 'projects',
    data: { moduleNameCode: Constants.RESOURCES.ITCP_REGISTER },
    children: [
      { path: '', component: ProjectComponent },
      { path: ':id/basic-info', component: BasicInfoComponent },
      { path: ':id/justify', component: JustifyComponent },
      { path: ':id/idea', component: IdeaComponent },
      { path: ':id/goals', component: GoalsComponent },
      { path: ':id/advantage', component: AdvantageComponent },
      { path: ':id/deals', component: DealsComponent },
      { path: ':id/property', component: PropertyComponent },
      // { path: ':id/via', component: IdeaComponent },
      { path: ':id/environment', component: EnvironmentComponent },
      { path: ':id/risks', component: RisksComponent },
      { path: ':id/other', component: OthersComponent },
      { path: ':id/budget', component: BudgetComponent },
      { path: ':id/conclusions', component: ConclusionsComponent },
      { path: ':id/pdf', component: PdfComponent },
      { path: ':id/sworn', component: SwornComponent },
      { path: ':id/done', component: DoneComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class ITCPRoutingModule {}
