import { ICity, IProvince, ITown } from 'src/app/shared';
export interface IEntity {
  id: number;
  nombre: string;
  direccion: string;
  telefono: string;
  correo: string;
  descripcion: string;
  id_tipo_entidad: number;
  enabled: boolean;
  city?: ICity;
  province?: IProvince;
  town?: ITown;
}
