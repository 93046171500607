import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
    IEdtpCompliance,
    IProjectCompliance,
    IProjectComplianceGroup,
} from '../../../../models';
import {Table} from "primeng/table";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToasterService} from "../../../../../shared/services/toaster.service";
import {EdtpComplianceService, ProjectComplianceService} from "../../../../services";
@Component({
    selector: 'app-compliance',
    templateUrl: './compliance.component.html',
    styleUrls: ['./compliance.component.scss']
})
export class ComplianceComponent implements OnInit, AfterViewInit {
    @Input() group!: IProjectComplianceGroup;
    @Input() defaultCompliances!: IEdtpCompliance[];
    @Input() editMode: boolean = false;
    @Input() canEdit: boolean = false;
    @Output() onCancel = new EventEmitter<any>();
    @Output() onSave = new EventEmitter<any>();
    @Output() onEdit = new EventEmitter<any>();
    @Output() onRemove = new EventEmitter<any>();

    clonedCompliances: { [s: string]: IProjectCompliance; } = {};
    selectedGroup!: IEdtpCompliance;

    @ViewChild('dt') table!: Table;
    constructor(
        private readonly complianceService: ProjectComplianceService,
        private fb: FormBuilder,
        private toasterService: ToasterService,
        private element: ElementRef,
    ) {}

    ngOnInit(): void {
        if(!this.group.children)
            this.group.children = [];
    }

    ngAfterViewInit() {
        console.log()
        if (this.group.code && !this.hasSelectableChildren(this.group.code) && !this.group.children?.length)
            setTimeout(() => this.onNew(), 200);
    }

    saveGroup() {
        this.group.title = this.selectedGroup.title;
        this.group.code = parseInt(this.selectedGroup.code);
        this.onSave.emit(this.group);
        this.editMode = false;
    }

    editGroup() {
        this.onEdit.emit(this.group);
    }

    removeGroup() {
        this.onRemove.emit(this.group);
    }

    cancelEditGroup() {
        this.onCancel.emit(this.group);
    }

    onRowEditInit(obj: IProjectCompliance) {
        if (!obj?.id) return;
        this.clonedCompliances[obj.id] = {...obj};
    }

    onRowEditSave(obj: IProjectCompliance) {
        if (!obj?.id) return;

        const form = this.itemForm(obj);

        if (form.valid) {
            delete this.clonedCompliances[obj.id];

            if (obj?.id.toString().includes('new_')) {
                const index = this.group.children.indexOf(obj);
                this.complianceService.store(form.value).then((resp) => {
                    this.group.children[index] = resp;
                    this.toasterService.success('El Registro fue creado satisfactoriamente.');
                });

            } else {
                const id = parseInt(obj.id.toString());
                this.complianceService.update(form.value, id).then((resp) => {
                    this.toasterService.success('El Registro fue actualizado satisfactoriamente.');
                })
            }

        } else {
            this.toasterService.error('No se pudo guardar, los datos son inválidos!');
        }
    }

    onRowEditCancel(obj: IProjectCompliance, index: number) {
        if (!obj?.id) return;

        if (this.clonedCompliances[obj.id]) {
            this.group.children[index] = this.clonedCompliances[obj.id];
            delete this.clonedCompliances[obj.id];
        }

        if (obj?.id.toString().includes('new_')) {
            this.group.children = this.group.children.filter(o => o.id !== obj.id);
        }
    }

    onNew() {
        const ob: IProjectCompliance = {
            id: `new_${new Date().getTime()}`,
            projectId: this.group.projectId,
            groupId: this.group.id ? parseInt(this.group.id.toString()) : 0,
            compliance: this.hasSelectableChildren(this.group.code) ? '' : this.group.title,
            submitted: false,
        }

        this.group.children.push(ob);
        this.table.initRowEdit(this.table.value[this.group.children.length - 1]);
    }

    onSelectItem(event: any) {
        const item = this.defaultCompliances.find(c => c.id === event.value);
        if (item) this.selectedGroup = { ...item };
    }

    onSelectSubItem(event: any, obj: IProjectCompliance) {
        if (event.value !== 'Otros'){
            obj.compliance = event.value;
            obj.other = false;
        } else {
            obj.other = true;
            obj.compliance = '';
            setTimeout(() => {
                const input = this.element.nativeElement.querySelector('.other');
                if (input) input.focus()
            }, 100);
        }
    }

    itemForm(obj: IProjectCompliance) {
        const form: FormGroup = this.fb.group({
            projectId: [obj.projectId, Validators.required],
            groupId: [obj.groupId, Validators.required],
            compliance: [obj.compliance, Validators.required],
            submitted: [obj.submitted],
        });
        return form;
    }

    hasSelectableChildren(code: number):boolean {
        const item = this.defaultCompliances.find(c => c.code === code?.toString());
        return !!(item && item.children?.length);
    }

    getDefaultGroupChildren(code: number) {
        let children: IEdtpCompliance[] = [];
        const item = this.defaultCompliances.find(c => c.code === code?.toString());
        if (item && item.children) children = item.children;

        children = [
            ...children,
            {
                code: 'OTHER',
                title: 'Otros',
                description: 'Otros',
                parentCode: '',
            }
        ];

        return children;
    }
}
