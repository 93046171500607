import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectModel } from '../../../models';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../services';
import {AttachmentService, CryptoService} from '../../../../shared';
import { FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { Constants } from '../../../../shared/constants';

@Component({
    selector: 'app-pdf',
    templateUrl: './pdf.component.html',
    styleUrls: ['./pdf.component.scss']
})
export class PdfComponent extends ProjectModel implements OnInit, OnDestroy {
    private step: number = 13;

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private crypto: CryptoService,
    ) {
        super(activatedRoute, projectService, tService, crypto);
    }

    ngOnInit(): void {
        this.readParams();
        this.setActive(this.step);

        this.service.get(this.params['id']).then((project) => {
            const {id, name } = project || {};
            this.currentProject = { ...project };

            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required]
            });
            this.disableFormIfSubmitted();
            this.showContent = true;
        });
    }

    print(event: any) {
        console.log('Imprimir');
        // const printContents = document.getElementById('print-area')?.innerHTML;
        // const originalContents = document.body.innerHTML;
        // if (printContents) document.body.innerHTML = printContents;
        // window.print();
        // document.body.innerHTML = originalContents;
    }

    ngOnDestroy() {
        this.cleanParams();
    }
}
