<app-wizard
  [steps]="steps"
  [current]="currentStep"
  [bodyContentTpl]="maeInfoForm"
  [nextDisabled]="this.formGroup?.invalid"
  (onClickNext)="save($event)"
>
</app-wizard>

<ng-template #maeInfoForm>
  <div *ngIf="showContent" class="mae-form flex justify-content-center flex-column mb-8" [formGroup]="formGroup">
    <div class="field">
      <label>Nombre de la MAE</label>
      <input
        id="seoName"
        [ngClass]="{ 'ng-invalid ng-dirty': form.seoName.errors && form.seoName.touched }"
        pInputText
        formControlName="seoName"
        placeholder="Nombre de la MAE"
        type="text"
        class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
      />
      <div *ngIf="form.seoName.errors">
        <p *ngIf="form.seoName.errors?.required && form.seoName.touched" class="text-danger">*Campo requerido</p>
      </div>
    </div>

    <div class="field">
      <app-input-file
        id="seoIdentifyFile"
        [label]="'Carnet de Identidad de la MAE'"
        [multiple]="false"
        [maxFiles]="1"
        [defaultFiles]="seoIdentifyFile"
        [accept]="'application/pdf, image/jpg, image/jpeg, image/png'"
        (onUploaded)="onUploaded($event)"
        (onRemove)="onRemoveFile($event)"
      >
      </app-input-file>

      <input id="seoIdentityFileId" formControlName="seoIdentityFileId" type="hidden" />
      <div *ngIf="form.seoIdentityFileId.errors">
        <p *ngIf="form.seoIdentityFileId.errors?.required && form.dirty" class="text-danger">*Campo requerido</p>
      </div>
    </div>

    <div class="field">
      <label>Cargo de la MAE</label>
      <input
        id="seoJobtitle"
        pInputText
        [ngClass]="{ 'ng-invalid ng-dirty': form.seoJobtitle.errors && form.seoJobtitle.touched }"
        formControlName="seoJobtitle"
        placeholder="Cargo de la MAE"
        type="text"
        class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
      />
      <div *ngIf="form.seoJobtitle.errors">
        <p *ngIf="form.seoJobtitle.errors?.required && form.seoJobtitle.touched" class="text-danger">
          *Campo requerido
        </p>
      </div>
    </div>

    <div class="field">
      <app-input-file
        id="seoMemoFile"
        [label]="'Documento de Designación'"
        [multiple]="false"
        [maxFiles]="1"
        [defaultFiles]="seoMemoFile"
        [accept]="'application/pdf, image/jpg, image/jpeg, image/png'"
        (onUploaded)="onUploaded($event)"
        (onRemove)="onRemoveFile($event)"
      >
      </app-input-file>

      <input id="seoMemoFileId" formControlName="seoMemoFileId" type="hidden" />
      <div *ngIf="form.seoMemoFileId.errors">
        <p *ngIf="form.seoMemoFileId.errors?.required && form.dirty" class="text-danger">*Campo requerido</p>
      </div>
    </div>

    <div class="field">
      <label>Nro. de Celular de la MAE</label>
            <input
              id="seoCellPhone"
              pInputText
              [ngClass]="{ 'ng-invalid ng-dirty': form.seoCellPhone.errors && form.seoCellPhone.touched }"
              formControlName="seoCellPhone"
              placeholder="Numero de celular de la MAE"
              type="text"
              (keypress)="onKeyPressNumbers($event)"
              class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
            />
            <div *ngIf="form.seoCellPhone.errors">
              <p *ngIf="form.seoCellPhone.errors?.required && form.seoCellPhone.touched" class="text-danger">
                *Campo requerido
              </p>
              <p *ngIf="form.seoCellPhone.errors && form.seoCellPhone.touched" class="text-danger">
                *Debe ser un numero de celular valido
              </p>
            </div>
    </div>
  </div>
</ng-template>
