<app-wizard
  [steps]="steps"
  [current]="currentStep"
  [bodyContentTpl]="projectForm"
  [nextDisabled]="this.formGroup?.invalid"
  (onClickNext)="save($event)"
  [layout]="true"
>
</app-wizard>

<ng-template #projectForm>
  <div *ngIf="showContent" class="project-form flex justify-content-center flex-column" [formGroup]="formGroup">
    <div class="grid">
      <div class="field col-12">
        <h3>IDENTIFICACION DE POSIBLES IMPACTOS AMBIENTALES</h3>
      </div>
      <div class="field col-12">
        <p-table [value]="environments" dataKey="id" editMode="row" [tableStyle]="{ 'min-width': '100%' }" #dt>
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 15%">Componente Ambiental</th>
              <th style="width: 50%">Nivel</th>
              <th style="width: 30%">Temporalidad</th>
              <th style="width: 5%"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="item">
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    {{ item.component }}
                    <input
                      type="hidden"
                      [value]="item.component"
                      [(ngModel)]="item.component"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ item.component }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-radioButton
                      class="ml-4 mb-2"
                      name="grouplevels"
                      [value]="levels.NONE"
                      [label]="levels.NONE"
                      [(ngModel)]="item.level"
                      [ngModelOptions]="{ standalone: true }"
                    ></p-radioButton>
                    <p-radioButton
                      class="ml-4 mb-2"
                      name="grouplevels"
                      [value]="levels.LOW"
                      [label]="levels.LOW"
                      [(ngModel)]="item.level"
                      [ngModelOptions]="{ standalone: true }"
                    ></p-radioButton>
                    <p-radioButton
                      class="ml-4 mb-2"
                      name="grouplevels"
                      [value]="levels.MEDIUM"
                      [label]="levels.MEDIUM"
                      [(ngModel)]="item.level"
                      [ngModelOptions]="{ standalone: true }"
                    ></p-radioButton>
                    <p-radioButton
                      class="ml-4 mb-2"
                      name="grouplevels"
                      [value]="levels.HIGH"
                      [label]="levels.HIGH"
                      [(ngModel)]="item.level"
                      [ngModelOptions]="{ standalone: true }"
                    ></p-radioButton>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <span class="ml-4 mb-2">{{ item.level }}</span>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-radioButton
                      class="ml-4 mb-2"
                      name="grouptemporality"
                      [value]="temporalities.TRANSIENT"
                      [label]="temporalities.TRANSIENT"
                      [(ngModel)]="item.temporality"
                      [ngModelOptions]="{ standalone: true }"
                    ></p-radioButton>
                    <p-radioButton
                      class="ml-4 mb-2"
                      name="grouptemporality"
                      [value]="temporalities.PERMANENT"
                      [label]="temporalities.PERMANENT"
                      [(ngModel)]="item.temporality"
                      [ngModelOptions]="{ standalone: true }"
                    ></p-radioButton>
                    <p-radioButton
                      class="ml-4 mb-2"
                      name="grouptemporality"
                      [value]="temporalities.DOES_NOT_CORRESPOND"
                      [label]="temporalities.DOES_NOT_CORRESPOND"
                      [(ngModel)]="item.temporality"
                      [ngModelOptions]="{ standalone: true }"
                    ></p-radioButton>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <span class="ml-4 mb-2">{{ item.temporality }}</span>
                  </ng-template>
                </p-cellEditor>
              </td>

              <td>
                <div *ngIf="!isSubmitted" class="flex align-items-center justify-content-center gap-2">
                  <button
                    *ngIf="!editing"
                    pButton
                    pRipple
                    type="button"
                    pInitEditableRow
                    icon="pi pi-pencil"
                    (click)="onRowEditInit(item)"
                    class="p-button-rounded p-button-text"
                  ></button>
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pSaveEditableRow
                    icon="pi pi-check"
                    (click)="onRowEditSave(item)"
                    class="p-button-rounded p-button-text p-button-success mr-2"
                  ></button>
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pCancelEditableRow
                    icon="pi pi-times"
                    (click)="onRowEditCancel(item, ri)"
                    class="p-button-rounded p-button-text p-button-danger"
                  ></button>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</ng-template>
