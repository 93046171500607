<p-breadcrumb [model]='breadcrumbs'></p-breadcrumb>
<p-dialog [closable]='false' [showHeader]='false' [visible]='generatingPDF' [modal]='true' [style]="{ width: '50vw' }"
    [draggable]='false' [resizable]='false'>
    <div class='text-center py-6'>Por favor espere mientras se genera el documento PDF .....</div>
</p-dialog>

<p-tabView styleClass='tabview-custom' [scrollable]='true'>
    <!--  Proyectos en ejecucion, reporte fisico/financiero -->
    <p-tabPanel>
        <ng-template pTemplate='header'>
            <i class='pi pi-list'></i>
            <span class='ml-2'>Proyectos en ejecucion</span>
        </ng-template>

        <div class='grid'>
            <div class='field lg:col-4 md:col-6 sm:col-12'>
                <button pButton type='button' label='Exportar a PDF' (click)='generateExecutedProjectsPDF()'
                    [disabled]='generatingPDF || !runningProjects.length'></button>
            </div>
        </div>

        <h4 *ngIf='runningProjects.length' class='mb-6 mt-3'>Listado de Municipios y otros con proyectos en Ejecucion
        </h4>

        <div *ngIf='runningProjects.length' class='container mt-3'>
            <p-table #dtRunningProjects [value]='runningProjects' styleClass='p-datatable-gridlines'
                responsiveLayout='scroll' [scrollable]='!generatingPDF' scrollHeight='400px' [loading]='loading'
                currentPageReportTemplate='{first} al {last} de {totalRecords} items'>
                <ng-template pTemplate='header'>
                    <tr>
                        <th class='bg-primary text-center' style='width: 50px'>Nº</th>
                        <th class='bg-primary text-center' style='width: 200px'>Nombre del proyecto</th>
                        <th class='bg-primary text-center' style='width: 120px'>Departamento</th>
                        <th class='bg-primary text-center' style='width: 150px'>Provincia</th>
                        <th class='bg-primary text-center' style='width: 150px'>Municipio</th>
                        <th class='bg-primary text-center' style='width: 200px'>MAE</th>
                        <th class='bg-primary text-center' style='width: 200px'>Estado del proyecto</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate='body' let-project let-ri='rowIndex'>
                    <tr>
                        <td class='text-center overflow-hidden' style='width: 50px'>{{ ri + 1 }}</td>
                        <td class='text-center overflow-hidden' style='width: 200px'>{{ project?.name }}</td>
                        <td class='text-center overflow-hidden' style='width: 150px'>
                            {{ project?.iTCPRequest?.entity?.city?.dep }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 200px'>
                            {{ project?.iTCPRequest?.entity?.province?.prov }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 200px'>
                            {{ project?.iTCPRequest?.entity?.town?.mun }}
                        </td>
                        <td *ngIf='!generatingPDF' class='text-center overflow-hidden' style='width: 200px'>
                            {{ project?.iTCPRequest?.seoName }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 200px'>
                            <app-project-status [status]='project.status'></app-project-status>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <h4 *ngIf='flattedFSGoalResultsPerProject.length' class='mb-6 mt-3'>Reporte Fisico de entidades ejecutoras con
            proyectos en ejecución</h4>

        <div *ngIf='flattedFSGoalResultsPerProject.length' class='container mt-3'>
            <p-table #dtFSGoalResultsTable [value]='flattedFSGoalResultsPerProject' styleClass='p-datatable-gridlines'
                [loading]='loading' rowGroupMode='rowspan' groupRowsBy='projectName'>
                <ng-template pTemplate='header'>
                    <tr>
                        <th class='bg-primary text-center' style='width: 250px'>Nombre del proyecto</th>
                        <th class='bg-primary text-center' style='width: 150px'>Componente</th>
                        <th class='bg-primary text-center' style='width: 150px'>Resultado o meta esperada</th>
                        <th class='bg-primary text-center' style='width: 200px'>Actividad</th>
                        <th class='bg-primary text-center' style='width: 100px'>Avance programado</th>
                        <th class='bg-primary text-center' style='width: 400px'>Avence Ejecutado</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate='body' let-goalResult let-ri='rowIndex' let-rowgroup='rowgroup'
                    let-rowspan='rowspan'>
                    <tr>
                        <td *ngIf='rowgroup' [attr.rowspan]='rowspan' class='text-center overflow-hidden'
                            style='width: 200px'>
                            {{ goalResult.projectName }}
                        </td>
                        <td style='width: 150px'>{{ goalResult.component }}</td>
                        <td style='width: 150px'>{{ goalResult.expectedResult }}</td>
                        <td style='width: 200px'>{{ goalResult.activity }}</td>
                        <td style='width: 100px'>{{ goalResult.generalExpected }}</td>
                        <td style='width: 300px'>
                            <div>
                                <div *ngFor='let monthDetail of goalResult.monthDetails'>
                                    <b>{{ monthDetail.startsDate | date: 'MMMM, y' }}</b><br />
                                    Ejecutado: <b>{{ monthDetail.executed }}</b><br />
                                    Esperado: <b>{{ monthDetail.expected }}</b><br /><br />
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div *ngIf='flattedFNGoalResultsPerProject.length' class='container mt-3'>
            <h4 class='my-6'>Reporte Financiero de entidades ejecutoras con proyectos en ejecución</h4>
            <p-table #dtFNGoalResultsTable [value]='flattedFNGoalResultsPerProject' styleClass='p-datatable-gridlines'
                [loading]='loading' rowGroupMode='rowspan' groupRowsBy='projectName'>
                <ng-template pTemplate='header'>
                    <tr>
                        <th class='bg-primary text-center' style='width: 250px'>Nombre del proyecto</th>
                        <th class='bg-primary text-center' style='width: 150px'>Componente</th>
                        <th class='bg-primary text-center' style='width: 150px'>Resultado o meta esperada</th>
                        <th class='bg-primary text-center' style='width: 200px'>Actividad</th>
                        <th class='bg-primary text-center' style='width: 200px'>Partida presupuestaria</th>
                        <th class='bg-primary text-center' style='width: 900px'>Presupuesto</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate='body' let-goalResult let-ri='rowIndex' let-rowgroup='rowgroup'
                    let-rowspan='rowspan'>
                    <tr>
                        <td *ngIf='rowgroup' [attr.rowspan]='rowspan' class='text-center overflow-hidden'
                            style='width: 200px'>
                            {{ goalResult.projectName }}
                        </td>
                        <td style='width: 150px'>{{ goalResult.component }}</td>
                        <td style='width: 150px'>{{ goalResult.expectedResult }}</td>
                        <td style='width: 200px'>{{ goalResult.activity }}</td>
                        <td style='width: 200px'>
                            {{ goalResult.budgetItemCode + ' - ' + goalResult.budgetItemDetails }}
                        </td>
                        <td style='width: 900px'>
                            <div>
                                <div *ngFor='let monthDetail of goalResult.monthDetails'>
                                    <b>{{ monthDetail.startsDate | date: 'MMMM, y' }}</b><br />
                                    Presupuesto Entidad Programado: <b>{{ monthDetail.entity }}</b><br />
                                    Presupuesto Entidad Ejecutado: <b>{{ monthDetail.entityExecuted }}</b><br /><br />
                                    Presupuesto Entidad en especie Programado: <b>{{ monthDetail.entityInKind
                                        }}</b><br />
                                    Presupuesto Entidad en especie Ejecutado:
                                    <b>{{ monthDetail.entityInKindExpected }}</b><br /><br />
                                    Presupuesto Fonabosque Programado: <b>{{ monthDetail.owner }}</b><br />
                                    Presupuesto Fonabosque Ejecutado: <b>{{ monthDetail.ownerExpected }}</b><br /><br />
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-tabPanel>

    <!--  Desembolsos programados-->
    <p-tabPanel *ngIf='projectWithStartOrders.length'>
        <ng-template pTemplate='header'>
            <i class='pi pi-list'></i>
            <span class='ml-2'>Desembolsos programados</span>
        </ng-template>

        <div *ngIf='projectWithStartOrders.length'>
            <div class='grid'>
                <div class='field lg:col-4 md:col-6 sm:col-12'>
                    <button pButton type='button' label='Exportar a PDF' (click)='generateStartOrderProjectPdf()'
                        [disabled]='generatingPDF'></button>
                </div>
            </div>

            <div class='mt-3'>
                <h4 class='mb-6 mt-3'>Programacion de Desembolsos para los Proyectos</h4>
            </div>
            <p-table #dtProjectWithStartOrders [value]='projectWithStartOrders' styleClass='p-datatable-gridlines'
                responsiveLayout='scroll' scrollHeight='400px' [scrollable]='!generatingPDF' [loading]='loading'
                currentPageReportTemplate='{first} al {last} de {totalRecords} items'>
                <ng-template pTemplate='header'>
                    <tr>
                        <th class='bg-primary text-center' style='width: 50px'>Nº</th>
                        <th class='bg-primary text-center' style='width: 200px'>Entidad</th>
                        <th class='bg-primary text-center' style='width: 120px'>Correo</th>
                        <th class='bg-primary text-center' style='width: 120px'>Direccion</th>
                        <th class='bg-primary text-center' style='width: 120px'>Departamento</th>
                        <th class='bg-primary text-center' style='width: 150px'>Provincia</th>
                        <th class='bg-primary text-center' style='width: 150px'>Municipio</th>
                        <th class='bg-primary text-center' style='width: 200px'>Nombre del proyecto</th>
                        <th class='bg-primary text-center' style='width: 200px'>Orden de Inicio</th>
                        <th class='bg-primary text-center' style='width: 200px'>Primer desembolso</th>
                        <th class='bg-primary text-center' style='width: 200px'>Segundo desembolso</th>
                        <th class='bg-primary text-center' style='width: 200px'>Tercer desembolso</th>
                        <th class='bg-primary text-center' style='width: 200px'>Estado del Proyecto</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate='body' let-project let-ri='rowIndex'>
                    <tr>
                        <td class='text-center overflow-hidden' style='width: 50px'>{{ ri + 1 }}</td>
                        <td class='text-center overflow-hidden' style='width: 200px'>
                            {{ project?.iTCPRequest.entity?.nombre }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 120px'>
                            {{ project?.iTCPRequest.entity?.correo }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 120px'>
                            {{ project?.iTCPRequest.entity?.direccion }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 120px'>
                            {{ project?.iTCPRequest.entity?.city?.dep }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 150px'>
                            {{ project?.iTCPRequest.entity?.province?.prov }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 150px'>
                            {{ project?.iTCPRequest.entity?.town?.mun }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 200px'>
                            {{ project?.name }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 200px'>
                            {{ project.startOrder?.startOrderDate | date: 'yyyy-MM-dd' }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 200px'>
                            {{ project.startOrder?.firstPaymentDate | date: 'yyyy-MM-dd' }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 200px'>
                            {{ project.startOrder?.secondPaymentDate | date: 'yyyy-MM-dd' }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 200px'>
                            {{ project.startOrder?.thirdPaymentDate | date: 'yyyy-MM-dd' }}
                        </td>
                        <td>
                            <app-project-status [status]='project.status'></app-project-status>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-tabPanel>

    <!--  Proyectos cerrados-->
    <p-tabPanel *ngIf='closedProjects.length || projectsForComponentReport.length'>
        <ng-template pTemplate='header'>
            <i class='pi pi-list'></i>
            <span class='ml-2'>Proyectos Cerrados</span>
        </ng-template>

        <div class='grid'>
            <div class='field lg:col-4 md:col-6 sm:col-12'>
                <button pButton type='button' label='Exportar a PDF' (click)='generateClosedProjectsPDF()'
                    [disabled]='generatingPDF || !closedProjects.length'></button>
            </div>
        </div>

        <div *ngIf='closedProjects.length'>
            <div class='mt-3' #closedProjectsRef>
                <h4 class='mb-6 mt-3'>Listado de entidades ejecutoras con proyectos en etapa de cierre</h4>
            </div>
            <p-table #closedProjectsTable [value]='closedProjects' styleClass='p-datatable-gridlines'
                responsiveLayout='scroll' scrollHeight='400px' [scrollable]='!generatingPDF' [loading]='loading'
                currentPageReportTemplate='{first} al {last} de {totalRecords} items'>
                <ng-template pTemplate='header'>
                    <tr>
                        <th class='bg-primary text-center' style='width: 50px'>Nº</th>
                        <th class='bg-primary text-center' style='width: 200px'>Entidad</th>
                        <th class='bg-primary text-center' style='width: 150px'>Correo</th>
                        <th class='bg-primary text-center' style='width: 150px'>Direccion</th>
                        <th class='bg-primary text-center' style='width: 120px'>Departamento</th>
                        <th class='bg-primary text-center' style='width: 150px'>Provincia</th>
                        <th class='bg-primary text-center' style='width: 150px'>Municipio</th>
                        <th class='bg-primary text-center' style='width: 200px'>Nombre del proyecto</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate='body' let-project let-ri='rowIndex'>
                    <tr>
                        <td class='text-center overflow-hidden' style='width: 50px'>{{ ri + 1 }}</td>
                        <td class='text-center overflow-hidden' style='width: 200px'>
                            {{ project?.iTCPRequest.entity?.nombre }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 120px'>
                            {{ project?.iTCPRequest.entity?.correo }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 120px'>
                            {{ project?.iTCPRequest.entity?.direccion }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 120px'>
                            {{ project?.iTCPRequest.entity?.city?.dep }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 150px'>
                            {{ project?.iTCPRequest.entity?.province?.prov }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 150px'>
                            {{ project?.iTCPRequest.entity?.town?.mun }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 200px'>{{ project.name }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-tabPanel>

    <!--  Plantines por especie-->
    <p-tabPanel>
        <ng-template pTemplate='header'>
            <i class='pi pi-list'></i>
            <span class='ml-2'>Plantines por especie</span>
        </ng-template>

        <div class='grid'>
            <div class='field lg:col-4 md:col-6 sm:col-12'>
                <button pButton type='button' label='Exportar a PDF' (click)='generateSeedlingsReportPDF()'
                    [disabled]='generatingPDF || !seedlingsPerProject.length'></button>
            </div>
        </div>

        <div *ngIf='seedlingsPerProject.length'>
            <h4 class='mb-6 mt-3'>Nro de plantines por especie de los proyectos cerrados, en cierre y en ejecucion</h4>
            <p-table #seedlingsPerProjectTable [value]='seedlingsPerProject' styleClass='p-datatable-gridlines'
                [loading]='loading' rowGroupMode='rowspan' groupRowsBy='project.iTCPRequest.entityId'>
                <ng-template pTemplate='header'>
                    <tr>
                        <th class='bg-primary text-center' style='width: 50px'>Nº</th>
                        <th class='bg-primary text-center' style='width: 200px'>Entidad</th>
                        <th class='bg-primary text-center' style='width: 120px'>Departamento</th>
                        <th class='bg-primary text-center' style='width: 150px'>Provincia</th>
                        <th class='bg-primary text-center' style='width: 150px'>Municipio</th>
                        <th class='bg-primary text-center' style='width: 200px'>Nombre del proyecto</th>
                        <th class='bg-primary text-center' style='width: 200px'>Plantin</th>
                        <th class='bg-primary text-center' style='width: 100px'>Año 1</th>
                        <th class='bg-primary text-center' style='width: 100px'>Año 2</th>
                        <th class='bg-primary text-center' style='width: 100px'>Año 3</th>
                        <th class='bg-primary text-center' style='width: 100px'>Total</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate='body' let-projectC2 let-ri='rowIndex' let-rowgroup='rowgroup'
                    let-rowspan='rowspan'>
                    <tr>
                        <td class='text-center overflow-hidden' style='width: 50px'>{{ ri + 1 }}</td>
                        <td *ngIf='rowgroup' [attr.rowspan]='rowspan' class='text-center overflow-hidden'
                            style='width: 200px'>
                            {{ projectC2?.project.iTCPRequest.entity?.nombre }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 120px'>
                            {{ projectC2?.project.iTCPRequest.entity?.town?.dep }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 150px'>
                            {{ projectC2?.project.iTCPRequest.entity?.town?.prov }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 150px'>
                            {{ projectC2?.project.iTCPRequest.entity?.town?.mun }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 200px'>{{ projectC2.project.name }}</td>
                        <td class='text-center overflow-hidden' style='width: 200px'>
                            <div>
                                <div class='font-semibold'>
                                    {{ projectC2.specie.code + ' - ' + projectC2.specie.name }}
                                </div>
                                <span>{{
                                    projectC2.typology == 'TYPOLOGY_II' ? 'Tipologia II' : 'Tipologia III'
                                    }}</span>
                            </div>
                        </td>
                        <td class='text-center overflow-hidden' style='width: 100px'>
                            {{ projectC2.quantityYear1 }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 100px'>
                            {{ projectC2.quantityYear2 }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 100px'>
                            {{ projectC2.quantityYear3 }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 100px'>
                            {{ projectC2.total }}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-tabPanel>

    <!--  Hectareas por tipo de plantacion-->
    <p-tabPanel>
        <ng-template pTemplate='header'>
            <i class='pi pi-list'></i>
            <span class='ml-2'>Hectareas por Tipo de plantacion</span>
        </ng-template>

        <div class='grid'>
            <div class='field lg:col-4 md:col-6 sm:col-12'>
                <button pButton type='button' label='Exportar a PDF' (click)='generateForestedReportPDF()' [disabled]='
                        generatingPDF || !(haSpecieForestedPerProject.length || haSpecieReforestedPerProject.length)
                    '></button>
            </div>
        </div>

        <div *ngIf='haSpecieForestedPerProject.length || haSpecieReforestedPerProject.length'>
            <h4 class='mb-6 mt-3'>
                Nro de ha Forestadas por tipo de plantacion de los Proyectos cerrados, en cierre y en ejecucion
            </h4>

            <p-table #forestationPerProjectTable [value]='haSpecieForestedPerProject' styleClass='p-datatable-gridlines'
                [loading]='loading' [scrollable]='true' scrollHeight='400px'>
                <ng-template pTemplate='header'>
                    <tr>
                        <th class='bg-primary text-center' style='width: 50px'>Nº</th>
                        <th class='bg-primary text-center' style='width: 200px'>Entidad</th>
                        <th class='bg-primary text-center' style='width: 120px'>Departamento</th>
                        <th class='bg-primary text-center' style='width: 150px'>Provincia</th>
                        <th class='bg-primary text-center' style='width: 150px'>Municipio</th>
                        <th class='bg-primary text-center' style='width: 200px'>Comunidad</th>
                        <th class='bg-primary text-center' style='width: 200px'>Especies</th>
                        <th class='bg-primary text-center' style='width: 100px'>Tipo</th>
                        <th class='bg-primary text-center' style='width: 100px'>Año 1</th>
                        <th class='bg-primary text-center' style='width: 100px'>Tipo</th>
                        <th class='bg-primary text-center' style='width: 100px'>Año 2</th>
                        <th class='bg-primary text-center' style='width: 100px'>Tipo</th>
                        <th class='bg-primary text-center' style='width: 100px'>Año 3</th>
                        <th class='bg-primary text-center' style='width: 100px'>Sistema de plantacion</th>
                        <th class='bg-primary text-center' style='width: 100px'>Metodo de plantacion</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate='body' let-projectC3 let-ri='rowIndex' let-rowgroup='rowgroup'
                    let-rowspan='rowspan'>
                    <tr>
                        <td class='text-center overflow-hidden' style='width: 50px'>{{ ri + 1 }}</td>
                        <td class='text-center overflow-hidden' style='width: 200px'>
                            {{ projectC3?.project.iTCPRequest.entity?.nombre }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 120px'>
                            {{ projectC3?.project.iTCPRequest.entity?.town?.dep }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 150px'>
                            {{ projectC3?.project.iTCPRequest.entity?.town?.prov }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 150px'>
                            {{ projectC3?.project.iTCPRequest.entity?.town?.mun }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 200px'>{{ projectC3.act.community }}</td>
                        <td class='text-center overflow-hidden' style='width: 200px'>
                            <div>
                                <div class='font-semibold'>
                                    {{ projectC3.specie.code + ' - ' + projectC3.specie.name }}
                                </div>
                                <div>{{ projectC3.typology == 'TYPOLOGY_II' ? 'Tipologia II' : 'Tipologia III' }}</div>
                            </div>
                        </td>
                        <td class='text-center overflow-hidden' style='width: 100px'>
                            {{ projectC3.year1Kind }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 100px'>
                            {{ projectC3.quantityYear1 }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 100px'>
                            {{ projectC3.year2Kind }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 100px'>
                            {{ projectC3.quantityYear2 }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 100px'>
                            {{ projectC3.year3Kind }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 100px'>
                            {{ projectC3.quantityYear3 }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 100px'>
                            {{ projectC3.system }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 100px'>
                            {{ projectC3.method }}
                        </td>
                    </tr>
                </ng-template>
            </p-table>

            <h4 class='mb-6 mt-3'>
                Nro de ha Reforestadas por tipo de plantacion de los Proyectos cerrados, en cierre y en ejecucion
            </h4>

            <p-table #reforestationPerProjectTable [value]='haSpecieReforestedPerProject'
                styleClass='p-datatable-gridlines' [loading]='loading' [scrollable]='true' scrollHeight='400px'>
                <ng-template pTemplate='header'>
                    <tr>
                        <th class='bg-primary text-center' style='width: 50px'>Nº</th>
                        <th class='bg-primary text-center' style='width: 200px'>Entidad</th>
                        <th class='bg-primary text-center' style='width: 120px'>Departamento</th>
                        <th class='bg-primary text-center' style='width: 150px'>Provincia</th>
                        <th class='bg-primary text-center' style='width: 150px'>Municipio</th>
                        <th class='bg-primary text-center' style='width: 200px'>Comunidad</th>
                        <th class='bg-primary text-center' style='width: 200px'>Especies</th>
                        <th class='bg-primary text-center' style='width: 100px'>Tipo</th>
                        <th class='bg-primary text-center' style='width: 100px'>Año 1</th>
                        <th class='bg-primary text-center' style='width: 100px'>Tipo</th>
                        <th class='bg-primary text-center' style='width: 100px'>Año 2</th>
                        <th class='bg-primary text-center' style='width: 100px'>Tipo</th>
                        <th class='bg-primary text-center' style='width: 100px'>Año 3</th>
                        <th class='bg-primary text-center' style='width: 100px'>Sistema de plantacion</th>
                        <th class='bg-primary text-center' style='width: 100px'>Metodo de plantacion</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate='body' let-projectC3 let-ri='rowIndex' let-rowgroup='rowgroup'
                    let-rowspan='rowspan'>
                    <tr>
                        <td class='text-center overflow-hidden' style='width: 50px'>{{ ri + 1 }}</td>
                        <td class='text-center overflow-hidden' style='width: 200px'>
                            {{ projectC3?.project.iTCPRequest.entity?.nombre }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 120px'>
                            {{ projectC3?.project.iTCPRequest.entity?.town?.dep }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 150px'>
                            {{ projectC3?.project.iTCPRequest.entity?.town?.prov }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 150px'>
                            {{ projectC3?.project.iTCPRequest.entity?.town?.mun }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 200px'>{{ projectC3.act.community }}</td>
                        <td class='text-center overflow-hidden' style='width: 200px'>
                            <div>
                                <div class='font-semibold'>
                                    {{ projectC3.specie.code + ' - ' + projectC3.specie.name }}
                                </div>
                                <span>{{
                                    projectC3.typology == 'TYPOLOGY_II' ? 'Tipologia II' : 'Tipologia III'
                                    }}</span>
                            </div>
                        </td>
                        <td class='text-center overflow-hidden' style='width: 100px'>
                            {{ projectC3.year1Kind }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 100px'>
                            {{ projectC3.quantityYear1 }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 100px'>
                            {{ projectC3.year2Kind }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 100px'>
                            {{ projectC3.quantityYear2 }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 100px'>
                            {{ projectC3.year3Kind }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 100px'>
                            {{ projectC3.quantityYear3 }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 100px'>
                            {{ projectC3.system }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 100px'>
                            {{ projectC3.method }}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-tabPanel>

    <!--  Hectareas por departamento -->
    <p-tabPanel>
        <ng-template pTemplate='header'>
            <i class='pi pi-list'></i>
            <span class='ml-2'>Hectareas por Departamento</span>
        </ng-template>

        <div class='grid'>
            <div class='field lg:col-4 md:col-6 sm:col-12'>
                <button pButton type='button' label='Exportar a PDF' (click)='generateForestedPerCityReportPDF()'
                    [disabled]='
                        generatingPDF || !(totalHaForestedPerProject.length || totalHaReforestedPerProject.length)
                    '></button>
            </div>
        </div>

        <div *ngIf='totalHaForestedPerProject.length || totalHaReforestedPerProject.length'>
            <h4 class='mb-6 mt-3'>Nro de ha Forestadas por Departamento</h4>

            <p-table #forestationPerCityTable [value]='totalHaForestedPerProject' styleClass='p-datatable-gridlines'
                [loading]='loading' rowGroupMode='rowspan' groupRowsBy='dep'>
                <ng-template pTemplate='header'>
                    <tr>
                        <th class='bg-primary text-center' style='width: 50px'>Nº</th>
                        <th class='bg-primary text-center' style='width: 120px'>Departamento</th>
                        <th class='bg-primary text-center' style='width: 250px'>Entidad</th>
                        <th class='bg-primary text-center' style='width: 250px'>Nombre del proyecto</th>
                        <th class='bg-primary text-center' style='width: 150px'>Provincia</th>
                        <th class='bg-primary text-center' style='width: 150px'>Municipio</th>
                        <th class='bg-primary text-center' style='width: 200px'>MAE</th>
                        <th class='bg-primary text-center' style='width: 150px'>Total He Forestadas</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate='body' let-project let-ri='rowIndex' let-rowgroup='rowgroup'
                    let-rowspan='rowspan'>
                    <tr>
                        <td class='text-center overflow-hidden' style='width: 50px'>{{ ri + 1 }}</td>
                        <td *ngIf='rowgroup' [attr.rowspan]='rowspan' class='text-center overflow-hidden'
                            style='width: 120px'>
                            {{ project.iTCPRequest.entity?.town?.dep }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 250px'>
                            {{ project.iTCPRequest.entity?.nombre }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 250px'>
                            {{ project.name }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 150px'>
                            {{ project.iTCPRequest.entity?.town?.prov }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 150px'>
                            {{ project.iTCPRequest.entity?.town?.mun }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 200px'>
                            {{ project?.iTCPRequest?.seoName }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 250px'>
                            {{ project.totalForested }}
                        </td>
                    </tr>
                </ng-template>
            </p-table>

            <h4 class='mb-6 mt-3'>Nro de ha Reforestadas por Departamento</h4>

            <p-table #reforestationPerCityTable [value]='totalHaReforestedPerProject' styleClass='p-datatable-gridlines'
                [loading]='loading' rowGroupMode='rowspan' groupRowsBy='dep'>
                <ng-template pTemplate='header'>
                    <tr>
                        <th class='bg-primary text-center' style='width: 50px'>Nº</th>
                        <th class='bg-primary text-center' style='width: 120px'>Departamento</th>
                        <th class='bg-primary text-center' style='width: 250px'>Entidad</th>
                        <th class='bg-primary text-center' style='width: 250px'>Nombre del proyecto</th>
                        <th class='bg-primary text-center' style='width: 150px'>Provincia</th>
                        <th class='bg-primary text-center' style='width: 150px'>Municipio</th>
                        <th class='bg-primary text-center' style='width: 200px'>MAE</th>
                        <th class='bg-primary text-center' style='width: 150px'>Total He Reforestadas</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate='body' let-project let-ri='rowIndex' let-rowgroup='rowgroup'
                    let-rowspan='rowspan'>
                    <tr>
                        <td class='text-center overflow-hidden' style='width: 50px'>{{ ri + 1 }}</td>
                        <td *ngIf='rowgroup' [attr.rowspan]='rowspan' class='text-center overflow-hidden'
                            style='width: 120px'>
                            {{ project.iTCPRequest.entity?.town?.dep }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 250px'>
                            {{ project.iTCPRequest.entity?.nombre }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 250px'>
                            {{ project.name }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 150px'>
                            {{ project.iTCPRequest.entity?.town?.prov }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 150px'>
                            {{ project.iTCPRequest.entity?.town?.mun }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 200px'>
                            {{ project?.iTCPRequest?.seoName }}
                        </td>
                        <td class='text-center overflow-hidden' style='width: 250px'>
                            {{ project.totalReforested }}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-tabPanel>

    <p-tabPanel>
        <ng-template pTemplate='header'>
            <i class='pi pi-list'></i>
            <span class='ml-2'>Reporte Fisico / Financiero por Departamento</span>
        </ng-template>

        <div class='grid'>
            <div class='field lg:col-4 md:col-6 sm:col-12'>
                <button pButton type='button' label='Exportar a PDF' (click)='generateFSFNPerCityReportPDF()'
                    [disabled]='generatingPDF || !flattedFSGoalResultsPerProject.length'></button>
            </div>
        </div>

        <h4 *ngIf='flattedFSGoalResultsPerProject.length' class='mb-6 mt-3'>Reporte Fisico por Departamento</h4>

        <div *ngIf='flattedFSGoalResultsPerProject.length' class='container mt-3'>
            <p-table #dtFSCityGoalResultsTable [value]='flattedFSGoalResultsPerProject'
                styleClass='p-datatable-gridlines' [loading]='loading' rowGroupMode='rowspan' groupRowsBy='city'>
                <ng-template pTemplate='header'>
                    <tr>
                        <th class='bg-primary text-center' style='width: 100px'>Departamento</th>
                        <th class='bg-primary text-center' style='width: 100px'>Provincia</th>
                        <th class='bg-primary text-center' style='width: 100px'>Municipio</th>
                        <th class='bg-primary text-center' style='width: 250px'>Nombre del proyecto</th>
                        <th class='bg-primary text-center' style='width: 150px'>Componente</th>
                        <th class='bg-primary text-center' style='width: 150px'>Resultado o meta esperada</th>
                        <th class='bg-primary text-center' style='width: 200px'>Actividad</th>
                        <th class='bg-primary text-center' style='width: 100px'>Avance programado</th>
                        <th class='bg-primary text-center' style='width: 400px'>Avence Ejecutado</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate='body' let-goalResult let-ri='rowIndex' let-rowgroup='rowgroup'
                    let-rowspan='rowspan'>
                    <tr>
                        <td *ngIf='rowgroup' [attr.rowspan]='rowspan' class='text-center overflow-hidden'
                            style='width: 200px'>
                            {{ goalResult.city }}
                        </td>
                        <td style='width: 100px'>{{ goalResult.province }}</td>
                        <td style='width: 100px'>{{ goalResult.town }}</td>
                        <td style='width: 250px'>{{ goalResult.projectName }}</td>
                        <td style='width: 150px'>{{ goalResult.component }}</td>
                        <td style='width: 150px'>{{ goalResult.expectedResult }}</td>
                        <td style='width: 200px'>{{ goalResult.activity }}</td>
                        <td style='width: 100px'>{{ goalResult.generalExpected }}</td>
                        <td style='width: 300px'>
                            <div>
                                <div *ngFor='let monthDetail of goalResult.monthDetails'>
                                    <b>{{ monthDetail.startsDate | date: 'MMMM, y' }}</b><br />
                                    Ejecutado: <b>{{ monthDetail.executed }}</b><br />
                                    Esperado: <b>{{ monthDetail.expected }}</b><br /><br />
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <div *ngIf='flattedFNGoalResultsPerProject.length' class='container mt-3'>
            <h4 class='my-6'>Reporte Financiero por Departamento</h4>
            <p-table #dtFNCityGoalResultsTable [value]='flattedFNGoalResultsPerProject'
                styleClass='p-datatable-gridlines' [loading]='loading' rowGroupMode='rowspan' groupRowsBy='city'>
                <ng-template pTemplate='header'>
                    <tr>
                        <th class='bg-primary text-center' style='width: 100px'>Departamento</th>
                        <th class='bg-primary text-center' style='width: 100px'>Provincia</th>
                        <th class='bg-primary text-center' style='width: 100px'>Municipio</th>
                        <th class='bg-primary text-center' style='width: 250px'>Nombre del proyecto</th>
                        <th class='bg-primary text-center' style='width: 150px'>Componente</th>
                        <th class='bg-primary text-center' style='width: 150px'>Resultado o meta esperada</th>
                        <th class='bg-primary text-center' style='width: 200px'>Actividad</th>
                        <th class='bg-primary text-center' style='width: 200px'>Partida presupuestaria</th>
                        <th class='bg-primary text-center' style='width: 900px'>Presupuesto</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate='body' let-goalResult let-ri='rowIndex' let-rowgroup='rowgroup'
                    let-rowspan='rowspan'>
                    <tr>
                        <td *ngIf='rowgroup' [attr.rowspan]='rowspan' class='text-center overflow-hidden'
                            style='width: 200px'>
                            {{ goalResult.city }}
                        </td>
                        <td style='width: 100px'>{{ goalResult.province }}</td>
                        <td style='width: 100px'>{{ goalResult.town }}</td>
                        <td class='text-center overflow-hidden' style='width: 250px'>
                            {{ goalResult.projectName }}
                        </td>
                        <td style='width: 150px'>{{ goalResult.component }}</td>
                        <td style='width: 150px'>{{ goalResult.expectedResult }}</td>
                        <td style='width: 200px'>{{ goalResult.activity }}</td>
                        <td style='width: 200px'>
                            {{ goalResult.budgetItemCode + ' - ' + goalResult.budgetItemDetails }}
                        </td>
                        <td style='width: 900px'>
                            <div>
                                <div *ngFor='let monthDetail of goalResult.monthDetails'>
                                    <b>{{ monthDetail.startsDate | date: 'MMMM, y' }}</b><br />
                                    Presupuesto Entidad Programado: <b>{{ monthDetail.entity }}</b><br />
                                    Presupuesto Entidad Ejecutado: <b>{{ monthDetail.entityExecuted }}</b><br /><br />
                                    Presupuesto Entidad en especie Programado: <b>{{ monthDetail.entityInKind
                                        }}</b><br />
                                    Presupuesto Entidad en especie Ejecutado:
                                    <b>{{ monthDetail.entityInKindExpected }}</b><br /><br />
                                    Presupuesto Fonabosque Programado: <b>{{ monthDetail.owner }}</b><br />
                                    Presupuesto Fonabosque Ejecutado: <b>{{ monthDetail.ownerExpected }}</b><br /><br />
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-tabPanel>

    <p-tabPanel>
        <ng-template pTemplate='header'>
            <i class='pi pi-list'></i>
            <span class='ml-2'>Reporte Proyectos Reprogramados</span>
        </ng-template>

        <div class='grid'>
            <div class='field lg:col-4 md:col-6 sm:col-12'>
                <button pButton type='button' label='Exportar a PDF' (click)='generateReprogrammeddProjectsPDF()'
                    [disabled]='
                        generatingPDF || !(flattedFSGoalResultsPerReprogrammedProject.length || flattedFSGoalResultsPerReprogrammedProject.length)
                    '></button>
            </div>
        </div>


        <div *ngIf='flattedFSGoalResultsPerReprogrammedProject.length' class='container mt-3'>
            <h4 class='mb-6 mt-3'>Reporte Fisico de entidades ejecutoras con proyectos reprogramados</h4>

            <p-table #dtFSReprogrammedGoalResultsTable [value]='flattedFSGoalResultsPerReprogrammedProject'
                styleClass='p-datatable-gridlines' [loading]='loading' rowGroupMode='rowspan' groupRowsBy='projectName'>
                <ng-template pTemplate='header'>
                    <tr>
                        <th class='bg-primary text-center' style='width: 250px'>Nombre del proyecto</th>
                        <th class='bg-primary text-center' style='width: 150px'>Componente</th>
                        <th class='bg-primary text-center' style='width: 150px'>Resultado o meta esperada</th>
                        <th class='bg-primary text-center' style='width: 200px'>Actividad</th>
                        <th class='bg-primary text-center' style='width: 100px'>Avance programado</th>
                        <th class='bg-primary text-center' style='width: 400px'>Avence Ejecutado</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate='body' let-goalResult let-ri='rowIndex' let-rowgroup='rowgroup'
                    let-rowspan='rowspan'>
                    <tr>
                        <td *ngIf='rowgroup' [attr.rowspan]='rowspan' class='text-center overflow-hidden'
                            style='width: 200px'>
                            {{ goalResult.projectName }}
                        </td>
                        <td style='width: 150px'>{{ goalResult.component }}</td>
                        <td style='width: 150px'>{{ goalResult.expectedResult }}</td>
                        <td style='width: 200px'>{{ goalResult.activity }}</td>
                        <td style='width: 100px'>{{ goalResult.generalExpected }}</td>
                        <td style='width: 300px'>
                            <div>
                                <div *ngFor='let monthDetail of goalResult.monthDetails'>
                                    <b>{{ monthDetail.startsDate | date: 'MMMM, y' }}</b><br />
                                    Ejecutado: <b>{{ monthDetail.executed }}</b><br />
                                    Esperado: <b>{{ monthDetail.expected }}</b><br /><br />
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div *ngIf='flattedFNGoalResultsPerReprogrammedProject.length' class='container mt-3'>
            <h4 class='my-6'>Reporte Financiero de entidades ejecutoras con proyectos reprogramados</h4>
            <p-table #dtFNReprogrammedGoalResultsTable [value]='flattedFNGoalResultsPerReprogrammedProject'
                styleClass='p-datatable-gridlines' [loading]='loading' rowGroupMode='rowspan' groupRowsBy='projectName'>
                <ng-template pTemplate='header'>
                    <tr>
                        <th class='bg-primary text-center' style='width: 250px'>Nombre del proyecto</th>
                        <th class='bg-primary text-center' style='width: 150px'>Componente</th>
                        <th class='bg-primary text-center' style='width: 150px'>Resultado o meta esperada</th>
                        <th class='bg-primary text-center' style='width: 200px'>Actividad</th>
                        <th class='bg-primary text-center' style='width: 200px'>Partida presupuestaria</th>
                        <th class='bg-primary text-center' style='width: 900px'>Presupuesto</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate='body' let-goalResult let-ri='rowIndex' let-rowgroup='rowgroup'
                    let-rowspan='rowspan'>
                    <tr>
                        <td *ngIf='rowgroup' [attr.rowspan]='rowspan' class='text-center overflow-hidden'
                            style='width: 200px'>
                            {{ goalResult.projectName }}
                        </td>
                        <td style='width: 150px'>{{ goalResult.component }}</td>
                        <td style='width: 150px'>{{ goalResult.expectedResult }}</td>
                        <td style='width: 200px'>{{ goalResult.activity }}</td>
                        <td style='width: 200px'>
                            {{ goalResult.budgetItemCode + ' - ' + goalResult.budgetItemDetails }}
                        </td>
                        <td style='width: 900px'>
                            <div>
                                <div *ngFor='let monthDetail of goalResult.monthDetails'>
                                    <b>{{ monthDetail.startsDate | date: 'MMMM, y' }}</b><br />
                                    Presupuesto Entidad Programado: <b>{{ monthDetail.entity }}</b><br />
                                    Presupuesto Entidad Ejecutado: <b>{{ monthDetail.entityExecuted }}</b><br /><br />
                                    Presupuesto Entidad en especie Programado: <b>{{ monthDetail.entityInKind
                                        }}</b><br />
                                    Presupuesto Entidad en especie Ejecutado:
                                    <b>{{ monthDetail.entityInKindExpected }}</b><br /><br />
                                    Presupuesto Fonabosque Programado: <b>{{ monthDetail.owner }}</b><br />
                                    Presupuesto Fonabosque Ejecutado: <b>{{ monthDetail.ownerExpected }}</b><br /><br />
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-tabPanel>
</p-tabView>
