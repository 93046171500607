import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProjectProgrammerModel } from '../../../models';
import { Table } from 'primeng/table';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../../itcp';
import { CryptoService, SettingsService } from '../../../../shared';
import { FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { IProjectGoalResult, ProjectGoalResultService } from '../../../../edtp';
import { LayoutService } from '../../../../shared/services/app.layout.service';

@Component({
    selector: 'app-programmer-activities',
    templateUrl: './activities.component.html',
    styleUrls: ['./activities.component.scss'],
})
export class ProgrammerActivitiesComponent extends ProjectProgrammerModel implements OnInit, OnDestroy {
    private step: number = 2;

    results: IProjectGoalResult[] = [];
    @ViewChild('dt') table!: Table;

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly goalResultService: ProjectGoalResultService,
        private readonly setService: SettingsService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private crypto: CryptoService,
        private readonly layoutService: LayoutService
    ) {
        super(activatedRoute, projectService, tService, setService, crypto);
    }

    ngOnInit(): void {
        this.layoutService.showMenuDesktop(false);
        this.readParams();
        this.setActive(this.step);
        this.service
            .get(this.params['id'])
            .then((project) => {
                const { id, name } = project || {};
                this.currentProject = { ...project };
                this.formGroup = this.fb.group({
                    id: [id],
                    name: [name, Validators.required],
                });
            })
            .finally(() => {
                this.disableFormIfSubmitted();
                this.calculateYears();
                this.initYearOptions();
                this.selectedOptionYear = 'YEARLY';
                this.generateMonthlyOptions();
                this.loadActivities();
                this.showContent = true;
            });
    }

    loadActivities() {
        const projectId = this.currentProject?.id || 0;
        this.goalResultService
            .getAll({
                params: { projectId },
            })
            .then((resp) => {
                this.results = [...resp];
                this.setCurrentMonthByActivity(this.results);
            });
    }

    onChangeSelectedProjectYear(event: any) {
        this.loadActivities();
        this.generateMonthlyOptions();
    }

    ngOnDestroy() {
        this.cleanParams();
    }
}
