import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {IVisit, VisitType} from "../../models";
import {Constants} from "../../../shared/constants";
import {ConfirmationService, MenuItem} from "primeng/api";
import {Table} from "primeng/table";
import {Subject, takeUntil} from "rxjs";
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {GenericFunctions} from "../../../shared/services/generic-functions";
import {ToasterService} from "../../../shared/services/toaster.service";
import {VisitService} from "../../services";
import {User} from "../../../core/user/interfaces";
import {VisitFormModalComponent} from "../form-modal/modal.component";

@Component({
    selector: 'app-visits',
    templateUrl: './visits.component.html',
    styleUrls: ['./visits.component.scss']
})
export class VisitsComponent implements OnInit {

    visitType: VisitType = "FORESTATION_REFORESTATION";
    public loading = false;
    public data: IVisit[] = [];
    public resource = Constants.RESOURCES.VISITS_MENU;
    public breadcrumbs: MenuItem[] = [
        {label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home']},
        {label: ' Visitas In-Situ'},
    ];

    @ViewChild('dt') table!: Table;
    private onDestroy$ = new Subject();
    public ref!: DynamicDialogRef;
    private modal: DynamicDialogConfig<IVisit> = {
        ...Constants.MODAL.default,
        contentStyle: {
            overflow: 'visible'
        },
    };

    constructor(
        private router: Router,
        private readonly genericFunctions: GenericFunctions,
        private readonly service: VisitService,
        public readonly dialogService: DialogService,
        public readonly confirmationService: ConfirmationService,
        private readonly toasterService: ToasterService,
    ) {
    }

    ngOnInit(): void {
        const vType = this.router.url.replace('/visits/', '');
        switch (vType) {
            case 'forestation':
                this.visitType = 'FORESTATION_REFORESTATION';
                this.resource = Constants.RESOURCES.VISITS_FORESTATION_REFORESTATION;
                break;
            case 'infrastructure':
                this.visitType = 'INFRASTRUCTURE';
                this.resource = Constants.RESOURCES.VISITS_INFRASTRUCTURE;
                break;
            case 'production':
                this.visitType = 'PRODUCTION';
                this.resource = Constants.RESOURCES.VISITS_PRODUCTION;
                break;
        }

        this.load();
    }

    load(): void {
        this.loading = true;
        this.service
            .getAll({ params: { type: this.visitType }})
            .then((response) => {
                this.data = [...response];
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => (this.loading = false));
    }

    setFilter(event: any, type: string, query: string, column: string = ''): void {
        if (type === 'filterGlobal') {
            this.table.filterGlobal(event.target.value, query);
        } else if (type === 'filter') {
            this.table.filter(event.target.value, column, query);
        }
    }

    selectedItem(data: IVisit, action: string) {
        const id = data?.id || 0;
        switch (action) {
            case 'delete':
                this.confirmationService.confirm({
                    message: '¿Se encuentra seguro de eliminar este registro?',
                    accept: () => {
                        this.service
                            .delete(id)
                            .then((resp: boolean) => {
                                if (resp) {
                                    this.toasterService.success('El registro fué eliminado con éxito.');
                                    this.load();
                                }
                            })
                            .catch((err) => {
                                console.error(err);
                                this.toasterService.error(
                                    'Se ha producido un error inténtelo más tarde o contáctese con el Administrador.'
                                );
                            });
                    },
                });
                break;
            case 'edit':
                this.modal.data = data;
                this.modal.header = 'Actualizar Visita';
                this.ref = this.dialogService.open(VisitFormModalComponent, this.modal);
                this.ref.onClose.pipe(takeUntil(this.onDestroy$)).subscribe((resp) => {
                    if (resp) this.load();
                });
                break;
            default:
                break;
        }
    }

    addNew() {
        this.modal.header = 'Nueva Visita';
        this.modal.data = {
            act: undefined,
            actId: 0,
            id: 0,
            project: undefined,
            projectId: 0,
            user: undefined,
            userId: 0,
            visitDate: new Date(),
            visitStatus: 'PROGRAMMED',
            visitType: this.visitType,
            description: '',

        };
        this.ref = this.dialogService.open(VisitFormModalComponent, this.modal);
        this.ref.onClose.pipe(takeUntil(this.onDestroy$)).subscribe((resp) => {
            if (resp) this.load();
        });
    }


    getFullName(user: User): string {
        return GenericFunctions.getUserFullName(user);
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(undefined);
        this.onDestroy$.complete();
    }
}
