import { Injectable } from '@angular/core';
import get from 'lodash-es/get';
import * as CryptoJS from 'crypto-js';
import { Permissions } from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class CredentialsService {
    constructor() {}

    /**
     * Get current token from session storage
     *
     * @returns {string}
     * @memberof CredentialsService
     */
    public getToken(): string {
        return sessionStorage.getItem('auth_token') || '';
    }

    /**
     * Get Logged user credentials from session storafe
     *
     * @returns {object}
     * @memberof CredentialsService
     */
    public getLoggedUser(): object {
        try {
            return JSON.parse(sessionStorage.getItem('user') || '');
        } catch (error) {
            return {};
        }
    }

    /**
     * Set User Credentials to session storage
     *
     * @template T
     * @param {T} payload
     * @memberof CredentialsService
     */
    public setUser<T>(payload: T): void {
        sessionStorage.setItem('auth_token', get(payload, 'token', null));
        sessionStorage.setItem('user', JSON.stringify(payload));
    }

    /**
     * Set Creds of application
     *
     * @param {string} payload
     * @memberof CredentialsService
     */
    public setCreds(payload: string): void {
        sessionStorage.setItem('config_creds', get(payload, 'data', null));
    }

    /**
     *Get creds of application
     *
     * @return {*}  {object}
     * @memberof CredentialsService
     */
    public getCreds(): Permissions {
        try {
            const secret = this.getToken().slice(0, 32);
            let _key = CryptoJS.enc.Utf8.parse(secret);
            let _iv = CryptoJS.enc.Utf8.parse(secret);
            const creds = sessionStorage.getItem('config_creds');
            const decrypted = CryptoJS.AES.decrypt(creds as string, _key, {
                keySize: 32,
                iv: _iv,
                mode: CryptoJS.mode.CTR,
                padding: CryptoJS.pad.Pkcs7,
            }).toString(CryptoJS.enc.Utf8);
            return JSON.parse(decrypted);
        } catch (error) {
            return {
                NONE: 0,
            };
        }
    }

    /**
     *
     *
     * @memberof CredentialsService
     */
    public removeUser(): void {
        sessionStorage.removeItem('auth_token');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('config_creds');
    }
}
