<div class="card border-0">
    <p-skeleton *ngIf="!showForm" width="100%" height="150px"></p-skeleton>
    <form *ngIf="showForm" class="form-signin" [formGroup]="formGroup">
        <div class="grid">
            <div class="field lg:col-12 md:col-12 sm:col-12" *ngFor="let item of verificationKeys">
                <label>{{item.label}}</label>
                <fieldset class='flex justify-content-around align-items-center'>
                    <div class='text-left w-full'>
                        <div *ngIf="item.fields.includes('seoName')" class='pb-3'>
                            <b>Nombre</b>
                            <p>{{ config.data.seoName }}</p>
                        </div>
                        <div *ngIf="item.fields.includes('seoIdentityFile')" class='pb-3'>
                            <b>Documento de Identidad</b>
                            <p>
                                <a [href]="downloadFileUrl(seoIdentifyFile?.key)" [download]="seoIdentifyFile?.filename"
                                    target="_blank">
                                    {{ seoIdentifyFile?.filename }}
                                </a>
                            </p>
                        </div>
                        <div *ngIf="item.fields.includes('seoCellPhone')" class='pb-3'>
                            <b>Celular</b>
                            <p>{{ config.data.seoCellPhone }}</p>
                        </div>

                        <div *ngIf="item.fields.includes('seoJobtitle')" class='pb-3'>
                            <b>Cargo</b>
                            <p>{{ config.data.seoJobtitle }}</p>
                        </div>

                        <div *ngIf="item.fields.includes('seoMemoFile')" class='pb-3'>
                            <b>Documento de Designación</b>
                            <p>
                                <a [href]="downloadFileUrl(seoMemoFile?.key)" [download]="seoMemoFile?.filename"
                                    target="_blank">
                                    {{ seoMemoFile?.filename }}
                                </a>
                            </p>
                        </div>

                        <div *ngIf="item.fields.includes('leadName')" class='pb-3'>
                            <b>Nombre</b>
                            <p>{{ config.data.leadName }}</p>
                        </div>
                        <div *ngIf="item.fields.includes('leadCellPhone')" class='pb-3'>
                            <b>Celular</b>
                            <p>{{ config.data.leadCellPhone }}</p>
                        </div>
                        <div *ngIf="item.fields.includes('leadEmail')" class='pb-3'>
                            <b>Correo Electrónico</b>
                            <p>{{ config.data.leadEmail }}</p>
                        </div>
                        <div *ngIf="item.fields.includes('leadJobtitle')" class='pb-3'>
                            <b>Cargo</b>
                            <p>{{ config.data.leadJobtitle }}</p>
                        </div>

                        <div *ngIf="item.fields.includes('leadMemoFile')" class='pb-3'>
                            <b>Documento de Designación</b>
                            <p>
                                <a [href]="downloadFileUrl(leadMemoFile?.key)" [download]="leadMemoFile?.filename"
                                    target="_blank">
                                    {{ leadMemoFile?.filename }}
                                </a>
                            </p>
                        </div>
                        <div *ngIf="item.fields.includes('contactEmail')" class='pb-3'>
                            <b>Correo Electrónico</b>
                            <p>{{ config.data.contactEmail }}</p>
                        </div>
                    </div>

                    <div class='text-center px-6'>
                        <label class='font-semibold'>Verificado</label>
                        <div class='mt-2'>
                            <p-inputSwitch [ngModel]="item.value" [ngModelOptions]="{standalone: true}"
                                (onChange)="onChange($event, item.key)">

                            </p-inputSwitch>
                        </div>
                    </div>
                </fieldset>

            </div>

        </div>
    </form>
</div>
<div class="p-dialog-footer">
    <button pButton type="button" label="Cerrar" class="p-button-secondary" (click)="closeModal()"></button>
</div>
