import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IProjectBaseLine, ProjectEdtpModel} from "../../../../models";
import {GroupedComponents, ProjectBaseLineService, TypologyType} from "../../../../services";
import {CryptoService, ISettings, SettingsService} from "../../../../../shared";
import {Table} from "primeng/table";
import {ActivatedRoute} from "@angular/router";
import {ProjectService} from "../../../../../itcp";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToasterService} from "../../../../../shared/services/toaster.service";
import {Constants} from "../../../../../shared/constants";

@Component({
  selector: 'app-t-iii',
  templateUrl: './t-iii.component.html',
  styleUrls: ['./t-iii.component.scss']
})
export class LineTIIIComponent extends ProjectEdtpModel implements OnInit, OnDestroy {

    private step: number = 3;

    clonedBaseLines: { [s: string]: IProjectBaseLine; } = {};

    @ViewChild('dtIII') tableIII!: Table;

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly baseLineService: ProjectBaseLineService,
        private readonly setService: SettingsService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private crypto: CryptoService,
    ) {
        super(activatedRoute, projectService, tService, setService, crypto);
    }

    ngOnInit(): void {
        this.readParams();
        this.setActive(this.step);
        this.service.get(this.params['id']).then((project) => {
            const {id, name} = project || {};
            this.currentProject = {...project};
            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required]
            });
            return this.loadComponents(this.componentsForTypologyIII());
        }).finally(() => {
            this.setBaseLines();
            this.setSteps();
            this.setActive(this.step); //We need to reload the steps and put as active the current step
            this.disableFormIfSubmitted();
            this.showContent = true;
        });

    }

    setBaseLines() {
        if (!this.currentProject?.baseLines?.length) return;
        this.baseLines = this.currentProject.baseLines.filter(bl => bl.typology === Constants.TYPOLOGIES.TYPOLOGY_III);
    }

    onRowEditInit(obj: any) {
        if (!obj?.id) return;
        this.clonedBaseLines[obj.id] = {...obj};
    }

    onRowEditSave(obj: IProjectBaseLine) {
        if (!obj?.id) return;

        const baseLineForm: FormGroup = this.fb.group({
            projectId: [obj.projectId, Validators.required],
            component: [obj.component, Validators.required],
            quantity: [obj.quantity, [Validators.required, Validators.min(0)]],
            uom: [obj.uom, Validators.required],
            typology: [obj.typology, Validators.required],
        });


        if (baseLineForm.valid) {
            delete this.clonedBaseLines[obj.id];

            if (obj?.id.toString().includes('new_')) {
                const index = this.baseLines.indexOf(obj);
                this.baseLineService.store(baseLineForm.value).then((resp) => {
                    this.baseLines[index] = resp;
                    this.toasterService.success('La Línea Base fue creada satisfactoriamente.');
                });

            } else {
                const id = parseInt(obj.id.toString());
                this.baseLineService.update(baseLineForm.value, id).then((resp) => {
                    this.toasterService.success('La Línea Base fue actualizada satisfactoriamente.');
                })
            }

        } else {
            this.toasterService.error('No se pudo guardar, los datos son inválidos!');
        }

    }

    onRowEditCancel(obj: IProjectBaseLine, index: number) {
        if (!obj?.id) return;

        if (this.clonedBaseLines[obj.id]) {
            this.baseLines[index] = this.clonedBaseLines[obj.id];
            delete this.clonedBaseLines[obj.id];
        }

        if (obj?.id.toString().includes('new_')) {
            this.baseLines.pop();
        }
    }

    onNew(typology: TypologyType) {
        const bl: IProjectBaseLine = {
            component: '',
            quantity: 0,
            uom: '',
            projectId: parseInt(this.params['id']),
            typology,
            id: `new_${new Date().getTime()}`
        }

        this.baseLines.push(bl);
        this.tableIII.initRowEdit(this.tableIII.value[this.baseLines.length - 1]);
    }

    onSelectComponent(event: any, obj: IProjectBaseLine) {
        const group: any = this.components.find(c => {
            return c.items.find(i => i.value === event.value)
        });

        const uom = this.unitsOfMeasure.find(uom => uom.key === group.value);
        if (uom) obj.uom = uom.value;
    }

    ngOnDestroy() {
        this.cleanParams();
    }

}
