export * from './project-stages.enum';

export enum ProjectBudgetEnum {
    GENERAL= 'GENERAL',
    COMPONENTS = 'COMPONENTS',
    VIPFE = 'VIPFE'
}

export enum ComplianceGroupEnum {
    COMPLIANCE= 'COMPLIANCE',
    QUANTITATIVE_ASSESSMENT = 'QUANTITATIVE_ASSESSMENT',
}
