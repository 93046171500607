import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {
    IProjectGoalActivity,
    IProjectGoalResult,
    ProgrammerType,
    ProjectGoalActivityService
} from "../../../../../edtp";
import {IProjectGoal} from "../../../../../itcp";
import {Table} from "primeng/table";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToasterService} from "../../../../../shared/services/toaster.service";

@Component({
    selector: 'app-result-fn-activities',
    templateUrl: './activities.component.html',
    styleUrls: ['./activities.component.scss']
})
export class ResultFNActivitiesComponent implements OnInit, OnChanges {
    @Input() group!: IProjectGoalResult;
    @Input() goals!: IProjectGoal[];
    @Input()  totalProjectDays: number = 0;
    @Input() selectedYear!: number;
    @Input() canProgram: boolean = false;

    cloned: { [s: string]: IProjectGoalActivity; } = {};
    selectedGroup!: IProjectGoal;

    @ViewChild('dt') table!: Table;

    constructor(
        private readonly activityService: ProjectGoalActivityService,
        private fb: FormBuilder,
        private toasterService: ToasterService,
    ) {
    }

    ngOnInit(): void {
        if (!this.group.activities)
            this.group.activities = [];
    }

    onRowEditInit(obj: IProjectGoalActivity) {
        if (!obj?.id) return;
        this.cloned[obj.id] = {...obj};
    }

    getForm(obj: IProjectGoalActivity) {
        if (!obj) return;
        const form: FormGroup = this.fb.group({
            projectId: [obj.projectId, Validators.required],
            resultId: [obj.resultId, Validators.required],
            activity: [obj.activity, Validators.required],
            indicator: [obj.indicator, Validators.required],
            uom: [obj.uom, Validators.required],
            verification: [obj.verification, Validators.required],
            weeksDuration: [obj.weeksDuration, Validators.required],
            programmerType: [obj.programmerType, Validators.required],
            generalExpected: [obj.generalExpected || 0, Validators.required],
            expectedYear: [obj.expectedYear || 0, Validators.required],
            year: [this.selectedYear, Validators.required],
        });
        return form;
    }

    onRowEditSave(obj: IProjectGoalActivity) {
        if (!obj?.id) return;

        const form = this.getForm(obj);
        if (form?.valid) {
            delete this.cloned[obj.id];
            const index = this.group.activities.indexOf(obj);
            if (obj?.id.toString().includes('new_')) {
                this.activityService.store(form.value).then((resp) => {
                    this.group.activities[index] = {
                        ...this.group.activities[index],
                        ...resp,
                    };
                    this.toasterService.success('El Registro fue creado satisfactoriamente.');
                });

            } else {
                const id = parseInt(obj.id.toString());
                this.activityService.update(form.value, id).then((resp) => {
                    this.group.activities[index] = {
                        ...this.group.activities[index],
                        ...resp,
                    };
                    this.toasterService.success('El Registro fue actualizado satisfactoriamente.');
                })
            }
        } else {
            this.toasterService.error('No se pudo guardar, los datos son inválidos!');
        }
    }

    onRowEditCancel(obj: IProjectGoalActivity, index: number) {
        if (!obj?.id) return;

        if (this.cloned[obj.id]) {
            this.group.activities[index] = this.cloned[obj.id];
            delete this.cloned[obj.id];
        }

        if (obj?.id.toString().includes('new_')) {
            this.group.activities = this.group.activities.filter(o => o.id !== obj.id);
        }
    }

    onNew() {
        const ob: IProjectGoalActivity = {
            activity: '',
            id: `new_${new Date().getTime()}`,
            projectId: this.group.projectId,
            resultId: this.group.id ? parseInt(this.group.id.toString()) : 0,
            weeksDuration: 0,
            programmerType: 'FN',
            generalExpected: 0,
            expectedYear: 0,
            year: this.selectedYear,
        }

        this.group.activities.push(ob);
        this.table.initRowEdit(this.table.value[this.group.activities.length - 1]);
    }

    onSelectItem(event: any) {
        const item = this.goals.find(c => c.id === event.value);
        if (item) this.selectedGroup = { ...item };
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['selectedYear']) {
            this.group.activities.map(act => {
                if (act.yearsProgrammed?.length) {
                    act.expectedYear = 0;
                    for (const yp of act.yearsProgrammed) {
                        if (yp.year === this.selectedYear){
                            act.expectedYear = yp.expected;
                            break;
                        }
                    }
                }
            });
        }
    }
}
