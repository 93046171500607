import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IProjectComplianceGroup } from '../../../../models';
import { ComplianceGroupEnum } from '../../../../../shared';
import { ProjectComplianceGroupService } from '../../../../services';
import { Table } from 'primeng/table';
import { PdfBuilder } from 'src/app/shared/lib/pdf-builder/pdf-builder';

const MINIMUM_ACCEPTED = 0.51; //51%

@Component({
    selector: 'app-weighting-content',
    templateUrl: './weighting-content.component.html',
    styleUrls: ['./weighting-content.component.scss'],
})
export class WeightingContentComponent implements OnInit {
    @Input() projectId!: number | undefined;
    @Output() onScoreChanged = new EventEmitter<any>();
    @ViewChild('dtTable') table!: Table;
    groups: IProjectComplianceGroup[] = [];
    totals = { weight: 0, score: 0, maxWeighted: 0, minWeighted: 0 };
    generatingReport = false;

    constructor(private readonly complianceGroupService: ProjectComplianceGroupService) {}

    ngOnInit(): void {
        this.load();
        this.resetTotals();
    }

    resetTotals() {
        this.totals = { weight: 0, score: 0, maxWeighted: 0, minWeighted: 0 };
    }

    load() {
        const projectId = this.projectId;
        if (!projectId) return;
        this.complianceGroupService
            .getAll({ params: { projectId, type: ComplianceGroupEnum.QUANTITATIVE_ASSESSMENT } })
            .then((resp) => {
                this.groups = [...resp];
            })
            .finally(() => {
                this.setAccumTotals();
            });
    }

    setAccumTotals() {
        this.resetTotals();
        this.groups.map((g) => {
            g.scoreAccum = 0;
            g.maxScoreAccum = 0;
            g.assessments?.map((child) => {
                // @ts-ignore
                g.weight += child.weight;
                // @ts-ignore
                g.maxScoreAccum += child.maxScore;
                // @ts-ignore
                g.scoreAccum += child.score;
            });
            g.minimumAccepted = MINIMUM_ACCEPTED * g.maxScoreAccum;
            // @ts-ignore
            this.totals.weight += g.weight;
            // @ts-ignore
            this.totals.score += g.scoreAccum;
            // @ts-ignore
            this.totals.maxWeighted += g.maxScoreAccum;
            // @ts-ignore
            this.totals.minWeighted += g.minimumAccepted;
        });

        this.onScoreChanged.emit(this.totals.score);
    }

    generateReport() {
        this.generatingReport = true;
        setTimeout(async () => {
            const pdf = new PdfBuilder('Reporte de Evaluacion', 'EDTP');
            const tableElement = this.table.tableViewChild.nativeElement;

            await pdf.addTable(tableElement);

            pdf.generate('fonabosque_reporte_evaluacion_edtp');
            this.generatingReport = false;
        }, 10);
    }
}
