import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IAnnouncement, IProject, ITCPRequestService, ProjectService } from '../../../itcp';
import { Constants } from '../../../shared/constants';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CartographyService, ICity, ProjectFlow } from '../../../shared';
import { PdfBuilder } from '../../../shared/lib/pdf-builder/pdf-builder';

const ProjectStatusesMapped: any = {
    APPROVED: 'Aprobado', //ITCP, EDTP user decides if project approves
    REJECTED: 'Rechazado' //ITCP, EDTP user decides if project is rejected
};

@Component({
    selector: 'app-itcp',
    templateUrl: './itcp.component.html',
    styleUrls: ['./itcp.component.scss']
})
export class ItcpComponent implements OnInit {
    loading = false;
    projectStatuses: any = { ...ProjectStatusesMapped };
    announcements: IAnnouncement[] = [];
    private _approvedRejectedProjects: IProject[] = [];
    private _projects: IProject[] = [];
    cities: ICity[] = [];
    towns: any[] = [];
    showData = false;
    totalApproved: number = 0;
    totalRejected: number = 0;
    flattedDataPerTown: any[] = [];
    filteredFlattedData: any[] = [];
    totalOwnerCost: number = 0;
    totalEntityCost: number = 0;
    totalOwnerEntityCost: number = 0;
    stackedOptions: any = {};
    approvedRejectedChartData: any = {};
    beneficiariesChartData: any = {};
    financialChartData: any = {};
    totalMenBeneficiaries = 0;
    totalWomenBeneficiaries = 0;
    totalBeneficiaries = 0;
    totalFilteredMenBeneficiaries = 0;
    totalFilteredWomenBeneficiaries = 0;
    totalFilteredBeneficiaries = 0;
    generatingPDF = false;
    public resource = Constants.RESOURCES.PROJECTS;
    private onDestroy$ = new Subject();
    public projectForm!: FormGroup;
    filterForm!: FormGroup;
    public breadcrumbs: MenuItem[] = [
        { label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home'] },
        { label: ' Reportes' }
    ];
    @ViewChild('dtTable') table!: Table;
    @ViewChild('dtApprovedRejectedTable') approvedRejectedTable!: Table;
    @ViewChild('dtSpecific') specificTable!: Table;
    @ViewChild('dtTableAR') tableAR!: Table;
    @ViewChild('chartPDFRef') chartPDFRef!: ElementRef;
    @ViewChild('budgetBarChartPDF') budgetBarChartPDF!: ElementRef;
    @ViewChild('benefitedBarChartPDF') benefitedBarChartPDF!: ElementRef;
    cityDropdownSettings: IDropdownSettings = {};
    townsDropdownSettings: IDropdownSettings = {};
    barChartOptions: any = {};

    constructor(
        private fb: FormBuilder,
        private readonly itcpRequestService: ITCPRequestService,
        private service: ProjectService,
        private cartographyService: CartographyService
    ) {
    }

    get projects(): IProject[] {
        return this._projects;
    }

    set projects(value: IProject[]) {
        this._projects = value;
    }

    get approvedRejectedProjects(): IProject[] {
        return this._approvedRejectedProjects;
    }

    set approvedRejectedProjects(value: IProject[]) {
        this.showData = false;
        this.flattedDataPerTown = [];
        this.filteredFlattedData = [];
        this.totalOwnerCost = 0;
        this.totalEntityCost = 0;
        this.totalOwnerEntityCost = 0;
        this.totalApproved = 0;
        this.totalRejected = 0;
        this.totalMenBeneficiaries = 0;
        this.totalWomenBeneficiaries = 0;
        this.totalBeneficiaries = 0;
        this._approvedRejectedProjects = value.map((project) => {
            return this.setProjectProperties(project) as any;
        });

        this.filteredFlattedData = this.flattedDataPerTown;
        this.totalFilteredMenBeneficiaries = this.totalMenBeneficiaries;
        this.totalFilteredWomenBeneficiaries = this.totalWomenBeneficiaries;
        this.totalFilteredBeneficiaries = this.totalBeneficiaries;
        this.sortDataPerTown();
        this.setApprovedRejectedProjectsChartData();
        this.showData = true;
    }

    private setProjectProperties(project: IProject) {
        const {
            iTCPRequest: {
                entity: { city, town, province }
            },
            budgets = [],
            beneficiaries = [],
            status
        } = project as any;

        let totalBeneficiaries;
        let menBeneficiary;
        let womenBeneficiary;
        let budget: any = {};

        menBeneficiary = beneficiaries.reduce((a: any, b: any) => {
            return a + b.men;
        }, 0);
        womenBeneficiary = beneficiaries.reduce((a: any, b: any) => {
            return a + b.women;
        }, 0);
        totalBeneficiaries = menBeneficiary + womenBeneficiary;
        budget = budgets.length ? budgets[0] : ({} as any);
        if (this.isProjectApproved(project)) this.totalApproved++;
        else this.totalRejected++;

        const parsedTownData = {
            cityName: city?.dep,
            provinceName: province?.prov,
            townName: town?.mun,
            ownerAmount: budget?.ownerAmount,
            entityAmount: budget.entityAmount,
            totalAmount: (budget.ownerAmount || 0) + (budget.entityAmount || 0),
            menBeneficiary,
            womenBeneficiary,
            totalBeneficiaries
        };

        this.totalMenBeneficiaries += menBeneficiary;
        this.totalWomenBeneficiaries += womenBeneficiary;
        this.totalBeneficiaries += totalBeneficiaries;
        this.totalOwnerCost = this.totalOwnerCost + (budget?.ownerAmount || 0);
        this.totalEntityCost = this.totalEntityCost + (budget?.entityAmount || 0);
        this.totalOwnerEntityCost =
            this.totalOwnerEntityCost + (budget?.ownerAmount || 0) + (budget?.entityAmount || 0);

        this.flattedDataPerTown.push(parsedTownData);

        return { ...project, menBeneficiary, womenBeneficiary, totalBeneficiaries, budget };
    }

    getProjectStatus(project: IProject) {
        return this.isProjectApproved(project) ? 'Aprobado' : 'Rechazado';
    }

    private isProjectApproved(project: IProject) {
        return [...ProjectFlow.edtpStatusesForAssigned(), Constants.PROJECT_STATUSES.APPROVED]
            .includes(project.status as any);
    }

    sortDataPerTown() {
        this.flattedDataPerTown.sort((a: any, b: any) => a.cityName.localeCompare(b.cityName));
        const townNames = this.flattedDataPerTown.map((town: any) => town.townName);
        townNames.sort((a: any, b: any) => a.localeCompare(b));
        this.towns = [...new Set(townNames)].map((t) => ({ name: t }));
    }

    setApprovedRejectedProjectsChartData() {
        this.approvedRejectedChartData = {
            labels: ['Aprobado', 'Deshabilitado'],
            datasets: [
                {
                    label: 'Reporte ITCP Aprobados - Deshabilitados',
                    data: [this.totalApproved, this.totalRejected],
                    backgroundColor: ['rgb(14,72,25)', 'rgb(60,140,64)'],
                    hoverOffset: 4
                }
            ]
        };
        const ownerAmounts: any[] = [];
        const entityAmounts: any[] = [];
        const menBeneficiaries: any[] = [];
        const womenBeneficiaries: any[] = [];
        const labels: any[] = [];
        this.towns.forEach(({ name: town }) => {
            const relevantData = this.filteredFlattedData.filter((d) => d.townName === town);
            const filteredTownNames = this.filteredFlattedData.map((f) => f.townName);
            let ownerAmount = 0;
            let entityAmount = 0;
            let menBeneficiary = 0;
            let womenBeneficiary = 0;
            relevantData.forEach((data) => {
                ownerAmount = ownerAmount + (data.ownerAmount || 0);
                entityAmount = entityAmount + (data.entityAmount || 0);
                menBeneficiary += data.menBeneficiary;
                womenBeneficiary += data.womenBeneficiary;
            });
            if (filteredTownNames.includes(town)) {
                labels.push(town);
                ownerAmounts.push(ownerAmount);
                entityAmounts.push(entityAmount);
                menBeneficiaries.push(menBeneficiary);
                womenBeneficiaries.push(womenBeneficiary);
            }
        });

        this.financialChartData = {
            labels: labels,
            datasets: [
                {
                    type: 'bar',
                    label: 'Presupuesto FONABOSQUE',
                    backgroundColor: '#42A5F5',
                    data: ownerAmounts
                },
                {
                    type: 'bar',
                    label: 'Presupuesto ENTIDAD',
                    backgroundColor: '#09144f',
                    data: entityAmounts
                }
            ]
        };

        this.beneficiariesChartData = {
            labels: labels,
            datasets: [
                {
                    type: 'bar',
                    label: 'Beneficiarios Varones',
                    backgroundColor: '#42A5F5',
                    data: menBeneficiaries
                },
                {
                    type: 'bar',
                    label: 'Beneficiarios Mujeres',
                    backgroundColor: '#fa2f2f',
                    data: womenBeneficiaries
                }
            ]
        };
    }

    ngOnInit(): void {
        this.projectForm = this.fb.group({
            cityIds: [[], Validators.required]
        });
        this.filterForm = this.fb.group({
            towns: [[]]
        });

        this.cartographyService
            .getCities()
            .then((resp) => {
                this.cities = [...resp];
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => (this.loading = false));

        this.stackedOptions = {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            responsive: true,
            scales: {
                x: {
                    stacked: true
                },
                y: {
                    stacked: true
                }
            }
        };

        this.cityDropdownSettings = {
            singleSelection: false,
            idField: 'gid',
            textField: 'dep',
            selectAllText: 'Todos',
            unSelectAllText: 'Ninguno',
            itemsShowLimit: 4,
            allowSearchFilter: true
        };

        this.townsDropdownSettings = {
            singleSelection: false,
            idField: 'name',
            textField: 'name',
            selectAllText: 'Todos',
            unSelectAllText: 'Ninguno',
            itemsShowLimit: 4,
            allowSearchFilter: true
        };

        this.barChartOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        maxRotation: 90,
                        minRotation: 0,
                        autoSkip: true,
                        maxTicksLimit: 8
                    }
                },
                y: {
                    ticks: {
                        beginAtZero: true
                    }
                }
            },
        };
    }

    get form() {
        return this.projectForm.controls || {};
    }

    getAll(): void {
        this.loading = true;
        const postData: any = this.projectForm.value;
        let { cityIds = [] } = postData;
        cityIds = cityIds.map((a: any) => parseInt(a.gid));
        this.service
            .getAll({
                params: {
                    cityIds,
                    status: [
                        ...ProjectFlow.itcpStatusesForRanking()
                    ]
                }
            })
            .then((response) => {
                this.approvedRejectedProjects = response;
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => (this.loading = false));
    }

    reload() {
        this.getAll();
    }

    filterByTowns() {
        const data: any = this.filterForm.value;
        const { towns } = data;
        if (!towns.length) {
            this.filteredFlattedData = this.flattedDataPerTown;
            this.totalFilteredMenBeneficiaries = this.totalMenBeneficiaries;
            this.totalFilteredWomenBeneficiaries = this.totalWomenBeneficiaries;
            this.totalFilteredBeneficiaries = this.totalBeneficiaries;
            return;
        }
        const townNames = towns.map((t: any) => t.name);
        this.filteredFlattedData = this.flattedDataPerTown.filter((t) => {
            return townNames.includes(t.townName);
        });

        this.totalFilteredWomenBeneficiaries = 0;
        this.totalFilteredMenBeneficiaries = 0;
        this.totalFilteredBeneficiaries = 0;
        this.filteredFlattedData.forEach((f) => {
            this.totalFilteredWomenBeneficiaries += f.womenBeneficiary;
            this.totalFilteredMenBeneficiaries += f.menBeneficiary;
            this.totalFilteredBeneficiaries += f.totalBeneficiaries;
        });
        this.setApprovedRejectedProjectsChartData();
    }

    generateApprovedRejectedPDF() {
        this.generatingPDF = true;

        setTimeout(async () => {
            const pdf = new PdfBuilder('REPORTE ITCP APROBADOS / RECHAZADOS', 'ITCP');

            const tableElement = this.approvedRejectedTable.tableViewChild.nativeElement;
            await pdf.addTable(tableElement, 'Listado de Municipios total y por Dpto. con Nro de beneficiarios, monto Total del Proyecto, Monto cofinanciado por Fonabosque y Monto del GAM');

            const chartElement = this.chartPDFRef.nativeElement;
            await pdf.addImage(chartElement, 'El cuadro y gráfico muestra la lista de municipios que se encuentran con el ITCP aprobado, y los montos fianciados por el FONABOSQUE y la entidad solicitante');

            const budgetTableElement = this.tableAR.tableViewChild.nativeElement;
            await pdf.addTable(budgetTableElement, 'Listado de Municipio con ITCP aprobados / rechazados total y por Dpto. con monto Total del Proyecto, Monto cofinanciado por Fonabosque y Monto del GAM');

            const budgetChartElement = this.budgetBarChartPDF.nativeElement;
            await pdf.addImage(budgetChartElement, 'Grafica de presupuestos por municipio');

            pdf.generate('fonabosque_reporte_presupuesto_itcp');
            this.generatingPDF = false;
        }, 10);
    }

    generateBenefitedPeoplePDF() {
        this.generatingPDF = true;

        setTimeout(async () => {
            const pdf = new PdfBuilder('Componentes ITCP', 'ITCP');

            // Specific table
            const specificTableElement = this.specificTable.tableViewChild.nativeElement;
            await pdf.addTable(specificTableElement, 'Listado de Municipios total por provincia y departamento, que incluya # personas beneficiadas por genero');

            // Benefited people chart
            const benefitedPeopleElement = this.benefitedBarChartPDF.nativeElement;
            await pdf.addImage(benefitedPeopleElement, 'Grafico del Listado de Municipios en base al numero personas beneficiadas por genero');

            pdf.generate('fonabosque_reporte_personas_beneficiadas_itcp');
            this.generatingPDF = false;
        }, 10);
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(undefined);
        this.onDestroy$.complete();
    }
}
