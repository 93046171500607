<app-wizard
    [steps]="steps"
    [current]="currentStep"
    [bodyContentTpl]="basicInfoForm"
    [nextDisabled]="this.formGroup?.invalid"
    (onClickNext)="save($event)"
    [layout]='true'
>
</app-wizard>

<ng-template #basicInfoForm>
    <div *ngIf="showContent" class="project-form flex justify-content-center flex-column" [formGroup]="formGroup">
        <div class="field">
            <label>Nombre del Proyecto</label>
            <input id="name"
                   pInputText
                   [ngClass]="{'ng-invalid ng-dirty': form.name.errors && form.name.touched}"
                   formControlName="name"
                   placeholder="Nombre del Proyecto"
                   type="text"
                   [pAutoFocus]="true"

                   class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full">
            <div *ngIf="form.name.errors">
                <p *ngIf="form.name.errors?.required && form.name.touched" class="text-danger">
                    *Campo requerido
                </p>
            </div>
        </div>

        <div class="field">
            <label>Tipología del Proyecto</label>

            <ng-multiselect-dropdown
                formControlName="selectedTypologies"
                placeholder="Seleccione al menos una Tipología"
                [settings]="{
                    singleSelection: false,
                    idField: 'id',
                    textField: 'name',
                    selectAllText: 'Todos',
                    unSelectAllText: 'Ninguno',
                    itemsShowLimit: 4,
                    allowSearchFilter: true
                }"
                [data]="typologies"
                [disabled]='isSubmitted'
            >
            </ng-multiselect-dropdown>
            <div *ngIf="form.selectedTypologies.errors">
                <p *ngIf="form.selectedTypologies.errors?.required && form.selectedTypologies.touched" class="text-danger">
                    *Campo requerido
                </p>
            </div>
        </div>

        <div class="field">
            <label>Periodo de Ejecución del Proyecto (en años)</label>
            <p-dropdown
                [options]="availableYears"
                formControlName="years"
                placeholder="Seleccione"
                [style]="{ width: '100%' }"
            >
            </p-dropdown>
        </div>
    </div>
</ng-template>

