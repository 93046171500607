import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ProjectService} from "../../../services";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {ToasterService} from "../../../../shared/services/toaster.service";
import {ProjectModel} from "../../../models";
import {IAttachment} from "../../../../shared/interfaces";
import { Constants } from '../../../../shared/constants';
import {AttachmentService, CryptoService} from '../../../../shared';

@Component({
  selector: 'app-sworn',
  templateUrl: './sworn.component.html',
  styleUrls: ['./sworn.component.scss']
})
export class SwornComponent extends ProjectModel implements OnInit, OnDestroy {

    private step: number = 14;
    swornFiles: IAttachment[] = [];
    itcpFiles: IAttachment[] = [];
    requestFinancingFiles: IAttachment[] = [];
    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        public readonly attachmentService: AttachmentService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private crypto: CryptoService,
    ) {
        super(activatedRoute, projectService, tService, crypto);
    }

    ngOnInit(): void {
        this.readParams();
        this.setActive(this.step);
        this.service.get(this.params['id']).then((project) => {
            const {id, name, swornFileId, swornFile, itcpFileId, itcpFile, requestFinancingFile, requestFinancingFileId } = project || {};
            this.currentProject = { ...project };
            if (swornFile) this.swornFiles.push(swornFile);
            if (itcpFile) this.itcpFiles.push(itcpFile);
            if (requestFinancingFile) this.requestFinancingFiles.push(requestFinancingFile);
            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required],
                swornFileId: [swornFileId, Validators.required],
                itcpFileId: [itcpFileId, Validators.required],
                requestFinancingFileId: [requestFinancingFileId, Validators.required],
                status: [Constants.PROJECT_STATUSES.COMPLETED, Validators.required]
            });
            this.disableFormIfSubmitted();
            this.showContent = true;
        });
    }

    onUploadedSwornFile(data: any) {
        const { files } = data;
        if (files.length) {
            this.formGroup.patchValue({
                swornFileId: files[0].id,
            });
            const field: any = this.formGroup.get('swornFileId');
            field['_pendingDirty'] = true;
        }
    }

    onUploadedItcpFile(data: any) {
        const { files } = data;
        if (files.length) {
            this.formGroup.patchValue({
                itcpFileId: files[0].id,
            });
            const field:any = this.formGroup.get('itcpFileId');
            field['_pendingDirty'] = true;
        }
    }

    onUploadedFinancingFile(data: any) {
        const { files } = data;
        if (files.length) {
            this.formGroup.patchValue({
                requestFinancingFileId: files[0].id,
            });
        }
        const field:any = this.formGroup.get('itcpFileId');
        field['_pendingDirty'] = true;
    }

    ngOnDestroy() {
        this.cleanParams();
    }

}
