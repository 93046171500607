import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { AttachmentService } from '../../../services';
import { IAttachment } from '../../../interfaces';
//https://www.npmjs.com/package/ngx-dropzone
@Component({
    selector: 'app-input-file',
    templateUrl: './input-file.component.html',
    styleUrls: ['./input-file.component.scss']
})
export class InputFileComponent implements OnInit {

    @Input() id: string = '';
    @Input() label: string = '';
    @Input() accept: string = '*';
    @Input() multiple: boolean = true;
    @Input() maxFiles: number = 0;
    @Input() small: boolean = false;
    @Input() defaultFiles: IAttachment[] | undefined;
    @Output() onSelect = new EventEmitter<any>();
    @Output() onUploaded = new EventEmitter<any>();
    @Output() onRemove = new EventEmitter<any>();

    errorMessage: string = '';
    files: File[] = [];
    uploading: boolean = false;

    constructor(private readonly service: AttachmentService) {}

    ngOnInit(): void {
        if (this.defaultFiles?.length) {
            this.defaultFiles.map(attachment => {
                let emptyData = new Blob([''], { type: attachment.contentType });
                let arrayOfBlob = new Array<Blob>();
                arrayOfBlob.push(emptyData);
                let file: File = new File(arrayOfBlob, attachment.filename);
                this.files.push(file);
            });
        }
    }

    onChange(event: any) {
        const total = this.files.length + event.addedFiles.length;
        if (this.maxFiles > 0 && total > this.maxFiles) {
            this.errorMessage = '*El valor máximo de archivos es ' + this.maxFiles;
            return;
        }

        this.uploading = true;
        this.files.push(...event.addedFiles);
        this.onSelect.emit(event.addedFiles);
        let formData = new FormData();
        for (const file of event.addedFiles) {
            formData.append('files[]', file, file.name);
        }

        this.service.upload(formData)
            .then((response) => {
                this.onUploaded.emit({ event, files: response });
                this.uploading = false;
            })
            .catch((err) => {
                console.log(err);
            });
    }

    remove(event: any) {
        this.files.splice(this.files.indexOf(event), 1);
        if (this.files.length <= this.maxFiles) this.errorMessage = '';
        this.onRemove.emit(this.files);
    }

    fileIcon(type:string): string {
        if (type === 'application/pdf') return 'pi pi-file-pdf';
        if (type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') return 'pi pi-file-excel';
        if (type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') return 'pi pi-file';
        if (type.includes('image/')) return 'pi pi-image';
        return 'pi pi-file';
    }

}
