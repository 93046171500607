import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IProjectEnvironment, ProjectModel} from "../../../models";
import {Table} from "primeng/table";
import {ActivatedRoute} from "@angular/router";
import {ProjectEnvironment, ProjectService} from "../../../services";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToasterService} from "../../../../shared/services/toaster.service";
import {ProjectEnvironmentService} from "../../../services/project-environment.service";
import {CryptoService} from "../../../../shared";

@Component({
    selector: 'app-environment',
    templateUrl: './environment.component.html',
    styleUrls: ['./environment.component.scss']
})
export class EnvironmentComponent extends ProjectModel implements OnInit, OnDestroy  {

    private step: number = 8;
    environments: IProjectEnvironment[] = [];
    levels = ProjectEnvironment.LEVEL;
    temporalities = ProjectEnvironment.TEMPORALITY;
    cloned: { [s: string]: IProjectEnvironment; } = {};
    @ViewChild('dt') table!: Table;

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly environmentService: ProjectEnvironmentService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private crypto: CryptoService,
    ) {
        super(activatedRoute, projectService, tService, crypto);
    }

    ngOnInit(): void {
        this.readParams();
        this.setActive(this.step);
        this.service.get(this.params['id']).then((project) => {
            const { id, name, environments } = project || {};
            this.currentProject = { ...project };
            this.buildDefaultEnvironments(environments)
            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required],
            });
            this.disableFormIfSubmitted();
            this.showContent = true;
        });
    }

    buildDefaultEnvironments(storedEnvironments?: IProjectEnvironment[]) {
        const entries = Object.entries(ProjectEnvironment.COMPONENT);

        if (!storedEnvironments) storedEnvironments = [];
        for (const component of entries) {
            const found = storedEnvironments.find(e => e.component === component[1]);
            if(found) {
                this.environments.push(found);
                continue;
            }

            this.environments.push({
                id: `new_${component[0].toLowerCase()}, ${new Date().getTime()}`,
                projectId: parseInt(this.params['id']),
                component: component[1],
                level: ProjectEnvironment.LEVEL.NONE,
                temporality: ProjectEnvironment.TEMPORALITY.TRANSIENT
            });
        }
    }

    onRowEditInit(obj: IProjectEnvironment) {
        if (!obj?.id) return;
        this.cloned[obj.id] = {...obj};
    }

    onRowEditSave(obj: IProjectEnvironment) {
        if (!obj?.id) return;

        const objForm: FormGroup = this.fb.group({
            component: [obj.component, Validators.required],
            level: [obj.level, Validators.required],
            temporality: [obj.temporality, Validators.required],
            projectId: [obj.projectId, Validators.required],
        });

        if (objForm.valid) {
            delete this.cloned[obj.id];

            if (obj.id.toString().includes('new_')) {
                const index = this.environments.indexOf(obj);
                this.environmentService.store(objForm.value).then((resp) => {
                    this.environments[index] = resp;
                    this.toasterService.success('El Impacto ambiental fue creada satisfactoriamente.');
                });

            } else {
                const id = parseInt(obj.id.toString());
                this.environmentService.update(objForm.value, id).then((resp) => {
                    this.toasterService.success('El Impacto ambiental fue actualizada satisfactoriamente.');
                })
            }

        }
        else {
            this.toasterService.error('No se pudo guardar los datos son inválidos!');
        }
    }

    onRowEditCancel(obj: IProjectEnvironment, index: number) {
        if (!obj?.id) return;

        if (this.cloned[obj.id]) {
            this.environments[index] = this.cloned[obj.id];
            delete this.cloned[obj.id];
        }
    }

    ngOnDestroy() {
        this.cleanParams();
    }

}
