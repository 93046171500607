import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from 'src/app/shared/services';
import {IProjectCost} from "../models/interfaces/project-cost.interface";

@Injectable({
  providedIn: 'root'
})
export class ProjectCostService extends BaseService<IProjectCost>{

    constructor(private readonly httpService: BaseHttpService) {
        super(httpService, 'project-costs');
    }
}
