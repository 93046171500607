import { Component, OnInit } from '@angular/core';

import { LayoutService } from 'src/app/shared/services/app.layout.service';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { Constants } from '../../shared/constants';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-menu-template',
    templateUrl: './menu-template.component.html',
    styleUrls: ['./menu-template.component.scss'],
})
export class MenuTemplateComponent implements OnInit {
    model: any[] = [];

    constructor(public layoutService: LayoutService, public permissionService: PermissionService) {}

    ngOnInit(): void {
        const externalRole = this.permissionService.isRequestingEntityRole();
        this.model = [
            {
                label: 'Home',
                isPublic: true,
                items: [{ label: 'Inicio', icon: 'pi pi-fw pi-home', routerLink: ['/home'], isPublic: true }],
            },
            {
                label: 'Menu',
                isPublic: false,
                items: [
                    {
                        label: 'Módulo ITCP',
                        icon: 'pi pi-fw pi-map',
                        resourceName: Constants.RESOURCES.ITCP_ADMIN_MENU,
                        right: Constants.PERMISSIONS.canView,
                        isPublic: false,
                        class: 'bg-primary',
                        items: [
                            {
                                label: 'Convocatorias',
                                resourceName: Constants.RESOURCES.ANNOUNCEMENTS,
                                icon: 'pi pi-fw pi-send',
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/itcp/announcements'],
                            },
                            {
                                label: 'Solicitudes',
                                resourceName: Constants.RESOURCES.ITCP_REQUESTS,
                                icon: 'pi pi-fw pi-id-card',
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/itcp/requests'],
                            },
                            {
                                label: externalRole ? 'Registro de Proyectos' : 'Proyectos',
                                resourceName: Constants.RESOURCES.ITCP_REGISTER,
                                icon: 'pi pi-fw pi-sitemap',
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/itcp/projects'],
                            },
                            {
                                label: 'Asignación de Técnicos',
                                resourceName: Constants.RESOURCES.ITCP_ASSIGNMENT,
                                icon: 'pi pi-fw pi-reply',
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/itcp/assignments'],
                            },
                            {
                                label: 'Valoración de Proyectos',
                                resourceName: Constants.RESOURCES.ITCP_EVAL,
                                icon: 'pi pi-fw pi-check-square',
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/itcp/project-assessments'],
                            },
                            {
                                label: 'Ranking de Proyectos',
                                resourceName: Constants.RESOURCES.ITCP_RANKING,
                                icon: 'pi pi-fw pi-sort-alt-slash',
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/itcp/project-rankings'],
                            },
                        ],
                    },
                    {
                        label: 'Módulo EDTP',
                        icon: 'pi pi-fw pi-database',
                        resourceName: Constants.RESOURCES.EDTP_MENU,
                        right: Constants.PERMISSIONS.canView,
                        isPublic: false,
                        class: 'bg-primary',
                        items: [
                            {
                                label: 'Asignación de Técnicos',
                                resourceName: Constants.RESOURCES.EDTP_ASIGNMENT,
                                icon: 'pi pi-fw pi-reply',
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/edtp/assignments'],
                            },
                            {
                                label: 'Formulación del EDTP',
                                resourceName: Constants.RESOURCES.EDTP_FORM,
                                icon: 'pi pi-fw pi-sliders-v',
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/edtp/form'],
                            },
                            {
                                label: 'Cronograma de Ejecución',
                                resourceName: Constants.RESOURCES.EDTP_SCHEDULER,
                                icon: 'pi pi-fw pi-clock',
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/edtp/scheduler'],
                            },
                            {
                                label: 'Evaluación del EDTP',
                                resourceName: Constants.RESOURCES.EDTP_EVAL,
                                icon: 'pi pi-fw pi-thumbs-up',
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/edtp/eval'],
                            },
                            {
                                label: 'Valoración de Proyectos',
                                resourceName: Constants.RESOURCES.EDTP_EVAL,
                                icon: 'pi pi-fw pi-check-square',
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/edtp/assessment'],
                            },
                            {
                                label: 'Ranking de Proyectos',
                                resourceName: Constants.RESOURCES.EDTP_RANKING,
                                icon: 'pi pi-fw pi-sort-alt-slash',
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/edtp/ranking'],
                            },
                        ],
                    },
                    {
                        label: 'Seguimiento',
                        icon: 'pi pi-fw pi-chart-line',
                        resourceName: Constants.RESOURCES.TRACKING_MENU,
                        right: Constants.PERMISSIONS.canView,
                        isPublic: false,
                        class: 'bg-primary',
                        items: [
                            {
                                label: 'Assignación de Técnicos',
                                icon: 'pi pi-fw pi-reply',
                                resourceName: Constants.RESOURCES.TRACKING_ASSIGNMENTS,
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/tracking/assignments'],
                            },
                            {
                                label: 'Programación de Actividades',
                                icon: 'pi pi-fw pi-sliders-h',
                                resourceName: Constants.RESOURCES.TRACKING_PROGRAMMER,
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/tracking/programmer'],
                            },
                            {
                                label: 'Monitoreo y Seguimiento',
                                icon: 'pi pi-fw pi-window-minimize',
                                resourceName: Constants.RESOURCES.TRACKING_MONITORING,
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/tracking/monitoring'],
                            },
                            {
                                label: 'Valoración de Reportes',
                                icon: 'pi pi-fw pi-check-square',
                                resourceName: Constants.RESOURCES.TRACKING_EVALUATION,
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/tracking/evaluation'],
                            },
                            {
                                label: 'Órdenes de Proceso',
                                icon: 'pi pi-fw pi-play',
                                resourceName: Constants.RESOURCES.TRACKING_ORDERS,
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/tracking/orders'],
                            },
                            {
                                label: 'Detalles del Proyecto',
                                icon: 'pi pi-fw pi-bars',
                                resourceName: Constants.RESOURCES.TRACKING_PROJECT_DETAILS,
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/tracking/project-details'],
                            },
                            {
                                label: 'Momentos de Evaluación del Proyecto',
                                icon: 'pi pi-fw pi-refresh',
                                resourceName: Constants.RESOURCES.PROJECT_EXPOST,
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/tracking/expost'],
                            },
                        ],
                    },
                    {
                        label: 'Reportes',
                        icon: 'pi pi-fw pi-chart-bar',
                        resourceName: Constants.RESOURCES.REPORTS_MENU,
                        right: Constants.PERMISSIONS.canView,
                        isPublic: false,
                        class: 'bg-primary',
                        items: [
                            {
                                label: 'Reporte de Convocatorias',
                                icon: 'pi pi-fw pi-send',
                                resourceName: Constants.RESOURCES.REPORTS_ANNOUNCEMENTS,
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/reports/announcements'],
                            },
                            {
                                label: 'Reporte del ITCP',
                                icon: 'pi pi-fw pi-map',
                                resourceName: Constants.RESOURCES.REPORTS_ITCP,
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/reports/itcp'],
                            },
                            {
                                label: 'Reporte del EDTP',
                                icon: 'pi pi-fw pi-database',
                                resourceName: Constants.RESOURCES.REPORTS_EDTP,
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/reports/edtp'],
                            },
                            {
                                label: 'Reporte de Seguimiento',
                                icon: 'pi pi-fw pi-chart-line',
                                resourceName: Constants.RESOURCES.REPORTS_TRACKING,
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/reports/tracking'],
                            },
                        ],
                    },
                    {
                        label: 'Visitas In-Situ',
                        icon: 'pi pi-fw pi-map-marker',
                        resourceName: Constants.RESOURCES.VISITS_MENU,
                        right: Constants.PERMISSIONS.canView,
                        isPublic: false,
                        class: 'bg-primary',
                        items: [
                            {
                                label: 'Forestación/Reforestación',
                                icon: 'pi pi-fw pi-share-alt',
                                resourceName: Constants.RESOURCES.VISITS_FORESTATION_REFORESTATION,
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/visits/forestation'],
                            },
                            {
                                label: 'Infraestructura',
                                icon: 'pi pi-fw pi-building',
                                resourceName: Constants.RESOURCES.VISITS_INFRASTRUCTURE,
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/visits/infrastructure'],
                            },
                            {
                                label: 'Producción',
                                icon: 'pi pi-fw pi-directions',
                                resourceName: Constants.RESOURCES.VISITS_PRODUCTION,
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/visits/production'],
                            },
                        ],
                    },
                    {
                        label: 'Settings',
                        icon: 'pi pi-fw pi-cog',
                        resourceName: Constants.RESOURCES.USERS_ADMIN_MENU,
                        right: Constants.PERMISSIONS.canView,
                        isPublic: false,
                        class: 'bg-primary',
                        items: [
                            {
                                label: 'Administración de Usuarios',
                                icon: 'pi pi-fw pi-user',
                                resourceName: Constants.RESOURCES.USERS,
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/user'],
                            },
                            {
                                label: 'Administración de Roles',
                                icon: 'pi pi-fw pi-list',
                                resourceName: Constants.RESOURCES.ROLES,
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/role'],
                            },
                            {
                                label: 'Administración de Permisos del Sistema',
                                icon: 'pi pi-fw pi-th-large',
                                resourceName: Constants.RESOURCES.SYSTEM_PERMISSIONS,
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/system-acl'],
                            },
                            {
                                label: 'Administración de Entidades',
                                icon: 'pi pi-fw pi-tag',
                                resourceName: Constants.RESOURCES.ENTITIES,
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/entidad'],
                            },
                            {
                                label: 'Log de Actividad',
                                icon: 'pi pi-fw pi-list',
                                resourceName: Constants.RESOURCES.LOG_ACTIVITIES,
                                right: Constants.PERMISSIONS.canView,
                                isPublic: false,
                                routerLink: ['/log-activity'],
                            },
                            {
                                label: 'API Docs',
                                icon: 'pi pi-fw pi-server',
                                isPublic: true,
                                url: `${environment.host}-docs`,
                                target: '_blank',
                            },
                            // {
                            //   label: 'Submenu 1.1',
                            //   icon: 'pi pi-fw pi-bookmark',
                            //   items: [
                            //     { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark' },
                            //     { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark' },
                            //     { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark' },
                            //   ],
                            // },
                            // {
                            //   label: 'Submenu 1.2',
                            //   icon: 'pi pi-fw pi-bookmark',
                            //   items: [{ label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark' }],
                            // },
                        ],
                    },
                    {
                        label: 'Geovisor',
                        isPublic: true,
                        icon: 'pi pi-fw pi-globe',
                        class: 'bg-primary',
                        // resourceName: Constants.RESOURCES.GEOVISOR,
                        // right: Constants.PERMISSIONS.canView,
                        routerLink: ['/geovisor'],
                    },
                    // {
                    //   label: 'Submenu 2',
                    //   icon: 'pi pi-fw pi-bookmark',
                    //   class: 'bg-primary',
                    //   isPublic: true,
                    //   items: [
                    //     {
                    //       label: 'Submenu 2.1',
                    //       icon: 'pi pi-fw pi-bookmark',
                    //       isPublic: true,
                    //       items: [
                    //         { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-bookmark' },
                    //         { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-bookmark' },
                    //       ],
                    //     },
                    //     {
                    //       label: 'Submenu 2.2',
                    //       isPublic: true,
                    //       icon: 'pi pi-fw pi-bookmark',
                    //       items: [{ label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-bookmark' }],
                    //     },
                    //   ],
                    // },
                ],
            },
            // {
            //     label: 'Get Started',
            //     items: [
            //         {
            //             label: 'Documentation', icon: 'pi pi-fw pi-question', routerLink: ['/documentation']
            //         },
            //         {
            //             label: 'View Source', icon: 'pi pi-fw pi-search', url: ['https://github.com/primefaces/sigespie-frontend'], target: '_blank'
            //         }
            //     ]
            // }
        ];
    }
}
