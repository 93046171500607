import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from 'src/app/shared/services';
import {IProjectEnvironment} from '../models';

@Injectable({
  providedIn: 'root'
})
export class ProjectEnvironmentService extends BaseService<IProjectEnvironment>{

    constructor(private readonly httpService: BaseHttpService) {
        super(httpService, 'project-environments');
    }
}
