import { Injectable } from '@angular/core';
import {BaseHttpService, BaseService} from 'src/app/shared/services';
import {IBudgetItem} from "../interfaces/budget-item.interface";

@Injectable({
  providedIn: 'root'
})
export class BudgetItemService extends BaseService<IBudgetItem>{

    constructor(private readonly httpService: BaseHttpService) {
        super(httpService, 'budget-items');
    }
}
