import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ToasterService } from '../../shared/services/toaster.service';
import { IWizardStep } from '../../public/models';
import { IProject } from './interfaces';
import { ProjectService } from '../services';
import { GenericFunctions } from '../../shared/services/generic-functions';
import {Constants, PROJECT_READ_ONLY} from '../../shared/constants';
import { CryptoService, ProjectFlow, ProjectStagesEnum } from '../../shared';
import * as dayjs from "dayjs";
import {IProjectBudget, IProjectBudgetTotals} from "../../edtp/models/interfaces/project-budget.interface";

export abstract class ProjectModel {
  protected steps: IWizardStep[] = [];
  protected route: ActivatedRoute;
  protected params: any = {};
  protected currentProject: IProject | undefined;
  protected service: ProjectService;
  protected toasterService: ToasterService;
  protected cryptoService: CryptoService;
  protected showContent: boolean = false;
  protected formGroup!: FormGroup;
  protected currentStep: IWizardStep | undefined;
  protected projectYears: number[] = [];
  protected selectedProjectYear!: number;
  protected selectableProjectYears: { label: string; id: number; yearValue: number; }[] = [];
  protected sending: boolean = false;
  protected projectTotals: IProjectBudgetTotals = {entity: 0, entityInKind: 0, owner: 0, total: 0};
  private sub: any;

  protected constructor(route: ActivatedRoute, service: ProjectService, tService: ToasterService, crypto: CryptoService) {
    this.route = route;
    this.service = service;
    this.toasterService = tService;
    this.cryptoService = crypto;
  }

  async setCurrentRequest(): Promise<IProject> {
    this.currentProject = await this.service.get(this.params['id']);
    return this.currentProject;
  }

  setActive(step: number): void {
    this.steps.map((s) => {
      if (s.step <= step) s.active = true;
    });

    this.currentStep = this.steps.find((s) => s?.step === step);
  }

  calculateYears() {
      if(this.currentProject?.years) {
          const currentYear = 1;
          let currentLabelYear = 1;
          let limitYears = this.currentProject.years - 1;
          if (this.currentProject.startOrder?.startOrderDate) {
              const startDate = dayjs(this.currentProject.startOrder?.startOrderDate);
              const endsDate = startDate.add(this.currentProject.years, 'year');
              this.currentProject.startOrderDate = startDate.toDate();
              currentLabelYear = startDate.toDate().getFullYear();
              limitYears = endsDate.toDate().getFullYear() - startDate.toDate().getFullYear();
          }
          this.projectYears = [];
          this.selectableProjectYears = [];
          for (let i = 0; i <= limitYears; i++) {
              this.projectYears.push(currentYear + i);
              this.selectableProjectYears.push(
                  {
                      label: `Gestión ${currentLabelYear + i}`,
                      yearValue: currentLabelYear + i,
                      id: currentYear + i,
                  }
              );
          }
          this.selectedProjectYear = this.projectYears[0];
      }
  }

  setSelectedYear(target: any) {
      this.selectedProjectYear = target.value;
  }

  setSteps() {
    this.steps = [
      {
        step: 1,
        text: 'Datos Principales del Proyecto',
        icon: 'pi pi-sitemap',
        path: `/itcp/projects/${this.params['id']}/basic-info`,
      },
      {
        step: 2,
        text: 'Justificación de la Iniciativa del Proyecto',
        icon: 'pi pi-link',
        path: `/itcp/projects/${this.params['id']}/justify`,
      },
      {
        step: 3,
        text: 'Idea del Proyecto',
        icon: 'pi pi-bolt',
        path: `/itcp/projects/${this.params['id']}/idea`,
      },
      {
        step: 4,
        text: 'Objetivos del Proyecto',
        icon: 'pi pi-list',
        path: `/itcp/projects/${this.params['id']}/goals`,
      },
      {
        step: 5,
        text: 'Beneficios del Proyecto',
        icon: 'pi pi-chart-bar',
        path: `/itcp/projects/${this.params['id']}/advantage`,
      },
      {
        step: 6,
        text: 'Actas de Compromiso',
        icon: 'pi pi-copy',
        path: `/itcp/projects/${this.params['id']}/deals`,
      },
      {
        step: 7,
        text: 'Derecho Propietario',
        icon: 'pi pi-check-circle',
        path: `/itcp/projects/${this.params['id']}/property`,
      },
      {
        step: 8,
        text: 'Impactos Ambientales',
        icon: 'pi pi-sync',
        path: `/itcp/projects/${this.params['id']}/environment`,
      },
      {
        step: 9,
        text: 'Posibles Riesgos de Desastres',
        icon: 'pi pi-sort-alt-slash',
        path: `/itcp/projects/${this.params['id']}/risks`,
      },
      {
        step: 10,
        text: 'Otros Aspectos',
        icon: 'pi pi-arrows-h',
        path: `/itcp/projects/${this.params['id']}/other`,
      },
      {
        step: 11,
        text: 'Presupuesto Referecial',
        icon: 'pi pi-percentage',
        path: `/itcp/projects/${this.params['id']}/budget`,
      },
      {
        step: 12,
        text: 'Conclusiones y Recomendaciones',
        icon: 'pi pi-thumbs-up',
        path: `/itcp/projects/${this.params['id']}/conclusions`,
      },
      {
        step: 13,
        text: 'Documento Imprimible',
        icon: 'pi pi-file-pdf',
        path: `/itcp/projects/${this.params['id']}/pdf`,
      },
      {
        step: 14,
        text: 'Declaración Jurada',
        icon: 'pi pi-check-square',
        path: `/itcp/projects/${this.params['id']}/sworn`,
      },
      { step: 15, text: 'Finalizar', icon: 'pi pi-check', path: `/itcp/projects/${this.params['id']}/done` },
    ];
  }

  readParams() {
    this.sub = this.route.params.subscribe((params) => {
      this.params['id'] = params['id'];
    });

    this.setSteps();
  }

  cleanParams() {
    this.sub.unsubscribe();
    this.params = {};
  }

  get form(): any {
    return this.formGroup.controls;
  }

  get isSubmitted(): boolean {
      if (this.currentProject?.stage === ProjectStagesEnum.ITCP && this.currentProject?.status &&
          [
              Constants.PROJECT_STATUSES.APPROVED,
              Constants.PROJECT_STATUSES.VALUED_OK,
              Constants.PROJECT_STATUSES.VALUED_FAIL,
              Constants.PROJECT_STATUSES.REJECTED,
          ].includes(this.currentProject.status)) return true;

    return this.currentProject?.status === Constants.PROJECT_STATUSES.SUBMITTED || this.isConsolidated || this.isClosed;
  }

  get isClosed(): boolean {
      return this.currentProject?.status === Constants.PROJECT_STATUSES.CLOSED;
  }

  setAsReadOnly() {
      this.formGroup.disable();
  }

  isReadOnly(key: string) {
      try {
          const decryptedKey = this.cryptoService.decrypt(sessionStorage.getItem(key));
          return decryptedKey;
      } catch (e) {
          console.error(e);
          return true;
      }
  }

    disableFormIfSubmitted() {
        if (this.isSubmitted || this.isProgrammed) this.formGroup.disable();
    }

    programmerReadOnly() {
        return this.isReadOnly(PROJECT_READ_ONLY.PROGRAMMER) || this.isProgrammed;
    }

    get isProgrammed(): boolean {
        const statuses = [
            Constants.PROJECT_STATUSES.PROGRAMMED,
            ...ProjectFlow.trackingStatusesForMonitoring(),
            ...ProjectFlow.trackingStatusesForEvaluation(),
        ].filter(s => s != Constants.PROJECT_STATUSES.PROGRAMMING)

        if (!this.currentProject?.status) return false;

        return statuses.includes(this.currentProject.status)
    }

    get isProgramming(): boolean {
      return this.currentProject?.status === Constants.PROJECT_STATUSES.PROGRAMMING;
    }

    get isConsolidated(): boolean {
        if (!this.currentProject?.status) return false;
      return [Constants.PROJECT_STATUSES.CONSOLIDATED, Constants.PROJECT_STATUSES.CONSOLIDATED_SUBMITTED].includes(this.currentProject.status)
    }

  setAsEditable() {
      this.formGroup.enable();
  }

  save(data?: any): void {
    if (this.isSubmitted || this.formGroup.disabled) return;
    const hasChanged = GenericFunctions.isFormChanged(this.formGroup);
    // Invalid
    if (!this.formGroup.valid) {
      this.toasterService.error('Corrija los datos e inténtelo nuevamente');
      return;
    }

    const postData: IProject = this.formGroup.value;
    if (postData?.id && hasChanged) this.store();
  }

  saveSkipValidation(event?: any, silence?: boolean) {
    this.store(silence);
  }

  getNextStep(step: number): IWizardStep | undefined {
    return this.steps.find((s) => s.step === step + 1);
  }

  store(silence = false) {
    const postData: IProject = this.formGroup.value;

    if (!postData.id) return;
      this.sending = true;
    this.service
      .update(postData, postData.id)
      .then((resp: IProject) => {
        this.currentProject = { ...resp };
        this.disableFormIfSubmitted();
        if (!silence)
            this.toasterService.success('Los datos del Proyecto fueron actualizados con éxito');
      })
      .catch((err) => {
        console.error(err.error.message);
        this.toasterService.error('Se ha producido un error inténtelo más tarde o contáctese con el Administrador.');
      }).finally(() => {
        this.sending = false;
    });
  }

  calculateTotalProjectBudget() {
      if (!this.currentProject?.projectBudgets?.length) return;

      this.currentProject.projectBudgets.map(pb => {
          this.setRowTotals(pb);
          this.projectTotals.owner += pb.totals.owner;
          this.projectTotals.entity += pb.totals.entity;
          this.projectTotals.entityInKind += pb.totals.entityInKind;
          this.projectTotals.total += pb.totals.total;
      });
  }

  setRowTotals(budget: IProjectBudget) {
      budget['totals'] = { owner: 0, entity: 0, entityInKind: 0, total: 0 };
      budget.totals.owner = budget.ownerY1 + budget.ownerY2 + budget.ownerY3;
      budget.totals.entity = budget.entityY1 + budget.entityY2 + budget.entityY3;
      budget.totals.entityInKind = budget.entityInKindY1 + budget.entityInKindY2 + budget.entityInKindY3;
      budget.totals.total = budget.totals.owner + budget.totals.entity + budget.totals.entityInKind;
  }
}
