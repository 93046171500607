import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { BaseHttpService } from './base-http.service';
import { ProjectAssignment, UserAssignment } from '../../edtp';
import { IProject, IProjectAssignment } from '../../itcp';
import { Constants } from '../constants';
import { ProjectStagesEnum } from '../enums/project-stages.enum';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectAssignmentsService {
  namespace: string;
  protected constructor(private readonly httpService: BaseHttpService) {
    this.namespace = 'project-assigns';
  }

  async store(assigns: ProjectAssignment[] = [], stage = ProjectStagesEnum.EDTP, changeStage = true): Promise<IProject[]> {
    const data: { projectId: number; assignments: [] }[] = [];
    assigns.forEach((a) => {
      const item: any = {
        projectId: a.project.id,
        assignments: [],
      };

      if (a.financialTech?.id) {
        const financial: UserAssignment = <UserAssignment>{
          userId: a.financialTech.id,
          permissions: Constants.TECHNICIAN_PERMISSIONS.REVIEW_EVAL,
        };
        if (changeStage) financial.stage = stage;
        item.assignments.push(financial);
      }

      const physical: UserAssignment = <UserAssignment>{
        userId: a.physicalTech.id,
        permissions: Constants.TECHNICIAN_PERMISSIONS.REVIEW_EVAL,
      };

      if (changeStage) physical.stage = stage;
      item.assignments.push(physical);

      data.push(item);
    });
    return lastValueFrom(this.httpService.post(this.namespace, data));
  }

  async update(data: IProjectAssignment): Promise<IProjectAssignment> {
    return lastValueFrom(this.httpService.put(`${this.namespace}`, data));
  }

  async get(projectId: number): Promise<IProjectAssignment> {
    return lastValueFrom(this.httpService.get(`${this.namespace}/${projectId}`));
  }
}
