import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectModel } from '../../../models';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../../../services';
import { FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from '../../../../shared/services/toaster.service';
import {CryptoService} from "../../../../shared";

@Component({
  selector: 'app-justify',
  templateUrl: './justify.component.html',
  styleUrls: ['./justify.component.scss'],
})
export class JustifyComponent extends ProjectModel implements OnInit, OnDestroy {
  private step: number = 2;

  public yesNoOptions = [
    { label: 'SI', value: true },
    { label: 'NO', value: false },
  ];
  public showNoJustification: boolean = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private readonly projectService: ProjectService,
    private fb: FormBuilder,
    private tService: ToasterService,
    private readonly router: Router,
    private crypto: CryptoService,
  ) {
    super(activatedRoute, projectService, tService, crypto);
  }

  ngOnInit(): void {
    this.readParams();
    this.setActive(this.step);
    this.service.get(this.params['id']).then((project) => {
      const { id, name, justified, noJustifiedDescription } = project || {};
      this.showNoJustification = !justified;
      this.currentProject = { ...project };
      this.formGroup = this.fb.group({
        id: [id],
        name: [name, Validators.required],
        justified: [justified, Validators.required],
        noJustifiedDescription: [noJustifiedDescription],
      });
      this.disableFormIfSubmitted();
      this.showContent = true;
    });
  }

  onChangeYesNoOption(event: any) {
    this.showNoJustification = !event.value;
    if (this.showNoJustification) this.formGroup.get('noJustifiedDescription')?.addValidators(Validators.required);
    else {
      const nextStep = this.getNextStep(this.step);
      this.save(event);
      setTimeout(() => {
        this.router.navigate([nextStep?.path]).then();
      }, 500);
    }
  }

  ngOnDestroy() {
    this.cleanParams();
  }
}
