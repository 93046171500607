import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from 'src/app/shared/services';
import {
    IProjectActivityBudget,
    IProjectActivityBudgetMonth,
} from '../models';
import {lastValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ProjectActivityBudgetService extends BaseService<IProjectActivityBudget>{

    constructor(private readonly httpService: BaseHttpService) {
        super(httpService, 'project-activity-budgets');
    }

    async updateActivityBudgetMonth(data: IProjectActivityBudgetMonth, id: number): Promise<IProjectActivityBudgetMonth> {
        return lastValueFrom(this._baseHttpService.put(`${this.namespace}/month/${id}`, data));
    }

    async storeActivityBudgetMonth(data: IProjectActivityBudgetMonth): Promise<IProjectActivityBudgetMonth> {
        return lastValueFrom(this._baseHttpService.post(`${this.namespace}/month`, data));
    }
}
