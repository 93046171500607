import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss'],
})
// TODO Use this in all forms with cell phone number input
export class PhoneNumberInputComponent {
  @Input() name: string = '';
  @Input() placeholder: string = '';
  @Input() errors: any = undefined;
  @Input() touched: boolean = false;
  @Output() onChange = new EventEmitter<any>();

  // Only Integer Numbers
  onKeyPressNumbers(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    console.log('ERRORS:', this.errors);
    console.log('TOUCHED', this.touched);
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      this.onChange.emit(event);
      return true;
    }
  }
}
