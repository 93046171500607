import { IAttachment, ICity, ITown, CartographyService } from '../../../../shared';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { AnnouncementService } from '../../../services';
import { EntidadService } from '../../../../core/entidad/entidad.service';
import { IAnnouncement, TipoEntidad } from '../../../models';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as dayjs from 'dayjs';
import {GenericFunctions} from "../../../../shared/services/generic-functions";

@Component({
  selector: 'app-announcement-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() public id!: number;
  @Input() public data!: IAnnouncement;

  public modalForm!: FormGroup;
  public showForm = false;
  entitiesDropdown: TipoEntidad[] = [];
  cities: ICity[] = [];
  towns: ITown[] = [];
  entitiesDropdownSettings: IDropdownSettings = {};
  citiesDropdownSettings: IDropdownSettings = {};
  defaultFiles: IAttachment[] = [];
  public minDate: Date = dayjs().toDate();
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public service: AnnouncementService,
    public entidadService: EntidadService,
    public cartographyService: CartographyService,
    private fb: FormBuilder,
    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    const {
      id,
      title,
      description,
      enabledEntityTypes,
      restrictedCities,
      restrictedTowns,
      fileId,
      file,
      published,
      dueDate,
      yearsLimit,
    } = this.config.data || {};

    let date;
    if (dueDate) date = GenericFunctions.parseDate(dueDate);
    if (file) this.defaultFiles.push(file);
    Promise.all([
      this.entidadService.getTipoEntidades(),
      this.cartographyService.getCities(),
      this.cartographyService.getTowns(),
    ])
      .then(([entities, cities, towns]) => {
        this.entitiesDropdown = [...entities];
        this.cities = [...cities];
        this.towns = [...towns];
      })
      .finally(() => {
        this.showForm = true;
      });

    if (id) {
      this.modalForm = this.fb.group({
        title: [title, Validators.required],
        description: [description, Validators.required],
        enabledEntityTypes: [enabledEntityTypes, Validators.required],
        restrictedCities: [restrictedCities],
        restrictedTowns: [restrictedTowns],
        fileId: [fileId, Validators.required],
        published: [published],
        dueDate: [date, Validators.required],
        yearsLimit: [yearsLimit, Validators.pattern('^[0-9]*$')],
      });
    } else {
      this.modalForm = this.fb.group({
        title: [null, Validators.required],
        description: [null, Validators.required],
        enabledEntityTypes: [[], Validators.required],
        restrictedCities: [[]],
        restrictedTowns: [[]],
        fileId: [null, Validators.required],
        published: [false],
        dueDate: [null, Validators.required],
        yearsLimit: [null],
      });
    }

    this.entitiesDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'tipo',
      selectAllText: 'Todos',
      unSelectAllText: 'Ninguno',
      itemsShowLimit: 4,
      allowSearchFilter: true,
    };

    this.citiesDropdownSettings = {
      singleSelection: false,
      idField: 'gid',
      textField: 'dep',
      selectAllText: 'Todos',
      unSelectAllText: 'Ninguno',
      itemsShowLimit: 4,
      allowSearchFilter: true,
    };
  }

  get form(): any {
    return this.modalForm.controls;
  }

  save(): void {
    const { id } = this.config.data || {};
    // Invalid
    if (!this.modalForm.valid) {
      this.toasterService.error('Corrija los datos e inténtelo nuevamente');
      return;
    }
    const postData: IAnnouncement = this.modalForm.value;
    postData.dueDate = GenericFunctions.parseDate(postData.dueDate);
    // Update
    if (id) {
      let tmpResponse!: IAnnouncement;
      this.service
        .update(postData, this.config.data.id)
        .then((resp: IAnnouncement) => {
          this.toasterService.success('Los datos de la Convocatoria fueron actualizados con éxito');
          tmpResponse = resp;
          this.closeModal(tmpResponse);
        })
        .catch((err) => {
          console.error(err.error.message);
          this.toasterService.error('Se ha producido un error inténtelo más tarde o contáctese con el Administrador.');
        });
      return;
    }

    //Store
    let tmpResponse!: IAnnouncement;
    this.service
      .store(postData)
      .then((resp: IAnnouncement) => {
        this.toasterService.success('La Convocatoria fué almacenada con éxito');
        tmpResponse = resp;
        this.closeModal(tmpResponse);
      })
      .catch((err) => {
        console.error(err.error.message);
        this.toasterService.error('Se ha producido un error inténtelo más tarde o contáctese con el Administrador.');
      });
  }

  filterTowns(event: any) {
    this.cartographyService.getTowns({ params: { query: event.query } }).then((resp) => {
      this.towns = [...resp];
    });
  }

  onUploaded(data: any) {
    const { files } = data;
    if (files.length) {
      this.modalForm.patchValue({
        fileId: files[0].id,
      });
    }
  }

  closeModal(data: any = undefined): void {
    this.ref.close(data);
  }
}
