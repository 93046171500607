import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';

import { IWizardStep } from '../../public/models';
import { IProject, IProjectAssignment } from './interfaces';
import { ToasterService } from '../../shared/services/toaster.service';
import { ProjectAssignmentsService } from '../../shared/services/project-assignments.service';
import { Constants } from '../../shared/constants';
import { GenericFunctions } from '../../shared/services/generic-functions';
import { ProjectService } from '../services';
import { IProjectAssessment } from '../components/project-assessment/interfaces/project-assessment.interface';

export abstract class ProjectAssessmentModel {
  protected steps: IWizardStep[] = [];
  protected route: ActivatedRoute;
  protected params: any = {};
  protected currentProjectAssignment: IProjectAssignment | undefined;
  protected currentProject: IProject | undefined;
  protected projectsService: ProjectService;
  protected toasterService: ToasterService;
  protected showContent: boolean = false;
  protected formGroup!: FormGroup;
  protected currentStep: IWizardStep | undefined;
  protected totalProjectScore: number = 0;
  private sub: any;

  protected constructor(route: ActivatedRoute, projectsService: ProjectService, tService: ToasterService) {
    this.route = route;
    this.projectsService = projectsService;
    this.toasterService = tService;
  }

  setActive(step: number): void {
    this.steps.map((s) => {
      if (s.step <= step) s.active = true;
    });

    this.currentStep = this.steps.find((s) => s.step === step);
  }

  setSteps() {
    this.steps = [
      {
        step: 1,
        text: 'Requisitos para presentacion de ITCP',
        icon: 'pi pi-check-square',
        path: `/itcp/project-assessments/${this.params['id']}/requirements`,
      },
      {
        step: 2,
        text: 'Valoracion',
        icon: 'pi pi-check-square',
        path: `/itcp/project-assessments/${this.params['id']}/assessments`,
      },
    ];
  }

  readParams() {
    this.sub = this.route.params.subscribe((params) => {
      this.params['id'] = params['id'];
    });

    this.setSteps();
  }

  cleanParams() {
    this.sub.unsubscribe();
    this.params = {};
  }

  get form(): any {
    return this.formGroup.controls;
  }

  get isSubmitted(): boolean {
    return this.currentProjectAssignment?.status == Constants.PROJECT_ASSIGNMENT_STATUSES.SUBMITTED;
  }

  disableFormIfCompleted(): void {
    if (this.isSubmitted) this.formGroup.disable();
  }

  getNextStep(step: number): IWizardStep | undefined {
    return this.steps.find((s) => s.step === step + 1);
  }

  save(data: any): void {
    if (this.isSubmitted) return;

    const hasChanged = GenericFunctions.isFormChanged(this.formGroup);
    // Invalid
    if (!this.formGroup.valid) {
      this.toasterService.error('Corrija los datos e inténtelo nuevamente');
      return;
    }

    const postData: IProjectAssignment = this.formGroup.value;
    if (postData?.id && hasChanged) this.store();
  }

  store() {
    const status =
      this.totalProjectScore > 71 ? Constants.PROJECT_STATUSES.VALUED_OK : Constants.PROJECT_STATUSES.VALUED_FAIL;
    this.formGroup.patchValue({ score: this.totalProjectScore, status });
    const postData: IProject = this.formGroup.value;
    if (!postData.id) return;
    this.projectsService
      .update(postData, postData.id)
      .then((resp: IProject) => {
        this.disableFormIfCompleted();
        this.toasterService.success('Los datos del proyecto fueron actualizados con exito');
      })
      .catch((err) => {
        console.error(err.error.message);
        this.toasterService.error('Se ha producido un error inténtelo más tarde o contáctese con el Administrador.');
      });
  }
}
