import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from 'src/app/shared/services';
import {
    IProjectExpost,
} from '../models';

@Injectable({
  providedIn: 'root'
})
export class ProjectExpostService extends BaseService<IProjectExpost>{

    constructor(private readonly httpService: BaseHttpService) {
        super(httpService, 'project-expost');
    }
}
