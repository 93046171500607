import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService, HttpOptions } from 'src/app/shared/services';
import { IITCPRequest, IProject } from '../models';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import compact from 'lodash-es/compact';
import { IProjectRequirement } from '../components/project-assessment/interfaces/project-requirement.interface';
import { IProjectAssessment } from '../components/project-assessment/interfaces/project-assessment.interface';
import { IProjectC3 } from '../../edtp/models/interfaces/project-c3.interface';

@Injectable({
    providedIn: 'root',
})
export class ProjectService extends BaseService<IProject> {
    constructor(private readonly httpService: BaseHttpService) {
        super(httpService, 'projects');
    }

    async getAllRequests(options?: HttpOptions): Promise<IITCPRequest[]> {
        return lastValueFrom(this._baseHttpService.get(`${this.namespace}/requests`, options));
    }

    async getByITCPRequestId(id: number): Promise<IProject> {
        return lastValueFrom(this._baseHttpService.get(`${this.namespace}/request/${id}`));
    }

    async getEDTPByReferenceId(id: number): Promise<IProject> {
        return lastValueFrom(this._baseHttpService.get(`${this.namespace}/edtp/reference/${id}`));
    }

    async generateEDTP(id: number): Promise<IProject> {
        return lastValueFrom(this._baseHttpService.post(`${this.namespace}/edtp/${id}`, {}));
    }

    async upsertRequirement(data: IProjectRequirement, projectId: number): Promise<IProject> {
        return lastValueFrom(this._baseHttpService.put(`${this.namespace}/${projectId}/requirement`, data));
    }

    async upsertAssessment(data: IProjectAssessment, projectId: number): Promise<IProject> {
        return lastValueFrom(this._baseHttpService.put(`${this.namespace}/${projectId}/assessment`, data));
    }

    async notifyEntity(projectId: number, data: any): Promise<IProject> {
        const apiUrl = environment.host;
        data.host = apiUrl;
        return lastValueFrom(this._baseHttpService.post(`${this.namespace}/${projectId}/notify`, data));
    }

    async notifyV2(projectId: number, data: any): Promise<any> {
        const apiUrl = environment.host;
        data.host = apiUrl;
        return lastValueFrom(this._baseHttpService.post(`${this.namespace}/${projectId}/notify/v2`, data));
    }

    getPDFUrl(id?: number) {
        if (!id) return '#';
        const apiUrl = environment.host;
        const url = encodeURI(compact([apiUrl, this.namespace, id, 'pdf']).join('/'));
        return url;
    }
}
