<div class='w-full px-4 py-1 bg-secondary'>
    <div *ngIf="!editMode && group" class='flex gap-4 justify-content-start align-items-center'>
        <div class='flex gap-4 justify-content-start align-items-center'>
            <span class="font-bold">{{group.code}}.</span><div style="white-space: pre-line">{{group.title}}</div>
        </div>
        <div class="flex justify-content-start align-items-center gap-1">
            <button
                class='p-button p-button-success p-button-rounded p-button-text'
                pButton pTooltip="Agregar item para este Grupo" tooltipPosition="top"
                [disabled]="!canEdit"
                (click)='onNew()'
            >
                <i class="pi pi-plus"></i>
            </button>

            <button pButton pRipple [disabled]="!canEdit" type="button" pTooltip="Eliminar" tooltipPosition="top" icon="pi pi-trash" (click)="removeGroup()" class="p-button-rounded p-button-text p-button-danger"></button>
        </div>
    </div>

    <div *ngIf='editMode' class='flex gap-4 justify-content-start align-items-center'>
        <div class='w-20rem'>
            <p-dropdown
                [options]="defaultCompliances"
                placeholder="Seleccione"
                [style]="{'width':'100%'}"
                scrollHeight="200px"
                [filter]="true"
                filterBy="value"
                optionLabel="title"
                optionValue="id"
                (onChange)='onSelectItem($event)'
            >
                <ng-template let-item pTemplate="selectedItem">
                    <div class="flex gap-1 flex-nowrap">
                        <span class="font-bold">{{item.code}}.</span>
                        <div>{{item.title}}</div>
                    </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                    <div class="flex gap-1 flex-nowrap">
                        <span class="font-bold">{{item.code}}.</span>
                        <div class="">{{item.title}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>

        <div>
            <button
                pButton
                pRipple
                type="button"
                pSaveEditableRow
                icon="pi pi-check"
                (click)="saveGroup()"
                class="p-button-rounded p-button-text p-button-success mr-2">
            </button>
            <button pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="cancelEditGroup()" class="p-button-rounded p-button-text p-button-danger"></button>
        </div>
    </div>
</div>

<p-table [tableStyle]="{'width': '100%'}" class="border-none">
    <ng-template pTemplate="header">
        <tr class="border-none">
            <th class="bg-blue-50 border-none text-center p-0"style="width:60px"></th>
            <th class="bg-blue-50 border-none text-center p-0" style="width: 250px"></th>
            <th class="bg-blue-50 border-none text-center p-0"> {{group.weight}} %</th>
            <th class="bg-blue-50 border-none text-center p-0"> {{group.maxScore}}</th>
            <th class="bg-blue-50 border-none text-center p-0"></th>
            <th class="bg-blue-50 border-none text-center p-0"></th>
            <th class="bg-blue-50 border-none text-center p-0"></th>
            <th class="bg-blue-50 border-none text-center p-0"></th>
            <th class="bg-blue-50 border-none text-center p-0"></th>
            <th class="bg-blue-50 border-none text-center p-1"  scope="colgroup">{{group.scoreAccum}}</th>
            <th class="bg-blue-50 border-none text-center p-1"  scope="colgroup">{{group.scoreDist | number: '1.2'}}</th>
            <th class="bg-blue-50 border-none text-center p-1"  scope="colgroup">{{group.maxScoreAccum}}</th>
            <th class="bg-blue-50 border-none text-center p-1"  scope="colgroup">{{group.maxScoreDist | number: '1.2'}}</th>
            <th class="bg-blue-50 border-none text-center p-0" style="width: 200px"></th>
            <th class="bg-blue-50 border-none p-0" style="width:80px"></th>
        </tr>
    </ng-template>
</p-table>

<p-table [value]="assessments" dataKey="id" editMode="row" [tableStyle]="{'width': '100%'}" #dt>
    <ng-template pTemplate="header">
        <tr>
            <th class="bg-secondary border-x-1 text-center p-0"style="width:60px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="width: 250px"></th>
            <th class="bg-secondary border-x-1 text-center p-0"></th>
            <th class="bg-secondary border-x-1 text-center p-0"></th>
            <th class="bg-secondary border-x-1 text-center p-0"></th>
            <th class="bg-secondary border-x-1 text-center p-0"></th>
            <th class="bg-secondary border-x-1 text-center p-0"></th>
            <th class="bg-secondary border-x-1 text-center p-0"></th>
            <th class="bg-secondary border-x-1 text-center p-0"></th>
            <th class="bg-secondary border-x-1 text-center p-0"></th>
            <th class="bg-secondary border-x-1 text-center p-0"></th>
            <th class="bg-secondary border-x-1 text-center p-0"></th>
            <th class="bg-secondary border-x-1 text-center p-0"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="width: 200px"></th>
            <th class="bg-secondary p-0" style="width:80px"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-obj let-editing="editing" let-index="rowIndex">
        <tr [pEditableRow]="obj">
            <td style='width:60px'>{{this.group.code}}.{{index + 1}}</td>
            <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-dropdown
                            [options]="getDefaultGroupChildren(group.code)"
                            placeholder="Sin cambios"
                            [(ngModel)]="obj.title" [ngModelOptions]="{standalone: true}"
                            [style]="{'width':'100%'}"
                            scrollHeight="200px"
                            [filter]="true"
                            filterBy="title"
                            optionLabel="title"
                            optionValue="title"
                            (onChange)='onSelectSubItem($event, obj)'
                        >
                        </p-dropdown>

                        <input *ngIf="obj.other" pInputText
                               [pAutoFocus]="true"
                               placeholder='Escriba la descripción....'
                               required
                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full mt-4 other'
                               type="text" [(ngModel)]="obj.key" [ngModelOptions]="{standalone: true}">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.key}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class='text-center'>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <div class="flex gap-1 flex-nowrap align-items-center">
                            <input pInputText
                                   placeholder='Ponderación'
                                   required
                                   [min]="0"
                                   class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary max-w-4rem'
                                   type="number" [(ngModel)]="obj.weight" [ngModelOptions]="{standalone: true}">
                            <span> %</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.weight}} %
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class='text-center'>
                {{obj.maxScore}}
            </td>
            <td class='text-center'>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-radioButton name="score" [value]='scores.worst' [(ngModel)]="obj.score" [ngModelOptions]="{standalone: true}"></p-radioButton>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <i *ngIf="obj.score === scores.worst" class="pi pi-check text-2xl"></i>
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class='text-center'>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-radioButton name="score" [value]='scores.bad' [(ngModel)]="obj.score" [ngModelOptions]="{standalone: true}"></p-radioButton>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <i *ngIf="obj.score === scores.bad" class="pi pi-check text-2xl"></i>
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class='text-center'>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-radioButton name="score" [value]='scores.regular' [(ngModel)]="obj.score" [ngModelOptions]="{standalone: true}"></p-radioButton>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <i *ngIf="obj.score === scores.regular" class="pi pi-check text-2xl"></i>
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class='text-center'>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-radioButton name="score" [value]='scores.good' [(ngModel)]="obj.score" [ngModelOptions]="{standalone: true}"></p-radioButton>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <i *ngIf="obj.score === scores.good" class="pi pi-check text-2xl"></i>
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class='text-center'>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-radioButton name="score" [value]='scores.excellent' [(ngModel)]="obj.score" [ngModelOptions]="{standalone: true}"></p-radioButton>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <i *ngIf="obj.score === scores.excellent" class="pi pi-check text-2xl"></i>
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class='text-center'>
                {{obj.score}}
            </td>
            <td class='text-center'>
                {{obj.scoreDist | number: '1.2'}}
            </td>
            <td class='text-center'>
                {{obj.maxScore}}
            </td>
            <td class='text-center'>
                {{obj.maxScoreDist | number: '1.2'}}
            </td>
            <td class='text-center'>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <textarea pInputText
                               placeholder='Observación'
                               required
                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                                  type="text" [(ngModel)]="obj.justification" [ngModelOptions]="{standalone: true}"></textarea>
                    </ng-template>
                    <ng-template pTemplate="output">
                       {{obj.justification}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td>
                <div *ngIf="canEdit" class="flex align-items-center justify-content-center gap-2">
                    <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(obj)" class="p-button-rounded p-button-text"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(obj)" class="p-button-rounded p-button-text p-button-success mr-2" [disabled]="!itemForm(obj).valid"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(obj, index)" class="p-button-rounded p-button-text p-button-danger"></button>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
