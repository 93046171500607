<div class="grid">
    <p-breadcrumb [model]="breadcrumbs"></p-breadcrumb>
    <div class="col-12">
        <div class="card">
            <div class="text-base">
                <b class="mr-2">NOMBRE DEL PROYECTO</b> <app-project-status [status]="currentProject?.status"></app-project-status>:
                <br/> {{currentProject?.name}}
            </div>
            <p-toolbar styleClass="mb-4 w-full clean-toolbar">
                <div class="flex justify-content-between flex-column align-items-center sm:flex-row w-full">
                    <div class="p-input-icon-left mb-2 searcher">
                        <i class="pi pi-search"></i>
                        <input
                            pInputText
                            type="text"
                            class="w-full"
                            (input)="setFilter($event, 'filterGlobal', 'contains')"
                            placeholder="Buscar..."
                        />
                    </div>
                    <div class="my-2">
                        <button
                            appPermission
                            [resource]="resource"
                            [enableActions]="'canView'"
                            pButton
                            pRipple
                            label="Actualizar"
                            icon="pi pi-refresh"
                            class="p-button-info mr-2"
                            (click)="load()"
                        ></button>
                    </div>
                </div>
            </p-toolbar>
            <p-table
                #dt
                [value]="documents"
                styleClass="p-datatable-gridlines"
                [loading]="loading"
                [resizableColumns]="true"
                [paginator]="true"
                [rows]="6"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} al {last} de {totalRecords} items"
                [rowsPerPageOptions]="[6, 12, 24]"
                responsiveLayout="scroll"
                [filterDelay]="0"
                [globalFilterFields]="['reference']"
            >
                <ng-template pTemplate="caption">
                    <div class="w-full text-center">REPOSITORIO DOCUMENTAL</div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="bg-primary border-x-1 text-center">NRO</th>
                        <th class="bg-primary border-x-1 text-center">REFERENCIA</th>
                        <th class="bg-primary border-x-1 text-center">ARCHIVO</th>
                        <th class="bg-primary border-x-1 text-center">FECHA</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                    <tr>
                        <td>{{ rowIndex + 1 }}</td>
                        <td>{{ item.reference }}</td>
                        <td>
                            <a [href]="attachmentService.getFileUrl(item.file.key)" class="hover:underline"
                               [download]="item.file.filename" target="_blank">
                                <i class="pi pi-download mr-2"></i>
                                {{ item.file.filename }}
                            </a>
                        </td>
                        <td class="text-center" >{{ item.file.createdAt | date: "yyyy-MM-dd, h:mm a" }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

