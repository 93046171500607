<app-wizard
    [steps]='steps'
    [current]='currentStep'
    [bodyContentTpl]='projectForm'
    [nextDisabled]='isSubmitted'
    (onFinish)='saveSkipValidation($event)'
    [layout]='true'
    [lastStep]='true'
>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column' [formGroup]='formGroup'>
        <div class='grid'>
            <div class="field col-12 text-2xl text-primary uppercase">
                {{currentStep?.text}}
            </div>

            <div class="field col-12">
                <div class="message-description">
                    <p>Felicidades ha completado la formulación satisfactoriamente.</p>
                    <p class='description'>
                        {{ isSubmitted ? 'El proyecto ha sido enviado para la valoración' : ' Porfavor haga click en el botón Enviar.'}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</ng-template>
