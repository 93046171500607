<div class='card border-0'>
  <p-skeleton *ngIf='!showForm' width='100%' height='150px'></p-skeleton>
  <div class='grid'>
    <div class='w-full flex flex-wrap gap-4 justify-content-center align-items-center  px-6'>
      <div *ngIf='saving' class='px-8 py-4'>
        <label class='text-base'>Enviando.....</label>
        <p-progressBar mode='indeterminate' [style]="{ height: '6px' }"></p-progressBar>
      </div>
    </div>

    <div class='w-full flex flex-wrap gap-4 justify-content-center align-items-center  px-6'>
      <p-editor
        [(ngModel)]='textNotification'
        [style]="{ height: '200px'}"
      >
      </p-editor>
    </div>
    <p class='font-italic text-sm'>
      <b>* "{{ '{{'}} contactName {{'}}' }}"</b>: No modificar este texto, es la clave donde se replazará el
      nombre.<br />
    </p>

    <div class='field lg:col-12 md:col-12 sm:col-12'>
      <app-input-file
        [label]="'Archivo de la Notificacion'"
        [multiple]='false'
        [maxFiles]='1'
        [accept]="'*'"
        [defaultFiles]='defaultFiles'
        (onUploaded)='onUploaded($event)'
      >
      </app-input-file>
    </div>
  </div>
</div>
<div class='p-dialog-footer'>
  <button pButton type='button' label='Cancelar' class='p-button-secondary' (click)='closeModal()'></button>
  <button pButton type='button' label='Notificar' (click)='save()' [disabled]='modalForm?.invalid || saving'></button>
</div>
