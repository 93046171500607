import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {Constants} from "../shared/constants";
import {VisitsComponent} from "./components";

const routes: Routes = [
    { path: '', component: VisitsComponent, data: { moduleNameCode: Constants.RESOURCES.VISITS_MENU } },
    { path: 'forestation', component: VisitsComponent, data: { moduleNameCode: Constants.RESOURCES.VISITS_FORESTATION_REFORESTATION } },
    { path: 'infrastructure', component: VisitsComponent, data: { moduleNameCode: Constants.RESOURCES.VISITS_INFRASTRUCTURE } },
    { path: 'production', component: VisitsComponent, data: { moduleNameCode: Constants.RESOURCES.VISITS_PRODUCTION } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VisitsRoutingModule { }
