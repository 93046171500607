import { Injectable } from '@angular/core';
import {BaseHttpService, BaseService} from 'src/app/shared/services';
import { IEdtpCompliance } from '../models';

@Injectable({
  providedIn: 'root'
})
export class EdtpComplianceService extends BaseService<IEdtpCompliance>{

    constructor(private readonly httpService: BaseHttpService) {
        super(httpService, 'edtp-compliances');
    }
}
