export * from './announcement.interface';
export * from '../../../core/entidad/interfaces/tipo-entidad.interface';
export * from './itcp-request.interface';
export * from './entity.interface';
export * from './entity.interface';
export * from './itcp-request-verification.interface';
export * from './project.interface';
export * from './project-goal.interface';
export * from './project-beneficiary.interface';
export * from './project-act.interface';
export * from './project-property.interface';
export * from './coordinates.interface';
export * from './project-environment.interface';
export * from './project-risk.interface';
export * from './project-general-budget.interface';
export * from './project-assignment.interface';
