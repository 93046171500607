import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectModel } from '../../../models';
import { ActivatedRoute } from '@angular/router';
import {DEFAULT_LIMIT_YEARS, PROJECT_NO_NAME, ProjectService} from '../../../services';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { FormBuilder, Validators } from '@angular/forms';
import {CryptoService, SettingsService} from "../../../../shared";
import {ITypology} from '../../../../shared';
import { Constants } from '../../../../shared/constants';

@Component({
    selector: 'app-basic-info',
    templateUrl: './basic-info.component.html',
    styleUrls: ['./basic-info.component.scss']
})
export class BasicInfoComponent extends ProjectModel implements OnInit, OnDestroy {
    private step: number = 1;
    typologies: ITypology[] = [];
    availableYears: number[] = [];
    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private settingService: SettingsService,
        private crypto: CryptoService,
    ) {
        super(activatedRoute, projectService, tService, crypto);
    }

    ngOnInit(): void {
        this.readParams();
        this.setActive(this.step);

        this.service.get(this.params['id']).then((project) => {
            const { id, name, typologies, years, status } = project || {};
            let projectName = name
            if (projectName === PROJECT_NO_NAME) projectName = ''
            this.currentProject = { ...project };
            const newStatus = this.isSubmitted ? status : Constants.PROJECT_STATUSES.STARTED;
            this.formGroup = this.fb.group({
                id: [id],
                name: [projectName, Validators.required],
                selectedTypologies: [typologies, Validators.required],
                years: [years, Validators.required],
                status: [newStatus, Validators.required],
            });
        }).then(() => {
            return this.settingService.getAllTypologies();
        }).then((typologies) => {
            this.typologies = typologies;
        }).finally(() => {
            this.disableFormIfSubmitted();
            this.initializeAvailableYears();
            this.showContent = true;
        });
    }

    initializeAvailableYears() {
        const limit = this.currentProject?.years || DEFAULT_LIMIT_YEARS;
        for(let i = 0; i < limit; i++) {
            this.availableYears.push(i+1);
        }
    }

    ngOnDestroy() {
        this.cleanParams();
    }

}
