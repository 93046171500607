
export interface IProjectBeneficiary {
    id?: number;
    projectId: number;
    beneficiaryType: string;
    men: number;
    women: number;
}

export interface IProjectBeneficiaryTable {
    id?: number;
    beneficiaryType: string;
    men: number;
    menPercentage: number;
    women: number;
    womenPercentage: number;
    total: number;
}
