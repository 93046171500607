<div class="grid">
  <p-breadcrumb [model]="breadcrumbs"></p-breadcrumb>
  <div class="col-12">
    <div class="card">
      <p-toolbar styleClass="mb-4 w-full clean-toolbar">
        <div class="flex justify-content-between flex-column align-items-center sm:flex-row w-full">
          <div class="p-input-icon-left mb-2 searcher">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              class="w-full"
              (input)="setFilter($event, 'filterGlobal', 'contains')"
              placeholder="Buscar..."
            />
          </div>
          <div class="my-2">
            <button
              appPermission
              [resource]="resource"
              [enableActions]="'canView'"
              pButton
              pRipple
              label="Actualizar"
              icon="pi pi-refresh"
              class="p-button-info mr-2"
              (click)="reload()"
            ></button>
          </div>
        </div>
      </p-toolbar>

      <p-table
        #dtTable
        [value]="data"
        styleClass="p-datatable-gridlines"
        [loading]="loading"
        [resizableColumns]="true"
        [paginator]="true"
        [rows]="6"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} al {last} de {totalRecords} items"
        [rowsPerPageOptions]="[6, 12, 24]"
        responsiveLayout="scroll"
        [filterDelay]="0"
        [globalFilterFields]="['entityId', 'name', 'description']"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="bg-primary text-center">NRO</th>
            <th class="bg-primary text-center">PROYECTO</th>
            <th class="bg-primary text-center">ENTIDAD SOLICITANTE</th>
            <th class="bg-primary text-center">CALIFICACIÓN</th>
            <th class="bg-primary text-center">ESTADO</th>
            <th class="bg-primary text-center">NOTIFICACIÓN</th>
            <th class="bg-primary text-center">APROBAR/RECHAZAR</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-data let-ri="rowIndex">
          <tr>
            <td>{{ ri + 1 }}</td>
              <td><div class="large-cell">{{ data.name }}</div></td>
            <td>{{ data.iTCPRequest.entity.nombre }}</td>
            <td *ngIf="data.score >= SCORES.ITCP_APPROVE" class="text-center font-bold text-xl">{{ data.score }}</td>
            <td *ngIf="data.score < SCORES.ITCP_APPROVE" class="text-danger text-center font-bold text-xl">{{ data.score }}</td>
            <td class="text-center">
                {{ getState(data) }}<br/>
                <p-badge *ngIf="approved(data.status)" value="APROBADO" severity="success"></p-badge>
                <p-badge *ngIf="rejected(data.status)" value="RECHAZADO" severity="danger"></p-badge>
            </td>
            <td>
              <button
                pButton
                pRipple
                label="Notificar"
                appPermission
                [resource]="resource"
                [enableActions]="'isSuperAdmin'"
                pTooltip="Notificar"
                tooltipPosition="top"
                type="button"
                icon="pi pi-check-square"
                class="p-button p-button-outlined"
                (click)="notifyProject(data)"
              ></button>
            </td>
              <td class="text-center">
                  <button
                      *ngIf='!wasSentToEDTP(data.status)'
                      pButton
                      pRipple
                      appPermission
                      [resource]="resource"
                      [enableActions]="'isSuperAdmin'"
                      pTooltip="Aprobar Proyecto"
                      tooltipPosition="top"
                      type="button"
                      icon="pi pi-check"
                      class="p-button p-button-success p-button-text p-button-rounded"
                      (click)="approveRejectProject(data, true)"
                  ></button>
                  <button
                      *ngIf='!wasSentToEDTP(data.status)'
                      pButton
                      pRipple
                      appPermission
                      [resource]="resource"
                      [enableActions]="'isSuperAdmin'"
                      pTooltip="Rechazar Proyecto"
                      tooltipPosition="top"
                      type="button"
                      icon="pi pi-times"
                      class="p-button p-button-danger p-button-text p-button-rounded"
                      (click)="approveRejectProject(data, false)"
                  ></button>
              </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
