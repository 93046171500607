import { Component, OnDestroy, OnInit } from '@angular/core';
import { IProjectGoal, ProjectModel } from '../../../models';
import { ActivatedRoute } from '@angular/router';
import { ProjectBeneficiaryService, ProjectService } from '../../../services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from '../../../../shared/services/toaster.service';
import {
    IProjectBeneficiary,
    IProjectBeneficiaryTable
} from '../../../models';
import {CryptoService} from "../../../../shared";

@Component({
    selector: 'app-advantage',
    templateUrl: './advantage.component.html',
    styleUrls: ['./advantage.component.scss']
})
export class AdvantageComponent extends ProjectModel implements OnInit, OnDestroy {
    private step: number = 5;
    beneficiaries: IProjectBeneficiaryTable[] = [];
    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly beneficiaryService: ProjectBeneficiaryService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private crypto: CryptoService,
    ) {
        super(activatedRoute, projectService, tService, crypto);
    }

    ngOnInit(): void {
        this.readParams();
        this.setActive(this.step);
        this.initializeBeneficiaries();
        this.service.get(this.params['id']).then((project) => {
            const { id, name, advantage, beneficiaries } = project || {};
            this.currentProject = { ...project };
            if (beneficiaries?.length){
                for (const beneficiary of beneficiaries) {
                    this.beneficiaries.map(b => {
                       if (b.beneficiaryType === beneficiary.beneficiaryType) {
                           b.men = beneficiary.men;
                           b.women = beneficiary.women;
                       }
                    });
                }
                this.setTotals();
            }

            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required],
                advantage: [advantage, [Validators.required, Validators.maxLength(3000)]],
            });

            this.disableFormIfSubmitted();
            this.showContent = true;
        });
    }

    initializeBeneficiaries() {
        const b = {
            beneficiaryType: 'DIRECT',
            men: 0,
            women: 0,
            menPercentage: 0,
            womenPercentage: 0,
            total: 0,
        };

        this.beneficiaries.push(b);
        this.beneficiaries.push({
            ...b,
            beneficiaryType: 'INDIRECT'
        });

        this.beneficiaries.push({
            ...b,
            beneficiaryType: 'TOTAL'
        });
    }

    setTotals() {
        const t = this.beneficiaries.find(b => b.beneficiaryType === 'TOTAL');
        if (t) {
            t.men = 0;
            t.women = 0;
            this.beneficiaries.map(b => {
                if (b.beneficiaryType != 'TOTAL') {
                    b.total = b.men + b.women;
                    b.menPercentage = (b.men * 100) / b.total;
                    b.womenPercentage = 100 - b.menPercentage;

                    t.men += b.men;
                    t.women += b.women;
                    t.total = t.men + t.women;
                    t.menPercentage = (t.men * 100) / t.total;
                    t.womenPercentage = 100 - t.menPercentage;
                }
            });
        }
    }

    onEditComplete(data: any) {
        if (data.data.beneficiaryType === 'TOTAL') return;
        this.setTotals();

        const projectId = parseInt(this.params['id']);
        const beneficiaryForm: FormGroup = this.fb.group({
            beneficiaryType: [data.data.beneficiaryType, Validators.required],
            men: [data.data.men, [Validators.required, Validators.min(0)]],
            women: [data.data.women, [Validators.required, Validators.min(0)]],
            projectId: [projectId, Validators.required],
        });

        if (beneficiaryForm.valid) {
            this.beneficiaryService.upsert(beneficiaryForm.value).then((resp) => {
                this.toasterService.success('Los datos han sido actualizados satisfactoriamente.')
            })
        }
    }

    ngOnDestroy() {
        this.cleanParams();
    }

}
