import {Component, Input, OnInit} from '@angular/core';
import {Constants} from "../../../constants";

const Severities = {
    info: 'info',
    success: 'success',
    warning: 'warning',
    danger: 'danger',
}

@Component({
    selector: 'app-project-status',
    templateUrl: './project-status.component.html',
    styleUrls: ['./project-status.component.scss']
})
export class ProjectStatusComponent implements OnInit {

    @Input() status!: string | undefined;

    statuses:any = {};
    statusesClassNames:any = {};

    constructor() {
        this.statuses[Constants.PROJECT_STATUSES.DRAFT] = 'Borrador';
        this.statuses[Constants.PROJECT_STATUSES.STARTED] = 'Iniciado';
        this.statuses[Constants.PROJECT_STATUSES.ACTIVE] = 'Activo';
        this.statuses[Constants.PROJECT_STATUSES.COMPLETED] = 'Completado';
        this.statuses[Constants.PROJECT_STATUSES.SUBMITTED] = 'Enviado';
        this.statuses[Constants.PROJECT_STATUSES.VERIFIED] = 'Verificado';
        this.statuses[Constants.PROJECT_STATUSES.VALUED_OK] = 'Valorado y Aceptado';
        this.statuses[Constants.PROJECT_STATUSES.VALUED_FAIL] = 'Valorado y Rechazado';
        this.statuses[Constants.PROJECT_STATUSES.EVALUATED] = 'Evaluado';
        this.statuses[Constants.PROJECT_STATUSES.NOTIFIED] = 'Notificado';
        this.statuses[Constants.PROJECT_STATUSES.APPROVED] = 'Aprobado';
        this.statuses[Constants.PROJECT_STATUSES.REJECTED] = 'Rechazado';
        this.statuses[Constants.PROJECT_STATUSES.ASSIGNED_ITCP] = 'Técnicos Asignados';
        this.statuses[Constants.PROJECT_STATUSES.ASSIGNED_EDTP] = 'Técnicos Asignados';
        this.statuses[Constants.PROJECT_STATUSES.ASSIGNED_TRACKING] = 'Técnicos Asignados';
        this.statuses[Constants.PROJECT_STATUSES.PROCESSING] = 'Procesando';
        this.statuses[Constants.PROJECT_STATUSES.PROGRAMMING] = 'Programando';
        this.statuses[Constants.PROJECT_STATUSES.PROGRAMMED] = 'Programado';
        this.statuses[Constants.PROJECT_STATUSES.START_ORDERED] = 'Orden de Inicio Ejecutada';
        this.statuses[Constants.PROJECT_STATUSES.CHANGE_ORDERED] = 'Orden de Cambio Solicitada';
        this.statuses[Constants.PROJECT_STATUSES.REPORTING] = 'Reportando Progreso';
        this.statuses[Constants.PROJECT_STATUSES.REPORTED] = 'Progreso Reportado';
        this.statuses[Constants.PROJECT_STATUSES.REPORT_EVALUATING] = 'Evaluando Progreso';
        this.statuses[Constants.PROJECT_STATUSES.REPORT_EVALUATED] = 'Progreso Evaluado';
        this.statuses[Constants.PROJECT_STATUSES.CONSOLIDATED] = 'Consolidado';
        this.statuses[Constants.PROJECT_STATUSES.CONSOLIDATED_SUBMITTED] = 'Enviado y Consolidado';
        this.statuses[Constants.PROJECT_STATUSES.CLOSED] = 'Cerrado';

        this.statusesClassNames[Constants.PROJECT_STATUSES.DRAFT] = Severities.info;
        this.statusesClassNames[Constants.PROJECT_STATUSES.STARTED] = Severities.warning;
        this.statusesClassNames[Constants.PROJECT_STATUSES.ACTIVE] = Severities.info;
        this.statusesClassNames[Constants.PROJECT_STATUSES.COMPLETED] = Severities.success;
        this.statusesClassNames[Constants.PROJECT_STATUSES.SUBMITTED] = Severities.success;
        this.statusesClassNames[Constants.PROJECT_STATUSES.VERIFIED] = Severities.success;
        this.statusesClassNames[Constants.PROJECT_STATUSES.VALUED_OK] = Severities.success;
        this.statusesClassNames[Constants.PROJECT_STATUSES.VALUED_FAIL] = Severities.danger;
        this.statusesClassNames[Constants.PROJECT_STATUSES.EVALUATED] = Severities.success;
        this.statusesClassNames[Constants.PROJECT_STATUSES.NOTIFIED] = Severities.success;
        this.statusesClassNames[Constants.PROJECT_STATUSES.APPROVED] = Severities.success;
        this.statusesClassNames[Constants.PROJECT_STATUSES.REJECTED] = Severities.danger;
        this.statusesClassNames[Constants.PROJECT_STATUSES.ASSIGNED_ITCP] = Severities.success;
        this.statusesClassNames[Constants.PROJECT_STATUSES.ASSIGNED_EDTP] = Severities.success;
        this.statusesClassNames[Constants.PROJECT_STATUSES.ASSIGNED_TRACKING] = Severities.success;
        this.statusesClassNames[Constants.PROJECT_STATUSES.PROCESSING] = Severities.warning;
        this.statusesClassNames[Constants.PROJECT_STATUSES.PROGRAMMING] = Severities.warning;
        this.statusesClassNames[Constants.PROJECT_STATUSES.PROGRAMMED] = Severities.success;
        this.statusesClassNames[Constants.PROJECT_STATUSES.START_ORDERED] = Severities.info;
        this.statusesClassNames[Constants.PROJECT_STATUSES.CHANGE_ORDERED] = Severities.info;
        this.statusesClassNames[Constants.PROJECT_STATUSES.REPORTING] = Severities.warning;
        this.statusesClassNames[Constants.PROJECT_STATUSES.REPORTED] = Severities.success;
        this.statusesClassNames[Constants.PROJECT_STATUSES.REPORT_EVALUATING] = Severities.warning;
        this.statusesClassNames[Constants.PROJECT_STATUSES.REPORT_EVALUATED] = Severities.success;
        this.statusesClassNames[Constants.PROJECT_STATUSES.CONSOLIDATED] = Severities.success;
        this.statusesClassNames[Constants.PROJECT_STATUSES.CONSOLIDATED_SUBMITTED] = Severities.success;
        this.statusesClassNames[Constants.PROJECT_STATUSES.CLOSED] = Severities.warning;
    }

    ngOnInit(): void {
    }

}
