<div class="grid">
    <p-breadcrumb [model]="breadcrumbs"></p-breadcrumb>
    <div class="col-12">
        <div class="card">
            <p-toolbar styleClass="mb-4 w-full clean-toolbar">
                <div class="flex justify-content-between flex-column align-items-center sm:flex-row w-full">
                    <div class="p-input-icon-left mb-2 searcher">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" class="w-full"
                               (input)="setFilter($event, 'filterGlobal', 'contains')" placeholder="Buscar..."/>
                    </div>
                    <div class="my-2">
                        <button
                            appPermission
                            [resource]="resource"
                            [enableActions]="'canView'"
                            pButton pRipple label="Actualizar" icon="pi pi-refresh" class="p-button-info mr-2" (click)="reload()">
                        </button>
                    </div>
                </div>
            </p-toolbar>
            <p-table
                #dtITCPRequest
                [value]="data"
                styleClass="p-datatable-gridlines"
                [loading]="loading"
                [resizableColumns]="true"
                [paginator]="true"
                [rows]="6"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} al {last} de {totalRecords} items"
                [rowsPerPageOptions]="[6, 12, 24]"
                responsiveLayout="scroll"
                [filterDelay]="0"
                [globalFilterFields]="[
                  'seoName',
                  'entityId',
                  'name',
                  'description',
                ]"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th class="bg-primary" *ngFor="let item of header" [pSortableColumn]="item.column">
                            {{ item.label }}
                            <p-sortIcon
                                *ngIf="item.sort === true"
                                [field]="item.column"
                            ></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-data>
                    <tr>
                        <td *ngIf="!externalRole"><div class="large-cell">{{data.iTCPRequest?.announcement?.description}}</div></td>
                        <td>
                            <div class="large-cell">{{ externalRole ? data.entity.nombre : data.name }}</div>
                        </td>
                        <td>
                            <div class="large-cell">{{ externalRole ? data.seoName : data.iTCPRequest.entity.nombre }}</div>
                        </td>
                        <td>
                            <ul *ngIf="externalRole">
                                <li><b>Correo electrónico: </b>{{data.contactEmail}}</li>
                            </ul>
                            <span *ngIf="!externalRole">{{data.iTCPRequest.seoName}}</span>
                        </td>

                        <td>

                            <button
                                *ngIf="externalRole"
                                pButton pTooltip="{{!externalRole ? 'Ver Proyecto Regitrado' : 'Registrar Proyecto' }}" tooltipPosition="top"
                                icon="pi {{!externalRole ? 'pi-eye' : 'pi-save' }}"
                                type="button"
                                class="p-button-rounded p-button-info p-button-text ml-1"
                                (click)="retrieveProject(0, data)"
                                >
                            </button>

                            <button
                                *ngIf="!externalRole"
                                pButton pTooltip="Ver Proyecto" tooltipPosition="top"
                                icon='pi pi-eye'
                                type="button"
                                class="p-button-rounded p-button-info p-button-text ml-1"
                                (click)="retrieveProject(data.id)"
                            >
                            </button>

                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>


