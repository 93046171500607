import { Injectable } from '@angular/core';
import { get } from 'lodash-es';
import { CredentialsService } from '.';
import { permissionTypes } from '../directives/permission.directive';
import { Constants } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  public userData: any = this.credentialsService.getLoggedUser();
  public rights: any = this.credentialsService.getCreds();
  private NONE = this.rights.NONE;
  private CAN_READ = this.rights.CAN_READ;
  private CAN_UPDATE = this.rights.CAN_UPDATE;
  private CAN_CREATE = this.rights.CAN_CREATE;
  private CAN_DELETE = this.rights.CAN_DELETE;
  private IS_ADMIN = this.rights.IS_ADMIN;
  private IS_SUPER_ADMIN = this.rights.IS_SUPER_ADMIN;
  private enablePermissionSystem = this.rights.enablePermissionSystem;

  constructor(private readonly credentialsService: CredentialsService) {}

  public handleAccess(resource: string, right: permissionTypes, isPublic: boolean = false) {
    if (!this.enablePermissionSystem) {
      return true;
    }
    const userPermission = get(this.userData, `accessTo.${resource}`, null);

    if (isPublic) {
      return true;
    }
    if (!userPermission) {
      return false;
    }
    const isSuperAdmin = this.isSuperAdmin(userPermission.permiso);
    if (isSuperAdmin) {
      return true;
    }
    switch (right) {
      case Constants.PERMISSIONS.canView:
        return this.canView(userPermission.permiso);
        break;
      case Constants.PERMISSIONS.canCreate:
        return this.canCreate(userPermission.permiso);
        break;
      case Constants.PERMISSIONS.canDelete:
        return this.canDelete(userPermission.permiso);
        break;
      case Constants.PERMISSIONS.canUpdate:
        return this.canUpdate(userPermission.permiso);
        break;
      case Constants.PERMISSIONS.isAdmin:
        return this.isAdmin(userPermission.permiso);
        break;
      default:
        return false;
        break;
    }
  }

  public isSuperAdmin(currentAccess: number): boolean {
    return !!(this.IS_SUPER_ADMIN & currentAccess);
  }

  public isAdmin(currentAccess: number): boolean {
    return this.IS_SUPER_ADMIN === (this.IS_ADMIN & currentAccess);
  }

  public canView(currentAccess: number): boolean {
    return this.CAN_READ === (this.CAN_READ & currentAccess);
  }

  public canCreate(currentAccess: number): boolean {
    return this.CAN_CREATE === (this.CAN_CREATE & currentAccess);
  }

  public canUpdate(currentAccess: number): boolean {
    return this.CAN_UPDATE === (this.CAN_UPDATE & currentAccess);
  }

  public canDelete(currentAccess: number): boolean {
    return this.CAN_DELETE === (this.CAN_DELETE & currentAccess);
  }

  public isRequestingEntityRole(): boolean {
    return(
        this.userData?.role?.roleName === Constants.ROLES.REQUESTING_ENTITY ||
        this.userData?.role?.roleName === Constants.ROLES.MAE_SOLICITANTE ||
        this.userData?.role?.roleName === Constants.ROLES.RESP_SOLICITANTE ||
        this.userData?.role?.roleName === Constants.ROLES.TEC_SOLICITANTE
    )

  }

  public isSuperAdminRole(): boolean {
    return this.userData?.role?.roleName === Constants.ROLES.SUPER_ADMIN;
  }
}
