import {IRisk} from "../../../shared/interfaces/risk.interface";

export interface IProjectRisk {
    id?: number | string;
    projectId: number;
    risk?: IRisk;
    riskId: number;
    level: string;
    other: string;
}
