import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/shared/services';
import { lastValueFrom } from 'rxjs';
import { TipoEntidad } from '../models';

@Injectable({
  providedIn: 'root',
})
export class EntityTypeService {
  constructor(private readonly _baseHttpService: BaseHttpService) {}

  async getAll(): Promise<TipoEntidad[]> {
    return lastValueFrom(this._baseHttpService.get('entidades/tipos'));
  }
}
