import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {IITCPRequest, IProject} from '../../models';
import { Constants } from '../../../shared/constants';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subject } from 'rxjs';
import { GenericFunctions } from '../../../shared/services/generic-functions';
import { DialogService } from 'primeng/dynamicdialog';
import { ToasterService } from '../../../shared/services/toaster.service';
import {PROJECT_NO_NAME, ProjectService} from '../../services';
import { Router } from '@angular/router';
import {PermissionService} from "../../../shared/services/permission.service";
import {ProjectFlow} from "../../../shared/services/project.flow";
import {ProjectStagesEnum} from "../../../shared";

@Component({
    selector: 'app-project',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit, OnDestroy {
    public loading = false;
    public externalRole: boolean = false;
    public data: IITCPRequest[] | IProject[] = [];
    public resource = Constants.RESOURCES.ITCP_REGISTER;
    public breadcrumbs: MenuItem[] = [
        { label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home'] },
        { label: ' ITCP', routerLink: ['/itcp'] },
        { label: ' Proyectos' },
    ];

    public header: Array<any> = [];

    @ViewChild('dtITCPRequest') table!: Table;
    private onDestroy$ = new Subject();
    constructor(
        private readonly genericFunctions: GenericFunctions,
        public readonly dialogService: DialogService,
        public readonly confirmationService: ConfirmationService,
        private readonly toasterService: ToasterService,
        private readonly service: ProjectService,
        private readonly router: Router,
        private readonly permissionsService: PermissionService,
    ) {}

    ngOnInit(): void {
        this.getAll();
    }

    getAll(): void {
        this.loading = true;
        this.externalRole = this.permissionsService.isRequestingEntityRole();

        if (this.externalRole) {
            this.setItcpRequestHeader()
            this.service.getAllRequests({
                params: {
                    requestStatus:ProjectFlow.itcpStatusesForProjects(),
                    stage: ProjectStagesEnum.ITCP,
                }
            })
                .then((response) => {
                    this.data = response;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => this.loading = false);
        } else {
            this.setProjectHeader();
            this.service.getAll({
                params: {
                    status: ProjectFlow.itcpStatusesForProjects(),
                    stage: ProjectStagesEnum.ITCP,
                }
            })
                .then((response) => {
                    this.data = response;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => this.loading = false);
        }
    }

    reload() {
        this.getAll();
    }

    setFilter(event: any, type: string, query: string, column: string = ''): void {
        if (type === 'filterGlobal') {
            this.table.filterGlobal(event.target.value, query);
        } else if (type === 'filter') {
            this.table.filter(event.target.value, column, query);
        }
    }

    retrieveProject(id: number, itcpRequest?: IITCPRequest) {

        const itcpRequestId = itcpRequest?.id || 0;
        if (id > 0) {
            this.service.get(id).then((project) => {
                const route = `/itcp/projects/${project.id}/basic-info`;
                this.router.navigate([route]).then();
            });
        } else if (itcpRequestId > 0) {
            this.service.getByITCPRequestId(itcpRequestId).then((project) => {
                if (project) {
                    const route = `/itcp/projects/${project.id}/basic-info`;
                    this.router.navigate([route]).then();
                } else {
                    const newProject: IProject = {
                        itcpRequestId,
                        name: PROJECT_NO_NAME,
                        years: itcpRequest?.announcement?.yearsLimit,
                    }
                    this.service.store(newProject).then((project) => {
                        const route = `/itcp/projects/${project?.id}/basic-info`;
                        this.router.navigate([route]).then();
                    });
                }
            });
        }
    }

    setProjectHeader() {
        this.header = [
            { label: 'Convocatoria', column: 'description', sort: true },
            { label: 'Proyecto', column: 'name', sort: true },
            { label: 'Entidad Solicitante', column: 'entityId', sort: true },
            { label: 'MAE', column: 'seoName', sort: true },
            { label: ' ', sort: false },
        ]

    }

    setItcpRequestHeader() {
        this.header =  [
            { label: 'Entidad Solicitante', column: 'entityId', sort: true },
            { label: 'MAE', column: 'seoName', sort: true },
            { label: 'Datos de Contacto', column: 'contactEmail', sort: false },
            { label: ' ', sort: false },
        ]
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(undefined);
        this.onDestroy$.complete();
    }

}
