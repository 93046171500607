import { IITCPRequest } from './itcp-request.interface';
import { IProjectGoal } from './project-goal.interface';
import { IProjectBeneficiary } from './project-beneficiary.interface';
import { IProjectAct } from './project-act.interface';
import { IProjectProperty } from './project-property.interface';
import { IProjectEnvironment } from './project-environment.interface';
import { IProjectRisk } from './project-risk.interface';
import { IProjectGeneralBudget } from './project-general-budget.interface';
import { IAttachment, ITypology } from '../../../shared';
import { IProjectAssignment } from './project-assignment.interface';
import { IProjectBaseLine, StageType } from '../../../edtp';
import { IProjectRequirement } from '../../components/project-assessment/interfaces/project-requirement.interface';
import { IProjectAssessment } from '../../components/project-assessment/interfaces/project-assessment.interface';
import { IProjectStartOrder } from '../../../tracking/models';
import { IProjectBudget } from '../../../edtp/models/interfaces/project-budget.interface';

export interface IProject {
  id?: number;
  name?: string;
  typologies?: ITypology[];
  years?: number;
  justified?: boolean;
  noJustifiedDescription?: string;
  history?: string;
  diagnosis?: string;
  statement?: string;
  items?: string;
  solution1?: string;
  solution2?: string;
  chosenSolution?: number;
  justificationSolution?: string;
  iTCPRequest?: IITCPRequest;
  itcpRequestId?: number;
  generalGoal?: string;
  advantage?: string;
  goals?: IProjectGoal[];
  beneficiaries?: IProjectBeneficiary[];
  acts?: IProjectAct[];
  dataProperty?: IProjectProperty;
  environments?: IProjectEnvironment[];
  risks?: IProjectRisk[];
  details?: string;
  budgets?: IProjectGeneralBudget[];
  projectBudgets?: IProjectBudget[];
  conclusions?: string;
  recommendations?: string;
  swornFileId?: number;
  swornFile?: IAttachment;
  itcpFileId?: number;
  itcpFile?: IAttachment;
  requestFinancingFileId?: number;
  requestFinancingFile?: IAttachment;
  status?: string;
  technicians?: IProjectAssignment[];
  baseLines?: IProjectBaseLine[];
  requirements?: IProjectRequirement[];
  assessments?: IProjectAssessment[];
  referenceId?: number;
  stage?: StageType;
  score?: number;
  notificationFile?: IAttachment;
  notificationFileId?: number;
  edtpNotificationFile?: IAttachment;
  edtpNotificationFileId?: number;
  totalOwnerInversionAmount?: number;
  totalEntityInversionAmount?: number;
  totalInversionAmount?: number;
  totalTrainedMen?: number;
  totalTrainedWomen?: number;
  totalAssistedMen?: number;
  totalAssistedWomen?: number;
  totalBenefitedMen?: number;
  totalBenefitedWomen?: number;
  startOrder?: IProjectStartOrder;
  startOrderDate?: Date;
}
