import { Component, OnDestroy, OnInit } from '@angular/core';
import { ITCPRequest } from '../../../models';
import { ActivatedRoute } from '@angular/router';
import { ITCPRequestService } from '../../../../itcp';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Constants } from '../../../../shared/constants';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
})
export class ContactInfoComponent extends ITCPRequest implements OnInit, OnDestroy {
  private step: number = 4;
  constructor(
    private activatedRoute: ActivatedRoute,
    private readonly iTCPRequestService: ITCPRequestService,
    private tService: ToasterService,
    private fb: FormBuilder
  ) {
    super(activatedRoute, iTCPRequestService, tService);
  }

  ngOnInit(): void {
    this.readParams();
    this.showContent = false;
    this.setCurrentRequest()
      .then((request) => {
        const { id, announcementCode, announcementId, token, contactEmail } = request || {};

        this.formGroup = this.fb.group({
          id: [id],
          announcementCode: [announcementCode],
          announcementId: [announcementId],
          token: [token],
          contactEmail: [contactEmail, [Validators.required, Validators.email]],
          requestStatus: [Constants.PROJECT_STATUSES.COMPLETED],
          readOnly: [true],
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
          this.disableFormIfReadonly();
        this.showContent = true;
      });
    this.setActive(this.step);
  }

  ngOnDestroy() {
    this.cleanParams();
  }
}
