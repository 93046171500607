import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { Subject, takeUntil } from 'rxjs';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IITCPRequest } from '../../models';
import { Constants } from '../../../shared/constants';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { GenericFunctions } from '../../../shared/services/generic-functions';
import { ITCPRequestService } from '../../services';
import { ToasterService } from '../../../shared/services/toaster.service';
import { RequestModalComponent } from './request-modal/request-modal.component';
import { GenerateUserComponent } from './generate-user/generate-user.component';
import {ProjectFlow} from "../../../shared/services/project.flow";
import {ProjectStagesEnum} from "../../../shared";
@Component({
    selector: 'app-itcp-request',
    templateUrl: './itcp-request.component.html',
    styleUrls: ['./itcp-request.component.scss']
})
export class ITCPRequestComponent implements OnInit, OnDestroy {

    public loading = false;
    public data: IITCPRequest[] = [];
    public resource = Constants.RESOURCES.ITCP_REQUESTS;
    public breadcrumbs: MenuItem[] = [
        { label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home'] },
        { label: ' ITCP', routerLink: ['/itcp'] },
        { label: ' Solicitudes' },
    ];

    public header: Array<any> = [
        { label: 'Entidad Solicitante', column: 'entityId', sort: true },
        { label: 'MAE', column: 'seoName', sort: true },
        { label: 'Datos de Contacto', column: 'contactEmail', sort: false },
        { label: 'Verificación', column: '', sort: false },
        { label: ' ', sort: false },
    ];

    @ViewChild('dtITCPRequest') table!: Table;
    private onDestroy$ = new Subject();
    public ref!: DynamicDialogRef;
    private modal: DynamicDialogConfig<IITCPRequest> = {
        ...Constants.MODAL.default,
        width: '50%',
        closable: false,
    };

    constructor(
        private readonly genericFunctions: GenericFunctions,
        public readonly dialogService: DialogService,
        public readonly confirmationService: ConfirmationService,
        private readonly toasterService: ToasterService,
        private readonly service: ITCPRequestService,
    ) {}

    ngOnInit(): void {
        this.getAll();
    }

    getAll(): void {
        this.loading = true;
        this.service.getAll({
            params: {
                requestStatus: ProjectFlow.itcpStatusesForRequests(),
                stage: ProjectStagesEnum.ITCP,
            }
        })
            .then((response) => {
                this.data = response;
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => this.loading = false);
    }

    reload() {
        this.getAll();
    }

    setFilter(event: any, type: string, query: string, column: string = ''): void {
        if (type === 'filterGlobal') {
            this.table.filterGlobal(event.target.value, query);
        } else if (type === 'filter') {
            this.table.filter(event.target.value, column, query);
        }
    }

    selectedItem(data: IITCPRequest, type: string) {
        switch (type) {
            case 'delete':
                this.confirmationService.confirm({
                    message: '¿Se encuentra seguro de eliminar este registro?',
                    accept: () => {
                        if (data.id != null) {
                            this.service
                                .delete(data.id)
                                .then((resp: boolean) => {
                                    if (resp) {
                                        this.toasterService.success('La Solicitud fué eliminada con éxito.');
                                        this.reload();
                                    }
                                })
                                .catch((err) => {
                                    console.error(err);
                                    this.toasterService.error('Se ha producido un error inténtelo más tarde o contáctese con el Administrador.');
                                });
                        }
                    },
                });
                break;
            case 'verify':
                this.modal.data = data;
                this.modal.header = 'Verificar Solicitud';
                this.ref = this.dialogService.open(RequestModalComponent, this.modal);
                this.ref.onClose.pipe(takeUntil(this.onDestroy$)).subscribe((resp) => {
                    if (resp)  this.reload();
                });
                break;
            case 'generateUser':
                this.modal.data = data;
                this.modal.header = 'Generar Usuario';
                this.ref = this.dialogService.open(GenerateUserComponent, this.modal);
                this.ref.onClose.pipe(takeUntil(this.onDestroy$)).subscribe((resp) => {
                    if (resp)  this.reload();
                });
                break;
            default:
                break;
        }
    }

    isVerified(item: IITCPRequest): boolean {
        return item.requestStatus === Constants.PROJECT_STATUSES.VERIFIED;
    }

    readRequest(data: IITCPRequest) {
        const url = '/public/itcp-request/' + data.token + '/basic-info';
        const id = data.id ? parseInt(data.id.toString()) : 0;
        this.service.setReadonly(id, true).then((resp) => {
            if (resp) {
                window.open(url, '_blank');
            }
        });
    }

    editRequest(data: IITCPRequest) {
        const url = '/public/itcp-request/' + data.token + '/basic-info';
        const id = data.id ? parseInt(data.id.toString()) : 0;
        this.service.setReadonly(id, false).then((resp) => {
            if (resp) {
                window.open(url, '_blank');
            }
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(undefined);
        this.onDestroy$.complete();
    }

}
