<p-table [value]="projectBudgets" dataKey="id" editMode="row" [tableStyle]="{'width': '100%'}" #dt>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th class="bg-secondary border-0 component" style="width: 250px">
                <div class="flex justify-content-start gap-4 align-items-center">
                    <span class="uppercase">{{title}}</span>
                    <button
                        [disabled]='isSubmitted'
                        class='p-button p-button-success btn-small'
                        pButton pTooltip="Agregar Partida" tooltipPosition="top"
                        (click)='onNew()'
                    >
                        <i class="pi pi-plus"></i>
                    </button>
                </div>
            </th>
            <th class="bg-secondary border-0" style="width:100px"></th>
            <th class="bg-secondary border-0" style="width:100px"></th>
            <th class="bg-secondary border-0" style="width:100px"></th>
            <th class="bg-secondary border-0" style="width:100px"></th>
            <th class="bg-secondary border-0" style="width:100px"></th>
            <th class="bg-secondary border-0" style="width:100px"></th>
            <th *ngIf="isValidYear(2)" class="bg-secondary border-0" style="width:100px"></th>
            <th *ngIf="isValidYear(2)" class="bg-secondary border-0" style="width:100px"></th>
            <th *ngIf="isValidYear(2)" class="bg-secondary border-0" style="width:100px"></th>
            <th *ngIf="isValidYear(3)" class="bg-secondary border-0" style="width:100px"></th>
            <th *ngIf="isValidYear(3)" class="bg-secondary border-0" style="width:100px"></th>
            <th *ngIf="isValidYear(3)" class="bg-secondary border-0" style="width:100px"></th>
            <th class="bg-secondary border-0" style="width:100px"></th>
            <th class="bg-secondary border-0" style="width:100px"></th>
            <th class="bg-secondary border-0" style="width:100px"></th>
            <th class="bg-secondary border-0" style="width:100px"></th>
            <th class="bg-secondary border-0" style="width:100px"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-obj let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="obj">
            <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-autoComplete
                            [suggestions]="budgetItems"
                            [dropdown]='true'
                            (completeMethod)="filterBudgetItems($event)"
                            (onSelect)='onSelectBudgetItem($event, obj)'
                            field='code'
                            placeholder='Partida Presupuestaria'
                            [minLength]='3'>
                            <ng-template let-item pTemplate="item">
                                <div class="budget-item w-30rem">
                                    <div class="budget-item-title" style="white-space: pre-wrap">
                                        <b>{{item.code}}</b> - {{item.title}}
                                    </div>
                                    <div class='text-xs text-bluegray-300 budget-item-description' style="white-space: pre-wrap">
                                        {{item.description}}
                                    </div>
                                </div>
                            </ng-template>

                        </p-autoComplete>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <b>{{obj.budgetItemCode}}</b> - {{obj.budgetItemDescription}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText
                               required
                               placeholder="Unidad"
                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                               type="text" [(ngModel)]="obj.uom" [ngModelOptions]="{standalone: true}">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.uom}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText
                               required
                               min='0'
                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                               type="number" [(ngModel)]="obj.price" [ngModelOptions]="{standalone: true}">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.price | number: '1.2'}}
                    </ng-template>
                </p-cellEditor>
            </td>

            <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText
                               required
                               min='0'
                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                               type="number" [(ngModel)]="obj.quantity" [ngModelOptions]="{standalone: true}">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.quantity}}
                    </ng-template>
                </p-cellEditor>
            </td>

            <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText
                               required
                               min='0'
                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                               type="number" [(ngModel)]="obj.ownerY1" [ngModelOptions]="{standalone: true}">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.ownerY1 | number: '1.2'}}
                    </ng-template>
                </p-cellEditor>
            </td>

            <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText
                               required
                               min='0'
                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                               type="number" [(ngModel)]="obj.entityY1" [ngModelOptions]="{standalone: true}">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.entityY1 | number: '1.2'}}
                    </ng-template>
                </p-cellEditor>
            </td>

            <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText
                               required
                               min='0'
                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                               type="number" [(ngModel)]="obj.entityInKindY1" [ngModelOptions]="{standalone: true}">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.entityInKindY1 | number: '1.2'}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td *ngIf="isValidYear(2)">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText
                               required
                               min='0'
                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                               type="number" [(ngModel)]="obj.ownerY2" [ngModelOptions]="{standalone: true}">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.ownerY2 | number: '1.2'}}
                    </ng-template>
                </p-cellEditor>
            </td>

            <td *ngIf="isValidYear(2)">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText
                               required
                               min='0'
                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                               type="number" [(ngModel)]="obj.entityY2" [ngModelOptions]="{standalone: true}">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.entityY2 | number: '1.2'}}
                    </ng-template>
                </p-cellEditor>
            </td>

            <td *ngIf="isValidYear(2)">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText
                               required
                               min='0'
                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                               type="number" [(ngModel)]="obj.entityInKindY2" [ngModelOptions]="{standalone: true}">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.entityInKindY2 | number: '1.2'}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td *ngIf="isValidYear(3)">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText
                               required
                               min='0'
                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                               type="number" [(ngModel)]="obj.ownerY3" [ngModelOptions]="{standalone: true}">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.ownerY3 | number: '1.2'}}
                    </ng-template>
                </p-cellEditor>
            </td>

            <td *ngIf="isValidYear(3)">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText
                               required
                               min='0'
                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                               type="number" [(ngModel)]="obj.entityY3" [ngModelOptions]="{standalone: true}">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.entityY3 | number: '1.2'}}
                    </ng-template>
                </p-cellEditor>
            </td>

            <td *ngIf="isValidYear(3)">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText
                               required
                               min='0'
                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                               type="number" [(ngModel)]="obj.entityInKindY3" [ngModelOptions]="{standalone: true}">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.entityInKindY3 | number: '1.2'}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td>
                <b>{{obj.totals.owner | number: '1.2'}}</b>
            </td>
            <td>
                <b>{{obj.totals.entity | number: '1.2'}}</b>
            </td>
            <td>
                <b>{{obj.totals.entityInKind | number: '1.2'}}</b>
            </td>
            <td>
                <b>{{obj.totals.total | number: '1.2'}}</b>
            </td>
            <td>
                <div *ngIf="!isSubmitted" class="flex align-items-center justify-content-center gap-2">
                    <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(obj)" class="p-button-rounded p-button-text"></button>
                    <button *ngIf="!editing" pButton pRipple type="button" icon="pi pi-trash" (click)="onDelete(obj)" class="p-button-rounded p-button-text  p-button-danger"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(obj)" class="p-button-rounded p-button-text p-button-success mr-2" [disabled]="!getForm(obj)?.valid"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(obj, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
