<app-wizard
    [steps]='steps'
    [current]='currentStep'
    [bodyContentTpl]='projectForm'
    [nextDisabled]='this.formGroup?.invalid'
    (onClickNext)='save($event)'
    [layout]='true'
>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column' [formGroup]='formGroup'>
        <div class='grid'>
            <div class='field col-12'>
                <p-table [value]="[projectBudget]" dataKey="id" (onEditComplete)='onEditComplete($event)' (onEditInit)="onEditInit($event)">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th style="width:20%">FONABOSQUE (Bs.)</th>
                            <th style="width:16%">Entidad Solicitante (Bs.)</th>
                            <th style="width:16%">Entidad Solicitante en Especie (Bs.)</th>
                            <th style="width:16%">Total </th>
                            <th style="width:16%">FONABOSQUE(%) </th>
                            <th style="width:16%">Entidad Solicitante(%) </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item>
                        <tr>
                            <td [pEditableColumn]="item" [pEditableColumnField]="'owner'">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number"
                                               min='0'
                                               [disabled]='isSubmitted'
                                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                                               [(ngModel)]="item.owner" required [ngModelOptions]="{standalone: true}">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{item.owner | number: '1.2-2' }}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td [pEditableColumn]="item" [pEditableColumnField]="'entity'">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number"
                                               [disabled]='isSubmitted'
                                               min='0'
                                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                                               [(ngModel)]="item.entity" required [ngModelOptions]="{standalone: true}">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{item.entity | number: '1.2-2'}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td [pEditableColumn]="item" [pEditableColumnField]="'entityInKind'">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText
                                               type='number'
                                               [disabled]='isSubmitted'
                                               min='0'
                                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                                               [(ngModel)]="item.entityInKind" required [ngModelOptions]="{standalone: true}">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{item.entityInKind | number: '1.2-2'}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <span class="total">
                                    {{item.total | number: '1.2' }}
                                </span>
                            </td>
                            <td>
                                <span class="total">
                                    {{item.ownerPercentage | number: '1.0-1' }} %
                                </span>
                            </td>
                            <td>
                                <div class="total w-full">
                                     {{item.totalEntityPercentage | number: '1.0-1' }} %
                                </div>
                                <div class="mt-3 text-sm">
                                    <b>Efectivo</b>: {{item.entityPercentage | number: '1.0-1' }} %<br/>
                                    <b>Especie</b>: {{item.entityInKindPercentage | number: '1.0-1' }} %
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</ng-template>

