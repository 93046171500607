<app-wizard
  [steps]="steps"
  [current]="currentStep"
  [bodyContentTpl]="contactInfoForm"
  [nextDisabled]="this.formGroup?.invalid"
  (onClickNext)="save($event)"
>
</app-wizard>

<ng-template #contactInfoForm>
  <div *ngIf="showContent" class="contact-form flex justify-content-center flex-column mb-8" [formGroup]="formGroup">
    <div class="field">
      <label>Correo Electrónico</label>
      <input
        id="contactEmail"
        pInputText
        [ngClass]="{ 'ng-invalid ng-dirty': form.contactEmail.errors && form.contactEmail.touched }"
        formControlName="contactEmail"
        placeholder="Correo Electrónico de Contacto"
        type="text"
        class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
      />
      <div *ngIf="form.contactEmail.errors">
        <p *ngIf="form.contactEmail.errors?.required && form.contactEmail.touched" class="text-danger">
          *Campo requerido
        </p>

        <p *ngIf="form.contactEmail.errors?.email && form.contactEmail.touched" class="text-danger">
          Introduzcs una correo electrónico válido
        </p>
      </div>
    </div>
  </div>
</ng-template>
