import {Component, OnInit, ViewChild} from '@angular/core';
import {Constants} from "../../../shared/constants";
import {IProject, IProjectAssignment, ProjectService} from "../../../itcp";
import {MenuItem} from "primeng/api";
import {Table} from "primeng/table";
import {CredentialsService, ProjectFlow, ProjectStagesEnum} from "../../../shared";
import {PermissionService} from "../../../shared/services/permission.service";
import {Router} from "@angular/router";
import {ToasterService} from "../../../shared/services/toaster.service";
import {User} from "../../../core/user/interfaces";
import {GenericFunctions} from "../../../shared/services/generic-functions";
import {LayoutService} from "../../../shared/services/app.layout.service";

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss']
})
export class AssessmentComponent implements OnInit {
    resource = Constants.RESOURCES.EDTP_FORM;
    statuses:any = {};
    statusesClassNames:any = {};
    projects: IProject[] = []; //Assigned projects

    loading: boolean = false;
    breadcrumbs: MenuItem[] = [
        { label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home'] },
        { label: ' EDTP', routerLink: ['/edtp'] },
        { label: ' Formulación del EDTP' },
    ];
    @ViewChild('dt') table!: Table;

    constructor(
        private readonly credentialsService: CredentialsService,
        private readonly permissionService: PermissionService,
        private readonly projectService: ProjectService,
        private readonly router: Router,
        private readonly toasterService: ToasterService,
        private readonly layoutService: LayoutService,
    ) {}

    ngOnInit(): void {
        this.layoutService.showMenuDesktop(true);
        this.getAll();
    }

    getAll(): void {
        this.loading = true;
        this.projectService.getAll({ params: { assignments: true, stage: ProjectStagesEnum.EDTP, status: ProjectFlow.edtpStatusesForAssessment()}})
            .then((resp) => {
                this.projects = [...resp];
            })
            .finally(() => {
                this.loading = false;
            });
    }

    setFilter(event: any, type: string, query: string, column: string = ''): void {
        if (type === 'filterGlobal') {
            this.table.filterGlobal(event.target.value, query);
        } else if (type === 'filter') {
            this.table.filter(event.target.value, column, query);
        }
    }

    assessProject(projectId: number) {
        this.projectService.get(projectId).then((resp) => {
            if (resp) {
                const route = `/edtp/assessment/${resp.id}/check-content`;
                this.router.navigate([route]).then();
            }
        }).catch((error) => {
            console.log(error);
            this.toasterService.error('Se ha producido un error inténtelo más tarde o contáctese con el Administrador.');
        })

    }

    getFullName(user: User): string {
        return GenericFunctions.getUserFullName(user);
    }

    canAssess(assignments: IProjectAssignment[]) {
        if (this.permissionService.isSuperAdminRole()) return true;
        const currentUser: User = this.credentialsService.getLoggedUser();
        const assigment = assignments.find(a => a.userId === currentUser.id);
        if (!assigment) return false;

        return [Constants.TECHNICIAN_PERMISSIONS.REVIEW_EVAL].includes(assigment.permissions)
    }
}
