import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { BaseHttpService, HttpOptions } from '../../shared';
import { Entidad } from './interfaces/entidad.interface';
import { TipoEntidad } from './interfaces/tipo-entidad.interface';

@Injectable({
  providedIn: 'root',
})
export class EntidadService {
  constructor(private readonly _baseHttpService: BaseHttpService) {}

  public getEntidades(options?: HttpOptions): Promise<Entidad[]> {
    return lastValueFrom(this._baseHttpService.get('entidad', options));
  }

  getTipoEntidades(): Promise<TipoEntidad[]> {
    return lastValueFrom(this._baseHttpService.get('entidad/tipos'));
  }

  public storeEntidad(data: Entidad): Promise<Entidad> {
    return lastValueFrom(this._baseHttpService.post('entidad', data));
  }

  public updateEntidad(data: Entidad, entidadId: number): Promise<Entidad> {
    return lastValueFrom(this._baseHttpService.put(`entidad/${entidadId}`, data));
  }

  public deleteEntidad(entidadId: number): Promise<boolean> {
    return lastValueFrom(this._baseHttpService.delete(`entidad/${entidadId}`));
  }
}
