import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Injectable({
  providedIn: 'root'
})
export class Html2canvasService {

  async getFonabosqueLogo(): Promise<string> {
    const imgElement = document.getElementById('fonabosque-logo-img');
    // @ts-ignore
    const canvas = await html2canvas(imgElement);
    return canvas.toDataURL('image/png');
  }

  async getImage(element: HTMLElement): Promise<HTMLCanvasElement> {
    return html2canvas(element);
  }
}