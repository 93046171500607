<app-wizard [steps]='steps' [current]='currentStep' [bodyContentTpl]='projectForm'
    [nextDisabled]='this.formGroup?.invalid' (onClickNext)='save($event)' [layout]='true'>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column'>
        <div class='grid'>
            <div class="field col-12 text-2xl text-primary uppercase">
                {{currentStep?.text}}
            </div>

            <div class="field col-12">
                <p-table [value]="projectBudgetsComponents" dataKey="id" [tableStyle]="{'min-width': '100%'}">
                    <ng-template pTemplate="header">
                        <col>
                        <col>
                        <colgroup span="{{ (currentProject?.years || 0) * 4 }}"></colgroup>
                        <colgroup span="4"></colgroup>
                        <tr>
                            <th class="bg-primary" style="width:50px"></th>
                            <th class="bg-primary" style="width:250px"></th>
                            <th class="bg-primary text-center border-x-1"
                                [attr.colspan]="(currentProject?.years || 0) * 4" scope="colgroup">FUENTE DE
                                FINANCIAMIENTO EN (Bs.)</th>
                            <th class="bg-primary text-center border-x-1" colspan="4" scope="colgroup">PRESUPUESTO TOTAL
                                DE FINANCIAMIENTO EN EFECTIVO(Bs.)</th>
                        </tr>
                        <tr>
                            <th class="bg-primary" style="width:50px"></th>
                            <th class="bg-primary" style="width:250px"></th>
                            <th colspan="4" scope="colgroup" class="bg-primary text-center border-x-1">AÑO Nro. 1</th>
                            <th *ngIf="isValidYear(2)" colspan="4" scope="colgroup"
                                class="bg-primary text-center border-x-1">AÑO Nro. 2</th>
                            <th *ngIf="isValidYear(3)" colspan="4" scope="colgroup"
                                class="bg-primary text-center border-x-1">AÑO Nro. 3</th>
                            <th colspan="1" class="bg-primary text-center"></th>
                            <th colspan="1" class="bg-primary text-center"></th>
                            <th colspan="1" class="bg-primary text-center"></th>
                            <th colspan="1" class="bg-primary text-center"></th>
                        </tr>
                        <tr>
                            <th class="bg-primary" style="width:50px">NRO.</th>
                            <th class="bg-primary" style="width:250px">DESCRIPCIÓN</th>
                            <th class="bg-secondary border-0 component border-x-1">FONABOSQUE</th>
                            <th class="bg-secondary border-0 component border-x-1">ENTIDAD EFECTIVO</th>
                            <th class="bg-secondary border-0 component border-x-1">ENTIDAD ESPECIE</th>
                            <th class="bg-secondary border-0 component border-x-1">TOTAL</th>
                            <th *ngIf="isValidYear(2)" class="bg-secondary border-0 component border-x-1">FONABOSQUE
                            </th>
                            <th *ngIf="isValidYear(2)" class="bg-secondary border-0 component border-x-1">ENTIDAD
                                EFECTIVO</th>
                            <th *ngIf="isValidYear(2)" class="bg-secondary border-0 component border-x-1">ENTIDAD
                                ESPECIE</th>
                            <th *ngIf="isValidYear(2)" class="bg-secondary border-0 component border-x-1">TOTAL</th>
                            <th *ngIf="isValidYear(3)" class="bg-secondary border-0 component border-x-1">FONABOSQUE
                            </th>
                            <th *ngIf="isValidYear(3)" class="bg-secondary border-0 component border-x-1">ENTIDAD
                                EFECTIVO</th>
                            <th *ngIf="isValidYear(3)" class="bg-secondary border-0 component border-x-1">ENTIDAD
                                ESPECIE</th>
                            <th *ngIf="isValidYear(3)" class="bg-secondary border-0 component border-x-1">TOTAL</th>
                            <th class="bg-secondary border-0 component border-x-1">FONABOSQUE</th>
                            <th class="bg-secondary border-0 component border-x-1">ENTIDAD EFECTIVO</th>
                            <th class="bg-secondary border-0 component border-x-1">ENTIDAD ESPECIE</th>
                            <th class="bg-secondary border-0 component border-x-1">TOTAL</th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-obj let-rowIndex="rowIndex">
                        <tr>
                            <td>{{ obj.type ? (rowIndex + 1) : ''}}</td>
                            <td>{{obj.description}}</td>
                            <td>{{obj.year1.owner | number: '1.2'}}</td>
                            <td>{{obj.year1.entity | number: '1.2'}}</td>
                            <td>{{obj.year1.entityInKind | number: '1.2'}}</td>
                            <td><b>{{obj.year1.total | number: '1.2'}}</b></td>
                            <td *ngIf="isValidYear(2)">{{obj.year2.owner | number: '1.2'}}</td>
                            <td *ngIf="isValidYear(2)">{{obj.year2.entity | number: '1.2'}}</td>
                            <td *ngIf="isValidYear(2)">{{obj.year2.entityInKind | number: '1.2'}}
                            </td>
                            <td *ngIf="isValidYear(2)"><b>{{obj.year2.total | number: '1.2'}}</b>
                            </td>
                            <td *ngIf="isValidYear(3)">{{obj.year3.owner | number: '1.2'}}</td>
                            <td *ngIf="isValidYear(3)">{{obj.year3.entity | number: '1.2'}}</td>
                            <td *ngIf="isValidYear(3)">{{obj.year3.entityInKind | number: '1.2'}}
                            </td>
                            <td *ngIf="isValidYear(3)"><b>{{obj.year3.total | number: '1.2'}}</b>
                            </td>
                            <td>{{obj.totals.owner | number: '1.2'}}</td>
                            <td>{{obj.totals.entity | number: '1.2'}}</td>
                            <td>{{obj.totals.entityInKind | number: '1.2'}}</td>
                            <td><b>{{obj.totals.total | number: '1.2'}}</b></td>

                        </tr>
                    </ng-template>

                </p-table>
            </div>
        </div>
    </div>
</ng-template>
