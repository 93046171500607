import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {
    IProjectGoalActivity,
    IProjectGoalResult,
    ProjectGoalActivityService
} from "../../../../../edtp";
import {Table} from "primeng/table";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToasterService} from "../../../../../shared/services/toaster.service";
import {AttachmentService} from "../../../../../shared";
import {User} from "../../../../../core/user/interfaces";
import {GenericFunctions} from "../../../../../shared/services/generic-functions";

@Component({
    selector: 'app-reporting-fs-activities',
    templateUrl: './activities.component.html',
    styleUrls: ['./activities.component.scss']
})
export class ReportFSActivitiesComponent implements OnInit {
    @Input() group!: IProjectGoalResult;
    @Input() selectedYear!: number;

    cloned: { [s: string]: IProjectGoalActivity; } = {};
    @ViewChild('dt') table!: Table;

    constructor(
        private readonly activityService: ProjectGoalActivityService,
        public readonly attachmentService: AttachmentService,
        private fb: FormBuilder,
        private toasterService: ToasterService,
    ) {
    }

    ngOnInit(): void {
        if (!this.group.activities)
            this.group.activities = [];
    }

    onRowEditInit(obj: IProjectGoalActivity) {
        if (!obj?.id) return;
        this.cloned[obj.id] = {...obj};
    }

    getForm(obj: IProjectGoalActivity): any {
        if (!obj?.id) return;
        const { currentMonth } = obj;
        if (!currentMonth) return;

        const form: FormGroup = this.fb.group({
            activityId: [currentMonth.activityId, Validators.required],
            expected: [currentMonth.expected, Validators.required],
            executed: [currentMonth.executed, [Validators.required, Validators.min(0), Validators.max(currentMonth.yearExpected || 0)]],
            year: [currentMonth.year, Validators.required],
            month: [currentMonth.month, Validators.required],
            verifiableIds: [currentMonth.verifiableIds],
            currentObservation: [currentMonth.currentObservation, Validators.required],
        });
        return form;
    }

    onRowEditSave(obj: IProjectGoalActivity) {
        if (!obj?.id || !obj.currentMonth?.id) return;

        const form = this.getForm(obj);
        const { currentMonth } = obj;

        if (form?.valid) {
            delete this.cloned[obj.id];

            if (currentMonth.id?.toString().includes('new_')) {
                const index = this.group.activities.indexOf(obj);
                this.activityService.storeActivityMonth(form.value).then((resp) => {
                    this.group.activities[index].currentMonth = resp;
                    this.toasterService.success('El Registro fue creado satisfactoriamente.');
                });

            } else {
                const id = currentMonth?.id ? parseInt(currentMonth.id.toString()) : 0;
                const index = this.group.activities.indexOf(obj);
                this.activityService.updateActivityMonth(form.value, id).then((resp) => {
                    const currentMonth = this.group.activities[index].currentMonth;
                    if (currentMonth) {
                        currentMonth.observations = resp.observations;
                    }
                    this.toasterService.success('El Registro fue actualizado satisfactoriamente.');
                })
            }

        } else {
            this.toasterService.error('No se pudo guardar, los datos son inválidos!');
        }
    }

    onRowEditCancel(obj: IProjectGoalActivity, index: number) {
        if (!obj?.id) return;

        if (this.cloned[obj.id]) {
            this.group.activities[index] = this.cloned[obj.id];
            delete this.cloned[obj.id];
        }

        if (obj?.id.toString().includes('new_')) {
            this.group.activities = this.group.activities.filter(o => o.id !== obj.id);
        }
    }

    calcPMG(act: IProjectGoalActivity) {
        if (!act.currentMonth) return 0;
        const yearExpected = act.currentMonth.yearExpected || 0;

        if (yearExpected === 0) return 0;

        return (act.currentMonth.expected / yearExpected) * 100;
    }

    calcEMG(act: IProjectGoalActivity) {
        if (!act.currentMonth) return 0;
        const yearExpected = act.currentMonth.yearExpected || 0;

        if (yearExpected === 0) return 0;

        return (act.currentMonth.executed / yearExpected) * 100;
    }

    getFullName(user: User): string {
        return GenericFunctions.getUserFullName(user);
    }
}
