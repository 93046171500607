import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IProjectCostEfficiency } from '../../../models/interfaces/project-cost-efficiency.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Table } from 'primeng/table';
import { ProjectService } from '../../../../itcp';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { ProjectCostEfficiencyService } from '../../../services/project-cost-efficiency.service';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-cost-efficiency',
    templateUrl: './cost-efficiency.component.html',
    styleUrls: ['./cost-efficiency.component.scss']
})
export class CostEfficiencyComponent implements OnInit,  OnDestroy {

    breadcrumbs: MenuItem[] = [
        { label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home'] },
        { label: ' EDTP', routerLink: ['/edtp'] },
        { label: ' Evaluación', routerLink: ['/edtp/eval'] },
        { label: ' Costo Eficiencia' }
    ];

    components: IProjectCostEfficiency[] = [];
    cloned: { [s: string]: IProjectCostEfficiency; } = {};
    private sub: any;
    private projectId!: number;
    loading!: boolean;

    @ViewChild('dt') table!: Table;
    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly costEfficiencyService: ProjectCostEfficiencyService,
        private tService: ToasterService,
        private fb: FormBuilder,
    ) {}

    ngOnInit(): void {
        this.loading = true;
        this.readParams();
        this.costEfficiencyService.getAll({ params: { projectId: this.projectId }}).then((resp) => {
            this.initComponents(resp);
        }).finally(() => {
            this.loading = false;
        });
    }

    initComponents(items: IProjectCostEfficiency[]) {
        const c1 = items.find(i => i.componentType === 'COMPONENT1') || {};
        const c2 = items.find(i => i.componentType === 'COMPONENT2') || {};
        const c3 = items.find(i => i.componentType === 'COMPONENT3') || {};
        const c4 = items.find(i => i.componentType === 'COMPONENT4') || {};
        const c5 = items.find(i => i.componentType === 'COMPONENT5') || {};
        const c6 = items.find(i => i.componentType === 'ADMINISTRATION') || {};
        const c7 = items.find(i => i.componentType === 'AUDIT') || {};
        const item: IProjectCostEfficiency = {
            id: `new_add_${new Date().getTime()}_1`,
            componentName: 'COMPONENTE NRO. 1: CONSTRUCCIÓN DE LA INFRAESTRUCTURA PRODUCTIVA',
            capacity: 0,
            componentType: 'COMPONENT1',
            cost: 0,
            projectId: this.projectId,
            quantity: 0,
            ...c1,
        }
        this.components.push(item);

        const item2: IProjectCostEfficiency = {
            id: `new_add_${new Date().getTime()}_2`,
            componentName: 'COMPONENTE NRO. 2: PRODUCCIÓN DE PLANTINES',
            capacity: 0,
            componentType: 'COMPONENT2',
            cost: 0,
            projectId: this.projectId,
            quantity: 0,
            ...c2,
        }
        this.components.push(item2);
        const item3: IProjectCostEfficiency = {
            id: `new_add_${new Date().getTime()}_3`,
            componentName: 'COMPONENTE NRO. 3: REFORESTACIÓN E IMPLEMENTACIÓN DE SISTEMAS AGROFORESTALES',
            capacity: 0,
            componentType: 'COMPONENT3',
            cost: 0,
            projectId: this.projectId,
            quantity: 0,
            ...c3,
        }
        this.components.push(item3);
        const item4: IProjectCostEfficiency = {
            id: `new_add_${new Date().getTime()}_4`,
            componentName:  'COMPONENTE NRO. 4: CAPACITACIÓN',
            capacity: 0,
            componentType: 'COMPONENT4',
            cost: 0,
            projectId: this.projectId,
            quantity: 0,
            ...c4,
        }
        this.components.push(item4);
        const item5: IProjectCostEfficiency = {
            id: `new_add_${new Date().getTime()}_5`,
            componentName: 'COMPONENTE NRO. 5: ASISTENCIA TÉCNICA',
            capacity: 0,
            componentType: 'COMPONENT5',
            cost: 0,
            projectId: this.projectId,
            quantity: 0,
            ...c5,
        }
        this.components.push(item5);
        const item6: IProjectCostEfficiency = {
            id: `new_add_${new Date().getTime()}_6`,
            componentName: 'ADMINISTRACIÓN',
            capacity: 0,
            componentType: 'ADMINISTRATION',
            cost: 0,
            projectId: this.projectId,
            quantity: 0,
            ...c6,
        }
        this.components.push(item6);
        const item7: IProjectCostEfficiency = {
            id: `new_add_${new Date().getTime()}_7`,
            componentName:  'AUDITORÍA',
            capacity: 0,
            componentType: 'AUDIT',
            cost: 0,
            projectId: this.projectId,
            quantity: 0,
            ...c7,
        }
        this.components.push(item7);
    }

    onRowEditInit(obj: IProjectCostEfficiency, index: number) {
        this.cloned[index] = {...obj};
    }

    onRowEditSave(obj: IProjectCostEfficiency) {
        if (!obj?.id) return;

        const form: FormGroup = this.fb.group({
            projectId: [obj.projectId, Validators.required],
            cost: [obj.cost, [Validators.required, Validators.min(0)]],
            capacity: [obj.capacity, [Validators.required, Validators.min(0)]],
            quantity: [obj.quantity, [Validators.required, Validators.min(0)]],
            componentType: [obj.componentType, [Validators.required, Validators.min(0)]],
        });

        if (form.valid) {
            delete this.cloned[obj.id];

            if (obj?.id.toString().includes('new_')) {
                const index = this.components.indexOf(obj);
                this.costEfficiencyService.store(form.value).then((resp) => {
                    this.components[index] = {
                        ...this.components[index],
                        ...resp,
                    };
                    this.tService.success('El Registro fue creado satisfactoriamente.');
                });

            } else {
                const id = parseInt(obj.id.toString());
                const index = this.components.indexOf(obj);
                this.costEfficiencyService.update(form.value, id).then((resp) => {
                    this.components[index] = {
                        ...this.components[index],
                        ...resp,
                    };
                    this.tService.success('El Registro fue actualizado satisfactoriamente.');
                })
            }

        } else {
            this.tService.error('No se pudo guardar, los datos son inválidos!');
        }

    }

    onRowEditCancel(obj: IProjectCostEfficiency, index: number) {

        if (this.cloned[index]) {
            this.components[index] = this.cloned[index];
            delete this.cloned[index];
        }
    }

    readParams() {
        this.sub = this.activatedRoute.params.subscribe((params) => {
            this.projectId = params['id'];
        });
    }

    cleanParams() {
        this.sub.unsubscribe();
    }

    ngOnDestroy() {
        this.cleanParams();
    }

}
