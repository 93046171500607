import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from 'src/app/shared/services';
import { IProjectCompliance } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ProjectComplianceService extends BaseService<IProjectCompliance>{

    constructor(private readonly httpService: BaseHttpService) {
        super(httpService, 'project-compliances');
    }
}
