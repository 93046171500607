import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TableModule } from 'primeng/table';
import { ChartModule } from 'primeng/chart';
import { ButtonModule } from 'primeng/button';
import { SkeletonModule } from 'primeng/skeleton';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { GeovisorRoutingModule } from './geovisor-routing.module';
import { GeovisorComponent } from './components/geovisor/geovisor.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GeovisorRoutingModule,
        BreadcrumbModule,
        CardModule,
        TableModule,
        ButtonModule,
        SkeletonModule,
        TabViewModule,
        TooltipModule,
        ChartModule,
        InputTextModule,
        DropdownModule,
        CardModule,
        AutoCompleteModule,
        NgMultiSelectDropDownModule.forRoot(),
    ],
    declarations: [GeovisorComponent],
})
export class GeovisorModule {}
