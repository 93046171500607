<div class='w-full bg-green-100'>
    <div class='flex gap-2 justify-content-start align-items-center px-4 py-2'>
        <div class="px-4 py-1">{{index + 1 }}</div>
        <div class="px-4 py-1 w-20rem flex justify-content-start align-items-center flex-1">
            <span class="font-bold">{{activity.activity}}</span>
        </div>
    </div>
</div>

<p-table
    [value]="budgets"
    dataKey="id"
    editMode="row"
    [tableStyle]="{'min-width': '100%'}"
    #dt>
    <ng-template pTemplate="header">
        <tr *ngIf="budgets.length">
            <th class="bg-primary border-x-1 text-center p-0" style="min-width:60px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width:300px; max-width: 300px;"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 120px;"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 150px;"></th>
            <th class="bg-primary p-0" style="width:100px"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-obj let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="obj">
            <td style='width:50px'>{{index + 1}}.{{ri + 1}}</td>
            <td width="300">
                <b>{{obj.budgetItemCode}}</b> - {{obj.budgetItem?.title}}
                <div class="text-sm mt-2">
                    {{obj.budgetItemDetails}}
                </div>
            </td>

            <td class="text-center">
                {{obj.currentMonth.ownerAmount | number:'1.2-2'}}
            </td>

            <td class="text-center">
                {{obj.currentMonth.ownerExecutedAmount | number: '1.2-2'}}
            </td>

            <td class="text-center">
                {{ calcExecuted(obj.currentMonth.ownerExecutedAmount, obj.currentMonth.ownerAmount) | percent:'1.0-1'}}
            </td>
            <td>
                {{ obj.currentMonth.ownerAmount - obj.currentMonth.ownerExecutedAmount | number: '1.2-2'}}
            </td>
            <td class="text-center">
                {{obj.currentMonth.entityAmount | number: '1.2-2'}}
            </td>
            <td class='text-center'>
                {{obj.currentMonth.entityExecutedAmount | number: '1.2-2'}}
            </td>
            <td class="text-center">
                {{ calcExecuted(obj.currentMonth.entityExecutedAmount, obj.currentMonth.entityAmount) | percent:'1.0-1'}}
            </td>
            <td class="text-center">
                {{ obj.currentMonth.entityAmount - obj.currentMonth.entityExecutedAmount | number: '1.2-2'}}
            </td>
            <td class="text-center">
                {{obj.currentMonth.entityInKindAmount | number: '1.2-2'}}
            </td>
            <td class='text-center'>
                {{obj.currentMonth.entityInKindExecutedAmount | number: '1.2-2'}}
            </td>
            <td class="text-center">
                {{ calcExecuted(obj.currentMonth.entityInKindExecutedAmount, obj.currentMonth.entityInKindAmount) | percent:'1.0-1'}}
            </td>
            <td class="text-center">
                {{ obj.currentMonth.entityInKindAmount - obj.currentMonth.entityInKindExecutedAmount | number: '1.2-2'}}
            </td>
            <td>
                <div *ngIf="obj.currentMonth.files?.length">
                    <p-overlayPanel #uploadedFiles>
                        <div *ngFor="let item of obj.currentMonth.files;" class="mb-4">
                            <a [href]="attachmentService.getFileUrl(item?.key)" class="hover:underline"
                               [download]="item.filename" target="_blank">
                                <i class="pi pi-download mr-2"></i>
                                {{item.filename}}
                            </a>
                        </div>
                    </p-overlayPanel>

                    <button
                        pButton pTooltip="Ver Archivos Verificables"
                        tooltipPosition="top"
                        label="Verificables"
                        icon='pi pi-eye'
                        type="button"
                        class="p-button-rounded p-button-text"
                        (click)="uploadedFiles.toggle($event)"
                    ></button>
                </div>
            </td>
            <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-overlayPanel #addBudgetComment>
                            <ng-template pTemplate="content">
                                <div class="p-2">
                                    <textarea
                                        rows="5"
                                        cols="50"
                                        pInputTextarea
                                        placeholder='Escriba la observación aquí.....'
                                        [(ngModel)]="obj.currentMonth.currentObservation"
                                        [ngModelOptions]="{standalone: true}"
                                    ></textarea>
                                </div>
                                <div class="text-right">
                                    <button
                                        label="Aceptar"
                                        type="button"
                                        class="p-button p-button-success"
                                        (click)="addBudgetComment.toggle($event)"
                                    >
                                        Aceptar
                                    </button>
                                </div>
                            </ng-template>
                        </p-overlayPanel>
                        <button
                            pButton pTooltip="Agregar Observacion"
                            tooltipPosition="top"
                            label="Observación"
                            icon='pi pi-upload'
                            type="button"
                            class="p-button-rounded p-button-text"
                            (click)="addBudgetComment.toggle($event)"
                        ></button>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <div *ngIf="obj.currentMonth.observations?.length">
                            <p-overlayPanel #comments>
                                <div *ngFor="let item of obj.currentMonth.observations;" class="w-30rem overflow-y-auto">
                                    <div class="hover:bg-gray-100 px-4 py-2">
                                        {{item.observation}}
                                        <div class="text-gray-600 text-sm py-2">
                                            {{getFullName(item.createdBy)}}
                                        </div>
                                    </div>
                                </div>
                            </p-overlayPanel>

                            <button
                                pButton pTooltip="Ver todas las observaciones"
                                tooltipPosition="top"
                                label="Observaciones"
                                icon='pi pi-check-circle'
                                type="button"
                                class="p-button-rounded p-button-text"
                                (click)="comments.toggle($event)"
                            ></button>
                        </div>
                    </ng-template>
                </p-cellEditor>
            </td>
            <td>
                <div class="flex align-items-center justify-content-center gap-2">
                    <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(obj)" class="p-button-rounded p-button-text"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(obj)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(obj, index)" class="p-button-rounded p-button-text p-button-danger"></button>
                </div>
            </td>
        </tr>
<!--        <tr *ngIf="budgets.length === ri + 1" class="bg-gray-100 font-bold">-->
<!--            <td class="text-center p-1" colspan="3">TOTAL</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem" >0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem" >0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="p-1" style="width:100px"></td>-->
<!--        </tr>-->
    </ng-template>
</p-table>
