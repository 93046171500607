import {Component, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';

import {Table} from "primeng/table";
import {FormBuilder} from "@angular/forms";
import {
    IProjectGoalActivity,
} from "../../../../../../../edtp";
import {ToasterService} from "../../../../../../../shared/services/toaster.service";
import {IProjectActivityBudget} from "../../../../../../models";
import {ProjectActivityBudgetService} from "../../../../../../services";
import {AttachmentService, BudgetItemService} from "../../../../../../../shared";
import {IBudgetItem} from "../../../../../../../shared/interfaces/budget-item.interface";

@Component({
    selector: 'app-summary-activity-budgets',
    templateUrl: './budgets.component.html',
    styleUrls: ['./budgets.component.scss']
})
export class SummaryActivityBudgetsComponent implements OnInit, OnChanges {
    @Input() index: number = 0;
    @Input() activity!: IProjectGoalActivity;
    @Input() budgets: IProjectActivityBudget[] = [];
    @Input() selectedYear!: number;
    @Input() totalProjectDays!: number;

    budgetItems: IBudgetItem[] = [];
    loading: boolean = false;
    cloned: { [s: string]: IProjectActivityBudget; } = {};

    @ViewChild('dt') table!: Table;

    constructor(
        private readonly budgetService: ProjectActivityBudgetService,
        private readonly budgetItemsService: BudgetItemService,
        private fb: FormBuilder,
        public readonly attachmentService: AttachmentService,
        private toasterService: ToasterService,
    ) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.budgetItemsService.getAll().then((resp) => {
            this.budgetItems = [ ...resp ];
        }).finally(() => {
            this.loading = false;
        });
    }


    ngOnChanges(changes: SimpleChanges) {
        this.budgets = this.activity.budgets || [];
        if (changes['selectedYear']) {
            this.budgets.map(b => {
                b.ownerAmountYear = 0;
                b.entityAmountYear = 0;
                b.entityInKindAmountYear = 0;
                if (b.yearsProgrammed?.length) {
                    for (const yp of b.yearsProgrammed) {
                        if (yp.year === this.selectedYear){
                            b.ownerAmountYear = yp.ownerAmount;
                            b.entityAmountYear = yp.entityAmount;
                            b.entityInKindAmountYear = yp.entityInKindAmount;
                            break;
                        }
                    }
                }
            });
        }
    }

    calcExecuted(programmed: number, executed: number) {

        if (!(programmed && executed)) return 0;

        return (programmed/executed);
    }
}
