
export interface IProjectC1 {
    id?: number | string;
    projectId: number;
    description: string;
    quantityRequired: number;
    quantityYear1: number;
    quantityYear2: number;
    quantityYear3: number;
    uom: string;
    typology?: string;
    baseLineId?: number | string;
    geos?: { geoId: number; c1Id: number; }[];
    geoIds?: number[];
}
