<app-wizard
    [steps]='steps'
    [current]='currentStep'
    [bodyContentTpl]='projectForm'
    [nextDisabled]='this.formGroup?.invalid'
    (onClickNext)='save($event)'
    [layout]='true'
>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column' [formGroup]='formGroup'>
        <div class='grid'>
            <div class="field col-12 text-2xl text-primary uppercase">
                {{currentStep?.text}}
            </div>

            <div class="field col-12">
                <p-table [tableStyle]="{width: '100%'}">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="bg-primary" style="width:50%">INVERSIÓN</th>
                            <th class="bg-primary" style="width:50%">IMPORTE (Bs.)</th>
                        </tr>
                    </ng-template>
                </p-table>

                <p-table [value]="tangible" dataKey="id" [tableStyle]="{width: '100%'}" (onEditComplete)='onEditComplete($event)' (onEditInit)="onEditInit($event)">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="bg-secondary component border-0" style="width:50%">TANGIBLE</th>
                            <th class="bg-secondary border-0" style="width:50%"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-obj let-editing="editing">
                        <tr>
                            <td>Infraestructura</td>
                            <td [pEditableColumn]="obj" pEditableColumnField="infrastructure">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number"
                                               [disabled]='isSubmitted'
                                               [min]="0"
                                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary'
                                               [(ngModel)]="obj.infrastructure" required [ngModelOptions]="{standalone: true}">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{obj.infrastructure | number: '1.2' }}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                        </tr>

                        <tr>
                            <td>Maquinaria y Equipos</td>
                            <td [pEditableColumn]="obj" pEditableColumnField="machines">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number"
                                               [disabled]='isSubmitted'
                                               [min]="0"
                                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary'
                                               [(ngModel)]="obj.machines" required [ngModelOptions]="{standalone: true}">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{obj.machines | number: '1.2' }}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>

                <p-table [value]="intangible" dataKey="id" [tableStyle]="{width: '100%'}" (onEditComplete)='onEditComplete($event)' (onEditInit)="onEditInit($event)">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="bg-secondary component border-0" style="width:50%">INTANGIBLE</th>
                            <th class="bg-secondary border-0" style="width:50%"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-obj let-rowIndex="rowIndex">
                        <tr>
                            <td>Asistencia Técnica y Capacitación</td>
                            <td [pEditableColumn]="obj" pEditableColumnField="training">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number"
                                               [disabled]='isSubmitted'
                                               [min]="0"
                                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary'
                                               [(ngModel)]="obj.training" required [ngModelOptions]="{standalone: true}">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{obj.training | number: '1.2' }}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                        </tr>

                        <tr>
                            <td>Consultorías</td>
                            <td [pEditableColumn]="obj" pEditableColumnField="consultancy">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number"
                                               [disabled]='isSubmitted'
                                               [min]="0"
                                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary'
                                               [(ngModel)]="obj.consultancy" required [ngModelOptions]="{standalone: true}">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{obj.consultancy | number: '1.2' }}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                        </tr>

                        <tr>
                            <td>Auditoría</td>
                            <td [pEditableColumn]="obj" pEditableColumnField="machines">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number"
                                               [disabled]='isSubmitted'
                                               [min]="0"
                                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary'
                                               [(ngModel)]="obj.machines" required [ngModelOptions]="{standalone: true}">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{obj.machines | number: '1.2' }}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>

                <p-table [value]="workingCapital" dataKey="id" [tableStyle]="{width: '100%'}" (onEditComplete)='onEditComplete($event)' (onEditInit)="onEditInit($event)">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="bg-secondary component border-0" style="width:50%">CAPITAL DE TRABAJO</th>
                            <th class="bg-secondary border-0" style="width:50%"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-obj let-rowIndex="rowIndex">
                        <tr>
                            <td>Insumos</td>
                            <td [pEditableColumn]="obj" pEditableColumnField="infrastructure">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number"
                                               [disabled]='isSubmitted'
                                               [min]="0"
                                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary'
                                               [(ngModel)]="obj.infrastructure" required [ngModelOptions]="{standalone: true}">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{obj.infrastructure | number: '1.2' }}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                        </tr>

                        <tr>
                            <td>Mano de Obra</td>
                            <td [pEditableColumn]="obj" pEditableColumnField="machines">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number"
                                               [disabled]='isSubmitted'
                                               [min]="0"
                                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary'
                                               [(ngModel)]="obj.machines" required [ngModelOptions]="{standalone: true}">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{obj.machines | number: '1.2' }}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</ng-template>
