import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {IProjectChangeOrder} from "../../../../models";
import {ProjectChangeOrderService} from "../../../../services";
import {IAttachment} from "../../../../../shared";
import {ToasterService} from "../../../../../shared/services/toaster.service";

@Component({
  selector: 'app-change-order-form',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ChangeOrderFormModal implements OnInit {
  @Input() public id!: number;
  @Input() public data!: IProjectChangeOrder;

  public modalForm!: FormGroup;
  public showForm = false;
  defaultFiles: IAttachment[] = [];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public service: ProjectChangeOrderService,
    private fb: FormBuilder,
    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    const { id, justification, status, fileId, file, projectId } = this.config.data || {};
    if (file) this.defaultFiles.push(file);

    if (id) {
      this.modalForm = this.fb.group({
        justification: [justification, [Validators.required, Validators.maxLength(3000)]],
        fileId: [fileId, Validators.required],
          projectId: [projectId, Validators.required],
      });
    } else {
      this.modalForm = this.fb.group({
          justification: [null, [Validators.required, Validators.maxLength(3000)]],
          fileId: [null, Validators.required],
          projectId: [projectId, Validators.required],
      });
    }

    this.showForm = true;
  }

  get form(): any {
    return this.modalForm.controls;
  }

  save(): void {
    const { id } = this.config.data || {};
    // Invalid
    if (!this.modalForm.valid) {
      this.toasterService.error('Corrija los datos e inténtelo nuevamente');
      return;
    }

    const postData: IProjectChangeOrder = this.modalForm.value;
      let tmpResponse!: IProjectChangeOrder;
    // Update
    if (id) {
      this.service
        .update(postData, this.config.data.id)
        .then((resp) => {
          this.toasterService.success('Los datos fueron actualizados con éxito');
          tmpResponse = resp;
          this.closeModal(tmpResponse);
        })
        .catch((err) => {
          console.error(err.error.message);
          this.toasterService.error('Se ha producido un error inténtelo más tarde o contáctese con el Administrador.');
        });
      return;
    }

    //Store
    this.service
      .store(postData)
      .then((resp) => {
        this.toasterService.success('El registro fué almacenado con éxito');
        tmpResponse = resp;
        this.closeModal(tmpResponse);
      })
      .catch((err) => {
        console.error(err.error.message);
        this.toasterService.error('Se ha producido un error inténtelo más tarde o contáctese con el Administrador.');
      });
  }

  onUploaded(data: any) {
    const { files } = data;
    if (files.length) {
      this.modalForm.patchValue({
        fileId: files[0].id,
      });
    }
  }

  closeModal(data: any = undefined): void {
    this.ref.close(data);
  }
}
