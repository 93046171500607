import { ProjectModel, ProjectService } from '../../itcp';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from '../../shared/services/toaster.service';
import {Constants, PROJECT_READ_ONLY} from '../../shared/constants';
import { StepsEDTPBuilder } from './steps-edtp.builder';
import { GroupedComponents } from '../services';
import { IProjectBaseLine } from './interfaces';
import {CryptoService, ISettings, ProjectFlow, ProjectStagesEnum, SettingsService} from '../../shared';

export abstract class ProjectEdtpModel extends ProjectModel{

    protected settingService!: SettingsService;
    typologies: string[] = [];
    private stepsBuilder!: StepsEDTPBuilder;
    protected baseLines: IProjectBaseLine[] = [];
    protected components: GroupedComponents[] = [];
    protected unitsOfMeasure: ISettings[] = [];

    protected constructor(route: ActivatedRoute, service: ProjectService, tService: ToasterService, setService: SettingsService, crypto: CryptoService) {
        super(route, service, tService, crypto);
        this.settingService = setService;
    }

    checkEditableForm() {
        const editable: boolean = !this.isReadOnly(PROJECT_READ_ONLY.FORMULATE);
        if (editable) {
            this.setAsEditable();
        } else {
            this.setAsReadOnly();
        }
    }

    formulateReadOnly() {
        return this.isReadOnly(PROJECT_READ_ONLY.FORMULATE) || this.programmerReadOnly();
    }

    isFormulated(): boolean {
        if(this.currentProject?.status && this.currentProject?.stage === ProjectStagesEnum.EDTP && [
            Constants.PROJECT_STATUSES.EVALUATED,
            Constants.PROJECT_STATUSES.APPROVED,
        ].includes(this.currentProject.status)) return true;
        return false;
    }

    override programmerReadOnly() {
        return this.isReadOnly(PROJECT_READ_ONLY.PROGRAMMER) || this.isProgrammed || this.isProgramming;
    }

    override setSteps() {
        this.stepsBuilder = new StepsEDTPBuilder(this.params['id']);
        let typologies = this.currentProject?.typologies?.map(t => t.key);
        if (typologies) typologies = typologies.filter(t => t !== Constants.TYPOLOGIES.TYPOLOGY_V); //TODO: Remove this once typology V is defined
        this.steps = this.stepsBuilder.buildSteps(typologies);
        try {
            this.checkEditableForm();
        } catch (e) {}
    }

    override get isSubmitted(): boolean {
        return this.isFormulated() || this.formulateReadOnly();
    }

    protected loadComponents(types: string[] = [] ) {
        if (!types?.length) {
            types = [
                Constants.SETTING_TYPES.INFRASTRUCTURE_COMPONENT,
                Constants.SETTING_TYPES.PRODUCTION_COMPONENT,
                Constants.SETTING_TYPES.FORESTATION_COMPONENT,
                Constants.SETTING_TYPES.REFORESTATION_COMPONENT,
                Constants.SETTING_TYPES.TRAINING_COMPONENT,
                Constants.SETTING_TYPES.TECHNICAL_ASSISTANCE_COMPONENT,
                Constants.SETTING_TYPES.EQUIPMENT_COMPONENT,
                Constants.SETTING_TYPES.INFRASTRUCTURE_TIV_COMPONENT,
                Constants.SETTING_TYPES.INVESTIGATION_COMPONENT,
                Constants.SETTING_TYPES.SOCIALIZATION_COMPONENT,
                Constants.SETTING_TYPES.TECHNOLOGY_TRANSFER_COMPONENT,
            ]
        }
        return this.service.get(this.params['id']).then((project) => {
            this.currentProject = {...project};
            return this.settingService.getAll({ params: { types }})
        }).then((resp) => {
            this.addGroupComponent(
                'Infraestructura',
                Constants.COMPONENT_TYPE.INFRASTRUCTURE,
                resp.filter(r => r.type === Constants.SETTING_TYPES.INFRASTRUCTURE_COMPONENT)
            )

            this.addGroupComponent(
                'Producción',
                 Constants.COMPONENT_TYPE.PRODUCTION,
                resp.filter(r => r.type === Constants.SETTING_TYPES.PRODUCTION_COMPONENT)
            );

            this.addGroupComponent(
                'Forestación',
                Constants.COMPONENT_TYPE.FORESTATION,
                resp.filter(r => r.type === Constants.SETTING_TYPES.FORESTATION_COMPONENT)
            );

            this.addGroupComponent(
                'Reforestación',
                Constants.COMPONENT_TYPE.REFORESTATION,
                resp.filter(r => r.type === Constants.SETTING_TYPES.REFORESTATION_COMPONENT)
            );

            this.addGroupComponent(
                'Capacitación',
                Constants.COMPONENT_TYPE.TRAINING,
                resp.filter(r => r.type === Constants.SETTING_TYPES.TRAINING_COMPONENT)
            );
            this.addGroupComponent(
                'Asistencia Técnica',
                Constants.COMPONENT_TYPE.TECHNICAL_ASSISTANCE,
                resp.filter(r => r.type === Constants.SETTING_TYPES.TECHNICAL_ASSISTANCE_COMPONENT)
            );
            this.addGroupComponent(
                'Equipamiento',
                Constants.COMPONENT_TYPE.EQUIPMENT,
                resp.filter(r => r.type === Constants.SETTING_TYPES.EQUIPMENT_COMPONENT)
            );
            this.addGroupComponent(
                'Infrastructura',
                Constants.COMPONENT_TYPE.INFRASTRUCTURE_TIV,
                resp.filter(r => r.type === Constants.SETTING_TYPES.INFRASTRUCTURE_TIV_COMPONENT)
            );
            this.addGroupComponent(
                'Investigación',
                Constants.COMPONENT_TYPE.INVESTIGATION,
                resp.filter(r => r.type === Constants.SETTING_TYPES.INVESTIGATION_COMPONENT)
            );
            this.addGroupComponent(
                'Socialización',
                Constants.COMPONENT_TYPE.SOCIALIZATION,
                resp.filter(r => r.type === Constants.SETTING_TYPES.SOCIALIZATION_COMPONENT)
            );
            this.addGroupComponent(
                'Tranferida de Tecnología',
                Constants.COMPONENT_TYPE.TECHNOLOGY_TRANSFER,
                resp.filter(r => r.type === Constants.SETTING_TYPES.TECHNOLOGY_TRANSFER_COMPONENT)
            );

            return this.settingService.getAll({params: {types: [Constants.SETTING_TYPES.UOM]}})
        }).then((resp) => {
            this.unitsOfMeasure = [...resp];
        });
    }

    mapTypologies() {
        if (!this.currentProject?.typologies?.length) return;

        this.typologies = this.currentProject.typologies.map(t => t.key);
        this.steps = this.stepsBuilder.buildSteps(this.typologies);
    }

    isTypologyII(): boolean {
        return this.typologies.includes(Constants.TYPOLOGIES.TYPOLOGY_II);
    }

    isTypologyIII(): boolean {
        return this.typologies.includes(Constants.TYPOLOGIES.TYPOLOGY_III);
    }

    isTypologyIV(): boolean {
        return this.typologies.includes(Constants.TYPOLOGIES.TYPOLOGY_IV);
    }

    isTypologyV(): boolean {
        return this.typologies.includes(Constants.TYPOLOGIES.TYPOLOGY_V);
    }

    componentsForTypologyII() {
        return [
            Constants.SETTING_TYPES.INFRASTRUCTURE_COMPONENT,
            Constants.SETTING_TYPES.PRODUCTION_COMPONENT,
            Constants.SETTING_TYPES.FORESTATION_COMPONENT,
            Constants.SETTING_TYPES.REFORESTATION_COMPONENT,
            Constants.SETTING_TYPES.TRAINING_COMPONENT,
            Constants.SETTING_TYPES.TECHNICAL_ASSISTANCE_COMPONENT,
        ]
    }

    componentsForTypologyIII() {
        return this.componentsForTypologyII();
    }

    componentsForTypologyIV() {
        return [
            Constants.SETTING_TYPES.EQUIPMENT_COMPONENT,
            Constants.SETTING_TYPES.TECHNICAL_ASSISTANCE_COMPONENT,
            Constants.SETTING_TYPES.INFRASTRUCTURE_TIV_COMPONENT,
        ];
    }

    componentsForTypologyV() {
        return [
            Constants.SETTING_TYPES.INVESTIGATION_COMPONENT,
            Constants.SETTING_TYPES.SOCIALIZATION_COMPONENT,
            Constants.SETTING_TYPES.TECHNOLOGY_TRANSFER_COMPONENT,
        ];
    }


    isValidYear(value: number) {
        if (!this.currentProject || !this.currentProject.years) return false;

        return this.currentProject.years >= value;
    }


    private addGroupComponent(label:string, value: string, items: ISettings[]) {
        if (!items.length) return;

        const gc: GroupedComponents = {
            label, value, items,
        };
        this.components.push(gc);
    }
}
