<app-wizard
    [steps]='steps'
    [current]='currentStep'
    [bodyContentTpl]='projectForm'
    [nextDisabled]='this.formGroup?.invalid'
    (onClickNext)='save($event)'
    [layout]='true'
>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column mb-8' [formGroup]='formGroup'>
        <div class='grid'>
            <div class="field col-12 text-2xl text-primary uppercase">
                {{currentStep?.text}}
            </div>

            <div class="field col-12">
                <button
                    class='p-button p-button-success'
                    [disabled]="!isSubmitted"
                    pButton pTooltip="Agregar Grupo" tooltipPosition="top"
                    (click)='onNew()'
                >
                    <i class="pi pi-plus"></i>
                </button>
                <p-table [value]="groups" dataKey="id" editMode="row" [tableStyle]="{'min-width': '100%'}" #dt>
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="bg-primary border-x-1 text-center" rowspan='2' style="width:60px">NRO.</th>
                            <th class="bg-primary border-x-1 text-center" rowspan='2'>ESTRUCTURA DEL CONTENIDO DEL EDTP</th>
                            <th class="bg-primary border-x-1 text-center" colspan='2' style="width:300px">FICHA DE CONSISTENCIA DEL EDTP</th>
                            <th class="bg-primary" rowspan='2'  style="width:100px"></th>
                        </tr>
                        <tr>
                            <th class="bg-primary border-x-1 text-center"  scope="colgroup">PRESENTA</th>
                            <th class="bg-primary border-x-1 text-center"  scope="colgroup">NO PRESENTA</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-obj let-editing="editing" let-index="rowIndex">
                        <tr [pEditableRow]="obj">
                            <td colspan='5' class='p-0 m-0'>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <app-compliance
                                            [group]='obj'
                                            [defaultCompliances]="defaultCompliances"
                                            [editMode]='true'
                                            (onSave)='onRowEditSave($event)'
                                            (onEdit)='onRowEditInit($event)'
                                            (onCancel)='onRowEditCancel($event, index)'
                                        >
                                        </app-compliance>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <app-compliance
                                            [group]='obj'
                                            [defaultCompliances]="defaultCompliances"
                                            [editMode]='false'
                                            [canEdit]="isSubmitted"
                                            (onRemove)="onRemove($event)"
                                        >
                                        </app-compliance>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</ng-template>
