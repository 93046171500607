import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IProjectBudget} from "../../../../models/interfaces/project-budget.interface";
import {ComponentType, ProjectBudgetService} from "../../../../services";
import {Table} from "primeng/table";
import {IBudgetItem} from "../../../../../shared/interfaces/budget-item.interface";
import {BudgetItemService, ProjectBudgetEnum} from "../../../../../shared";
import {IProject} from "../../../../../itcp";
import {BudgetSummaryHelper} from "../../../../models";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToasterService} from "../../../../../shared/services/toaster.service";
import {ConfirmationService} from "primeng/api";

@Component({
    selector: 'app-budget-component-component',
    templateUrl: './budget-component.component.html',
    styleUrls: ['./budget-component.component.scss']
})
export class BudgetComponentComponent implements OnInit {

    @Input() title!: string;
    @Input() projectBudgets: IProjectBudget[] = [];
    @Input()  budgetItems: IBudgetItem[] = [];
    @Input() componentType!: ComponentType;
    @Input() isSubmitted: boolean = false;
    @Input() currentProject: IProject | undefined;

    clonedProjectBudgets: { [s: string]: IProjectBudget; } = {};
    @ViewChild('dt') table!: Table;
    constructor(
        private readonly budgetItemsService: BudgetItemService,
        private readonly projectBudgetsService: ProjectBudgetService,
        private toasterService: ToasterService,
        private fb: FormBuilder,
        private readonly confirmationService: ConfirmationService,
    ) {}

    ngOnInit(): void {
    }

    onRowEditInit(obj: IProjectBudget) {
        if (!obj?.id) return;

        this.clonedProjectBudgets[obj.id] = {...obj};
    }

    onDelete(obj: IProjectBudget) {
        if (!obj.id) return;
        console.log(obj)
        const id = parseInt(obj.id.toString());
        this.confirmationService.confirm({
            message: 'Está seguro de eliminar este registro?',
            accept: () => {
                this.projectBudgetsService
                    .delete(id)
                    .then((resp: boolean) => {
                        if (resp) {
                            this.toasterService.success('El registro se eliminó correctamente');
                            this.projectBudgets = this.projectBudgets.filter(b => b.id !== obj.id);
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                        this.toasterService.error(err.error.message);
                    });
            },
        });
    }

    getForm(obj: IProjectBudget) {
        if (!obj?.id) return;

        BudgetSummaryHelper.setRowTotals(obj);

        const form: FormGroup = this.fb.group({
            projectId: [obj.projectId, Validators.required],
            budgetItemCode: [obj.budgetItemCode, Validators.required],
            budgetItemDescription: [obj.budgetItemDescription, Validators.required],
            entityInKindY1: [obj.entityInKindY1, [Validators.required, Validators.min(0)]],
            entityInKindY2: [obj.entityInKindY2, [Validators.required, Validators.min(0)]],
            entityInKindY3: [obj.entityInKindY3, [Validators.required, Validators.min(0)]],
            entityY1: [obj.entityY1, [Validators.required, Validators.min(0)]],
            entityY2: [obj.entityY2, [Validators.required, Validators.min(0)]],
            entityY3: [obj.entityY3, [Validators.required, Validators.min(0)]],
            ownerY1: [obj.ownerY1, [Validators.required, Validators.min(0)]],
            ownerY2: [obj.ownerY2, [Validators.required, Validators.min(0)]],
            ownerY3: [obj.ownerY3, [Validators.required, Validators.min(0)]],
            price: [obj.price, [Validators.required, Validators.min(0)]],
            uom: [obj.uom, Validators.required],
            quantity: [obj.quantity, [Validators.required, Validators.min(0)]],
            type: [obj.type, Validators.required],
            componentType: [obj.componentType, Validators.required],
        });

        return form;
    }

    onRowEditSave(obj: IProjectBudget) {
        if (!obj?.id) return;

        const form = this.getForm(obj);

        if (form?.valid) {
            delete this.clonedProjectBudgets[obj.id];

            const index = this.projectBudgets.indexOf(obj);
            if (obj?.id.toString().includes('new_')) {
                this.projectBudgetsService.store(form.value).then((resp) => {
                    this.toasterService.success('El registro se creó satisfactoriamente.');
                    if (resp) {
                      this.projectBudgets[index].id = resp.id;
                    }
                });

            } else {
                const id = parseInt(obj.id.toString());
                this.projectBudgetsService.update(form.value, id).then((resp) => {
                    this.toasterService.success('El registro se actualizó satisfactoriamente.');
                })
            }

        } else {
            this.toasterService.error('No se pudo guardar, los datos son inválidos!');
        }

    }

    onRowEditCancel(obj: IProjectBudget, index: number) {
        if (!obj?.id) return;

        if (this.clonedProjectBudgets[obj.id]) {
            this.projectBudgets[index] = this.clonedProjectBudgets[obj.id];
            delete this.clonedProjectBudgets[obj.id];
        }

        if (obj?.id.toString().includes('new_')) {
            this.projectBudgets = this.projectBudgets.filter(b => b.id !== obj.id);
        }
    }

    onNew() {
        const projectId = this.currentProject?.id || 0;
        const budget: IProjectBudget = {
            id: `new_add_${new Date().getTime()}`,
            projectId,
            budgetItemCode: 0,
            budgetItemDescription: "",
            entityInKindY1: 0,
            entityInKindY2: 0,
            entityInKindY3: 0,
            entityY1: 0,
            entityY2: 0,
            entityY3: 0,
            ownerY1: 0,
            ownerY2: 0,
            ownerY3: 0,
            price: 0,
            quantity: 0,
            type: ProjectBudgetEnum.GENERAL,
            uom: "",
            componentType: this.componentType,
            totals: { owner: 0, entityInKind: 0, entity: 0, total: 0 }
        }
        this.projectBudgets.push(budget);
        this.table.initRowEdit(this.table.value[this.projectBudgets.length - 1]);
    }

    filterBudgetItems(event: any) {
        this.budgetItemsService
            .getAll({ params: { query: event.query }})
            .then((resp) => {
                this.budgetItems = [ ...resp ];
            });
    }

    onSelectBudgetItem(event: any, obj: IProjectBudget) {
        obj.budgetItemCode = event.code;
        obj.budgetItemDescription = event.title;
    }

    isValidYear(value: number) {
        if (!this.currentProject || !this.currentProject.years) return false;

        return this.currentProject.years >= value;
    }

}
