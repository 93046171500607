import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from 'src/app/shared/services';
import {IVisit} from '../models';
import {lastValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class VisitService extends BaseService<IVisit>{

    constructor(private readonly httpService: BaseHttpService) {
        super(httpService, 'visits');
    }
}
