import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ActivatedRoute } from '@angular/router';
import { ProjectModel, ProjectService } from '../../../../itcp';
import { AttachmentService, CryptoService } from '../../../../shared';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { LayoutService } from '../../../../shared/services/app.layout.service';
import { IProjectStartOrder } from '../../../models';
import { ProjectStartOrderService } from '../../../services';
import { Constants } from '../../../../shared/constants';
import { MenuItem } from 'primeng/api';
import { GenericFunctions } from '../../../../shared/services/generic-functions';

type StartOrderType = 'SIGN' | 'START_ORDER' | 'FIRST_PAYMENT' | 'SECOND_PAYMENT' | 'THIRD_PAYMENT';
export const StartOrderLabels: any = {
    'SIGN': 'Firma de Convenio',
    'START_ORDER': 'Orden de Inicio',
    'FIRST_PAYMENT': 'Primer Desembolso',
    'SECOND_PAYMENT': 'Segundo Desembolso',
    'THIRD_PAYMENT': 'Tercer Desembolso'
};

@Component({
    selector: 'app-start-order',
    templateUrl: './start-order.component.html',
    styleUrls: ['./start-order.component.scss']
})
export class StartOrderComponent extends ProjectModel implements OnInit, OnDestroy {
    resource = Constants.RESOURCES.TRACKING_START_ORDER;
    startOrder!: IProjectStartOrder;
    cloned!: IProjectStartOrder | undefined;
    loading: boolean = false;
    calculating: boolean = false;
    projectStarted: boolean = false;
    startOrderDate!: Date | undefined;

    breadcrumbs: MenuItem[] = [
        { label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home'] },
        { label: ' Seguimiento', routerLink: ['/tracking'] },
        { label: ' Órdenes de Proceso', routerLink: ['/tracking/orders'] },
        { label: ' Orden de Inicio/Desembolso' }
    ];

    @ViewChild('dt') table!: Table;

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly startOrderService: ProjectStartOrderService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private readonly layoutService: LayoutService,
        private crypto: CryptoService,
        public readonly attachmentService: AttachmentService
    ) {
        super(activatedRoute, projectService, tService, crypto);
    }

    ngOnInit(): void {
        this.layoutService.showMenuDesktop(true);
        this.readParams();
        this.loadData();
    }

    get disabled(): boolean {
        return this.currentProject?.status === Constants.PROJECT_STATUSES.PROGRAMMING ||
            this.currentProject?.status === Constants.PROJECT_STATUSES.CLOSED;
    }

    loadData() {
        this.loading = true;
        this.service.get(this.params['id']).then((project) => {
            const { id, name } = project || {};
            this.currentProject = { ...project };
            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required],
                status: [Constants.PROJECT_STATUSES.START_ORDERED]
            });
            return this.startOrderService.get(this.params['id']);
        }).then((resp) => {
            if (resp) {
                this.startOrder = { ...resp };
            }
        }).finally(() => {
            this.calculateTotalProjectBudget();
            this.initStartOrder();
            this.showContent = true;
            this.loading = false;
        });
    }

    initStartOrder() {
        if (this.startOrder) {
            this.projectStarted = false;
            this.startOrderDate = this.startOrder.startOrderDate;
            if (this.startOrder.startOrderDate && this.startOrder.signDate && this.startOrder.signPrice > 0)
                this.projectStarted = true;
            this.setStartOrderFiles();
            return;
        }

        this.startOrder = {
            id: `new_${new Date().getTime()}`,
            firstPayment: 0,
            projectId: parseInt(this.params['id']),
            secondPayment: 0,
            signPrice: this.projectTotals.owner,
            thirdPayment: 0,
            signFiles: [],
            startOrderFiles: [],
            firstPaymentFiles: [],
            secondPaymentFiles: [],
            thirdPaymentFiles: []
        };
    }

    setStartOrderFiles() {
        if (this.startOrder?.verifiables?.length) {
            this.startOrder.signFiles = this.startOrder.verifiables.filter(v => v.orderType === 'SIGN').map(f => f.file);
            this.startOrder.startOrderFiles = this.startOrder.verifiables.filter(v => v.orderType === 'START_ORDER').map(f => f.file);
            this.startOrder.firstPaymentFiles = this.startOrder.verifiables.filter(v => v.orderType === 'FIRST_PAYMENT').map(f => f.file);
            this.startOrder.secondPaymentFiles = this.startOrder.verifiables.filter(v => v.orderType === 'SECOND_PAYMENT').map(f => f.file);
            this.startOrder.thirdPaymentFiles = this.startOrder.verifiables.filter(v => v.orderType === 'THIRD_PAYMENT').map(f => f.file);
            this.startOrder.signFilesIds = this.startOrder.signFiles.map(v => v.id);
            this.startOrder.startOrderFilesIds = this.startOrder.startOrderFiles.map(v => v.id);
            this.startOrder.firstPaymentFilesIds = this.startOrder.firstPaymentFiles.map(v => v.id);
            this.startOrder.secondPaymentFilesIds = this.startOrder.secondPaymentFiles.map(v => v.id);
            this.startOrder.thirdPaymentFilesIds = this.startOrder.thirdPaymentFiles.map(v => v.id);
        }
    }

    onRowEditInit() {
        this.cloned = { ...this.startOrder };
    }

    getForm(): any {
        let signDate, startOrderDate, firstPaymentDate, secondPaymentDate, thirdPaymentDate;
        if (this.startOrder.signDate) {
            signDate = GenericFunctions.parseDate(this.startOrder.signDate);
        }

        if (this.startOrder.startOrderDate) {
            startOrderDate = GenericFunctions.parseDate(this.startOrder.startOrderDate);
        }

        if (this.startOrder.firstPaymentDate) {
            firstPaymentDate = GenericFunctions.parseDate(this.startOrder.firstPaymentDate);
        }

        if (this.startOrder.secondPaymentDate) {
            secondPaymentDate = GenericFunctions.parseDate(this.startOrder.secondPaymentDate);
        }

        if (this.startOrder.thirdPaymentDate) {
            thirdPaymentDate = GenericFunctions.parseDate(this.startOrder.thirdPaymentDate);
        }

        const form: FormGroup = this.fb.group({
            projectId: [this.startOrder.projectId, Validators.required],
            signDate: [signDate, Validators.required],
            startOrderDate: [startOrderDate],
            firstPaymentDate: [firstPaymentDate],
            secondPaymentDate: [secondPaymentDate],
            thirdPaymentDate: [thirdPaymentDate],
            signPrice: [this.startOrder.signPrice, [Validators.required, Validators.min(0)]],
            firstPayment: [this.startOrder.firstPayment, [Validators.required, Validators.min(0), Validators.max(this.maxValue(this.startOrder.firstPayment || 0))]],
            secondPayment: [this.startOrder.secondPayment, [Validators.required, Validators.min(0), Validators.max(this.maxValue(this.startOrder.secondPayment || 0))]],
            thirdPayment: [this.startOrder.thirdPayment, [Validators.required, Validators.min(0), Validators.max(this.maxValue(this.startOrder.thirdPayment || 0))]],
            signFilesIds: [this.startOrder.signFilesIds],
            startOrderFilesIds: [this.startOrder.startOrderFilesIds],
            firstPaymentFilesIds: [this.startOrder.firstPaymentFilesIds],
            secondPaymentFilesIds: [this.startOrder.secondPaymentFilesIds],
            thirdPaymentFilesIds: [this.startOrder.thirdPaymentFilesIds]
        });
        return form;
    }

    onRowEditSave() {
        const form = this.getForm();

        if (form?.valid) {
            this.cloned = undefined;

            if (this.startOrder.id?.toString().includes('new_')) {
                this.startOrderService.store(form.value).then((resp) => {
                    this.startOrder = { ...resp };
                    this.setStartOrderFiles();
                    this.calculateDateActivities();
                    this.startOrderDate = this.startOrder.startOrderDate;
                    if (this.startOrder.startOrderDate)
                        this.saveSkipValidation(null, true);
                    this.toasterService.success('El Registro fue creado satisfactoriamente.');
                });

            } else {
                const id = this.startOrder?.id ? parseInt(this.startOrder.id.toString()) : 0;
                this.startOrderService.update(form.value, id).then((resp) => {
                    this.startOrder = { ...resp };
                    this.setStartOrderFiles();
                    this.calculateDateActivities();
                    this.startOrderDate = this.startOrder.startOrderDate;
                    if (this.startOrder.startOrderDate)
                        this.saveSkipValidation(null, true);
                    this.toasterService.success('El Registro fue actualizado satisfactoriamente.');
                });
            }

        } else {
            this.toasterService.error('No se pudo guardar, los datos son inválidos!');
        }
    }

    calculateDateActivities() {
        const startDateChanged = this.startOrderDate !== this.startOrder.startOrderDate;
        if (!startDateChanged) return;
        this.calculating = true;
        this.startOrderService.calculateDateActivities(this.startOrder.projectId).then((resp) => {
            console.info(`Calculating activity dates:  success: ${resp}`);
        }).finally(() => this.calculating = false);
    }

    onRowEditCancel() {
        if (this.cloned) {
            this.startOrder = { ...this.cloned };
            this.cloned = undefined;
        }
    }

    onUploadedVerifiable(data: any, type: StartOrderType) {

        const { files } = data;

        if (files.length) {
            switch (type) {
                case 'SIGN':
                    if (!this.startOrder.signFiles?.length) this.startOrder.signFiles = [];
                    this.startOrder.signFiles = [
                        ...this.startOrder.signFiles,
                        ...files
                    ];
                    if (!this.startOrder.signFilesIds?.length) this.startOrder.signFilesIds = [];
                    this.startOrder.signFilesIds = [
                        ...this.startOrder.signFilesIds,
                        ...files.map((f: any) => f.id)
                    ];
                    break;
                case 'START_ORDER':
                    if (!this.startOrder.startOrderFiles?.length) this.startOrder.startOrderFiles = [];
                    this.startOrder.startOrderFiles = [
                        ...this.startOrder.startOrderFiles,
                        ...files
                    ];
                    if (!this.startOrder.startOrderFilesIds?.length) this.startOrder.startOrderFilesIds = [];
                    this.startOrder.startOrderFilesIds = [
                        ...this.startOrder.startOrderFilesIds,
                        ...files.map((f: any) => f.id)
                    ];
                    break;
                case 'FIRST_PAYMENT':
                    if (!this.startOrder.firstPaymentFiles?.length) this.startOrder.firstPaymentFiles = [];
                    this.startOrder.firstPaymentFiles = [
                        ...this.startOrder.firstPaymentFiles,
                        ...files
                    ];
                    if (!this.startOrder.firstPaymentFilesIds?.length) this.startOrder.firstPaymentFilesIds = [];
                    this.startOrder.firstPaymentFilesIds = [
                        ...this.startOrder.firstPaymentFilesIds,
                        ...files.map((f: any) => f.id)
                    ];
                    break;
                case 'SECOND_PAYMENT':
                    if (!this.startOrder.secondPaymentFiles?.length) this.startOrder.secondPaymentFiles = [];
                    this.startOrder.secondPaymentFiles = [
                        ...this.startOrder.secondPaymentFiles,
                        ...files
                    ];
                    if (!this.startOrder.secondPaymentFilesIds?.length) this.startOrder.secondPaymentFilesIds = [];
                    this.startOrder.secondPaymentFilesIds = [
                        ...this.startOrder.secondPaymentFilesIds,
                        ...files.map((f: any) => f.id)
                    ];
                    break;
                case 'THIRD_PAYMENT':
                    if (!this.startOrder.thirdPaymentFiles?.length) this.startOrder.thirdPaymentFiles = [];
                    this.startOrder.thirdPaymentFiles = [
                        ...this.startOrder.thirdPaymentFiles,
                        ...files
                    ];
                    if (!this.startOrder.thirdPaymentFilesIds?.length) this.startOrder.thirdPaymentFilesIds = [];
                    this.startOrder.thirdPaymentFilesIds = [
                        ...this.startOrder.thirdPaymentFilesIds,
                        ...files.map((f: any) => f.id)
                    ];
                    break;
            }

        }
    }

    maxValue(value: number): number {
        const available = this.startOrder.signPrice - (this.startOrder.firstPayment || 0) - (this.startOrder.secondPayment || 0) - (this.startOrder.thirdPayment || 0);

        if (available < 0) return this.startOrder.signPrice - value;
        return available + value;
    }

    get balance(): number {
        const available = this.startOrder.signPrice - (this.startOrder.firstPayment || 0) - (this.startOrder.secondPayment || 0) - (this.startOrder.thirdPayment || 0);
        if (available < 0) return 0;

        return available;
    }

    get needRestart(): boolean {
        return !!this.startOrderDate && this.currentProject?.status !== Constants.PROJECT_STATUSES.START_ORDERED &&
            this.currentProject?.status === Constants.PROJECT_STATUSES.PROGRAMMED &&
            this.currentProject?.status !== Constants.PROJECT_STATUSES.CLOSED;
    }

    ngOnDestroy() {
        this.cleanParams();
    }
}
