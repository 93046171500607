import {Component, OnDestroy, OnInit} from '@angular/core';
import {IProjectGeneralBudgetTable, ProjectModel} from "../../../models";
import {ActivatedRoute} from "@angular/router";
import {ProjectGeneralBudgetService, ProjectService} from "../../../services";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToasterService} from "../../../../shared/services/toaster.service";
import {CryptoService} from "../../../../shared";

@Component({
    selector: 'app-budget',
    templateUrl: './budget.component.html',
    styleUrls: ['./budget.component.scss']
})
export class BudgetComponent extends ProjectModel implements OnInit, OnDestroy {
    private step: number = 11;
    projectBudget!: IProjectGeneralBudgetTable;
    private objForm!: FormGroup;

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly budgetService: ProjectGeneralBudgetService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private crypto: CryptoService,
    ) {
        super(activatedRoute, projectService, tService, crypto);
    }

    ngOnInit(): void {
        this.readParams();
        this.setActive(this.step);
        this.initializeBudget();
        this.service.get(this.params['id']).then((project) => {
            const { id, name, budgets } = project || {};
            this.currentProject = { ...project };
            if (budgets?.length) {
                const budget = budgets[0];
                this.projectBudget = {
                    ...this.projectBudget,
                    id: budget.id,
                    owner: budget.ownerAmount,
                    entity: budget.entityAmount,
                    entityInKind: budget.entityInKindAmount,
                };
                this.setTotals();
            }

            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required],
            });
            this.disableFormIfSubmitted();
            this.showContent = true;
        });
    }

    setTotals() {
        if (this.projectBudget) {
            this.projectBudget.total = this.projectBudget.owner + this.projectBudget.entity + this.projectBudget.entityInKind;
            this.projectBudget.ownerPercentage = (this.projectBudget.owner * 100) / this.projectBudget.total;
            this.projectBudget.entityPercentage = (this.projectBudget.entity * 100) / this.projectBudget.total;
            this.projectBudget.entityInKindPercentage = 100 - this.projectBudget.ownerPercentage - this.projectBudget.entityPercentage;
            this.projectBudget.totalEntityPercentage = 100 - this.projectBudget.ownerPercentage;
        }
    }

    initializeBudget() {
        this.projectBudget = {
            entity: 0,
            entityInKind: 0,
            entityInKindPercentage: 0,
            entityPercentage: 0,
            owner: 0,
            ownerPercentage: 0,
            projectId: 0,
            total: 0,
            totalEntityPercentage: 0
        };
    }

    onEditInit(data: any ) {
        const projectId = parseInt(this.params['id']);
        this.objForm = this.fb.group({
            owner: [this.projectBudget.owner, [Validators.required, Validators.min(0)]],
            entity: [this.projectBudget.entity, [Validators.required, Validators.min(0)]],
            entityInKind: [this.projectBudget.entityInKind, [Validators.required, Validators.min(0)]],
            projectId: [projectId, Validators.required],
        });
    }

    onEditComplete(data: any) {
        const previous = this.objForm.value;
        this.setTotals();
        this.objForm.patchValue({
            owner: data.data.owner,
            entity: data.data.entity,
            entityInKind: data.data.entityInKind,
        });

        if (this.objForm.valid && this.hasChanged(previous)) {
            if (this.projectBudget.id) {
                this.budgetService.update(this.objForm.value, this.projectBudget.id).then((resp) => {
                    this.toasterService.success('Los datos han sido actualizados satisfactoriamente.')
                })
            } else {
                this.budgetService.store(this.objForm.value).then((resp) => {
                    this.projectBudget = {
                        ...this.projectBudget,
                        id: resp.id,
                    }
                    this.toasterService.success('Los datos han sido creados satisfactoriamente.')
                })
            }
        }
    }

    hasChanged(previous: any): boolean {
        const { owner: ownerPrev, entity: entityPrev, entityInKind: entityInKindPrev  } = previous;
        const { owner, entity, entityInKind } = this.objForm.value;

        return !(ownerPrev === owner && entityPrev === entity && entityInKindPrev === entityInKind);
    }

    ngOnDestroy() {
        this.cleanParams();
    }

}
