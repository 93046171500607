export interface IStreet {
    gid: number;
    objectid_1: number;
    dep: string;
    prov: string;
    mun: string;
    cod_dep: string;
    cod_prov:string;
    cod_mun: string;
    nom_bar: string;
    cod_bar: number;
    nom_ca: string;
    cod_call: number;
    shape_leng: number;
    geom: any;
}
