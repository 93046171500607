import {IWizardStep} from "./interfaces";
import { ActivatedRoute } from '@angular/router';
import { IITCPRequest, ITCPRequestService } from '../../itcp';
import { FormGroup } from '@angular/forms';
import { ToasterService } from '../../shared/services/toaster.service';
import { Constants } from '../../shared/constants';
import { GenericFunctions } from '../../shared/services/generic-functions';

export abstract class ITCPRequest {
    protected steps: IWizardStep[] = [];
    protected route: ActivatedRoute;
    protected params: any = {};
    protected currentRequest: IITCPRequest | undefined;
    protected service: ITCPRequestService;
    protected toasterService: ToasterService;
    protected showContent: boolean = false;
    protected formGroup!: FormGroup;
    protected currentStep: IWizardStep | undefined;
    private sub: any;

    protected constructor(
        route: ActivatedRoute,
        service: ITCPRequestService,
        tService: ToasterService
    ){
        this.route = route;
        this.service = service;
        this.toasterService = tService;
    }

    async setCurrentRequest(): Promise<IITCPRequest> {
        this.currentRequest = await this.service.getByToken(this.params['token']);
        return this.currentRequest;
    }

    disableFormIfReadonly() {
        if (this.currentRequest?.readOnly) this.formGroup.disable();
    }

    setActive(step: number): void {
        this.steps.map(s => {
            if (s.step <= step) s.active = true;
        });
        this.currentStep = this.steps.find(s => s.step === step);
    }

    setSteps() {
        this.steps = [
            { step: 1, text: 'Información Básica de la Entidad', icon: 'pi pi-building', path: `/public/itcp-request/${this.params['token']}/basic-info` },
            { step: 2, text: 'Información de la MAE', icon: 'pi pi-user', path: `/public/itcp-request/${this.params['token']}/mae-info` },
            { step: 3, text: 'Información del Encargado del Proyecto', icon: 'pi pi-sitemap', path: `/public/itcp-request/${this.params['token']}/project-manager-info` },
            { step: 4, text: 'Información de Contacto', icon: 'pi pi-phone', path: `/public/itcp-request/${this.params['token']}/contact-info` },
            { step: 5, text: 'Finalizar', icon: 'pi pi-check', path: `/public/itcp-request/${this.params['token']}/done` },
        ];
    }

    readParams() {
        this.sub = this.route.params.subscribe(params => {
            this.params['token'] = params['code'];
        });

        this.setSteps();
    }

    cleanParams() {
        this.sub.unsubscribe();
        this.params = {};
    }

    get form(): any {
        return this.formGroup.controls;
    }

    save(data: any): void {

        if (this.currentRequest?.readOnly) return;

        const hasChanged = GenericFunctions.isFormChanged(this.formGroup);
        // Invalid
        if (!this.formGroup.valid) {
            this.toasterService.error('Corrija los datos e inténtelo nuevamente');
            return;
        }

        const postData: IITCPRequest = this.formGroup.value;

        if (postData?.id && hasChanged) {
            this.service
                .update(postData,postData.id)
                .then((resp: IITCPRequest) => {
                    this.toasterService.success('Los datos de la Solicitud fueron actualizados con éxito');
                })
                .catch((err) => {
                    console.error(err.error.message);
                    this.toasterService.error('Se ha producido un error inténtelo más tarde o contáctese con el Administrador.');
                });
        }
    }
}
