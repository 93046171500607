import { Component } from '@angular/core';
import { PrimeNGConfig, Translation } from 'primeng/api';
import { GenericFunctions } from './shared/services/generic-functions';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    private translationsCalendar: Translation = this._genericFunctions.dateEsConfig();
    constructor(private primengConfig: PrimeNGConfig, private readonly _genericFunctions: GenericFunctions) { }

    ngOnInit() {
        this.primengConfig.ripple = false;
        this.primengConfig.setTranslation(this.translationsCalendar);
    }
}
