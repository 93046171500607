import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GenericFunctions } from '../../../shared/services/generic-functions';
import { ToasterService } from '../../../shared/services/toaster.service';
import { AnnouncementService } from '../../services';
import { IAnnouncement } from '../../models';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Constants } from '../../../shared/constants';
import { Subject, takeUntil } from 'rxjs';
import { Table } from 'primeng/table';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ModalComponent } from './modal/modal.component';
import * as dayjs from 'dayjs';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss'],
})
export class AnnouncementComponent implements OnInit, OnDestroy {
  public loading = false;
  public data: IAnnouncement[] = [];
  public resource = Constants.RESOURCES.ANNOUNCEMENTS;
  public breadcrumbs: MenuItem[] = [
    { label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home'] },
    { label: ' ITCP', routerLink: ['/itcp'] },
    { label: ' Convocatorias' },
  ];

  public header: Array<any> = [
    { label: 'Título', column: 'title', sort: true },
    { label: 'Descripción', column: 'description', sort: true },
    { label: 'Publicado', column: 'published', sort: false },
    { label: 'Fecha Límite de Postulación', column: 'dueDate', sort: true },
    { label: 'Periodo de Ejecución', column: 'yearsLimit', sort: true },
    { label: ' ', sort: false },
  ];

  @ViewChild('dtAnnouncement') table!: Table;
  private onDestroy$ = new Subject();
  public ref!: DynamicDialogRef;
  private modal: DynamicDialogConfig<IAnnouncement> = {
    ...Constants.MODAL.default,
  };

  constructor(
    private readonly genericFunctions: GenericFunctions,
    private readonly service: AnnouncementService,
    public readonly dialogService: DialogService,
    public readonly confirmationService: ConfirmationService,
    private readonly toasterService: ToasterService,
    private clipboard: Clipboard,
  ) {}

  ngOnInit(): void {
    this.getAll();
  }

  getAll(): void {
    this.loading = true;
    this.service
      .getAll()
      .then((response) => {
        this.data = response;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => (this.loading = false));
  }

  setFilter(event: any, type: string, query: string, column: string = ''): void {
    if (type === 'filterGlobal') {
      this.table.filterGlobal(event.target.value, query);
    } else if (type === 'filter') {
      this.table.filter(event.target.value, column, query);
    }
  }

  selectedItem(data: IAnnouncement, type: string) {
    switch (type) {
      case 'delete':
        this.confirmationService.confirm({
          message: '¿Se encuentra seguro de eliminar este registro?',
          accept: () => {
            this.service
              .delete(data.id)
              .then((resp: boolean) => {
                if (resp) {
                  this.toasterService.success('La Convocatoria fué eliminada con éxito.');
                  this.reload();
                }
              })
              .catch((err) => {
                console.error(err);
                this.toasterService.error(
                  'Se ha producido un error inténtelo más tarde o contáctese con el Administrador.'
                );
              });
          },
        });
        break;
      case 'edit':
        this.modal.data = data;
        this.modal.header = 'Actualizar Convocatoria';
        this.ref = this.dialogService.open(ModalComponent, this.modal);
        this.ref.onClose.pipe(takeUntil(this.onDestroy$)).subscribe((resp) => {
          if (resp) this.reload();
        });
        break;
      default:
        break;
    }
  }

  addNew() {
    this.modal.header = 'Nueva Convocatoria';
    this.modal.data = undefined;
    this.ref = this.dialogService.open(ModalComponent, this.modal);
    this.ref.onClose.pipe(takeUntil(this.onDestroy$)).subscribe((resp) => {
      if (resp) this.reload();
    });
  }

  reload() {
    this.getAll();
  }

  isExpired(date: Date): boolean {
    const dueDate = dayjs(date).toDate();
    const today = dayjs().toDate();

    return dueDate < today;
  }

  getLink(code: string) {
      const protocol = window.location.protocol;
      const domain = window.location.host;
      return`${protocol}//${domain}/public/itcp-request/${code}`;
  }

  copyLink(link: string) {
      this.clipboard.copy(link);
      this.toasterService.info('El enlace fue copiado al portapapeles');
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(undefined);
    this.onDestroy$.complete();
  }
}
