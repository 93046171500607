import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { MenuItem } from 'primeng/api';

import { Constants } from '../../../shared/constants';
import { IProject, ProjectService } from '../../../itcp';
import { CredentialsService, ProjectFlow, ProjectStagesEnum } from '../../../shared';
import { PermissionService } from '../../../shared/services/permission.service';
import { LayoutService } from '../../../shared/services/app.layout.service';
import { User } from '../../../core/user/interfaces';
import { GenericFunctions } from '../../../shared/services/generic-functions';

@Component({
  selector: 'app-process-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class ProcessOrdersComponent implements OnInit {
    resource = Constants.RESOURCES.TRACKING_ORDERS;
    projects: IProject[] = []; //Assigned projects

    loading: boolean = false;
    breadcrumbs: MenuItem[] = [
        { label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home'] },
        { label: ' Seguimiento', routerLink: ['/tracking'] },
        { label: ' Órdenes de Proceso' },
    ];
    @ViewChild('dt') table!: Table;

    constructor(
        private readonly credentialsService: CredentialsService,
        private readonly permissionService: PermissionService,
        private readonly projectService: ProjectService,
        private readonly layoutService: LayoutService,
    ) {}

    ngOnInit(): void {
        this.layoutService.showMenuDesktop(true);
        this.getAll();
    }

    getAll(): void {
        this.loading = true;
        this.projectService.getAll({ params: {
                status: ProjectFlow.trackingStatusesForProcessOrders(),
                stage: ProjectStagesEnum.TRACKING
            }})
            .then((resp) => {
                this.projects = [...resp];
            })
            .finally(() => {
                this.loading = false;
            });
    }

    setFilter(event: any, type: string, query: string, column: string = ''): void {
        if (type === 'filterGlobal') {
            this.table.filterGlobal(event.target.value, query);
        } else if (type === 'filter') {
            this.table.filter(event.target.value, column, query);
        }
    }

    getFullName(user: User): string {
        return GenericFunctions.getUserFullName(user);
    }
}
