import { Component, Input, OnInit } from '@angular/core';
import { BudgetSummaryType, ComponentType } from '../../../../services';
import { IProjectBudget } from '../../../../models/interfaces/project-budget.interface';

@Component({
  selector: 'app-budget-vipfe-table',
  templateUrl: './comp.component.html',
  styleUrls: ['./comp.component.scss']
})
export class CompComponent implements OnInit {

  @Input() projectBudgets: IProjectBudget[] = [];
  @Input() componentType!: ComponentType;
  @Input() title: string = '';

  budgets: BudgetSummaryType[] = [];

  constructor() {
  }

  ngOnInit(): void {
    this.setSummary(this.projectBudgets);
  }

  private setSummary(budgets: IProjectBudget[]) {
    const total: BudgetSummaryType = {
      description: 'SUB-TOTAL EN Bs.',
      entity: 0,
      entityInKind: 0,
      owner: 0,
      total: 0
    };

    budgets.map(b => {
      if (b.componentType === this.componentType) {
        const item: BudgetSummaryType = {
          description: `${b.budgetItemCode} - ${b.budgetItemDescription}`,
          entity: b.totals.entity,
          entityInKind: 0,
          owner: b.totals.owner,
          total: b.totals.total,
          type: b.componentType
        };
        this.budgets.push(item);

        total.owner += b.totals.owner;
        total.entity += b.totals.entity;
        total.total += b.totals.total;
      }
    });
    this.budgets.push(total);
  }

}
