import { IProjectBudget } from './interfaces/project-budget.interface';

export class BudgetSummaryHelper {
  public static setRowTotals(budget: IProjectBudget) {
    budget.totals.owner = budget.ownerY1 + budget.ownerY2 + budget.ownerY3;
    budget.totals.entity = budget.entityY1 + budget.entityY2 + budget.entityY3;
    budget.totals.entityInKind = budget.entityInKindY1 + budget.entityInKindY2 + budget.entityInKindY3;
    budget.totals.total = budget.totals.owner + budget.totals.entity + budget.totals.entityInKind;
  }

  public static setBudgetAmounts(budgets: IProjectBudget[]) {
    budgets.map(b => {
      b['totals'] = { owner: 0, entity: 0, entityInKind: 0, total: 0 };
      b.price = b.priceAmount || 0;
      b.ownerY1 = b.ownerAmountY1 || 0;
      b.ownerY2 = b.ownerAmountY2 || 0;
      b.ownerY3 = b.ownerAmountY3 || 0;
      b.entityY1 = b.entityAmountY1 || 0;
      b.entityY2 = b.entityAmountY2 || 0;
      b.entityY3 = b.entityAmountY3 || 0;
      b.entityInKindY1 = b.entityInKindAmountY1 || 0;
      b.entityInKindY2 = b.entityInKindAmountY2 || 0;
      b.entityInKindY3 = b.entityInKindAmountY3 || 0;

      BudgetSummaryHelper.setRowTotals(b);
    });
  }
}
