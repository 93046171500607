<p-breadcrumb [model]='breadcrumbs'></p-breadcrumb>
<p-dialog [closable]='false'
          [showHeader]='false'
          [visible]='generatingPDF'
          [modal]='true'
          [style]="{ width: '50vw' }"
          [draggable]='false'
          [resizable]='false'
>
  <div class='text-center py-6'>
    Por favor espere mientras se genera el documento PDF .....
  </div>
</p-dialog>
<div class='container'>
  <div class='grid'>
    <form class='sm:col-12 d-flex align-items-center' [formGroup]='announcementForm'>
      <div class='grid'>
        <div class='field lg:col-4 md:col-6 sm:col-12'>
          <ng-multiselect-dropdown
            [placeholder]="'Seleccione las Convocatorias'"
            [settings]='announcementDropdownSettings'
            [data]='announcements'
            formControlName='announcementIds'
          >
          </ng-multiselect-dropdown>
        </div>
        <div class='field lg:col-4 md:col-6 sm:col-12'>
          <ng-multiselect-dropdown
            [placeholder]="'Departamento'"
            [settings]='citiesDropdownSettings'
            [data]='cities'
            formControlName='cityIds'
          >
          </ng-multiselect-dropdown>
        </div>
        <div class='field lg:col-4 md:col-6 sm:col-12'>
          <button
            pButton
            type='button'
            label='Filtrar'
            (click)='getAll()'
            [disabled]='this.announcementForm.invalid'
          ></button>
        </div>
      </div>
    </form>
  </div>
</div>
<p-tabView styleClass='tabview-custom' *ngIf='showData'>
  <p-tabPanel>
    <ng-template pTemplate='header'>
      <i class='pi pi-list'></i>
      <span class='ml-2'>Municipios Interesados</span>
    </ng-template>

    <div class='grid'>
      <div class='field lg:col-4 md:col-6 sm:col-12'>
        <button
          pButton
          type='button'
          label='Exportar a PDF'
          (click)='generatePDF()'
          [disabled]='generatingPDF'
        ></button>
      </div>
    </div>
    <div class='container mt-3' #pdfContent>
      <p-table
        #dtTable
        [value]='data'
        styleClass='p-datatable-gridlines'
        [scrollable]='true'
        scrollHeight='400px'
        scrollDirection='both'
        [loading]='loading'
        currentPageReportTemplate='{first} al {last} de {totalRecords} items'
      >
        <ng-template pTemplate='header'>
          <tr>
            <th *ngIf='!generatingPDF' class='bg-primary text-center' style='width: 50px'>Nº</th>
            <th *ngIf='!generatingPDF' class='bg-primary text-center' style='width: 200px'>Usuario</th>
            <th class='bg-primary text-center' style='width: 120px'>Departamento</th>
            <th class='bg-primary text-center' style='width: 150px'>Provincia</th>
            <th class='bg-primary text-center' style='width: 150px'>Municipio</th>
            <th *ngIf='!generatingPDF' class='bg-primary text-center' style='width: 150px'>MAE</th>
            <th class='bg-primary text-center' style='width: 200px'>Nombre del proyecto</th>
            <th class='bg-primary text-center' style='width: 100px'>Beneficiarios</th>
            <th class='bg-primary text-center' style='width: 130px'>Presupuesto FONABOSQUE</th>
            <th class='bg-primary text-center' style='width: 130px'>Presupuesto ENTIDAD</th>
            <th class='bg-primary text-center' style='width: 130px'>Presupuesto TOTAL</th>
            <th class='bg-primary text-center' style='width: 130px'>Estado del proyecto</th>
          </tr>
        </ng-template>

        <ng-template pTemplate='body' let-request let-ri='rowIndex'>
          <tr>
            <td *ngIf='!generatingPDF' class='text-center overflow-hidden' style='width: 50px'>{{ ri + 1 }}</td>
            <td *ngIf='!generatingPDF' class='text-center overflow-hidden'
                style='width: 200px'>{{ request?.user?.correo }}</td>
            <td class='text-center overflow-hidden' style='width: 120px'>{{ request?.entity?.city?.dep }}</td>
            <td class='text-center overflow-hidden' style='width: 150px'>{{ request?.entity?.province?.prov }}</td>
            <td *ngIf='!generatingPDF' class='text-center overflow-hidden'
                style='width: 150px'>{{ request?.entity?.town?.mun }}</td>
            <td class='text-center overflow-hidden' style='width: 150px'>{{ request?.seoName }}</td>
            <td class='text-center overflow-hidden' style='width: 200px'>{{ request?.project?.name }}</td>
            <td class='text-center overflow-hidden' style='width: 100px'>{{ request?.totalBeneficiaries }}</td>
            <td class='text-center overflow-hidden' style='width: 130px'>{{ request?.budget?.ownerAmount }}</td>
            <td class='text-center overflow-hidden' style='width: 130px'>{{ request?.budget?.entityAmount }}</td>
            <td class='text-center overflow-hidden' style='width: 130px'>
              {{ ((request?.budget?.ownerAmount || 0) + (request?.budget?.entityAmount || 0)).toFixed(2) }}
            </td>
            <td class='text-center' style='width: 130px'>{{ request?.state }}</td>
          </tr>
        </ng-template>
      </p-table>

      <div class='grid mt-3' #interestedTownsPDFContent>
        <div *ngIf='!generatingPDF' class='sm:col-12'>
          <div class='text-lg font-semibold'>
            Listado de Municipios interesados total y por Dpto (Cuentan con Usuario y contraseña)
          </div>
        </div>

        <div [ngClass]="{'field lg:col-4 md:col-6 sm:col-12': true, 'text-2xl': generatingPDF}">
          <div class='mb-3'>
            <div class='flex'>
              <span class='font-semibold w-6 text-right'>Enviado:</span>
              <span class='ml-2'>{{ this.totalSend }}</span>
            </div>
            <div class='flex'>
              <span class='font-semibold w-6 text-right'>No completado:</span>
              <span class='ml-2'>{{ this.totalIncomplete }}</span>
            </div>
            <div class='flex'>
              <span class='font-semibold w-6 text-right'>Con usuario y contraseña:</span>
              <span class='ml-2'>{{ this.totalWithUser }}</span>
            </div>
            <div class='flex'>
              <span class='font-semibold w-6 text-right'>Total: </span>
              <span class='ml-2'>{{ this.totalWithUser + this.totalIncomplete + this.totalSend }}</span>
            </div>
          </div>

          <div [ngClass]="{'text-2xl': generatingPDF}">
            <p [ngClass]="{'font-semibold': true, 'text-lg': !generatingPDF}">Departamentos</p>

            <div class='flex' *ngFor='let city of parsedDataPerCity'>
              <span class='font-semibold w-6 text-right'>{{ city.name }}</span>
              <span class='ml-2'>{{ city.count }}</span>
            </div>
          </div>
        </div>

        <div class='field lg:col-4 md:col-6 sm:col-12'>
          <p-chart type='pie' [data]='interestedTownsChartData'></p-chart>
        </div>
      </div>

      <div class='grid mt-3' #townsListContent>
        <div class='sm:col-12 mb-3'>
          <div class='text-lg font-semibold'>
            Listado de Municipios total y por provincia y departamento Dpto presentaron ITCP
          </div>
        </div>
        <div class='field lg:col-4 md:col-6 sm:col-12'>
          <p-table
            #dtCities
            [value]='requestDataPerCityParsed'
            styleClass='p-datatable-gridlines'
            [loading]='loading'
            [tableStyle]="{'min-width': '100%'}"
            currentPageReportTemplate='{first} al {last} de {totalRecords} items'
          >
            <ng-template pTemplate='header'>
              <tr>
                <th class='bg-primary text-center' style='width: 120px' colspan='2'>Departamentos</th>
              </tr>
            </ng-template>
            <ng-template pTemplate='body' let-city let-ri='rowIndex'>
              <tr>
                <td style='width: 100px'>{{ city.name }}</td>
                <td style='width: 20px'>{{ city.count }}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate='footer'>
              <tr>
                <td class='text-right' style='width: 100px'>Total:</td>
                <td style='width: 20px'>{{ totalSend }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class='field lg:col-4 md:col-6 sm:col-12'>
          <p-table
            #dtProvinces
            [value]='requestDataPerProvinceParsed'
            styleClass='p-datatable-gridlines'
            [loading]='loading'
            [tableStyle]="{'min-width': '100%'}"
            currentPageReportTemplate='{first} al {last} de {totalRecords} items'
          >
            <ng-template pTemplate='header'>
              <tr>
                <th class='bg-primary text-center' colspan='3' style='width: 220px'>Provincias</th>
              </tr>
            </ng-template>
            <ng-template pTemplate='body' let-province let-ri='rowIndex'>
              <tr>
                <td style='width: 100px'>{{ province.cityName }}</td>
                <td style='width: 100px'>{{ province.provinceName }}</td>
                <td style='width: 20px'>{{ province.count }}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate='footer'>
              <tr>
                <td colspan='2' class='text-right' style='width: 200px'>Total:</td>
                <td style='width: 20px'>{{ totalSend }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class='field lg:col-4 md:col-6 sm:col-12'>
          <p-table
            #dtTowns
            [value]='requestDataPerTownParsed'
            styleClass='p-datatable-gridlines'
            [loading]='loading'
            [resizableColumns]='!generatingPDF'
            [tableStyle]="{'min-width': '100%'}"
            currentPageReportTemplate='{first} al {last} de {totalRecords} items'
          >
            <ng-template pTemplate='header'>
              <tr>
                <th class='bg-primary text-center' colspan='4' style='width: 320px'>Municipios</th>
              </tr>
            </ng-template>
            <ng-template pTemplate='body' let-town let-ri='rowIndex'>
              <tr>
                <td style='width: 100px'>{{ town.cityName }}</td>
                <td style='width: 100px'>{{ town.provinceName }}</td>
                <td style='width: 100px'>{{ town.townName }}</td>
                <td style='width: 20px'>{{ town.count }}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate='footer'>
              <tr>
                <td colspan='3' class='text-right' style='width: 300px'>Total:</td>
                <td style='width: 20px'>{{ totalSend }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>


    <div *ngIf='generatingPDF' class='grid mt-3' #chartPDFRef>
      <div class='sm:col-12'>
        <p-chart type='bar' [data]='filteredByTownsChartData' [options]='barChartOptions'></p-chart>
      </div>
    </div>

  </p-tabPanel>
  <p-tabPanel>
    <ng-template pTemplate='header'>
      <i class='pi pi-list'></i>
      <span class='ml-2'>Presupuesto por municipios</span>
    </ng-template>

    <div class='container mt-3'>
      <form class='sm:col-12 d-flex align-items-center' [formGroup]='itcpForm'>
        <div class='grid'>
          <div class='field lg:col-4 md:col-6 sm:col-12'>
            <ng-multiselect-dropdown
              [placeholder]="'Municipios'"
              [settings]='townsDropdownSettings'
              [data]='towns'
              formControlName='towns'
            >
            </ng-multiselect-dropdown>
          </div>
          <div class='field lg:col-4 md:col-6 sm:col-12'>
            <ng-multiselect-dropdown
              [placeholder]="'Estado del Proyecto'"
              [settings]='stateDropdownSettings'
              [data]='states'
              formControlName='states'
            >
            </ng-multiselect-dropdown>
          </div>
          <div class='field lg:col-4 md:col-6 sm:col-12'>
            <button
              pButton
              type='button'
              label='Filtrar'
              (click)='filterByTownsAndStates()'
              [disabled]='this.announcementForm.invalid'
            ></button>
          </div>
        </div>
      </form>

      <div class='grid mt-3'>
        <div class='sm:col-12'>
          <p-chart type='bar' [data]='filteredByTownsChartData' [options]='barChartOptions'></p-chart>
        </div>
      </div>
    </div>
  </p-tabPanel>
</p-tabView>
