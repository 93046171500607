import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { ProjectGoalResultService } from '../../../edtp';
import { LayoutService } from '../../../shared/services/app.layout.service';
import { CartographyService, ProjectFlow, ProjectStagesEnum } from '../../../shared';
import { IProject, ProjectService } from '../../../itcp';
import { Constants } from '../../../shared/constants';
import { Html2canvasService } from '../../../shared/services/html2canvas.service';
import { ProjectC2Service } from '../../../edtp/services/project-c2.service';
import { IProjectC2 } from '../../../edtp/models/interfaces/project-c2.interface';
import { PdfBuilder, PDFOrientation } from '../../../shared/lib/pdf-builder/pdf-builder';
import { IProjectC3 } from '../../../edtp/models/interfaces/project-c3.interface';
import { ProjectC3Service } from '../../../edtp/services/project-c3.service';

const RELEVANT_PROJECT_COMPONENT_KEYS = {
    PLANT_NURSERY: 'VIVERO',
    NUMBER_NATIVE_FOREST_SPECIES: 'NRO DE ESPECIES FORESTALES NATIVAS',
    NUMBER_EXOTIC_FOREST_SPECIES: 'NRO DE ESPECIES FORESTALES EXÓTICAS',
    NUMBER_FRUIT_SPECIES: 'NRO DE ESPECIES FRUTALES',
    NUMBER_PEOPLE_TRAINED: 'NRO DE PERSONAS CAPACITADAS',
    NUMBER_PEOPLE_ASSISTED: 'NRO DE PERSONAS ASISTIDAS',
    NUMBER_FORESTED_HECTARES: 'NRO DE HECTAREAS FORESTADAS',
    NUMBER_REFORESTED_HECTARES: 'NRO DE HECTAREAS REFORESTADAS'
};

@Component({
    selector: 'app-tracking',
    templateUrl: './tracking.component.html',
    styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnInit {
    loading = false;
    generatingPDF = false;
    private _goalResultsPerProject: any[] = [];
    private _goalResultPerReprogrammedProject: any[] = [];
    private _flattedFSGoalResultsPerProject: any[] = [];
    flattedFSGoalResultsPerReprogrammedProject: any[] = [];
    private _flattedFNGoalResultsPerProject: any[] = [];
    flattedFNGoalResultsPerReprogrammedProject: any[] = [];
    private _closedProjects: IProject[] = [];
    private _projectsForComponentReport: IProject[] = [];
    private _seedlingsPerProject: (IProjectC2 & { total?: number })[] = [];
    private _haPerProject: IProjectC3[] = [];
    private _projectWithStartOrders: IProject[] = [];
    runningProjects: IProject[] = [];
    private _haSpecieForestedPerProject: (IProjectC3 & { total?: number })[] = [];
    private _haSpecieReforestedPerProject: (IProjectC3 & { total?: number })[] = [];
    totalHaReforestedPerProject: any[] = [];
    totalHaForestedPerProject: any[] = [];
    seedlingsTotals: { quantityYear1Total?: number, quantityYear2Total?: number, quantityYear3Total?: number } = {};
    componentKeys = { ...RELEVANT_PROJECT_COMPONENT_KEYS };

    private onDestroy$ = new Subject();
    public breadcrumbs: MenuItem[] = [
        { label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home'] },
        { label: ' Reportes' }
    ];
    @ViewChild('dtFSGoalResultsTable') dtFSGoalResultsTable!: Table;
    @ViewChild('dtFSReprogrammedGoalResultsTable') dtFSReprogrammedGoalResultsTable!: Table;
    @ViewChild('dtFNReprogrammedGoalResultsTable') dtFNReprogrammedGoalResultsTable!: Table;
    @ViewChild('dtFSCityGoalResultsTable') dtFSCityGoalResultsTable!: Table;
    @ViewChild('dtFNGoalResultsTable') dtFNGoalResultsTable!: Table;
    @ViewChild('dtFNCityGoalResultsTable') dtFNCityGoalResultsTable!: Table;
    @ViewChild('closedProjectsTable') closedProjectsTable!: Table;
    @ViewChild('dtRunningProjects') dtRunningProjects!: Table;
    @ViewChild('dtProjectWithStartOrders') dtProjectWithStartOrders!: Table;
    @ViewChild('seedlingsPerProjectTable') seedlingsPerProjectTable!: Table;
    @ViewChild('forestationPerProjectTable') forestationPerProjectTable!: Table;
    @ViewChild('reforestationPerProjectTable') reforestationPerProjectTable!: Table;
    @ViewChild('forestationPerCityTable') forestationPerCityTable!: Table;
    @ViewChild('reforestationPerCityTable') reforestationPerCityTable!: Table;
    @ViewChild('componentProjectsTable') componentProjectsTable!: Table;
    @ViewChild('closedProjectsRef') closedProjectsRef!: ElementRef;
    @ViewChild('projectComponentsRef') projectComponentsRef!: ElementRef;

    constructor(
        private fb: FormBuilder,
        private readonly goalResultService: ProjectGoalResultService,
        private readonly layoutService: LayoutService,
        private readonly cartographyService: CartographyService,
        private readonly projectService: ProjectService,
        private html2canvasService: Html2canvasService,
        private projectC2Service: ProjectC2Service,
        private projectC3Service: ProjectC3Service
    ) {
    }

    get haSpecieForestedPerProject(): (IProjectC3 & { total?: number })[] {
        return this._haSpecieForestedPerProject;
    }

    set haSpecieForestedPerProject(values: any[]) {
        const mapped: any = {};
        for (const value of values) {
            const { projectId, project, dep } = value;
            const projectData = mapped[projectId] || {};
            const { total } = value;
            const totalForested = (projectData.totalForested || 0) + total;
            mapped[projectId] = { ...project, totalForested, dep };
        }

        this._haSpecieForestedPerProject = values;
        this.totalHaForestedPerProject = Object.values(mapped);
    }

    get haSpecieReforestedPerProject(): (IProjectC3 & { total?: number })[] {
        return this._haSpecieReforestedPerProject;
    }

    set haSpecieReforestedPerProject(values: any[]) {
        const mapped: any = {};
        for (const value of values) {
            const { projectId, project, dep } = value;
            const projectData = mapped[projectId] || {};
            const { total } = value;
            const totalReforested = (projectData.totalReforested || 0) + total;
            mapped[projectId] = { ...project, totalReforested, dep };
        }

        this._haSpecieReforestedPerProject = values;
        this.totalHaReforestedPerProject = Object.values(mapped);
    }

    get goalResultPerReprogrammedProject(): any[] {
        return this._goalResultPerReprogrammedProject;
    }

    set goalResultPerReprogrammedProject(value: any[]) {
        this._goalResultPerReprogrammedProject = value;
        const fsGoalResultPerReprogrammedProject: any[] = [];
        const fnGoalResultPerReprogrammedProject: any[] = [];
        value.forEach((result) => {
            this.buildProjectDataForReport(result, fsGoalResultPerReprogrammedProject, fnGoalResultPerReprogrammedProject);
        });

        this.flattedFNGoalResultsPerReprogrammedProject = fnGoalResultPerReprogrammedProject;
        this.flattedFSGoalResultsPerReprogrammedProject = fsGoalResultPerReprogrammedProject;
    }

    get projectWithStartOrders(): IProject[] {
        return this._projectWithStartOrders;
    }

    set projectWithStartOrders(value: IProject[]) {
        this._projectWithStartOrders = value;
        this._projectWithStartOrders.sort((p1: any, p2: any) => (p1.startOrder?.startOrderDate || '').localeCompare((p1.startOrder?.startOrderDate || '')));
    }

    get haPerProject(): (IProjectC3 & { total?: number })[] {
        return this._haPerProject;
    }

    set haPerProject(value: (IProjectC3 & { total?: number })[]) {
        const forestedPerProjects: any[] = [];
        const reforestedPerProject: any[] = [];
        this._haPerProject = value.map((c3: any) => {
            const {
                quantityYear1,
                quantityYear2,
                quantityYear3,
                project: { iTCPRequest: { entity: { town: { dep } } } }
            } = c3;
            const total = [quantityYear1, quantityYear2, quantityYear3].reduce((a: number, b) => a + (b || 0), 0);

            const parsedObject = { ...c3, dep, total };
            if (c3.type === 'AFFORESTATION') forestedPerProjects.push(parsedObject);
            else if (c3.type === 'REFORESTATION') reforestedPerProject.push(parsedObject);

            return parsedObject;
        });
        forestedPerProjects.sort((a, b) => (a.project?.iTCPRequest?.entity?.nombre || '').localeCompare(b.project?.iTCPRequest?.entity?.nombre || ''));
        reforestedPerProject.sort((a, b) => (a.project?.iTCPRequest?.entity?.nombre || '').localeCompare(b.project?.iTCPRequest?.entity?.nombre || ''));

        this.haSpecieReforestedPerProject = reforestedPerProject;
        this.haSpecieForestedPerProject = forestedPerProjects;
    }

    get seedlingsPerProject(): IProjectC2[] {
        return this._seedlingsPerProject;
    }

    set seedlingsPerProject(value: IProjectC2[]) {
        this._seedlingsPerProject = value.map((c2) => {
            const { quantityYear1, quantityYear2, quantityYear3 } = c2;
            const total = [quantityYear1, quantityYear2, quantityYear3].reduce((a: number, b) => a + (b || 0), 0);
            this.seedlingsTotals.quantityYear1Total = (this.seedlingsTotals.quantityYear1Total || 0) + (quantityYear1 || 0);
            this.seedlingsTotals.quantityYear2Total = (this.seedlingsTotals.quantityYear2Total || 0) + (quantityYear2 || 0);
            this.seedlingsTotals.quantityYear3Total = (this.seedlingsTotals.quantityYear3Total || 0) + (quantityYear3 || 0);

            return { ...c2, total };
        });
    }

    get flattedFSGoalResultsPerProject(): any[] {
        return this._flattedFSGoalResultsPerProject;
    }

    set flattedFSGoalResultsPerProject(value: any[]) {
        this._flattedFSGoalResultsPerProject = value;
    }

    get flattedFNGoalResultsPerProject(): any[] {
        return this._flattedFNGoalResultsPerProject;
    }

    set flattedFNGoalResultsPerProject(value: any[]) {
        this._flattedFNGoalResultsPerProject = value;
    }

    get goalResultsPerProject(): any[] {
        return this._goalResultsPerProject;
    }

    get closedProjects(): IProject[] {
        return this._closedProjects;
    }

    set closedProjects(value: IProject[]) {
        this._closedProjects = value;
    }

    get projectsForComponentReport(): IProject[] {
        return this._projectsForComponentReport;
    }

    set projectsForComponentReport(value: IProject[]) {
        this._projectsForComponentReport = value;
    }

    set goalResultsPerProject(value: any[]) {
        this._goalResultsPerProject = value;
        const fsGoalResultPerProject: any[] = [];
        const fnGoalResultPerProject: any[] = [];
        value.forEach((result) => {
            this.buildProjectDataForReport(result, fsGoalResultPerProject, fnGoalResultPerProject);
        });

        this.flattedFNGoalResultsPerProject = fnGoalResultPerProject;
        this.flattedFSGoalResultsPerProject = fsGoalResultPerProject;
    }

    private buildProjectDataForReport(result: any, fsGoalResultPerProject: any[], fnGoalResultPerProject: any[]) {
        let flattedObject: any = {};
        let totalExecuted = 0;

        for (const act of result.activities) {
            const { activity, monthDetails, generalExpected, programmerType, uom } = act;

            flattedObject = {
                projectName: result.project.name,
                city: result.project.iTCPRequest.entity.city.dep,
                province: result.project.iTCPRequest.entity.province.prov,
                town: result.project.iTCPRequest.entity.town.mun,
                component: result.goal?.component?.name + ' - ' + result.goal?.component?.description,
                activity,
                generalExpected,
                uom,
                expectedResult: result.expectedResult,
                monthDetails
            };
            if (programmerType === 'FS') {
                totalExecuted = monthDetails.reduce((a: any, b: any) => {
                    const { executed } = b;
                    return a + executed;
                }, 0);
                flattedObject.totalExecuted = totalExecuted;
                fsGoalResultPerProject.push(flattedObject);
            } else {
                const { budgets } = act;
                for (const budget of budgets) {
                    const { monthProgrammed, budgetItemCode, budgetItemDetails } = budget;
                    let totalEntityAmount = 0;
                    let totalEntityInKindAmount = 0;
                    let totalOwnerAmount = 0;
                    let totalEntityAmountExecuted = 0;
                    let totalEntityInKindAmountExecuted = 0;
                    let totalOwnerAmountExecuted = 0;
                    for (const month of monthProgrammed) {
                        const {
                            entity,
                            owner,
                            entityInKind,
                            entityExecuted,
                            ownerExecuted,
                            entityInKindExecuted
                        } = month;
                        totalEntityAmount += entity;
                        totalOwnerAmount += owner;
                        totalEntityInKindAmount += entityInKind;
                        totalEntityAmountExecuted += entityExecuted;
                        totalOwnerAmountExecuted += ownerExecuted;
                        totalEntityInKindAmountExecuted += entityInKindExecuted;
                    }

                    flattedObject = {
                        ...flattedObject,
                        budgetItemCode,
                        budgetItemDetails,
                        totalEntityAmount,
                        totalEntityAmountExecuted,
                        totalOwnerAmount,
                        totalOwnerAmountExecuted,
                        totalEntityInKindAmount,
                        totalEntityInKindAmountExecuted,
                        monthDetails: [...monthProgrammed]
                    };

                    fnGoalResultPerProject.push(flattedObject);
                }
            }
        }
    }

    ngOnInit(): void {
        this.loadReprogrammedProjects();
        this.loadRunningProjects();
        this.loadMainData();
        this.loadClosedProjects();
        this.loadProjectsForComponentsReport();
        this.loadSpecieTypesData();
        this.loadHaForestedPerProjectData();
        this.loadStartOrderProjects();
    }

    loadRunningProjects() {
        this.projectService.getAll({
            params: {
                status: ProjectFlow.trackingStatusesForProgrammer(),
                stage: ['EDTP', 'TRACKING']
            }
        })
            .then((resp) => {
                this.runningProjects = resp;
            });
    }

    loadMainData() {
        this.goalResultService
            .getAll({
                params: {
                    byProjectStatuses: ProjectFlow.trackingStatusesForProgrammer(),
                    withProjectInfo: true
                }
            })
            .then((result) => {
                this.goalResultsPerProject = result;
            })
            .catch((e) => console.log('Error:', e));
    }

    loadReprogrammedProjects() {
        this.goalResultService
            .getAll({
                params: {
                    onlyReprogrammed: true,
                    withProjectInfo: true
                }
            })
            .then((result) => {
                this.goalResultPerReprogrammedProject = result;
            })
            .catch((e) => console.log('Error:', e));
    }

    loadClosedProjects() {
        this.projectService.getAll({ params: { status: [Constants.PROJECT_STATUSES.CLOSED] } })
            .then((response) => {
                this.closedProjects = response;
            })
            .catch((e) => console.log('Error:', e));
    }

    loadProjectsForComponentsReport() {
        this.projectService.getAll({
            params: {
                status: [Constants.PROJECT_STATUSES.CLOSED, ...ProjectFlow.trackingStatusesForProgrammer()],
                stage: [ProjectStagesEnum.EDTP, ProjectStagesEnum.ITCP, ProjectStagesEnum.TRACKING],
                withBaseLines: true
            }
        })
            .then((response) => {
                this.projectsForComponentReport = response;
            })
            .catch((e) => console.log('Error:', e));
    }

    loadStartOrderProjects() {
        this.projectService.getAll({
            params: {
                status: ProjectFlow.trackingStatusesForProcessOrders(),
                stage: ProjectStagesEnum.TRACKING,
                withStartOrder: true
            }
        })
            .then((resp) => {
                this.projectWithStartOrders = resp;
            });
    }

    loadSpecieTypesData() {
        this.projectC2Service.getAll({
            params: { byProjectStatuses: [Constants.PROJECT_STATUSES.CLOSED, ...ProjectFlow.trackingStatusesForProgrammer()] }
        })
            .then((response) => {
                this.seedlingsPerProject = [...response];
            });
    }

    loadHaForestedPerProjectData() {
        this.projectC3Service.getAll({
            params: { byProjectStatuses: [Constants.PROJECT_STATUSES.CLOSED, ...ProjectFlow.trackingStatusesForProgrammer()] }
        })
            .then((response) => {
                this.haPerProject = [...response];
            });
    }

    getBaseLineQuantityByComponentName(project: IProject, settingTypes: string[]) {
        const { baseLines = [] } = project;
        const relevantData = baseLines.filter((baseLine) => settingTypes.includes(baseLine.component)) || {};

        return relevantData.reduce((a, b) => a + b.quantity, 0);
    }

    generateExecutedProjectsPDF() {
        this.generatingPDF = true;
        setTimeout(async () => {
            const pdf = new PdfBuilder('REPORTE PROYECTOS EN EJECUCION', 'SEGUIMIENTO', PDFOrientation.LANDSCAPE);

            if (this.runningProjects.length) {
                const runningProjectsTable = this.dtRunningProjects.tableViewChild.nativeElement;
                await pdf.addTable(runningProjectsTable, 'Listado de Municipios y otros con proyectos en Ejecucion');
                pdf.addText('El cuadro muestra el listado de entidades con proyectos cofinanciados por el FONABOSQUE en etapa de ejecución');
            }

            if (this.flattedFSGoalResultsPerProject.length) {
                const physicalTableElement = this.dtFSGoalResultsTable.tableViewChild.nativeElement;
                await pdf.addTable(physicalTableElement, 'Ejecucion Fisica  por Proyecto, componente, resultado y actividad', {
                    columnStyles: {
                        0: {
                            valign: 'middle',
                            halign: 'center',
                            fillColor: 'white',
                            cellWidth: 100
                        },
                        1: {
                            valign: 'middle',
                            halign: 'center'
                        },
                        2: {
                            valign: 'middle',
                            halign: 'center'
                        },
                        3: {
                            valign: 'middle',
                            halign: 'center'
                        },
                        4: {
                            valign: 'middle',
                            halign: 'center'
                        },
                        5: {
                            valign: 'middle'
                        }
                    }
                });
                pdf.addText('El cuadro muestra el avance de la ejecución física por proyecto, detallado por componente, resultado y actividad.\n');
            }
            if (this.flattedFNGoalResultsPerProject.length) {
                const financialTableElement = this.dtFNGoalResultsTable.tableViewChild.nativeElement;
                await pdf.addTable(financialTableElement, 'Ejecucion Financiera por Proyecto, componentes, actividad, partida presupuestaria y fuente de financiamiento', {
                    columnStyles: {
                        0: {
                            valign: 'middle',
                            halign: 'center',
                            fillColor: 'white',
                            cellWidth: 100
                        },
                        1: {
                            valign: 'middle',
                            halign: 'center'
                        },
                        2: {
                            valign: 'middle',
                            halign: 'center'
                        },
                        3: {
                            valign: 'middle',
                            halign: 'center'
                        },
                        4: {
                            valign: 'middle',
                            halign: 'center'
                        },
                        5: {
                            cellWidth: 60
                        }
                    }
                });
                pdf.addText('El cuadro muestra el avance de la ejecución financiera por proyecto, detallado por componente,  actividad, partida presupuestaria y fuente de financiamiento');
            }

            pdf.generate('fonabosque_reporte_fisico_financiero');
            this.generatingPDF = false;
        }, 10);
    }

    generateReprogrammeddProjectsPDF() {
        this.generatingPDF = true;
        setTimeout(async () => {
            const pdf = new PdfBuilder('REPORTE PROYECTOS EN EJECUCION', 'SEGUIMIENTO', PDFOrientation.LANDSCAPE);

            if (this.flattedFSGoalResultsPerProject.length) {
                const physicalTableElement = this.dtFSReprogrammedGoalResultsTable.tableViewChild.nativeElement;
                await pdf.addTable(physicalTableElement, 'Reporte de las reprogramacion fisica por proyecto (por componente y actividad)', {
                    columnStyles: {
                        0: {
                            valign: 'middle',
                            halign: 'center',
                            fillColor: 'white',
                            cellWidth: 100
                        },
                        1: {
                            valign: 'middle',
                            halign: 'center'
                        },
                        2: {
                            valign: 'middle',
                            halign: 'center'
                        },
                        3: {
                            valign: 'middle',
                            halign: 'center'
                        },
                        4: {
                            valign: 'middle',
                            halign: 'center'
                        },
                        5: {
                            valign: 'middle'
                        }
                    }
                });
                pdf.addText('El cuadro muestra las reprogramaciones físicas realizadas a los componentes y actividades de los proyectos cofinanciados por el FONABOSQUE');
            }
            if (this.flattedFNGoalResultsPerProject.length) {
                const financialTableElement = this.dtFNReprogrammedGoalResultsTable.tableViewChild.nativeElement;
                await pdf.addTable(financialTableElement, 'Reporte de las reprogramacion financiera por proyecto (por componente, actividad y partida presupuestaria)', {
                    columnStyles: {
                        0: {
                            valign: 'middle',
                            halign: 'center',
                            fillColor: 'white',
                            cellWidth: 100
                        },
                        1: {
                            valign: 'middle',
                            halign: 'center'
                        },
                        2: {
                            valign: 'middle',
                            halign: 'center'
                        },
                        3: {
                            valign: 'middle',
                            halign: 'center'
                        },
                        4: {
                            valign: 'middle',
                            halign: 'center'
                        },
                        5: {
                            cellWidth: 60
                        }
                    }
                });
                pdf.addText('El cuadro y gráfico muestra las reprogramaciones financieras realizadas a los componentes, actividades y partidas presupuestarias de los proyectos cofinanciados por el FONABOSQUE');
            }

            pdf.generate('fonabosque_reporte_proyectos_reprogramados');
            this.generatingPDF = false;
        }, 10);
    }

    generateClosedProjectsPDF() {
        this.generatingPDF = true;
        let currentHeight = 0;

        setTimeout(async () => {
            const pdf = new PdfBuilder('REPORTE DE PROYECTOS CERRADOS', 'SEGUIMIENTO', PDFOrientation.LANDSCAPE);

            if (this.closedProjects.length) {
                const tableElement = this.closedProjectsTable.tableViewChild.nativeElement;
                pdf.addTable(tableElement, 'Listado de Municipios y otros con proyectos en etapa Cierre');
                pdf.addText('El cuadro muestra el listado de entidades con proyectos cofinanciados por el FONABOSQUE en etapa de cierre\n');
            }

            if (this.projectsForComponentReport.length) {
                pdf.addText('Resumen de Proyectos Cerrados o en ejecucion', 14);
                const closedTableElement = this.closedProjectsTable.tableViewChild.nativeElement;
                pdf.addTable(closedTableElement);
            }

            pdf.generate('fonabosque_reporte_proyectos_cerrados');
            this.generatingPDF = false;
        }, 10);
    }

    generateSeedlingsReportPDF() {
        this.generatingPDF = true;
        setTimeout(async () => {
            const tableElement = this.seedlingsPerProjectTable.tableViewChild.nativeElement;
            const pdf = new PdfBuilder('REPORTE PLANTINES', 'SEGUIMIENTO', PDFOrientation.LANDSCAPE);
            await pdf.addTable(tableElement, 'Nro de plantines por especie de los proyectos cerrados, en cierre y en ejecucion', {
                columnStyles: {
                    1: {
                        valign: 'middle',
                        fillColor: 'white'
                    },
                    5: {
                        valign: 'middle',
                        halign: 'center',
                        cellWidth: 50
                    }
                }
            });
            pdf.addText('El cuadro y gráfico muestra el número de especies forestadas/reforestadas de los proyectos en etapa de ejecución y cierre');
            pdf.generate('fonabosque_reporte_plantines_por_especie');
            this.generatingPDF = false;
        }, 10);
    }

    generateForestedReportPDF() {
        this.generatingPDF = true;
        setTimeout(async () => {
            const afforestedTable = this.forestationPerProjectTable.tableViewChild.nativeElement;
            const pdf = new PdfBuilder('REPORTE HECTAREAS', 'SEGUIMIENTO', PDFOrientation.LANDSCAPE);
            await pdf.addTable(afforestedTable, 'Nro de ha Forestadas por tipo de plantacion de los Proyectos cerrados, en cierre y en ejecucion');
            pdf.addText('El cuadro muestra el número de hectáreas forestadas de los proyectos en ejecución y cierre');

            const reforestedTable = this.reforestationPerProjectTable.tableViewChild.nativeElement;
            await pdf.addTable(reforestedTable, 'Nro de ha Reforestadas por tipo de plantacion de los proyectos cerrados, en cierre y en ejecucion');
            pdf.addText('El cuadro y gráfico muestra el número de hectáreas reforestadas de los proyectos en ejecución y cierre');

            pdf.generate('fonabosque_reporte_hectareas_forestadas_reforestadas');
            this.generatingPDF = false;
        }, 10);
    }

    generateForestedPerCityReportPDF() {
        this.generatingPDF = true;
        setTimeout(async () => {
            const afforestedTable = this.forestationPerCityTable.tableViewChild.nativeElement;
            const pdf = new PdfBuilder('REPORTE HECTAREAS FORESTADAS Y REFORESTADAS POR DEPARTAMENTO', 'SEGUIMIENTO', PDFOrientation.LANDSCAPE);
            await pdf.addTable(afforestedTable, 'Hectáreas forestadas a nivel nacional y departamental', {
                columnStyles: {
                    3: {
                        valign: 'middle',
                        halign: 'center',
                        cellWidth: 50
                    }
                }
            });
            pdf.addText('El cuadro muestra el número de hectáreas forestadas de los proyectos a nivel nacional y departamental');

            const reforestedTable = this.reforestationPerCityTable.tableViewChild.nativeElement;
            await pdf.addTable(reforestedTable, 'Hectáreas reforestadas a nivel nacional y departamental', {
                columnStyles: {
                    3: {
                        valign: 'middle',
                        halign: 'center',
                        cellWidth: 50
                    }
                }
            });
            pdf.addText('El cuadro muestra el número de hectáreas reforestadas de los proyectos a nivel nacional y departamental');

            pdf.generate('fonabosque_reporte_hectareas_forestadas_reforestadas_por_departamento');
            this.generatingPDF = false;
        }, 10);
    }

    generateFSFNPerCityReportPDF() {
        this.generatingPDF = true;
        setTimeout(async () => {
            const pdf = new PdfBuilder('Reporte fisico/financiero por proyecto, componente, resultado y actividad, departamento y Nacional', 'SEGUIMIENTO', PDFOrientation.LANDSCAPE);
            if (this.flattedFSGoalResultsPerProject.length) {
                const physicalTableElement = this.dtFSCityGoalResultsTable.tableViewChild.nativeElement;
                await pdf.addTable(physicalTableElement, 'Ejecución física por proyecto a nivel nacional y departamental', {
                    columnStyles: {
                        0: {
                            valign: 'middle',
                            halign: 'center',
                            fillColor: 'white'
                        },
                        1: {
                            valign: 'middle',
                            halign: 'center'
                        },
                        2: {
                            valign: 'middle',
                            halign: 'center'
                        },
                        3: {
                            valign: 'middle',
                            halign: 'center',
                            cellWidth: 50
                        },
                        4: {
                            valign: 'middle',
                            halign: 'center'
                        },
                        5: {
                            valign: 'middle'
                        }
                    }
                });
                pdf.addText('El cuadro muestra el avance de la ejecución física por proyecto, detallado por componente, resultado y actividad, a nivel nacional y departamental.');
            }
            if (this.flattedFNGoalResultsPerProject.length) {
                const financialTableElement = this.dtFNCityGoalResultsTable.tableViewChild.nativeElement;
                await pdf.addTable(financialTableElement, 'El cuadro muestra el avance de la ejecución financiera por proyecto, detallado por componente,  actividad, partida presupuestaria y fuente de financiamiento, , a nivel nacional y departamental.', {
                    columnStyles: {
                        0: {
                            valign: 'middle',
                            halign: 'center',
                            fillColor: 'white'
                        },
                        1: {
                            valign: 'middle',
                            halign: 'center'
                        },
                        2: {
                            valign: 'middle',
                            halign: 'center'
                        },
                        3: {
                            valign: 'middle',
                            halign: 'center',
                            cellWidth: 30
                        },
                        4: {
                            valign: 'middle',
                            halign: 'center',
                            cellWidth: 30
                        },
                        5: {
                            valign: 'middle',
                            halign: 'center'
                        },
                        6: {
                            valign: 'middle',
                            halign: 'center',
                            cellWidth: 30
                        },
                        7: {
                            valign: 'middle',
                            halign: 'center',
                            cellWidth: 30
                        }
                    }
                });
                pdf.addText('El cuadro muestra el avance de la ejecución financiera por proyecto, detallado por componente,  actividad, partida presupuestaria y fuente de financiamiento');
            }

            pdf.generate('fonabosque_reporte_fisico_financiero');
            this.generatingPDF = false;
        });
    }

    generateStartOrderProjectPdf() {
        this.generatingPDF = true;
        setTimeout(async () => {
            const tableElement = this.dtProjectWithStartOrders.tableViewChild.nativeElement;
            const pdf = new PdfBuilder('Desembolsos programados', 'SEGUIMIENTO', PDFOrientation.LANDSCAPE);

            await pdf.addTable(tableElement, 'Programacion  de Desembolsos para los Proyectos');
            pdf.addText('El cuadro muestra el detalle de los desembolsos programados para los proyectos cofinanciados por el FONABOSQUE en etapa de cierre');

            pdf.generate('fonabosque_reporte_plantines_por_especie');
            this.generatingPDF = false;
        }, 10);
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(undefined);
        this.onDestroy$.complete();
    }
}
