export interface IProjectGeneralBudget {
    id?: number;
    projectId: number;
    owner: any;
    entity: any;
    entityInKind: any;
    entityAmount: number,
    ownerAmount: number,
    entityInKindAmount: number,
}

export interface IProjectGeneralBudgetTable {
    id?: number;
    projectId: number;
    owner: number;
    entity: number;
    entityInKind: number;
    total: number;
    ownerPercentage: number;
    entityPercentage: number;
    entityInKindPercentage: number;
    totalEntityPercentage: number;
}
