<app-wizard
    [steps]='steps'
    [current]='currentStep'
    [bodyContentTpl]='projectForm'
    [nextDisabled]='this.formGroup?.invalid'
    (onClickNext)='save($event)'
    [layout]='true'
>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column' [formGroup]='formGroup'>
        <div class='grid'>
            <div class='field col-12'>
                <h3>
                    DECLARACION JURADA
                </h3>
            </div>

            <div class="field col-12 md:col-4 flex flex-wrap justify-content-end align-items-center text-right">
                <div class="text-base">
                    <label>Subir Declaración Jurada</label>
                    <div class="mt-2">
                        <a href="/assets/docs/modelo_declaracion_jurada.docx" download pTooltip="Descargar modelo de declaración jurada">
                            <i class="pi pi-download mr-2"></i>
                            Descargar Modelo
                        </a>
                    </div>
                </div>
            </div>
            <div class="field col-12 md:col-8">
                <app-input-file
                    *ngIf='!isSubmitted'
                    [multiple]="false"
                    [maxFiles]="1"
                    [defaultFiles]="swornFiles"
                    [accept]="'application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                    (onUploaded)="onUploadedSwornFile($event)"
                >
                </app-input-file>

                <a *ngIf='currentProject?.swornFile' [href]="attachmentService.getFileUrl(currentProject?.swornFile?.key)"
                   [download]="currentProject?.swornFile?.filename" target="_blank">
                    {{currentProject?.swornFile?.filename}}
                </a>
            </div>

            <div class="field col-12 md:col-4 flex flex justify-content-end align-items-center text-right">
                <label>Subir ITCP Foliado y Rubricado</label>
            </div>
            <div class="field col-12 md:col-8">

                <app-input-file
                    *ngIf='!isSubmitted'
                    [multiple]="false"
                    [maxFiles]="1"
                    [accept]="'application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                    [defaultFiles]="itcpFiles"
                    (onUploaded)="onUploadedItcpFile($event)"
                >
                </app-input-file>
                <a *ngIf='currentProject?.itcpFile' [href]="attachmentService.getFileUrl(currentProject?.itcpFile?.key)"
                   [download]="currentProject?.itcpFile?.filename" target="_blank">
                    {{currentProject?.itcpFile?.filename}}
                </a>
            </div>

<!--            <div  *ngIf='!isSubmitted' class="field col-12 md:col-4 flex flex justify-content-end align-items-center text-right">-->
<!--                <label>Modelo de Carta de Solicitud de Financiamiento</label>-->
<!--            </div>-->
<!--            <div  *ngIf='!isSubmitted' class="field col-12 md:col-8">-->

<!--            </div>-->

            <div class="field col-12 md:col-4 flex flex justify-content-end align-items-center text-right">
                <div class="text-base w-full">
                    <label>Subir Solicitud de Financiamiento</label>
                    <div class="mt-2">
                        <a href="/assets/docs/Modelo_solicitud-entidades-CON-vivero.docx" download pTooltip="Descargar Modelo con Vivero">
                            <i class="pi pi-download mr-2"></i>
                            Descargar Modelo con Vivero
                        </a>
                    </div>
                    <div>
                        <a href="/assets/docs/Modelo_solicitud-entidades-SIN-vivero.docx" download pTooltip="Descargar Modelo sin Vivero">
                            <i class="pi pi-download mr-2"></i>
                            Descargar Modelo sin Vivero
                        </a>
                    </div>
                </div>
            </div>
            <div class="field col-12 md:col-8">
                <app-input-file
                    *ngIf='!isSubmitted'
                    [multiple]="false"
                    [defaultFiles]="requestFinancingFiles"
                    [maxFiles]="1"
                    [accept]="'application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                    (onUploaded)="onUploadedFinancingFile($event)"
                >
                </app-input-file>

                <a *ngIf='currentProject?.requestFinancingFile' [href]="attachmentService.getFileUrl(currentProject?.requestFinancingFile?.key)"
                   [download]="currentProject?.requestFinancingFile?.filename" target="_blank">
                    {{currentProject?.requestFinancingFile?.filename}}
                </a>
            </div>
        </div>
    </div>
</ng-template>
