import {Constants} from "../../shared/constants";

export class StepsEDTPBuilder {
    private readonly allSteps: any[] = [];
    projectId: number;

    constructor(projectId: number) {
        this.projectId = projectId;
        this.allSteps = [
            {
                step: 1,
                text: 'Objetivos del Proyecto',
                icon: 'pi pi-list',
                path: `/edtp/projects/${this.projectId}/goals`,
                type: 'ALL',
            },
            {
                step: 2,
                text: 'Línea Base - Tipología II',
                icon: 'pi pi-server',
                path: `/edtp/projects/${this.projectId}/line/t-ii`,
                type: Constants.TYPOLOGIES.TYPOLOGY_II,
            },

            {
                step: 3,
                text: 'Línea Base - Tipología III',
                icon: 'pi pi-server',
                path: `/edtp/projects/${this.projectId}/line/t-iii`,
                type: Constants.TYPOLOGIES.TYPOLOGY_III,
            },

            {
                step: 4,
                text: 'Línea Base - Tipología IV',
                icon: 'pi pi-server',
                path: `/edtp/projects/${this.projectId}/line/t-iv`,
                type: Constants.TYPOLOGIES.TYPOLOGY_IV,
            },

            {
                step: 5,
                text: 'Línea Base - Tipología V',
                icon: 'pi pi-server',
                path: `/edtp/projects/${this.projectId}/line/t-v`,
                type: Constants.TYPOLOGIES.TYPOLOGY_V,
            },

            {
                step: 6,
                text: 'Componente I - Tipología II',
                icon: 'pi pi-ticket',
                path: `/edtp/projects/${this.projectId}/c1/t-ii`,
                type: Constants.TYPOLOGIES.TYPOLOGY_II,
            },
            {
                step: 7,
                text: 'Componente I - Tipología III',
                icon: 'pi pi-ticket',
                path: `/edtp/projects/${this.projectId}/c1/t-iii`,
                type: Constants.TYPOLOGIES.TYPOLOGY_III,
            },
            {
                step: 8,
                text: 'Componente I - Tipología IV',
                icon: 'pi pi-ticket',
                path: `/edtp/projects/${this.projectId}/c1/t-iv`,
                type: Constants.TYPOLOGIES.TYPOLOGY_IV,
            },
            {
                step: 9,
                text: 'Componente I - Tipología V',
                icon: 'pi pi-ticket',
                path: `/edtp/projects/${this.projectId}/c1/t-v`,
                type: Constants.TYPOLOGIES.TYPOLOGY_V,
            },
            {
                step: 10,
                text: 'Componente II - Tipología II',
                icon: 'pi pi-ticket',
                path: `/edtp/projects/${this.projectId}/c2/t-ii`,
                type: Constants.TYPOLOGIES.TYPOLOGY_II,
            },
            {
                step: 11,
                text: 'Componente II - Tipología III',
                icon: 'pi pi-ticket',
                path: `/edtp/projects/${this.projectId}/c2/t-iii`,
                type: Constants.TYPOLOGIES.TYPOLOGY_III,
            },
            {
                step: 12,
                text: 'Componente II - Tipología IV',
                icon: 'pi pi-ticket',
                path: `/edtp/projects/${this.projectId}/c2/t-iv`,
                type: Constants.TYPOLOGIES.TYPOLOGY_IV,
            },
            {
                step: 13,
                text: 'Componente II - Tipología V',
                icon: 'pi pi-ticket',
                path: `/edtp/projects/${this.projectId}/c2/t-v`,
                type: Constants.TYPOLOGIES.TYPOLOGY_V,
            },
            {
                step: 14,
                text: 'Componente III - Tipología II',
                icon: 'pi pi-ticket',
                path: `/edtp/projects/${this.projectId}/c3/t-ii`,
                type: Constants.TYPOLOGIES.TYPOLOGY_II,
            },
            {
                step: 15,
                text: 'Componente III - Tipología III',
                icon: 'pi pi-ticket',
                path: `/edtp/projects/${this.projectId}/c3/t-iii`,
                type: Constants.TYPOLOGIES.TYPOLOGY_III,
            },
            {
                step: 16,
                text: 'Componente III - Tipología IV',
                icon: 'pi pi-ticket',
                path: `/edtp/projects/${this.projectId}/c3/t-iv`,
                type: Constants.TYPOLOGIES.TYPOLOGY_IV,
            },
            {
                step: 17,
                text: 'Componente III - Tipología V',
                icon: 'pi pi-ticket',
                path: `/edtp/projects/${this.projectId}/c3/t-v`,
                type: Constants.TYPOLOGIES.TYPOLOGY_V,
            },
            {
                step: 18,
                text: 'Componente IV - Tipología II',
                icon: 'pi pi-ticket',
                path: `/edtp/projects/${this.projectId}/c4/t-ii`,
                type: Constants.TYPOLOGIES.TYPOLOGY_II,
            },
            {
                step: 19,
                text: 'Componente IV - Tipología III',
                icon: 'pi pi-ticket',
                path: `/edtp/projects/${this.projectId}/c4/t-iii`,
                type: Constants.TYPOLOGIES.TYPOLOGY_III,
            },
            // {
            //     step: 20,
            //     text: 'Componente IV - Tipología IV',
            //     icon: 'pi pi-ticket',
            //     path: `/edtp/projects/${this.projectId}/c4/t-iv`,
            //     type: Constants.TYPOLOGIES.TYPOLOGY_IV,
            // },
            {
                step: 21,
                text: 'Componente IV - Tipología V',
                icon: 'pi pi-ticket',
                path: `/edtp/projects/${this.projectId}/c4/t-v`,
                type: Constants.TYPOLOGIES.TYPOLOGY_V,
            },
            {
                step: 22,
                text: 'Presupuesto General',
                icon: 'pi pi-dollar',
                path: `/edtp/projects/${this.projectId}/budget`,
                type: 'ALL',
            },
            {
                step: 23,
                text: 'Presupuesto por Componente',
                icon: 'pi pi-money-bill',
                path: `/edtp/projects/${this.projectId}/budget-comp`,
                type: 'ALL',
            },
            {
                step: 24,
                text: 'Presupuesto por VIPFE',
                icon: 'pi pi-dollar',
                path: `/edtp/projects/${this.projectId}/budget-vipfe`,
                type: 'ALL',
            },
            {
                step: 25,
                text: 'Costos de Inversión',
                icon: 'pi pi-money-bill',
                path: `/edtp/projects/${this.projectId}/costs`,
                type: 'ALL',
            },
            {
                step: 26,
                text: 'Estructura de Financiamiento',
                icon: 'pi pi-th-large',
                path: `/edtp/projects/${this.projectId}/structure`,
                type: 'ALL',
            },
            {
                step: 27,
                text: 'Finalizar',
                icon: 'pi pi-check',
                path: `/edtp/projects/${this.projectId}/done`,
                type: 'ALL',
            },
        ];
    }

    buildSteps(typologies: any[] = []) {
        if (!typologies.length) return this.allSteps;
        return this.allSteps.filter(s => typologies.includes(s.type) || s.type === 'ALL');
    }
}
