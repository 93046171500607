import {IAttachment} from "../../../shared";

export type ExpostCycleType = 'SHORT' | 'MEDIUMM' | 'LARGE';
export interface IProjectExpost {
    id?: number | string;
    title: string;
    projectId: number;
    cycle: ExpostCycleType;
    seizure: number;
    mortality: number;
    refaults: number;
    evalFiles?: any[];// returned by query to populate in files
    evalFilesIds?: number[];
    files?: IAttachment[];
}
