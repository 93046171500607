<div class='w-full bg-gray-200'>
    <div class='flex flex-column gap-2 justify-content-start'>
        <div class="w-full  px-4 py-1">
            <span class="font-bold">{{group.goal?.description}}</span>
            <div class="text-sm">{{group.goal?.component?.name}}</div>
            <div class="text-xs">{{group.goal?.component?.description}}</div>
        </div>
        <div class="flex gap-4 justify-content-start align-items-center bg-gray-100">
            <div class="px-6 py-1">
                <b>Resultado Esperado:</b>
                <div>{{group.expectedResult}}</div>
            </div>
        </div>
    </div>
</div>

<p-table
    [value]="group.activities"
    dataKey="id"
    editMode="row"
    [tableStyle]="{'width': '100%'}"
    #dt>
    <ng-template pTemplate="header">
        <tr>
            <th class="bg-secondary border-x-1 text-center p-0" style="width:60px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="width:300px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="width:120px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="width:120px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="width:120px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="width:120px"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-obj let-editing="editing" let-index="rowIndex">
        <tr [pEditableRow]="obj">
            <td style='width:60px'>{{index + 1}}</td>
            <td>
                {{obj.activity}}
            </td>
            <td class="text-center">
                {{obj.currentMonth.expected | number: '1.0-2'}}
            </td>
            <td class="text-center">
                {{ calcPMG(obj) | number: '1.0-2'}} %
            </td>
            <td class="text-center relative">
                {{obj.currentMonth.executed | number: '1.0-2'}}
            </td>
            <td class="text-center">
                {{ calcEMG(obj) | number: '1.0-2'}} %
            </td>
        </tr>
    </ng-template>
</p-table>
