<div class="">
  <p-breadcrumb [model]="breadcrumbs"></p-breadcrumb>
  <div class="card">
    <p-tabView styleClass="tabview-custom">
      <p-tabPanel>
        <ng-template pTemplate="header">
          <i class="pi pi-list"></i>
          <span class="ml-2">Asignacion</span>
        </ng-template>

        <p-toolbar styleClass="mb-4 w-full clean-toolbar">
          <div class="flex justify-content-between flex-column align-items-center sm:flex-row w-full">
            <div class="p-input-icon-left mb-2 searcher">
              <i class="pi pi-search"></i>
              <input
                pInputText
                type="text"
                class="w-full"
                (input)="setFilter($event, 'filterGlobal', 'contains')"
                placeholder="Buscar..."
              />
            </div>
            <div class="my-2">
              <button
                appPermission
                [resource]="resource"
                [enableActions]="'canView'"
                pButton
                pRipple
                label="Actualizar"
                icon="pi pi-refresh"
                class="p-button-info mr-2"
                (click)="populateData()"
              ></button>
            </div>
          </div>
        </p-toolbar>
        <p-table
          #dtSort
          [value]="assignableProjects"
          styleClass="p-datatable-gridlines"
          [loading]="loading"
          [resizableColumns]="true"
          [paginator]="true"
          [rows]="6"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="{first} al {last} de {totalRecords} items"
          [rowsPerPageOptions]="[6, 12, 24]"
          (selectionChange)="onSelectionChange($event)"
          [globalFilterFields]="[
            'project.name',
            'physicalTech.primerNombre',
            'physicalTech.segundoNombre',
            'physicalTech.apellidoMaterno',
            'physicalTech.apellidoPaterno'
          ]"
        >
          <ng-template pTemplate="header">
            <tr>
              <th class="bg-primary">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th class="bg-primary">Nro</th>
              <th class="bg-primary">Proyecto</th>
              <th class="bg-primary">Técnico</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-data>
            <tr>
              <td>
                <p-tableCheckbox [value]="data"></p-tableCheckbox>
              </td>
              <td>{{ data.id }}</td>
                <td><div class="large-cell">{{ data.project.name }}</div></td>
              <td>{{ getPhysicalTechnicianName(data.physicalTech) }}</td>
            </tr>
          </ng-template>
        </p-table>

        <div class="w-full text-right mt-8">
          <button
              appPermission
              [resource]="resource"
              [enableActions]="'canCreate'"
              class="p-button p-button-outlined px-6 py-4"
              (click)="sortData()">Sortear</button>

          <button
              appPermission
              [resource]="resource"
              [enableActions]="'canCreate'"
            class="ml-4 p-button p-button-primary px-6 py-4"
            [disabled]="!assignedAndSelectedProjects.length"
            (click)="submit()"
          >
            Asignar
          </button>
        </div>
      </p-tabPanel>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <i class="pi pi-list"></i>
          <span class="ml-2">Asignados</span>
        </ng-template>
        <p-table
          #dtProjectTechnicians
          [value]="storedAssignedProjects"
          styleClass="p-datatable-gridlines"
          [loading]="loading"
          [resizableColumns]="true"
          [paginator]="true"
          [rows]="6"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="{first} al {last} de {totalRecords} items"
          [rowsPerPageOptions]="[6, 12, 24]"
          responsiveLayout="scroll"
          [filterDelay]="0"
        >
          <ng-template pTemplate="header">
            <tr>
              <th class="bg-primary">Nro</th>
              <th class="bg-primary">Proyecto</th>
              <th class="bg-primary">Asignado</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
            <tr>
              <td>{{ rowIndex + 1 }}</td>
                <td><div class="large-cell">{{ data.name }}</div></td>
              <td>{{ getPhysicalTechnicianName(data.technicians[0].user) }}</td>
            </tr>
          </ng-template>
        </p-table>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
