import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ProjectProgrammerModel} from "../../../models";
import {Table} from "primeng/table";
import {ActivatedRoute} from "@angular/router";
import {ProjectGoalService, ProjectService} from "../../../../itcp";
import {CryptoService, SettingsService} from "../../../../shared";
import {FormBuilder, Validators} from "@angular/forms";
import {ToasterService} from "../../../../shared/services/toaster.service";
import {IProjectGoalResult, ProjectGoalResultService} from "../../../../edtp";
import {LayoutService} from "../../../../shared/services/app.layout.service";
import {ConfirmationService} from "primeng/api";
import {Constants} from "../../../../shared/constants";

@Component({
  selector: 'app-financial-details',
  templateUrl: './financial-details.component.html',
  styleUrls: ['./financial-details.component.scss']
})
export class FinancialDetailsComponent extends ProjectProgrammerModel implements OnInit, OnDestroy {
    private step: number = 6;

    loading: boolean = false;
    results: IProjectGoalResult[] = [];
    @ViewChild('dt') table!: Table;

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly goalResultService: ProjectGoalResultService,
        private readonly setService: SettingsService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private crypto: CryptoService,
        private readonly confirmationService: ConfirmationService,
        private readonly layoutService: LayoutService,
    ) {
        super(activatedRoute, projectService, tService, setService, crypto);
    }

    ngOnInit(): void {
        this.layoutService.showMenuDesktop(false);
        this.readParams();
        this.setActive(this.step);
        this.load();
    }

    load() {
        this.loading = true;
        this.service.get(this.params['id']).then((project) => {
            const { id, name } = project || {};
            this.currentProject = { ...project };
            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required],
                status: [Constants.PROJECT_STATUSES.PROGRAMMED],
            });
        }).finally(() => {
            this.disableFormIfSubmitted();
            this.calculateYears();
            this.initYearOptions();
            this.generateMonthlyOptions();
            this.loadActivities();
            this.showContent = true;
        });
    }

    generateMonthAndLoadActivities() {
        this.generateMonthlyOptions();
        this.loadActivities();
    }

    loadActivities() {
        const projectId = this.currentProject?.id || 0;
        const year = this.selectedProjectYear || 1;
        const month = this.selectedOptionMonth || 0;
        const range = this.getRangeFor(year, month + 1);

        let params: any = { projectId, programmerType: 'FN' };
        if (range) {
            params = { ...params, ...range };
        } else {
            params = { ...params, year, month };
        }

        this.goalResultService.getAll({
            params,
        }).then((resp)=> {
            this.results = [ ...resp ];
            this.setCurrentMonthByActivityBudget(this.results);
            this.loading = false;
        });
    }

    onFinish(event: any) {
        this.confirmationService.confirm({
            message: '¿Estas seguro de enviar el formulario?. Una vez enviado ya no podra realizar cambios.',
            accept: () => {
                this.saveSkipValidation(event);
                this.layoutService.showMenuDesktop(true);
            },
        });
    }

    ngOnDestroy() {
        this.cleanParams();
    }

}
