<app-wizard
    [steps]='steps'
    [current]='currentStep'
    [bodyContentTpl]='projectForm'
    [nextDisabled]='this.formGroup?.invalid'
    (onClickNext)='save($event)'
    [layout]='true'
>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column' [formGroup]='formGroup'>
        <div class='grid'>
            <div class='field col-12'>
                <label>Objetivo general</label>
                <textarea
                    [rows]='10' pInputTextarea [autoResize]='false'
                    formControlName='generalGoal'
                    placeholder='Objetivo General del proyecto'
                    class='text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                >
                </textarea>
                <div *ngIf="form.generalGoal.errors">
                    <p *ngIf="form.generalGoal.errors?.required && form.generalGoal.touched" class="text-danger">
                        *Campo requerido
                    </p>

                    <p *ngIf="form.generalGoal.errors?.maxlength" class="text-danger">
                        *Máximo de caracteres permitido es 3000
                    </p>
                </div>
            </div>

            <div class="field col-12">
                    <button
                        [disabled]='isSubmitted'
                        class='p-button p-button-success'
                        pButton pTooltip="Agregar Objetivo" tooltipPosition="top"
                        (click)='onNew()'
                    >
                        <i class="pi pi-plus"></i>
                    </button>
                    <p-table [value]="goals" dataKey="id" editMode="row" [tableStyle]="{'min-width': '100%'}" #dt>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="bg-primary border-x-1" style="width:250px">Objetivo Específico</th>
                                <th class="bg-primary border-x-1" style="width:100px">Componente</th>
                                <th class="bg-primary border-x-1" style="width:80px">Indicador</th>
                                <th class="bg-primary border-x-1" style="width:80px">Unidad</th>
                                <th class="bg-primary border-x-1" style="width:80px">Situación Actual/Línea de Base</th>
                                <th class="bg-primary border-x-1" style="width:80px">Meta</th>
                                <th class="bg-primary border-x-1" style="width:60px"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-goal let-editing="editing" let-ri="rowIndex">
                            <tr [pEditableRow]="goal">
                                <td>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <textarea
                                                [autofocus]="true"
                                                [rows]='2'
                                                pInputTextarea
                                                [autoResize]='false'
                                                placeholder='Objectivo Específico'
                                                [(ngModel)]="goal.description"
                                                [ngModelOptions]="{standalone: true}"
                                                class='text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-20rem'
                                            >
                                            </textarea>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{goal.description}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown
                                                [options]="components"
                                                [(ngModel)]="goal.componentId"
                                                [ngModelOptions]="{standalone: true}"
                                                [style]="{'width':'100%'}"
                                                scrollHeight="300px"
                                                [filter]="true"
                                                filterBy="name"
                                                optionValue="id"
                                                [resetFilterOnHide]="true"
                                                >
                                                <ng-template let-item pTemplate="selectedItem">
                                                    {{item.name}}
                                                    <div class="text-xs text-gray-600">{{item.description}}</div>
                                                </ng-template>
                                                <ng-template let-item pTemplate="item">
                                                    <div class="item-drop1">
                                                        {{item.name}}
                                                        <div class="text-xs text-gray-600">{{item.description}}</div>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{goal.component?.name}}
                                            <div class="text-xs text-gray-600">{{goal.component?.description}}</div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <textarea
                                                [autofocus]="true"
                                                [rows]='1'
                                                pInputTextarea
                                                [autoResize]='false'
                                                placeholder='Indicador'
                                                [(ngModel)]="goal.indicator"
                                                [ngModelOptions]="{standalone: true}"
                                                class='text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-10rem'
                                            >
                                            </textarea>

                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{goal.indicator}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <textarea
                                                [autofocus]="true"
                                                [rows]='1'
                                                pInputTextarea
                                                [autoResize]='false'
                                                placeholder='Unidad'
                                                [(ngModel)]="goal.unit"
                                                [ngModelOptions]="{standalone: true}"
                                                class='text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-10rem'
                                            >
                                            </textarea>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{goal.unit}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input pInputText
                                                   required
                                                   min='0'
                                                   class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                                                   type="number" [(ngModel)]="goal.currentSituation" [ngModelOptions]="{standalone: true}">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{goal.currentSituation | number: '1.0-2'}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input pInputText
                                                   min='0'
                                                   required
                                                   class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-6rem'
                                                   type="number" [(ngModel)]="goal.goal" [ngModelOptions]="{standalone: true}">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{goal.goal | number: '1.0-2'}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    <div *ngIf="!isSubmitted" class="flex align-items-center justify-content-center gap-2">
                                        <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(goal)" class="p-button-rounded p-button-text"></button>
                                        <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(goal)" class="p-button-rounded p-button-text p-button-success mr-2" [disabled]="!getForm(goal)?.valid"></button>
                                        <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(goal, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
            </div>
        </div>
    </div>
</ng-template>

