import { TipoEntidad } from '../../../core/entidad/interfaces/tipo-entidad.interface';
import { ICity } from '../../../shared';

export interface IAnnouncement {
  id: number;
  title: string;
  description: string;
  enabledEntityTypes: TipoEntidad[] | any[];
  enabledCities: ICity[] | any[];
  file: any;
  fileId: number;
  published: boolean;
  publishingCode: string;
  dueDate: Date;
  yearsLimit?: number;
  createdAt: Date;
  showPublicLink?: boolean;
}
