import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from '../../shared';
import { IProjectC3t4 } from '../models/interfaces/project-c3t4.interface';

@Injectable({
  providedIn: 'root',
})
export class ProjectC3t4Service extends BaseService<IProjectC3t4> {
  constructor(private readonly httpService: BaseHttpService) {
    super(httpService, 'project-c3t4');
  }
}
