import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from 'src/app/shared/services';
import {IProjectGoalActivity, IProjectGoalActivityMonth} from '../models';
import {lastValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ProjectGoalActivityService extends BaseService<IProjectGoalActivity>{

    constructor(private readonly httpService: BaseHttpService) {
        super(httpService, 'project-goal-activities');
    }

    async updateActivityMonth(data: IProjectGoalActivityMonth, id: number): Promise<IProjectGoalActivityMonth> {
        return lastValueFrom(this._baseHttpService.put(`${this.namespace}/month/${id}`, data));
    }

    async storeActivityMonth(data: IProjectGoalActivityMonth): Promise<IProjectGoalActivityMonth> {
        return lastValueFrom(this._baseHttpService.post(`${this.namespace}/month`, data));
    }
}
