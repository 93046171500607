<div class='w-full bg-gray-200'>
    <div class='flex flex-column gap-2 justify-content-start'>
        <div class="w-full  px-4 py-1">
            <span class="font-bold">{{group.goal?.description}}</span>
            <div class="text-sm">{{group.goal?.component?.name}}</div>
            <div class="text-xs">{{group.goal?.component?.description}}</div>
        </div>
        <div class="flex gap-4 justify-content-start align-items-center bg-gray-100">
            <div class="px-6 py-1">
                <b>Resultado Esperado:</b>
                <div>{{group.expectedResult}}</div>
            </div>
        </div>
    </div>
</div>

<p-table
    [value]="group.activities"
    dataKey="id"
    editMode="row"
    [tableStyle]="{'width': '100%'}"
    styleClass="p-datatable-gridlines"
    #dt>
    <ng-template pTemplate="header">
        <tr>
            <th class="bg-secondary border-x-1 text-center p-0" style="width:60px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="width:300px"></th>
            <th class="bg-secondary border-x-1 text-center p-0"></th>
            <th class="bg-secondary border-x-1 text-center p-0" ></th>
            <th class="bg-secondary p-0" style="width:100px"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-obj let-editing="editing" let-index="rowIndex">
        <tr [pEditableRow]="obj">
            <td style='width:50px'>{{index + 1}}</td>
            <td>
                {{obj.activity}}
            </td>
            <td class="text-center">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText
                               required
                               [min]="0"
                               [max]="obj.currentMonth.availableExpected"
                               class='w-6rem p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary'
                               type="number" [(ngModel)]="obj.currentMonth.expected" [ngModelOptions]="{standalone: true}">

                        <p-overlayPanel #op>
                            <b>Total Programado:</b> {{obj.currentMonth.totalProgrammed + obj.currentMonth.expected}}<br>
                            <b>Programado presente Gestión:</b>  {{obj.currentMonth.yearExpected}}<br>
                            <b>Programación Disponible:</b> {{ obj.currentMonth.availableExpected > obj.currentMonth.expected ? obj.currentMonth.availableExpected - obj.currentMonth.expected : 0 }}
                        </p-overlayPanel>

                        <button pButton pRipple type="button" pInitEditableRow icon="pi pi-info-circle" (click)="op.toggle($event)" class="p-button-rounded p-button-text"></button>
                        <div *ngIf="getForm(obj)?.invalid" class="text-center">
                            <p *ngIf="!obj.currentMonth.expected || obj.currentMonth.expected < 0" class="text-danger text-sm">
                                {{'Valor inválido'}}
                            </p>
                            <p *ngIf="obj.currentMonth.expected && (obj.currentMonth.availableExpected === 0 && obj.currentMonth.expected > 0)" class="text-danger text-sm">
                                {{'Límite alcanzado'}}
                            </p>

                            <p *ngIf="obj.currentMonth.expected && obj.currentMonth.availableExpected > 0" class="text-danger text-sm">
                                {{'El valor máximo es '  + obj.currentMonth.availableExpected }}
                            </p>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.currentMonth.expected}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class="text-center">
                {{ calcPMG(obj) | number: '1.0-2'}} %
            </td>
            <td>
                <div *ngIf="canProgram" class="flex align-items-center justify-content-center gap-2">
                    <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(obj)" class="p-button-rounded p-button-text"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(obj)" class="p-button-rounded p-button-text p-button-success mr-2" [disabled]="getForm(obj)?.invalid"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(obj, index)" class="p-button-rounded p-button-text p-button-danger"></button>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
