<app-wizard
    [steps]='steps'
    [current]='currentStep'
    [bodyContentTpl]='projectForm'
    [nextDisabled]='this.formGroup?.invalid'
    (onClickNext)='save($event)'
    [layout]='true'
>
</app-wizard>


<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column' [formGroup]='formGroup'>
        <div class='grid'>
            <div class="field col-12 text-2xl text-primary uppercase">
                {{currentStep?.text}}
            </div>

            <div class='field col-12'>
                <p-table [tableStyle]="{'width': '100%'}">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="bg-primary" style="width: 250px">Partida Presupuestaria</th>
                            <th class="bg-primary" style="width:100px">Unidad</th>
                            <th class="bg-primary" style="width:100px">Precio</th>
                            <th class="bg-primary" style="width:100px">Cantidad</th>
                            <th class="bg-primary" style="width: 100px"><div>AÑO Nro. 1</div><div>FONABOSQUE</div></th>
                            <th class="bg-primary" style="width: 100px"><div>AÑO Nro. 1</div><div>ENTIDAD EFECTIVO (Bs.)</div></th>
                            <th class="bg-primary" style="width: 100px"><div>AÑO Nro. 1</div><div>ENTIDAD ESPECIE (Bs.)</div></th>
                            <th *ngIf="isValidYear(2)" class="bg-primary" style="width: 100px"><div>AÑO Nro. 2</div><div>FONABOSQUE (Bs.)</div></th>
                            <th *ngIf="isValidYear(2)" class="bg-primary" style="width: 100px"><div>AÑO Nro. 2</div><div>ENTIDAD EFECTIVO (Bs.)</div></th>
                            <th *ngIf="isValidYear(2)" class="bg-primary" style="width: 100px"><div>AÑO Nro. 2</div><div>ENTIDAD ESPECIE (Bs.)</div></th>
                            <th *ngIf="isValidYear(3)" class="bg-primary" style="width: 100px"><div>AÑO Nro. 3</div><div>FONABOSQUE (Bs.)</div></th>
                            <th *ngIf="isValidYear(3)" class="bg-primary" style="width: 100px"><div>AÑO Nro. 3</div><div>ENTIDAD EFECTIVO (Bs.)</div></th>
                            <th *ngIf="isValidYear(3)" class="bg-primary" style="width: 100px"><div>AÑO Nro. 3</div><div>ENTIDAD ESPECIE (Bs.)</div></th>
                            <th class="bg-primary" style="width:100px">TOTAL FONABOSQUE (Bs.)</th>
                            <th class="bg-primary" style="width:100px">TOTAL ENTIDAD EFECTIVO (Bs.)</th>
                            <th class="bg-primary" style="width:100px">TOTAL ENTIDAD ESPECIE (Bs.)</th>
                            <th class="bg-primary" style="width:100px">TOTAL GENERAL (Bs.)</th>
                            <th class="bg-primary" style="width:100px"></th>
                        </tr>
                    </ng-template>
                </p-table>
                <!--Component 1 Table-->
                <app-budget-component-component
                    title="Componente 1"
                    [projectBudgets]="projectBudgetsC1"
                    [budgetItems]="budgetItems"
                    [componentType]="'COMPONENT1'"
                    [isSubmitted]="isSubmitted"
                    [currentProject]="currentProject"
                ></app-budget-component-component>
                <!--Component 2 Table-->
                <app-budget-component-component
                    title="Componente 2"
                    [projectBudgets]="projectBudgetsC2"
                    [budgetItems]="budgetItems"
                    [componentType]="'COMPONENT2'"
                    [isSubmitted]="isSubmitted"
                    [currentProject]="currentProject"
                ></app-budget-component-component>
                <!--Component 3 Table-->
                <app-budget-component-component
                    title="Componente 3"
                    [projectBudgets]="projectBudgetsC3"
                    [budgetItems]="budgetItems"
                    [componentType]="'COMPONENT3'"
                    [isSubmitted]="isSubmitted"
                    [currentProject]="currentProject"
                ></app-budget-component-component>
                <!--Component 4 Table-->
                <app-budget-component-component
                    title="Componente 4"
                    [projectBudgets]="projectBudgetsC4"
                    [budgetItems]="budgetItems"
                    [componentType]="'COMPONENT4'"
                    [isSubmitted]="isSubmitted"
                    [currentProject]="currentProject"
                ></app-budget-component-component>
                <!--Component 5 Table-->
                <app-budget-component-component
                    title="Componente 5"
                    [projectBudgets]="projectBudgetsC5"
                    [budgetItems]="budgetItems"
                    [componentType]="'COMPONENT5'"
                    [isSubmitted]="isSubmitted"
                    [currentProject]="currentProject"
                ></app-budget-component-component>
                <!-- Administration Table-->
                <app-budget-component-component
                    title="Administración"
                    [projectBudgets]="projectBudgetsAdmin"
                    [budgetItems]="budgetItems"
                    [componentType]="'ADMINISTRATION'"
                    [isSubmitted]="isSubmitted"
                    [currentProject]="currentProject"
                ></app-budget-component-component>
                <!--Audit Table-->
                <app-budget-component-component
                    title="Auditoría"
                    [projectBudgets]="projectBudgetsAudit"
                    [budgetItems]="budgetItems"
                    [componentType]="'AUDIT'"
                    [isSubmitted]="isSubmitted"
                    [currentProject]="currentProject"
                ></app-budget-component-component>
            </div>
        </div>
    </div>
</ng-template>
