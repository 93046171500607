import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { IProjectRisk, ProjectModel} from "../../../models";
import {ActivatedRoute} from "@angular/router";
import {ProjectEnvironment, ProjectService, ProjectRiskService} from "../../../services";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToasterService} from "../../../../shared/services/toaster.service";
import {Table} from "primeng/table";
import {CryptoService, SettingsService} from "../../../../shared";
import {IRisk} from "../../../../shared/interfaces/risk.interface";

const OTHER_RISK = 'OTHERS';
@Component({
    selector: 'app-risks',
    templateUrl: './risks.component.html',
    styleUrls: ['./risks.component.scss']
})
export class RisksComponent extends ProjectModel implements OnInit, OnDestroy {

    private step: number = 9;
    risks: IProjectRisk[] = [];
    riskOptions: IRisk[] = [];
    levels = ProjectEnvironment.LEVEL;
    cloned: { [s: string]: IProjectRisk; } = {};
    showOtherRisk: boolean = false;
    @ViewChild('dt') table!: Table;

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly riskService: ProjectRiskService,
        private readonly settingsService: SettingsService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private element: ElementRef,
        private crypto: CryptoService,
    ) {
        super(activatedRoute, projectService, tService, crypto);
    }

    ngOnInit(): void {
        this.readParams();
        this.setActive(this.step);
        this.service.get(this.params['id']).then((project) => {
            const {id, name, risks} = project || {};
            this.currentProject = { ...project };
            if (risks?.length) this.risks = [...risks];
            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required],
            });
        }).then(() => {
            return this.settingsService.getAllRisks();
        }).then((resp) => {
            this.riskOptions = [ ... resp ];
        }).finally(() => {
            this.disableFormIfSubmitted();
            this.showContent = true;
        });
    }

    onRowEditInit(obj: IProjectRisk) {
        if (!obj?.id) return;
        this.cloned[obj.id] = {...obj};
    }

    onRowEditSave(obj: IProjectRisk) {
        if (!obj?.id) return;

        const objForm: FormGroup = this.fb.group({
            riskId: [obj.riskId, Validators.required],
            level: [obj.level, Validators.required],
            other: [obj.other],
            projectId: [obj.projectId, Validators.required],
        });

        if (objForm.invalid) {
            this.toasterService.error('No se pudo guardar los datos son inválidos!');
            return;
        }

        delete this.cloned[obj.id];

        if (obj.id.toString().includes('new_')) {
            const index = this.risks.indexOf(obj);
            this.riskService.store(objForm.value).then((resp) => {
                this.risks[index] = resp;
                this.toasterService.success('El Riesgo fue creada satisfactoriamente.');
            });

        } else {
            const id = parseInt(obj.id.toString());
            this.riskService.update(objForm.value, id).then((resp) => {
                this.toasterService.success('El Riesgo fue actualizada satisfactoriamente.');
            });
        }
    }

    onRowEditCancel(obj: IProjectRisk, index: number) {
        if (!obj?.id) return;

        if (this.cloned[obj.id]) {
            this.risks[index] = this.cloned[obj.id];
            delete this.cloned[obj.id];
        }

        if (obj?.id.toString().includes('new_')) {
            this.risks.pop();
        }
    }

    onNew() {
        const r: IProjectRisk = {
            riskId: 0,
            level: ProjectEnvironment.LEVEL.NONE,
            other: '',
            projectId: parseInt(this.params['id']),
            id: `new_${new Date().getTime()}`}

        this.risks.push(r)
        this.table.initRowEdit(this.table.value[this.risks.length - 1]);
    }

    onSelectRisk(event: any) {
        const selected: IRisk | undefined = this.riskOptions.find(ro => ro.id === event.value);
        this.showOtherRisk = !!selected && this.isOtherRisk(selected);

        setTimeout(() => {
            const input = this.element.nativeElement.querySelector('.other-risk');
            if (input) input.focus()
        }, 100);

    }

    isOtherRisk(risk: IRisk): boolean {
        return risk?.key === OTHER_RISK;
    }

    ngOnDestroy() {
        this.cleanParams();
    }
}
