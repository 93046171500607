<app-wizard
    [steps]='steps'
    [stepsSize]="5"
    [current]='currentStep'
    [bodyContentTpl]='projectForm'
    [nextDisabled]='isProgrammed'
    (onClickNext)='save($event)'
    (onFinish)='onFinish($event)'
    [layout]='true'
    [lastStep]='true'
>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column' [formGroup]='formGroup'>
        <div class='grid'>
            <div class="field col-12 text-2xl text-primary uppercase">
                {{currentStep?.text}}
            </div>

            <div class="field col-12 pb-0 mb-0">
                <p-selectButton
                    [options]="selectableProjectYears"
                    optionLabel="label"
                    optionValue="id"
                    [(ngModel)]="selectedProjectYear"
                    [ngModelOptions]="{standalone: true}"
                    (onChange)="generateMonthAndLoadActivities()"
                >
                </p-selectButton>
                <p-selectButton
                    [options]="optionsByYear"
                    [(ngModel)]="selectedOptionYear"
                    [ngModelOptions]="{standalone: true}"
                    (onChange)="generateMonthAndLoadActivities()"
                >
                </p-selectButton>
                <p-selectButton
                    [options]="optionsByMonth"
                    [(ngModel)]="selectedOptionMonth"
                    [ngModelOptions]="{standalone: true}"
                    (onChange)="loadActivities()"
                >
                </p-selectButton>
            </div>
            <div class="field col-12">
                <p-table [value]="results" dataKey="id" editMode="row" [loading]='loading' [tableStyle]="{'min-width': '100%'}" #dt>
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="bg-primary border-x-1 text-center" rowspan="2" style="min-width:60px">NRO.</th>
                            <th class="bg-primary border-x-1 text-center" rowspan="2" style="min-width:300px; max-width:300px;">PARTIDA PRESUPUESTARIA</th>
                            <th class="bg-primary border-x-1 text-center" colspan="4" style="min-width:115px; max-width:115px;">PROGRAMADO</th>
                            <th class="bg-primary" rowspan="2" style="width:100px"></th>
                        </tr>
                        <tr>
                            <th class="bg-primary border-x-1 text-center" style="min-width:115px; max-width:115px;">FONABOSQUE</th>
                            <th class="bg-primary border-x-1 text-center" style="min-width:115px; max-width:115px;">ENTIDAD EJECUTORA ESPECIE</th>
                            <th class="bg-primary border-x-1 text-center" style="min-width:115px; max-width:115px;">ENTIDAD EJECUTORA EFECTIVO</th>
                            <th class="bg-primary border-x-1 text-center" rowspan="2" style="min-width:115px; max-width:115px;">TOTAL ENTIDAD EJECUTORA</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-obj let-editing="editing" let-index="rowIndex">
                        <tr [pEditableRow]="obj">
                            <td colspan='100%' class='p-0 m-0'>
                                <app-fn-activities-month
                                    [group]='obj'
                                    [editMode]='false'
                                    [canProgram]="!programmerReadOnly()"
                                    [totalProjectDays]='totalProjectDays'
                                    [selectedYear]="selectedProjectYear"
                                ></app-fn-activities-month>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="100%">
                                <div class="w-full text-center font-bold">No se ha encontrado resultados</div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</ng-template>

