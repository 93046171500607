<app-wizard
    [steps]='steps'
    [current]='currentStep'
    [bodyContentTpl]='projectForm'
    [nextDisabled]='this.formGroup?.invalid'
    (onClickNext)='save($event)'
    [layout]='true'
>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column' [formGroup]='formGroup'>
        <div class='grid'>

            <div class='field col-12'>
                <h3>
                    DERECHO PROPIETARIO DE LUGAR A IMPLEMENTAR EL VIVERO FORESTAL
                </h3>
                <div class='text-primary'>* Campo obligatorio para el componente infraestructura</div>
            </div>
            <div class="field col-12">

                <p-table [value]="[property]" dataKey="id" editMode="row" [tableStyle]="{'min-width': '100%'}" #dt>
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width:40%">Nro de Matrícula de Folio Real</th>
                            <th style="width:25%">Folio Real</th>
                            <th style="width:25%">Plano Catastral</th>
                            <th style="width:10%"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item let-editing="editing" let-ri="rowIndex">
                        <tr [pEditableRow]="item">
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <textarea
                                            required
                                            [rows]='5'pInputTextarea [autoResize]='true'
                                            placeholder='Nro de Matrícula de Folio Real'
                                            class='text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                                            [(ngModel)]="item.registration"
                                            [ngModelOptions]="{standalone: true}"
                                        >
                                        </textarea>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{item.registration}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <app-input-file
                                            [small]="true"
                                            [multiple]="false"
                                            [maxFiles]="1"
                                            [accept]="'application/pdf, image/jpg, image/jpeg, image/png'"
                                            (onUploaded)="onUploadedFolio($event)"
                                        >
                                        </app-input-file>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <a [href]="attachmentService.getFileUrl(item.realFolioFile?.key)"
                                           [download]="item.realFolioFile?.filename" target="_blank">
                                            {{item.realFolioFile?.filename}}
                                        </a>

                                    </ng-template>
                                </p-cellEditor>
                            </td>

                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <app-input-file
                                            [small]="true"
                                            [multiple]="false"
                                            [maxFiles]="1"
                                            [accept]="'*'"
                                            (onUploaded)="onUploadedCadastral($event)"
                                        >
                                        </app-input-file>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <a [href]="attachmentService.getFileUrl(item.cadastralFile?.key)"
                                           [download]="item.cadastralFile?.filename" target="_blank">
                                            {{item.cadastralFile?.filename}}
                                        </a>

                                    </ng-template>
                                </p-cellEditor>
                            </td>

                            <td>
                                <div *ngIf="!isSubmitted" class="flex align-items-center justify-content-center gap-2">
                                    <button *ngIf="editing" pButton type='button' icon='pi pi-map-marker' pTooltip="Registrar Coordenadas" tooltipPosition="top" (click)="setCoordinates(item)" class='p-button-success'></button>
                                    <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(item)" class="p-button-rounded p-button-text"></button>
                                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(item)" class="p-button-rounded p-button-text p-button-success mr-2" [disabled]="!getItemForm(item).valid"></button>
                                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(item, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>

            <div class="field col-12 field-map-container">
                <div class="map-container">
                    <div class="map-frame">
                        <div id="property-map"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

