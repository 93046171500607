import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-wizard-step',
    templateUrl: './wizard-step.component.html',
    styleUrls: ['./wizard-step.component.scss']
})
export class WizardStepComponent implements OnInit {

    @Input() text: string | undefined;
    @Input() icon: string | undefined;
    @Input() path: string | undefined;
    @Input() active: boolean | undefined;
    @Input() last: boolean | undefined;

    constructor() {}

    ngOnInit(): void {}

}
