import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectModel } from '../../../models';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../services';
import { FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { CryptoService } from '../../../../shared';

@Component({
    selector: 'app-idea',
    templateUrl: './idea.component.html',
    styleUrls: ['./idea.component.scss'],
})
export class IdeaComponent extends ProjectModel implements OnInit, OnDestroy {
    private step: number = 3;

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private crypto: CryptoService
    ) {
        super(activatedRoute, projectService, tService, crypto);
    }

    ngOnInit(): void {
        this.readParams();
        this.setActive(this.step);
        this.service.get(this.params['id']).then((project) => {
            const {
                id,
                name,
                history,
                diagnosis,
                statement,
                items,
                solution1,
                solution2,
                chosenSolution,
                justificationSolution,
            } = project || {};
            this.currentProject = { ...project };
            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required],
                history: [history, [Validators.required]],
                diagnosis: [diagnosis, [Validators.required]],
                statement: [statement, [Validators.required]],
                items: [items, [Validators.required]],
                solution1: [solution1, [Validators.required]],
                solution2: [solution2, [Validators.required]],
                chosenSolution: [chosenSolution, Validators.required],
                justificationSolution: [justificationSolution, [Validators.required]],
            });
            this.disableFormIfSubmitted();
            this.showContent = true;
        });
    }

    ngOnDestroy() {
        this.cleanParams();
    }
}
