import {IAttachment} from "../../../shared";

export type ChangeOrderType = 'REQUESTED' | 'APPROVED' | 'REJECTED' | 'ERROR';
export interface IProjectChangeOrder {
    id: number;
    projectId: number;
    oldProjectId?: number;
    createdAt?: Date;
    approvedDate?: Date;
    rejectedDate?: Date;
    fileId?: number;
    file?: IAttachment;
    justification: string;
    rejectedJustification?: string;
    status?: ChangeOrderType;
    processing?: boolean;
}
