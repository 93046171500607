 <p-breadcrumb *ngIf="!external" [model]="breadcrumbs"></p-breadcrumb>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column' [formGroup]='formGroup'>
        <div class='grid'>
            <div class="field col-12">
                <button
                    class='p-button p-button-success'
                    [disabled]="programmerReadOnly()"
                    pButton pTooltip="Agregar Objetivo" tooltipPosition="top"
                    (click)='onNew()'
                >
                    <i class="pi pi-plus"></i>
                </button>
                <p-table
                    [value]="results"
                    dataKey="id"
                    editMode="row"
                    [tableStyle]="{'min-width': '100%'}"
                    styleClass="p-datatable-gridlines"
                    #dt>
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="bg-primary border-x-1 text-center" style="width:60px">NRO.</th>
                            <th class="bg-primary border-x-1 text-center">ACTIVIDAD</th>
                            <th class="bg-primary border-x-1 text-center" style="width:100px">TIPO DE PROGRAMACIÓN</th>
                            <th class="bg-primary" style="width:100px"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-obj let-editing="editing" let-index="rowIndex">
                        <tr [pEditableRow]="obj">
                            <td colspan='100%' class='p-0 m-0'>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <app-results
                                            [group]='obj'
                                            [goals]="goals"
                                            [editMode]='true'
                                            [canProgram]="!programmerReadOnly()"
                                            (onSave)='onRowEditSave($event)'
                                            (onEdit)='onRowEditInit($event)'
                                            (onCancel)='onRowEditCancel($event, index)'
                                        ></app-results>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <app-results
                                            [group]='obj'
                                            [canProgram]="!programmerReadOnly()"
                                            [editMode]='false'
                                            (onRemove)="onRemove($event)"
                                        ></app-results>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
