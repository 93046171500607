import {Component, OnDestroy, OnInit} from '@angular/core';
import { BudgetSummaryHelper, ProjectEdtpModel } from '../../../models';
import {ActivatedRoute} from "@angular/router";
import {ProjectService} from "../../../../itcp";
import { BudgetSummaryType, ProjectBudgetService } from '../../../services';
import {CryptoService, SettingsService} from "../../../../shared";
import {FormBuilder, Validators} from "@angular/forms";
import {ToasterService} from "../../../../shared/services/toaster.service";
import { IProjectBudget } from '../../../models/interfaces/project-budget.interface';
import {LayoutService} from "../../../../shared/services/app.layout.service";

@Component({
  selector: 'app-budget-comp',
  templateUrl: './budget-comp.component.html',
  styleUrls: ['./budget-comp.component.scss']
})
export class BudgetCompComponent extends ProjectEdtpModel implements OnInit, OnDestroy {

    private step: number = 23;
    projectBudgetsComponents: BudgetSummaryType[] = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly projectBudgetsService: ProjectBudgetService,
        private readonly setService: SettingsService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private layoutService: LayoutService,
        private crypto: CryptoService,
    ) {
        super(activatedRoute, projectService, tService, setService, crypto);
    }

    ngOnInit(): void {
        this.layoutService.showMenuDesktop(false);
        this.readParams();
        this.setActive(this.step);
        this.service.get(this.params['id']).then((project) => {
            const {id, name} = project || {};
            this.currentProject = {...project};
            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required]
            });
            const projectId = id || 0;
            return this.projectBudgetsService.getAll({ params: { projectId }});
        }).then((resp) => {
          this.loadSummary(resp);
        }).finally(() => {
            this.setSteps();
            this.setActive(this.step); //We need to reload the steps and put as active the current step
            this.disableFormIfSubmitted();
            this.showContent = true;
        });
    }

    loadSummary(budgets: IProjectBudget[]) {
        BudgetSummaryHelper.setBudgetAmounts(budgets);
        this.addC1Summary(budgets.filter(b => b.componentType === 'COMPONENT1'));
        this.addC2Summary(budgets.filter(b => b.componentType === 'COMPONENT2'));
        this.addC3Summary(budgets.filter(b => b.componentType === 'COMPONENT3'));
        this.addC4Summary(budgets.filter(b => b.componentType === 'COMPONENT4'));
        this.addC5Summary(budgets.filter(b => b.componentType === 'COMPONENT5'));
        this.addAdminSummary(budgets.filter(b => b.componentType === 'ADMINISTRATION'));
        this.addAuditSummary(budgets.filter(b => b.componentType === 'AUDIT'));
        this.addTotalsSummary();
    }

    private addC1Summary(budgets: IProjectBudget[]) {
        const item: BudgetSummaryType = {
            description: 'COMPONENTE NRO. 1: CONSTRUCCIÓN DE LA INFRAESTRUCTURA PRODUCTIVA',
            entity: 0,
            entityInKind: 0,
            owner: 0,
            total: 0,
            type: 'COMPONENT1'
        }

        budgets.map(b => {
            if (b.componentType === 'COMPONENT1') {
                item.owner += b.totals.owner;
                item.entity += b.totals.entity;
                item.entityInKind += b.totals.entityInKind;
                item.total += b.totals.total;
            }
        });

        this.projectBudgetsComponents.push(item);
    }

    private addC2Summary(budgets: IProjectBudget[]) {
        const item: BudgetSummaryType = {
            description: 'COMPONENTE NRO. 2: PRODUCCIÓN DE PLANTINES',
            entity: 0,
            entityInKind: 0,
            owner: 0,
            total: 0,
            type: 'COMPONENT2'
        }

        budgets.map(b => {
            if (b.componentType === 'COMPONENT2') {
                item.owner += b.totals.owner;
                item.entity += b.totals.entity;
                item.entityInKind += b.totals.entityInKind;
                item.total += b.totals.total;
            }
        });

        this.projectBudgetsComponents.push(item);
    }

    private addC3Summary(budgets: IProjectBudget[]) {
        const item: BudgetSummaryType = {
            description: 'COMPONENTE NRO. 3: REFORESTACIÓN E IMPLEMENTACIÓN DE SISTEMAS AGROFORESTALES',
            entity: 0,
            entityInKind: 0,
            owner: 0,
            total: 0,
            type: 'COMPONENT3'
        }

        budgets.map(b => {
            if (b.componentType === 'COMPONENT3') {
                item.owner += b.totals.owner;
                item.entity += b.totals.entity;
                item.entityInKind += b.totals.entityInKind;
                item.total += b.totals.total;
            }
        });

        this.projectBudgetsComponents.push(item);
    }
    private addC4Summary(budgets: IProjectBudget[]) {
        const item: BudgetSummaryType = {
            description: 'COMPONENTE NRO. 4: CAPACITACIÓN',
            entity: 0,
            entityInKind: 0,
            owner: 0,
            total: 0,
            type: 'COMPONENT4'
        }

        budgets.map(b => {
            if (b.componentType === 'COMPONENT4') {
                item.owner += b.totals.owner;
                item.entity += b.totals.entity;
                item.entityInKind += b.totals.entityInKind;
                item.total += b.totals.total;
            }
        });

        this.projectBudgetsComponents.push(item);
    }
    private addC5Summary(budgets: IProjectBudget[]) {
        const item: BudgetSummaryType = {
            description: 'COMPONENTE NRO. 5: ASISTENCIA TÉCNICA',
            entity: 0,
            entityInKind: 0,
            owner: 0,
            total: 0,
            type: 'COMPONENT5'
        }

        budgets.map(b => {
            if (b.componentType === 'COMPONENT5') {
                item.owner += b.totals.owner;
                item.entity += b.totals.entity;
                item.entityInKind += b.totals.entityInKind;
                item.total += b.totals.total;
            }
        });

        this.projectBudgetsComponents.push(item);
    }
    private addAdminSummary(budgets: IProjectBudget[]) {
        const item: BudgetSummaryType = {
            description: 'ADMINISTRACIÓN',
            entity: 0,
            entityInKind: 0,
            owner: 0,
            total: 0,
            type: 'ADMINISTRATION'
        }

        budgets.map(b => {
            if (b.componentType === 'ADMINISTRATION') {
                item.owner += b.totals.owner;
                item.entity += b.totals.entity;
                item.entityInKind += b.totals.entityInKind;
                item.total += b.totals.total;
            }
        });

        this.projectBudgetsComponents.push(item);
    }
    private addAuditSummary(budgets: IProjectBudget[]) {
        const item: BudgetSummaryType = {
            description: 'AUDITORÍA',
            entity: 0,
            entityInKind: 0,
            owner: 0,
            total: 0,
            type: 'AUDIT'
        }

        budgets.map(b => {
            if (b.componentType === 'AUDIT') {
                item.owner += b.totals.owner;
                item.entity += b.totals.entity;
                item.entityInKind += b.totals.entityInKind;
                item.total += b.totals.total;
            }
        });

        this.projectBudgetsComponents.push(item);
    }

    private addTotalsSummary() {
        const item: BudgetSummaryType = {
            description: 'PRESUPUESTO TOTAL EN Bs.',
            entity: 0,
            entityInKind: 0,
            owner: 0,
            total: 0,
        }

        this.projectBudgetsComponents.map(b => {
            item.owner += b.owner;
            item.entity += b.entity;
            item.entityInKind += b.entityInKind;
            item.total += b.total;
        });

        this.projectBudgetsComponents.push(item);
    }

    ngOnDestroy() {
        this.cleanParams();
    }
}
