import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { IEdtpCompliance, IProjectComplianceGroup } from '../../../../models';
import { Table } from 'primeng/table';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from '../../../../../shared/services/toaster.service';
import { IProjectAssessment } from '../../../../../itcp/components/project-assessment/interfaces/project-assessment.interface';
import { ProjectAssessmentsService } from '../../../../../itcp/components/project-assessment/services/project-assessments.service';
import { QuantitativeTotalsType, SCORES } from '../../../../services';
import { ProjectStagesEnum } from '../../../../../shared';

@Component({
    selector: 'app-score',
    templateUrl: './score.component.html',
    styleUrls: ['./score.component.scss'],
})
export class ScoreComponent implements OnInit, OnChanges {
    @Input() group!: IProjectComplianceGroup;
    @Input() defaultCompliances!: IEdtpCompliance[];
    @Input() editMode: boolean = false;
    @Input() canEdit: boolean = false;
    @Input() totalGroups!: QuantitativeTotalsType;
    @Output() onCancel = new EventEmitter<any>();
    @Output() onSave = new EventEmitter<any>();
    @Output() onEdit = new EventEmitter<any>();
    @Output() onRemove = new EventEmitter<any>();
    @Output() onChangeAccumTotals = new EventEmitter<any>();
    @Output() onChangeDistTotals = new EventEmitter<any>();

    assessments: IProjectAssessment[] = [];
    clonedAssessments: { [s: string]: IProjectAssessment } = {};
    selectedGroup!: IEdtpCompliance;
    scores = SCORES;
    private updateDistTotals: boolean = false;

    @ViewChild('dt') table!: Table;
    constructor(
        private readonly assessmentService: ProjectAssessmentsService,
        private fb: FormBuilder,
        private toasterService: ToasterService,
        private element: ElementRef
    ) {}

    ngOnInit(): void {
        if (!this.group.assessments) this.group.assessments = [];

        if (this.group.assessments?.length) {
            this.assessments = [...this.group.assessments];
        }
        this.setAccumTotals();
    }

    saveGroup() {
        if (this.selectedGroup) {
            this.group.title = this.selectedGroup.title;
            this.group.code = parseInt(this.selectedGroup.code);
        }

        this.onSave.emit(this.group);
        this.editMode = false;
    }

    editGroup() {
        this.onEdit.emit(this.group);
    }

    removeGroup() {
        this.onRemove.emit(this.group);
    }

    cancelEditGroup() {
        this.onCancel.emit(this.group);
    }

    onRowEditInit(obj: IProjectAssessment) {
        if (!obj?.id) return;
        this.clonedAssessments[obj.id] = { ...obj };
    }

    onRowEditSave(obj: IProjectAssessment) {
        if (!obj?.id) return;

        const form = this.itemForm(obj);

        if (form.valid) {
            delete this.clonedAssessments[obj.id];

            if (obj?.id.toString().includes('new_')) {
                const index = this.assessments.indexOf(obj);
                this.assessmentService.store(form.value).then((resp) => {
                    this.assessments[index] = resp;
                    this.setAccumTotals();
                    this.toasterService.success('El Registro fue creado satisfactoriamente.');
                });
            } else {
                const id = parseInt(obj.id.toString());
                this.assessmentService.update(form.value, id).then((resp) => {
                    this.setAccumTotals();
                    this.toasterService.success('El Registro fue actualizado satisfactoriamente.');
                });
            }
        } else {
            this.toasterService.error('No se pudo guardar, los datos son inválidos!');
        }
    }

    onRowEditCancel(obj: IProjectAssessment, index: number) {
        if (!obj?.id) return;

        if (this.clonedAssessments[obj.id]) {
            this.assessments[index] = this.clonedAssessments[obj.id];
            delete this.clonedAssessments[obj.id];
        }

        if (obj?.id.toString().includes('new_')) {
            this.assessments = this.assessments.filter((o) => o.id !== obj.id);
        }
    }

    onNew() {
        const ob: IProjectAssessment = {
            id: `new_${new Date().getTime()}`,
            projectId: this.group.projectId,
            groupId: this.group.id ? parseInt(this.group.id.toString()) : 0,
            key: '',
            justification: '',
            score: SCORES.worst,
            weight: 0,
            maxScore: SCORES.excellent,
            stage: ProjectStagesEnum.EDTP,
        };

        this.assessments.push(ob);
        this.table.initRowEdit(this.table.value[this.assessments.length - 1]);
    }

    onSelectItem(event: any) {
        const item = this.defaultCompliances.find((c) => c.id === event.value);
        if (item) this.selectedGroup = { ...item };
    }

    onSelectSubItem(event: any, obj: IProjectAssessment) {
        if (event.value !== 'Otros') {
            obj.key = event.value;
            obj.other = false;
        } else {
            obj.other = true;
            obj.key = '';
            setTimeout(() => {
                const input = this.element.nativeElement.querySelector('.other');
                if (input) input.focus();
            }, 100);
        }
    }

    itemForm(obj: IProjectAssessment) {
        const form: FormGroup = this.fb.group({
            projectId: [obj.projectId, Validators.required],
            groupId: [obj.groupId, Validators.required],
            key: [obj.key, Validators.required],
            justification: [obj.justification, Validators.required],
            stage: [obj.stage, Validators.required],
            score: [obj.score, Validators.required],
            weight: [obj.weight, [Validators.required, Validators.min(0)]],
            maxScore: [obj.maxScore, Validators.required],
        });

        return form;
    }

    setAccumTotals() {
        this.group.weight = 0;
        this.group.maxScore = 0;
        this.group.scoreAccum = 0;
        this.group.maxScoreAccum = 0;

        this.assessments.map((a) => {
            // @ts-ignore
            this.group.weight += a.weight;
            // @ts-ignore
            this.group.maxScore += a.maxScore;
            // @ts-ignore
            this.group.scoreAccum += a.score;
            // @ts-ignore
            this.group.maxScoreAccum += a.maxScore;

            a.scoreAccum = a.score;
            a.scoreDist = 0;
            a.maxScoreAccum = a.maxScore;
            a.maxScoreDist = 0;
        });
        setTimeout(() => this.onChangeAccumTotals.emit(this.group), 250);
        this.updateDistTotals = true;
    }

    setDistTotals() {
        this.group.scoreDist = 0;
        this.group.maxScoreDist = 0;

        this.assessments.map((a) => {
            // @ts-ignore
            a.maxScoreDist = this.totalGroups.maxScoreAccum * (a.weight / 100);
            // @ts-ignore
            a.scoreDist = (a.scoreAccum * a.maxScoreDist) / a.maxScoreAccum;
            //@ts-ignore
            this.group.maxScoreDist += a.maxScoreDist;
            // @ts-ignore
            this.group.scoreDist += a.scoreDist;
        });

        this.updateDistTotals = false;
        setTimeout(() => this.onChangeDistTotals.emit(this.group), 500);
    }

    getDefaultGroupChildren(code: number) {
        let children: IEdtpCompliance[] = [];
        const item = this.defaultCompliances.find((c) => c.code === code?.toString());
        if (item && item.children) children = item.children;

        children = [
            ...children,
            {
                code: 'OTHER',
                title: 'Otros',
                description: 'Otros',
                parentCode: '',
            },
        ];

        return children;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['totalGroups'] && this.updateDistTotals) {
            this.setDistTotals();
        }
    }
}
