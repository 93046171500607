<app-wizard
    [steps]="steps"
    [current]="currentStep"
    [bodyContentTpl]="projectForm"
    [nextDisabled]="this.formGroup?.invalid"
    (onClickNext)="save($event)"
    [layout]='true'
>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf="showContent" class="project-form flex justify-content-center flex-column" [formGroup]="formGroup">
        <h4>EL PROYECTO DEBERÁ ESTAR ENMARCADO BAJO LOS SIGUIENTES CRITERIOS:</h4>
        <div class='grid'>
            <div class='col-12 md:col-6 m-0'>
                <ul>
                    <li>El proyecto debe estar acorde a los principios y derechos establecidos en Constitución Política del Estado</li>
                    <li>¿El proyecto debe estar acorde a Los lineamientos de la Agenda Patriótica 2025 y la Ley N° 300 Marco de la Madre Tierra y Desarrollo Integral para Vivir Bien?</li>
                    <li>¿El proyecto debe estar acorde a la normativa del Plan Sectorial de Desarrollo Integral del Ministerio de Medio Ambiente y Agua?</li>
                    <li>¿El proyecto debe estar acorde a la normativa de la Ley de Autonomías y Descentralización "ANDRES IBAÑEZ"?</li>
                    <li>¿El proyecto debe estar acorde a la normativa de la Ley Forestal?</li>
                    <li>¿El proyecto debe estar dentro de la normativa de los Planes Territoriales de Desarrollo Integral (PTDI) o está dentro de la normativa de los Planes de Gestión Territorial Comunitaria (PGTC)?</li>
                </ul>
            </div>
            <div class='col-12 md:col-6 flex justify-content-center flex-column align-items-center text-center m-0'>
                <h2>¿EL PROYECTO CUMPLE CON LOS CRITERIOS EXPUESTOS?</h2>

                <p-selectButton
                    [options]="yesNoOptions"
                    formControlName="justified"
                    (onChange)='onChangeYesNoOption($event)'
                >
                    <ng-template let-item>
                        <span class="yes-no-options">{{item.label}}</span>
                    </ng-template>
                </p-selectButton>

                <div *ngIf="showNoJustification" class='field w-full mt-4'>
                    <textarea
                        [rows]='5'
                        pInputTextarea [autoResize]='false'
                        formControlName='noJustifiedDescription'
                        placeholder='Justificación'
                        [pAutoFocus]="true"
                        class='text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                    >
                </textarea>
                    <div *ngIf="form.noJustifiedDescription.errors">
                        <p *ngIf="form.noJustifiedDescription.errors?.required && form.noJustifiedDescription.touched" class="text-danger">
                            *Campo requerido
                        </p>

                        <p *ngIf="form.noJustifiedDescription.errors?.maxlength" class="text-danger">
                            *Máximo de caracteres permitido es 250
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

