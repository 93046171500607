import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {IVisit} from "../../models";
import dayjs from "dayjs";
import {VisitService} from "../../services";
import {ToasterService} from "../../../shared/services/toaster.service";
import {IProject, IProjectAct, ProjectActService, ProjectService} from "../../../itcp";
import {UserService} from "../../../core/user/user.service";
import {User} from "../../../core/user/interfaces";
import {ProcessTypes, ProjectStagesEnum} from "../../../shared";
import {Constants} from "../../../shared/constants";
import {GenericFunctions} from "../../../shared/services/generic-functions";


@Component({
    selector: 'app-visit-form-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
export class VisitFormModalComponent implements OnInit {
    @Input() public id!: number;
    @Input() public data!: IVisit;

    public modalForm!: FormGroup;
    public showForm = false;
    public minDate: Date = dayjs().toDate();
    projects: IProject[] = [];
    users: User[] = [];
    acts: IProjectAct[] = [];
    enableCommunity: boolean = false;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public service: VisitService,
        public projectService: ProjectService,
        public actService: ProjectActService,
        public userService: UserService,
        private fb: FormBuilder,
        private toasterService: ToasterService
    ) {
    }

    ngOnInit(): void {
        let {id, projectId, actId, visitDate, userId, visitType, visitStatus, description} = this.config.data || {};

        let date: any;
        if (visitDate) date = dayjs(visitDate).toDate();

        this.projectService.getAll({params: {stage: ProjectStagesEnum.TRACKING}})
            .then((resp) => {
                this.projects = [...resp];
                if (resp?.length) this.filterActs({ value: resp[0].id });
                if (projectId) this.filterActs({ value: projectId });
                return this.userService.getUsers(ProcessTypes.APROVE, [Constants.ROLES.TEC_FIS_PLAN, Constants.ROLES.TEC_FIS_SEG, Constants.ROLES.COORDINADOR, Constants.ROLES.RESP_PLANIFICACION])
            }).then((resp) => {
            this.users = [...resp];
            this.showForm = true;
        }).finally(() => {
            if (!projectId) projectId = this.projects[0].id;
            if (!actId) actId = this.acts[0].id;
            if (!userId) userId = this.users[0].id;
            this.modalForm = this.fb.group({
                projectId: [projectId, Validators.required],
                actId: [actId, Validators.required],
                visitDate: [date, Validators.required],
                userId: [userId, Validators.required],
                visitType: [visitType, Validators.required],
                visitStatus: [visitStatus, Validators.required],
                description: [description, Validators.required],
            });
        });
    }

    get form(): any {
        return this.modalForm.controls;
    }

    save(): void {
        const {id} = this.config.data || {};
        // Invalid
        if (!this.modalForm.valid) {
            this.toasterService.error('Corrija los datos e inténtelo nuevamente');
            return;
        }
        const postData: IVisit = this.modalForm.value;
        postData.visitDate = dayjs(
            dayjs(postData.visitDate).toDate().toLocaleDateString('en-US', {timeZone: 'America/La_Paz'})
        ).toDate();
        // Update
        if (id) {
            let tmpResponse!: IVisit;
            this.service
                .update(postData, this.config.data.id)
                .then((resp) => {
                    this.toasterService.success('Los datos fueron actualizados con éxito');
                    tmpResponse = resp;
                    this.closeModal(tmpResponse);
                })
                .catch((err) => {
                    console.error(err.error.message);
                    this.toasterService.error('Se ha producido un error inténtelo más tarde o contáctese con el Administrador.');
                });
            return;
        }

        //Store
        let tmpResponse!: IVisit;
        this.service
            .store(postData)
            .then((resp) => {
                this.toasterService.success('El registro fué almacenado con éxito');
                tmpResponse = resp;
                this.closeModal(tmpResponse);
            })
            .catch((err) => {
                console.error(err.error.message);
                this.toasterService.error('Se ha producido un error inténtelo más tarde o contáctese con el Administrador.');
            });
    }

    filterActs(event: any) {
        if (!event.value) return;
        this.enableCommunity = false;
        this.actService.getAll({params: {projectId: event.value}}).then((resp) => {
            this.acts = [...resp];
            if (resp) this.enableCommunity = true;
        });
    }

    getFullName(user: User): string {
        return GenericFunctions.getUserFullName(user);
    }

    closeModal(data: any = undefined): void {
        this.ref.close(data);
    }
}
