import {IAttachment} from "../../../shared";
import {User} from "../../../core/user/interfaces";
import {IBudgetItem} from "../../../shared/interfaces/budget-item.interface";

export interface IProjectActivityBudget {
    id?: number | string;

    activityId: number;
    budgetItemCode: string;
    budgetItemTitle: string;
    budgetItemDetails: string;
    budgetItem?: IBudgetItem;
    owner: number;
    entity: number;
    entityInKind: number;
    ownerAmount?: number;
    entityAmount?: number;
    entityInKindAmount?: number;
    year?: number;
    ownerAmountYear?: number;
    entityAmountYear?: number;
    entityInKindAmountYear?: number;
    currentMonth?: IProjectActivityBudgetMonth;
    yearsProgrammed?: IProjectActivityBudgetYear[];
    monthProgrammed?: IProjectActivityBudgetMonth[];
}

export interface IProjectActivityBudgetYear {
    activityBudgetId: number;
    year: number;
    owner: number;
    entity: number;
    entityInKind: number;
    ownerAmount?: number;
    entityAmount?: number;
    entityInKindAmount?: number;
}

export interface IProjectActivityBudgetMonth {
    id?: number | string;
    activityBudgetIds?: number[]; //To update weeks
    activityBudgetId: number;
    activityBudget?: IProjectActivityBudget;
    month: number;
    year: number;
    startsDate?: Date;
    endsDate?: Date;
    owner: number;
    entity: number;
    entityInKind: number;
    ownerAmount: number;
    entityAmount: number;
    entityInKindAmount: number;
    ownerExecuted: number;
    entityExecuted: number;
    entityInKindExecuted: number;
    ownerExecutedAmount?: number;
    entityExecutedAmount?: number;
    entityInKindExecutedAmount?: number;
    yearOwner?: number;
    yearEntity?: number;
    yearEntityInKind?: number;
    totalProgrammedOwner?: number;
    totalProgrammedEntity?: number;
    totalProgrammedEntityInKind?: number;
    availableOwner?: number;
    availableEntity?: number;
    availableEntityInKind?: number;
    totalExecutedOwner?: number;
    totalExecutedEntity?: number;
    totalExecutedEntityInKind?: number;
    verifiableIds?: number[];
    files?: IAttachment[];
    verifiables?: any[];// returned by query to populate in files
    currentObservation?: string;
    observations?: { activityMonthId: number, observation: string, createdBy: User }[];
    week1?: boolean;
    week2?: boolean;
    week3?: boolean;
    week4?: boolean;
}
