import { Component, OnDestroy, OnInit } from '@angular/core';
import { ITCPRequest } from '../../../models';
import { TipoEntidad, ITCPRequestService } from '../../../../itcp';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { EntidadService } from '../../../../core/entidad/entidad.service';
import { Entidad } from '../../../../core/entidad/interfaces/entidad.interface';
import { AttachmentService, CartographyService, ICity, IProvince, ITown } from '../../../../shared';

@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info-component.html',
  styleUrls: ['./basic-info.component.scss']
})
export class BasicInfoComponent extends ITCPRequest implements OnInit, OnDestroy {
  private step: number = 1;
  _cities: ICity[] = [];
  provinces: IProvince[] = [];
  _towns: ITown[] = [];
  territorialEntities: TipoEntidad[] = [];
  entities: Entidad[] = [];
  alreadyRequested: boolean = false;
  selectedTownId!: number;

  constructor(
    public cartographyService: CartographyService,
    public entityService: EntidadService,
    private activatedRoute: ActivatedRoute,
    private readonly iTCPRequestService: ITCPRequestService,
    private fb: FormBuilder,
    private tService: ToasterService,
    public readonly attachmentService: AttachmentService,
  ) {
    super(activatedRoute, iTCPRequestService, tService);
  }

  get cities() {
    return this._cities;
  }

  set cities(data: ICity[]) {
    const {
      announcement: { restrictedCities = [] }
    } = this.currentRequest as any;
    const restrictedCityIds = restrictedCities.map((c: any) => c.cityId);
    this._cities = data.filter((d) => {
      return !restrictedCityIds.includes(d.gid);
    });
  }

  get towns() {
    return this._towns;
  }

  set towns(data: ITown[]) {
    const {
      announcement: { restrictedTowns = [] }
    } = this.currentRequest as any;
    const restrictedTownIds = restrictedTowns.map((t: any) => t.townId);
    this._towns = data.filter((d) => {
      return !restrictedTownIds.includes(d.gid);
    });
  }

  ngOnInit(): void {
    this.readParams();
    const getAll = [this.setCurrentRequest(), this.entityService.getEntidades(), this.cartographyService.getCities()];

    Promise.all(getAll)
      .then(([request, entities, cities]) => {
        this.showContent = false;
        const enabledEntities = this.currentRequest?.announcement?.enabledEntityTypes?.map((en) => en.entityType);
        this.territorialEntities = enabledEntities as TipoEntidad[];

        this.cities = cities as ICity[];
        this.entities = entities as Entidad[];
        const {
          id,
          announcementCode,
          announcementId,
          token,
          entityTypeId,
          entityId,
          announcement
        } = this.currentRequest || {};
        this.formGroup = this.fb.group({
          id: [id],
          announcementCode: [announcementCode],
          announcementId: [announcementId],
          token: [token],
          entityTypeId: [entityTypeId, Validators.required],
          entityId: [entityId, Validators.required]
        });
        // this.setRoutes(['/public/itcp-request', this.params['token'], 'mae-info']);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
          this.disableFormIfReadonly();
        this.showContent = true;
      });

    this.setActive(this.step);
  }

  getAnnouncementFileUrl(): string | undefined {
    const fileUrl = this.attachmentService.getFileUrl(String(this.currentRequest?.announcement?.fileId));
    return `${fileUrl}/?safe=true`;
  }

  getAnnouncementFileName(): string {
    return `Convocatoria ${this.currentRequest?.announcement?.title}`;
  }

  onSelectEntity(entity: any) {
    if (!this.currentRequest?.id) return;

    this.iTCPRequestService.getByIdEntityId(this.currentRequest.announcementId, entity.value).then((resp) => {
      this.alreadyRequested = false;
      if (resp) this.alreadyRequested = true;
    });
  }

  onChangeCity(city: any) {
      this.provinces = [];
    this.cartographyService.getProvinces({ params: { depId: city.value } }).then((provinces) => {
      this.provinces = [...provinces];
    });
  }

  onChangeProvince(province: any) {
    this.cartographyService.getTowns({ params: { provId: province.value } }).then((towns) => {
      this.towns = [...towns];
    });
  }

  onChangeTown(event: any) {
   this.selectedTownId = event.value;
  }
  onChangeTerritorialEntity(event: any) {
     const townId = this.selectedTownId || 0;
     this.entityService.getEntidades({params: {townId, territorialId: event.value}}).then((entities) => {
         this.entities = [...entities];
     });
  }

  ngOnDestroy() {
    this.cleanParams();
  }
}
