import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ProjectProgrammerModel} from "../../../models";
import {Table} from "primeng/table";
import {ActivatedRoute} from "@angular/router";
import {IProjectGoal, ProjectGoalService, ProjectService} from "../../../../itcp";
import {CryptoService, SettingsService} from "../../../../shared";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToasterService} from "../../../../shared/services/toaster.service";
import {Constants} from "../../../../shared/constants";
import {IProjectGoalResult, ProjectGoalResultService} from "../../../../edtp";
import {LayoutService} from "../../../../shared/services/app.layout.service";
import {ConfirmationService} from "primeng/api";

@Component({
  selector: 'app-financial',
  templateUrl: './financial.component.html',
  styleUrls: ['./financial.component.scss']
})
export class FinancialComponent extends ProjectProgrammerModel implements OnInit, OnDestroy {
    private step: number = 5;

    results: IProjectGoalResult[] = [];
    clonedResults: { [s: string]: IProjectGoalResult; } = {};
    goals: IProjectGoal[] = [];
    loading: boolean = false;

    @ViewChild('dt') table!: Table;

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly goalService: ProjectGoalService,
        private readonly setService: SettingsService,
        private readonly goalResultService: ProjectGoalResultService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private readonly layoutService: LayoutService,
        private readonly confirmationService: ConfirmationService,
        private crypto: CryptoService,
    ) {
        super(activatedRoute, projectService, tService, setService, crypto);
    }

    ngOnInit(): void {
        this.layoutService.showMenuDesktop(false);
        this.readParams();
        this.setActive(this.step);
        this.load();
    }

    load() {
        this.loading = true;
        this.service.get(this.params['id']).then((project) => {
            const {id, name, goals, status } = project || {};
            this.currentProject = {...project};
            if (goals?.length) this.goals = [ ...goals ];

            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required],
            });

            const projectId = id || 0;
            return this.goalResultService.getAll({ params: { projectId, programmerType: 'FN' }});
        }).then((resp) => {
            this.calculateDaysActivities(resp);
            this.results = [ ...resp ];
        }).finally(() => {
            this.disableFormIfSubmitted();
            this.calculateYears();
            this.checkEditableForm();
            this.showContent = true;
            this.loading = false;
        });
    }

    ngOnDestroy() {
        this.cleanParams();
    }

}
