<div class='w-full bg-gray-200'>
    <div class='flex flex-column gap-2 justify-content-start'>
        <div class="w-full  px-4 py-1">
            <span class="font-bold">{{group.goal?.description}}</span>
            <div class="text-sm">{{group.goal?.component?.name}}</div>
            <div class="text-xs">{{group.goal?.component?.description}}</div>
        </div>
        <div class="flex gap-4 justify-content-start align-items-center bg-gray-100">
            <div class="px-6 py-1">
                <b>Resultado Esperado:</b>
                <div>{{group.expectedResult}}</div>
            </div>
        </div>
    </div>
</div>

<p-table
    [value]="group.activities"
    dataKey="id"
    editMode="row"
    [tableStyle]="{'width': '100%'}"
    #dt>
    <ng-template pTemplate="header">
        <tr>
            <th class="bg-secondary border-x-1 text-center p-0" style="width:60px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="width:300px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="width:100px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="width:100px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="width:100px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="width:100px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="width:150px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="width:150px"></th>
            <th class="bg-secondary p-0" style="width:100px"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-obj let-editing="editing" let-index="rowIndex">
        <tr [pEditableRow]="obj">
            <td style='width:60px'>{{index + 1}}</td>
            <td>
                {{obj.activity}}
            </td>
            <td class="text-center">
                {{obj.currentMonth.expected}}
            </td>
            <td class="text-center font-semibold">
                {{ calcPMG(obj) | number: '1.0-2'}} %
            </td>
            <td class="text-center">
                {{obj.currentMonth.executed}}
            </td>
            <td class="text-center font-semibold">
                {{ calcEMG(obj) | number: '1.0-2'}} %
            </td>
            <td>
                <div *ngIf="obj.currentMonth.files?.length">
                    <p-overlayPanel #uploadedFiles>
                        <div *ngFor="let item of obj.currentMonth.files;" class="mb-4">
                            <a [href]="attachmentService.getFileUrl(item?.key)" class="hover:underline"
                               [download]="item.filename" target="_blank">
                                <i class="pi pi-download mr-2"></i>
                                {{item.filename}}
                            </a>
                        </div>
                    </p-overlayPanel>

                    <button
                        pButton pTooltip="Ver Archivos Verificables"
                        tooltipPosition="top"
                        label="Verificables"
                        icon='pi pi-eye'
                        type="button"
                        class="p-button-rounded p-button-text"
                        (click)="uploadedFiles.toggle($event)"
                    ></button>
                </div>
            </td>
            <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-overlayPanel #addComment>
                            <ng-template pTemplate="content">
                                <div class="p-2">
                                    <textarea
                                        rows="5"
                                        cols="50"
                                        pInputTextarea
                                        placeholder='Escriba la observación aquí.....'
                                        [pAutoFocus]="true"
                                        [(ngModel)]="obj.currentMonth.currentObservation"
                                        [ngModelOptions]="{standalone: true}"
                                    ></textarea>
                                </div>
                                <div class="text-right">
                                    <button
                                        label="Aceptar"
                                        type="button"
                                        class="p-button p-button-success"
                                        (click)="addComment.toggle($event)"
                                    >
                                        Aceptar
                                    </button>
                                </div>
                            </ng-template>
                        </p-overlayPanel>
                        <button
                            pButton pTooltip="Agregar Observacion"
                            tooltipPosition="top"
                            label="Observación"
                            icon='pi pi-upload'
                            type="button"
                            class="p-button-rounded p-button-text"
                            (click)="addComment.toggle($event)"
                        ></button>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <div *ngIf="obj.currentMonth.observations?.length">
                            <p-overlayPanel #comments>
                                <div *ngFor="let item of obj.currentMonth.observations;" class="w-30rem overflow-y-auto">
                                    <div class="hover:bg-gray-100 px-4 py-2">
                                        {{item.observation}}
                                        <div class="text-gray-600 text-sm py-2">
                                            {{getFullName(item.createdBy)}}
                                        </div>
                                    </div>
                                </div>
                            </p-overlayPanel>

                            <button
                                pButton pTooltip="Ver todas las observaciones"
                                tooltipPosition="top"
                                label="Observaciones"
                                icon='pi pi-check-circle'
                                type="button"
                                class="p-button-rounded p-button-text"
                                (click)="comments.toggle($event)"
                            ></button>
                        </div>
                    </ng-template>
                </p-cellEditor>
            </td>
            <td>
                <div class="flex align-items-center justify-content-center gap-2">
                    <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(obj)" class="p-button-rounded p-button-text"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(obj)" class="p-button-rounded p-button-text p-button-success mr-2" [disabled]="getForm(obj)?.invalid"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(obj, index)" class="p-button-rounded p-button-text p-button-danger"></button>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
