<app-wizard
  [steps]='steps'
  [current]='currentStep'
  [bodyContentTpl]='basicInfoForm'
  [nextDisabled]='this.formGroup?.invalid || alreadyRequested'
  (onClickNext)='save($event)'
>
</app-wizard>

<ng-template #basicInfoForm>
  <div *ngIf='showContent' class='basic-form flex justify-content-center flex-column mb-8' [formGroup]='formGroup'>
    <div class='enabled-entities field'>
      <h4>Entidades Territoriales Habilitadas</h4>
      <ul *ngFor='let item of territorialEntities'>
        <li class="text-left">{{ item.tipo }}</li>
      </ul>
    </div>
    <div *ngIf="!(currentRequest?.readOnly || currentRequest?.entityId)" class='field'>
      <label>Departamento</label>
      <p-dropdown
        [options]='cities'
        optionLabel='dep'
        placeholder='Seleccione'
        optionValue='gid'
        [style]="{ width: '100%' }"
        (onChange)='onChangeCity($event)'
      >
      </p-dropdown>
    </div>
    <div *ngIf="!(currentRequest?.readOnly || currentRequest?.entityId)" class='field'>
      <label>Provincias</label>
      <p-dropdown
        [options]='provinces'
        optionLabel='prov'
        placeholder='Seleccione'
        optionValue='gid'
        [disabled]="provinces.length === 0"
        [style]="{ width: '100%' }"
        (onChange)='onChangeProvince($event)'
      >
      </p-dropdown>
    </div>
    <div *ngIf="!(currentRequest?.readOnly || currentRequest?.entityId)" class='field'>
      <label>Municipios</label>
      <p-dropdown
        [options]='towns'
        optionLabel='mun'
        placeholder='Seleccione'
        optionValue='gid'
        [disabled]="towns.length === 0"
        [style]="{ width: '100%' }"
        (onChange)='onChangeTown($event)'
      >
      </p-dropdown>
    </div>

    <div class='field'>
      <label>Entidad Territorial Autónoma</label>
      <p-dropdown
        [options]='territorialEntities'
        formControlName='entityTypeId'
        optionLabel='tipo'
        placeholder='Seleccione'
        optionValue='id'
        [style]="{ width: '100%' }"
        (onChange)="onChangeTerritorialEntity($event)"
      >
      </p-dropdown>
    </div>

    <div class='field'>
      <label>Entidad Solicitante</label>
      <p-dropdown
        [options]='entities'
        formControlName='entityId'
        optionLabel='nombre'
        placeholder='Seleccione'
        optionValue='id'
        emptyMessage="No se encontraron resultados"
        [style]="{ width: '100%' }"
        [disabled]="entities.length === 0"
        (onChange)='onSelectEntity($event)'
      >
      </p-dropdown>
    </div>

    <div *ngIf='alreadyRequested' class='text-danger'>
      La entidad que acaba de seleccionar ya tiene una solicitud hecha.
    </div>
    <div class='my-4'>
        <a *ngIf='currentRequest?.announcement?.file'
           class='p-button p-button-outlined'
           [href]="attachmentService.getFileUrl(currentRequest?.announcement?.file?.key)"
           [download]="currentRequest?.announcement?.file?.filename" target="_blank">
            Ver convocatoria
        </a>
    </div>
  </div>
</ng-template>
