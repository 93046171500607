<app-wizard
    [steps]='steps'
    [current]='currentStep'
    [bodyContentTpl]='projectForm'
    [nextDisabled]='this.formGroup?.invalid'
    (onClickNext)='save($event)'
    [layout]='true'
>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column' [formGroup]='formGroup'>
        <div class='grid'>
            <div class="field col-12 text-2xl text-primary uppercase">
                {{currentStep?.text}}
            </div>

            <div class="field col-12">
                <p-table [value]="groups" dataKey="id" editMode="row" [tableStyle]="{'min-width': '100%'}" #dt>
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="bg-primary border-x-1 text-center"  style="width:60px">NRO.</th>
                            <th class="bg-primary border-x-1 text-center" >ESTRUCTURA DEL CONTENIDO DEL EDTP</th>
                            <th class="bg-primary border-x-1 text-center" style="width:300px">OBSERVACIONES</th>
                            <th class="bg-primary"  style="width:100px"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-obj let-editing="editing" let-index="rowIndex">
                        <tr [pEditableRow]="obj">
                            <td colspan='5' class='p-0 m-0'>
                                <app-compliance-obs
                                    [group]='obj'
                                    [canEdit]="isSubmitted"
                                >
                                </app-compliance-obs>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</ng-template>
