import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import {
  ProjectAssessmentFirstValues,
  ProjectAssessmentSecondValues,
  ProjectAssessmentThirdValues,
  ProjectAssessmentFourthValues,
  ProjectAssessmentFirstAmbitKeys,
  ProjectAssessmentSecondAmbitKeys,
  ProjectAssessmentThirdAmbitKeys,
  ProjectAssessmentFourthAmbitKeys
} from '../constants';
import { IParsedPA, IProjectAssessment } from '../interfaces/project-assessment.interface';
import { Table } from 'primeng/table';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { SettingsService } from '../../../../shared';
import { ProjectAssessmentsService } from '../services/project-assessments.service';
import { ProjectAssessmentModel } from '../../../models/project-assessment.model';
import { LayoutService } from '../../../../shared/services/app.layout.service';
import { Constants } from '../../../../shared/constants';
import { IProject } from '../../../models';

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss']
})
export class AssessmentComponent extends ProjectAssessmentModel implements OnInit, OnDestroy {
  private step = 2;
  showForm = false;

  firstGroupValues = [...this.parseObjectGroups(ProjectAssessmentFirstValues)];
  firstGroupTotal = '0';
  secondGroupValues = [...this.parseObjectGroups(ProjectAssessmentSecondValues)];
  secondGroupTotal = '0';
  thirdGroupValues = [...this.parseObjectGroups(ProjectAssessmentThirdValues)];
  thirdGroupTotal = '0';
  fourthGroupValues = [...this.parseObjectGroups(ProjectAssessmentFourthValues)];
  fourthGroupTotal = '0';

  cloned: { [s: string]: IParsedPA } = {};
  _assessments: IProjectAssessment[] = [];
  parsedAssessments: IParsedPA[] = [];

  @ViewChild('dtTable') table!: Table;
  @ViewChild('dtTable2') table2!: Table;
  @ViewChild('dtTable3') table3!: Table;
  @ViewChild('dtTable4') table4!: Table;

  constructor(
    private activatedRoute: ActivatedRoute,
    private readonly projectService: ProjectService,
    private fb: FormBuilder,
    private tService: ToasterService,
    private settingService: SettingsService,
    private projectAssessmentsService: ProjectAssessmentsService,
    private readonly layoutService: LayoutService
  ) {
    super(activatedRoute, projectService, tService);
  }

  get assessments() {
    return this._assessments;
  }

  set assessments(data: IProjectAssessment[]) {
    this.parsedAssessments = [];

    const firstDataKeys = Object.keys(ProjectAssessmentFirstAmbitKeys);
    const secondGroupDataKeys = Object.keys(ProjectAssessmentSecondAmbitKeys);
    const thirdGroupDataKeys = Object.keys(ProjectAssessmentThirdAmbitKeys);
    const fourthGroupDataKeys = Object.keys(ProjectAssessmentFourthAmbitKeys);

    for (const assessment of data) {
      if (firstDataKeys.includes(assessment.key)) {
        const assess = this.firstGroupValues.find((a) => a.key === assessment.key);
        const index = this.firstGroupValues.findIndex((a) => a.key === assessment.key);
        const parsedValue = { ...assess, ...assessment };
        this.firstGroupValues[index] = { ...parsedValue };
      } else if (secondGroupDataKeys.includes(assessment.key)) {
        const assess = this.secondGroupValues.find((a) => a.key === assessment.key);
        const index = this.secondGroupValues.findIndex((a) => a.key === assessment.key);
        const parsedValue = { ...assess, ...assessment };
        this.secondGroupValues[index] = { ...parsedValue };
      } else if (thirdGroupDataKeys.includes(assessment.key)) {
        const assess = this.thirdGroupValues.find((a) => a.key === assessment.key);
        const index = this.thirdGroupValues.findIndex((a) => a.key === assessment.key);
        const parsedValue = { ...assess, ...assessment };
        this.thirdGroupValues[index] = { ...parsedValue };
      } else if (fourthGroupDataKeys.includes(assessment.key)) {
        const assess = this.fourthGroupValues.find((a) => a.key === assessment.key);
        const index = this.fourthGroupValues.findIndex((a) => a.key === assessment.key);
        const parsedValue = { ...assess, ...assessment };
        this.fourthGroupValues[index] = { ...parsedValue };
      }
    }
  }

  onRowEditInit(obj: IParsedPA) {
    if (!obj?.key) return;
    this.cloned[obj.key] = { ...obj };
  }

  getForm(obj: IParsedPA, group: string): any {
    if (!obj?.key) return;
    const validationRange = this.getRangeValidation(group);

    return this.fb.group({
      key: [obj.key, Validators.required],
      score: [parseFloat(String(obj.score)), [Validators.required, Validators.max(validationRange)]],
      justification: [obj.justification || ' - ', Validators.required],
      projectId: [parseInt(this.params['id'])]
    });
  }

  onRowEditSave(obj: IParsedPA, group: string, index: number) {
    const objForm = this.getForm(obj, group);

    if (!objForm.valid) {
      this.toasterService.error('No se pudo guardar, los datos son inválidos!');
      return;
    }

    switch (group) {
      case 'FIRST':
        this.firstGroupTotal = this.getScoreAverageTotalSum(this.firstGroupValues);
        break;
      case 'SECOND':
        this.secondGroupTotal = this.getScoreAverageTotalSum(this.secondGroupValues);
        break;
      case 'THIRD':
        this.thirdGroupTotal = this.getScoreAverageTotalSum(this.thirdGroupValues);
        break;
      case 'FOURTH':
        this.fourthGroupTotal = this.getScoreAverageTotalSum(this.fourthGroupValues);
        break;

      default:
        break;
    }

    this.projectService.upsertAssessment(objForm.value, parseInt(this.params['id'])).then((project) => {
      const { assessments = [] } = project;
      this.currentProject = { ...project };
      this.assessments = [...assessments];
      this.firstGroupTotal = this.getScoreAverageTotalSum(this.firstGroupValues);
      this.secondGroupTotal = this.getScoreAverageTotalSum(this.secondGroupValues);
      this.thirdGroupTotal = this.getScoreAverageTotalSum(this.thirdGroupValues);
      this.fourthGroupTotal = this.getScoreAverageTotalSum(this.fourthGroupValues);
      this.totalProjectScore = this.getProjectTotalScore();

      this.toasterService.success('Datos actualizados con exito');
    });
  }

  onRowEditCancel(obj: any, index: number, group: string, newValue: any = null) {
    if (!obj?.key) return;

    const cloned = this.cloned[obj.key];
    const valueToReplace = newValue || cloned;
    if (this.cloned[obj.id]) {
      switch (group) {
        case 'FIRST':
          this.firstGroupValues[index] = valueToReplace;
          break;
        case 'SECOND':
          this.secondGroupValues[index] = valueToReplace;
          break;
        case 'THIRD':
          this.thirdGroupValues[index] = valueToReplace;
          break;
        case 'FOURTH':
          this.fourthGroupValues[index] = valueToReplace;
          break;

        default:
          break;
      }

      delete this.cloned[obj.key];
    }
  }

  ngOnInit(): void {
    this.layoutService.showMenuDesktop(false);
    this.readParams();
    this.setActive(this.step);
    this.projectService
      .get(this.params['id'])
      .then((project) => {
        const { id, name, assessments = [] } = project;
        this.currentProject = { ...project };
        this.assessments = [...assessments];
        this.firstGroupTotal = this.getScoreAverageTotalSum(this.firstGroupValues);
        this.secondGroupTotal = this.getScoreAverageTotalSum(this.secondGroupValues);
        this.thirdGroupTotal = this.getScoreAverageTotalSum(this.thirdGroupValues);
        this.fourthGroupTotal = this.getScoreAverageTotalSum(this.fourthGroupValues);
        this.totalProjectScore = this.getProjectTotalScore();
        this.formGroup = this.fb.group({
          id: [id],
          name: [name, Validators.required],
          status: [Constants.PROJECT_STATUSES.VALUED_OK],
          score: [0, Validators.required]
        });
      })
      .finally(() => {
        this.setSteps();
        this.setActive(this.step); //We need to reload the steps and put as active the current step
        this.disableFormIfCompleted();
        this.showForm = true;
      });
  }

  ngOnDestroy() {
    this.cleanParams();
  }

  getProjectTotalScore() {
    return (
      parseFloat(this.firstGroupTotal) +
      parseFloat(this.secondGroupTotal) +
      parseFloat(this.thirdGroupTotal) +
      parseFloat(this.fourthGroupTotal)
    );
  }

  getProjectTotalScorePartialObject(): Partial<IProject> {
    const totalScoreSum = this.getProjectTotalScore();

    return { score: totalScoreSum };
  }

  saveProject() {
    const projectScoreAttribute = this.getProjectTotalScorePartialObject();

    this.save(projectScoreAttribute);
  }

  private getScoreAverageTotalSum(group: IProjectAssessment[]): string {
    const sum = group.reduce((a, b) => {
      return a + (parseFloat(String(b.score)) || 0);
    }, 0);

    const average = sum / group.length;
    return average > 1 ? average.toFixed(2) : '0';
  }

  getRangeValidation(group: string) {
    switch (group) {
      case 'FIRST':
        return 15;
      case 'SECOND':
        return 60;
      case 'THIRD':
        return 15;

      default:
        return 10;
    }
  }

  private parseObjectGroups(group: any[]) {
    return group.map((a) => ({ ...a, score: 0, justification: null }));
  }
}
