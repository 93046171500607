import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { AdminTemplateComponent } from './templates/admin-template/admin-template.component';
import { AuthenticationGuard } from './shared/guards/authentication.guard';
import { NotfoundComponent } from './shared/pages/notfound/notfound.component';
import { SystemPermissionGuard } from './shared/guards/system-permission.guard';
import { Constants } from './shared/constants';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'auth',
        pathMatch: 'full',
    },
    { path: 'auth', loadChildren: () => import('./core/auth/auth.module').then((m) => m.AuthModule) },
    { path: 'error', loadChildren: () => import('./shared/pages/error/error.module').then((m) => m.ErrorModule) },
    { path: 'access', loadChildren: () => import('./shared/pages/access/access.module').then((m) => m.AccessModule) },
    { path: 'public', loadChildren: () => import('./public/public.module').then((m) => m.PublicModule) },
    {
        path: '',
        component: AdminTemplateComponent,
        canActivate: [AuthenticationGuard],
        canActivateChild: [SystemPermissionGuard],
        children: [
            {
                path: 'home',
                loadChildren: () => import('./core/dashboard/dashboard.module').then((m) => m.DashboardModule),
            },
            {
                path: 'user',
                data: {
                    moduleNameCode: 'USUARIOS',
                },
                loadChildren: () => import('./core/user/user.module').then((m) => m.UserModule),
            },
            {
                path: 'role',
                data: {
                    moduleNameCode: 'ROLES',
                },
                loadChildren: () => import('./core/role/role.module').then((m) => m.RoleModule),
            },
            {
                path: 'system-acl',
                data: {
                    moduleNameCode: 'PERMISOS_SISTEMA',
                },
                loadChildren: () =>
                    import('./core/permiso-sistema/permiso-sistema.module').then((m) => m.PermisoSistemaModule),
            },
            {
                path: 'itcp',
                loadChildren: () => import('./itcp/itcp.module').then((m) => m.ITCPModule),
            },
            {
                path: 'edtp',
                loadChildren: () => import('./edtp/edtp.module').then((m) => m.EDTPModule),
            },
            {
                path: 'log-activity',
                data: {
                    moduleNameCode: 'LOG_ACTIVIDADES',
                },
                loadChildren: () =>
                    import('./core/log-actividad/log-actividad.module').then((m) => m.LogActividadModule),
            },
            {
                path: 'entidad',
                data: {
                    moduleNameCode: Constants.RESOURCES.ENTITIES,
                },
                loadChildren: () => import('./core/entidad/entidad.module').then((m) => m.EntidadModule),
            },
            {
                path: 'reports',
                loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
            },
            {
                path: 'tracking',
                loadChildren: () => import('./tracking/tracking.module').then((m) => m.TrackingModule),
            },
            {
                path: 'visits',
                loadChildren: () => import('./visits/visits.module').then((m) => m.VisitsModule),
            },
        ],
    },
    // {
    //   path: 'landing',
    //   loadChildren: () => import('./modules/landing/landing.module').then((m) => m.LandingModule),
    // },
    {
        path: 'geovisor',
        loadChildren: () => import('./geovisor/geovisor.module').then((m) => m.GeovisorModule),
    },
    { path: 'notfound', component: NotfoundComponent },
    { path: '**', redirectTo: 'notfound' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            onSameUrlNavigation: 'reload',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
