import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TableModule } from 'primeng/table';
import { ChartModule } from 'primeng/chart';
import { ButtonModule } from 'primeng/button';
import { SkeletonModule } from 'primeng/skeleton';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { ReportsRoutingModule } from './reports-routing.module';
import { AnnouncementsComponent, ItcpComponent, EdtpComponent, TrackingComponent } from './components';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CustomFormModule } from '../shared';

@NgModule({
    declarations: [AnnouncementsComponent, ItcpComponent, EdtpComponent, TrackingComponent],
    imports: [
        CommonModule,
        ReportsRoutingModule,
        BreadcrumbModule,
        TableModule,
        ButtonModule,
        SkeletonModule,
        TabViewModule,
        TooltipModule,
        NgMultiSelectDropDownModule,
        ReactiveFormsModule,
        ChartModule,
        DialogModule,
        ProgressSpinnerModule,
        CustomFormModule
    ]
})
export class ReportsModule {
}
