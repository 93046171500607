import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from '../../../../shared';
import { IProjectRequirement } from '../interfaces/project-requirement.interface';

@Injectable({
  providedIn: 'root',
})
export class ProjectRequirementService extends BaseService<IProjectRequirement> {
  constructor(private readonly httpService: BaseHttpService) {
    super(httpService, 'project-requirements');
  }
}
