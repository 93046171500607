import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
    IProjectGoalActivity,
    IProjectGoalResult,
} from "../../../../models";
import {Table} from "primeng/table";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToasterService} from "../../../../../shared/services/toaster.service";
import {ProgrammerType, ProjectGoalActivityService} from "../../../../services";
import {IProjectGoal} from "../../../../../itcp";
import {ConfirmationService} from "primeng/api";

@Component({
    selector: 'app-results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {
    @Input() group!: IProjectGoalResult;
    @Input() goals!: IProjectGoal[];
    @Input() editMode: boolean = false;
    @Input() canProgram: boolean = false;
    @Output() onCancel = new EventEmitter<any>();
    @Output() onSave = new EventEmitter<any>();
    @Output() onEdit = new EventEmitter<any>();
    @Output() onRemove = new EventEmitter<any>();

    cloned: { [s: string]: IProjectGoalActivity; } = {};
    selectedGroup!: IProjectGoal;
    programmerTypes: ProgrammerType[] = ['FS', 'FN'];

    @ViewChild('dt') table!: Table;

    constructor(
        private readonly activityService: ProjectGoalActivityService,
        private fb: FormBuilder,
        private toasterService: ToasterService,
        private readonly confirmationService: ConfirmationService,
    ) {
    }

    ngOnInit(): void {
        if (!this.group.activities)
            this.group.activities = [];
    }

    saveGroup() {
        if (this.selectedGroup?.id) {
            this.group.goalId = parseInt(this.selectedGroup.id.toString());
            this.group.goal = this.selectedGroup;
        }
        this.onSave.emit(this.group);
        this.editMode = false;
    }

    editGroup() {
        this.onEdit.emit(this.group);
    }
    removeGroup() {
        this.onRemove.emit(this.group);
    }

    cancelEditGroup() {
        this.onCancel.emit(this.group);
    }

    onRowEditInit(obj: IProjectGoalActivity) {
        if (!obj?.id) return;
        this.cloned[obj.id] = {...obj};
    }

    onRowEditSave(obj: IProjectGoalActivity) {
        if (!obj?.id) return;

        const form: FormGroup = this.fb.group({
            projectId: [obj.projectId, Validators.required],
            resultId: [obj.resultId, Validators.required],
            activity: [obj.activity, Validators.required],
            weeksDuration: [obj.weeksDuration, Validators.required],
            programmerType: [obj.programmerType, Validators.required],
        });

        if (form.valid) {
            delete this.cloned[obj.id];

            if (obj?.id.toString().includes('new_')) {
                const index = this.group.activities.indexOf(obj);
                this.activityService.store(form.value).then((resp) => {
                    this.group.activities[index] = resp;
                    this.toasterService.success('El Registro fue creado satisfactoriamente.');
                });

            } else {
                const id = parseInt(obj.id.toString());
                this.activityService.update(form.value, id).then((resp) => {
                    this.toasterService.success('El Registro fue actualizado satisfactoriamente.');
                })
            }

        } else {
            this.toasterService.error('No se pudo guardar, los datos son inválidos!');
        }
    }
    onRowRemove(obj: IProjectGoalActivity) {
        if (!obj.id) return;

        const id = parseInt(obj.id.toString());
        this.confirmationService.confirm({
            message: 'Se encuentra seguro de eliminar este registro?',
            accept: () => {
                this.activityService
                    .delete(id)
                    .then((resp: any) => {
                        if (resp) {
                            this.toasterService.success('Se Elimino correctamente!');
                            this.group.activities = this.group.activities.filter(g => g.id !== resp.id);
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                        this.toasterService.error(err.error.message);
                    });
            },
        });
    }

    onRowEditCancel(obj: IProjectGoalActivity, index: number) {
        if (!obj?.id) return;

        if (this.cloned[obj.id]) {
            this.group.activities[index] = this.cloned[obj.id];
            delete this.cloned[obj.id];
        }

        if (obj?.id.toString().includes('new_')) {
            this.group.activities = this.group.activities.filter(o => o.id !== obj.id);
        }
    }

    onNew() {
        const ob: IProjectGoalActivity = {
            activity: '',
            id: `new_${new Date().getTime()}`,
            projectId: this.group.projectId,
            resultId: this.group.id ? parseInt(this.group.id.toString()) : 0,
            weeksDuration: 0,
            programmerType: 'FS'
        }

        this.group.activities.push(ob);
        this.table.initRowEdit(this.table.value[this.group.activities.length - 1]);
    }


    onSelectItem(event: any) {
        const item = this.goals.find(c => c.id === event.value);
        if (item) this.selectedGroup = { ...item };
    }
}
