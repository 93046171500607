import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IEdtpCompliance, IProjectComplianceGroup, ProjectAssessmentModel} from "../../../models";
import {ActivatedRoute} from "@angular/router";
import {ProjectService} from "../../../../itcp";
import {EdtpComplianceService, ProjectComplianceGroupService, QuantitativeTotalsType} from "../../../services";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToasterService} from "../../../../shared/services/toaster.service";
import {Table} from "primeng/table";
import {LayoutService} from "../../../../shared/services/app.layout.service";
import {ComplianceGroupEnum, CryptoService} from "../../../../shared";
import {ConfirmationService} from "primeng/api";

@Component({
  selector: 'app-quantitative',
  templateUrl: './quantitative.component.html',
  styleUrls: ['./quantitative.component.scss']
})
export class QuantitativeComponent extends ProjectAssessmentModel implements OnInit, OnDestroy {

    private step: number = 4;
    groups: IProjectComplianceGroup[] = [];
    clonedGroups: { [s: string]: IProjectComplianceGroup; } = {};
    accumTotals!: QuantitativeTotalsType;

    defaultCompliances: IEdtpCompliance[] = [];

    @ViewChild('dt') table!: Table;
    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly complianceGroupService: ProjectComplianceGroupService,
        private readonly edtpComplianceService: EdtpComplianceService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private layoutService: LayoutService,
        private readonly confirmationService: ConfirmationService,
        private crypto: CryptoService,
    ) {
        super(activatedRoute, projectService, tService, crypto);
    }

    ngOnInit(): void {
        this.layoutService.showMenuDesktop(false);
        this.readParams();
        this.setActive(this.step);
        this.service.get(this.params['id']).then((project) => {
            const {id, name} = project || {};
            this.currentProject = {...project};
            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required]
            });
            return this.edtpComplianceService.getAll();
        }).then((resp) => {
            this.defaultCompliances = [ ...resp ];
        }).finally(() => {
            this.setSteps();
            this.setActive(this.step); //We need to reload the steps and put as active the current step
            this.load();
            this.disableFormIfSubmitted();
            this.resetTotals();
            this.showContent = true;
        });

    }

    resetTotals() {
        this.accumTotals = { scoreAccum: 0, maxScoreAccum: 0, scoreDist: 0, maxScoreDist: 0 };
    }

    load() {
        const projectId = parseInt(this.params['id']);
        this.complianceGroupService.getAll({ params: { projectId, type: ComplianceGroupEnum.QUANTITATIVE_ASSESSMENT }})
            .then((resp) => {
                this.groups = [ ...resp ];
            });
    }

    onNew() {
        const g: IProjectComplianceGroup = {
            id: `new_${new Date().getTime()}`,
            projectId: parseInt(this.params['id']),
            title: '',
            code: 0,
            type: ComplianceGroupEnum.QUANTITATIVE_ASSESSMENT,
            weight: 0,
            maxScore: 0,
            scoreAccum: 0,
            scoreDist: 0,
            maxScoreAccum: 0,
            maxScoreDist: 0,
            children: [],
            assessments: [],
        }
        this.groups.push(g);
        this.table.initRowEdit(this.table.value[this.groups.length - 1]);
    }

    onRowEditInit(obj: IProjectComplianceGroup) {
        if (!obj?.id) return;
        this.clonedGroups[obj.id] = {...obj};
    }

    onRowEditCancel(obj: IProjectComplianceGroup, index: number) {
        if (!obj?.id) return;

        if (this.clonedGroups[obj.id]) {
            this.groups[index] = this.clonedGroups[obj.id];
            delete this.clonedGroups[obj.id];
        }

        if (obj?.id.toString().includes('new_')) {
            this.groups = this.groups.filter(g => g.id !== obj.id);
        }
    }

    onRowEditSave(obj: IProjectComplianceGroup) {
        if (!obj?.id) return;

        const form: FormGroup = this.fb.group({
            projectId: [obj.projectId, Validators.required],
            title: [obj.title, Validators.required],
            code: [obj.code, Validators.required],
            type: [obj.type, Validators.required],
            weight: [obj.weight, Validators.required],
            maxScore: [obj.maxScore, Validators.required],
        });

        if (form.valid) {
            delete this.clonedGroups[obj.id];

            if (obj?.id.toString().includes('new_')) {
                const index = this.groups.indexOf(obj);
                this.complianceGroupService.store(form.value).then((resp) => {
                    this.groups[index] = resp;
                    this.toasterService.success('El Registro fue creado satisfactoriamente.');
                });

            } else {
                const id = parseInt(obj.id.toString());
                this.complianceGroupService.update(form.value, id).then((resp) => {
                    this.toasterService.success('El Registro fue actualizado satisfactoriamente.');
                })
            }
        } else {
            this.toasterService.error('No se pudo guardar, los datos son inválidos!');
        }

    }

    onRemove(obj: IProjectComplianceGroup) {
        if (!obj.id) return;

        const id = parseInt(obj.id.toString());
        this.confirmationService.confirm({
            message: 'Estas seguro de eliminar este registro?',
            accept: () => {
                this.complianceGroupService
                    .delete(id)
                    .then((resp: boolean) => {
                        if (resp) {
                            this.toasterService.success('Se Elimino correctamente!');
                            this.load();
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                        this.toasterService.error(err.error.message);
                    });
            },
        });
    }

    setAccumTotals(obj: IProjectComplianceGroup) {
        this.resetTotals();
        this.groups.map(g => {
            // @ts-ignore
            this.accumTotals.scoreAccum += g.scoreAccum;
            // @ts-ignore
            this.accumTotals.maxScoreAccum += g.maxScoreAccum;
        });
    }

    setDistTotals(obj: IProjectComplianceGroup) {
        this.accumTotals.scoreDist = 0;
        this.accumTotals.maxScoreDist = 0;
        this.groups.map(g => {
            // @ts-ignore
            this.accumTotals.scoreDist += g.scoreDist;
            // @ts-ignore
            this.accumTotals.maxScoreDist += g.maxScoreDist;
        });
    }

    ngOnDestroy() {
        this.cleanParams();
    }
}
