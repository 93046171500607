import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from 'src/app/shared/services';
import { IProjectCostEfficiency } from '../models/interfaces/project-cost-efficiency.interface';

@Injectable({
  providedIn: 'root'
})
export class ProjectCostEfficiencyService extends BaseService<IProjectCostEfficiency>{

    constructor(private readonly httpService: BaseHttpService) {
        super(httpService, 'project-cost-efficiencies');
    }
}
