<div class="surface-ground h-full" [class.min-h-screen]="!layout">
    <div class="wizard-container grid w-full" [class.min-h-screen]="!layout">
        <div [ngClass]="{'wizard-body overflow-x-auto text-center flex flex-column justify-content-between': true, 'md:col-9 sm:col-12': showRightBar}"
            style="{{layout ? 'min-height: calc(100vh - 150px);' : 'height: 100vh'}}">
            <div class="flex-1 pb-0">
                <div class="flex justify-content-end">
                    <button *ngIf="!showRightBar" pButton type="button" [icon]="'pi pi-list'"
                        class="p-button-outlined p-button-rounded" [style]="'color: var(--primary-color)'"
                        (click)="showRightBar=true" [pTooltip]="'Mostrar Menú'" tooltipPosition="left"></button>
                </div>
                <div *ngIf="!layout" class="wizard-body-title">
                    Porfavor, ingrese la información solicitada
                    <p>
                        La información solicitada nos ayudara a determinar si cumple con los requisitos necesarios para
                        aprobar su proyecto
                    </p>
                </div>

                <div class="wizard-body-content text-sm">
                    <form class="form-wizard mb-8">
                        <ng-container
                            *ngTemplateOutlet="bodyContentTpl ? bodyContentTpl : defaultBodyContent"></ng-container>
                    </form>
                </div>
            </div>

            <div class="wizard-body-footer flex justify-content-between">
                <div>
                    <button class="p-button p-button-outlined {{previousRoute ? '' : 'hidden'}}"
                        [disabled]="previousDisabled" (click)="onPrevious($event)">
                        Anterior
                    </button>
                </div>
                <div>
                    <button *ngIf='!lastStep' class="p-button {{nextRoute ? '' : 'hidden'}}" [disabled]="nextDisabled"
                        (click)="onNext($event)">
                        Siguiente
                    </button>

                    <button *ngIf='lastStep' class="p-button" [disabled]="nextDisabled" (click)="onFinish.emit($event)">
                        Enviar
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="showRightBar" class="md:col-3 md:block sm:hidden wizard-steps">
            <div class="flex justify-content-end w-full">
                <button *ngIf="layout" pButton type="button" [icon]="'pi pi-times'"
                    class="p-button-outlined p-button-rounded" [style]="'color:white'" (click)="showRightBar=false"
                    [pTooltip]="'Ocultar Menú'" tooltipPosition="left"></button>
            </div>
            <div *ngFor="let item of getDisplaySteps(); let i = index;">
                <app-wizard-step [last]="(i + 1) === getDisplaySteps().length" [icon]="item?.icon" [text]="item?.text"
                    [path]="item?.path" [active]="item?.active">
                </app-wizard-step>
            </div>
        </div>
    </div>
</div>

<ng-template #defaultBodyContent>
    <div>Wizard Content............</div>
</ng-template>
