<div class='w-full bg-green-100'>
    <div class='flex gap-2 justify-content-start align-items-center px-4 py-2'>
        <div class="px-4 py-1">{{index + 1 }}</div>
        <div class="px-4 py-1 w-20rem flex justify-content-start align-items-center flex-1">
            <span class="font-bold">{{activity.activity}}</span>
        </div>
    </div>
</div>

<p-table
    [value]="budgets"
    dataKey="id"
    editMode="row"
    [tableStyle]="{'min-width': '100%'}"
    #dt>
    <ng-template pTemplate="header">
        <tr *ngIf="budgets.length">
            <th class="bg-primary border-x-1 text-center p-0" style="min-width:60px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width:300px; max-width: 300px;"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-obj let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="obj">
            <td style='width:50px'>{{index + 1}}.{{ri + 1}}</td>
            <td width="300">
                <b>{{obj.budgetItemCode}}</b> - {{obj.budgetItem?.title}}
                <div class="text-sm mt-2">
                    {{obj.budgetItemDetails}}
                </div>
            </td>

            <td class="text-center">
                {{obj.currentMonth.ownerAmount | number:'1.2-2'}}
            </td>

            <td class="text-center">
                {{obj.currentMonth.ownerExecutedAmount | number: '1.2-2'}}
            </td>

            <td class="text-center">
                {{ calcExecuted(obj.currentMonth.ownerExecutedAmount, obj.currentMonth.ownerAmount) | percent:'1.0-1'}}
            </td>
            <td>
                {{ obj.currentMonth.ownerAmount - obj.currentMonth.ownerExecutedAmount | number: '1.2-2'}}
            </td>
            <td class="text-center">
                {{obj.currentMonth.entityAmount | number: '1.2-2'}}
            </td>
            <td class='text-center'>
                {{obj.currentMonth.entityExecutedAmount | number: '1.2-2'}}
            </td>
            <td class="text-center">
                {{ calcExecuted(obj.currentMonth.entityExecutedAmount, obj.currentMonth.entityAmount) | percent:'1.0-1'}}
            </td>
            <td class="text-center">
                {{ obj.currentMonth.entityAmount - obj.currentMonth.entityExecutedAmount | number: '1.2-2'}}
            </td>
            <td class="text-center">
                {{obj.currentMonth.entityInKindAmount | number: '1.2-2'}}
            </td>
            <td class='text-center'>
                {{obj.currentMonth.entityInKindExecutedAmount | number: '1.2-2'}}
            </td>
            <td class="text-center">
                {{ calcExecuted(obj.currentMonth.entityInKindExecutedAmount, obj.currentMonth.entityInKindAmount) | percent:'1.0-1'}}
            </td>
            <td class="text-center">
                {{ obj.currentMonth.entityInKindAmount - obj.currentMonth.entityInKindExecutedAmount | number: '1.2-2'}}
            </td>
        </tr>
<!--        <tr *ngIf="budgets.length === ri + 1" class="bg-gray-100 font-bold">-->
<!--            <td class="text-center p-1" colspan="3">TOTAL</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem" >0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem" >0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="p-1" style="width:100px"></td>-->
<!--        </tr>-->
    </ng-template>
</p-table>
