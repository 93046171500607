import { Constants } from '../constants';

export class ProjectFlow {

  static allStatuses() {
    return [
      ...Object.keys(Constants.PROJECT_STATUSES)
    ].filter(s => s != Constants.PROJECT_STATUSES.CLOSED);// Allow all except closed
  }

  //Module ITCP
  static itcpStatusesForRequests() {
    return [Constants.PROJECT_STATUSES.COMPLETED, Constants.PROJECT_STATUSES.VERIFIED];
  }

  static itcpStatusesForProjects() {
    return [
      Constants.PROJECT_STATUSES.SUBMITTED,
      Constants.PROJECT_STATUSES.VERIFIED,
      ...this.itcpStatusesForRanking()
    ];
  }

  static itcpStatusesForAssignments() {
    return [Constants.PROJECT_STATUSES.SUBMITTED];
  }

  static itcpStatusesForAssigned() {
    return [
      Constants.PROJECT_STATUSES.ASSIGNED_ITCP,
      ...this.itcpStatusesForRanking()
    ];
  }

  static itcpStatusesForAssessments() {
    return [Constants.PROJECT_STATUSES.ASSIGNED_ITCP];
  }

  static itcpStatusesForRanking() {
    return [
      Constants.PROJECT_STATUSES.VALUED_OK,
      Constants.PROJECT_STATUSES.VALUED_FAIL,
      Constants.PROJECT_STATUSES.NOTIFIED,
      Constants.PROJECT_STATUSES.APPROVED,
      Constants.PROJECT_STATUSES.REJECTED,
      Constants.PROJECT_STATUSES.ASSIGNED_EDTP,
      Constants.PROJECT_STATUSES.CONSOLIDATED,
      Constants.PROJECT_STATUSES.CONSOLIDATED_SUBMITTED
    ];
  }

  //Module EDTP
  static edtpStatusesForAssignments() {
    return [Constants.PROJECT_STATUSES.APPROVED];
  }

  static edtpStatusesForAssigned() {
    return [Constants.PROJECT_STATUSES.ASSIGNED_EDTP, Constants.PROJECT_STATUSES.CONSOLIDATED, Constants.PROJECT_STATUSES.CONSOLIDATED_SUBMITTED];
  }

  static edtpStatusesForFormulate() {
    return [Constants.PROJECT_STATUSES.APPROVED, Constants.PROJECT_STATUSES.ASSIGNED_EDTP, Constants.PROJECT_STATUSES.CONSOLIDATED, Constants.PROJECT_STATUSES.CONSOLIDATED_SUBMITTED];
  }

  static edtpStatusesForReport() {
    return [
      ...ProjectFlow.trackingStatusesForMonitoring(),
      Constants.PROJECT_STATUSES.REJECTED
    ];
  }

  static edtpStatusesForScheduler() {
    return [
      Constants.PROJECT_STATUSES.SUBMITTED,
      ...ProjectFlow.trackingStatusesForProgrammer()
    ];
  }

  static edtpStatusesForAssessment() {
    return this.allStatuses();
  }

  static edtpStatusesForEval() {
    return this.allStatuses();
  }

  static edtpStatusesForRanking() {
    return [
        Constants.PROJECT_STATUSES.EVALUATED,
        ...ProjectFlow.trackingStatusesForProcessOrders()
    ];
  }

  //Module Tracking
  static trackingStatusesForAssignments() {
    return [Constants.PROJECT_STATUSES.APPROVED];
  }

  static trackingStatusesForAssigned() {
    return [
      ...this.trackingStatusesForProgrammer()
    ];
  }

  static trackingStatusesForProgrammer() {
    return [Constants.PROJECT_STATUSES.ASSIGNED_TRACKING, Constants.PROJECT_STATUSES.PROGRAMMING, Constants.PROJECT_STATUSES.PROGRAMMED,
      ...ProjectFlow.trackingStatusesForMonitoring()
    ];
  }

  static trackingStatusesForMonitoring() {
    return [
      Constants.PROJECT_STATUSES.REPORTING, Constants.PROJECT_STATUSES.PROGRAMMING,
      Constants.PROJECT_STATUSES.REPORTING, Constants.PROJECT_STATUSES.PROGRAMMED,
      Constants.PROJECT_STATUSES.REPORTING, Constants.PROJECT_STATUSES.START_ORDERED,
      Constants.PROJECT_STATUSES.REPORTING, Constants.PROJECT_STATUSES.REPORTING,
      Constants.PROJECT_STATUSES.REPORTING, Constants.PROJECT_STATUSES.REPORTED,
      ...ProjectFlow.trackingStatusesForEvaluation()
    ];
  }

  static trackingStatusesForEvaluation() {
    return [
      Constants.PROJECT_STATUSES.REPORTING,
      Constants.PROJECT_STATUSES.REPORTED,
      Constants.PROJECT_STATUSES.REPORT_EVALUATING,
      Constants.PROJECT_STATUSES.REPORT_EVALUATED
    ];
  }

  static trackingStatusesForProcessOrders() {
    return [
      Constants.PROJECT_STATUSES.PROGRAMMING,
      Constants.PROJECT_STATUSES.PROGRAMMED,
      Constants.PROJECT_STATUSES.START_ORDERED,
      Constants.PROJECT_STATUSES.CHANGE_ORDERED,
      Constants.PROJECT_STATUSES.CLOSED,
      ...ProjectFlow.trackingStatusesForMonitoring(),
      ...ProjectFlow.trackingStatusesForEvaluation()
    ];
  }

  static trackingStatusesForProjectDetails() {
    return [
      Constants.PROJECT_STATUSES.CLOSED,
      ...this.trackingStatusesForProcessOrders()
    ];
  }

    static trackingStatusesForExpost() {
        return [
            Constants.PROJECT_STATUSES.CLOSED,
        ];
    }
}
