import { Component, OnDestroy, OnInit } from '@angular/core';
import { ITCPRequest } from '../../../models';
import { ActivatedRoute } from '@angular/router';
import { ITCPRequestService } from '../../../../itcp';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { FormBuilder, Validators } from '@angular/forms';
import { IAttachment } from '../../../../shared/interfaces';
import { PhoneNumberLengthValidator } from '../../../../shared/validators/phone-number-length.validator';

@Component({
  selector: 'app-mae-info',
  templateUrl: './mae-info.component.html',
  styleUrls: ['./mae-info.component.scss'],
})
export class MaeInfoComponent extends ITCPRequest implements OnInit, OnDestroy {
  private step: number = 2;
  seoIdentifyFile: IAttachment[] = [];
  seoMemoFile: IAttachment[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private readonly iTCPRequestService: ITCPRequestService,
    private tService: ToasterService,
    private fb: FormBuilder
  ) {
    super(activatedRoute, iTCPRequestService, tService);
  }

  ngOnInit(): void {
    this.readParams();
    this.showContent = false;
    this.setCurrentRequest()
      .then((request) => {
        const {
          id,
          announcementCode,
          announcementId,
          token,
          seoName,
          seoIdentityFileId,
          seoIdentityFile,
          seoJobtitle,
          seoMemoFileId,
          seoMemoFile,
          seoCellPhone,
        } = request || {};

        if (seoIdentityFile) this.seoIdentifyFile.push(seoIdentityFile);
        if (seoMemoFile) this.seoMemoFile.push(seoMemoFile);
        this.formGroup = this.fb.group({
          id: [id],
          announcementCode: [announcementCode],
          announcementId: [announcementId],
          token: [token],
          seoName: [seoName, Validators.required],
          seoIdentityFileId: [seoIdentityFileId, Validators.required],
          seoJobtitle: [seoJobtitle, Validators.required],
          seoMemoFileId: [seoMemoFileId, Validators.required],
          seoCellPhone: [
            seoCellPhone,
            [Validators.required, PhoneNumberLengthValidator.phoneNumberValidator.bind(this)],
          ],
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
          this.disableFormIfReadonly();
        this.showContent = true;
      });
    this.setActive(this.step);
  }

  onUploaded(data: any) {
    const {
      event: {
        source: {
          _fileInput: { nativeElement },
        },
      },
      files,
    } = data;
    if (files.length) {
      if (nativeElement.id === 'seoIdentifyFile') {
        this.formGroup.patchValue({
          seoIdentityFileId: files[0].id,
        });
      } else if (nativeElement.id === 'seoMemoFile') {
        this.formGroup.patchValue({
          seoMemoFileId: files[0].id,
        });
      }
    }
  }

  // Only Integer Numbers
  onKeyPressNumbers(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  onRemoveFile(event: any) {
    console.log('child', event);
  }

  ngOnDestroy() {
    this.cleanParams();
  }
}
