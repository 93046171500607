import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProjectEdtpModel } from '../../../../models';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../../../itcp';
import { ProjectBaseLineService } from '../../../../services';
import {CryptoService, SettingsService} from '../../../../../shared';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from '../../../../../shared/services/toaster.service';
import { IProjectC2t4 } from '../../../../models/interfaces/project-c2t4.interface';
import { Table } from 'primeng/table';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProjectC2t4Service } from '../../../../services/project-c2t4.service';
import { LayoutService } from '../../../../../shared/services/app.layout.service';

@Component({
  selector: 'app-t-iv',
  templateUrl: './t-iv.component.html',
  styleUrls: ['./t-iv.component.scss'],
})
export class C2TIVComponent extends ProjectEdtpModel implements OnInit, OnDestroy {
  private step: number = 12;

  c2t4Items: IProjectC2t4[] = [];
  clonedC2t4Items: { [s: string]: IProjectC2t4 } = {};
  @ViewChild('dt') table!: Table;
  public ref!: DynamicDialogRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private readonly projectService: ProjectService,
    private readonly projectC2t4Service: ProjectC2t4Service,
    private readonly baseLineService: ProjectBaseLineService,
    private readonly setService: SettingsService,
    private fb: FormBuilder,
    private tService: ToasterService,
    private layoutService: LayoutService,
    private crypto: CryptoService,
  ) {
    super(activatedRoute, projectService, tService, setService, crypto);
  }

  ngOnInit(): void {
      this.layoutService.showMenuDesktop(false);
    this.readParams();
    this.setActive(this.step);
    this.service
      .get(this.params['id'])
      .then((project) => {
        const { id, name } = project || {};
        this.currentProject = { ...project };
        this.formGroup = this.fb.group({
          id: [id],
          name: [name, Validators.required],
        });

        return this.projectC2t4Service.getAll({ params: { projectId: parseInt(String(id)) } });
      })
      .then((resp) => {
        this.c2t4Items = [...resp];
      })
      .finally(() => {
        this.setSteps();
        this.setActive(this.step); //We need to reload the steps and put as active the current step
        this.disableFormIfSubmitted();
        this.showContent = true;
      });
  }

  onRowEditInit(obj: IProjectC2t4) {
    if (!obj?.id) return;
    this.clonedC2t4Items[obj.id] = { ...obj };
  }

  onRowEditSave(obj: IProjectC2t4) {
    if (!obj?.id) return;

    const form: FormGroup = this.fb.group({
      projectId: [obj.projectId, Validators.required],
      workArea: [obj.workArea, Validators.required],
      trainedMen: [obj.trainedMen, [Validators.required, Validators.min(0)]],
      trainedWomen: [obj.trainedWomen, [Validators.required, Validators.min(0)]],
      assistedMen: [obj.assistedMen, [Validators.required, Validators.min(0)]],
      assistedWomen: [obj.assistedWomen, [Validators.required, Validators.min(0)]],
      directlyBenefitedMen: [obj.directlyBenefitedMen, [Validators.required, Validators.min(0)]],
      directlyBenefitedWomen: [obj.directlyBenefitedWomen, [Validators.required, Validators.min(0)]],
      indirectlyBenefitedMen: [obj.indirectlyBenefitedMen, [Validators.required, Validators.min(0)]],
      indirectlyBenefitedWomen: [obj.indirectlyBenefitedWomen, [Validators.required, Validators.min(0)]],
    });

    if (form.valid) {
      delete this.clonedC2t4Items[obj.id];

      if (obj?.id.toString().includes('new_')) {
        const index = this.c2t4Items.indexOf(obj);
        this.projectC2t4Service.store(form.value).then((resp) => {
          this.c2t4Items[index] = { ...resp };
          this.toasterService.success('El Registro fue creado satisfactoriamente.');
        });
      } else {
        const id = parseInt(obj.id.toString());
        this.projectC2t4Service.update(form.value, id).then((resp) => {
          this.toasterService.success('El Registro fue actualizado satisfactoriamente.');
        });
      }
    } else {
      this.toasterService.error('No se pudo guardar, los datos son inválidos!');
    }
  }

  onRowEditCancel(obj: IProjectC2t4, index: number) {
    if (!obj?.id) return;

    if (this.c2t4Items[parseInt(String(obj.id))]) {
      this.c2t4Items[index] = this.clonedC2t4Items[obj.id];
      delete this.c2t4Items[parseInt(String(obj.id))];
    }

    if (obj?.id.toString().includes('new_add')) {
      this.c2t4Items.pop();
    }
  }

  onNew() {
    const c2t4: IProjectC2t4 = {
      id: `new_add_${new Date().getTime()}`,
      projectId: parseInt(this.params['id']),
      trainedMen: 0,
      trainedWomen: 0,
      assistedMen: 0,
      assistedWomen: 0,
      directlyBenefitedMen: 0,
      directlyBenefitedWomen: 0,
      indirectlyBenefitedMen: 0,
      indirectlyBenefitedWomen: 0,
    };

    this.c2t4Items.push(c2t4);
    this.table.initRowEdit(this.table.value[this.c2t4Items.length - 1]);
  }

  ngOnDestroy() {
    this.cleanParams();
  }
}
