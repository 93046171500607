import {Injectable} from '@angular/core';
import {lastValueFrom} from 'rxjs';
import {ProcessTypes} from 'src/app/shared/interfaces';
import {BaseHttpService} from 'src/app/shared/services';
import {AproveReject, EnableDisableUser, User} from './interfaces';

const END_POINT = 'usuario';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(public readonly _baseHttpService: BaseHttpService) {
    }

    public getUsers(estado: ProcessTypes = ProcessTypes.APROVE, roles: string[] = []): Promise<User[]> {

        let options = {
            params: {
                estado,
                roles
            }
        }

        return lastValueFrom(this._baseHttpService.get(END_POINT, options));
    }

    async getByEmail(email: string): Promise<User> {
        return lastValueFrom(this._baseHttpService.get(`${END_POINT}/email/${email}`));
    }

    public getDisabeldUsers(): Promise<User> {
        return lastValueFrom(this._baseHttpService.get(`${END_POINT}/disabled`));
    }

    public storeUser(data: User): Promise<User> {
        return lastValueFrom(this._baseHttpService.post(END_POINT, data));
    }

    public updateUser(data: User, userId: number): Promise<User> {
        return lastValueFrom(this._baseHttpService.put(`${END_POINT}/${userId}`, data));
    }

    public aproveRejectUser(data: AproveReject, userId: number): Promise<boolean> {
        return lastValueFrom(this._baseHttpService.put(`${END_POINT}/alta-baja/${userId}`, data));
    }

    public deleteUser(userId: number): Promise<boolean> {
        return lastValueFrom(this._baseHttpService.delete(`${END_POINT}/${userId}`));
    }

    public enableDisableUser(data: EnableDisableUser, userId: number): Promise<boolean> {
        return lastValueFrom(this._baseHttpService.put(`${END_POINT}/habilitar-inhabilitar/${userId}`, data));
    }
}
