export * from './programmer.component';
export * from "./physical/physical.component";
export * from "./physical-details/physical-details.component";
export * from "./physical-details/activities/activities.component";
export * from "./financial/financial.component";
export * from "./financial/activities/activities.component";
export * from "./financial/activities/budgets/budgets.component";
export * from "./financial-details/financial-details.component";
export * from "./financial-details/activities/activities.component";
export * from "./financial-details/activities/budgets/budgets.component";
export * from "./physical/activities/activities.component";
export * from "./activities/activities.component";
export * from "./activities/activity-weekly/activities.component";
export * from "./schedule/schedule.component";
