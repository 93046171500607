<div class='w-full bg-green-100'>
    <div *ngIf="!editMode" class='flex gap-2 justify-content-start align-items-center px-4 py-2'>
        <div class="px-4 py-1">{{index + 1 }}</div>
        <div class="px-4 py-1 w-20rem flex justify-content-start align-items-center">
            <span class="font-bold">{{activity.activity}}</span>
            <button
                *ngIf="canProgram"
                class='p-button p-button-success p-button-rounded p-button-text'
                pButton pTooltip="Agregar Partida Presupuestaria" tooltipPosition="top"
                [disabled]="!canProgram"
                (click)='onNew()'
            >
                <i class="pi pi-plus"></i>
            </button>
        </div>
        <div class="px-4 py-1 text-center w-10rem" pTooltip="INDICADOR DE ACTIVIDAD" tooltipPosition="top">
            {{activity.indicator}}
        </div>
        <div class="px-4 py-1 text-center w-10rem" pTooltip="UNIDAD DE MEDIDA" tooltipPosition="top">
            {{activity.uom}}
        </div>
        <div class="px-4 py-1 text-center w-10rem" pTooltip="MEDIOS DE VERIFICACIÓN" tooltipPosition="top">
            {{activity.verification}}
        </div>
        <div class="px-4 py-1 text-center w-10rem" pTooltip="DURACIÓN EN SEMANAS" tooltipPosition="top">
            {{ activity.weeksDuration }}
        </div>

        <div *ngIf="canProgram">
            <button pButton pRipple type="button" pTooltip="Editar" tooltipPosition="top" pInitEditableRow icon="pi pi-pencil" (click)="editGroup()" [disabled]="!canProgram" class="p-button-rounded p-button-text"></button>

<!--            <button pButton pRipple type="button" pTooltip="Eliminar" tooltipPosition="top" icon="pi pi-trash" (click)="removeGroup()" [disabled]="!canProgram" class="p-button-rounded p-button-text p-button-danger"></button>-->
        </div>
    </div>

    <div *ngIf='editMode' class='flex gap-4 justify-content-start align-items-center px-4 py-2'>
        <div class="px-4 py-1">{{index + 1 }}</div>
        <div class="w-20rem ">
            <div class="text-xs font-bold">ACTIVIDAD</div>
            <textarea
                [autofocus]="true"
                [rows]='2'
                pInputTextarea
                [autoResize]='false'
                placeholder='Descripción de la actividad'
                [(ngModel)]="activity.activity"
                [ngModelOptions]="{standalone: true}"
                class='text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full'
            ></textarea>
        </div>
        <div class="w-10rem">
            <div class="text-xs font-bold">INDICADOR DE ACTIVIDAD</div>
            <textarea
                [autofocus]="true"
                [rows]='2'
                pInputTextarea
                [autoResize]='false'
                placeholder='Indicador'
                [(ngModel)]="activity.indicator"
                [ngModelOptions]="{standalone: true}"
                class='text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full'
            ></textarea>
        </div>
        <div class="w-10rem">
            <div class="text-xs font-bold">UNIDAD</div>
            <textarea
                [autofocus]="true"
                [rows]='2'
                pInputTextarea
                [autoResize]='false'
                placeholder="Unidad de Medida"
                [(ngModel)]="activity.uom"
                [ngModelOptions]="{standalone: true}"
                class='text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full'
            ></textarea>
        </div>
        <div class="w-10rem">
            <div class="text-xs font-bold">MEDIOS DE VERIFICACIÓN</div>
            <textarea
                [autofocus]="true"
                [rows]='2'
                pInputTextarea
                [autoResize]='false'
                placeholder="Medios de Verificación"
                [(ngModel)]="activity.verification"
                [ngModelOptions]="{standalone: true}"
                class='text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full'
            ></textarea>
        </div>
        <div class="w-10rem text-center">
            <div class="text-xs font-bold">DURACIÓN EN SEMANAS</div>
            {{ activity.weeksDuration }}
        </div>

        <div class="flex gap-1">
            <button
                pButton
                pRipple
                type="button"
                pSaveEditableRow
                icon="pi pi-check"
                (click)="saveGroup()"
                [disabled]="!canSave"
                class="p-button-rounded p-button-text p-button-success mr-2">
            </button>
            <button pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="cancelEditGroup()" class="p-button-rounded p-button-text p-button-danger"></button>
        </div>
    </div>
</div>

<p-table
    [value]="budgets"
    dataKey="id"
    editMode="row"
    [tableStyle]="{'min-width': '100%'}"
    #dt>
    <ng-template pTemplate="header">
        <tr *ngIf="budgets.length">
            <th class="bg-green-50 border-x-1 text-center p-1" style="min-width:60px">Nro</th>
            <th class="bg-green-50 border-x-1 text-center p-1" style="min-width:300px; max-width:300px">Partida Presupuestaria</th>
            <th class="bg-green-50 border-x-1 text-center p-1" style="min-width:200px; max-width:200px">Detalle</th>
            <th class="bg-green-50 border-x-1 text-center p-1" style="min-width:115px;">Fonabosque (Bs.)</th>
            <th class="bg-green-50 border-x-1 text-center p-1" style="min-width:115px;" >Entidad Ejecutora Especie (Bs.)</th>
            <th class="bg-green-50 border-x-1 text-center p-1" style="min-width:115px;">Entidad Ejecutora Efectivo (Bs.)</th>
            <th class="bg-green-50 border-x-1 text-center p-1" style="min-width:115px;">Total Entidad Ejecutora (Bs.)</th>
            <th class="bg-green-50 border-x-1 text-center p-1" style="min-width:115px;">Total (Bs.)</th>
            <th class="bg-green-50 border-x-1 text-center p-1" style="min-width:115px;">Fonabosque (Bs.)</th>
            <th class="bg-green-50 border-x-1 text-center p-1" style="min-width:115px;" >Entidad Ejecutora Especie (Bs.)</th>
            <th class="bg-green-50 border-x-1 text-center p-1" style="min-width:115px;">Entidad Ejecutora Efectivo (Bs.)</th>
            <th class="bg-green-50 border-x-1 text-center p-1" style="min-width:115px;">Total Entidad Ejecutora (Bs.)</th>
            <th class="bg-green-50 border-x-1 text-center p-1" style="min-width:115px;">Total (Bs.)</th>
            <th class="bg-green-50 p-1" style="width:100px"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-obj let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="obj">
            <td style='width:50px'>{{index + 1}}.{{ri + 1}}</td>
            <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-autoComplete
                            [suggestions]="budgetItems"
                            [dropdown]='true'
                            (completeMethod)="filterBudgetItems($event)"
                            (onSelect)='onSelectBudgetItem($event, obj)'
                            field='code'
                            placeholder='Partida Presupuestaria'
                            scrollHeight="300px"
                            [minLength]='3'>
                            <ng-template let-item pTemplate="item">
                                <div class="budget-item w-30rem">
                                    <div class="budget-item-title" style="white-space: pre-wrap">
                                        <b>{{item.code}}</b> - {{item.title}}
                                    </div>
                                    <div class='text-xs text-bluegray-300 budget-item-description' style="white-space: pre-wrap">
                                        {{item.description}}
                                    </div>
                                </div>
                            </ng-template>

                        </p-autoComplete>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <b>{{obj.budgetItemCode}}</b> - {{obj.budgetItem?.title}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <textarea pInputText
                               placeholder="Detalle"
                               required
                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                                  type="text" [(ngModel)]="obj.budgetItemDetails" [ngModelOptions]="{standalone: true}">

                        </textarea>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.budgetItemDetails}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class="text-center">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText
                               required
                               class='w-6rem p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary'
                               type="number" min="0" [(ngModel)]="obj.ownerAmount" [ngModelOptions]="{standalone: true}">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.ownerAmount | number: '1.2-2'}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class="text-center">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText
                               required
                               class='w-6rem p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary'
                               type="number" min="0" [(ngModel)]="obj.entityInKindAmount" [ngModelOptions]="{standalone: true}">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.entityInKindAmount | number: '1.2-2'}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class='text-center'>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText
                               required
                               class='w-6rem p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary'
                               type="number" min="0" [(ngModel)]="obj.entityAmount" [ngModelOptions]="{standalone: true}">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.entityAmount | number: '1.2-2'}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class="text-center">
                {{ (obj.entityInKindAmount + obj.entityAmount) | number: '1.2-2'}}
            </td>
            <td class="text-center">
                {{ (obj.entityInKindAmount + obj.entityAmount + obj.ownerAmount) | number: '1.2-2'}}
            </td>
            <td class="text-center">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText
                               required
                               class='w-6rem p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary'
                               type="number" min="0" [(ngModel)]="obj.ownerAmountYear" [ngModelOptions]="{standalone: true}">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.ownerAmountYear | number: '1.2-2'}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class="text-center">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText
                               required
                               class='w-6rem p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary'
                               type="number" min="0" [(ngModel)]="obj.entityInKindAmountYear" [ngModelOptions]="{standalone: true}">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.entityInKindAmountYear | number: '1.2-2'}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class='text-center'>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText
                               required
                               class='w-6rem p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary'
                               type="number" min="0" [(ngModel)]="obj.entityAmountYear" [ngModelOptions]="{standalone: true}">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.entityAmountYear | number: '1.2-2'}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class="text-center">
                {{ (obj.entityInKindAmountYear + obj.entityAmountYear) | number: '1.2-2'}}
            </td>
            <td class="text-center">
                {{ (obj.entityInKindAmountYear + obj.entityAmountYear + obj.ownerAmountYear) | number: '1.2-2'}}
            </td>
            <td>
                <div *ngIf="canProgram" class="flex align-items-center justify-content-center gap-2">
                    <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(obj)" class="p-button-rounded p-button-text"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(obj)" class="p-button-rounded p-button-text p-button-success mr-2" [disabled]="!getForm(obj)?.valid"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(obj, index)" class="p-button-rounded p-button-text p-button-danger"></button>
                </div>
            </td>
        </tr>
<!--        <tr *ngIf="budgets.length === ri + 1" class="bg-gray-100 font-bold">-->
<!--            <td class="text-center p-1" colspan="3">TOTAL</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem" >0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem" >0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="p-1" style="width:100px"></td>-->
<!--        </tr>-->
    </ng-template>
</p-table>
