import { Component, Input, OnInit } from '@angular/core';
import { IITCPRequest, IITCPRequestVerification } from '../../../models';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ITCPRequestService, ITCPRequestVerificationKeys } from '../../../services';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { IAttachment } from '../../../../shared';
import { Constants } from '../../../../shared/constants';
import {AttachmentService} from "../../../../shared";

@Component({
    selector: 'app-request-modal',
    templateUrl: './request-modal.component.html',
    styleUrls: ['./request-modal.component.scss']
})
export class RequestModalComponent implements OnInit {

    @Input() public id!: number;
    @Input() public data!: IITCPRequest;

    public formGroup!: FormGroup;
    public showForm = false;
    public verificationKeys = ITCPRequestVerificationKeys;


    seoIdentifyFile: IAttachment | undefined;
    seoMemoFile: IAttachment | undefined;
    leadMemoFile: IAttachment | undefined;
    private tmpResponse!: IITCPRequest;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private fb: FormBuilder,
        private toasterService: ToasterService,
        public service: ITCPRequestService,
        private attachmentService: AttachmentService,
    ) {}

    ngOnInit(): void {
        const { id, seoIdentityFile, seoMemoFile, leadMemoFile, verifications } = this.config.data || {};

        if (seoIdentityFile) this.seoIdentifyFile = seoIdentityFile;
        if (seoMemoFile)  this.seoMemoFile = seoMemoFile;
        if (leadMemoFile)  this.leadMemoFile = leadMemoFile;

        this.formGroup = this.fb.group({
            key: [null],
            itcpRequestId: [id],
            verified: [null]
        });

        this.verificationKeys.map(vk => {
            const verification = verifications.find((v: IITCPRequestVerification) => v.key === vk.key);
            if (verification) vk.value = verification.verified;
        });

        this.showForm = true;
    }

    get form(): any {
        return this.formGroup.controls;
    }

    save(key: string, verified: boolean): void {
        this.formGroup.patchValue({ key, verified });

        // Invalid
        if (!this.formGroup.valid) {
            this.toasterService.error('Corrija los datos e inténtelo nuevamente');
            return;
        }

        //Store
        const postData: IITCPRequestVerification = this.formGroup.value;
        this.service
            .upsertVerification(postData, postData.itcpRequestId)
            .then((resp: IITCPRequest) => {
                const { verifications, requestStatus } = resp || {};
                this.toasterService.success('La verificación fué almacenada con éxito');
                this.tmpResponse = resp;
                if (verifications) {
                    const expected = this.verificationKeys.map(v => v.key);
                    const verified = verifications.filter((v:any) => v.verified && expected.includes(v.key));

                    if(verified.length === expected.length )
                        this.service.updatePartialData(resp, { requestStatus:  Constants.PROJECT_STATUSES.VERIFIED }, postData.itcpRequestId).then();
                    else if(requestStatus === Constants.PROJECT_STATUSES.VERIFIED)
                        this.service.updatePartialData(resp, { requestStatus: Constants.PROJECT_STATUSES.COMPLETED }, postData.itcpRequestId).then();
                }
            })
            .catch((err) => {
                console.error(err.error.message);
                this.toasterService.error('Se ha producido un error inténtelo más tarde o contáctese con el Administrador.');
            });
    }

    onChange(event: any, key: string) {
        this.save(key, event.checked);
    }

    downloadFileUrl(key?: string) {
        if (!key) return '#';

        return this.attachmentService.getFileUrl(key);
    }

    closeModal(): void {
        this.ref.close(this.tmpResponse);
    }

}
