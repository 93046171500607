<div class='w-full px-4 py-3 bg-secondary'>
    <div class='flex gap-4 justify-content-start align-items-center'>
        <div class='flex gap-4 justify-content-start align-items-center'>
            <span class="font-bold">{{group.code}}.</span>{{group.title}}
        </div>
    </div>
</div>

<p-table [value]="group.children" dataKey="id" editMode="row" [tableStyle]="{width: '100%'}" #dt>
    <ng-template pTemplate="header">
        <tr>
            <th class="bg-secondary border-x-1 text-center p-0" rowspan='2' style="width:60px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" rowspan='2' style="width:300px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" rowspan='2' style="width:200px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" colspan='2'></th>
            <th class="bg-secondary border-x-1 text-center p-0" rowspan='2' style="width:200px"></th>
            <th class="bg-secondary p-0" rowspan='2'  style="width:100px"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-obj let-editing="editing" let-index="rowIndex">
        <tr [pEditableRow]="obj">
            <td style='width:60px'>{{this.group.code}}.{{index + 1}}</td>
            <td>
                {{obj.compliance}}
            </td>
            <td>
                {{obj.observation}}
            </td>
            <td class='text-center'>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-radioButton name="fixed" [value]='false' [(ngModel)]="obj.fixed" [ngModelOptions]="{standalone: true}"></p-radioButton>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <i *ngIf="!obj.fixed" class="pi pi-check text-2xl p-primary-color"></i>
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class='text-center'>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-radioButton name="fixed" [value]='true' [(ngModel)]="obj.fixed" [ngModelOptions]="{standalone: true}"></p-radioButton>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <i *ngIf="obj.fixed" class="pi pi-check text-2xl p-primary-color"></i>
                    </ng-template>
                </p-cellEditor>
            </td>
            <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                       <textarea
                           [rows]='3'
                           pInputTextarea
                           [autoResize]='true'
                           placeholder='Comentarios sobre la observación'
                           [pAutoFocus]="true"
                           class='text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                           [(ngModel)]="obj.fixingObservation" [ngModelOptions]="{standalone: true}"
                       >
                        </textarea>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.fixingObservation}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td>
                <div *ngIf="canEdit" class="flex align-items-center justify-content-center gap-2">
                    <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(obj)" class="p-button-rounded p-button-text"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(obj)" class="p-button-rounded p-button-text p-button-success mr-2" [disabled]="!itemForm(obj).valid"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(obj, index)" class="p-button-rounded p-button-text p-button-danger"></button>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
