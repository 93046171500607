import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { Subject } from 'rxjs';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import * as _ from 'lodash-es';

import { ProcessTypes, ProjectStagesEnum } from '../../../shared';
import { UserService } from '../../../core/user/user.service';
import { ProjectService } from '../../services';
import { User } from 'src/app/core/user/interfaces';
import { Constants } from 'src/app/shared/constants';
import { GenericFunctions } from 'src/app/shared/services/generic-functions';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { IProject } from '../../models';
import { ProjectAssignmentsService } from '../../../shared/services/project-assignments.service';
import { ProjectAssignment } from '../../../edtp';
import {ProjectFlow} from "../../../shared/services/project.flow";

@Component({
  selector: 'app-assignment',
  templateUrl: './assignement.component.html',
  styleUrls: ['./assignement.component.scss'],
})
export class AssignmentComponent implements OnInit, OnDestroy {
  loading = false;
  _projects: IProject[] = [];
  _technicians: User[] = [];
  _storedAssignedProjects: IProject[] = [];
  assignedAndSelectedProjects: ProjectAssignment[] = [];
  assignableProjects: ProjectAssignment[] = [];
  resource = Constants.RESOURCES.ITCP_ASSIGNMENT;

  breadcrumbs: MenuItem[] = [
    { label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home'] },
    { label: ' ITCP', routerLink: ['/itcp'] },
    { label: ' Asignacion de Tecnicos' },
  ];

  @ViewChild('dtProjectTechnicians') tableProjectTechnicians!: Table;
  @ViewChild('dtSort') tableSort!: Table;
  private onDestroy$ = new Subject();
  public ref!: DynamicDialogRef;

  constructor(
    private readonly genericFunctions: GenericFunctions,
    private readonly toasterService: ToasterService,
    private readonly projectsService: ProjectService,
    private readonly userService: UserService,
    private readonly projectAssignmentsService: ProjectAssignmentsService
  ) {}

  get storedAssignedProjects() {
    return this._storedAssignedProjects;
  }

  set storedAssignedProjects(data) {
    this._storedAssignedProjects = data;
  }

  get technicians() {
    return this._technicians;
  }

  set technicians(users: User[]) {
    const shuffleTechnicians = _.shuffle(users);
    this._technicians = shuffleTechnicians;
  }

  get projects() {
    return this._projects;
  }

  set projects(projects: IProject[]) {
    this._projects = projects;
  }

  ngOnInit(): void {
    this.getAll();
    this.populateData();
  }

  getAll() {
    this.loading = true;
    this.projectsService
      .getAll({
        params: { assignments: true, status: ProjectFlow.itcpStatusesForAssigned() },
      })
      .then((projects) => {
        this.storedAssignedProjects = [...projects];
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => (this.loading = false));
  }

  populateData(): void {
    this.loading = true;
    Promise.all([
      this.projectsService.getAll({ params: { status: ProjectFlow.itcpStatusesForAssignments() } }),
      this.userService.getUsers(ProcessTypes.APROVE, [Constants.ROLES.TEC_FIS_PLAN]),
    ])
      .then(([projects, users]) => {
        this.technicians = users;
        this.projects = projects;
        this.match();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => (this.loading = false));
  }

  match() {
    const { projects, technicians } = this;
    const projectsLength = projects.length;
    const techniciansLength = technicians.length;
    let techniciansList = _.shuffle(technicians);
    if (projectsLength > techniciansLength || projectsLength == techniciansLength) {
      techniciansList = [
        ...techniciansList,
        ...this.takeElementsFromList(techniciansList, projectsLength - techniciansLength),
      ];
    } else {
      techniciansList = techniciansList.slice(0, projectsLength);
    }
    this.assignableProjects = this.projects.map((project, i) => ({
      id: i + 1,
      project,
      physicalTech: techniciansList[i],
    }));
  }

  sortData() {
    this.match();
  }

  submit() {
    this.loading = true;
    this.projectAssignmentsService
      .store(this.assignedAndSelectedProjects, ProjectStagesEnum.ITCP)
      .then((success) => {
        if (success) this.toasterService.success('Se asignaron correctamente los tecnicos a los proyectos');
      })
      .catch((e) => {
        this.toasterService.error('Hubo un error inesperado, por favor contacte a un administrador.');
      })
      .finally(() => {
        this.getAll();
        this.populateData();
        this.loading = false;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(undefined);
    this.onDestroy$.complete();
  }

  reload() {
    this.getAll();
    this.populateData();
  }

  getPhysicalTechnicianName(physicalTechnician: User): string {
    return GenericFunctions.getUserFullName(physicalTechnician);
  }

  onSelectionChange(value = []) {
    this.assignedAndSelectedProjects = [...value];
  }

  setFilter(event: any, type: string, query: string, column: string = ''): void {
    if (type === 'filterGlobal') {
      this.tableSort.filterGlobal(event.target.value, query);
    } else if (type === 'filter') {
      this.tableSort.filter(event.target.value, column, query);
    }
  }

  private takeElementsFromList(list: any[], quantity: number) {
    const returnList: any[] = [];
    for (let i = 0; i < quantity; i++) {
      const randomIndex = Math.floor(Math.random() * quantity);
      const selectedItem = list[randomIndex];
      returnList.push(selectedItem);
    }

    return returnList;
  }
}
