import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IProject, PROJECT_SCORES, ProjectService } from '../../../itcp';
import { Constants, DEFAULT_CONTENT_NOTIFICATION } from '../../../shared/constants';
import { Table } from 'primeng/table';
import { GenericFunctions } from '../../../shared/services/generic-functions';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ToasterService } from '../../../shared/services/toaster.service';
import { IAttachment, ProjectFlow, ProjectStagesEnum, ProjectStatusType } from '../../../shared';
import { OverlayPanel } from 'primeng/overlaypanel';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss']
})
export class RankingComponent implements OnInit {
  loading = false;
  projects: IProject[] = [];
  resource = Constants.RESOURCES.EDTP_RANKING;
  dialogVisible: boolean = false;
  textNotification: string = '';
  sending: boolean = false;
  defaultFiles: IAttachment[] = [];
  selectedProject: IProject = {};
  SCORES = PROJECT_SCORES;

  breadcrumbs: MenuItem[] = [
    { label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home'] },
    { label: ' ITCP', routerLink: ['/edtp'] },
    { label: ' Ranking de Proyectos' }
  ];

  @ViewChild('dt') table!: Table;
  @ViewChild('preview') previewPanel!: OverlayPanel;
  @ViewChild('contentTable') contentTable!: ElementRef;

  constructor(
    private readonly genericFunctions: GenericFunctions,
    public readonly dialogService: DialogService,
    public readonly confirmationService: ConfirmationService,
    private readonly toasterService: ToasterService,
    private readonly service: ProjectService,
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.getAll();
    this.textNotification = DEFAULT_CONTENT_NOTIFICATION;
  }

  getAll() {
    this.loading = true;
    this.service
      .getAll({
        params: {
          stage: [ProjectStagesEnum.EDTP, ProjectStagesEnum.TRACKING],
          status: ProjectFlow.edtpStatusesForRanking()
        }
      })
      .then((resp) => {
        this.projects = [...resp];
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => (this.loading = false));
  }

  setFilter(event: any, type: string, query: string, column: string = ''): void {
    if (type === 'filterGlobal') {
      this.table.filterGlobal(event.target.value, query);
    } else if (type === 'filter') {
      this.table.filter(event.target.value, column, query);
    }
  }

  notifyProject(project: IProject) {
    const id = project.id || 0;
    if (!project.edtpNotificationFileId) {
      this.toasterService.error('Se ha producido un error, por favor intentelo mas tarde');
      return;
    }
    this.sending = true;
    this.service.notifyV2(id, {
      projectId: id,
      content: this.textNotification
    })
      .then(() => {
        this.toasterService.success('La Notificación fué enviada satisfactoriamente.');
      })
      .catch((e) => {
        console.error(e.error.message);
        this.toasterService.error('Se ha producido un error, por favor intentelo mas tarde');
      })
      .finally(() => {
        this.sending = false;
        this.dialogVisible = false;
      });
  }

  approveRejectProject(project: IProject, approve: boolean) {
    const status = approve ? Constants.PROJECT_STATUSES.APPROVED : Constants.PROJECT_STATUSES.REJECTED;
    const formGroup = this.fb.group({
      id: [project.id],
      name: [project.name, Validators.required],
      status: [status, Validators.required]
    });

    const postData: any = formGroup.value;

    if (!formGroup.value.id) return;
    this.service
      .update(postData, postData.id)
      .then((resp: IProject) => {
        project.status = status;
        this.toasterService.success('Los datos del Proyecto fueron actualizados con éxito');
      })
      .catch((err) => {
        console.error(err.error.message);
        this.toasterService.error('Se ha producido un error inténtelo más tarde o contáctese con el Administrador.');
      }).finally(() => {
      project.status = status;
    });
  }

  approved(status: ProjectStatusType) {
    return [
      Constants.PROJECT_STATUSES.APPROVED,
      Constants.PROJECT_STATUSES.ASSIGNED_TRACKING,
      ...ProjectFlow.trackingStatusesForAssigned(),
      ...ProjectFlow.trackingStatusesForProgrammer()
    ].includes(status);
  }

  rejected(status: ProjectStatusType) {
    return Constants.PROJECT_STATUSES.REJECTED === status;
  }

  onUploaded(data: any, project: IProject) {
    const { files } = data;
    if (files.length) {
      project.edtpNotificationFileId = files[0].id;
      project.edtpNotificationFile = files[0];

      const formGroup = this.fb.group({
        id: [project.id],
        name: [project.name, Validators.required],
        edtpNotificationFileId: [files[0].id, Validators.required]
      });
      const postData: any = formGroup.value;
      this.service.update(postData, postData.id).then();
    }
  }

  getProjectForm(project: IProject) {
    return this.fb.group({
      id: [project.id, Validators.required],
      name: [project.name, Validators.required],
      edtpNotificationFileId: [project.edtpNotificationFileId, Validators.required]
    });
  }

  openDialogModal(project: IProject) {
    this.selectedProject = project;
    this.dialogVisible = true;
  }

  wasSentToTracking(status: ProjectStatusType) {
    return [
      Constants.PROJECT_STATUSES.ASSIGNED_TRACKING,
      ...ProjectFlow.trackingStatusesForAssigned(),
      ...ProjectFlow.trackingStatusesForProgrammer()
    ].includes(status);
  }

}
