export interface INeighborhood {
    gid: number;
    objectid_1: number;
    objectid: number;
    dep: string;
    prov: string;
    mun: string;
    cod_dep: string;
    cod_prov:string;
    cod_mun: string;
    nom_bar: string;
    cod_bar: number;
    shape_leng: number;
    geom: any;
}
