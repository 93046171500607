import {Component, OnInit, ViewChild} from '@angular/core';
import {Constants, PROJECT_READ_ONLY} from "../../../shared/constants";
import {IProject, IProjectAssignment, ProjectService} from "../../../itcp";
import {MenuItem} from "primeng/api";
import {Table} from "primeng/table";
import {User} from "../../../core/user/interfaces";
import {GenericFunctions} from "../../../shared/services/generic-functions";
import {CredentialsService, CryptoService, ProjectFlow} from "../../../shared";
import {PermissionService} from "../../../shared/services/permission.service";
import {Router} from "@angular/router";
import {ToasterService} from "../../../shared/services/toaster.service";
import {LayoutService} from "../../../shared/services/app.layout.service";

@Component({
    selector: 'app-formulation',
    templateUrl: './formulation.component.html',
    styleUrls: ['./formulation.component.scss']
})
export class FormulationComponent implements OnInit {
    resource = Constants.RESOURCES.EDTP_FORM;
    projects: IProject[] = []; //Assigned projects

    loading: boolean = false;
    breadcrumbs: MenuItem[] = [
        { label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home'] },
        { label: ' EDTP', routerLink: ['/edtp'] },
        { label: ' Formulación del EDTP' },
    ];
    @ViewChild('dt') table!: Table;

    constructor(
        private readonly credentialsService: CredentialsService,
        private readonly permissionService: PermissionService,
        private readonly projectService: ProjectService,
        private readonly router: Router,
        private readonly toasterService: ToasterService,
        private readonly cryptoService: CryptoService,
        private readonly layoutService: LayoutService,
    ) {}

    ngOnInit(): void {
        this.layoutService.showMenuDesktop(true);
        this.getAll();
    }

    getAll(): void {
        this.loading = true;
        this.projectService.getAll({ params: { assignments: true, status: ProjectFlow.edtpStatusesForFormulate()}})
            .then((resp) => {
                this.projects = [...resp];
            })
            .finally(() => {
                this.loading = false;
            });
    }

    setFilter(event: any, type: string, query: string, column: string = ''): void {
        if (type === 'filterGlobal') {
            this.table.filterGlobal(event.target.value, query);
        } else if (type === 'filter') {
            this.table.filter(event.target.value, column, query);
        }
    }

    generateEDTPProject(projectId: number, readOnly = true) {
        sessionStorage.setItem(PROJECT_READ_ONLY.FORMULATE, this.cryptoService.encrypt(true));
        if (!readOnly) {
            sessionStorage.setItem(PROJECT_READ_ONLY.FORMULATE, this.cryptoService.encrypt(false));
        }
        this.projectService.getEDTPByReferenceId(projectId).then((resp) => {
            if (resp) {
                const route = `/edtp/projects/${resp.id}/goals`;
                this.router.navigate([route]).then();
            } else {
                this.projectService.generateEDTP(projectId).then((resp) => {
                    const route = `/edtp/projects/${resp?.id}/goals`;
                    this.router.navigate([route]).then();
                });
            }
        }).catch((error) => {
            console.log(error);
            this.toasterService.error('Se ha producido un error inténtelo más tarde o contáctese con el Administrador.');
        })

    }

    getFullName(user: User): string {
        return GenericFunctions.getUserFullName(user);
    }

    getRespFormulation(assignments: IProjectAssignment[]): string {
        const permissions = assignments.map(a => a.permissions);
        return permissions.includes(Constants.TECHNICIAN_PERMISSIONS.FORMULATE) ? 'Fonabosque' : 'Entidad Solicitante';
    }

    canFormulate(project: IProject) {
        if (this.permissionService.isSuperAdminRole()) return true;
        if (project.status === Constants.PROJECT_STATUSES.CONSOLIDATED_SUBMITTED && this.permissionService.isRequestingEntityRole()) return false;
        else if (this.permissionService.isRequestingEntityRole()) return true;

        const assignments = project.technicians || [];
        const currentUser: User = this.credentialsService.getLoggedUser();
        const assigment = assignments.find(a => a.userId === currentUser.id);
        if (!assigment) return false;

        return [Constants.TECHNICIAN_PERMISSIONS.FORMULATE, Constants.TECHNICIAN_PERMISSIONS.REVIEW_EVAL].includes(assigment.permissions)
    }

    canReview(project: IProject) {
        if (this.permissionService.isSuperAdminRole()) return true;

        if (project.status === Constants.PROJECT_STATUSES.CONSOLIDATED_SUBMITTED && this.permissionService.isRequestingEntityRole()) return true;
        else if (this.permissionService.isRequestingEntityRole()) return false;

        const assignments = project.technicians || [];
        const currentUser: User = this.credentialsService.getLoggedUser();
        const assigment = assignments.find(a => a.userId === currentUser.id);
        if (!assigment) return false;

        return [Constants.TECHNICIAN_PERMISSIONS.REVIEW_EVAL, Constants.TECHNICIAN_PERMISSIONS.REVIEW].includes(assigment.permissions)
    }


}
