import { AssignmentComponent } from './components/assignment/assignment.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AnnouncementComponent,
  ITCPRequestComponent,
  RequestModalComponent,
  GenerateUserComponent,
  ProjectComponent,
  BasicInfoComponent,
  JustifyComponent,
  IdeaComponent
} from './components';
import { ITCPRoutingModule } from './itcp-routing.module';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ToolbarModule } from 'primeng/toolbar';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DirectiveModule } from '../shared/directives/directive.module';
import { ModalComponent } from './components/announcement/modal/modal.component';
import { ModalComponent as RankingModalComponent } from './components/ranking/modal/modal.component';
import { CustomFormModule } from '../shared';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MessageModule } from 'primeng/message';
import { PublicModule } from '../public/public.module';
import { DropdownModule } from 'primeng/dropdown';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { EditorModule } from 'primeng/editor';
import {
  GoalsComponent,
  AdvantageComponent,
  DealsComponent,
  PropertyComponent,
  EnvironmentComponent,
  RisksComponent,
  OthersComponent,
  BudgetComponent,
  ConclusionsComponent,
  PdfComponent,
  SwornComponent,
  CoordinatesModalComponent,
  DoneComponent
} from './components';
import { AutoFocusModule } from 'primeng/autofocus';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CalendarModule } from 'primeng/calendar';
import { BadgeModule } from 'primeng/badge';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { RippleModule } from 'primeng/ripple';
import { ProjectAssessmentComponent } from './components/project-assessment/project-assessment.component';
import { RequirementComponent } from './components/project-assessment/requirement/requirement.component';
import { AssessmentComponent } from './components/project-assessment/assessment/assessment.component';
import { RankingComponent } from './components/ranking/ranking.component';
import { SkeletonModule } from 'primeng/skeleton';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { ProgressBarModule } from 'primeng/progressbar';
import { DialogModule } from 'primeng/dialog';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { InputNumberModule } from 'primeng/inputnumber';
import {ToastModule} from "primeng/toast";
import {FileUploadModule} from "primeng/fileupload";

@NgModule({
  declarations: [
    AnnouncementComponent,
    ModalComponent,
    RankingModalComponent,
    ITCPRequestComponent,
    RequestModalComponent,
    GenerateUserComponent,
    ProjectComponent,
    AssignmentComponent,
    BasicInfoComponent,
    JustifyComponent,
    IdeaComponent,
    GoalsComponent,
    AdvantageComponent,
    DealsComponent,
    PropertyComponent,
    EnvironmentComponent,
    RisksComponent,
    OthersComponent,
    BudgetComponent,
    ConclusionsComponent,
    SwornComponent,
    CoordinatesModalComponent,
    DoneComponent,
    PdfComponent,
    ProjectAssessmentComponent,
    ProjectComponent,
    RequirementComponent,
    AssessmentComponent,
    RankingComponent
  ],
    imports: [
        CommonModule,
        ITCPRoutingModule,
        BreadcrumbModule,
        TableModule,
        InputTextModule,
        ButtonModule,
        ToolbarModule,
        DynamicDialogModule,
        FormsModule,
        ReactiveFormsModule,
        SkeletonModule,
        TabViewModule,
        TooltipModule,
        DirectiveModule,
        CustomFormModule,
        CheckboxModule,
        InputSwitchModule,
        MessageModule,
        DropdownModule,
        SelectButtonModule,
        InputTextareaModule,
        RadioButtonModule,
        NgMultiSelectDropDownModule.forRoot(),
        PublicModule,
        AutoFocusModule,
        PdfViewerModule,
        CalendarModule,
        BadgeModule,
        AutoCompleteModule,
        RippleModule,
        ProgressBarModule,
        DialogModule,
        ClipboardModule,
        EditorModule,
        InputNumberModule,
        ToastModule,
        FileUploadModule
    ]
})
export class ITCPModule {
}
