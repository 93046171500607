<p-skeleton *ngIf="!showContent" width="100%" height="150px" ></p-skeleton>

<div class="flex w-full flex-column min-h-screen justify-content-center align-items-center">
    <i class="pi pi-exclamation-circle"></i>
    <p *ngIf="showContent">
        ¡Esta Convocatoria ya no se encuentra disponible!
    </p>

    <p *ngIf="!showContent">
        Cargando.......
    </p>

    <a
        href="https://www.fonabosque.gob.bo"
        class="p-button p-button-primary"
    >
        Ir a la Web de FonaBosque
    </a>
</div>
