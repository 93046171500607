import { AfterViewInit, OnDestroy, Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-draw';
import drawLocales from 'leaflet-draw-locales';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CartographyService, ICity, INeighborhood, IProvince, IStreet, ITown } from '../../../../../shared';
import { FeatureGroup, GeoJSON } from 'leaflet';
import proj4 from 'proj4';
import * as shp from 'shpjs';
import { Observable } from 'rxjs';

drawLocales('es');
export type CoordinatesModalType = {
    geoIds?: number[];
    enablePolygon?: boolean; //if true then can draw polygons
    multi?: boolean; //if true then can select multiple points or polygons
};

export type GeometricType = {
    type: string;
    coordinates: any[];
};

export type CoordinatesTable = 'CITY' | 'PROVINCE' | 'TOWN' | 'NEIGHBORHOOD' | 'STREET';

export const GreenIcon: any = L.icon({
    iconUrl: 'https://leafletjs.com/examples/custom-icons/leaf-green.png',
    shadowUrl: 'https://leafletjs.com/examples/custom-icons/leaf-shadow.png',

    iconSize: [38, 95], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62], // the same for the shadow
    popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
});

interface CustomLayer extends L.Layer {
    geoId?: number;
}

@Component({
    selector: 'app-coordinates-modal',
    templateUrl: './coordinates-modal.component.html',
    styleUrls: ['./coordinates-modal.component.scss'],
})
export class CoordinatesModalComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() public data!: CoordinatesModalType;
    private map: any;
    private drawFullControl: any;
    private drawEditControl: any;
    private drawItems: any;
    features: FeatureGroup = new FeatureGroup<any>();
    geomIds: number[] = [];
    enablePolygon!: boolean;
    multi!: boolean;
    public showContent: boolean = false;
    public cities: ICity[] = [];
    public provinces: IProvince[] = [];
    public towns: ITown[] = [];
    public neighborhoods: INeighborhood[] = [];
    public streets: IStreet[] = [];
    public loading = false;
    coordinatesUTM = { x: undefined, y: undefined, zone: undefined, hemisphere: 'S' };

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public readonly cartographyService: CartographyService
    ) {}

    private initMap(): void {
        this.map = L.map('map', {
            center: [-16.2837065, -63.5493965],
            zoom: 6,
        });

        const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        });
        tiles.addTo(this.map);
        this.drawItems = new L.FeatureGroup();
        this.map.addLayer(this.drawItems);
        let drawOptions: any = {
            //rectangle: <any>{ showArea: false }, // disable showArea
            rectangle: false,
            circle: false,
            polyline: false,
            marker: {
                icon: GreenIcon,
            },
        };

        if (!this.enablePolygon) {
            drawOptions = {
                ...drawOptions,
                polygon: false,
            };
        }
        this.buildGeos();
        this.drawFullControl = new L.Control.Draw({
            draw: drawOptions,
            edit: {
                featureGroup: this.drawItems,
            },
        });

        this.drawEditControl = new L.Control.Draw({
            // @ts-ignore
            draw: false,
            edit: {
                featureGroup: this.drawItems,
            },
        });
        this.map.addControl(this.drawFullControl);
        this.addEvents();
    }

    buildGeos() {
        const me = this;
        const { geoIds } = this.config.data || {};
        if (!geoIds?.length) return;
        let center: any = [-16.2837065, -63.5493965];
        let zoom = 8;
        this.cartographyService.getGeoLocations({ params: { id: geoIds } }).then((result) => {
            result?.map((r: any) => {
                const geoId = r.id;
                this.geomIds.push(r.id);
                const geo = JSON.parse(r.geom);
                if (geo.type === 'Point') {
                    const layers = L.geoJSON(geo, {
                        pointToLayer: function (feature, latlng) {
                            return L.marker(latlng, {
                                icon: GreenIcon,
                            });
                        },
                    });
                    layers.eachLayer(function (l) {
                        const layer = l as CustomLayer;
                        layer.geoId = geoId;
                        me.drawItems.addLayer(layer);
                    });
                    layers.addTo(this.map);
                    center = geo.coordinates.reverse();
                } else if (geo.type === 'Polygon') {
                    const layers = L.geoJSON(geo);
                    const limits = layers.getBounds();
                    center = limits.getCenter();
                    zoom = me.map.getBoundsZoom(limits);
                    layers.eachLayer(function (l) {
                        const layer = l as CustomLayer;
                        layer.geoId = geoId;
                        me.drawItems.addLayer(layer);
                    });

                    layers.addTo(this.map);
                }
            });
            this.map.flyTo(center, zoom);
        });
    }

    ngOnInit() {
        this.multi = this.config.data.multi || false;
        this.enablePolygon = this.config.data.enablePolygon || false;

        this.cartographyService
            .getCities()
            .then((resp) => {
                this.cities = [...resp];
                return this.cartographyService.getProvinces();
            })
            .then((resp) => {
                this.provinces = [...resp];
                return this.cartographyService.getTowns();
            })
            .then((resp) => {
                this.towns = [...resp];
                return this.cartographyService.getNeighborhoods();
            })
            .then((resp) => {
                this.neighborhoods = [...resp];
                return this.cartographyService.getStreets();
            })
            .then((resp) => {
                this.streets = [...resp];
            })
            .finally(() => {
                this.showContent = true;
            });
    }

    ngAfterViewInit(): void {
        this.initMap();
    }

    addEvents() {
        const me = this;
        this.map.on(L.Draw.Event.CREATED, function (e: any) {
            const type = e.layerType,
                layer = e.layer;
            me.drawItems.addLayer(layer);
            me.features.addLayer(layer);

            if (!me.multi) {
                me.drawFullControl.remove();
                me.map.addControl(me.drawEditControl);
            }
        });

        me.map.on(L.Draw.Event.EDITED, function (e: any) {
            const layers = e.layers;
            layers.eachLayer(function (layer: any) {
                me.cartographyService.updateGeometry(layer.toGeoJSON(), layer.geoId).then();
            });
        });

        me.map.on(L.Draw.Event.DELETED, function (e: any) {
            const layers = e.layers;
            layers.eachLayer(function (layer: any) {
                me.geomIds = me.geomIds.filter((g) => g !== layer.geoId);
            });

            if (!me.multi) {
                me.drawEditControl.remove();
                me.map.addControl(me.drawFullControl);
            }
        });
    }

    buildLayerFromGeoJson(geom: any, addToFeatures = true) {
        if (!geom) return;

        const layer = L.geoJSON(geom, { style: this.getRandomStyle() });
        const limits = layer.getBounds();
        const center = limits.getCenter();
        const zoom = this.map.getBoundsZoom(limits);
        this.map.flyTo(center, zoom);
        layer.addTo(this.map);
        if (addToFeatures) this.features.addLayer(layer);
    }

    getRandomStyle() {
        return {
            color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
            weight: 2,
            opacity: 0.7,
        };
    }

    filterCities(event: any) {
        this.cartographyService.getCities({ params: { query: event.query } }).then((resp) => {
            this.cities = [...resp];
        });
    }

    filterProvinces(event: any) {
        this.cartographyService.getProvinces({ params: { query: event.query } }).then((resp) => {
            this.provinces = [...resp];
        });
    }

    filterTowns(event: any) {
        this.cartographyService.getTowns({ params: { query: event.query } }).then((resp) => {
            this.towns = [...resp];
        });
    }

    filterNeighborhoods(event: any) {
        this.cartographyService.getNeighborhoods({ params: { query: event.query } }).then((resp) => {
            this.neighborhoods = [...resp];
        });
    }

    filterStreets(event: any) {
        this.cartographyService.getStreets({ params: { query: event.query } }).then((resp) => {
            this.streets = [...resp];
        });
    }

    onSelectGeo(event: any, table: CoordinatesTable) {
        this.cartographyService.findCoordinates(event.gid, table).then((resp) => {
            this.buildLayerFromGeoJson(resp, table !== 'CITY');
        });
    }

    saveGeoLocation() {
        this.cartographyService.storeGeometry(this.features.toGeoJSON()).then((resp) => {
            if (resp) {
                this.geomIds = [...this.geomIds, ...resp];
                this.closeModal();
            }
        });
    }

    findUTM(event: any) {
        const { x, y, zone, hemisphere } = this.coordinatesUTM;
        if (!(x && y && zone)) return;

        const h = hemisphere === 'S' ? '+south' : '';
        proj4.defs(
            'EPSG:327' + zone + hemisphere,
            '+proj=utm +zone=' + zone + h + ' +ellps=WGS84 +datum=WGS84 +units=m +no_defs'
        );
        proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');

        const utmCoords = [x, y];
        const lonLatCoords = proj4('EPSG:327' + zone + hemisphere, 'EPSG:4326', utmCoords);

        const lat = lonLatCoords[1];
        const lng = lonLatCoords[0];
        const marker = L.marker([lat, lng], { icon: GreenIcon });
        this.map.flyTo([lat, lng], 12);
        this.features.addLayer(marker);
        marker.addTo(this.map);
    }

    onUpload(event: any) {
        this.SHPtoGEOJSON(event.files[0]).then();
    }

    async SHPtoGEOJSON(file: File) {
        const me = this;
        await this.readFileContent(file)
            .toPromise()
            .then((res: any) => {
                shp(res).then(function (geojson) {
                    me.buildLayerFromGeoJson(geojson, true);
                });
            });
    }

    readFileContent(file: File) {
        let fileReader: FileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        return Observable.create((observer: any) => {
            fileReader.onloadend = () => {
                observer.next(fileReader.result);
                observer.complete();
            };
        });
    }

    ngOnDestroy() {
        this.ref.close(this.geomIds);
    }

    closeModal(): void {
        this.ngOnDestroy();
    }
}
