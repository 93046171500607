<app-wizard
  [steps]="steps"
  [current]="currentStep"
  [bodyContentTpl]="projectForm"
  [nextDisabled]="this.formGroup?.invalid"
  (onClickNext)="save($event)"
  [layout]="true"
>
</app-wizard>

<ng-template #projectForm>
  <div *ngIf="!showContent" class="grid bg-white" style="min-height: calc(100vh - 150px)">
    <div class="field col-12">
      <p-skeleton height="100%" styleClass="mb-2"></p-skeleton>
    </div>
  </div>
  <div *ngIf="showContent" class="project-form flex justify-content-center flex-column" [formGroup]="formGroup">
    <div class="grid">
      <div class="field col-12 text-2xl text-primary uppercase">
        {{ currentStep?.text }}
      </div>

      <div class="field col-12">
        <button
          [disabled]="isSubmitted"
          class="p-button p-button-success"
          pButton
          pTooltip="Agregar"
          tooltipPosition="top"
          (click)="onNew()"
        >
          <i class="pi pi-plus"></i>
        </button>

        <p-table
          #dt
          [value]="c2t4Items"
          dataKey="id"
          editMode="row"
          styleClass="p-datatable-gridlines"
          [tableStyle]="{ 'min-width': '100%' }"
        >
          <ng-template pTemplate="header">
            <tr>
              <th class="bg-primary" style="width: 250px">Capacitacion</th>
              <th class="bg-primary text-center" colspan="2">
                <tr>
                  <td class="text-center" colspan="2">Nro de Capacitados</td>
                </tr>
                <tr>
                  <td>Hombres</td>
                  <td>Mujeres</td>
                </tr>
              </th>
              <th class="bg-primary text-center" colspan="2">
                <tr>
                  <td class="text-center" colspan="2">Nro de Asistidos Tecnicamente</td>
                </tr>
                <tr>
                  <td>Hombres</td>
                  <td>Mujeres</td>
                </tr>
              </th>
              <th class="bg-primary text-center" colspan="2">
                <tr>
                  <td class="text-center" colspan="2">Nro de beneficiarios directos</td>
                </tr>
                <tr>
                  <td>Hombres</td>
                  <td>Mujeres</td>
                </tr>
              </th>
              <th class="bg-primary text-center" colspan="2">
                <tr>
                  <td class="text-center" colspan="2">Nro de beneficiarios indirectos</td>
                </tr>
                <tr>
                  <td>Hombres</td>
                  <td>Mujeres</td>
                </tr>
              </th>
              <th style="width: 10%" class="bg-primary"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-obj let-editing="editing" let-ri="rowindex">
            <tr [pEditableRow]="obj">
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="text"
                      [(ngModel)]="obj.workArea"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.workArea }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      min='0'
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      [(ngModel)]="obj.trainedMen"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.trainedMen }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      min='0'
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      [(ngModel)]="obj.trainedWomen"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.trainedWomen }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      min='0'
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      [(ngModel)]="obj.assistedMen"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.assistedMen }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      min='0'
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      [(ngModel)]="obj.assistedWomen"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.assistedWomen }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      min='0'
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      [(ngModel)]="obj.directlyBenefitedMen"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.directlyBenefitedMen }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      min='0'
                      [(ngModel)]="obj.directlyBenefitedWomen"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.directlyBenefitedWomen }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      min='0'
                      [(ngModel)]="obj.indirectlyBenefitedMen"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.indirectlyBenefitedMen }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      min='0'
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      [(ngModel)]="obj.indirectlyBenefitedWomen"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.indirectlyBenefitedWomen }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <div *ngIf="!isSubmitted" class="flex align-items-center justify-content-center gap-2">
                  <button
                    *ngIf="!editing"
                    pButton
                    pRipple
                    type="button"
                    pInitEditableRow
                    icon="pi pi-pencil"
                    (click)="onRowEditInit(obj)"
                    class="p-button-rounded p-button-text"
                  ></button>
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pSaveEditableRow
                    icon="pi pi-check"
                    (click)="onRowEditSave(obj)"
                    class="p-button-rounded p-button-text p-button-success mr-2"
                  ></button>
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pCancelEditableRow
                    icon="pi pi-times"
                    (click)="onRowEditCancel(obj, ri)"
                    class="p-button-rounded p-button-text p-button-danger"
                  ></button>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</ng-template>
