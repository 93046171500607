import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from 'src/app/shared/services';
import {IAnnouncement} from "../models";
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService extends BaseService<IAnnouncement> {

    constructor(private readonly httpService: BaseHttpService) {
        super(httpService, 'announcements');
    }

    async getByCode(code: string): Promise<IAnnouncement> {
        return lastValueFrom(this._baseHttpService.get(`${this.namespace}/code/${code}`));
    }
}
