import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnnouncementService, ITCPRequestService } from '../../../itcp';
import * as dayjs from 'dayjs';

@Component({
    selector: 'app-itcp-request',
    templateUrl: './itcp-request.component.html',
    styleUrls: ['./itcp-request.component.scss']
})
export class ITCPRequestComponent implements OnInit, OnDestroy {
    private sub: any;
    private code: string = '';
    public showContent: boolean = false;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private service: ITCPRequestService,
        private announcementService: AnnouncementService,
    ) {}

    ngOnInit(): void {
        this.sub = this.route.params.subscribe(params => {
            this.code = params['code'];
        });

        this.buildITCPRequest();
    }

    buildITCPRequest() {
        this.announcementService.getByCode(this.code).then((announcement) => {
            const dueDate = dayjs(announcement.dueDate).toDate();
            const today = dayjs().toDate();
            if (announcement.published && dueDate > today) {//If available
                this.service.store({ announcementCode: this.code, announcementId: announcement.id }).then((itcpRequest) => {
                    if (itcpRequest.token) {
                        this.router.navigate(['/public/itcp-request', itcpRequest.token, 'basic-info']);
                    } else {
                        this.showContent = true;
                    }
                });
            } else {
                this.showContent = true;
            }
        });
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

}
