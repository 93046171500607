<div class='w-full bg-gray-200'>
    <div *ngIf="!editMode" class='flex flex-column gap-2 justify-content-start'>
        <div class="w-full  px-4 py-1">
            <span class="font-bold">{{group.goal?.description}}</span>
            <div class="text-sm">{{group.goal?.component?.name}}</div>
            <div class="text-xs">{{group.goal?.component?.description}}</div>
        </div>
        <div class="flex gap-4 justify-content-start align-items-center bg-gray-100">
            <div class="px-6 py-1">
                <b>Resultado Esperado:</b>
                <div>{{group.expectedResult}}</div>
            </div>
        </div>
    </div>
</div>

<p-table
    [value]="group.activities"
    dataKey="id"
    editMode="row"
    [tableStyle]="{'min-width': '100%'}"
    #dt>
    <ng-template pTemplate="header">
        <tr>
            <th class="bg-secondary border-x-1 text-center p-0" style="min-width:60px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="min-width:300px; max-width: 300px;"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="min-width: 115px;"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="min-width: 120px;"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="min-width: 150px;"></th>
            <th class="bg-secondary p-0" style="width:100px"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-obj let-editing="editing" let-index="rowIndex">
        <tr [pEditableRow]="obj" class="bg-gray-100">
            <td colspan='100%' class='p-0 m-0'>
                <app-eval-activity-budgets
                    [index]="index"
                    [activity]='obj'
                    [editMode]='false'
                    [totalProjectDays]="totalProjectDays"
                    [selectedYear]="selectedYear"
                    (onEdit)='onRowEditInit($event)'
                    (onRemove)="removeGroup()"
                ></app-eval-activity-budgets>
            </td>
        </tr>
    </ng-template>
</p-table>
