import { Component, Input, OnInit, Output, TemplateRef, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { IWizardStep } from '../../models';
import { Router } from '@angular/router';

@Component({
    selector: 'app-wizard',
    templateUrl: './wizard.component.html',
    styleUrls: ['./wizard.component.scss'],
})
export class WizardComponent implements OnInit, OnChanges {
    @Input() previousRoute: any | undefined;
    @Input() nextRoute: any | undefined;
    @Input() nextDisabled: boolean | undefined;
    @Input() previousDisabled: boolean | undefined;
    @Input() steps: IWizardStep[] = [];
    @Input() current: IWizardStep | undefined;
    @Input() bodyContentTpl: TemplateRef<any> | undefined;
    @Input() stepsSize: number = 4;
    @Input() layout: boolean = false;
    @Input() lastStep: boolean = false;

    @Output() onClickPrevious = new EventEmitter<any>();
    @Output() onClickNext = new EventEmitter<any>();
    @Output() onFinish = new EventEmitter<any>();
    showRightBar = true;

    constructor(private readonly router: Router) {}

    ngOnInit(): void {
        // this.setRoutes();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.setRoutes(true);
    }

    setRoutes(force = false) {
        if (!this.current) return;

        const index = this.steps.indexOf(this.current);
        const previous: IWizardStep = this.steps[index - 1];
        const next: IWizardStep = this.steps[index + 1];

        if (previous && (!this.previousRoute || force)) this.previousRoute = previous.path;

        if (next && (!this.nextRoute || force)) this.nextRoute = next.path;
    }

    onNext(data: any) {
        if (data) this.onClickNext.emit(data);
        this.router.navigate([this.nextRoute]).then();
    }

    onPrevious(data: any) {
        this.router.navigate([this.previousRoute]).then();
    }

    getDisplaySteps(): IWizardStep[] {
        if (!this.steps.length) return [];

        if (!this.current) return this.steps.slice(0, this.stepsSize);

        const index = this.steps.indexOf(this.current);

        if (index < this.stepsSize) return this.steps.slice(0, this.stepsSize);

        const fromIndex = index - 1 < 0 ? 0 : index - 1;
        const toIndex = fromIndex + this.stepsSize;

        if (toIndex < this.steps.length - 1) return this.steps.slice(fromIndex, toIndex);

        return this.steps.slice(-this.stepsSize);
    }
}
