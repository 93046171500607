import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from '../../shared';
import { IProjectC3 } from '../models/interfaces/project-c3.interface';

@Injectable({
  providedIn: 'root',
})
export class ProjectC3Service extends BaseService<IProjectC3> {
  constructor(private readonly httpService: BaseHttpService) {
    super(httpService, 'project-c3');
  }
}
