<app-wizard
    [steps]='steps'
    [stepsSize]="5"
    [current]='currentStep'
    [bodyContentTpl]='projectForm'
    [nextDisabled]='this.formGroup?.invalid'
    (onClickNext)='save($event)'
    [layout]='true'
>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column' [formGroup]='formGroup'>
        <div class='grid'>
            <div class="field col-12 text-2xl text-primary uppercase">
                {{currentStep?.text}}
            </div>

            <div class="field col-12 pb-0 mb-0">
                <p-table
                    [value]="results"
                    dataKey="id" editMode="row"
                    [loading]="loading"
                    [tableStyle]="{'min-width': '100%'}" #dt>
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="bg-primary border-x-1 text-center" rowspan="2" style="width:60px">NRO.</th>
                            <th class="bg-primary border-x-1 text-center" rowspan="2" style="min-width:300px; max-width:300px">PARTIDA PRESUPUESTARIA</th>
                            <th class="bg-primary border-x-1 text-center" rowspan="2" style="min-width:200px; max-width:200px">DETALLE</th>
                            <th class="bg-primary border-x-1 text-center" colspan="5">PRESUPUESTO GENERAL</th>
                            <th class="bg-primary border-x-1 text-center" colspan="5">
                                PRESUPUESTO<br>
                                <p-dropdown
                                    [options]="selectableProjectYears"
                                    optionLabel="label"
                                    optionValue="id"
                                    [(ngModel)]="selectedProjectYear"
                                    [ngModelOptions]="{ standalone: true }"
                                    (onChange)="setSelectedYear($event)"
                                >
                                </p-dropdown>
                            </th>
                            <th class="bg-primary" rowspan="2" style="width:100px"></th>
                        </tr>
                        <tr>
                            <th class="bg-primary border-x-1 text-center"  scope="colgroup" style="min-width:115px; max-width: 115px;">FONABOSQUE</th>
                            <th class="bg-primary border-x-1 text-center"  scope="colgroup" style="min-width:115px; max-width: 115px;">MUNICIPIO ESPECIE</th>
                            <th class="bg-primary border-x-1 text-center"  scope="colgroup" style="min-width:115px; max-width: 115px;">MUNICIPIO EFECTIVO</th>
                            <th class="bg-primary border-x-1 text-center"  scope="colgroup" style="min-width:115px; max-width: 115px;">TOTAL MUNICIPIO</th>
                            <th class="bg-primary border-x-1 text-center"  scope="colgroup" style="min-width:115px; max-width: 115px;">TOTAL</th>
                            <th class="bg-primary border-x-1 text-center"  scope="colgroup" style="min-width:115px; max-width: 115px;">FONABOSQUE</th>
                            <th class="bg-primary border-x-1 text-center"  scope="colgroup" style="min-width:115px; max-width: 115px;">MUNICIPIO ESPECIE</th>
                            <th class="bg-primary border-x-1 text-center"  scope="colgroup" style="min-width:115px; max-width: 115px;">MUNICIPIO EFECTIVO</th>
                            <th class="bg-primary border-x-1 text-center"  scope="colgroup" style="min-width:115px; max-width: 115px;">TOTAL MUNICIPIO</th>
                            <th class="bg-primary border-x-1 text-center"  scope="colgroup" style="min-width:115px; max-width: 115px;">TOTAL</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-obj let-editing="editing" let-index="rowIndex">
                        <tr [pEditableRow]="obj">
                            <td colspan='100%' class='p-0 m-0'>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <app-result-fn-activities
                                            [group]='obj'
                                            [goals]="goals"
                                            [canProgram]="!programmerReadOnly()"
                                            [selectedYear]="selectedProjectYear"
                                        ></app-result-fn-activities>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <app-result-fn-activities
                                            [group]='obj'
                                            [canProgram]="!programmerReadOnly()"
                                            [totalProjectDays]='totalProjectDays'
                                            [selectedYear]="selectedProjectYear"
                                        ></app-result-fn-activities>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</ng-template>

