import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../../itcp';
import { CryptoService, SettingsService } from '../../../../shared';
import { FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { IProjectGoalResult, ProjectGoalResultService } from '../../../../edtp';
import { LayoutService } from '../../../../shared/services/app.layout.service';
import { Constants } from '../../../../shared/constants';
import { ProjectEvaluationModel } from '../../../models/project-evaluation.model';

@Component({
    selector: 'app-monitoring-physical',
    templateUrl: './report-physical.component.html',
    styleUrls: ['./report-physical.component.scss'],
})
export class ReportPhysicalComponent extends ProjectEvaluationModel implements OnInit, OnDestroy {
    private step: number = 1;

    results: IProjectGoalResult[] = [];

    @ViewChild('dt') table!: Table;

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly goalResultService: ProjectGoalResultService,
        private readonly setService: SettingsService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private readonly layoutService: LayoutService,
        private crypto: CryptoService
    ) {
        super(activatedRoute, projectService, tService, setService, crypto);
    }

    ngOnInit(): void {
        this.layoutService.showMenuDesktop(false);
        this.readParams();
        this.setActive(this.step);
        this.service
            .get(this.params['id'])
            .then((project) => {
                const { id, name, status } = project || {};
                this.currentProject = { ...project };
                this.formGroup = this.fb.group({
                    id: [id],
                    name: [name, Validators.required],
                    status: [Constants.PROJECT_STATUSES.REPORT_EVALUATING],
                });

                if (status === Constants.PROJECT_STATUSES.REPORTED) this.saveSkipValidation(null, true);
            })
            .finally(() => {
                this.disableFormIfSubmitted();
                this.calculateYears();
                this.initYearOptions();
                this.generateMonthlyOptions();
                this.loadActivities();
                this.showContent = true;
            });
    }

    loadActivities() {
        const projectId = this.currentProject?.id || 0;
        const year = this.selectedProjectYear || 1;
        const month = this.selectedOptionMonth || 0;
        const range = this.getRangeFor(year, month + 1);

        let params: any = { projectId, programmerType: 'FS' };
        if (range) {
            params = { ...params, ...range };
        } else {
            params = { ...params, year, month };
        }

        this.goalResultService
            .getAll({
                params,
            })
            .then((resp) => {
                this.results = [...resp];
                this.setCurrentMonthByActivity(this.results);
            });
    }

    onChangeSelectedProjectYear(event: any) {
        this.loadActivities();
        this.generateMonthlyOptions();
    }

    ngOnDestroy() {
        this.cleanParams();
    }
}
