import { Component, OnDestroy, OnInit } from '@angular/core';
import { ITCPRequest } from '../../../models';
import { ActivatedRoute } from '@angular/router';
import { ITCPRequestService } from '../../../../itcp';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { IAttachment } from '../../../../shared/interfaces';
import { FormBuilder, Validators } from '@angular/forms';
import { PhoneNumberLengthValidator } from '../../../../shared/validators/phone-number-length.validator';

@Component({
  selector: 'app-project-manager-info',
  templateUrl: './project-manager-info.component.html',
  styleUrls: ['./project-manager-info.component.scss'],
})
export class ProjectManagerInfoComponent extends ITCPRequest implements OnInit, OnDestroy {
  private step: number = 3;
  leadMemoFile: IAttachment[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private readonly iTCPRequestService: ITCPRequestService,
    private tService: ToasterService,
    private fb: FormBuilder
  ) {
    super(activatedRoute, iTCPRequestService, tService);
  }

  ngOnInit(): void {
    this.readParams();
    this.showContent = false;
    this.setCurrentRequest()
      .then((request) => {
        const {
          id,
          announcementCode,
          announcementId,
          token,
          leadName,
          leadJobtitle,
          leadMemoFile,
          leadMemoFileId,
          leadCellPhone,
          leadEmail,
        } = request || {};

        if (leadMemoFile) this.leadMemoFile.push(leadMemoFile);
        this.formGroup = this.fb.group({
          id: [id],
          announcementCode: [announcementCode],
          announcementId: [announcementId],
          token: [token],
          leadName: [leadName, Validators.required],
          leadJobtitle: [leadJobtitle, Validators.required],
          leadMemoFileId: [leadMemoFileId, Validators.required],
          leadCellPhone: [
            leadCellPhone,
            [Validators.required, PhoneNumberLengthValidator.phoneNumberValidator.bind(this)],
          ],
          leadEmail: [leadEmail, [Validators.required, Validators.email]],
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
          this.disableFormIfReadonly();
        this.showContent = true;
      });
    this.setActive(this.step);
  }

  onKeyPressNumbers(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  onUploaded(data: any) {
    const {
      event: {
        source: {
          _fileInput: { nativeElement },
        },
      },
      files,
    } = data;
    if (files.length) {
      this.formGroup.patchValue({
        leadMemoFileId: files[0].id,
      });
    }
  }

  onRemoveFile(event: any) {
    console.log('child', event);
  }

  ngOnDestroy() {
    this.cleanParams();
  }
}
