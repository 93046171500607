export interface IProvince {
    gid: number;
    objectid: number;
    dep: string;
    prov: string;
    cod_dep: string;
    cod_prov:string;
    shape_leng: number;
    geom: any;
}
