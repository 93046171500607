<div *ngIf="loading" class='grid bg-white' style="min-height: calc(100vh - 150px)">
    <div class="field col-8">
        <p-skeleton height="100%" styleClass="mb-2"></p-skeleton>
    </div>
    <div class="field col-4">
        <p-skeleton height="100%" styleClass="mb-2"></p-skeleton>
    </div>
</div>

<div *ngIf="!loading">
    Not Found
</div>
