<div class="grid">
    <p-breadcrumb [model]="breadcrumbs"></p-breadcrumb>
    <div class="col-12">
        <div class="card">
            <p-toolbar styleClass="mb-4 w-full clean-toolbar">
                <div class="flex justify-content-between flex-column align-items-center sm:flex-row w-full">
                    <div class="p-input-icon-left mb-2 searcher">
<!--                        <i class="pi pi-search"></i>-->
<!--                        <input-->
<!--                            pInputText-->
<!--                            type="text"-->
<!--                            class="w-full"-->
<!--                            (input)="setFilter($event, 'filterGlobal', 'contains')"-->
<!--                            placeholder="Buscar..."-->
<!--                        />-->
                    </div>
                    <div class="my-2">
                        <button
                            appPermission
                            [resource]="resource"
                            [enableActions]="'canView'"
                            pButton
                            pRipple
                            label="Actualizar"
                            icon="pi pi-refresh"
                            class="p-button-info mr-2"
                            (click)="load()"
                        ></button>
                        <button
                            appPermission
                            [resource]="resource"
                            [enableActions]="'canCreate'"
                            pButton
                            pRipple
                            label="Agregar"
                            icon="pi pi-plus"
                            class="p-button mr-2"
                            (click)="addNew()"
                        ></button>
                    </div>
                </div>
            </p-toolbar>
            <p-table
                #dt
                [value]="data"
                styleClass="p-datatable-gridlines"
                [loading]="loading"
                [resizableColumns]="true"
                [paginator]="true"
                [rows]="6"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} al {last} de {totalRecords} items"
                [rowsPerPageOptions]="[6, 12, 24]"
                responsiveLayout="scroll"
                [filterDelay]="0"
                [globalFilterFields]="['title', 'description']"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th class="bg-primary border-x-1">NRO.</th>
                        <th class="bg-primary border-x-1">PROYECTO</th>
                        <th class="bg-primary border-x-1">COMUNIDAD</th>
                        <th class="bg-primary border-x-1">TÉCNICO</th>
                        <th class="bg-primary border-x-1">FECHA</th>
                        <th class="bg-primary border-x-1">ESTADO</th>
                        <th class="bg-primary border-x-1"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                    <tr>
                        <td>{{rowIndex + 1 }}</td>
                        <td>
                            <div class="large-cell">{{ data.project.name }}</div>
                        </td>
                        <td>{{ data.act.community}}</td>
                        <td>
                            {{ getFullName(data.user)}}
                            <div class="text-xs text-gray-600">{{data.user?.usuarioRoles[0]?.rol?.descripcionRol}}</div>
                        </td>
                        <td class="text-center">
                            {{ data.visitDate | date: 'yyyy-MM-dd' }}
                        </td>
                        <td class="text-center">
                            <app-visit-status [status]="data.visitStatus"></app-visit-status>
                        </td>
                        <td>
                            <button
                                appPermission
                                [resource]="resource"
                                [enableActions]="'canUpdate'"
                                pButton
                                pTooltip="Editar"
                                tooltipPosition="top"
                                type="button"
                                icon="pi pi-pencil"
                                class="p-button-rounded p-button-success p-button-text"
                                (click)="selectedItem(data, 'edit')"
                            ></button>
                            <button
                                appPermission
                                [resource]="resource"
                                [enableActions]="'canDelete'"
                                pButton
                                pTooltip="Eliminar"
                                tooltipPosition="top"
                                type="button"
                                icon="pi pi-trash"
                                class="p-button-rounded p-button-danger p-button-text"
                                (click)="selectedItem(data, 'delete')"
                            ></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
