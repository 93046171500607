import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProjectEdtpModel } from '../../../../models';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../../../itcp';
import { ProjectBaseLineService } from '../../../../services';
import { CryptoService, SettingsService } from '../../../../../shared';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from '../../../../../shared/services/toaster.service';
import { IProjectC3t4 } from '../../../../models/interfaces/project-c3t4.interface';
import { Table } from 'primeng/table';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProjectC3t4Service } from '../../../../services/project-c3t4.service';
import { ProjectC3t4Activities } from './constants';

@Component({
    selector: 'app-t-iv',
    templateUrl: './t-iv.component.html',
    styleUrls: ['./t-iv.component.scss'],
})
export class C3TIVComponent extends ProjectEdtpModel implements OnInit, OnDestroy {
    private step: number = 16;

    _c3t4Items: IProjectC3t4[] = [];
    clonedC3t4Items: { [s: string]: IProjectC3t4 } = {};
    @ViewChild('dt') table!: Table;
    activities = [...ProjectC3t4Activities];
    public ref!: DynamicDialogRef;

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly projectC3t4Service: ProjectC3t4Service,
        private readonly baseLineService: ProjectBaseLineService,
        private readonly setService: SettingsService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private crypto: CryptoService
    ) {
        super(activatedRoute, projectService, tService, setService, crypto);
    }

    get c3t4Items() {
        return this._c3t4Items;
    }

    set c3t4Items(data: IProjectC3t4[]) {
        this._c3t4Items = data.map((c3t4) => ({ ...this.parseObject(c3t4) }));
    }

    ngOnInit(): void {
        this.readParams();
        this.setActive(this.step);
        this.service
            .get(this.params['id'])
            .then((project) => {
                const { id, name } = project || {};
                this.currentProject = { ...project };
                this.formGroup = this.fb.group({
                    id: [id],
                    name: [name, Validators.required],
                });
                return this.projectC3t4Service.getAll({ params: { projectId: parseInt(String(id)) } });
            })
            .then((resp) => {
                this.c3t4Items = [...resp];
            })
            .finally(() => {
                this.setSteps();
                this.setActive(this.step); //We need to reload the steps and put as active the current step
                this.disableFormIfSubmitted();
                this.showContent = true;
            });
    }

    onRowEditInit(obj: IProjectC3t4) {
        if (!obj?.id) return;
        this.clonedC3t4Items[obj.id] = { ...obj };
    }

    onRowEditSave(obj: IProjectC3t4) {
        if (!obj.id) return;

        const form: FormGroup = this.fb.group({
            projectId: [obj.projectId, Validators.required],
            infrastructure: [obj.infrastructure, Validators.required],
            activity: [obj.activity, Validators.required],
            quantity: [obj.quantity, [Validators.required, Validators.min(0)]],
            unit: [obj.unit, Validators.required],
        });

        if (form.valid) {
            delete this.clonedC3t4Items[obj.id];

            const index = this.c3t4Items.indexOf(obj);
            if (obj?.id.toString().includes('new_')) {
                this.projectC3t4Service.store(form.value).then((resp) => {
                    this.c3t4Items[index] = { ...this.parseObject(resp) };
                    this.toasterService.success('El Registro fue creado satisfactoriamente.');
                });
            } else {
                const id = parseInt(obj.id.toString());
                this.projectC3t4Service.update(form.value, id).then((resp) => {
                    this.c3t4Items[index] = { ...this.parseObject(resp) };
                    this.toasterService.success('El Registro fue actualizado satisfactoriamente.');
                });
            }
        } else {
            this.toasterService.error('No se pudo guardar, los datos son inválidos!');
        }
    }

    onRowEditCancel(obj: IProjectC3t4, index: number) {
        if (!obj.id) return;

        if (this.c3t4Items[parseInt(String(obj.id))]) {
            this.c3t4Items[index] = this.clonedC3t4Items[obj.id];
            delete this.c3t4Items[parseInt(String(obj.id))];
        }

        if (obj?.id.toString().includes('new_add')) {
            this.c3t4Items.pop();
        }
    }

    onNew() {
        const c3t4: IProjectC3t4 = {
            id: `new_add_${new Date().getTime()}`,
            projectId: parseInt(this.params['id']),
            infrastructure: '',
            activity: '',
            quantity: 0,
            unit: '',
        };

        this.c3t4Items.push(c3t4);
        this.table.initRowEdit(this.table.value[this.c3t4Items.length - 1]);
    }

    ngOnDestroy() {
        this.cleanParams();
    }

    parseObject(c3t4: IProjectC3t4) {
        const relatedActivity = this.activities.find((activity) => activity.value === c3t4.activity);
        return { ...c3t4, activityLabel: relatedActivity?.label || '' };
    }
}
