import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class CryptoService {
    private secretKey = 'O7NWSTxYWhK7VymaUPcBTXUGPmMDipt9o5UkDHUIM3s=';

    encrypt(value: any) {
        const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(value), this.secretKey).toString();
        return encryptedValue;
    }

    decrypt(value: any) {
        if (value) {
            const bytes = CryptoJS.AES.decrypt(value, this.secretKey);
            const decryptedValue = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return decryptedValue;
        }
        return null;
    }
}
