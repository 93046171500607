import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EDTPRoutingModule } from './edtp-routing.module';
import {
    AssignmentComponent,
    FormulationComponent,
    EvalComponent,
    AssessmentComponent,
    GoalsComponent,
    Component1Component,
    Component2Component,
    Component3Component,
    Component4Component,
    LineTIIComponent,
    LineTIIIComponent,
    LineTVComponent,
    LineTIVComponent,
    LineComponent,
    C1TIIComponent,
    C1TIIIComponent,
    C1TIVComponent,
    C1TVComponent,
    C2TIIComponent,
    C2TIIIComponent,
    C2TIVComponent,
    C2TVComponent,
    C3TIIComponent,
    C3TIIIComponent,
    C3TVComponent,
    C3TIVComponent,
    C4TIIComponent,
    C4TIIIComponent,
    C4TIVComponent,
    C4TVComponent,
    DoneComponent,
    BudgetComponent,
    BudgetCompComponent,
    BudgetVipfeComponent,
    CostsComponent,
    StructureComponent,
    ObservationsComponent,
    CheckContentComponent,
    QuantitativeComponent,
    WeighingComponent,
    RankingComponent,
    ObsGroupComponent,
    ComplianceObsComponent, BudgetComponentComponent,
} from './components';
import {BreadcrumbModule} from "primeng/breadcrumb";
import {TableModule} from "primeng/table";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TabViewModule} from "primeng/tabview";
import {ToolbarModule} from "primeng/toolbar";
import {DirectiveModule} from "../shared/directives/directive.module";
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import {BadgeModule} from "primeng/badge";
import {PublicModule} from "../public/public.module";
import {AutoFocusModule} from "primeng/autofocus";
import {InputTextareaModule} from "primeng/inputtextarea";
import {SkeletonModule} from "primeng/skeleton";
import {AutoCompleteModule} from "primeng/autocomplete";
import {RadioButtonModule} from 'primeng/radiobutton';
import { ComplianceComponent } from './components/assessment/check-content/compliance/compliance.component';
import { ScoreComponent } from './components/assessment/quantitative/score/score.component';
import { CompComponent } from './components/formulation/budget-vipfe/comp/comp.component';
import { SchedulerComponent, CheckContentObsComponent } from './components';
import { ActivitiesComponent } from './components/scheduler/activities/activities.component';
import { ResultsComponent } from './components/scheduler/activities/results/results.component';
import {CalendarModule} from "primeng/calendar";
import {DialogModule} from "primeng/dialog";
import { WeightingContentComponent } from './components/assessment/weighing/weighting-content/weighting-content.component';
import {EditorModule} from "primeng/editor";
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { CostEfficiencyComponent } from './components/eval/cost-efficiency/cost-efficiency.component';
import {CustomFormModule} from "../shared";

@NgModule({
    declarations: [
        AssignmentComponent,
        FormulationComponent,
        EvalComponent,
        GoalsComponent,
        LineComponent,
        Component1Component,
        Component2Component,
        Component3Component,
        Component4Component,
        LineTIIComponent,
        LineTIIIComponent,
        LineTIVComponent,
        LineTVComponent,
        C1TIIIComponent,
        C1TIIComponent,
        C1TIVComponent,
        C1TVComponent,
        C2TIIComponent,
        C2TIIIComponent,
        C2TIVComponent,
        C2TVComponent,
        C3TIIComponent,
        C3TIIIComponent,
        C3TIVComponent,
        C3TVComponent,
        C4TIIComponent,
        C4TIIIComponent,
        C4TIVComponent,
        C4TVComponent,
        DoneComponent,
        BudgetComponent,
        BudgetCompComponent,
        BudgetVipfeComponent,
        CostsComponent,
        StructureComponent,
        AssessmentComponent,
        ObservationsComponent,
        CheckContentObsComponent,
        ComplianceObsComponent,
        CheckContentComponent,
        QuantitativeComponent,
        WeighingComponent,
        ObsGroupComponent,
        ComplianceComponent,
        ScoreComponent,
        CompComponent,
        CompComponent,
        RankingComponent,
        SchedulerComponent,
        ActivitiesComponent,
        ResultsComponent,
        WeightingContentComponent,
        CostEfficiencyComponent,
        BudgetComponentComponent,
    ],
    exports: [
        ActivitiesComponent
    ],
    imports: [
        CommonModule,
        EDTPRoutingModule,
        BreadcrumbModule,
        TableModule,
        DropdownModule,
        ReactiveFormsModule,
        FormsModule,
        TabViewModule,
        ToolbarModule,
        DirectiveModule,
        InputTextModule,
        ButtonModule,
        BadgeModule,
        PublicModule,
        AutoFocusModule,
        InputTextareaModule,
        SkeletonModule,
        AutoCompleteModule,
        RadioButtonModule,
        CalendarModule,
        DialogModule,
        EditorModule,
        OverlayPanelModule,
        ProgressBarModule,
        CustomFormModule,
    ]
})
// @ts-ignore
export class EDTPModule {}
