<app-wizard
    [steps]="steps"
    [current]="currentStep"
    [bodyContentTpl]="projectForm"
    [nextDisabled]="this.formGroup?.invalid"
    (onFinish)="saveSkipValidation($event)"
    [layout]="true"
    [lastStep]="true"
>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf="showContent" class="project-form flex justify-content-center flex-column" [formGroup]="formGroup">
        <div class="grid">
            <div class="field col-12 text-2xl text-primary uppercase">
                {{ currentStep?.text }}
            </div>

            <div class="field col-12">
                <p-selectButton
                    [options]="selectableProjectYears"
                    optionLabel="label"
                    optionValue="id"
                    [(ngModel)]="selectedProjectYear"
                    [ngModelOptions]="{ standalone: true }"
                    (onChange)="onChangeSelectedProjectYear($event)"
                >
                </p-selectButton>
                <p-selectButton
                    [options]="optionsByYear"
                    [(ngModel)]="selectedOptionYear"
                    [ngModelOptions]="{ standalone: true }"
                    (onChange)="generateMonthlyOptions()"
                >
                </p-selectButton>
                <p-selectButton
                    [options]="optionsByMonth"
                    [(ngModel)]="selectedOptionMonth"
                    [ngModelOptions]="{ standalone: true }"
                    (onChange)="loadActivities()"
                >
                </p-selectButton>
            </div>

            <div class="field col-12">
                <p-table [value]="results" dataKey="id" editMode="row" [tableStyle]="{ 'min-width': '100%' }" #dt>
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="bg-primary border-x-1 text-center" rowspan="2" style="min-width: 60px">NRO.</th>
                            <th
                                class="bg-primary border-x-1 text-center"
                                rowspan="2"
                                style="min-width: 300px; max-width: 300px"
                            >
                                PARTIDA PRESUPUESTARIA
                            </th>
                            <th class="bg-primary border-x-1 text-center" colspan="4">FONABOSQUE</th>
                            <th class="bg-primary border-x-1 text-center" colspan="4">
                                ENTIDAD EJECUTORA<br />EFECTIVO
                            </th>
                            <th class="bg-primary border-x-1 text-center" colspan="4">
                                ENTIDAD EJECUTORA<br />ESPECIE
                            </th>
                            <th class="bg-primary border-x-1 text-center" rowspan="2" style="min-width: 120px">
                                MEDIO DE VERIFICACIÓN
                            </th>
                            <th class="bg-primary border-x-1 text-center" rowspan="2" style="min-width: 150px">
                                OBSERVACIÓN
                            </th>
                            <th class="bg-primary" rowspan="2" style="width: 100px"></th>
                        </tr>
                        <tr>
                            <th class="bg-primary border-x-1 text-center" style="min-width: 115px; max-width: 115px">
                                PROGRAMADO
                            </th>
                            <th class="bg-primary border-x-1 text-center" style="min-width: 115px; max-width: 115px">
                                EJECUTADO
                            </th>
                            <th class="bg-primary border-x-1 text-center" style="min-width: 115px; max-width: 115px">
                                % EJEC.
                            </th>
                            <th class="bg-primary border-x-1 text-center" style="min-width: 115px; max-width: 115px">
                                SALDO
                            </th>
                            <th class="bg-primary border-x-1 text-center" style="min-width: 115px; max-width: 115px">
                                PROGRAMADO
                            </th>
                            <th class="bg-primary border-x-1 text-center" style="min-width: 115px; max-width: 115px">
                                EJECUTADO
                            </th>
                            <th class="bg-primary border-x-1 text-center" style="min-width: 115px; max-width: 115px">
                                % EJEC.
                            </th>
                            <th class="bg-primary border-x-1 text-center" style="min-width: 115px; max-width: 115px">
                                SALDO
                            </th>
                            <th class="bg-primary border-x-1 text-center" style="min-width: 115px; max-width: 115px">
                                PROGRAMADO
                            </th>
                            <th class="bg-primary border-x-1 text-center" style="min-width: 115px; max-width: 115px">
                                EJECUTADO
                            </th>
                            <th class="bg-primary border-x-1 text-center" style="min-width: 115px; max-width: 115px">
                                % EJEC.
                            </th>
                            <th class="bg-primary border-x-1 text-center" style="min-width: 115px; max-width: 115px">
                                SALDO
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-obj let-editing="editing" let-index="rowIndex">
                        <tr [pEditableRow]="obj">
                            <td colspan="100%" class="p-0 m-0">
                                <app-eval-financial-activities
                                    [group]="obj"
                                    [editMode]="false"
                                    [totalProjectDays]="totalProjectDays"
                                    [selectedYear]="selectedProjectYear"
                                ></app-eval-financial-activities>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="100%">
                                <div class="w-full text-center font-bold">No se ha encontrado resultados</div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</ng-template>
