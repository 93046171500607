import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from 'src/app/shared/services';
import {IProjectChangeOrder} from '../models';

@Injectable({
  providedIn: 'root'
})
export class ProjectChangeOrderService extends BaseService<IProjectChangeOrder>{

    constructor(private readonly httpService: BaseHttpService) {
        super(httpService, 'project-change-orders');
    }
}
