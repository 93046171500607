import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, takeUntil } from 'rxjs';
const v = require('voca');

import { CredentialsService } from 'src/app/shared/services';
import { LayoutService } from 'src/app/shared/services/app.layout.service';
import { ChangePasswordModalComponent } from '../change-password-modal/change-password-modal.component';
import { User } from '../../core/user/interfaces';

@Component({
  selector: 'app-top-bar-template',
  templateUrl: './top-bar-template.component.html',
  styleUrls: ['./top-bar-template.component.scss'],
})
export class TopBarTemplateComponent implements OnDestroy {
  items!: MenuItem[];
  currentUser: User;

  @ViewChild('menubutton') menuButton!: ElementRef;

  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

  @ViewChild('topbarmenu') menu!: ElementRef;
  private onDestroy$ = new Subject();
  public ref!: DynamicDialogRef;
  private confModal: DynamicDialogConfig<any> = {
    header: 'Cambiar Contraseña',
    width: '50%',
    contentStyle: {
      overflow: 'auto',
    },
    baseZIndex: 10000,
    closable: true,
  };

  constructor(
    public layoutService: LayoutService,
    public router: Router,
    public credentialsService: CredentialsService,
    public dialogService: DialogService
  ) {
    this.currentUser = this.credentialsService.getLoggedUser();
    this.items = [
        {
        label: 'Seguridad',
        items: [
          {
            label: 'Cambiar Contraseña',
            icon: 'pi pi-pencil',
            command: () => {
              this.openModal();
            },
          },
        ],
      },
      {
        label: 'Configuración',
        items: [
          {
            label: 'Menu Estatico',
            icon: 'pi pi-desktop',
            command: () => {
              this.changeMenuType('static');
            },
          },
          {
            label: 'Menu Flotante',
            icon: 'pi pi-desktop',
            command: () => {
              this.changeMenuType('overlay');
            },
          },
          {
            label: 'Tema Oscuro',
            icon: 'pi pi-palette',
            command: () => {
              this.changeTheme('bootstrap4-dark-purple', 'dark');
            },
          },
          {
            label: 'Tema Claro',
            icon: 'pi pi-palette',
            command: () => {
              this.changeTheme('bootstrap4-light-purple', 'light');
            },
          },
        ],
      },
      {
        label: 'Sesion',
        items: [
          {
            label: 'Salir',
            icon: 'pi pi-sign-out',
            command: () => {
              this.logout();
            },
          },
        ],
      },
    ];
  }

  public openModal() {
    this.confModal.header = 'Cambiar Contraseña';
    this.confModal.data = undefined;
    this.ref = this.dialogService.open(ChangePasswordModalComponent, this.confModal);
    this.ref.onClose.pipe(takeUntil(this.onDestroy$)).subscribe((resp) => {
      if (resp) {
        this.logout();
      }
    });
  }

  public changeTheme(theme: string, colorScheme: string) {
    const themeLink = <HTMLLinkElement>document.getElementById('theme-css');
    const newHref = themeLink.getAttribute('href')!.replace(this.layoutService.config.theme, theme);
    this.layoutService.config.colorScheme;
    this.replaceThemeLink(newHref, () => {
      this.layoutService.config.theme = theme;
      this.layoutService.config.colorScheme = colorScheme;
      this.layoutService.onConfigUpdate();
    });
  }

  public changeMenuType(value: string = 'static') {
    this.layoutService.config.menuMode = value;
  }

  public logout() {
    this.credentialsService.removeUser();
    this.router.navigate(['']);
    window.location.reload();
  }

  public getCurrentUserName() {
    const {
      currentUser: { primerNombre, apellidoPaterno },
    } = this;
    const array: (string | undefined)[] = [primerNombre, apellidoPaterno];

    return array.filter((i) => !v.isBlank(i)).join(' ');
  }

  get getCurrentRole() {
      return this.currentUser?.role?.roleDescription;
  }

  private replaceThemeLink(href: string, onComplete: Function) {
    const id = 'theme-css';
    const themeLink = <HTMLLinkElement>document.getElementById('theme-css');
    const cloneLinkElement = <HTMLLinkElement>themeLink.cloneNode(true);

    cloneLinkElement.setAttribute('href', href);
    cloneLinkElement.setAttribute('id', id + '-clone');

    themeLink.parentNode!.insertBefore(cloneLinkElement, themeLink.nextSibling);

    cloneLinkElement.addEventListener('load', () => {
      themeLink.remove();
      cloneLinkElement.setAttribute('id', id);
      onComplete();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(undefined);
    this.onDestroy$.complete();
  }
}
