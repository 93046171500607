<app-wizard
  [steps]="steps"
  [current]="currentStep"
  [bodyContentTpl]="projectForm"
  [nextDisabled]="this.formGroup?.invalid"
  (onClickNext)="save($event)"
  [layout]="true"
>
</app-wizard>

<ng-template #projectForm>
  <div *ngIf="showContent" class="project-form flex justify-content-center flex-column" [formGroup]="formGroup">
    <div class="grid">
      <div class="field col-12 text-2xl text-primary uppercase">
        {{ currentStep?.text }}
      </div>

      <div class="field col-12">
        <button
          [disabled]="isSubmitted"
          class="p-button p-button-success"
          pButton
          pTooltip="Agregar"
          tooltipPosition="top"
          (click)="onNew('NATIVE')"
        >
          <i class="pi pi-plus"></i>
        </button>
        <p-table #dtForest [value]="c2NativeItems" dataKey="id" editMode="row" [tableStyle]="{ width: '100%' }">
          <ng-template pTemplate="header">
            <tr>
              <th class="bg-primary" style="width: 25%">Forestales</th>
              <th class="bg-primary" style="width: 15%">Gestión 1</th>
              <th class="bg-primary" style="width: 15%">Gestión 2</th>
              <th class="bg-primary" style="width: 15%">Gestión 3</th>
              <th class="bg-primary" style="width: 20%">Totales</th>
              <th class="bg-primary" style="width: 10%"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-obj let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="obj">
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-autoComplete
                      [suggestions]="species"
                      [dropdown]="true"
                      (completeMethod)="filterSpecieItems($event, 'NATIVE')"
                      (onSelect)="onSelectSpecie($event, obj)"
                      field="code"
                      placeholder="Codigo"
                      [minLength]="3"
                    >
                      <ng-template let-item pTemplate="item">
                        <div class="specie-item w-30rem">
                          <div class="specie-item-title" style="white-space: pre-wrap">
                            <b>{{ item.code }}</b> - {{ item.name }}
                          </div>
                        </div>
                      </ng-template>
                    </p-autoComplete>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <b>{{ obj.specieCode }}</b> - {{ obj.specieName }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      min='0'
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      [(ngModel)]="obj.quantityYear1"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.quantityYear1 | number: '1.0-2'}}
                  </ng-template>
                </p-cellEditor>
              </td>

              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      min='0'
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      [(ngModel)]="obj.quantityYear2"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.quantityYear2 | number: '1.0-2'}}
                  </ng-template>
                </p-cellEditor>
              </td>

              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      min='0'
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      [(ngModel)]="obj.quantityYear3"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.quantityYear3 | number: '1.0-2'}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                {{ obj.quantityTotal | number: '1.0-2'}}
              </td>
              <td>
                <div *ngIf="!isSubmitted" class="flex align-items-center justify-content-center gap-2">
                  <button
                    *ngIf="!editing"
                    pButton
                    pRipple
                    type="button"
                    pInitEditableRow
                    icon="pi pi-pencil"
                    (click)="onRowEditInit(obj)"
                    class="p-button-rounded p-button-text"
                  ></button>
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pSaveEditableRow
                    icon="pi pi-check"
                    [disabled]="!getForm(obj)?.valid"
                    (click)="onRowEditSave(obj, 'NATIVE')"
                    class="p-button-rounded p-button-text p-button-success mr-2"
                  ></button>
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pCancelEditableRow
                    icon="pi pi-times"
                    (click)="onRowEditCancel(obj, ri)"
                    class="p-button-rounded p-button-text p-button-danger"
                  ></button>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="field col-12">
        <button
          [disabled]="isSubmitted"
          class="p-button p-button-success"
          pButton
          pTooltip="Agregar"
          tooltipPosition="top"
          (click)="onNew('FRUIT')"
        >
          <i class="pi pi-plus"></i>
        </button>
        <p-table #dtFruit [value]="c2FruitItems" dataKey="id" editMode="row" [tableStyle]="{ width: '100%' }">
          <ng-template pTemplate="header">
            <tr>
              <th class="bg-primary" style="width: 25%">Frutales</th>
              <th class="bg-primary" style="width: 15%">Gestión 1</th>
              <th class="bg-primary" style="width: 15%">Gestión 2</th>
              <th class="bg-primary" style="width: 15%">Gestión 3</th>
              <th class="bg-primary" style="width: 20%">Totales</th>
              <th class="bg-primary" style="width: 10%"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-obj let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="obj">
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-autoComplete
                      [suggestions]="species"
                      [dropdown]="true"
                      (completeMethod)="filterSpecieItems($event, 'FRUIT')"
                      (onSelect)="onSelectSpecie($event, obj)"
                      field="code"
                      placeholder="Codigo"
                      [minLength]="3"
                    >
                      <ng-template let-item pTemplate="item">
                        <div class="specie-item w-30rem">
                          <div class="specie-item-title" style="white-space: pre-wrap">
                            <b>{{ item.code }}</b> - {{ item.name }}
                          </div>
                        </div>
                      </ng-template>
                    </p-autoComplete>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <b>{{ obj.specieCode }}</b> - {{ obj.specieName }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      min='0'
                      [(ngModel)]="obj.quantityYear1"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.quantityYear1 | number: '1.0-2'}}
                  </ng-template>
                </p-cellEditor>
              </td>

              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      min='0'
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      [(ngModel)]="obj.quantityYear2"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.quantityYear2 | number: '1.0-2'}}
                  </ng-template>
                </p-cellEditor>
              </td>

              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      min='0'
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      [(ngModel)]="obj.quantityYear3"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.quantityYear3 | number: '1.0-2'}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                {{ obj.quantityTotal | number: '1.0-2'}}
              </td>
              <td>
                <div *ngIf="!isSubmitted" class="flex align-items-center justify-content-center gap-2">
                  <button
                    *ngIf="!editing"
                    pButton
                    pRipple
                    type="button"
                    pInitEditableRow
                    icon="pi pi-pencil"
                    (click)="onRowEditInit(obj)"
                    class="p-button-rounded p-button-text"
                  ></button>
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pSaveEditableRow
                    icon="pi pi-check"
                    [disabled]="!getForm(obj)?.valid"
                    (click)="onRowEditSave(obj, 'FRUIT')"
                    class="p-button-rounded p-button-text p-button-success mr-2"
                  ></button>
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pCancelEditableRow
                    icon="pi pi-times"
                    (click)="onRowEditCancel(obj, ri)"
                    class="p-button-rounded p-button-text p-button-danger"
                  ></button>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="field col-12">
        <button
          [disabled]="isSubmitted"
          class="p-button p-button-success"
          pButton
          pTooltip="Agregar"
          tooltipPosition="top"
          (click)="onNew('EXOTIC')"
        >
          <i class="pi pi-plus"></i>
        </button>
        <p-table #dtExotic [value]="c2ExoticItems" dataKey="id" editMode="row" [tableStyle]="{ width: '100%' }">
          <ng-template pTemplate="header">
            <tr>
              <th class="bg-primary" style="width: 25%">Exóticas</th>
              <th class="bg-primary" style="width: 15%">Gestión 1</th>
              <th class="bg-primary" style="width: 15%">Gestión 2</th>
              <th class="bg-primary" style="width: 15%">Gestión 3</th>
              <th class="bg-primary" style="width: 20%">Totales</th>
              <th class="bg-primary" style="width: 10%"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-obj let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="obj">
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-autoComplete
                      [suggestions]="species"
                      [dropdown]="true"
                      (completeMethod)="filterSpecieItems($event, 'EXOTIC')"
                      (onSelect)="onSelectSpecie($event, obj)"
                      field="code"
                      placeholder="Codigo"
                      [minLength]="3"
                    >
                      <ng-template let-item pTemplate="item">
                        <div class="specie-item w-30rem">
                          <div class="specie-item-title" style="white-space: pre-wrap">
                            <b>{{ item.code }}</b> - {{ item.name }}
                          </div>
                        </div>
                      </ng-template>
                    </p-autoComplete>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <b>{{ obj.specieCode }}</b> - {{ obj.specieName }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      min='0'
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      [(ngModel)]="obj.quantityYear1"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.quantityYear1 | number: '1.0-2'}}
                  </ng-template>
                </p-cellEditor>
              </td>

              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      min='0'
                      [(ngModel)]="obj.quantityYear2"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.quantityYear2 | number: '1.0-2'}}
                  </ng-template>
                </p-cellEditor>
              </td>

              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      min='0'
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      [(ngModel)]="obj.quantityYear3"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.quantityYear3 | number: '1.0-2'}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                {{ obj.quantityTotal | number: '1.0-2'}}
              </td>
              <td>
                <div *ngIf="!isSubmitted" class="flex align-items-center justify-content-center gap-2">
                  <button
                    *ngIf="!editing"
                    pButton
                    pRipple
                    type="button"
                    pInitEditableRow
                    icon="pi pi-pencil"
                    (click)="onRowEditInit(obj)"
                    class="p-button-rounded p-button-text"
                  ></button>
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pSaveEditableRow
                    icon="pi pi-check"
                    [disabled]="!getForm(obj)?.valid"
                    (click)="onRowEditSave(obj, 'EXOTIC')"
                    class="p-button-rounded p-button-text p-button-success mr-2"
                  ></button>
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pCancelEditableRow
                    icon="pi pi-times"
                    (click)="onRowEditCancel(obj, ri)"
                    class="p-button-rounded p-button-text p-button-danger"
                  ></button>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</ng-template>
