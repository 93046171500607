import {ComplianceGroupType} from "../../services";
import {IProjectAssessment} from "../../../itcp/components/project-assessment/interfaces/project-assessment.interface";

export interface IProjectCompliance {
    id?: number | string;
    projectId: number;
    groupId: number;
    compliance: string;
    submitted: boolean;
    code?: number;
    other?: boolean;
    group?: IProjectComplianceGroup;
    observation?: string;
    fixed?: boolean;
    noFixed?: boolean;
    fixingObservation?: string;
}


export interface IProjectComplianceGroup {
    id?: number | string;
    projectId: number;
    title: string;
    code: number;
    type?: ComplianceGroupType;
    children: IProjectCompliance[];
    assessments?: IProjectAssessment[];
    weight?: number;
    maxScore?: number;
    scoreAccum?: number;
    scoreDist?: number;
    maxScoreAccum?: number;
    maxScoreDist?: number;
    minimumAccepted?:number;
}

