import {Component, Input, OnInit} from '@angular/core';
import {VisitType} from "../../models";

const Severities = {
    info: 'info',
    success: 'success',
    warning: 'warning',
    danger: 'danger',
}

@Component({
    selector: 'app-visit-status',
    templateUrl: './visit-status.component.html',
    styleUrls: ['./visit-status.component.scss']
})
export class VisitStatusComponent implements OnInit {

    @Input() status!: VisitType;
    statuses: any = {};
    statusesClassNames:any = {};
    constructor() {
        this.statuses['PROGRAMMED'] = 'Programado';
        this.statuses['IN_PROGRESS'] = 'En proceso';
        this.statuses['VISITED'] = 'Visitado';

        this.statuses['PROGRAMMED'] = Severities.info;
        this.statuses['IN_PROGRESS'] = Severities.warning;
        this.statuses['VISITED'] = Severities.success;
    }

    ngOnInit(): void {
    }

}
