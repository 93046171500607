import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from 'src/app/shared/services';
import {IProjectBaseLine} from "../models";

@Injectable({
  providedIn: 'root'
})
export class ProjectBaseLineService extends BaseService<IProjectBaseLine>{

    constructor(private readonly httpService: BaseHttpService) {
        super(httpService, 'project-base-line');
    }
}
