import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {
    IProjectGoalActivity,
    IProjectGoalResult,
    ProjectGoalActivityService
} from "../../../../../../edtp";
import {Table} from "primeng/table";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToasterService} from "../../../../../../shared/services/toaster.service";
import {ProjectActivityBudgetService} from "../../../../../services";

@Component({
    selector: 'app-summary-activity-weekly',
    templateUrl: './activities.component.html',
    styleUrls: ['./activities.component.scss']
})
export class SummaryWeekActivitiesComponent implements OnInit {
    @Input() group!: IProjectGoalResult;
    @Input() canProgram: boolean = false;
    @Input() selectedYear!: number;


    cloned: { [s: string]: IProjectGoalActivity; } = {};

    @ViewChild('dt') table!: Table;

    constructor(
        private readonly activityService: ProjectGoalActivityService,
        private readonly budgetService: ProjectActivityBudgetService,
        private fb: FormBuilder,
        private toasterService: ToasterService,
    ) {
    }

    ngOnInit(): void {
        if (!this.group.activities)
            this.group.activities = [];
    }

    onRowEditInit(obj: IProjectGoalActivity) {
        if (!obj?.id) return;
        this.cloned[obj.id] = {...obj};
    }

    getForm(obj: IProjectGoalActivity): any {
        if (!obj?.id) return;
        let form!: FormGroup;
        const { currentMonth, currentMonthBudget } = obj;

        if (obj.programmerType === 'FN') {
            if (!currentMonthBudget) return;

            form = this.fb.group({
                activityBudgetId: [currentMonthBudget.activityBudgetId, Validators.required],
                owner: [currentMonthBudget.ownerAmount, [Validators.required, Validators.min(0), Validators.max(currentMonthBudget.yearOwner || 0)]],
                entity: [currentMonthBudget.entityAmount, [Validators.required, Validators.min(0), Validators.max(currentMonthBudget.yearEntity || 0)]],
                entityInKind: [currentMonthBudget.entityInKindAmount, [Validators.required, Validators.min(0), Validators.max(currentMonthBudget.yearEntityInKind || 0)]],
                year: [currentMonthBudget.year, Validators.required],
                month: [currentMonthBudget.month, Validators.required],
                week1: [currentMonthBudget.week1],
                week2: [currentMonthBudget.week2],
                week3: [currentMonthBudget.week3],
                week4: [currentMonthBudget.week4],
                activityBudgetIds: [currentMonthBudget.activityBudgetIds, Validators.required],
            });

        } else if (obj.programmerType === 'FS') {
            if (!currentMonth) return;
            form = this.fb.group({
                activityId: [currentMonth.activityId, Validators.required],
                expected: [currentMonth.expected, [Validators.required, Validators.min(0), Validators.max(currentMonth.yearExpected || 0)]],
                year: [currentMonth.year, Validators.required],
                month: [currentMonth.month, Validators.required],
                week1: [currentMonth.week1],
                week2: [currentMonth.week2],
                week3: [currentMonth.week3],
                week4: [currentMonth.week4],
            });
        }

        return form;
    }

    onRowEditSave(obj: IProjectGoalActivity) {
        if (!obj?.id) return;

        const { currentMonth, currentMonthBudget } = obj;
        const form = this.getForm(obj);

        if (form?.valid) {
            delete this.cloned[obj.id];

            const index = this.group.activities.indexOf(obj);

            if (obj.programmerType === 'FS') {
                if (currentMonth?.id?.toString().includes('new_')) {
                    this.activityService.storeActivityMonth(form.value).then((resp) => {
                        this.group.activities[index].currentMonth = {
                            ...this.group.activities[index].currentMonth,
                            ...resp,
                        };
                        this.toasterService.success('El Registro fue creado satisfactoriamente.');
                    });

                } else {
                    const id = currentMonth?.id ? parseInt(currentMonth?.id.toString()) : 0;
                    this.activityService.updateActivityMonth(form.value, id).then((resp) => {
                        this.toasterService.success('El Registro fue actualizado satisfactoriamente.');
                    })
                }
            } else if (obj.programmerType === 'FN') {
                if (currentMonthBudget?.id?.toString().includes('new_')) {
                    this.budgetService.storeActivityBudgetMonth(form.value).then((resp) => {
                        this.group.activities[index].currentMonthBudget = {
                            ...this.group.activities[index].currentMonthBudget,
                            ...resp,
                        };
                        this.toasterService.success('El Registro fue creado satisfactoriamente.');
                    });

                } else {
                    const id = parseInt(obj.id.toString());
                    this.budgetService.updateActivityBudgetMonth(form.value, id).then((resp) => {
                        this.toasterService.success('El Registro fue actualizado satisfactoriamente.');
                    })
                }
            }

        } else {
            this.toasterService.error('No se pudo guardar, los datos son inválidos!');
        }
    }

    onRowEditCancel(obj: IProjectGoalActivity, index: number) {
        if (!obj?.id) return;

        if (this.cloned[obj.id]) {
            this.group.activities[index] = this.cloned[obj.id];
            delete this.cloned[obj.id];
        }

        if (obj?.id.toString().includes('new_')) {
            this.group.activities = this.group.activities.filter(o => o.id !== obj.id);
        }
    }
}
