<div class='w-full bg-green-100'>
    <div class='flex gap-2 justify-content-start align-items-center px-4 py-2'>
        <div class="px-4 py-1">{{index + 1 }}</div>
        <div class="px-4 py-1 w-20rem flex justify-content-start align-items-center flex-1">
            <span class="font-bold">{{activity.activity}}</span>
        </div>
    </div>
</div>

<p-table
    [value]="budgets"
    dataKey="id"
    editMode="row"
    [tableStyle]="{'min-width': '100%'}"
    styleClass="p-datatable-gridlines"
    #dt>
    <ng-template pTemplate="header">
        <tr *ngIf="budgets.length">
            <th class="bg-primary border-x-1 text-center p-0" style="width:60px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width:300px; max-width:300px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width:115px;"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width:115px;"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width:115px;"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width:115px;"></th>
            <th class="bg-primary p-0" style="width:100px"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-obj let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="obj">
            <td style='width:50px'>{{index + 1}}.{{ri + 1}}</td>
            <td>
                <b>{{obj.budgetItemCode}}</b> - {{obj.budgetItem?.title}}
                <div class="text-sm mt-2">
                    {{obj.budgetItemDetails}}
                </div>
            </td>

            <td class="text-center">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <div class="flex flex-nowrap">
                            <input pInputText
                                   required
                                   [min]="0"
                                   [max]="obj.currentMonth.availableOwner"
                                   class='w-6rem p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary'
                                   type="number" [(ngModel)]="obj.currentMonth.ownerAmount" [ngModelOptions]="{standalone: true}">
                            <p-overlayPanel #op>
                                <b>Programado:</b> {{(obj.currentMonth.totalProgrammedOwner + obj.currentMonth.ownerAmount) | number: '1.2-2'}}.<br>
                                <b>Programado Presente Gestión:</b>  {{obj.currentMonth.yearOwner | number: '1.2-2'}}.<br>
                                <b>Programación Disponible:</b> {{calcCurrentAvailable(obj.currentMonth.availableOwner, obj.currentMonth.ownerAmount) | number: '1.2-2' }}
                            </p-overlayPanel>

                            <button pButton pRipple type="button" pInitEditableRow icon="pi pi-info-circle" (click)="op.toggle($event)" class="p-button-rounded p-button-text"></button>
                        </div>
                        <div *ngIf="!getForm(obj)?.valid" class="relative">
                            <p *ngIf="!obj.currentMonth.ownerAmount" class="text-danger text-sm absolute">
                                {{'Valor inválido'}}
                            </p>
                            <p *ngIf="obj.currentMonth.ownerAmount && obj.currentMonth.ownerAmount < 0" class="text-danger text-sm absolute">
                                {{'Valor mínimo: 0'}}
                            </p>
                            <p *ngIf="obj.currentMonth.ownerAmount > obj.currentMonth.availableOwner" class="text-danger text-sm absolute">
                                {{'Valor máximo: '  + obj.currentMonth.availableOwner }}
                            </p>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.currentMonth.ownerAmount | number: '1.2-2'}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class="text-center">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <div class="flex flex-nowrap">
                            <input pInputText
                                   required
                                   [min]="0"
                                   [max]="obj.currentMonth.availableEntityInKind"
                                   class='w-6rem p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary'
                                   type="number" [(ngModel)]="obj.currentMonth.entityInKindAmount" [ngModelOptions]="{standalone: true}">
                            <p-overlayPanel #op>
                                <b>Programado:</b> {{(obj.currentMonth.totalProgrammedEntityInKind + obj.currentMonth.entityInKindAmount) | number: '1.2-2'}}.<br>
                                <b>Programado Presente Gestión:</b>  {{obj.currentMonth.yearEntityInKind | number: '1.2-2'}}.<br>
                                <b>Programación Disponible:</b> {{ calcCurrentAvailable(obj.currentMonth.availableEntityInKind, obj.currentMonth.entityInKindAmount) | number: '1.2-2'}}
                            </p-overlayPanel>

                            <button pButton pRipple type="button" pInitEditableRow icon="pi pi-info-circle" (click)="op.toggle($event)" class="p-button-rounded p-button-text"></button>
                        </div>
                        <div *ngIf="!getForm(obj)?.valid" class="relative">
                            <p *ngIf="!obj.currentMonth.entityInKindAmount" class="text-danger text-sm absolute">
                                {{'Valor inválido'}}
                            </p>
                            <p *ngIf="obj.currentMonth.entityInKindAmount && obj.currentMonth.entityInKindAmount < 0" class="text-danger text-sm absolute">
                                {{'Valor mínimo: 0'}}
                            </p>
                            <p *ngIf="obj.currentMonth.entityInKindAmount > obj.currentMonth.availableEntityInKind" class="text-danger text-sm absolute">
                                {{'Valor máximo: '  + obj.currentMonth.availableEntityInKind }}
                            </p>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.currentMonth.entityInKindAmount | number: '1.2-2'}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class='text-center'>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <div class="flex flex-nowrap">
                            <input pInputText
                                   required
                                   [min]="0"
                                   [max]="obj.currentMonth.availableEntity"
                                   class='w-6rem p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary'
                                   type="number" [(ngModel)]="obj.currentMonth.entityAmount" [ngModelOptions]="{standalone: true}">
                            <p-overlayPanel #op>
                                <b>Programado:</b> {{(obj.currentMonth.totalProgrammedEntity + obj.currentMonth.entityAmount) | number: '1.2-2'}}.<br>
                                <b>Programado Presente Gestión:</b>  {{obj.currentMonth.yearEntity | number: '1.2-2'}}.<br>
                                <b>Programación Disponible:</b> {{ calcCurrentAvailable(obj.currentMonth.availableEntity, obj.currentMonth.entityAmount) | number: '1.2-2'}}
                            </p-overlayPanel>

                            <button pButton pRipple type="button" pInitEditableRow icon="pi pi-info-circle" (click)="op.toggle($event)" class="p-button-rounded p-button-text"></button>
                        </div>
                        <div *ngIf="!getForm(obj)?.valid" class="relative">
                            <p *ngIf="!obj.currentMonth.entityAmount" class="text-danger text-sm absolute">
                                {{'Valor inválido'}}
                            </p>
                            <p *ngIf="obj.currentMonth.entityAmount && obj.currentMonth.entityAmount < 0" class="text-danger text-sm absolute">
                                {{'Valor mínimo: 0'}}
                            </p>
                            <p *ngIf="obj.currentMonth.entityAmount > obj.currentMonth.availableEntity" class="text-danger text-sm absolute">
                                {{'Valor máximo: '  + obj.currentMonth.availableEntity }}
                            </p>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.currentMonth.entityAmount | number: '1.2-2'}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class="text-center">
                {{ (obj.currentMonth.entityInKindAmount + obj.currentMonth.entityAmount) | number: '1.2-2'}}
            </td>
            <td>
                <div *ngIf="canProgram" class="flex align-items-center justify-content-center gap-2">
                    <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(obj)" class="p-button-rounded p-button-text"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(obj)" [disabled]="!getForm(obj)?.valid" class="p-button-rounded p-button-text p-button-success mr-2"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(obj, index)" class="p-button-rounded p-button-text p-button-danger"></button>
                </div>
            </td>
        </tr>
<!--        <tr *ngIf="budgets.length === ri + 1" class="bg-gray-100 font-bold">-->
<!--            <td class="text-center p-1" colspan="3">TOTAL</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem" >0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem" >0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="p-1" style="width:100px"></td>-->
<!--        </tr>-->
    </ng-template>
</p-table>
