import {IProject, IProjectAct} from "../../itcp";
import {User} from "../../core/user/interfaces";

export type VisitType = 'FORESTATION_REFORESTATION' | 'INFRASTRUCTURE' | 'PRODUCTION';
export type VisitStatus = 'PROGRAMMED' | 'IN_PROGRESS' | 'VISITED';
export interface IVisit {
    id?: number;
    projectId: number;
    project?: IProject;
    actId: number;
    act?: IProjectAct;
    visitDate: Date;
    userId: number;
    user?: User;
    visitType: VisitType;
    visitStatus: VisitStatus;
    description: string;
}
