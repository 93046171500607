<div class="">
    <p-breadcrumb [model]="breadcrumbs"></p-breadcrumb>
    <div class="card">
        <p-tabView>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <i class="pi pi-sync mr-2"></i>
                    <span>Asignación</span>
                </ng-template>

                <p-toolbar styleClass="mb-4 w-full clean-toolbar">
                    <div class="flex justify-content-between flex-column align-items-center sm:flex-row w-full">
                        <div class="p-input-icon-left mb-2 searcher">
                            <i class="pi pi-search"></i>
                            <input
                                pInputText
                                type="text"
                                class="w-full"
                                (input)="setFilter($event, 'filterGlobal', 'contains')"
                                placeholder="Buscar..."
                            />
                        </div>
                        <div class="my-2">
                            <button
                                appPermission
                                [resource]="resource"
                                [enableActions]="'canView'"
                                pButton
                                pRipple
                                label="Actualizar"
                                icon="pi pi-refresh"
                                class="p-button-info mr-2"
                                (click)="loadAll()"
                            ></button>
                        </div>
                    </div>
                </p-toolbar>

                <p-table
                    #dt
                    [value]="assignedProjects"
                    [loading]="loading"
                    [paginator]="true"
                    [rows]="6"
                    [showCurrentPageReport]="true"
                    currentPageReportTemplate="{first} al {last} de {totalRecords} items"
                    [rowsPerPageOptions]="[6, 12, 24]"
                    (selectionChange)="onSelectionChange($event)"
                    [globalFilterFields]="[
                    'project.name',
                    'physicalTech.primerNombre', 'physicalTech.segundoNombre', 'physicalTech.apellidoMaterno', 'physicalTech.apellidoPaterno',
                    'financialTech.primerNombre', 'financialTech.segundoNombre', 'financialTech.apellidoMaterno', 'financialTech.apellidoPaterno',
                    ]"
                >
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="bg-primary">
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                            </th>
                            <th class="bg-primary">Nro</th>
                            <th class="bg-primary">Proyecto</th>
                            <th class="bg-primary">Técnico Físico</th>
                            <th class="bg-primary">Técnico Financiero</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item>
                        <tr>
                            <td>
                                <p-tableCheckbox [value]="item"></p-tableCheckbox>
                            </td>
                            <td>{{item.id}}</td>
                            <td><div class="large-cell">{{item.project.name}}</div></td>
                            <td>
                                <p-dropdown
                                    [options]="physicalTechs"
                                    [(ngModel)]="item.physicalTech"
                                    [style]="{'width':'100%'}"
                                    scrollHeight="300px"
                                    [filter]="true"
                                    filterBy="primerNombre,segundoNombre,apellidoPaterno,apellidoMaterno"
                                    [resetFilterOnHide]="true"
                                    (onChange)="onChange($event, item, 'physical')">
                                    <ng-template let-item pTemplate="selectedItem">
                                        {{getFullName(item)}}
                                        <div class="text-xs text-gray-600">{{item.usuarioRoles[0]?.rol?.descripcionRol}}</div>
                                    </ng-template>
                                    <ng-template let-user pTemplate="item">
                                        <div class="item-drop1">
                                            <span class="item-value1">{{getFullName(user)}}</span>
                                            <div class="text-xs text-gray-600">{{user.usuarioRoles[0]?.rol?.descripcionRol}}</div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>

                            </td>
                            <td>
                                <p-dropdown
                                    [options]="financialTechs"
                                    [(ngModel)]="item.financialTech"
                                    [style]="{'width':'100%'}"
                                    scrollHeight="400px"
                                    [filter]="true"
                                    filterBy="primerNombre,segundoNombre,apellidoPaterno,apellidoMaterno"
                                    [resetFilterOnHide]="true"
                                    (onChange)="onChange($event, item, 'financial')">
                                    <ng-template let-item pTemplate="selectedItem">
                                        {{getFullName(item)}}
                                        <div class="text-xs text-gray-600">{{item.usuarioRoles[0]?.rol?.descripcionRol}}</div>
                                    </ng-template>
                                    <ng-template let-user pTemplate="item">
                                        <div class="item-drop1">
                                            <span class="item-value1">{{getFullName(user)}}</span>
                                            <div class="text-xs text-gray-600">{{user.usuarioRoles[0]?.rol?.descripcionRol}}</div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>

                <div class="w-full text-right mt-8">
                    <button
                        appPermission
                        [resource]="resource"
                        [enableActions]="'canCreate'"
                        class="p-button p-button-outlined px-6 py-4"
                        (click)="randomize()"
                    >
                        Sortear
                    </button>

                    <button
                        appPermission
                        [resource]="resource"
                        [enableActions]="'canCreate'"
                        class="ml-4 p-button p-button-primary px-6 py-4"
                        [disabled]="!assignedAndSelectedProjects.length"
                        (click)="save()"
                    >
                        Asignar
                    </button>
                </div>
            </p-tabPanel>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <i class="pi pi-thumbs-up mr-2"></i>
                    <span>Asignados</span>
                </ng-template>
                <p-table
                    [value]="storedAssignedProjects"
                    [loading]="loading"
                    [paginator]="true"
                    [rows]="6"
                    [showCurrentPageReport]="true"
                    currentPageReportTemplate="{first} al {last} de {totalRecords} items"
                    [rowsPerPageOptions]="[6, 12, 24]"
                >
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="bg-primary">Nro</th>
                            <th class="bg-primary">Proyecto</th>
                            <th class="bg-primary">Técnicos Asignados</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                        <tr>
                            <td>{{rowIndex + 1}}</td>
                            <td><div class="large-cell">{{item.name}}</div></td>
                            <td>
                                <div *ngFor="let tech of item.technicians" class="mb-4 w-full grid align-items-center">
                                    <div class="col-12 md:col-6">
                                        {{getFullName(tech.user)}}
                                        <div class="text-xs text-gray-600">{{tech.user.usuarioRoles[0]?.rol?.descripcionRol}}</div>
                                    </div>

                                    <div class="col-12 md:col-6">
                                        <p-dropdown
                                            [options]="techniciansPermissions"
                                            optionLabel="label"
                                            placeholder="Seleccione"
                                            optionValue="value"
                                            [style]="{ width: '100%' }"
                                            (onChange)="changePermissions($event, tech)"
                                            [(ngModel)]="tech.permissions" [ngModelOptions]="{standalone: true}"
                                        >
                                        </p-dropdown>
                                    </div>
                                </div>
                            </td>
                            <td>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
