import { ProjectModel, ProjectService } from '../../itcp';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from '../../shared/services/toaster.service';
import {CryptoService} from "../../shared";

export abstract class ProjectAssessmentModel extends ProjectModel{

    protected constructor(route: ActivatedRoute, service: ProjectService, tService: ToasterService, cryto: CryptoService) {
        super(route, service, tService, cryto);
    }

    override setSteps() {
        this.steps = [
            {
                step: 1,
                text: 'Cumplimiento del Contenido Referencial del EDTP',
                icon: 'pi pi-check-square',
                path: `/edtp/assessment/${this.params['id']}/check-content`,
            },
            {
                step: 2,
                text: 'Observaciones',
                icon: 'pi pi-eye',
                path: `/edtp/assessment/${this.params['id']}/obs-step1`,
            },
            {
                step: 3,
                text: 'Revisión de Observaciones',
                icon: 'pi pi-check-circle',
                path: `/edtp/assessment/${this.params['id']}/obs-step2`,
            },
            {
                step: 4,
                text: 'Valoración Cuantitativa',
                icon: 'pi pi-list',
                path: `/edtp/assessment/${this.params['id']}/quantitative`,
            },
            {
                step: 5,
                text: 'Ponderación',
                icon: 'pi pi-tag',
                path: `/edtp/assessment/${this.params['id']}/weighing`,
            },
        ]
    }
}
