<div class="layout-topbar">
  <a class="layout-topbar-logo" routerLink="">
      <!-- SOME ICON HERE -->
       <img src="assets/images/logo.png" alt="logo" id='fonabosque-logo-img'>
  </a>

  <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
      <i class="pi pi-bars"></i>
  </button>

  <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
      <i class="pi pi-ellipsis-v"></i>
  </button>

  <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
      <button class="p-link p-1" (click)="menu.toggle($event)">
          <div class="flex gap-2 justify-content-end align-items-center layout-topbar-button">
              <div class="layout-topbar-button">
                  <i class="pi pi-user"></i>
                  <span>Profile</span>
              </div>
              <div>
                  <label>{{getCurrentUserName()}}</label>
                  <p class="text-sm text-gray-600">{{getCurrentRole}}</p>
              </div>
          </div>
      </button>

      <p-menu #menu [appendTo]="'body'" [popup]="true" [model]="items"></p-menu>
  </div>
</div>
