import {Component, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {
    IProjectGoalActivity,
    IProjectGoalResult,
} from "../../../../../../edtp";
import {IProjectGoal} from "../../../../../../itcp";
import {Table} from "primeng/table";

@Component({
    selector: 'app-summary-financial-activities',
    templateUrl: './activities.component.html',
    styleUrls: ['./activities.component.scss']
})
export class SummaryFinancialActivitiesComponent implements OnInit, OnChanges {
    @Input() group!: IProjectGoalResult;
    @Input() goals!: IProjectGoal[];
    @Input()  totalProjectDays: number = 0;
    @Input() selectedYear!: number;

    cloned: { [s: string]: IProjectGoalActivity; } = {};
    selectedGroup!: IProjectGoal;

    @ViewChild('dt') table!: Table;

    constructor() {
    }

    ngOnInit(): void {
        if (!this.group.activities)
            this.group.activities = [];
    }

    onSelectItem(event: any) {
        const item = this.goals.find(c => c.id === event.value);
        if (item) this.selectedGroup = { ...item };
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['selectedYear']) {
            this.group.activities.map(act => {
                if (act.yearsProgrammed?.length) {
                    act.expectedYear = 0;
                    for (const yp of act.yearsProgrammed) {
                        if (yp.year === this.selectedYear){
                            act.expectedYear = yp.expected;
                            break;
                        }
                    }
                }
            });
        }
    }
}
