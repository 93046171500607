import {Component, OnDestroy, OnInit} from '@angular/core';
import {ProjectEdtpModel} from "../../../models";
import {ActivatedRoute} from "@angular/router";
import {ProjectService} from "../../../../itcp";
import {CryptoService, SettingsService} from "../../../../shared";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToasterService} from "../../../../shared/services/toaster.service";
import {IProjectCost} from "../../../models/interfaces/project-cost.interface";
import {ProjectCostService} from "../../../services/project-cost.service";
import {LayoutService} from "../../../../shared/services/app.layout.service";

@Component({
  selector: 'app-costs',
  templateUrl: './costs.component.html',
  styleUrls: ['./costs.component.scss']
})
export class CostsComponent extends ProjectEdtpModel implements OnInit, OnDestroy {

    private step: number = 25;
    private objForm!: FormGroup;
    tangible: IProjectCost[] = []
    intangible: IProjectCost[] = []
    workingCapital: IProjectCost[] = []

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly costService: ProjectCostService,
        private readonly setService: SettingsService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private layoutService: LayoutService,
        private crypto: CryptoService,
    ) {
        super(activatedRoute, projectService, tService, setService, crypto);
    }

    ngOnInit(): void {
        this.layoutService.showMenuDesktop(true);
        this.readParams();
        this.setActive(this.step);
        this.service.get(this.params['id']).then((project) => {
            const {id, name} = project || {};
            this.currentProject = {...project};
            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required]
            });
            const projectId = id || 0;
            return this.costService.getAll({ params: { projectId } });
        }).then((resp) => {
            this.initializeCosts(resp);
        }).finally(() => {
            this.setSteps();
            this.setActive(this.step); //We need to reload the steps and put as active the current step
            this.disableFormIfSubmitted();
            this.showContent = true;
        });

    }

    initializeCosts(costs: IProjectCost[]) {
        this.initializeTangible();
        this.initializeIntangible();
        this.initializeWorkingCapital();
        costs.map(c => this.initializeCost(c));
    }

    initializeCost(cost: IProjectCost) {
        switch (cost.type) {
            case "TANGIBLE":
                this.initializeTangible(cost);
                break;
            case "INTANGIBLE":
                this.initializeIntangible(cost);
                break;
            case "WORKING_CAPITAL":
                this.initializeWorkingCapital(cost);
                break;
        }
    }

    private initializeTangible(cost?: IProjectCost) {
        const tangible:IProjectCost = {
            infrastructure: 0, machines: 0, projectId: 0, training: 0, consultancy: 0, type: 'TANGIBLE'
        }

        if (cost) {
            tangible.id = cost.id;
            tangible.infrastructure = cost.infrastructureAmount || 0;
            tangible.machines = cost.machinesAmount || 0;
            tangible.consultancy = cost.consultancyAmount || 0;
            tangible.type = cost.type;
        }

        this.tangible = [tangible];
    }

    private initializeWorkingCapital(cost?: IProjectCost) {
        const workingCapital:IProjectCost = {
            infrastructure: 0, machines: 0, projectId: 0, training: 0, consultancy: 0, type: 'WORKING_CAPITAL'
        }
        if (cost) {
            workingCapital.id = cost.id;
            workingCapital.infrastructure = cost.infrastructureAmount || 0;
            workingCapital.machines = cost.machinesAmount || 0;
            workingCapital.type = cost.type;
            workingCapital.consultancy = cost.consultancyAmount || 0;
        }

        this.workingCapital = [workingCapital];
    }

    private initializeIntangible(cost?: IProjectCost) {
        const intangible:IProjectCost = {
            infrastructure: 0, machines: 0, projectId: 0, training: 0, consultancy: 0, type: 'INTANGIBLE'
        }

        if (cost) {
            intangible.id = cost.id;
            intangible.training = cost.trainingAmount || 0;
            intangible.machines = cost.machinesAmount || 0;
            intangible.type = cost.type;
            intangible.consultancy = cost.consultancyAmount || 0;
        }

        this.intangible = [intangible];
    }

    onEditInit(data: any ) {
        const projectId = parseInt(this.params['id']);
        this.objForm = this.fb.group({
            infrastructure: [data.data.infrastructure, Validators.required],
            machines: [data.data.machines, Validators.required],
            training: [data.data.training, Validators.required],
            consultancy: [data.data.consultancy, Validators.required],
            type: [data.data.type, Validators.required],
            projectId: [projectId, Validators.required],
        });
    }

    onEditComplete(data: any) {
        const previous = this.objForm.value;
        const id = data.data.id;
        this.objForm.patchValue({
            infrastructure: data.data.infrastructure,
            machines: data.data.machines,
            training: data.data.training,
            consultancy: data.data.consultancy,
            type: data.data.type,
        });

        if (this.objForm.valid && this.hasChanged(previous)) {
            if (id) {
                this.costService.update(this.objForm.value, id).then((resp) => {
                    this.toasterService.success('Los datos han sido actualizados satisfactoriamente.')
                })
            } else {
                this.costService.store(this.objForm.value).then((resp) => {
                    this.initializeCost(resp);
                    this.toasterService.success('Los datos han sido creados satisfactoriamente.')
                })
            }
        }
    }

    hasChanged(previous: any): boolean {
        const { infrastructure: infrastructurePrev, machines: machinesPrev, training: trainingPrev, consultancy: consultancyPrev } = previous;
        const { infrastructure, machines, training, consultancy } = this.objForm.value;

        return !(infrastructurePrev === infrastructure && machinesPrev === machines && trainingPrev === training && consultancyPrev === consultancy);
    }

    ngOnDestroy() {
        this.cleanParams();
    }
}
