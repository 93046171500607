import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {Constants} from "../shared/constants";
import {
    ProgrammerActivitiesComponent,
    AssignmentComponent,
    ProcessOrdersComponent,
    EvaluationComponent,
    FinancialComponent,
    FinancialDetailsComponent,
    MonitoringComponent,
    MonitoringFinancialComponent,
    MonitoringPhysicalComponent,
    PhysicalComponent,
    PhysicalDetailsComponent,
    ProgrammerComponent,
    ProjectDetailsComponent,
    ReportFinancialComponent,
    ReportPhysicalComponent,
    StartOrderComponent,
    ChangeOrderComponent,
    ProjectDocsComponent,
    SummaryPhysicalComponent,
    SummaryActivitiesComponent,
    SummaryWeekActivitiesComponent,
    SummaryFinancialComponent,
    ProjectEndsComponent,
    ScheduleComponent,
    ExpostComponent,
    EvalComponent,
} from "./components";

const routes: Routes = [
    { path: '', component: AssignmentComponent, data: { moduleNameCode: Constants.RESOURCES.TRACKING_ASSIGNMENTS } },
    { path: 'assignments', component: AssignmentComponent, data: { moduleNameCode: Constants.RESOURCES.TRACKING_ASSIGNMENTS } },
    {
        path: 'programmer',
        data: { moduleNameCode: Constants.RESOURCES.TRACKING_PROGRAMMER },
        children: [
            { path: '', component: ProgrammerComponent },
            { path: ':id/physical', component: PhysicalComponent },
            { path: ':id/physical-details', component: PhysicalDetailsComponent },
            { path: ':id/financial', component: FinancialComponent },
            { path: ':id/financial-details', component: FinancialDetailsComponent },
            { path: ':id/activities', component: ProgrammerActivitiesComponent },
            { path: ':id/schedule', component: ScheduleComponent },
        ],
    },
    {
        path: 'monitoring',
        data: { moduleNameCode: Constants.RESOURCES.TRACKING_MONITORING },
        children: [
            { path: '', component: MonitoringComponent },
            { path: ':id/physical', component: MonitoringPhysicalComponent },
            { path: ':id/financial', component: MonitoringFinancialComponent },
        ],
    },
    {
        path: 'evaluation',
        data: { moduleNameCode: Constants.RESOURCES.TRACKING_EVALUATION },
        children: [
            { path: '', component: EvaluationComponent },
            { path: ':id/physical', component: ReportPhysicalComponent },
            { path: ':id/financial', component: ReportFinancialComponent },
        ],
    },
    {
        path: 'orders',
        data: { moduleNameCode: Constants.RESOURCES.TRACKING_ORDERS },
        children: [
            { path: '', component: ProcessOrdersComponent },
            { path: ':id/start-order', component: StartOrderComponent },
            { path: ':id/change-order', component: ChangeOrderComponent },
        ]
    },
    {
        path: 'project-details',
        data: { moduleNameCode: Constants.RESOURCES.TRACKING_PROJECT_DETAILS },
        children: [
            { path: '', component: ProjectDetailsComponent },
            {
                path: ':id/ends',
                data: { moduleNameCode: Constants.RESOURCES.CLOSE_PROJECT },
                children: [
                    { path: '', component: SummaryActivitiesComponent },
                    { path: 'physical', component: SummaryPhysicalComponent },
                    { path: 'financial', component: SummaryFinancialComponent },
                    { path: 'activities', component: SummaryActivitiesComponent },
                    { path: 'done', component: ProjectEndsComponent },
                ],
            },
            { path: ':id/docs', component: ProjectDocsComponent },
        ]
    },
    {
        path: 'expost',
        data: { moduleNameCode: Constants.RESOURCES.PROJECT_EXPOST },
        children: [
            { path: '', component: ExpostComponent },
            { path: ':id/eval', component: EvalComponent },
        ]
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TrackingRoutingModule { }
