import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ProjectProgrammerModel} from "../../../models";
import {IProjectGoalActivity, IProjectGoalResult, ProjectGoalResultService} from "../../../../edtp";
import {Table} from "primeng/table";
import {ActivatedRoute} from "@angular/router";
import {IProjectGoal, ProjectGoalService, ProjectService} from "../../../../itcp";
import {CryptoService, SettingsService} from "../../../../shared";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToasterService} from "../../../../shared/services/toaster.service";
import {ConfirmationService} from "primeng/api";
import {LayoutService} from "../../../../shared/services/app.layout.service";
import {Constants} from "../../../../shared/constants";

@Component({
    selector: 'app-schedule',
    templateUrl: './schedule.component.html',
    styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent extends ProjectProgrammerModel implements OnInit, OnDestroy {
    private step: number = 1;
    results: IProjectGoalResult[] = [];
    clonedResults: { [s: string]: IProjectGoalResult; } = {};
    goals: IProjectGoal[] = [];

    @ViewChild('dt') table!: Table;

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly goalService: ProjectGoalService,
        private readonly setService: SettingsService,
        private readonly goalResultService: ProjectGoalResultService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private readonly layoutService: LayoutService,
        private readonly confirmationService: ConfirmationService,
        private crypto: CryptoService,
    ) {
        super(activatedRoute, projectService, tService, setService, crypto);
    }

    ngOnInit(): void {
        this.layoutService.showMenuDesktop(false);
        this.readParams();
        this.setActive(this.step);
        this.load();
    }

    load() {
        this.service.get(this.params['id']).then((project) => {
            const {id, name, goals, status } = project || {};
            this.currentProject = {...project};
            if (goals?.length) this.goals = [ ...goals ];

            let newStatus = status;
            if (status === Constants.PROJECT_STATUSES.ASSIGNED_TRACKING)
                newStatus = Constants.PROJECT_STATUSES.PROGRAMMING;

            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required],
                status: [newStatus],
            });

            const projectId = id || 0;
            return this.goalResultService.getAll({ params: { projectId, programmerType: 'FS' }});
        }).then((resp) => {
            this.calculateDaysActivities(resp);
            this.results = [ ...resp ];
        }).finally(() => {
            this.disableFormIfSubmitted();
            this.calculateYears();
            this.checkEditableForm();
            this.showContent = true;
        });
    }

    onNew() {
        const result: IProjectGoalResult = {
            id: `new_${new Date().getTime()}`,
            projectId: parseInt(this.params['id']),
            goalId: 0,
            expectedResult: '',
            activities: [],
        }
        this.results.push(result);
        this.table.initRowEdit(this.table.value[this.results.length - 1]);
    }

    onRowEditInit(obj: IProjectGoalResult) {
        if (!obj?.id) return;
        this.clonedResults[obj.id] = {...obj};
    }

    onRowEditCancel(obj: IProjectGoalResult, index: number) {
        if (!obj?.id) return;

        if (this.clonedResults[obj.id]) {
            this.results[index] = this.clonedResults[obj.id];
            delete this.clonedResults[obj.id];
        }

        if (obj?.id.toString().includes('new_')) {
            this.results = this.results.filter(g => g.id !== obj.id);
        }
    }

    onRowEditSave(obj: IProjectGoalResult) {
        if (!obj?.id) return;

        const form: FormGroup = this.fb.group({
            projectId: [obj.projectId, Validators.required],
            goalId: [obj.goalId, Validators.required],
            expectedResult: [obj.expectedResult, Validators.required],
        });

        if (form.valid) {
            delete this.clonedResults[obj.id];

            if (obj?.id.toString().includes('new_')) {
                const index = this.results.indexOf(obj);
                this.goalResultService.store(form.value).then((resp) => {
                    this.results[index] = resp;
                    this.toasterService.success('El Registro fue creado satisfactoriamente.');
                });

            } else {
                const id = parseInt(obj.id.toString());
                this.goalResultService.update(form.value, id).then((resp) => {
                    this.toasterService.success('El Registro fue actualizado satisfactoriamente.');
                })
            }

        } else {
            this.toasterService.error('No se pudo guardar, los datos son inválidos!');
        }

    }

    onRemove(obj: IProjectGoalResult) {
        if (!obj.id) return;

        const id = parseInt(obj.id.toString());
        this.confirmationService.confirm({
            message: 'Estas seguro de eliminar este registro?',
            accept: () => {
                this.goalResultService
                    .delete(id)
                    .then((resp: boolean) => {
                        if (resp) {
                            this.toasterService.success('Se Elimino correctamente!');
                            this.load();
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                        this.toasterService.error(err.error.message);
                    });
            },
        });
    }

    onActivitiesChange(act: IProjectGoalActivity){
        this.load();
    }

    ngOnDestroy() {
        this.cleanParams();
    }

}
