import { Injectable } from '@angular/core';

import { BaseHttpService, BaseService } from '../../shared';
import { IProjectC2 } from '../models/interfaces/project-c2.interface';

@Injectable({
  providedIn: 'root',
})
export class ProjectC2Service extends BaseService<IProjectC2> {
  constructor(private readonly httpService: BaseHttpService) {
    super(httpService, 'project-c2');
  }
}
