import {Component, OnDestroy, OnInit} from '@angular/core';
import {ProjectModel} from "../../../models";
import {ActivatedRoute} from "@angular/router";
import {ProjectService} from "../../../services";
import {ToasterService} from "../../../../shared/services/toaster.service";
import { FormBuilder, Validators } from '@angular/forms';
import { Constants } from '../../../../shared/constants';
import {ConfirmationService} from "primeng/api";
import {CryptoService} from "../../../../shared";

@Component({
  selector: 'app-done',
  templateUrl: './done.component.html',
  styleUrls: ['./done.component.scss']
})
export class DoneComponent extends ProjectModel implements OnInit, OnDestroy {

    private step: number = 15;

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private readonly confirmationService: ConfirmationService,
        private crypto: CryptoService,
    ) {
        super(activatedRoute, projectService, tService, crypto);
    }

    ngOnInit(): void {
        this.readParams();
        this.setActive(this.step);

        this.service.get(this.params['id']).then((project) => {
            const { id, name } = project || {};
            this.currentProject = { ...project };
            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required],
                status: [Constants.PROJECT_STATUSES.SUBMITTED],
            });
        }).finally(() => {
            this.showContent = true;
        });
    }

    onFinish(event: any) {
        this.confirmationService.confirm({
            message: '¿Estas seguro de enviar el formulario?. Una vez enviado ya no podra realizar cambios.',
            accept: () => {
                this.saveSkipValidation(event);
            },
        });
    }

    ngOnDestroy() {
        this.cleanParams();
    }
}
