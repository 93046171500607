import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputFileComponent } from './input-file/input-file.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ReactiveFormsModule } from '@angular/forms';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PhoneNumberInputComponent } from './phone-number-input/phone-number-input.component';
import {ProjectStatusComponent} from "./project-status/project-status.component";
import {BadgeModule} from "primeng/badge";

@NgModule({
  declarations: [InputFileComponent, PhoneNumberInputComponent, ProjectStatusComponent],
    imports: [CommonModule, NgxDropzoneModule, ReactiveFormsModule, ProgressSpinnerModule, BadgeModule],
  exports: [InputFileComponent, PhoneNumberInputComponent, ProjectStatusComponent],
})
export class CustomFormModule {}
