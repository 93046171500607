<app-wizard
  [steps]='steps'
  [current]='currentStep'
  [bodyContentTpl]='assessmentsForm'
  [nextDisabled]='this.formGroup?.invalid'
  (onFinish)='store()'
  [layout]='true'
  [lastStep]='true'
>
</app-wizard>
<ng-template #assessmentsForm>
  <div *ngIf='showForm' class='project-form flex justify-content-center flex-column' [formGroup]='formGroup'>
    <div class='grid'>
      <div class='field col-12'>
        <h3 class='p-text'>Valoracion del ITCP</h3>
      </div>
      <div class='col-12'>
        <p-table
          #dtTable
          dataKey='key'
          editMode='row'
          styleClass='p-datatable-gridlines'
          [tableStyle]="{ 'min-width': '100%' }"
          [filterDelay]='0'
          [formGroup]='formGroup'
          [value]='firstGroupValues'
        >
          <ng-template pTemplate='header'>
            <tr>
              <th class='bg-primary text-center' style='width: 30%'>Ambitos / Subambito / Aspectos de Analisis</th>
              <th class='bg-primary text-center' style='width: 30%'>Nota</th>
              <th class='bg-primary text-center' style='width: 30%'>Justificacion de la calificacion</th>
            </tr>
            <tr>
              <th class='text-center'>Ámbito 1 <small>({{getRangeValidation('FIRST')}} puntos)</small></th>
              <th *ngIf='firstGroupTotal' class='text-center'>{{ firstGroupTotal }}</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate='body' let-item let-editing='editing' let-ri='rowIndex'>
            <tr [pEditableRow]='item'>
              <td>{{ item.label }}</td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate='input'>
                    <input
                      pInputText
                      required
                      [min]='0'
                      [max]="getRangeValidation('FIRST')"
                      class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full'
                      type='number'
                      [(ngModel)]='item.score'
                      [ngModelOptions]='{ standalone: true }'
                    />

                    <div *ngIf="getForm(item, 'FIRST')?.invalid">
                      <p class='text-danger text-sm'>
                        {{'El valor máximo es ' + getRangeValidation('FIRST') }}.
                      </p>
                    </div>
                  </ng-template>
                  <ng-template pTemplate='output'>
                    <div class='text-center'>{{ item.score }}</div>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate='input'>
                    <input
                      pInputText
                      required
                      class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full'
                      type='text'
                      [(ngModel)]='item.justification'
                      [ngModelOptions]='{ standalone: true }'
                    />
                  </ng-template>
                  <ng-template pTemplate='output'>
                    <div class='text-center'>{{ item.justification }}</div>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <div *ngIf='!isSubmitted' class='flex align-items-center justify-content-center gap-2'>
                  <button
                    *ngIf='!editing'
                    pButton
                    pRipple
                    type='button'
                    pInitEditableRow
                    icon='pi pi-pencil'
                    (click)='onRowEditInit(item)'
                    class='p-button-rounded p-button-text'
                  ></button>
                  <button
                    *ngIf='editing'
                    pButton
                    pRipple
                    type='button'
                    pSaveEditableRow
                    icon='pi pi-check'
                    [disabled]="getForm(item, 'FIRST')?.invalid"
                    (click)="onRowEditSave(item, 'FIRST', ri)"
                    class='p-button-rounded p-button-text p-button-success mr-2'
                  ></button>
                  <button
                    *ngIf='editing'
                    pButton
                    pRipple
                    type='button'
                    pCancelEditableRow
                    icon='pi pi-times'
                    (click)="onRowEditCancel(item, ri, 'FIRST')"
                    class='p-button-rounded p-button-text p-button-danger'
                  ></button>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <p-table
          #dtTable2
          dataKey='key'
          editMode='row'
          styleClass='p-datatable-gridlines'
          [tableStyle]="{ 'min-width': '100%' }"
          [filterDelay]='0'
          [formGroup]='formGroup'
          [value]='secondGroupValues'
        >
          <ng-template pTemplate='header'>
            <tr>
              <th class='text-center' style='width: 30%'>Ámbito 2 <small>({{getRangeValidation('SECOND')}} puntos)</small></th>
              <th class='text-center' style='width: 30%'>{{ secondGroupTotal || '' }}</th>
              <th class='text-center' style='width: 30%'></th>
              <th class='text-center' style='width: 10%'></th>
            </tr>
          </ng-template>

          <ng-template pTemplate='body' let-item let-editing='editing' let-ri='rowIndex'>
            <tr [pEditableRow]='item'>
              <td>{{ item.label }}</td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate='input'>
                    <input
                      pInputText
                      required
                      class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full'
                      type='number'
                      [min]='0'
                      [max]="getRangeValidation('SECOND')"
                      [(ngModel)]='item.score'
                      [ngModelOptions]='{ standalone: true }'
                    />


                    <div *ngIf="getForm(item, 'SECOND')?.invalid">
                      <p class='text-danger text-sm'>
                        {{'El valor máximo es ' + getRangeValidation('SECOND') }}.
                      </p>
                    </div>
                  </ng-template>
                  <ng-template pTemplate='output'>
                    <div class='text-center'>{{ item.score }}</div>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate='input'>
                    <input
                      pInputText
                      required
                      class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full'
                      type='text'
                      [(ngModel)]='item.justification'
                      [ngModelOptions]='{ standalone: true }'
                    />
                  </ng-template>
                  <ng-template pTemplate='output'>
                    <div class='text-center'>{{ item.justification }}</div>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <div *ngIf='!isSubmitted' class='flex align-items-center justify-content-center gap-2'>
                  <button
                    *ngIf='!editing'
                    pButton
                    pRipple
                    type='button'
                    pInitEditableRow
                    icon='pi pi-pencil'
                    (click)='onRowEditInit(item)'
                    class='p-button-rounded p-button-text'
                  ></button>
                  <button
                    *ngIf='editing'
                    pButton
                    pRipple
                    type='button'
                    pSaveEditableRow
                    icon='pi pi-check'
                    [disabled]='getForm(item, "SECOND")?.invalid'
                    (click)="onRowEditSave(item, 'SECOND', ri)"
                    class='p-button-rounded p-button-text p-button-success mr-2'
                  ></button>
                  <button
                    *ngIf='editing'
                    pButton
                    pRipple
                    type='button'
                    pCancelEditableRow
                    icon='pi pi-times'
                    (click)="onRowEditCancel(item, ri, 'SECOND')"
                    class='p-button-rounded p-button-text p-button-danger'
                  ></button>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <p-table
          #dtTable3
          dataKey='key'
          editMode='row'
          styleClass='p-datatable-gridlines'
          [tableStyle]="{ 'min-width': '100%' }"
          [filterDelay]='0'
          [formGroup]='formGroup'
          [value]='thirdGroupValues'
        >
          <ng-template pTemplate='header'>
            <tr>
              <th class='text-center' style='width: 30%'>Ámbito 3 <small>({{getRangeValidation('THIRD')}} puntos)</small></th>
              <th class='text-center' style='width: 30%'>{{ thirdGroupTotal || '' }}</th>
              <th class='text-center' style='width: 30%'></th>
              <th class='text-center' style='width: 10%'></th>
            </tr>
          </ng-template>
          <ng-template pTemplate='body' let-item let-editing='editing' let-ri='rowIndex'>
            <tr [pEditableRow]='item'>
              <td>{{ item.label }}</td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate='input'>
                    <input
                      pInputText
                      required
                      class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full'
                      type='number'
                      [(ngModel)]='item.score'
                      [min]='0'
                      [max]='getRangeValidation("THIRD")'
                      [ngModelOptions]='{ standalone: true }'
                    />

                    <div *ngIf="getForm(item, 'THIRD')?.invalid">
                      <p class='text-danger text-sm'>
                        {{'El valor máximo es ' + getRangeValidation('THIRD') }}.
                      </p>
                    </div>
                  </ng-template>
                  <ng-template pTemplate='output'>
                    <div class='text-center'>{{ item.score }}</div>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate='input'>
                    <input
                      pInputText
                      required
                      class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full'
                      type='text'
                      [(ngModel)]='item.justification'
                      [ngModelOptions]='{ standalone: true }'
                    />
                  </ng-template>
                  <ng-template pTemplate='output'>
                    <div class='text-center'>{{ item.justification }}</div>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <div *ngIf='!isSubmitted' class='flex align-items-center justify-content-center gap-2'>
                  <button
                    *ngIf='!editing'
                    pButton
                    pRipple
                    type='button'
                    pInitEditableRow
                    icon='pi pi-pencil'
                    (click)='onRowEditInit(item)'
                    class='p-button-rounded p-button-text'
                  ></button>
                  <button
                    *ngIf='editing'
                    pButton
                    pRipple
                    type='button'
                    pSaveEditableRow
                    icon='pi pi-check'
                    (click)="onRowEditSave(item, 'THIRD', ri)"
                    [disabled]='getForm(item, "THIRD")?.invalid'
                    class='p-button-rounded p-button-text p-button-success mr-2'
                  ></button>
                  <button
                    *ngIf='editing'
                    pButton
                    pRipple
                    type='button'
                    pCancelEditableRow
                    icon='pi pi-times'
                    (click)="onRowEditCancel(item, ri, 'THIRD')"
                    class='p-button-rounded p-button-text p-button-danger'
                  ></button>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <p-table
          #dtTable4
          dataKey='key'
          editMode='row'
          styleClass='p-datatable-gridlines'
          [tableStyle]="{ 'min-width': '100%' }"
          [filterDelay]='0'
          [formGroup]='formGroup'
          [value]='fourthGroupValues'
        >
          <ng-template pTemplate='header'>
            <tr>
              <th class='text-center' style='width: 30%'>Ámbito 4 <small>({{getRangeValidation('FOURTH')}} puntos)</small></th>
              <th class='text-center' style='width: 30%'>{{ fourthGroupTotal || '' }}</th>
              <th class='text-center' style='width: 30%'></th>
              <th class='text-center' style='width: 10%'></th>
            </tr>
          </ng-template>
          <ng-template pTemplate='body' let-item let-editing='editing' let-ri='rowIndex'>
            <tr [pEditableRow]='item'>
              <td>{{ item.label }}</td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate='input'>
                    <input
                      pInputText
                      required
                      class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full'
                      type='number'
                      [(ngModel)]='item.score'
                      [min]='0'
                      [max]='getRangeValidation("FOURTH")'
                      [ngModelOptions]='{ standalone: true }'
                    />

                    <div *ngIf="getForm(item, 'FOURTH')?.invalid">
                      <p class='text-danger text-sm'>
                        {{'El valor máximo es ' + getRangeValidation('FOURTH') }}.
                      </p>
                    </div>
                  </ng-template>
                  <ng-template pTemplate='output'>
                    <div class='text-center'>{{ item.score }}</div>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate='input'>
                    <input
                      pInputText
                      required
                      class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full'
                      type='text'
                      [(ngModel)]='item.justification'
                      [ngModelOptions]='{ standalone: true }'
                    />
                  </ng-template>
                  <ng-template pTemplate='output'>
                    <div class='text-center'>{{ item.justification }}</div>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <div *ngIf='!isSubmitted' class='flex align-items-center justify-content-center gap-2'>
                  <button
                    *ngIf='!editing'
                    pButton
                    pRipple
                    type='button'
                    pInitEditableRow
                    icon='pi pi-pencil'
                    (click)='onRowEditInit(item)'
                    class='p-button-rounded p-button-text'
                  ></button>
                  <button
                    *ngIf='editing'
                    pButton
                    pRipple
                    type='button'
                    pSaveEditableRow
                    icon='pi pi-check'
                    (click)="onRowEditSave(item, 'FOURTH', ri)"
                    [disabled]='getForm(item, "FOURTH")?.invalid'
                    class='p-button-rounded p-button-text p-button-success mr-2'
                  ></button>
                  <button
                    *ngIf='editing'
                    pButton
                    pRipple
                    type='button'
                    pCancelEditableRow
                    icon='pi pi-times'
                    (click)="onRowEditCancel(item, ri, 'FOURTH')"
                    class='p-button-rounded p-button-text p-button-danger'
                  ></button>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>

        <div class='mt-4 text-left font-bold' style='font-size: 24px'>
          Total Ambitos ( 1 + 2 + 3 + 4 ) = {{ totalProjectScore }}
        </div>
      </div>
    </div>
  </div>
</ng-template>
