<p-breadcrumb [model]='breadcrumbs'></p-breadcrumb>
<p-dialog
  [closable]='false'
  [showHeader]='false'
  [visible]='generatingPDF'
  [modal]='true'
  [style]="{ width: '50vw' }"
  [draggable]='false'
  [resizable]='false'
>
  <div class='text-center py-6'>Por favor espere mientras se genera el documento PDF .....</div>
</p-dialog>
<div class='container mt-3'>
  <div class='grid'>
    <form class='sm:col-12 d-flex align-items-center' [formGroup]='projectForm'>
      <div class='grid'>
        <div class='field lg:col-6 md:col-8 sm:col-12'>
          <ng-multiselect-dropdown
            [placeholder]="'Departamento'"
            [settings]='cityDropdownSettings'
            [data]='cities'
            formControlName='cityIds'
          >
          </ng-multiselect-dropdown>
        </div>
        <div class='field lg:col-4 md:col-4 sm:col-12'>
          <button
            pButton
            type='button'
            label='Filtrar'
            (click)='getAll()'
            [disabled]='this.projectForm.invalid'
          ></button>
        </div>
      </div>
    </form>
  </div>
</div>

<div *ngIf='!projects.length' class='text-center w-1'>No existen datos</div>

<p-tabView styleClass='tabview-custom' *ngIf='showData && projects.length'>
  <p-tabPanel>
    <ng-template pTemplate='header'>
      <i class='pi pi-list'></i>
      <span class='ml-2'>EDTP Financiamiento</span>
    </ng-template>

    <div class='grid'>
      <div class='field lg:col-4 md:col-6 sm:col-12'>
        <button
          pButton
          type='button'
          label='Exportar a PDF'
          (click)='generateFinancialPDF()'
          [disabled]='generatingPDF || !projects.length'
        ></button>
      </div>
    </div>

    <div class='container mt-3'>
      <div *ngIf='projects.length'>
        <p-table
          #dtTable
          [value]='projects'
          styleClass='p-datatable-gridlines'
          [resizableColumns]='true'
          responsiveLayout='scroll'
          scrollHeight='400px'
          [loading]='loading'
          currentPageReportTemplate='{first} al {last} de {totalRecords} items'
        >
          <ng-template pTemplate='header'>
            <tr>
              <th *ngIf='!generatingPDF' class='bg-primary text-center' style='width: 50px'>Nº</th>
              <th *ngIf='!generatingPDF' class='bg-primary text-center' style='width: 200px'>Usuario</th>
              <th class='bg-primary text-center' style='width: 150px'>Departamento</th>
              <th class='bg-primary text-center' style='width: 200px'>Provincia</th>
              <th class='bg-primary text-center' style='width: 200px'>Municipio</th>
              <th *ngIf='!generatingPDF' class='bg-primary text-center' style='width: 200px'>MAE</th>
              <th class='bg-primary text-center' style='width: 200px'>Nombre del proyecto</th>
              <th *ngIf='!generatingPDF' class='bg-primary text-center' style='width: 200px'>Beneficiario Varones</th>
              <th *ngIf='!generatingPDF' class='bg-primary text-center' style='width: 200px'>Beneficiario Mujeres</th>
              <th class='bg-primary text-center' style='width: 200px'>Total Beneficiarios</th>
              <th *ngIf='!generatingPDF' class='bg-primary text-center' style='width: 200px'>Varones Asistidos</th>
              <th *ngIf='!generatingPDF' class='bg-primary text-center' style='width: 200px'>Mujeres Asistidas</th>
              <th class='bg-primary text-center' style='width: 200px'>Total Asistidos</th>
              <th *ngIf='!generatingPDF' class='bg-primary text-center' style='width: 200px'>Varones Capacitados</th>
              <th *ngIf='!generatingPDF' class='bg-primary text-center' style='width: 200px'>Mujeres Capacitadas</th>
              <th class='bg-primary text-center' style='width: 200px'>Total Capacitados</th>
              <th class='bg-primary text-center' style='width: 200px'>Presupuesto</th>
              <th class='bg-primary text-center' style='width: 200px'>Presupuesto Entidad</th>
              <th class='bg-primary text-center' style='width: 200px'>Presupuesto Total</th>
              <th class='bg-primary text-center' style='width: 200px'>Estado del EDTP</th>
            </tr>
          </ng-template>
          <ng-template pTemplate='body' let-project let-ri='rowIndex'>
            <tr>
              <td *ngIf='!generatingPDF' class='text-center overflow-hidden' style='width: 50px'>{{ ri + 1 }}</td>
              <td *ngIf='!generatingPDF' class='text-center overflow-hidden' style='width: 200px'>
                {{ project?.iTCPRequest?.user?.correo }}
              </td>
              <td class='text-center overflow-hidden' style='width: 150px'>
                {{ project?.iTCPRequest?.entity?.city?.dep }}
              </td>
              <td class='text-center overflow-hidden' style='width: 200px'>
                {{ project?.iTCPRequest?.entity?.province?.prov }}
              </td>
              <td class='text-center overflow-hidden' style='width: 200px'>
                {{ project?.iTCPRequest?.entity?.town?.mun }}
              </td>
              <td *ngIf='!generatingPDF' class='text-center overflow-hidden' style='width: 200px'>
                {{ project?.iTCPRequest?.seoName }}
              </td>
              <td class='text-center overflow-hidden' style='width: 200px'>{{ project?.name }}</td>
              <td *ngIf='!generatingPDF' class='text-center overflow-hidden' style='width: 200px'>
                {{ project?.totalBenefitedMen }}
              </td>
              <td *ngIf='!generatingPDF' class='text-center overflow-hidden' style='width: 200px'>
                {{ project?.totalBenefitedWomen }}
              </td>
              <td class='text-center overflow-hidden' style='width: 200px'>
                {{ (project?.totalBenefitedMen || 0) + (project?.totalBenefitedWomen || 0) }}
              </td>
              <td *ngIf='!generatingPDF' class='text-center overflow-hidden' style='width: 200px'>
                {{ project?.totalAssistedMen }}
              </td>
              <td *ngIf='!generatingPDF' class='text-center overflow-hidden' style='width: 200px'>
                {{ project?.totalAssistedWomen }}
              </td>
              <td class='text-center overflow-hidden' style='width: 200px'>
                {{ (project?.totalAssistedMen || 0) + (project?.totalAssistedWomen || 0) }}
              </td>
              <td *ngIf='!generatingPDF' class='text-center overflow-hidden' style='width: 200px'>
                {{ project?.totalTrainedMen }}
              </td>
              <td *ngIf='!generatingPDF' class='text-center overflow-hidden' style='width: 200px'>
                {{ project?.totalTrainedWomen }}
              </td>
              <td class='text-center overflow-hidden' style='width: 200px'>
                {{ (project?.totalTrainedMen || 0) + (project?.totalTrainedWomen || 0) }}
              </td>
              <td class='text-center overflow-hidden' style='width: 200px'>{{ project?.totalOwnerInversionAmount }}</td>
              <td class='text-center overflow-hidden'
                  style='width: 200px'>{{ project?.totalEntityInversionAmount }}</td>
              <td class='text-center overflow-hidden' style='width: 200px'>
                {{ project.totalInversionAmount.toFixed(2) }}
              </td>
              <td class='text-center' style='width: 150px'>{{ projectStatuses[project.status] }}</td>
            </tr>
          </ng-template>
        </p-table>

        <div [ngClass]="{'grid mt-3': true, 'text-2xl': generatingPDF}" #chartPDFRef>
          <div class='field lg:col-4 md:col-6 sm:col-12'>
            <div class='mb-3'>
              <div class='flex'>
                <span class='font-semibold w-6 text-right'>Aprobado:</span>
                <span class='ml-2'>{{ this.totalApproved }}</span>
              </div>
              <div class='flex'>
                <span class='font-semibold w-6 text-right'>Deshabilitado:</span>
                <span class='ml-2'>{{ this.totalRejected }}</span>
              </div>
              <div class='flex'>
                <span class='font-semibold w-6 text-right'>Total: </span>
                <span class='ml-2'>{{ this.totalApproved + this.totalRejected }}</span>
              </div>
            </div>
          </div>

          <div class='field lg:col-4 md:col-6 sm:col-12'>
            <p-chart type='pie' [data]='approvedRejectedChartData'></p-chart>
          </div>
        </div>
      </div>

      <div *ngIf='flattedDataPerTown.length'>
        <div *ngIf='!generatingPDF' class='sm:col-12 mb-3'>
          <div class='text-lg font-semibold'>
            Listado de Municipio con EDTP aprobados total, por provincia y departamento con costo total del Proyecto,
            monto cofinanciado por Fonabosque y monto del GAM en Bs
          </div>
        </div>

        <div class='grid mt-3'>
          <div class='sm:col-12'>
            <p-table
              #dtSpecific
              [value]='flattedDataPerTown'
              styleClass='p-datatable-gridlines'
              [scrollable]='true'
              scrollHeight='400px'
              scrollDirection='both'
              [loading]='loading'
              currentPageReportTemplate='{first} al {last} de {totalRecords} items'
            >
              <ng-template pTemplate='header'>
                <tr>
                  <th class='bg-primary text-center' style='width: 50px'>Nº</th>
                  <th class='bg-primary text-center' style='width: 150px'>Departamento</th>
                  <th class='bg-primary text-center' style='width: 200px'>Provincia</th>
                  <th class='bg-primary text-center' style='width: 200px'>Municipio</th>
                  <th class='bg-primary text-center' style='width: 200px'>Presupuesto FONABOSQUE</th>
                  <th class='bg-primary text-center' style='width: 200px'>Presupuesto ENTIDAD</th>
                  <th class='bg-primary text-center' style='width: 200px'>Presupuesto TOTAL</th>
                </tr>
              </ng-template>
              <ng-template pTemplate='body' let-data let-ri='rowIndex'>
                <tr>
                  <td class='text-center overflow-hidden' style='width: 50px'>{{ ri + 1 }}</td>
                  <td class='text-center overflow-hidden' style='width: 150px'>{{ data.cityName }}</td>
                  <td class='text-center overflow-hidden' style='width: 200px'>{{ data.provinceName }}</td>
                  <td class='text-center overflow-hidden' style='width: 200px'>{{ data.townName }}</td>
                  <td class='text-center overflow-hidden' style='width: 200px'>{{ data.ownerAmount }}</td>
                  <td class='text-center overflow-hidden' style='width: 200px'>{{ data.entityAmount }}</td>
                  <td class='text-center overflow-hidden' style='width: 200px'>
                    {{ ((data.ownerAmount || 0) + (data.entityAmount || 0)).toFixed(2) }}
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate='footer'>
                <tr>
                  <td colspan='4' style='width: 600px' class='text-right'>Total</td>
                  <td class='text-center' style='width: 200px'>{{ this.totalOwnerCost.toFixed(2) }}</td>
                  <td class='text-center' style='width: 200px'>{{ this.totalEntityCost.toFixed(2) }}</td>
                  <td class='text-center' style='width: 200px'>{{ this.totalOwnerEntityCost.toFixed(2) }}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>

      <div class='sm:col-12 mt-3' #financialChartDataRef>
        <p-chart type='bar' [data]='financialChartData' [options]='stackedOptions'></p-chart>
      </div>
    </div>
  </p-tabPanel>

  <p-tabPanel>
    <ng-template pTemplate='header'>
      <i class='pi pi-list'></i>
      <span class='ml-2'>Coeficientes Costo Eficiencia</span>
    </ng-template>

    <div class='container mt-3'>
      <form class='sm:col-12 d-flex align-items-center' [formGroup]='projectCostForm'>
        <div class='grid'>
          <div class='field lg:col-5 md:col-6 sm:col-12'>
            <ng-multiselect-dropdown
              [placeholder]="'Proyectos'"
              [settings]='projectCostsDropdownSettings'
              [data]='projectNames'
              formControlName='projectNames'
            >
            </ng-multiselect-dropdown>
          </div>
          <div class='field lg:col-5 md:col-6 sm:col-12'>
            <ng-multiselect-dropdown
              [placeholder]="'Componentes'"
              [settings]='projectCostsDropdownSettings'
              [data]='componentNames'
              formControlName='componentNames'
            >
            </ng-multiselect-dropdown>
          </div>
          <div class='field lg:col-2 md:col-6 sm:col-12'>
            <button
              pButton
              type='button'
              label='Filtrar'
              (click)='filterByProjectAndComponentNames()'
              [disabled]='this.projectCostForm.invalid'
            ></button>
          </div>
        </div>
      </form>
    </div>

    <div class='grid'>
      <div class='field lg:col-4 md:col-6 sm:col-12'>
        <button pButton type='button' label='Exportar a PDF' (click)='generateCostEfficiencyPDF()'></button>
      </div>
    </div>

    <div class='container mt-3' *ngIf='filteredFlattedDataByComponent.length'>
      <p-table
        #dtCostEfficiencyTable
        [value]='filteredFlattedDataByComponent'
        styleClass='p-datatable-gridlines'
        [scrollable]='true'
        scrollHeight='400px'
        scrollDirection='both'
        [loading]='loading'
        currentPageReportTemplate='{first} al {last} de {totalRecords} items'
      >
        <ng-template pTemplate='header'>
          <tr>
            <th class='bg-primary text-center' style='width: 50px'>Nº</th>
            <th class='bg-primary text-center' style='width: 200px'>Proyecto</th>
            <th class='bg-primary text-center' style='width: 200px'>Componente</th>
            <th class='bg-primary text-center' style='width: 200px'>Costo</th>
            <th class='bg-primary text-center' style='width: 200px'>Capacidad</th>
            <th class='bg-primary text-center' style='width: 200px'>Cantidad</th>
            <th class='bg-primary text-center' style='width: 200px'>Unidad de medida</th>
            <th class='bg-primary text-center' style='width: 200px'>Coeficiente</th>
          </tr>
        </ng-template>
        <ng-template pTemplate='body' let-data let-ri='rowIndex'>
          <tr>
            <td class='text-center overflow-hidden' style='width: 50px'>{{ ri + 1 }}</td>
            <td class='text-center overflow-hidden' style='width: 200px'>{{ data.projectName }}</td>
            <td class='text-center overflow-hidden' style='width: 200px'>{{ data.componentType }}</td>
            <td class='text-center overflow-hidden' style='width: 200px'>{{ data.cost }}</td>
            <td class='text-center overflow-hidden' style='width: 200px'>{{ data.capacity }}</td>
            <td class='text-center overflow-hidden' style='width: 200px'>{{ data.quantity }}</td>
            <td class='text-center overflow-hidden' style='width: 200px'>{{ data.uom }}</td>
            <td class='text-center overflow-hidden' style='width: 200px'>
              {{ data.cost / data.quantity | number: '1.0-2' }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div class='grid mt-3' #costEfficiencyChartRef>
      <div class='sm:col-12'>
        <p-chart type='bar' [data]='projectEfficiencyChartData' [options]='barChartOptions'></p-chart>
      </div>
    </div>
  </p-tabPanel>

  <p-tabPanel>
    <ng-template pTemplate='header'>
      <i class='pi pi-dollar'></i>
      <span class='ml-2'>Presupuesto</span>
    </ng-template>

    <div class='container mt-3'>
      <form class='sm:col-12 d-flex align-items-center' [formGroup]='projectBudgetsForm'>
        <div class='grid'>
          <div class='field lg:col-5 md:col-6 sm:col-12'>
            <ng-multiselect-dropdown
              [placeholder]="'Proyectos'"
              [settings]='projectCostsDropdownSettings'
              [data]='projectNames'
              formControlName='projectNames'
            >
            </ng-multiselect-dropdown>
          </div>
          <div class='field lg:col-5 md:col-6 sm:col-12'>
            <ng-multiselect-dropdown
              [placeholder]="'Componentes'"
              [settings]='projectCostsDropdownSettings'
              [data]='componentNames'
              formControlName='componentNames'
            >
            </ng-multiselect-dropdown>
          </div>
          <div class='field lg:col-2 md:col-6 sm:col-12'>
            <button
              pButton
              type='button'
              label='Filtrar'
              (click)='filterBudgetsByProjectNameAndComponentType()'
              [disabled]='this.projectBudgetsForm.invalid'
            ></button>
          </div>
        </div>
      </form>

      <div class='grid'>
        <div class='field lg:col-4 md:col-6 sm:col-12'>
          <button pButton type='button' label='Exportar a PDF' (click)='generateProjectBudgetsPDF()'></button>
        </div>
      </div>
    </div>

    <div class='container mt-3' *ngIf='filteredProjectBudgets.length'>
      <p-table
        #dtProjectBudgetsTable
        [value]='filteredProjectBudgets'
        styleClass='p-datatable-gridlines'
        [loading]='loading'
        rowGroupMode='rowspan'
        groupRowsBy='projectId'
      >
        <ng-template pTemplate='header'>
          <tr>
            <th class='bg-primary text-center' style='width: 50px'>Nro</th>
            <th class='bg-primary text-center' style='width: 200px'>Proyecto</th>
            <th class='bg-primary text-center' style='width: 200px'>Componente</th>
            <th class='bg-primary text-center' style='width: 200px'>FONABOSQUE</th>
            <th class='bg-primary text-center' style='width: 200px'>ENTIDAD (Bs.)</th>
            <th class='bg-primary text-center' style='width: 200px'>ENTIDAD EN ESPECIE (Bs.)</th>
            <th class='bg-primary text-center' style='width: 200px'>TOTAL (Bs.)</th>
          </tr>
        </ng-template>
        <ng-template pTemplate='body' let-data let-ri='rowIndex' let-rowgroup='rowgroup' let-rowspan='rowspan'>
          <tr>
            <td class='text-center overflow-hidden' style='width: 50px'>{{ ri + 1 }}</td>
            <td *ngIf='rowgroup' [attr.rowspan]='rowspan' class='text-center overflow-hidden' style='width: 200px'>
              {{ data.project.name }}
            </td>
            <td class='text-center overflow-hidden' style='width: 200px'>{{ data.componentType }}</td>
            <td class='text-center overflow-hidden' style='width: 200px'>{{ data.totals.owner | number: '1.2' }}</td>
            <td class='text-center overflow-hidden' style='width: 200px'>{{ data.totals.entity | number: '1.2' }}</td>
            <td class='text-center overflow-hidden'
                style='width: 200px'>{{ data.totals.entityInKind | number: '1.2' }}</td>
            <td class='text-center overflow-hidden' style='width: 200px'>{{ data.totals.total }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div class='grid mt-3' #projectBudgetChartRef>
      <div class='sm:col-12'>
        <p-chart type='bar' [data]='projectBudgetChartData' [options]='barChartOptions'></p-chart>
      </div>
    </div>

    <div class='container mt-3' *ngIf='filteredProjectBudgetsVIPFEE.length'>
      <h4>Reporte VIPFEE</h4>
      <p-table
        #dtProjectBudgetsVIPFEETable
        [value]='filteredProjectBudgetsVIPFEE'
        styleClass='p-datatable-gridlines'
        [loading]='loading'
        rowGroupMode='rowspan'
        groupRowsBy='projectId'
      >
        <ng-template pTemplate='header'>
          <tr>
            <th class='bg-primary text-center' style='width: 50px'>Nro</th>
            <th class='bg-primary text-center' style='width: 200px'>Proyecto</th>
            <th class='bg-primary text-center' style='width: 200px'>Componente</th>
            <th class='bg-primary text-center' style='width: 200px'>FONABOSQUE</th>
            <th class='bg-primary text-center' style='width: 200px'>ENTIDAD (Bs.)</th>
            <th class='bg-primary text-center' style='width: 200px'>ENTIDAD EN ESPECIE (Bs.)</th>
            <th class='bg-primary text-center' style='width: 200px'>TOTAL (Bs.)</th>
          </tr>
        </ng-template>
        <ng-template pTemplate='body' let-data let-ri='rowIndex' let-rowgroup='rowgroup' let-rowspan='rowspan'>
          <tr>
            <td class='text-center overflow-hidden' style='width: 50px'>{{ ri + 1 }}</td>
            <td *ngIf='rowgroup' [attr.rowspan]='rowspan' class='text-center overflow-hidden' style='width: 200px'>
              {{ data.project.name }}
            </td>
            <td [className]='!data.type ? "bg-primary border-0 thin" : ""'
                style='width: 200px'>{{ !data.type ? 'TOTAL ' + data.componentType : data.componentType }}</td>
            <td [className]='!data.type ? "bg-primary border-0 thin" : ""'
                style='width: 200px'>{{ data.totals.owner | number: '1.2' }}</td>
            <td [className]='!data.type ? "bg-primary border-0 thin" : ""'
                style='width: 200px'>{{ data.totals.entity | number: '1.2' }}</td>
            <td [className]='!data.type ? "bg-primary border-0 thin" : ""'
                style='width: 200px'>{{ data.totals.entityInKind | number: '1.2' }}</td>
            <td [className]='!data.type ? "bg-primary border-0 thin" : ""'
                style='width: 200px'>{{ data.totals.total }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>

  </p-tabPanel>
</p-tabView>
