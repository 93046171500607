<app-wizard
    [steps]='steps'
    [current]='currentStep'
    [bodyContentTpl]='projectForm'
    [nextDisabled]='this.formGroup?.invalid'
    (onClickNext)='save($event)'
    [layout]='true'
>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column' [formGroup]='formGroup'>
        <div class='grid'>
            <div class="field col-12 text-2xl text-primary uppercase">
                {{currentStep?.text}}
            </div>

            <div class="field col-12">
                <p-table [tableStyle]="{'min-width': '100%'}">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="bg-primary" style="width:5%">Nro.</th>
                            <th class="bg-primary" style="width:35%">DESCRIPCIÓN</th>
                            <th class="bg-primary" style="width:15%">FONABOSQUE</th>
                            <th class="bg-primary" style="width:15%">ENTIDAD(Bs.)</th>
                            <th class="bg-primary" style="width:15%">TOTAL (Bs.)</th>
                        </tr>
                    </ng-template>
                </p-table>

                <app-budget-vipfe-table
                    title="COMPONENTE 1"
                    [componentType]="'COMPONENT1'"
                    [projectBudgets]="projectBudgets"
                >
                </app-budget-vipfe-table>
                <app-budget-vipfe-table
                    title="COMPONENTE 2"
                    [componentType]="'COMPONENT2'"
                    [projectBudgets]="projectBudgets"
                >
                </app-budget-vipfe-table>
                <app-budget-vipfe-table
                    title="COMPONENTE 3"
                    [componentType]="'COMPONENT3'"
                    [projectBudgets]="projectBudgets"
                >
                </app-budget-vipfe-table>
                <app-budget-vipfe-table
                    title="COMPONENTE 4"
                    [componentType]="'COMPONENT4'"
                    [projectBudgets]="projectBudgets"
                >
                </app-budget-vipfe-table>
                <app-budget-vipfe-table
                    title="COMPONENTE 5"
                    [componentType]="'COMPONENT5'"
                    [projectBudgets]="projectBudgets"
                >
                </app-budget-vipfe-table>
                <app-budget-vipfe-table
                    title="ADMINISTRACIÓN"
                    [componentType]="'ADMINISTRATION'"
                    [projectBudgets]="projectBudgets"
                >
                </app-budget-vipfe-table>


                <app-budget-vipfe-table
                    title="AUDITORÍA"
                    [componentType]="'AUDIT'"
                    [projectBudgets]="projectBudgets"
                >
                </app-budget-vipfe-table>
            </div>
        </div>
    </div>
</ng-template>
