import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { get, size } from 'lodash-es';
import { Observable } from 'rxjs';
import { CredentialsService } from '../services';
import { PermissionService } from '../services/permission.service';

@Injectable({
  providedIn: 'root',
})
export class SystemPermissionGuard implements CanActivateChild {
  public userData: any = this.credentialsService.getLoggedUser();
  public rights: any = this.credentialsService.getCreds();
  constructor(
    private readonly credentialsService: CredentialsService,
    private readonly permissionService: PermissionService,
    private router: Router,
  ) {}
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const resourceName = get(childRoute, 'data.moduleNameCode', null);
    if (resourceName) {
      if (size(this.rights) > 1) {
        const permission = this.permissionService.handleAccess(resourceName, 'canView', false);
        if (permission) {
          return true;
        }
        this.router.navigate(['/access']);
        return false;
      }
    }
    return true;
  }
}
