import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { BudgetSummaryHelper, ProjectEdtpModel } from '../../../models';
import {ActivatedRoute} from "@angular/router";
import {ProjectService} from "../../../../itcp";
import {BudgetItemService, CryptoService, SettingsService} from "../../../../shared";
import {FormBuilder, Validators} from "@angular/forms";
import {ToasterService} from "../../../../shared/services/toaster.service";
import {IProjectBudget} from "../../../models/interfaces/project-budget.interface";
import {ProjectBudgetService} from "../../../services";
import {IBudgetItem} from "../../../../shared/interfaces/budget-item.interface";
import {LayoutService} from "../../../../shared/services/app.layout.service";

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.scss']
})
export class BudgetComponent extends ProjectEdtpModel implements OnInit, OnDestroy {

    private step: number = 22;
    budgetItems: IBudgetItem[] = [];
    projectBudgetsC1: IProjectBudget[] = [];
    projectBudgetsC2: IProjectBudget[] = [];
    projectBudgetsC3: IProjectBudget[] = [];
    projectBudgetsC4: IProjectBudget[] = [];
    projectBudgetsC5: IProjectBudget[] = [];
    projectBudgetsAdmin: IProjectBudget[] = [];
    projectBudgetsAudit: IProjectBudget[] = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly budgetItemsService: BudgetItemService,
        private readonly projectBudgetsService: ProjectBudgetService,
        private readonly setService: SettingsService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private layoutService: LayoutService,
        private crypto: CryptoService,
    ) {
        super(activatedRoute, projectService, tService, setService, crypto);
    }

    ngOnInit(): void {
        this.layoutService.showMenuDesktop(false);
        this.readParams();
        this.setActive(this.step);
        this.service.get(this.params['id']).then((project) => {
            const {id, name } = project || {};
            this.currentProject = {...project};
            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required]
            });
            const projectId = id || 0;
            return this.projectBudgetsService.getAll({ params: { projectId }});
        }).then((resp) => {
            this.loadBudgets(resp);
            return this.budgetItemsService.getAll();
        }).then((resp) => {
            this.budgetItems = [ ...resp ];
        }).finally(() => {
            this.setSteps();
            this.setActive(this.step); //We need to reload the steps and put as active the current step
            this.disableFormIfSubmitted();
            this.showContent = true;
        });

    }

    loadBudgets(budgets: IProjectBudget[]) {
        BudgetSummaryHelper.setBudgetAmounts(budgets);
        this.projectBudgetsC1 = budgets.filter(b => b.componentType === 'COMPONENT1');
        this.projectBudgetsC2 = budgets.filter(b => b.componentType === 'COMPONENT2');
        this.projectBudgetsC3 = budgets.filter(b => b.componentType === 'COMPONENT3');
        this.projectBudgetsC4 = budgets.filter(b => b.componentType === 'COMPONENT4');
        this.projectBudgetsC5 = budgets.filter(b => b.componentType === 'COMPONENT5');
        this.projectBudgetsAdmin = budgets.filter(b => b.componentType === 'ADMINISTRATION');
        this.projectBudgetsAudit = budgets.filter(b => b.componentType === 'AUDIT');
    }

    ngOnDestroy() {
        this.cleanParams();
    }
}
