const ProjectAssessmentFirstAmbitKeys = Object.freeze({
  POLITICAL_CONSTITUTION: 'POLITICAL_CONSTITUTION',
  DEVELOPMENT_SECTOR_PLAN: 'DEVELOPMENT_SECTOR_PLAN',
  PTDI: 'PTDI',
});

const ProjectAssessmentSecondAmbitKeys = Object.freeze({
  PROBLEM_STATEMENT: 'PROBLEM_STATEMENT',
  SOLUTION_ALTERNATIVES: 'SOLUTION_ALTERNATIVES',
  MAIN_GOAL: 'MAIN_GOAL',
  SPECIFIC_GOALS: 'SPECIFIC_GOALS',
  COMPONENTS: 'COMPONENTS',
  WANTED_RESULTS: 'WANTED_RESULTS',
});

const ProjectAssessmentThirdAmbitKeys = Object.freeze({
  OWNER_RIGHT: 'OWNER_RIGHT',
  ENVIRONMENTAL_IMPACT: 'ENVIRONMENTAL_IMPACT',
  DISASTER_RISKS: 'DISASTER_RISKS',
  SOCIAL_COMMITMENT: 'SOCIAL_COMMITMENT',
});

const ProjectAssessmentFourthAmbitKeys = Object.freeze({
  CONCLUSIONS: 'CONCLUSIONS',
  RECOMMENDATIONS: 'RECOMMENDATIONS',
});

const ProjectAssessmentFirstValues = [
  {
    key: ProjectAssessmentFirstAmbitKeys.POLITICAL_CONSTITUTION,
    label: '1.1 Constitución Politica del Estado',
  },
  {
    key: ProjectAssessmentFirstAmbitKeys.DEVELOPMENT_SECTOR_PLAN,
    label:
      '1.2 Plan sectorial de Desarrollo Integral del Ministerio de Medio Ambiente y Agua (en lo que corresponde a Gestion Integral de Funciones Ambientales, especificamente bosque)',
  },
  {
    key: ProjectAssessmentFirstAmbitKeys.PTDI,
    label: '1.3 Los Planes Territoriales de Desarrollo Integral PTDI',
  },
];

const ProjectAssessmentSecondValues = [
  {
    key: ProjectAssessmentSecondAmbitKeys.PROBLEM_STATEMENT,
    label: '2.1 Planteamiento del problema / Necesidad a resolver',
  },
  {
    key: ProjectAssessmentSecondAmbitKeys.SOLUTION_ALTERNATIVES,
    label: '2.2 Alternativas básicas de solución (mínimo 2)',
  },
  {
    key: ProjectAssessmentSecondAmbitKeys.MAIN_GOAL,
    label: '2.3 Objetivo general del proyecto (acorde a alternativa elegida)',
  },
  {
    key: ProjectAssessmentSecondAmbitKeys.SPECIFIC_GOALS,
    label: '2.4 Objetivos específicos del proyecto',
  },
  {
    key: ProjectAssessmentSecondAmbitKeys.COMPONENTS,
    label: '2.5 Componentes (descripción resumida)',
  },
  {
    key: ProjectAssessmentSecondAmbitKeys.WANTED_RESULTS,
    label: 'Resultados esperados por componente (enunciativamente no descriptivamente)',
  },
];

const ProjectAssessmentThirdValues = [
  {
    key: ProjectAssessmentThirdAmbitKeys.OWNER_RIGHT,
    label: '3.1 Derecho propietario de predio',
  },
  {
    key: ProjectAssessmentThirdAmbitKeys.ENVIRONMENTAL_IMPACT,
    label: '3.2 Impacto Ambiental',
  },
  {
    key: ProjectAssessmentThirdAmbitKeys.DISASTER_RISKS,
    label: '3.3 Riesgos de desastres',
  },
  {
    key: ProjectAssessmentThirdAmbitKeys.SOCIAL_COMMITMENT,
    label: '3.4 Compromiso Social',
  },
];

const ProjectAssessmentFourthValues = [
  {
    key: ProjectAssessmentFourthAmbitKeys.CONCLUSIONS,
    label: '4.1 Conclusiones',
  },
  {
    key: ProjectAssessmentFourthAmbitKeys.RECOMMENDATIONS,
    label: '4.2 Recomendaciones',
  },
];

export {
  ProjectAssessmentFirstAmbitKeys,
  ProjectAssessmentSecondAmbitKeys,
  ProjectAssessmentThirdAmbitKeys,
  ProjectAssessmentFourthAmbitKeys,
  ProjectAssessmentFirstValues,
  ProjectAssessmentSecondValues,
  ProjectAssessmentThirdValues,
  ProjectAssessmentFourthValues,
};
