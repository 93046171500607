<app-wizard
    [steps]='steps'
    [current]='currentStep'
    [bodyContentTpl]='projectForm'
    [nextDisabled]='this.formGroup?.invalid'
    (onClickNext)='save($event)'
    [layout]='true'
>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column' [formGroup]='formGroup'>
        <div class='grid'>
            <div class='field col-12'>
                <label>Antecedentes</label>
                <textarea
                    [rows]='10' pInputTextarea [autoResize]='false'
                    formControlName='history'
                    placeholder='Antecedentes del Proyecto'
                    class='text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                >
                </textarea>
                <div *ngIf="form.history.errors">
                    <p *ngIf="form.history.errors?.required && form.history.touched" class="text-danger">
                        *Campo requerido
                    </p>

                    <p *ngIf="form.history.errors?.maxlength" class="text-danger">
                        *Máximo de caracteres permitido es 3000
                    </p>
                </div>
            </div>

            <div class='field col-12'>
                <label>Diagnostico</label>
                <textarea
                    [rows]='10' pInputTextarea [autoResize]='false'
                    formControlName='diagnosis'
                    placeholder='Diagnostico del Proyecto'
                    class='text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                >
                </textarea>
                <div *ngIf="form.diagnosis.errors">
                    <p *ngIf="form.diagnosis.errors?.required && form.diagnosis.touched" class="text-danger">
                        *Campo requerido
                    </p>

                    <p *ngIf="form.diagnosis.errors?.maxlength" class="text-danger">
                        *Máximo de caracteres permitido es 3000
                    </p>
                </div>
            </div>

            <div class='field col-12'>
                <label>Plantamiento del Problema</label>
                <textarea
                    [rows]='10' pInputTextarea [autoResize]='false'
                    formControlName='statement'
                    placeholder='Plantamiento del Problema/necesidades a resolver con el proyecto'
                    class='text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                >
                </textarea>
                <div *ngIf="form.statement.errors">
                    <p *ngIf="form.statement.errors?.required && form.statement.touched" class="text-danger">
                        *Campo requerido
                    </p>

                    <p *ngIf="form.statement.errors?.maxlength" class="text-danger">
                        *Máximo de caracteres permitido es 3000
                    </p>
                </div>
            </div>

            <div class='field col-12'>
                <label>Actores Involucrados</label>
                <textarea
                    [rows]='10' pInputTextarea [autoResize]='false'
                    formControlName='items'
                    placeholder='Actores Involucrados'
                    class='text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                >
                </textarea>
                <div *ngIf="form.items.errors">
                    <p *ngIf="form.items.errors?.required && form.items.touched" class="text-danger">
                        *Campo requerido
                    </p>

                    <p *ngIf="form.items.errors?.maxlength" class="text-danger">
                        *Máximo de caracteres permitido es 3000
                    </p>
                </div>
            </div>

            <div class='field col-12'>
                <label>Alternativa de Solución 1</label>
                <textarea
                    [rows]='10' pInputTextarea [autoResize]='false'
                    formControlName='solution1'
                    placeholder='Alternativa de Solución 1'
                    class='text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                >
                </textarea>
                <div *ngIf="form.solution1.errors">
                    <p *ngIf="form.solution1.errors?.required && form.solution1.touched" class="text-danger">
                        *Campo requerido
                    </p>

                    <p *ngIf="form.solution1.errors?.maxlength" class="text-danger">
                        *Máximo de caracteres permitido es 3000
                    </p>
                </div>
            </div>

            <div class='field col-12'>
                <label>Alternativa de Solución 2</label>
                <textarea
                    [rows]='10' pInputTextarea [autoResize]='false'
                    formControlName='solution2'
                    placeholder='Alternativa de Solución 2'
                    class='text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                >
                </textarea>
                <div *ngIf="form.solution2.errors">
                    <p *ngIf="form.solution2.errors?.required && form.solution2.touched" class="text-danger">
                        *Campo requerido
                    </p>

                    <p *ngIf="form.solution2.errors?.maxlength" class="text-danger">
                        *Máximo de caracteres permitido es 3000
                    </p>
                </div>
            </div>

            <div class='field col-12 md:col-6'>
                <label>Alternativa Elegida</label>
                <p-dropdown
                    [options]="[{ value: 1}, { value: 2}]"
                    formControlName="chosenSolution"
                    optionLabel="value"
                    placeholder="Seleccione"
                    optionValue="value"
                    [style]="{ width: '100%' }"
                >
                </p-dropdown>
            </div>

            <div class='field col-12'>
                <label>Justificación de la alternativa elegida</label>
                <textarea
                    [rows]='10' pInputTextarea [autoResize]='false'
                    formControlName='justificationSolution'
                    placeholder='Justificación de la alternativa elegida'
                    class='text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                >
                </textarea>
                <div *ngIf="form.justificationSolution.errors">
                    <p *ngIf="form.justificationSolution.errors?.required && form.justificationSolution.touched" class="text-danger">
                        *Campo requerido
                    </p>

                    <p *ngIf="form.justificationSolution.errors?.maxlength" class="text-danger">
                        *Máximo de caracteres permitido es 3000
                    </p>
                </div>
            </div>
        </div>
    </div>
</ng-template>
