<div class="card border-0">
  <p-skeleton *ngIf="!showForm" width="100%" height="150px"></p-skeleton>
  <form *ngIf="showForm" class="form-signin" [formGroup]="modalForm">
    <div class="grid">
      <div class="field lg:col-12 md:col-12 sm:col-12">
        <label>Justificación</label>
          <textarea
              [autofocus]="true"
              [rows]='10'
              pInputTextarea
              [autoResize]='false'
              formControlName="justification"
              placeholder='Escriba la justificación aqui...'
              class='text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full'
          >
          </textarea>
        <div *ngIf="form.justification.errors">
          <p *ngIf="form.justification.errors.required && form.justification.touched" class="text-danger">*Campo requerido</p>
        </div>
      </div>

      <div class="field lg:col-12 md:col-12 sm:col-12">
        <app-input-file
          [label]="'Archivo del Justificativo'"
          [multiple]="false"
          [maxFiles]="1"
          [accept]="'application/pdf, image/jpg, image/jpeg, image/png'"
          [defaultFiles]="defaultFiles"
          (onUploaded)="onUploaded($event)"
        >
        </app-input-file>

        <input id="fileId" formControlName="fileId" type="hidden" />
        <div *ngIf="form.fileId.errors">
          <p *ngIf="form.fileId.errors?.required && form.dirty" class="text-danger">*Campo requerido</p>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="p-dialog-footer">
  <button pButton type="button" label="Cancelar" class="p-button-secondary" (click)="closeModal()"></button>
  <button pButton type="button" label="Guardar" (click)="save()" [disabled]="this.modalForm.invalid"></button>
</div>
