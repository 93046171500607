<app-wizard
    [steps]='steps'
    [current]='currentStep'
    [bodyContentTpl]='projectForm'
    [nextDisabled]='this.formGroup?.invalid'
    (onClickNext)='save($event)'
    [layout]='true'
>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form'>
        <div>
            <h3>
                DOCUMENTO IMPRIMIBLE
<!--                <button-->
<!--                    pButton-->
<!--                    pTooltip='Imprimir Documento'-->
<!--                    class='ml-2 p-button p-button-success'-->
<!--                    (click)='print($event)'-->
<!--                >-->
<!--                    <i class='pi pi-print'></i>-->
<!--                </button>-->

                <a [href]="service.getPDFUrl(currentProject?.id)"
                   pTooltip='Descargar Documento'
                    target="_blank" class='ml-2 p-button p-button-success'>
                    <i class='pi pi-file-pdf'></i>
                </a>
            </h3>
        </div>
        <div>
            <pdf-viewer id='print-area' [src]="this.service.getPDFUrl(currentProject?.id)"
                        [render-text]="true"
                        [original-size]="false"
                        [zoom]="0.8"
                        style="width: 100%; height: 350px;"
            ></pdf-viewer>
        </div>
    </div>
</ng-template>
