<div>
    <p-table #dtTable [value]="groups" dataKey="id" editMode="row" [tableStyle]="{ 'min-width': '00%' }">
        <ng-template pTemplate="header">
            <tr>
                <th class="bg-primary border-x-1 text-center" style="width: 60px">NRO.</th>
                <th class="bg-primary border-x-1 text-center" style="width: 250px">DESCRIPCIÓN</th>
                <th class="bg-primary border-x-1 text-center">PESO PONDERADO</th>
                <th class="bg-primary border-x-1 text-center">PUNTAJE PONDERADO MÁXIMO</th>
                <th class="bg-primary border-x-1 text-center">PUNTAJE PONDERADO MÍNIMO</th>
                <th class="bg-primary border-x-1 text-center">PUNTAJE OBTENIDO</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-group>
            <tr>
                <td>{{ group.code }}</td>
                <td>{{ group.title }}</td>
                <td class="text-center">{{ group.weight }} %</td>
                <td class="text-center">{{ group.maxScoreAccum }}</td>
                <td class="text-center">{{ group.minimumAccepted | number: '1.0-2' }}</td>
                <td class="text-center">{{ group.scoreAccum }}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr>
                <td colspan="2" class="text-center bg-green-100">TOTAL</td>
                <td class="text-center bg-green-100">{{ totals.weight | number: '1.0-2' }}</td>
                <td class="text-center bg-green-100">{{ totals.maxWeighted | number: '1.0-2' }}</td>
                <td class="text-center bg-green-100">{{ totals.minWeighted | number: '1.0-2' }}</td>
                <td class="text-center bg-green-100">{{ totals.score | number: '1.0-2' }}</td>
            </tr>
        </ng-template>
    </p-table>

    <div class="grid justify-content-end mt-3">
        <div class="field lg:col-4 md:col-6 sm:col-12 text-right">
            <button
                pButton
                type="button"
                label="Generar reporte"
                (click)="generateReport()"
                [disabled]="generatingReport"
            ></button>
        </div>
    </div>

    <ng-content></ng-content>
</div>
