import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { PublicRoutingModule } from './public-routing.module';
import {
    ITCPRequestComponent,
    BasicInfoComponent,
    MaeInfoComponent,
    ProjectManagerInfoComponent,
    ContactInfoComponent,
    DoneComponent,
} from './components';
import { WizardComponent, WizardStepComponent } from './shared';
import { DropdownModule } from 'primeng/dropdown';
import { CustomFormModule } from '../shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectiveModule } from '../shared/directives/directive.module';
import { SkeletonModule } from 'primeng/skeleton';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
    declarations: [
        WizardComponent,
        WizardStepComponent,
        BasicInfoComponent,
        MaeInfoComponent,
        ProjectManagerInfoComponent,
        ContactInfoComponent,
        DoneComponent,
        ITCPRequestComponent,
    ],
    exports: [WizardComponent],
    imports: [
        CommonModule,
        PublicRoutingModule,
        TooltipModule,
        ButtonModule,
        RouterModule,
        DropdownModule,
        CustomFormModule,
        FormsModule,
        ReactiveFormsModule,
        DirectiveModule,
        SkeletonModule,
    ],
})
export class PublicModule {}
