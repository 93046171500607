import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IAttachment } from '../../../../shared';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProjectService } from '../../../services';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { IProject } from '../../../models';
import { Constants, DEFAULT_CONTENT_NOTIFICATION } from '../../../../shared/constants';

@Component({
  selector: 'app-ranking-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() public id!: number;
  @Input() public data!: IProject;

  public modalForm!: FormGroup;
  public showForm = false;
  saving: boolean = false;
  defaultFiles: IAttachment[] = [];
  textNotification: string = '';

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public service: ProjectService,
    private fb: FormBuilder,
    private toasterService: ToasterService
  ) {
  }

  ngOnInit(): void {
    const { id, name, notificationFile, notificationFileId } = this.config.data || {};

    if (notificationFile) this.defaultFiles.push(notificationFile);
    this.textNotification = DEFAULT_CONTENT_NOTIFICATION;

    this.modalForm = this.fb.group({
      id: [id, Validators.required],
      name: [name, Validators.required],
      content: [this.textNotification, Validators.required],
      notificationFileId: [notificationFileId, Validators.required]
    });

    this.showForm = true;
  }

  get form(): any {
    return this.modalForm.controls;
  }

  onUploaded(data: any) {
    const { files } = data;
    if (files.length) {
      this.modalForm.patchValue({
        notificationFileId: files[0].id
      });
    }
  }

  save(): void {
    const { id } = this.config.data || {};

    if (!this.modalForm.valid) {
      this.toasterService.error('Corrija los datos e inténtelo nuevamente');
      return;
    }

    const formValue = this.modalForm.value;

    delete formValue.content;

    const postData: IProject = { ...formValue, status: Constants.PROJECT_STATUSES.NOTIFIED };

    this.saving = true;
    this.service
      .update(postData, id)
      .then(() => {
        return this.service.notifyEntity(id, {
          projectId: id,
          content: this.textNotification
        });
      })
      .then(() => {
        this.toasterService.success('La entidad fue notificada con exito');
        this.closeModal();
      })
      .catch((e) => {
        console.error(e.error.message);
        this.toasterService.error('Se ha producido un error, por favor intentelo mas tarde');
      })
      .finally(() => {
        this.saving = false;
      });

    return;
  }

  closeModal(data: any = undefined): void {
    this.ref.close(data);
  }
}
