import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from 'src/app/shared/services';
import { IProjectC1 } from "../models";

@Injectable({
  providedIn: 'root'
})
export class ProjectC1Service extends BaseService<IProjectC1>{

    constructor(private readonly httpService: BaseHttpService) {
        super(httpService, 'project-c1');
    }
}
