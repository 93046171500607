import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from 'src/app/shared/services';
import { IProjectGoal } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ProjectGoalService extends BaseService<IProjectGoal>{

    constructor(private readonly httpService: BaseHttpService) {
        super(httpService, 'project-goals');
    }
}
