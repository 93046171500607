<app-wizard
  [steps]="steps"
  [current]="currentStep"
  [bodyContentTpl]="requirementsForm"
  [nextDisabled]="this.formGroup?.invalid"
  (onClickNext)="save($event)"
  [layout]="true"
>
</app-wizard>
<ng-template #requirementsForm>
  <div *ngIf="showContent" class="project-form flex justify-content-center flex-column" [formGroup]="formGroup">
    <div class="grid">
      <div class="field col-12">
        <h3 class="p-text">REQUISITOS PARA PRESENTACION DE ITCP AL FONABOSQUE</h3>
      </div>
      <div class="col-12">
        <p-table
          #dtTable
          [value]="parsedRequirements"
          dataKey="key"
          editMode="row"
          styleClass="p-datatable-gridlines"
          [tableStyle]="{ 'min-width': '100%' }"
          [filterDelay]="0"
          [formGroup]="formGroup"
        >
          <ng-template pTemplate="header">
            <tr>
              <th class="bg-primary text-center" style="width: 30%">
                Descripción de documentación para adjuntar (Digitalizado)
              </th>
              <th class="bg-primary text-center" style="width: 30%" colspan="2">
                <table>
                  <tr>
                    <td class="text-center" colspan="2">
                      Entidades que soliciten el financiamiento de la formulación y ejecución del EDTP
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 50%">Presenta</td>
                    <td style="width: 50%">No Presenta</td>
                  </tr>
                </table>
              </th>
              <th style="display: none"></th>
              <th class="bg-primary text-center" style="width: 30%" colspan="2">
                <table>
                  <tr>
                    <td style="width: 100%" class="text-center" colspan="2">
                      Entidades que soliciten el financiamiento de la ejecución de EDTP
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 50%">Presenta</td>
                    <td style="width: 50%">No Presenta</td>
                  </tr>
                </table>
              </th>
              <th style="display: none"></th>
              <th class="bg-primary text-center" style="width: 10%">Adjunto</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-data let-editing="editing" let-ri="rowindex">
            <tr [pEditableRow]="data">
              <td>{{ data.label }}</td>
              <td class="text-center">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-radioButton
                      name="groupverified"
                      [value]="true"
                      [(ngModel)]="data.verified"
                      [ngModelOptions]="{ standalone: true }"
                    ></p-radioButton>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <i *ngIf="data.verified !== null && data.verified" class="pi pi-check text-2xl p-primary-color"></i>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td class="text-center">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-radioButton
                      name="groupverified"
                      [value]="false"
                      [(ngModel)]="data.verified"
                      [ngModelOptions]="{ standalone: true }"
                    ></p-radioButton>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <i
                      *ngIf="data.verified !== null && !data.verified"
                      class="pi pi-check text-2xl p-primary-color"
                    ></i>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td class="text-center">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-radioButton
                      name="groupFormulationVerified"
                      [value]="true"
                      [(ngModel)]="data.formulationVerified"
                      [ngModelOptions]="{ standalone: true }"
                    ></p-radioButton>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <i
                      *ngIf="data.formulationVerified !== null && data.formulationVerified"
                      class="pi pi-check text-2xl p-primary-color"
                    ></i>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td class="text-center">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-radioButton
                      name="groupFormulationVerified"
                      [value]="false"
                      [(ngModel)]="data.formulationVerified"
                      [ngModelOptions]="{ standalone: true }"
                    ></p-radioButton>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <i
                      *ngIf="data.formulationVerified !== null && !data.formulationVerified"
                      class="pi pi-check text-2xl p-primary-color"
                    ></i>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <a
                  *ngIf="getDownloadFileUrl(data.key)"
                  [href]="getDownloadFileUrl(data.key)"
                  [download]="getFilename(data.key)"
                  target="_blank"
                >
                  {{ getFilename(data.key) }}
                </a>
              </td>

              <td>
                <div *ngIf="!isSubmitted" class="flex align-items-center justify-content-center gap-2">
                  <button
                    *ngIf="!editing"
                    pButton
                    pRipple
                    type="button"
                    pInitEditableRow
                    icon="pi pi-pencil"
                    (click)="onRowEditInit(data)"
                    class="p-button-rounded p-button-text"
                  ></button>
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pSaveEditableRow
                    icon="pi pi-check"
                    (click)="onRowEditSave(data)"
                    class="p-button-rounded p-button-text p-button-success mr-2"
                  ></button>
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pCancelEditableRow
                    icon="pi pi-times"
                    (click)="onRowEditCancel(data, ri)"
                    class="p-button-rounded p-button-text p-button-danger"
                  ></button>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</ng-template>
