import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IProjectGoal, ProjectModel } from '../../../models';
import { ActivatedRoute } from '@angular/router';
import { ProjectGoalService, ProjectService } from '../../../services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { Table } from 'primeng/table';
import {CryptoService, IComponent, SettingsService} from "../../../../shared";
import {LayoutService} from "../../../../shared/services/app.layout.service";

@Component({
    selector: 'app-goals',
    templateUrl: './goals.component.html',
    styleUrls: ['./goals.component.scss']
})
export class GoalsComponent extends ProjectModel implements OnInit, OnDestroy {
    private step: number = 4;
    goals: IProjectGoal[] = [];
    clonedGoals: { [s: string]: IProjectGoal; } = {};
    components: IComponent[] = [];
    @ViewChild('dt') table!: Table;

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly goalService: ProjectGoalService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private settingsService: SettingsService,
        private layoutService: LayoutService,
        private crypto: CryptoService,
    ) {
        super(activatedRoute, projectService, tService, crypto);
    }

    ngOnInit(): void {
        this.layoutService.showMenuDesktop(false);
        this.readParams();
        this.setActive(this.step);
        this.service.get(this.params['id']).then((project) => {
            const { id, name, generalGoal, goals } = project || {};
            if (goals?.length) this.goals = goals;
            this.currentProject = { ...project };
            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required],
                generalGoal: [generalGoal, [Validators.required, Validators.maxLength(3000)]],
            });
            return this.settingsService.getAllComponents();
        }).then((resp) => {
            this.components = [ ...resp ];
        }).finally(() => {
            this.disableFormIfSubmitted();
            this.showContent = true;
        });
    }

    onRowEditInit(goal: IProjectGoal) {
        if (!goal?.id) return;
        this.clonedGoals[goal.id] = {...goal};
    }

    getForm(goal: IProjectGoal) {
        if (!goal?.id) return;

        const goalForm: FormGroup = this.fb.group({
            componentId: [goal.componentId, Validators.required],
            currentSituation: [goal.currentSituation, [Validators.required, Validators.min(0)]],
            description: [goal.description, Validators.required],
            goal: [goal.goal, [Validators.required, Validators.min(0)]],
            indicator: [goal.indicator, Validators.required],
            projectId: [goal.projectId, Validators.required],
            unit: [goal.unit, Validators.required],
        });

        return goalForm;
    }

    onRowEditSave(goal: IProjectGoal) {
        if (!goal?.id) return;
        const goalForm = this.getForm(goal);

        if (goalForm?.valid) {
            delete this.clonedGoals[goal.id];

            if (goal?.id.toString().includes('new_')) {
                const index = this.goals.indexOf(goal);
                this.goalService.store(goalForm.value).then((resp) => {
                    this.goals[index] = resp;
                    this.toasterService.success('El Objetivo fue creado satisfactoriamente.');
                });

            } else {
                const id = parseInt(goal.id.toString());
                const index = this.goals.indexOf(goal);
                this.goalService.update(goalForm.value, id).then((resp) => {
                    this.goals[index] = resp;
                    this.toasterService.success('El objetivo fue actualizado satisfactoriamente.');
                })
            }

        }
        else {
           this.toasterService.error('No se pudo guardar el objetivo los datos son inválidos!');
        }

    }

    onRowEditCancel(goal: IProjectGoal, index: number) {
        if (!goal?.id) return;

        if (this.clonedGoals[goal.id]) {
            this.goals[index] = this.clonedGoals[goal.id];
            delete this.clonedGoals[goal.id];
        }

        if (goal?.id.toString().includes('new_')) {
            this.goals.pop();
        }
    }

    onNew() {
        const g: IProjectGoal = {
            componentId: 0,
            currentSituation: 0,
            description: '',
            goal: 0,
            indicator: '',
            projectId: parseInt(this.params['id']),
            unit: '',
            id: `new_${new Date().getTime()}`}

        this.goals.push(g)
        this.table.initRowEdit(this.table.value[this.goals.length - 1])
    }

    ngOnDestroy() {
        this.cleanParams();
    }
}
