import { Component, OnDestroy, OnInit } from '@angular/core';
import { BudgetSummaryHelper, ProjectEdtpModel } from '../../../models';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../../itcp';
import { ProjectBudgetService } from '../../../services';
import { CryptoService, SettingsService } from '../../../../shared';
import { FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { LayoutService } from '../../../../shared/services/app.layout.service';
import { IProjectBudget } from '../../../models/interfaces/project-budget.interface';

@Component({
  selector: 'app-budget-vipfe',
  templateUrl: './budget-vipfe.component.html',
  styleUrls: ['./budget-vipfe.component.scss']
})
export class BudgetVipfeComponent extends ProjectEdtpModel implements OnInit, OnDestroy {

  private step: number = 24;
  projectBudgets: IProjectBudget[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private readonly projectService: ProjectService,
    private readonly projectBudgetsService: ProjectBudgetService,
    private readonly setService: SettingsService,
    private fb: FormBuilder,
    private tService: ToasterService,
    private layoutService: LayoutService,
    private crypto: CryptoService
  ) {
    super(activatedRoute, projectService, tService, setService, crypto);
  }

  ngOnInit(): void {
    this.layoutService.showMenuDesktop(false);
    this.readParams();
    this.setActive(this.step);
    this.service.get(this.params['id']).then((project) => {
      const { id, name } = project || {};
      this.currentProject = { ...project };
      this.formGroup = this.fb.group({
        id: [id],
        name: [name, Validators.required]
      });
      const projectId = id || 0;
      return this.projectBudgetsService.getAll({ params: { projectId } });
    }).then((resp) => {
      this.projectBudgets = [...resp];
    }).finally(() => {
      this.setSteps();
      this.setActive(this.step); //We need to reload the steps and put as active the current step
      this.loadSummary();
      this.disableFormIfSubmitted();
      this.showContent = true;
    });

  }

  loadSummary() {
    BudgetSummaryHelper.setBudgetAmounts(this.projectBudgets);
  }

  ngOnDestroy() {
    this.cleanParams();
  }

}
