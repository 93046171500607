export const ITCPRequestVerificationKeys = Object.freeze([
  {
    key: 'SEO_IDENTITY',
    label: 'Identidad de la MAE',
    value: false,
    fields: ['seoName', 'seoIdentityFile', 'seoCellPhone'],
  },
  { key: 'SEO_JOB_TITLE', label: 'Cargo de la MAE', value: false, fields: ['seoJobtitle', 'seoMemoFile'] },
  {
    key: 'LEAD_IDENTITY',
    label: 'Identidad del Encargado del Proyecto',
    value: false,
    fields: ['leadName', 'leadCellPhone', 'leadEmail'],
  },
  {
    key: 'LEAD_JOB_TITLE',
    label: 'Cargo del Encargado del Proyecto',
    value: false,
    fields: ['leadJobtitle', 'leadMemoFile'],
  },
  { key: 'CONTACT', label: 'Teléfono de Contacto', value: false, fields: ['contactPhone', 'contactEmail'] },
]);

export const ProjectRequirementKeys = Object.freeze({
  FINANCE_LETTER: 'FINANCE_LETTER',
  OWNER_RIGHTS_LETTER: 'OWNER_RIGHTS_LETTER',
  SOCIAL_COMMITMENT_LETTER: 'SOCIAL_COMMITMENT_LETTER',
  SWORN_DECLARATION: 'SWORN_DECLARATION',
  TDR: 'TDR',
  REFERENTIAL_BUDGET: 'REFERENTIAL_BUDGET',
});

export const ProjectRequirementValues = Object.freeze([
  {
    key: ProjectRequirementKeys.FINANCE_LETTER,
    label:
      'Carta de solicitud de financiamiento para formulación y ejecución de EDTP debidamente firmada por la MAE de la ETA',
  },
  {
    key: ProjectRequirementKeys.OWNER_RIGHTS_LETTER,
    label: 'Documentacion que acredite derecho propietario (si corresponde)',
  },
  {
    key: ProjectRequirementKeys.SOCIAL_COMMITMENT_LETTER,
    label: 'Actas de compromiso social debidamente firmadas por la MAE de la ETA y Beneficiarios',
  },
  {
    key: ProjectRequirementKeys.SWORN_DECLARATION,
    label: 'Formulario de declaracion jurada debidamente firmada por la MAE de la ETA',
  },
  {
    key: ProjectRequirementKeys.TDR,
    label: 'Terminos de referencia (TDR)',
  },
  {
    key: ProjectRequirementKeys.REFERENTIAL_BUDGET,
    label: 'Presupuesto Referencial',
  },
]);

export const ProjectEnvironment = Object.freeze({
  COMPONENT: {
    FOREST: 'BOSQUE',
    GROUND: 'SUELO',
    WATER: 'AGUA',
    AIR: 'AIRE',
    BIODIVERSITY: 'BIODIVERSIDAD',
  },
  LEVEL: {
    NONE: 'NINGUNO',
    LOW: 'BAJO',
    MEDIUM: 'MEDIO',
    HIGH: 'ALTO',
  },
  TEMPORALITY: {
    TRANSIENT: 'TRANSITORIO',
    PERMANENT: 'PERMANENTE',
    DOES_NOT_CORRESPOND: 'N0 CORRESPONDE',
  },
});

export const PROJECT_NO_NAME = '[NO NAME]';
export const DEFAULT_LIMIT_YEARS = 3;
export const PROJECT_SCORES = {
    ITCP_APPROVE: 71,
    EDTP_APPROVE: 81
};
