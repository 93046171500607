<app-wizard
    [steps]='steps'
    [current]='currentStep'
    [bodyContentTpl]='projectForm'
    [nextDisabled]='this.formGroup?.invalid'
    (onClickNext)='save($event)'
    [layout]='true'
>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column' [formGroup]='formGroup'>
        <div class='grid'>
            <div class='field col-12'>
                <h3>
                    CONCLUSIONES Y RECOMENDACIONES
                </h3>
            </div>
            <div class='field col-12 p-0 m-0'>
                <div class="flex">
                    <div class="label">Conclusiones</div>
                    <textarea
                        [autofocus]="true"
                        [rows]='7'pInputTextarea [autoResize]='false'
                        formControlName='conclusions'
                        placeholder='Conclusiones del Proyecto'
                        class='text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full'
                    >
                </textarea>
                </div>
                <div *ngIf="form.conclusions.errors">
                    <p *ngIf="form.conclusions.errors?.required && form.conclusions.touched" class="text-danger">
                        *Campo requerido
                    </p>

                    <p *ngIf="form.conclusions.errors?.maxlength" class="text-danger">
                        *Máximo de caracteres permitido es 3000
                    </p>
                </div>
            </div>
            <div class='field col-12 p-0 m-0'>
                <div class="flex">
                    <div class="label">Recomendaciones</div>
                    <textarea
                        [autofocus]="true"
                        [rows]='7'pInputTextarea [autoResize]='false'
                        formControlName='recommendations'
                        placeholder='Recomendaciones del Proyecto'
                        class='text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full'
                    >
                </textarea>
                </div>
                <div *ngIf="form.recommendations.errors">
                    <p *ngIf="form.recommendations.errors?.required && form.recommendations.touched" class="text-danger">
                        *Campo requerido
                    </p>

                    <p *ngIf="form.recommendations.errors?.maxlength" class="text-danger">
                        *Máximo de caracteres permitido es 3000
                    </p>
                </div>
            </div>
        </div>
    </div>
</ng-template>
