<div class="grid">
    <p-breadcrumb [model]="breadcrumbs"></p-breadcrumb>
    <div class="col-12">
        <div class="card">
            <p-table [value]="components" dataKey="id" editMode="row" [tableStyle]="{'min-width': '100%'}" #dt>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="bg-primary" style="width:5%">Nro.</th>
                        <th class="bg-primary" style="width:35%">DESCRIPCIÓN</th>
                        <th class="bg-primary" >COSTO TOTAL</th>
                        <th class="bg-primary" >CAPACIDAD</th>
                        <th class="bg-primary" >CANTIDAD</th>
                        <th class="bg-primary" >COEFICIENTE</th>
                        <th class="bg-primary" style="width:60px"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-obj let-editing="editing" let-ri="rowIndex">
                    <tr [pEditableRow]="obj">
                        <td>{{ri + 1}}</td>
                        <td>{{obj.componentName}}</td>
                        <td class='text-center'>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText
                                           required
                                           [min]="0"
                                           class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                                           type="number" [(ngModel)]="obj.cost" [ngModelOptions]="{standalone: true}">
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{obj.cost | number: '1.0-2'}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td class='text-center'>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText
                                           required
                                           [min]="0"
                                           class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                                           type="number" [(ngModel)]="obj.capacity" [ngModelOptions]="{standalone: true}">
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{obj.capacity | number: '1.0-2'}}
                                </ng-template>
                            </p-cellEditor>
                        </td>

                        <td class='text-center'>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText
                                           required
                                           [min]="0"
                                           class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                                           type="number" [(ngModel)]="obj.quantity" [ngModelOptions]="{standalone: true}">
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{obj.quantity}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td class='text-center'>{{(obj.cost/obj.quantity) | number: '1.0-2'}}</td>
                        <td>
                            <div class="flex align-items-center justify-content-center gap-2">
                                <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(obj, ri)" class="p-button-rounded p-button-text"></button>
                                <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(obj)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
                                <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(obj, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
