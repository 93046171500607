import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {
    IProjectGoalActivity,
    IProjectGoalResult,
    ProjectGoalActivityService
} from "../../../../../../edtp";
import {Table} from "primeng/table";
import {FormBuilder } from "@angular/forms";
import {ToasterService} from "../../../../../../shared/services/toaster.service";
import {AttachmentService} from "../../../../../../shared";

@Component({
    selector: 'app-summary-fs-activities',
    templateUrl: './activities.component.html',
    styleUrls: ['./activities.component.scss']
})
export class SummaryFSActivitiesComponent implements OnInit {
    @Input() group!: IProjectGoalResult;
    @Input() selectedYear!: number;

    cloned: { [s: string]: IProjectGoalActivity; } = {};
    @ViewChild('dt') table!: Table;

    constructor(
        private readonly activityService: ProjectGoalActivityService,
        public readonly attachmentService: AttachmentService,
        private fb: FormBuilder,
        private toasterService: ToasterService,
    ) {
    }

    ngOnInit(): void {
        if (!this.group.activities)
            this.group.activities = [];
    }

    calcPMG(act: IProjectGoalActivity) {
        if (!act.currentMonth) return 0;
        const yearExpected = act.currentMonth.yearExpected || 0;

        if (yearExpected === 0) return 0;

        return (act.currentMonth.expected / yearExpected) * 100;
    }

    calcEMG(act: IProjectGoalActivity) {
        if (!act.currentMonth) return 0;
        const yearExpected = act.currentMonth.yearExpected || 0;

        if (yearExpected === 0) return 0;

        return (act.currentMonth.executed / yearExpected) * 100;
    }
}
