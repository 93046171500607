<div class="grid">
    <p-breadcrumb [model]="breadcrumbs"></p-breadcrumb>
    <div class="col-12">
        <div *ngIf="showContent" class="card">
            <p-toolbar styleClass="mb-4 w-full clean-toolbar">
                <div class="flex justify-content-between flex-column align-items-center sm:flex-row w-full">
                    <div class="text-base">
                        <b>NOMBRE DEL PROYECTO</b>:<br/> {{currentProject?.name}}
                    </div>
                    <div class="my-2">
                        <button
                            pButton
                            pRipple
                            label="Actualizar"
                            icon="pi pi-refresh"
                            class="p-button-info mr-2"
                            (click)="loadData()"
                        ></button>
                    </div>
                </div>

                <div class='w-full text-right'>
                    <button
                        *ngIf='needRestart'
                        pButton
                        pRipple
                        label="Reiniciar Proyecto"
                        icon="pi pi-play"
                        class="p-button-success mr-2"
                        (click)="saveSkipValidation($event)"
                    ></button>
                </div>
            </p-toolbar>

            <p-table
                #dt
                [value]="[startOrder]"
                [loading]="loading"
                dataKey="id"
                editMode="row"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th class="bg-primary border-x-1 text-center">FASE/ACTIVIDAD</th>
                        <th class="bg-primary border-x-1 text-center">FECHA</th>
                        <th class="bg-primary border-x-1 text-center">MONTO (Bs.)</th>
                        <th class="bg-primary border-x-1 text-center"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-obj let-editing="editing" let-index="rowIndex">
                    <tr [pEditableRow]="obj">
                        <td>
                            Firma de convenio
                        </td>
                        <td *ngIf="!projectStarted" class="text-center">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-calendar
                                        placeholder="Elija una fecha"
                                        dateFormat="yy-mm-dd"
                                        [showIcon]="true"
                                        [yearNavigator]="true"
                                        [monthNavigator]="true"
                                        appendTo="body"
                                        [readonlyInput]="true"
                                        dataType="string"
                                        [(ngModel)]="obj.signDate"
                                        [ngModelOptions]="{standalone: true}"
                                    ></p-calendar>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ obj.signDate | date: 'yyyy-MM-dd' }}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="projectStarted" class="text-center">
                            {{ obj.signDate | date: 'yyyy-MM-dd' }}
                        </td>
                        <td *ngIf="!projectStarted" class="text-center">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText
                                           placeholder="Monto"
                                           min="0"
                                           required
                                           class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-10rem'
                                           type="number" [(ngModel)]="obj.signPrice" [ngModelOptions]="{standalone: true}">

                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ obj.signPrice | number: '1.2-2' }}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="projectStarted" class="text-center">
                            {{ obj.signPrice | number: '1.2-2' }}
                        </td>
                        <td class="text-center">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-overlayPanel #uploadFiles1>
                                        <ng-template pTemplate="content">
                                            <div class="p-2" style="min-width: 350px;">
                                                <app-input-file
                                                    [multiple]="true"
                                                    [maxFiles]="10"
                                                    [accept]="'application/pdf, image/jpg, image/jpeg, image/png'"
                                                    (onUploaded)="onUploadedVerifiable($event, 'SIGN')"
                                                >
                                                </app-input-file>
                                            </div>
                                        </ng-template>
                                    </p-overlayPanel>
                                    <button
                                        pButton pTooltip="Agregar Archivos Verificables"
                                        tooltipPosition="top"
                                        label="Agregar archivos"
                                        icon='pi pi-upload'
                                        type="button"
                                        class="p-button-rounded p-button-text"
                                        (click)="uploadFiles1.toggle($event)"
                                    ></button>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <div *ngIf="obj.signFiles?.length">
                                        <p-overlayPanel #uploadedFiles2>
                                            <div *ngFor="let item of obj.signFiles;" class="mb-4">
                                                <a [href]="attachmentService.getFileUrl(item?.key)" class="hover:underline"
                                                   [download]="item.filename" target="_blank">
                                                    <i class="pi pi-download mr-2"></i>
                                                    {{item.filename}}
                                                </a>
                                            </div>
                                        </p-overlayPanel>

                                        <button
                                            pButton pTooltip="Ver Archivos Verificables"
                                            tooltipPosition="top"
                                            label="Verificables"
                                            icon='pi pi-eye'
                                            type="button"
                                            class="p-button-rounded p-button-text"
                                            (click)="uploadedFiles2.toggle($event)"
                                        ></button>
                                    </div>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                    </tr>
                    <tr [pEditableRow]="obj">
                        <td>
                            Órden de inicio
                        </td>
                        <td *ngIf="!projectStarted" class="text-center">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-calendar
                                        placeholder="Elija una fecha"
                                        dateFormat="yy-mm-dd"
                                        [showIcon]="true"
                                        [yearNavigator]="true"
                                        [monthNavigator]="true"
                                        appendTo="body"
                                        [readonlyInput]="true"
                                        dataType="string"
                                        [(ngModel)]="obj.startOrderDate"
                                        [ngModelOptions]="{standalone: true}"
                                    ></p-calendar>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ obj.startOrderDate | date: 'yyyy-MM-dd' }}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="projectStarted" class="text-center">
                            {{ obj.startOrderDate | date: 'yyyy-MM-dd' }}
                        </td>
                        <td class="text-center">
                        </td>
                        <td class="text-center">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-overlayPanel #uploadFiles3>
                                        <ng-template pTemplate="content">
                                            <div class="p-2" style="min-width: 350px;">
                                                <app-input-file
                                                    [multiple]="true"
                                                    [maxFiles]="10"
                                                    [accept]="'application/pdf, image/jpg, image/jpeg, image/png'"
                                                    (onUploaded)="onUploadedVerifiable($event, 'START_ORDER')"
                                                >
                                                </app-input-file>
                                            </div>
                                        </ng-template>
                                    </p-overlayPanel>
                                    <button
                                        pButton pTooltip="Agregar Archivos Verificables"
                                        tooltipPosition="top"
                                        label="Agregar archivos"
                                        icon='pi pi-upload'
                                        type="button"
                                        class="p-button-rounded p-button-text"
                                        (click)="uploadFiles3.toggle($event)"
                                    ></button>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <div *ngIf="obj.startOrderFiles?.length">
                                        <p-overlayPanel #uploadedFiles4>
                                            <div *ngFor="let item of obj.startOrderFiles;" class="mb-4">
                                                <a [href]="attachmentService.getFileUrl(item?.key)" class="hover:underline"
                                                   [download]="item.filename" target="_blank">
                                                    <i class="pi pi-download mr-2"></i>
                                                    {{item.filename}}
                                                </a>
                                            </div>
                                        </p-overlayPanel>

                                        <button
                                            pButton pTooltip="Ver Archivos Verificables"
                                            tooltipPosition="top"
                                            label="Verificables"
                                            icon='pi pi-eye'
                                            type="button"
                                            class="p-button-rounded p-button-text"
                                            (click)="uploadedFiles4.toggle($event)"
                                        ></button>
                                    </div>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                    </tr>
                    <tr [pEditableRow]="obj">
                        <td>
                            Primer desembolso
                        </td>
                        <td class="text-center">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-calendar
                                        placeholder="Elija una fecha"
                                        dateFormat="yy-mm-dd"
                                        [showIcon]="true"
                                        [yearNavigator]="true"
                                        [monthNavigator]="true"
                                        appendTo="body"
                                        [readonlyInput]="true"
                                        dataType="string"
                                        [(ngModel)]="obj.firstPaymentDate"
                                        [ngModelOptions]="{standalone: true}"
                                    ></p-calendar>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ obj.firstPaymentDate | date: 'yyyy-MM-dd' }}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td class="text-center">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText
                                           placeholder="Monto"
                                           min="0"
                                           required
                                           class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-10rem'
                                           type="number" [(ngModel)]="obj.firstPayment" [ngModelOptions]="{standalone: true}">
                                    <div *ngIf="getForm()?.invalid" class="relative w-full">
                                        <p *ngIf="obj.firstPayment < 0" class="text-danger text-sm absolute">
                                            {{'Valor mínimo: 0'}}.
                                        </p>
                                        <p *ngIf="obj.firstPayment > obj.signPrice" class="text-danger text-sm absolute">
                                            {{'Valor máximo: ' + obj.signPrice }}
                                        </p>
                                        <p *ngIf="obj.firstPayment && obj.secondPayment && obj.thirdPayment" class="text-danger text-sm absolute">
                                            {{'Suma de los desembolsos no puede sobrepasar a: '  + obj.signPrice }}
                                        </p>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ obj.firstPayment | number: '1.2-2' }}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td class="text-center">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-overlayPanel #uploadFiles5>
                                        <ng-template pTemplate="content">
                                            <div class="p-2" style="min-width: 350px;">
                                                <app-input-file
                                                    [multiple]="true"
                                                    [maxFiles]="10"
                                                    [accept]="'application/pdf, image/jpg, image/jpeg, image/png'"
                                                    (onUploaded)="onUploadedVerifiable($event, 'FIRST_PAYMENT')"
                                                >
                                                </app-input-file>
                                            </div>
                                        </ng-template>
                                    </p-overlayPanel>
                                    <button
                                        pButton pTooltip="Agregar Archivos Verificables"
                                        tooltipPosition="top"
                                        label="Agregar archivos"
                                        icon='pi pi-upload'
                                        type="button"
                                        class="p-button-rounded p-button-text"
                                        (click)="uploadFiles5.toggle($event)"
                                    ></button>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <div *ngIf="obj.firstPaymentFiles?.length">
                                        <p-overlayPanel #uploadedFiles6>
                                            <div *ngFor="let item of obj.firstPaymentFiles;" class="mb-4">
                                                <a [href]="attachmentService.getFileUrl(item?.key)" class="hover:underline"
                                                   [download]="item.filename" target="_blank">
                                                    <i class="pi pi-download mr-2"></i>
                                                    {{item.filename}}
                                                </a>
                                            </div>
                                        </p-overlayPanel>

                                        <button
                                            pButton pTooltip="Ver Archivos Verificables"
                                            tooltipPosition="top"
                                            label="Verificables"
                                            icon='pi pi-eye'
                                            type="button"
                                            class="p-button-rounded p-button-text"
                                            (click)="uploadedFiles6.toggle($event)"
                                        ></button>
                                    </div>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                    </tr>
                    <tr [pEditableRow]="obj">
                        <td>
                            Segundo desembolso
                        </td>
                        <td class="text-center">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-calendar
                                        placeholder="Elija una fecha"
                                        dateFormat="yy-mm-dd"
                                        [showIcon]="true"
                                        [yearNavigator]="true"
                                        [monthNavigator]="true"
                                        appendTo="body"
                                        [readonlyInput]="true"
                                        dataType="string"
                                        [(ngModel)]="obj.secondPaymentDate"
                                        [ngModelOptions]="{standalone: true}"
                                    ></p-calendar>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ obj.secondPaymentDate | date: 'yyyy-MM-dd' }}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td class="text-center">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText
                                           placeholder="Monto"
                                           min="0"
                                           required
                                           class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-10rem'
                                           type="number" [(ngModel)]="obj.secondPayment" [ngModelOptions]="{standalone: true}">
                                    <div *ngIf="getForm()?.invalid" class="relative w-full">
                                        <p *ngIf="obj.secondPayment < 0" class="text-danger text-sm absolute">
                                            {{'Valor mínimo: 0'}}.
                                        </p>
                                        <p *ngIf="obj.secondPayment > obj.signPrice" class="text-danger text-sm absolute">
                                            {{'Valor máximo: ' + obj.signPrice }}
                                        </p>
                                        <p *ngIf="obj.firstPayment && obj.secondPayment && obj.thirdPayment" class="text-danger text-sm absolute">
                                            {{'Suma de los desembolsos no puede sobrepasar a: '  + obj.signPrice }}
                                        </p>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ obj.secondPayment | number: '1.2-2' }}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td class="text-center">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-overlayPanel #uploadFiles7>
                                        <ng-template pTemplate="content">
                                            <div class="p-2" style="min-width: 350px;">
                                                <app-input-file
                                                    [multiple]="true"
                                                    [maxFiles]="10"
                                                    [accept]="'application/pdf, image/jpg, image/jpeg, image/png'"
                                                    (onUploaded)="onUploadedVerifiable($event, 'SECOND_PAYMENT')"
                                                >
                                                </app-input-file>
                                            </div>
                                        </ng-template>
                                    </p-overlayPanel>
                                    <button
                                        pButton pTooltip="Agregar Archivos Verificables"
                                        tooltipPosition="top"
                                        label="Agregar archivos"
                                        icon='pi pi-upload'
                                        type="button"
                                        class="p-button-rounded p-button-text"
                                        (click)="uploadFiles7.toggle($event)"
                                    ></button>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <div *ngIf="obj.secondPaymentFiles?.length">
                                        <p-overlayPanel #uploadedFiles8>
                                            <div *ngFor="let item of obj.secondPaymentFiles;" class="mb-4">
                                                <a [href]="attachmentService.getFileUrl(item?.key)" class="hover:underline"
                                                   [download]="item.filename" target="_blank">
                                                    <i class="pi pi-download mr-2"></i>
                                                    {{item.filename}}
                                                </a>
                                            </div>
                                        </p-overlayPanel>

                                        <button
                                            pButton pTooltip="Ver Archivos Verificables"
                                            tooltipPosition="top"
                                            label="Verificables"
                                            icon='pi pi-eye'
                                            type="button"
                                            class="p-button-rounded p-button-text"
                                            (click)="uploadedFiles8.toggle($event)"
                                        ></button>
                                    </div>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                    </tr>
                    <tr [pEditableRow]="obj">
                        <td>
                            Tercer desembolso
                        </td>
                        <td class="text-center">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-calendar
                                        placeholder="Elija una fecha"
                                        dateFormat="yy-mm-dd"
                                        [showIcon]="true"
                                        [yearNavigator]="true"
                                        [monthNavigator]="true"
                                        appendTo="body"
                                        [readonlyInput]="true"
                                        dataType="string"
                                        [(ngModel)]="obj.thirdPaymentDate"
                                        [ngModelOptions]="{standalone: true}"
                                    ></p-calendar>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ obj.thirdPaymentDate | date: 'yyyy-MM-dd' }}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td class="text-center">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText
                                           placeholder="Monto"
                                           min="0"
                                           required
                                           class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-10rem'
                                           type="number" [(ngModel)]="obj.thirdPayment" [ngModelOptions]="{standalone: true}">
                                    <div *ngIf="getForm()?.invalid" class="relative w-full">
                                        <p *ngIf="obj.thirdPayment < 0" class="text-danger text-sm absolute">
                                            {{'Valor mínimo: 0'}}
                                        </p>
                                        <p *ngIf="obj.thirdPayment > obj.signPrice" class="text-danger text-sm absolute">
                                            {{'Valor máximo: ' + obj.signPrice }}
                                        </p>
                                        <p *ngIf="obj.firstPayment && obj.secondPayment && obj.thirdPayment" class="text-danger text-sm absolute">
                                            {{'Suma de los desembolsos no puede sobrepasar a: '  + obj.signPrice }}
                                        </p>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ obj.thirdPayment | number: '1.2-2' }}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td class="text-center">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-overlayPanel #uploadFiles9>
                                        <ng-template pTemplate="content">
                                            <div class="p-2" style="min-width: 350px;">
                                                <app-input-file
                                                    [multiple]="true"
                                                    [maxFiles]="10"
                                                    [accept]="'application/pdf, image/jpg, image/jpeg, image/png'"
                                                    (onUploaded)="onUploadedVerifiable($event, 'THIRD_PAYMENT')"
                                                >
                                                </app-input-file>
                                            </div>
                                        </ng-template>
                                    </p-overlayPanel>
                                    <button
                                        pButton pTooltip="Agregar Archivos Verificables"
                                        tooltipPosition="top"
                                        label="Agregar archivos"
                                        icon='pi pi-upload'
                                        type="button"
                                        class="p-button-rounded p-button-text"
                                        (click)="uploadFiles9.toggle($event)"
                                    ></button>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <div *ngIf="obj.thirdPaymentFiles?.length">
                                        <p-overlayPanel #uploadedFiles10>
                                            <div *ngFor="let item of obj.thirdPaymentFiles;" class="mb-4">
                                                <a [href]="attachmentService.getFileUrl(item?.key)" class="hover:underline"
                                                   [download]="item.filename" target="_blank">
                                                    <i class="pi pi-download mr-2"></i>
                                                    {{item.filename}}
                                                </a>
                                            </div>
                                        </p-overlayPanel>

                                        <button
                                            pButton pTooltip="Ver Archivos Verificables"
                                            tooltipPosition="top"
                                            label="Verificables"
                                            icon='pi pi-eye'
                                            type="button"
                                            class="p-button-rounded p-button-text"
                                            (click)="uploadedFiles10.toggle($event)"
                                        ></button>
                                    </div>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                    </tr>
                    <tr [pEditableRow]="obj">
                        <td colspan="3">
                            <div *ngIf="calculating" class="px-8 py-4 text-center">
                                <label class="text-sm">Calculando fechas para actividades.....</label>
                                <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
                            </div>
                        </td>
                        <td colspan="2">
                            <div
                                *ngIf='!disabled'
                                appPermission
                                [resource]="resource"
                                [enableActions]="'canUpdate'"
                                class="flex align-items-center justify-content-end gap-2">
                                <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" label="Editar" (click)="onRowEditInit()" class="p-button-rounded p-button-text"></button>
                                <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" label="Guardar" (click)="onRowEditSave()" class="p-button-rounded p-button-text p-button-success mr-2" [disabled]="!getForm()?.valid"></button>
                                <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" label="Cancelar" (click)="onRowEditCancel()" class="p-button-rounded p-button-text p-button-danger"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>

            <div class="flex align-items-center justify-content-between w-full">
                <div class="surface-900 text-white px-4 py-3 w-3  text-lg">MONTO TOTAL</div>
                <div class="px-4 py-3 w-3 font-bold text-lg surface-300">{{startOrder.signPrice | number: '1.2-2'}}</div>
                <div class="surface-900 text-white px-4 py-3 w-3  text-lg">SALDO A DESEMBOLSAR</div>
                <div class="surface-300 px-4 py-3 w-3 font-bold text-lg">{{balance | number: '1.2-2'}}</div>
                <div></div>
            </div>
        </div>
    </div>
</div>
