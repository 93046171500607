<div class="grid" xmlns="http://www.w3.org/1999/html">
    <p-breadcrumb [model]="breadcrumbs"></p-breadcrumb>
    <div class="col-12">
        <div class="card">
            <p-toolbar styleClass="mb-4 w-full clean-toolbar">
                <div class="flex justify-content-between flex-column align-items-center sm:flex-row w-full">
                    <div class="p-input-icon-left mb-2 searcher">
                        <i class="pi pi-search"></i>
                        <input
                            pInputText
                            type="text"
                            class="w-full"
                            (input)="setFilter($event, 'filterGlobal', 'contains')"
                            placeholder="Buscar..."
                        />
                    </div>
                    <div class="my-2">
                        <button
                            appPermission
                            [resource]="resource"
                            [enableActions]="'canView'"
                            pButton
                            pRipple
                            label="Actualizar"
                            icon="pi pi-refresh"
                            class="p-button-info mr-2"
                            (click)="getAll()"
                        ></button>
                    </div>
                </div>
            </p-toolbar>

            <p-table
                #dt
                [value]="projects"
                [loading]="loading"
                [paginator]="true"
                [rows]="6"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} al {last} de {totalRecords} items"
                [rowsPerPageOptions]="[6, 12, 24]"
                [globalFilterFields]="['name']"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th class="bg-primary">Nro</th>
                        <th class="bg-primary" pSortableColumn="name">
                            Proyecto
                            <p-sortIcon field="name"></p-sortIcon>
                        </th>
                        <th class="bg-primary">Técnicos Asignados</th>
                        <th class="bg-primary">Responsable de Programación</th>
                        <th class="bg-primary"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                    <tr>
                        <td>{{rowIndex + 1 }}</td>
                        <td><div class="large-cell">{{item.name}}</div></td>
                        <td>
                            <div *ngFor="let tech of item.technicians" class="mb-2 w-full">
                                {{getFullName(tech.user)}}
                                <div class="text-xs text-gray-600">{{tech.user.usuarioRoles[0]?.rol?.descripcionRol}}</div>
                            </div>
                        </td>
                        <td>
                            <div class="mb-1">{{getRespFormulation(item.technicians)}}</div>
                            <app-project-status [status]="item.status"></app-project-status>
                        </td>
                        <td>
                            <div class="flex gap-2 flex-wrap">

                                <button
                                    *ngIf="canReview(item.technicians)"
                                    pButton pTooltip="Revisar"
                                    tooltipPosition="top"
                                    label="Revisar"
                                    icon='pi pi-eye'
                                    type="button"
                                    class="p-button p-button-outlined"
                                    (click)="initWizard(item.id)">
                                </button>

                                <button
                                    *ngIf="canProgramme(item.technicians) && !programmedStatuses().includes(item.status)"
                                    pButton pTooltip="Programar"
                                    tooltipPosition="top"
                                    label="Programar"
                                    icon='pi pi-sliders-h'
                                    type="button"
                                    class="p-button  p-button-outlined"
                                    (click)="initWizard(item.id, false)">
                                </button>

                            </div>

                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
