export * from './project.component';
export * from './basic-info/basic-info.component';
export * from './justify/justify.component';
export * from './idea/idea.component';
export * from './goals/goals.component';
export * from './advantage/advantage.component';
export * from './deals/deals.component';
export * from './property/property.component';
export * from './property/coordinates-modal/coordinates-modal.component';
export * from './environment/environment.component';
export * from './risks/risks.component';
export * from './others/others.component';
export * from './budget/budget.component';
export * from './conclusions/conclusions.component';
export * from './pdf/pdf.component';
export * from './sworn/sworn.component';
export * from './done/done.component';
