import { Component, OnDestroy, OnInit } from '@angular/core';
import { IEdtpCompliance, IProjectComplianceGroup, ProjectAssessmentModel } from '../../../models';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../../itcp';
import { EdtpComplianceService, ProjectComplianceGroupService } from '../../../services';
import { FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { CryptoService } from '../../../../shared';
import { LayoutService } from '../../../../shared/services/app.layout.service';
import { Constants } from '../../../../shared/constants';

@Component({
    selector: 'app-weighing',
    templateUrl: './weighing.component.html',
    styleUrls: ['./weighing.component.scss'],
})
export class WeighingComponent extends ProjectAssessmentModel implements OnInit, OnDestroy {
    private step: number = 5;
    defaultCompliances: IEdtpCompliance[] = [];
    totals = { weight: 0, score: 0, maxWeighted: 0, minWeighted: 0 };
    groups: IProjectComplianceGroup[] = [];
    generatingReport: boolean = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly complianceGroupService: ProjectComplianceGroupService,
        private readonly edtpComplianceService: EdtpComplianceService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private readonly layoutService: LayoutService,
        private crypto: CryptoService
    ) {
        super(activatedRoute, projectService, tService, crypto);
    }

    ngOnInit(): void {
        this.layoutService.showMenuDesktop(true);
        this.readParams();
        this.setActive(this.step);
        this.service
            .get(this.params['id'])
            .then((project) => {
                const { id, name } = project || {};
                this.currentProject = { ...project };
                this.formGroup = this.fb.group({
                    id: [id],
                    name: [name, Validators.required],
                    status: [Constants.PROJECT_STATUSES.EVALUATED],
                    score: [0, Validators.required],
                });
                return this.edtpComplianceService.getAll();
            })
            .then((resp) => {
                this.defaultCompliances = [...resp];
            })
            .finally(() => {
                this.setSteps();
                this.setActive(this.step); //We need to reload the steps and put as active the current step
                this.disableFormIfSubmitted();
                this.showContent = true;
            });
    }

    onScoreChanged(score: number) {
        this.formGroup.patchValue({
            score,
        });
    }

    ngOnDestroy() {
        this.cleanParams();
    }
}
