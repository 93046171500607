import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProjectEdtpModel } from '../../../../models';
import { ActivatedRoute } from '@angular/router';
import {
    CoordinatesModalComponent,
    CoordinatesModalType,
    IProjectAct,
    ProjectActService,
    ProjectService,
} from '../../../../../itcp';
import { ProjectBaseLineService } from '../../../../services';
import { CryptoService, SettingsService } from '../../../../../shared';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from '../../../../../shared/services/toaster.service';
import { IProjectC3 } from '../../../../models/interfaces/project-c3.interface';
import { ISpecie } from '../../../../models/interfaces/specie.interface';
import { Table } from 'primeng/table';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SpecieService } from '../../../../services/specie.service';
import { ProjectC3Service } from '../../../../services/project-c3.service';
import { Pc3Types } from '../../../../../shared/enums/pc3-types.enum';
import { Constants } from '../../../../../shared/constants';
import { IProjectC2 } from '../../../../models/interfaces/project-c2.interface';
import { Subject, takeUntil } from 'rxjs';
import { LayoutService } from '../../../../../shared/services/app.layout.service';

@Component({
    selector: 'app-t-ii',
    templateUrl: './t-ii.component.html',
    styleUrls: ['./t-ii.component.scss'],
})
export class C3TIIComponent extends ProjectEdtpModel implements OnInit, OnDestroy {
    private step: number = 14;

    projectC3Types = ['PC', 'SAF'];
    defaultSystems = ['3x3', '5x5', '4x4', '3x4', '4x5'];
    safSystems = ['5x5', '4x4', '7x7', '6x5', '6x6', '10x10', 'OTROS'];
    methods = ['Hilera', 'Cuadrado', 'Tres Bolillo', 'Rectangluar'];

    _c3Items: IProjectC3[] = [];
    communities: IProjectAct[] = [];
    c3AfforestationItems: IProjectC3[] = [];
    c3ReforestationItems: IProjectC3[] = [];
    c3SAFItems: IProjectC3[] = [];
    species: ISpecie[] = [];
    clonedC3Items: { [s: string]: IProjectC3 } = {};
    @ViewChild('dtAfforestation') tableAfforestation!: Table;
    @ViewChild('dtReforestation') tableReforestation!: Table;
    @ViewChild('dtSAF') tableSAF!: Table;
    private onDestroy$ = new Subject();
    public ref!: DynamicDialogRef;
    private modal: DynamicDialogConfig<CoordinatesModalType> = {
        ...Constants.MODAL.default,
        width: '70%',
    };

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly specieService: SpecieService,
        private readonly pc3Service: ProjectC3Service,
        private readonly baseLineService: ProjectBaseLineService,
        private readonly projectActsService: ProjectActService,
        private readonly setService: SettingsService,
        private fb: FormBuilder,
        private tService: ToasterService,
        public readonly dialogService: DialogService,
        private layoutService: LayoutService,
        private crypto: CryptoService
    ) {
        super(activatedRoute, projectService, tService, setService, crypto);
    }

    get c3Items() {
        return this._c3Items;
    }

    set c3Items(data: IProjectC3[]) {
        data.forEach((c3) => {
            const parsedObject = {
                ...c3,
                specieCode: c3?.specie?.code,
                specieName: c3?.specie?.name,
                actCommunity: c3?.act?.community,
            };

            switch (c3.type) {
                case Pc3Types.SAF:
                    this.c3SAFItems.push(parsedObject);
                    break;
                case Pc3Types.REFORESTATION:
                    this.c3ReforestationItems.push(parsedObject);
                    break;
                default:
                    this.c3AfforestationItems.push(parsedObject);
                    break;
            }
        });

        this._c3Items = data;
    }

    ngOnInit(): void {
        this.layoutService.showMenuDesktop(false);
        this.readParams();
        this.setActive(this.step);
        this.service
            .get(this.params['id'])
            .then((project) => {
                const { id, name, referenceId } = project || {};
                this.currentProject = { ...project };
                this.formGroup = this.fb.group({
                    id: [id],
                    name: [name, Validators.required],
                });

                return Promise.all([
                    this.pc3Service.getAll({
                        params: { projectId: parseInt(String(id)), typology: Constants.TYPOLOGIES.TYPOLOGY_II },
                    }),
                    this.projectActsService.getAll({ params: { projectId: parseInt(String(referenceId)) } }),
                ]);
            })
            .then(([resp, acts]) => {
                this.c3Items = [...resp];
                this.communities = [...acts];
            })
            .finally(() => {
                this.setSteps();
                this.setActive(this.step); //We need to reload the steps and put as active the current step
                this.disableFormIfSubmitted();
                this.showContent = true;
                this.c3Items.map((item) => this.setGeoIds(item));
            });
    }

    setGeoIds(obj: IProjectC3) {
        const geoIds = obj.geos?.map((g) => g.geoId);
        obj.geoIds = geoIds;
    }

    onRowEditInit(obj: IProjectC3) {
        if (!obj?.id) return;
        this.clonedC3Items[obj.id] = { ...obj };
    }

    getForm(obj: IProjectC3) {
        if (!obj?.id) return;
        const form: FormGroup = this.fb.group({
            projectId: [obj.projectId, Validators.required],
            type: [obj.type, Validators.required],
            specieId: [obj.specieId, Validators.required],
            actId: [obj.actId, Validators.required],
            quantityYear1: [obj.quantityYear1, [Validators.required, Validators.min(0)]],
            year1Kind: [obj.year1Kind, Validators.required],
            quantityYear2: [obj.quantityYear2, [Validators.required, Validators.min(0)]],
            year2Kind: [obj.year2Kind, Validators.required],
            quantityYear3: [obj.quantityYear3, [Validators.required, Validators.min(0)]],
            year3Kind: [obj.year3Kind, Validators.required],
            typology: [obj.typology, Validators.required],
            system: [obj.system, Validators.required],
            method: [obj.method, Validators.required],
            geoIds: [obj.geoIds],
        });
        return form;
    }

    onRowEditSave(obj: IProjectC3) {
        if (!obj?.id) return;
        const form = this.getForm(obj);

        if (form?.valid) {
            delete this.clonedC3Items[obj.id];

            if (obj?.id.toString().includes('new_')) {
                const group = this.getItemGroup(obj);
                const index = group.indexOf(obj);
                this.pc3Service.store(form.value).then((c3) => {
                    this.setGeoIds(c3);
                    group[index] = {
                        ...c3,
                        specieCode: c3?.specie?.code,
                        specieName: c3?.specie?.name,
                        actCommunity: c3?.act?.community,
                    };
                    this.toasterService.success('El Registro fue creado satisfactoriamente.');
                });
            } else {
                const id = parseInt(obj.id.toString());
                const group = this.getItemGroup(obj);
                const index = group.indexOf(obj);
                this.pc3Service.update(form.value, id).then((resp) => {
                    this.setGeoIds(resp);
                    group[index].geoIds = resp.geoIds;
                    this.toasterService.success('El Registro fue actualizado satisfactoriamente.');
                });
            }
        } else {
            this.toasterService.error('No se pudo guardar, los datos son inválidos!');
        }
    }

    onRowEditCancel(obj: IProjectC3, index: number) {
        if (!obj?.id) return;

        const group = this.getItemGroup(obj);
        if (group[parseInt(String(obj.id))]) {
            group[index] = this.clonedC3Items[obj.id];
            delete group[parseInt(String(obj.id))];
        }

        if (obj?.id.toString().includes('new_add')) {
            group.pop();
        }
    }

    onNew(groupType: string) {
        const c3: IProjectC3 = {
            id: `new_add_${new Date().getTime()}`,
            projectId: parseInt(this.params['id']),
            quantityYear1: 0,
            quantityYear2: 0,
            quantityYear3: 0,
            typology: Constants.TYPOLOGIES.TYPOLOGY_II,
            type: groupType,
        };

        switch (groupType) {
            case Pc3Types.AFFORESTATION:
                this.c3AfforestationItems.push(c3);
                this.tableAfforestation.initRowEdit(
                    this.tableAfforestation.value[this.c3AfforestationItems.length - 1]
                );
                break;
            case Pc3Types.REFORESTATION:
                this.c3ReforestationItems.push(c3);
                this.tableReforestation.initRowEdit(
                    this.tableReforestation.value[this.c3ReforestationItems.length - 1]
                );
                break;
            case Pc3Types.SAF:
                this.c3SAFItems.push(c3);
                this.tableSAF.initRowEdit(this.tableSAF.value[this.c3SAFItems.length - 1]);
                break;
            default:
                break;
        }
    }

    getItemGroup(obj: any) {
        switch (obj.type) {
            case Pc3Types.SAF:
                return this.c3SAFItems;
            case Pc3Types.REFORESTATION:
                return this.c3ReforestationItems;
            default:
                return this.c3AfforestationItems;
        }
    }

    filterSpecieItems(event: any) {
        this.specieService.getAll({ params: { query: event.query } }).then((resp) => {
            this.species = [...resp];
        });
    }

    onSelectSpecie(event: any, obj: IProjectC2) {
        obj.specieId = event.id;
        obj.specieName = event.name;
        obj.specieCode = event.code;
    }

    setCoordinates(obj: IProjectC3) {
        this.setGeoIds(obj);
        this.modal.data = { geoIds: obj.geoIds, multi: true, enablePolygon: true };
        this.modal.header = 'Registrar Coordenadas';
        this.ref = this.dialogService.open(CoordinatesModalComponent, this.modal);
        this.ref.onClose.pipe(takeUntil(this.onDestroy$)).subscribe((resp) => {
            if (resp?.length) obj.geoIds = [...resp];
            else obj.geoIds = [];
        });
    }

    ngOnDestroy() {
        this.cleanParams();
    }
}
