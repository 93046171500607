import {Component, OnInit, ViewChild} from '@angular/core';
import {Constants, PROJECT_READ_ONLY} from "../../../shared/constants";
import {IProject, IProjectAssignment, ProjectService} from "../../../itcp";
import {MenuItem} from "primeng/api";
import {Table} from "primeng/table";
import {CredentialsService, CryptoService, ProjectFlow, ProjectStagesEnum} from "../../../shared";
import {PermissionService} from "../../../shared/services/permission.service";
import {User} from "../../../core/user/interfaces";
import {GenericFunctions} from "../../../shared/services/generic-functions";
import {Router} from "@angular/router";
import {LayoutService} from "../../../shared/services/app.layout.service";

@Component({
  selector: 'app-programmer',
  templateUrl: './programmer.component.html',
  styleUrls: ['./programmer.component.scss']
})
export class ProgrammerComponent implements OnInit {
    resource = Constants.RESOURCES.TRACKING_PROGRAMMER;
    projects: IProject[] = []; //Assigned projects

    loading: boolean = false;
    breadcrumbs: MenuItem[] = [
        { label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home'] },
        { label: ' Seguimiento', routerLink: ['/tracking'] },
        { label: ' Programación de Actividades' },
    ];
    @ViewChild('dt') table!: Table;

    constructor(
        private readonly credentialsService: CredentialsService,
        private readonly permissionService: PermissionService,
        private readonly projectService: ProjectService,
        private readonly router: Router,
        private readonly cryptoService: CryptoService,
        private readonly layoutService: LayoutService,

    ) {}

    ngOnInit(): void {
        this.layoutService.showMenuDesktop(true);
        this.getAll();
    }

    getAll(): void {
        this.loading = true;
        this.projectService.getAll({ params: {
            assignments: true,
            status: ProjectFlow.trackingStatusesForProgrammer(),
            stage: ProjectStagesEnum.TRACKING
        }})
            .then((resp) => {
                this.projects = [...resp];
            })
            .finally(() => {
                this.loading = false;
            });
    }

    setFilter(event: any, type: string, query: string, column: string = ''): void {
        if (type === 'filterGlobal') {
            this.table.filterGlobal(event.target.value, query);
        } else if (type === 'filter') {
            this.table.filter(event.target.value, column, query);
        }
    }

    initWizard(projectId: number, readOnly = true) {
        sessionStorage.setItem(PROJECT_READ_ONLY.PROGRAMMER, this.cryptoService.encrypt(true));
        if (!readOnly) {
            sessionStorage.setItem(PROJECT_READ_ONLY.PROGRAMMER, this.cryptoService.encrypt(false));
        }
        const route = `/tracking/programmer/${projectId}/schedule`;
        this.router.navigate([route]).then();
    }

    getFullName(user: User): string {
        return GenericFunctions.getUserFullName(user);
    }

    getRespFormulation(assignments: IProjectAssignment[]): string {
        const permissions = assignments.map(a => a.permissions);
        // return permissions.includes(Constants.TECHNICIAN_PERMISSIONS.PROGRAMME) ? 'Fonabosque' : 'Entidad Solicitante';
        return 'FONABOSQUE'
    }

    canProgramme(assignments: IProjectAssignment[]) {
        if (this.permissionService.isSuperAdminRole()) return true;
        const currentUser: User = this.credentialsService.getLoggedUser();
        const assigment = assignments.find(a => a.userId === currentUser.id);
        if (!assigment) return false;

        return [Constants.TECHNICIAN_PERMISSIONS.PROGRAMME].includes(assigment.permissions)
    }

    canReview(assignments: IProjectAssignment[]) {
        if (this.permissionService.isSuperAdminRole()) return true;
        const currentUser: User = this.credentialsService.getLoggedUser();
        const assigment = assignments.find(a => a.userId === currentUser.id);
        if (!assigment) return false;

        return [Constants.TECHNICIAN_PERMISSIONS.REVIEW_EVAL, Constants.TECHNICIAN_PERMISSIONS.REVIEW].includes(assigment.permissions)
    }

    programmedStatuses() {
        const statuses = [
            Constants.PROJECT_STATUSES.PROGRAMMED,
            ...ProjectFlow.trackingStatusesForMonitoring(),
            ...ProjectFlow.trackingStatusesForEvaluation(),
        ]
       return statuses.filter(s => s != Constants.PROJECT_STATUSES.PROGRAMMING)
    }

}
