export * from './assignment/assignment.component';
export * from "./programmer";
export * from "./monitoring";
export * from "./evaluation";
export * from "./orders/orders.component";
export * from "./orders/start-order/start-order.component";
export * from "./orders/change-order/change-order.component";
export * from "./orders/change-order/form/modal.component";
export * from "./project-details/project-details.component";
export * from "./project-details/docs/docs.component";
export * from "./project-details/ends/summary-physical/physical.component";
export * from "./project-details/ends/summary-physical/activities/activities.component";
export * from "./project-details/ends/summary-physical/activities/activities.component";
export * from "./project-details/ends/summary-financial/financial.component";
export * from "./project-details/ends/summary-financial/activities/activities.component";
export * from "./project-details/ends/summary-financial/activities/budgets/budgets.component";
export * from "./project-details/ends/activities/activities.component";
export * from "./project-details/ends/activities/activity-weekly/activities.component";
export * from "./project-details/ends/done/done.component";
export * from "./expost/expost.component";
export * from "./expost/eval/eval.component";



