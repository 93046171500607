<div class='w-full px-4 py-3 bg-secondary'>
    <div *ngIf="!editMode && group" class='flex gap-4 justify-content-start align-items-center'>
        <div class='flex gap-4 justify-content-start align-items-center'>
            <span class="font-bold">{{group.code}}.</span>{{group.title}}
        </div>
        <div>
            <button
                *ngIf="hasSelectableChildren(group.code)"
                class='p-button-success p-button-rounded p-button-text'
                [disabled]="!canEdit"
                pButton pTooltip="Agregar" tooltipPosition="top"
                (click)='onNew()'
            >
                <i class="pi pi-plus"></i>
            </button>
            <button pButton pRipple [disabled]="!canEdit" type="button" pTooltip="Eliminar" tooltipPosition="top" icon="pi pi-trash" (click)="removeGroup()" class="p-button-rounded p-button-text p-button-danger"></button>
        </div>
    </div>

    <div *ngIf='editMode' class='flex gap-4 justify-content-start align-items-center'>
        <div class='w-20rem'>
            <p-dropdown
                [options]="defaultCompliances"
                placeholder="Seleccione"
                [style]="{'width':'100%'}"
                scrollHeight="300px"
                [filter]="true"
                filterBy="value"
                optionLabel="title"
                optionValue="id"
                (onChange)='onSelectItem($event)'
            >
                <ng-template let-item pTemplate="selectedItem">
                    <div class="flex gap-1 flex-nowrap">
                        <span class="font-bold">{{item.code}}.</span>
                        <div>{{item.title}}</div>
                    </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                    <div class="flex gap-1 flex-nowrap">
                        <span class="font-bold">{{item.code}}.</span>
                        <div class="">{{item.title}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
        <div>
            <button
                pButton
                pRipple
                type="button"
                pSaveEditableRow
                icon="pi pi-check"
                (click)="saveGroup()"
                class="p-button-rounded p-button-text p-button-success mr-2">
            </button>
            <button pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="cancelEditGroup()" class="p-button-rounded p-button-text p-button-danger"></button>
        </div>
    </div>
</div>

<p-table [value]="group.children" dataKey="id" editMode="row" [tableStyle]="{width: '100%' }" #dt>
    <ng-template pTemplate="header">
        <tr>
            <th class="bg-secondary border-x-1 text-center p-0"style="width:60px"></th>
            <th class="bg-secondary border-x-1 text-center p-0"></th>
            <th class="bg-secondary border-x-1 text-center p-0"></th>
            <th class="bg-secondary border-x-1 text-center p-0"></th>
            <th class="bg-secondary p-0" style="width:100px"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-obj let-editing="editing" let-index="rowIndex">
        <tr [pEditableRow]="obj">
            <td style='width:60px'>{{this.group.code}}.{{index + 1}}</td>
            <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <div *ngIf="hasSelectableChildren(group.code)">
                            <p-dropdown
                                [options]="getDefaultGroupChildren(group.code)"
                                placeholder="Seleccione"
                                [(ngModel)]="obj.title" [ngModelOptions]="{standalone: true}"
                                [style]="{'width':'100%'}"
                                [filter]="true"
                                filterBy="title"
                                optionLabel="title"
                                optionValue="title"
                                (onChange)='onSelectSubItem($event, obj)'
                            >
                            </p-dropdown>

                            <input *ngIf="obj.other" pInputText
                                   [pAutoFocus]="true"
                                   placeholder='Escriba la descripción....'
                                   required
                                   class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full mt-4 other'
                                   type="text" [(ngModel)]="obj.compliance" [ngModelOptions]="{standalone: true}">
                        </div>
                        <div *ngIf="!hasSelectableChildren(group.code)">
                            <input pInputText
                                   required
                                   [value]="group.title"
                                   [readOnly]="true"
                                   class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full mt-4 other'
                                   type="text" [(ngModel)]="obj.compliance" [ngModelOptions]="{standalone: true}">
                        </div>

                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.compliance}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class='text-center'>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-radioButton name="submitted" [value]='false' [(ngModel)]="obj.submitted" [ngModelOptions]="{standalone: true}"></p-radioButton>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <i *ngIf="!obj.submitted" class="pi pi-check text-2xl p-primary-color"></i>
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class='text-center'>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-radioButton name="submitted" [value]='true' [(ngModel)]="obj.submitted" [ngModelOptions]="{standalone: true}"></p-radioButton>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <i *ngIf="obj.submitted" class="pi pi-check text-2xl p-primary-color"></i>
                    </ng-template>
                </p-cellEditor>
            </td>
            <td>
                <div *ngIf="canEdit" class="flex align-items-center justify-content-center gap-2">
                    <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(obj)" class="p-button-rounded p-button-text"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(obj)" class="p-button-rounded p-button-text p-button-success mr-2" [disabled]="!itemForm(obj).valid"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(obj, index)" class="p-button-rounded p-button-text p-button-danger"></button>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
