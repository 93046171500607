<app-wizard
  [steps]="steps"
  [current]="currentStep"
  [bodyContentTpl]="projectForm"
  [nextDisabled]="this.formGroup?.invalid"
  (onClickNext)="save($event)"
  [layout]="true"
>
</app-wizard>

<ng-template #projectForm>
  <div *ngIf="showContent" class="project-form flex justify-content-center flex-column" [formGroup]="formGroup">
    <div class="grid">
      <div class="field col-12 text-2xl text-primary uppercase">
        {{ currentStep?.text }}
      </div>

      <div class="field col-12">
        <button
          [disabled]="isSubmitted"
          class="p-button p-button-success"
          pButton
          pTooltip="Agregar"
          tooltipPosition="top"
          (click)="onNew('AFFORESTATION')"
        >
          <i class="pi pi-plus"></i>
        </button>
        <p-table
          #dtAfforestation
          [value]="c3AfforestationItems"
          dataKey="id"
          editMode="row"
          [tableStyle]="{ 'min-width': '150%' }"
        >
          <ng-template pTemplate="header">
            <tr>
              <th class="bg-primary">Forestacion</th>
              <th class="bg-primary">Especies</th>
              <th class="bg-primary">2023</th>
              <th class="bg-primary">Tipo</th>
              <th class="bg-primary">2024</th>
              <th class="bg-primary">Tipo</th>
              <th class="bg-primary">2025</th>
              <th class="bg-primary">Tipo</th>
              <th class="bg-primary">Sistema de Plantacion</th>
              <th class="bg-primary">Metodo de plantacion</th>
              <th class="bg-primary" style="width: 60px">Georeferencia</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-obj let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="obj">
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      [options]="communities"
                      [(ngModel)]="obj.actId"
                      [ngModelOptions]="{ standalone: true }"
                      optionLabel="community"
                      optionValue="id"
                      [style]="{ width: '100%' }"
                    >
                    </p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.actCommunity }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-autoComplete
                      [suggestions]="species"
                      [dropdown]="true"
                      (completeMethod)="filterSpecieItems($event)"
                      (onSelect)="onSelectSpecie($event, obj)"
                      field="code"
                      [minLength]="3"
                    >
                      <ng-template let-item pTemplate="item">
                        <div class="specie-item w-30rem">
                          <div class="specie-item-title" style="white-space: pre-wrap">
                            <b>{{ item.code }}</b> - {{ item.name }}
                          </div>
                        </div>
                      </ng-template>
                    </p-autoComplete>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <b>{{ obj.specieCode }}</b>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      min='0'
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      [(ngModel)]="obj.quantityYear1"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.quantityYear1 | number: '1.0-2'}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      [options]="projectC3Types"
                      [(ngModel)]="obj.year1Kind"
                      [ngModelOptions]="{ standalone: true }"
                      [style]="{ width: '100%' }"
                    >
                    </p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.year1Kind }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      min='0'
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      [(ngModel)]="obj.quantityYear2"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.quantityYear2 | number: '1.0-2'}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      [options]="projectC3Types"
                      [(ngModel)]="obj.year2Kind"
                      [ngModelOptions]="{ standalone: true }"
                      [style]="{ width: '100%' }"
                    >
                    </p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.year2Kind }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      min='0'
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      [(ngModel)]="obj.quantityYear3"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.quantityYear3 | number: '1.0-2'}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      [options]="projectC3Types"
                      [(ngModel)]="obj.year3Kind"
                      [ngModelOptions]="{ standalone: true }"
                      [style]="{ width: '100%' }"
                    >
                    </p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.year3Kind }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      [options]="defaultSystems"
                      [(ngModel)]="obj.system"
                      [ngModelOptions]="{ standalone: true }"
                      [style]="{ width: '100%' }"
                    >
                    </p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.system }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      [options]="methods"
                      [(ngModel)]="obj.method"
                      [ngModelOptions]="{ standalone: true }"
                      [style]="{ width: '100%' }"
                    >
                    </p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.method }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <div *ngIf="!isSubmitted" class="flex align-items-center justify-content-center gap-2">
                  <button
                    *ngIf="!editing"
                    pButton
                    pRipple
                    type="button"
                    pInitEditableRow
                    icon="pi pi-pencil"
                    (click)="onRowEditInit(obj)"
                    class="p-button-rounded p-button-text"
                  ></button>
                  <button
                    *ngIf="editing"
                    pButton
                    type="button"
                    icon="pi pi-map-marker"
                    pTooltip="Registrar Coordenadas"
                    tooltipPosition="top"
                    (click)="setCoordinates(obj)"
                    class="p-button-success px-3"
                  ></button>
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pSaveEditableRow
                    icon="pi pi-check"
                    [disabled]="!getForm(obj)?.valid"
                    (click)="onRowEditSave(obj)"
                    class="p-button-rounded p-button-text p-button-success mr-2"
                  ></button>
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pCancelEditableRow
                    icon="pi pi-times"
                    (click)="onRowEditCancel(obj, ri)"
                    class="p-button-rounded p-button-text p-button-danger"
                  ></button>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="field col-12">
        <button
          [disabled]="isSubmitted"
          class="p-button p-button-success"
          pButton
          pTooltip="Agregar"
          tooltipPosition="top"
          (click)="onNew('REFORESTATION')"
        >
          <i class="pi pi-plus"></i>
        </button>
        <p-table
          #dtReforestation
          [value]="c3ReforestationItems"
          dataKey="id"
          editMode="row"
          [tableStyle]="{ 'min-width': '150%' }"
        >
          <ng-template pTemplate="header">
            <tr>
              <th class="bg-primary">Reforestacion</th>
              <th class="bg-primary">Especies</th>
              <th class="bg-primary">2023</th>
              <th class="bg-primary">Tipo</th>
              <th class="bg-primary">2024</th>
              <th class="bg-primary">Tipo</th>
              <th class="bg-primary">2025</th>
              <th class="bg-primary">Tipo</th>
              <th class="bg-primary">Sistema de Plantacion</th>
              <th class="bg-primary">Metodo de plantacion</th>
              <th class="bg-primary" style="width: 60px">Georeferencia</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-obj let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="obj">
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      [options]="communities"
                      [(ngModel)]="obj.actId"
                      [ngModelOptions]="{ standalone: true }"
                      optionLabel="community"
                      optionValue="id"
                      [style]="{ width: '100%' }"
                    >
                    </p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.actCommunity }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-autoComplete
                      [suggestions]="species"
                      [dropdown]="true"
                      (completeMethod)="filterSpecieItems($event)"
                      (onSelect)="onSelectSpecie($event, obj)"
                      field="code"
                      [minLength]="3"
                    >
                      <ng-template let-item pTemplate="item">
                        <div class="specie-item w-30rem">
                          <div class="specie-item-title" style="white-space: pre-wrap">
                            <b>{{ item.code }}</b> - {{ item.name }}
                          </div>
                        </div>
                      </ng-template>
                    </p-autoComplete>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <b>{{ obj.specieCode }}</b>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      min='0'
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      [(ngModel)]="obj.quantityYear1"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.quantityYear1 | number: '1.0-2'}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      [options]="projectC3Types"
                      [(ngModel)]="obj.year1Kind"
                      [ngModelOptions]="{ standalone: true }"
                      [style]="{ width: '100%' }"
                    >
                    </p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.year1Kind }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      min='0'
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      [(ngModel)]="obj.quantityYear2"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.quantityYear2 | number: '1.0-2'}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      [options]="projectC3Types"
                      [(ngModel)]="obj.year2Kind"
                      [ngModelOptions]="{ standalone: true }"
                      [style]="{ width: '100%' }"
                    >
                    </p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.year2Kind }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      min='0'
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      [(ngModel)]="obj.quantityYear3"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.quantityYear3 | number: '1.0-2'}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      [options]="projectC3Types"
                      [(ngModel)]="obj.year3Kind"
                      [ngModelOptions]="{ standalone: true }"
                      [style]="{ width: '100%' }"
                    >
                    </p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.year3Kind }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      [options]="defaultSystems"
                      [(ngModel)]="obj.system"
                      [ngModelOptions]="{ standalone: true }"
                      [style]="{ width: '100%' }"
                    >
                    </p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.system }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      [options]="methods"
                      [(ngModel)]="obj.method"
                      [ngModelOptions]="{ standalone: true }"
                      [style]="{ width: '100%' }"
                    >
                    </p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.method }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <div *ngIf="!isSubmitted" class="flex align-items-center justify-content-center gap-2">
                  <button
                    *ngIf="!editing"
                    pButton
                    pRipple
                    type="button"
                    pInitEditableRow
                    icon="pi pi-pencil"
                    (click)="onRowEditInit(obj)"
                    class="p-button-rounded p-button-text"
                  ></button>
                  <button
                    *ngIf="editing"
                    pButton
                    type="button"
                    icon="pi pi-map-marker"
                    pTooltip="Registrar Coordenadas"
                    tooltipPosition="top"
                    (click)="setCoordinates(obj)"
                    class="p-button-success px-3"
                  ></button>
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pSaveEditableRow
                    icon="pi pi-check"
                    [disabled]="!getForm(obj)?.valid"
                    (click)="onRowEditSave(obj)"
                    class="p-button-rounded p-button-text p-button-success mr-2"
                  ></button>
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pCancelEditableRow
                    icon="pi pi-times"
                    (click)="onRowEditCancel(obj, ri)"
                    class="p-button-rounded p-button-text p-button-danger"
                  ></button>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="field col-12">
        <button
          [disabled]="isSubmitted"
          class="p-button p-button-success"
          pButton
          pTooltip="Agregar"
          tooltipPosition="top"
          (click)="onNew('SAF')"
        >
          <i class="pi pi-plus"></i>
        </button>
        <p-table #dtSAF [value]="c3SAFItems" dataKey="id" editMode="row" [tableStyle]="{ 'min-width': '150%' }">
          <ng-template pTemplate="header">
            <tr>
              <th class="bg-primary">Sistemas agroforestales SAF</th>
              <th class="bg-primary">Especies</th>
              <th class="bg-primary">2023</th>
              <th class="bg-primary">Tipo</th>
              <th class="bg-primary">2024</th>
              <th class="bg-primary">Tipo</th>
              <th class="bg-primary">2025</th>
              <th class="bg-primary">Tipo</th>
              <th class="bg-primary">Sistema de Plantacion</th>
              <th class="bg-primary">Metodo de plantacion</th>
              <th class="bg-primary" style="width: 60px">Georeferencia</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-obj let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="obj">
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      [options]="communities"
                      [(ngModel)]="obj.actId"
                      [ngModelOptions]="{ standalone: true }"
                      optionLabel="community"
                      optionValue="id"
                      [style]="{ width: '100%' }"
                      scrollHeight="100px"
                      [panelStyleClass]="'dropdown-scroll-custom'"
                    >
                    </p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.actCommunity }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-autoComplete
                      [suggestions]="species"
                      [dropdown]="true"
                      (completeMethod)="filterSpecieItems($event)"
                      (onSelect)="onSelectSpecie($event, obj)"
                      field="code"
                      [minLength]="3"
                    >
                      <ng-template let-item pTemplate="item">
                        <div class="specie-item w-30rem">
                          <div class="specie-item-title" style="white-space: pre-wrap">
                            <b>{{ item.code }}</b> - {{ item.name }}
                          </div>
                        </div>
                      </ng-template>
                    </p-autoComplete>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <b>{{ obj.specieCode }}</b>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      min='0'
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      [(ngModel)]="obj.quantityYear1"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.quantityYear1 | number: '1.0-2'}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      [options]="projectC3Types"
                      [(ngModel)]="obj.year1Kind"
                      [ngModelOptions]="{ standalone: true }"
                      [style]="{ width: '100%' }"
                    >
                    </p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.year1Kind }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      min='0'
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      [(ngModel)]="obj.quantityYear2"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.quantityYear2 | number: '1.0-2'}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      [options]="projectC3Types"
                      [(ngModel)]="obj.year2Kind"
                      [ngModelOptions]="{ standalone: true }"
                      [style]="{ width: '100%' }"
                    >
                    </p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.year2Kind }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      required
                      min='0'
                      class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                      type="number"
                      [(ngModel)]="obj.quantityYear3"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.quantityYear3 | number: '1.0-2'}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      [options]="projectC3Types"
                      [(ngModel)]="obj.year3Kind"
                      [ngModelOptions]="{ standalone: true }"
                      [style]="{ width: '100%' }"
                    >
                    </p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.year3Kind }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      [options]="safSystems"
                      [(ngModel)]="obj.system"
                      [ngModelOptions]="{ standalone: true }"
                      [style]="{ width: '100%' }"
                    >
                    </p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.system }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      [options]="methods"
                      [(ngModel)]="obj.method"
                      [ngModelOptions]="{ standalone: true }"
                      [style]="{ width: '100%' }"
                    >
                    </p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ obj.method }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <div *ngIf="!isSubmitted" class="flex align-items-center justify-content-center gap-2">
                  <button
                    *ngIf="!editing"
                    pButton
                    pRipple
                    type="button"
                    pInitEditableRow
                    icon="pi pi-pencil"
                    (click)="onRowEditInit(obj)"
                    class="p-button-rounded p-button-text"
                  ></button>
                  <button
                    *ngIf="editing"
                    pButton
                    type="button"
                    icon="pi pi-map-marker"
                    pTooltip="Registrar Coordenadas"
                    tooltipPosition="top"
                    (click)="setCoordinates(obj)"
                    class="p-button-success px-3"
                  ></button>
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pSaveEditableRow
                    icon="pi pi-check"
                    [disabled]="!getForm(obj)?.valid"
                    (click)="onRowEditSave(obj)"
                    class="p-button-rounded p-button-text p-button-success mr-2"
                  ></button>
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pCancelEditableRow
                    icon="pi pi-times"
                    (click)="onRowEditCancel(obj, ri)"
                    class="p-button-rounded p-button-text p-button-danger"
                  ></button>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</ng-template>
