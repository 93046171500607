import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    IEdtpCompliance,
    IProjectComplianceGroup,
    ProjectAssessmentModel
} from '../../../models';
import {ActivatedRoute} from "@angular/router";
import {ProjectService} from "../../../../itcp";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ToasterService} from "../../../../shared/services/toaster.service";
import { Table } from 'primeng/table';
import { LayoutService } from '../../../../shared/services/app.layout.service';
import {EdtpComplianceService, ProjectComplianceGroupService} from "../../../services";
import {ComplianceGroupEnum, CryptoService} from "../../../../shared";

@Component({
  selector: 'app-observations',
  templateUrl: './observations.component.html',
  styleUrls: ['./observations.component.scss']
})
export class ObservationsComponent extends ProjectAssessmentModel implements OnInit, OnDestroy {

    private step: number = 3;
    groups: IProjectComplianceGroup[] = [];

    defaultCompliances: IEdtpCompliance[] = [];

    @ViewChild('dt') table!: Table;
    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly complianceGroupService: ProjectComplianceGroupService,
        private readonly edtpComplianceService: EdtpComplianceService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private layoutService: LayoutService,
        private crypto: CryptoService,
    ) {
        super(activatedRoute, projectService, tService, crypto);
    }

    ngOnInit(): void {
        this.layoutService.showMenuDesktop(false);
        this.readParams();
        this.setActive(this.step);
        this.service.get(this.params['id']).then((project) => {
            const {id, name} = project || {};
            this.currentProject = {...project};
            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required]
            });
            return this.edtpComplianceService.getAll();
        }).then((resp) => {
            this.defaultCompliances = [ ...resp ];
            this.load();
        }).finally(() => {
            this.setSteps();
            this.setActive(this.step); //We need to reload the steps and put as active the current step
            this.disableFormIfSubmitted();
            this.showContent = true;
        });

    }

    load() {
        const projectId = parseInt(this.params['id']);
        this.complianceGroupService.getAll({ params: { projectId, type: ComplianceGroupEnum.COMPLIANCE }})
            .then((resp) => {
                this.groups = [ ...resp ];
            });
    }

    ngOnDestroy() {
        this.cleanParams();
    }
}
