import { MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subject } from 'rxjs';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { CartographyService } from '../../../shared';
import { ICity } from '../../../shared';
import { AnnouncementService, IAnnouncement, IITCPParsed, IITCPRequest, ITCPRequestService } from '../../../itcp';
import { Constants } from '../../../shared/constants';
import { Html2canvasService } from '../../../shared/services/html2canvas.service';
import { PdfBuilder } from '../../../shared/lib/pdf-builder/pdf-builder';

const PROJECT_STATUSES = {
    SEND: 'Enviado',
    NOT_COMPLETED: 'No completado',
    WITH_USER: 'Con usuario y contraseña'
};

type CountingData = {
    [name: string]: number;
};

type SendRequestData = {
    name: string;
    count: number;
    provinces: {
        [key: string]: {
            name: string;
            count: number;
            towns: { [key: string]: { name: string; count: number; state: string } };
        };
    };
};

type MappedSendRequestData = {
    [key: string]: SendRequestData;
};

type ParsedCountingData = {
    name: string;
    count: number;
};

@Component({
    selector: 'app-announcements',
    templateUrl: './announcements.component.html',
    styleUrls: ['./announcements.component.scss']
})
export class AnnouncementsComponent implements OnInit, OnDestroy {
    loading = false;
    showData = false;
    _data: IITCPParsed[] = [];
    announcements: IAnnouncement[] = [];
    cities: ICity[] = [];
    states = Object.values(PROJECT_STATUSES).map((value) => ({
        name: value
    }));
    towns: any[] = [];
    totalSend: number = 0;
    totalIncomplete: number = 0;
    totalWithUser: number = 0;
    dataPerCity: CountingData = {};
    parsedDataPerCity: ParsedCountingData[] = [];
    private _requestDataPerCity: MappedSendRequestData = {};
    private _requestDataPerCityParsed: any[] = [];
    requestDataPerProvinceParsed: any[] = [];
    requestDataPerTownParsed: any[] = [];
    flattedDataPerTown: any[] = [];
    filteredFlattedData: any[] = [];
    public resource = Constants.RESOURCES.ITCP_REQUESTS;
    private onDestroy$ = new Subject();
    announcementForm!: FormGroup;
    itcpForm!: FormGroup;
    breadcrumbs: MenuItem[] = [{ label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home'] }, { label: ' Reportes' }];
    @ViewChild('dtTable') table!: Table;
    @ViewChild('dtCities') tableCities!: Table;
    @ViewChild('dtProvinces') tableProvinces!: Table;
    @ViewChild('dtTowns') tableTowns!: Table;
    @ViewChild('interestedTownsPDFContent') interestedTownsPDFContent!: ElementRef;
    @ViewChild('townsListContent') townsListContent!: ElementRef;
    @ViewChild('chartPDFRef') chartPDFRef!: ElementRef;
    announcementDropdownSettings: IDropdownSettings = {};
    citiesDropdownSettings: IDropdownSettings = {};
    townsDropdownSettings: IDropdownSettings = {};
    stateDropdownSettings: IDropdownSettings = {};
    interestedTownsChartData: any = {};
    filteredByTownsChartData: any = {};
    barChartOptions: any = {};
    generatingPDF: boolean = false;

    constructor(
        private fb: FormBuilder,
        private readonly itcpRequestService: ITCPRequestService,
        private readonly service: AnnouncementService,
        private readonly cartographyService: CartographyService,
        private html2canvasService: Html2canvasService
    ) {
    }

    get requestDataPerCityParsed(): any[] {
        return this._requestDataPerCityParsed;
    }

    set requestDataPerCityParsed(value: any[]) {
        this._requestDataPerCityParsed = value;
        const provinceDataParsed: any[] = [];
        const townDataParsed: any[] = [];
        this._requestDataPerCityParsed.forEach((city) => {
            for (const province of city.provinces) {
                let parsedProvince: any = {};
                parsedProvince.cityName = city.name;
                parsedProvince.provinceName = province.name;
                parsedProvince.count = province.count;
                for (const town of province.towns) {
                    let parsedTown: any = [];
                    parsedTown.cityName = city.name;
                    parsedTown.provinceName = province.name;
                    parsedTown.townName = town.name;
                    parsedTown.count = town.count;
                    parsedTown.state = town.state;

                    townDataParsed.push(parsedTown);
                }

                provinceDataParsed.push(parsedProvince);
            }
        });

        this.requestDataPerProvinceParsed = provinceDataParsed;
        this.requestDataPerTownParsed = townDataParsed;
        this.showData = true;
    }

    get data() {
        return this._data;
    }

    set data(requests: IITCPRequest[]) {
        this.totalIncomplete = 0;
        this.totalSend = 0;
        this.totalWithUser = 0;
        this.dataPerCity = {};
        this.parsedDataPerCity = [];
        this._requestDataPerCity = {};

        this._data = requests.map((request) => {
            return this.processIITCPRequestData(request);
        });
        // Sort Data per Town
        this.sortDataPerTown();
        this.filteredFlattedData = this.flattedDataPerTown;
        this.setParsedDataPerCity();
        this.setRequestDataPerCityParsed();
        this.setChartsData();
    }

    generatePDF() {
        this.generatingPDF = true;
        setTimeout(async () => {
            const pdf = new PdfBuilder('Municipios interesados', 'CONVOCATORIAS');

            const tableElement = this.table.tableViewChild.nativeElement;
            await pdf.addTable(tableElement, 'MUNICIPIOS INTERESADOS');

            const townsChartElement = this.interestedTownsPDFContent.nativeElement;
            await pdf.addImage(townsChartElement, 'El cuadro y gráfico muestra la lista de municipios que mostraron interés en la convocatoria lanzada por el FONABOSQUE. Los mismos cuentan con usuario y contraseña para poder realizar el llenado de formulario ITCP.');

            const barChartPdfElement = this.chartPDFRef.nativeElement;
            await pdf.addImage(barChartPdfElement, 'Monto establecido en el ITCP');
            pdf.addText('El gráfico muestra el detalle del presupuesto establecido por los municipios en el ITCP, mostrando el monto financiado por el FONABOSQUE y el monto financiado por la entidad solicitante');

            pdf.generate('fonabosque_reporte_convocatorias');
            this.generatingPDF = false;
        }, 10);
    }

    private setChartsData() {
        this.interestedTownsChartData = {
            labels: ['Enviado', 'No Completado', 'Con usuario y contraseña'],
            datasets: [
                {
                    label: 'Municipios Interesados',
                    data: [this.totalSend, this.totalIncomplete, this.totalWithUser],
                    backgroundColor: ['rgb(243, 156, 18)', 'rgb(255, 233, 51)', 'rgb(125, 183, 60)'],
                    hoverOffset: 4
                }
            ]
        };

        const ownerAmounts: any[] = [];
        const entityAmounts: any[] = [];
        const labels: any[] = [];
        this.towns.forEach(({ name: town }) => {
            const relevantData = this.filteredFlattedData.filter((d) => d.townName === town);
            const filteredTownNames = this.filteredFlattedData.map((f) => f.townName);
            let ownerAmount = 0;
            let entityAmount = 0;
            relevantData.forEach((data) => {
                ownerAmount = ownerAmount + (data.ownerAmount || 0);
                entityAmount = entityAmount + (data.entityAmount || 0);
            });
            if (filteredTownNames.includes(town)) {
                labels.push(town);
                ownerAmounts.push(ownerAmount);
                entityAmounts.push(entityAmount);
            }
        });
        this.filteredByTownsChartData = {
            labels,
            datasets: [
                {
                    label: 'Suma presupuesto FONABOSQUE',
                    backgroundColor: '#42A5F5',
                    data: ownerAmounts
                },
                {
                    label: 'Suma presupuesto Entidad',
                    backgroundColor: '#9c9239',
                    data: entityAmounts
                }
            ]
        };
        this.barChartOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };
    }

    private processIITCPRequestData(request: IITCPRequest) {
        const { user, projects = [], entity } = request;

        let state;
        if (user && projects.length) {
            state = PROJECT_STATUSES.SEND;
            this.totalSend++;
        } else if (user && user?.codigoVerificacionRevisado) {
            state = PROJECT_STATUSES.WITH_USER;
            this.totalWithUser++;
        } else {
            state = PROJECT_STATUSES.NOT_COMPLETED;
            this.totalIncomplete++;
        }

        // Counting by City
        if (entity?.city?.dep) {
            const count = this.dataPerCity[entity?.city?.dep] || 0;
            this.dataPerCity[entity?.city?.dep] = count + 1;
        }

        const project = projects?.length ? projects[0] : {};
        let totalBeneficiaries = 0;
        let budget: any = {};

        if (Object.keys(project).length > 0) {
            const { budgets = [], beneficiaries = [] } = project;
            totalBeneficiaries = beneficiaries.reduce((a, b) => {
                return a + (b.men + b.women);
            }, 0);
            budget = budgets.length ? budgets[0] : ({} as any);
        }

        this.processAndSetRelevantData(entity, state, budget);

        return { ...request, state, project, totalBeneficiaries, budget };
    }

    private setParsedDataPerCity() {
        // Set parsed data per city
        const cityKeys = Object.keys(this.dataPerCity);

        for (const depKey of cityKeys) {
            const obj = { name: depKey, count: this.dataPerCity[depKey] };
            this.parsedDataPerCity.push(obj);
        }
    }

    private setRequestDataPerCityParsed() {
        const values = Object.values(this._requestDataPerCity);
        this.requestDataPerCityParsed = values.map((city) => {
            const provinceValues = Object.values(city.provinces);
            const provinceFlat = provinceValues.map((province) => {
                const townValues = Object.values(province.towns);

                return { ...province, towns: townValues };
            });

            return { ...city, provinces: provinceFlat };
        });
    }

    sortDataPerTown() {
        this.flattedDataPerTown.sort((a, b) => a.cityName.localeCompare(b.cityName));
        const townNames = this.flattedDataPerTown.map((town) => town.townName);
        townNames.sort((a, b) => a.localeCompare(b));
        this.towns = [...new Set(townNames)].map((t) => ({ name: t }));
    }

    processAndSetRelevantData(entity: any, state: string, budget: any) {
        if (entity?.city) {
            if (state === PROJECT_STATUSES.SEND) {
                let city = this._requestDataPerCity[entity?.city?.dep] || { provinces: {} };
                const cityCount = city.count || 0;

                let province = city?.provinces[entity?.province.prov] || { towns: {} };
                const provinceCount = province.count || 0;

                let town = (province?.towns || {})[entity?.town?.mun] || {};
                const townCount = town.count || 0;
                province.towns[entity?.town?.mun] = { ...town, name: entity?.town?.mun, count: townCount + 1, state };

                city.provinces[entity?.province.prov] = {
                    ...province,
                    name: entity?.province.prov,
                    count: provinceCount + 1
                };
                this._requestDataPerCity[entity?.city.dep] = { ...city, name: entity?.city.dep, count: cityCount + 1 };
            }

            // New Implementation starting here
            const perProvinceData: any = {
                cityName: entity?.city?.dep,
                provinceName: entity?.province.prov,
                townName: entity?.town.mun,
                state,
                ownerAmount: budget?.ownerAmount,
                entityAmount: budget.entityAmount
            };

            this.flattedDataPerTown.push(perProvinceData);
        }
    }

    ngOnInit(): void {
        this.announcementForm = this.fb.group({
            announcementIds: [[]],
            cityIds: [[]]
        });
        this.itcpForm = this.fb.group({
            towns: [[]],
            states: [[]]
        });

        this.cartographyService
            .getCities()
            .then((cities) => {
                this.cities = [...cities];
                return this.service.getAll();
            })
            .then((resp) => {
                this.announcements = [...resp];
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => (this.loading = false));
        this.generateDropdownSettings();
    }

    private generateDropdownSettings() {
        this.announcementDropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'title',
            selectAllText: 'Todos',
            unSelectAllText: 'Ninguno',
            itemsShowLimit: 4,
            allowSearchFilter: true
        };

        this.citiesDropdownSettings = {
            singleSelection: false,
            idField: 'gid',
            textField: 'dep',
            selectAllText: 'Todos',
            unSelectAllText: 'Ninguno',
            itemsShowLimit: 4,
            allowSearchFilter: true
        };

        this.townsDropdownSettings = {
            singleSelection: false,
            idField: 'name',
            textField: 'name',
            selectAllText: 'Todos',
            unSelectAllText: 'Ninguno',
            itemsShowLimit: 4,
            allowSearchFilter: true
        };

        this.stateDropdownSettings = {
            singleSelection: false,
            idField: 'name',
            textField: 'name',
            selectAllText: 'Todos',
            unSelectAllText: 'Ninguno',
            itemsShowLimit: 4,
            allowSearchFilter: true
        };
    }

    get announcementFormControls() {
        return this.announcementForm.controls || {};
    }

    getAll(): void {
        this.loading = true;
        const postData: any = this.announcementForm.value;
        let { announcementIds = [], cityIds = [] } = postData;
        announcementIds = announcementIds.map((a: any) => parseInt(a.id));
        cityIds = cityIds.map((c: any) => c.gid);
        this.itcpRequestService
            .getAll({
                params: {
                    withProjects: true,
                    announcementIds,
                    cityIds
                }
            })
            .then((response) => {
                this.data = response;
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.loading = false;
                this.generateDropdownSettings();
            });
    }

    filterByTownsAndStates() {
        const data: any = this.itcpForm.value;
        const { states, towns } = data;
        if (!states.length && !towns.length) {
            this.filteredFlattedData = this.flattedDataPerTown;
            return;
        }
        const townNames = towns.map((t: any) => t.name);
        const stateNames = states.map((s: any) => s.name);
        this.filteredFlattedData = this.flattedDataPerTown.filter((t) => {
            let hasValidState = true;
            let hasValidTown = true;
            if (states.length) hasValidState = stateNames.includes(t.state);
            if (towns.length) hasValidTown = townNames.includes(t.townName);

            return hasValidState && hasValidTown;
        });

        this.setChartsData();
    }

    reload() {
        this.getAll();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(undefined);
        this.onDestroy$.complete();
    }
}
