import { IAttachment } from '../../../shared/interfaces';
import { ICoordinates } from './coordinates.interface';

export interface IProjectProperty {
    id?: number | string;
    projectId: number;
    registration: string;
    realFolioFile?: IAttachment;
    realFolioFileId: number | undefined;
    cadastralFile?: IAttachment;
    cadastralFileId: number | undefined;
    points?: { geoId: number; propertyId: number;}[];
    pointIds?: number[];
}
