<div class="card border-0">
  <p-skeleton *ngIf="!showForm" width="100%" height="150px"></p-skeleton>
  <form *ngIf="showForm" class="form-signin" [formGroup]="modalForm">
    <div class="grid">
      <div class="field lg:col-12 md:col-12 sm:col-12">
        <label>Título</label>
        <input
          id="title"
          pInputText
          formControlName="title"
          [ngClass]="{ 'ng-invalid ng-dirty': form.title.errors && form.title.touched }"
          type="text"
          class="text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
        />
        <div *ngIf="form.title.errors">
          <p *ngIf="form.title.errors.required && form.title.touched" class="text-danger">*Campo requerido</p>
        </div>
      </div>
      <div class="field lg:col-12 md:col-12 sm:col-12">
        <label>Descripción</label>
        <textarea
          id="description"
          rows="6"
          pInputText
          formControlName="description"
          [ngClass]="{ 'ng-invalid ng-dirty': form.description.errors && form.description.touched }"
          class="text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
        >
        </textarea>
        <div *ngIf="form.description.errors">
          <p *ngIf="form.description.errors.required && form.description.touched" class="text-danger">
            *Campo requerido
          </p>
        </div>
      </div>

      <div class="field lg:col-12 md:col-12 sm:col-12">
        <label>Entidades Habilitadas</label>
        <ng-multiselect-dropdown
          formControlName="enabledEntityTypes"
          [placeholder]="'Seleccione las Entidades habilitadas'"
          [settings]="entitiesDropdownSettings"
          [data]="entitiesDropdown"
        >
        </ng-multiselect-dropdown>
        <div *ngIf="form.enabledEntityTypes.errors">
          <p *ngIf="form.enabledEntityTypes.errors?.required && form.enabledEntityTypes.touched" class="text-danger">
            *Campo requerido
          </p>
        </div>
      </div>

      <div class="field lg:col-12 md:col-12 sm:col-12">
        <label>Departamentos Inhabilitados</label>
        <ng-multiselect-dropdown
          formControlName="restrictedCities"
          [placeholder]="'Seleccione los Departamentos inhabilitados'"
          [settings]="citiesDropdownSettings"
          [data]="cities"
        >
        </ng-multiselect-dropdown>
        <div *ngIf="form.restrictedCities.errors">
          <p *ngIf="form.restrictedCities.errors?.required && form.restrictedCities.touched" class="text-danger">
            *Campo requerido
          </p>
        </div>
      </div>

      <div class="field lg:col-12 md:col-12 sm:col-12">
        <label>Municipios Inhabilitados</label>
        <p-autoComplete
          [suggestions]="towns"
          [dropdown]="true"
          [multiple]="true"
          formControlName="restrictedTowns"
          (completeMethod)="filterTowns($event)"
          field="mun"
          placeholder="Municipio"
          [style]="{ width: '100%' }"
          [minLength]="3"
        >
          <ng-template let-town pTemplate="item">
            <div class="town-item">
              <div>{{ town.mun }}</div>
              <div class="text-xs text-bluegray-300">{{ town.dep }} - {{ town.prov }}</div>
            </div>
          </ng-template>
        </p-autoComplete>
        <div *ngIf="form.restrictedTowns.errors">
          <p *ngIf="form.restrictedTowns.errors?.required && form.restrictedTowns.touched" class="text-danger">
            *Campo requerido
          </p>
        </div>
      </div>
      <div class="field col-12 md:col-6">
        <label>Periodo de Ejecución del Proyecto (en años)</label>
        <p-dropdown
          [options]="[{ year: 1 }, { year: 2 }, { year: 3 }]"
          formControlName="yearsLimit"
          optionLabel="year"
          placeholder="Seleccione"
          optionValue="year"
          [style]="{ width: '100%' }"
        >
        </p-dropdown>
      </div>

      <div class="field col-12 md:col-6">
        <label>Fecha Límite de Postulacion</label>
        <p-calendar
          placeholder="Fecha Límite"
          dateFormat="yy-mm-dd"
          [style]="{ width: '100%' }"
          [showIcon]="true"
          [yearNavigator]="true"
          [monthNavigator]="true"
          appendTo="body"
          [readonlyInput]="true"
          dataType="string"
          [minDate]="minDate"
          [ngClass]="{ 'ng-invalid ng-dirty': form.dueDate.errors && form.dueDate.dirty }"
          formControlName="dueDate"
        ></p-calendar>
        <div *ngIf="form.dueDate.errors">
          <p *ngIf="form.dueDate.errors.required && form.dueDate.touched" class="text-danger">*Campo requerido</p>
        </div>
      </div>

      <div class="field col-12 md:col-6">
        <label>Estado de Publicación</label>
        <div class="field-checkbox">
          <p-checkbox [formControl]="form.published" [binary]="true" [label]="'Publicado'"> </p-checkbox>
        </div>
      </div>

      <div class="field lg:col-12 md:col-12 sm:col-12">
        <app-input-file
          [label]="'Archivo de la Convocatoria'"
          [multiple]="false"
          [maxFiles]="1"
          [accept]="'*'"
          [defaultFiles]="defaultFiles"
          (onUploaded)="onUploaded($event)"
        >
        </app-input-file>

        <input id="fileId" formControlName="fileId" type="hidden" />
        <div *ngIf="form.fileId.errors">
          <p *ngIf="form.fileId.errors?.required && form.dirty" class="text-danger">*Campo requerido</p>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="p-dialog-footer">
  <button pButton type="button" label="Cancelar" class="p-button-secondary" (click)="closeModal()"></button>
  <button pButton type="button" label="Guardar" (click)="save()" [disabled]="this.modalForm.invalid"></button>
</div>
