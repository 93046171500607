import { IProject } from '../../itcp';
import { User } from '../../core/user/interfaces';
import { ISettings, ProjectStagesEnum } from '../../shared';

export type TypologyType = 'TYPOLOGY_II' | 'TYPOLOGY_III' | 'TYPOLOGY_IV' | 'TYPOLOGY_V';
export type ComponentType = 'COMPONENT1' | 'COMPONENT2' | 'COMPONENT3' | 'COMPONENT4' | 'COMPONENT5' | 'ADMINISTRATION' | 'AUDIT';
export type CostType = 'TANGIBLE' | 'INTANGIBLE' | 'WORKING_CAPITAL';
export type StageType = keyof typeof ProjectStagesEnum;
export type ComplianceGroupType = 'COMPLIANCE' | 'QUANTITATIVE_ASSESSMENT';
export type ProgrammerType = 'FS' | 'FN';

export type ProjectAssignment = {
    id: number;
    project: IProject,
    physicalTech: User,
    financialTech?: User
};

export type UserAssignment = {
    userId: number;
    permissions: string;
    stage: StageType;
}

export type GroupedComponents = {
    label: string;
    value: string;
    items: ISettings[];
}

export type BudgetSummaryType = {
    description: string;
    owner: number;
    entity: number;
    entityInKind: number;
    total: number;
    type?: ComponentType;
}

export type FinancingStructureType = {
    description: string;
    year1: BudgetSummaryType;
    year2?: BudgetSummaryType;
    year3?: BudgetSummaryType;
    totals: BudgetSummaryType;
    type?: ComponentType;
}

export type QuantitativeTotalsType = { scoreAccum: number; scoreDist: number; maxScoreAccum: number; maxScoreDist: number; };

export const SCORES = {
    worst: 0,
    bad: 1,
    regular: 2,
    good: 3,
    excellent: 4
}
