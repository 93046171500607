import {IComponent} from "../../../shared";

export interface IProjectGoal {
    id?: number | string;
    projectId: number;
    description: string;
    componentId?: number;
    component?: IComponent;
    indicator: string;
    unit: string;
    currentSituation: number;
    goal: number;
    goalDescription?: string;
}
