import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { ICoordinates, IProjectProperty, ProjectModel } from '../../../models';
import {Table} from "primeng/table";
import {ActivatedRoute} from "@angular/router";
import {ProjectPropertyService, ProjectService} from "../../../services";
import {AttachmentService, CartographyService, CryptoService} from '../../../../shared';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToasterService} from "../../../../shared/services/toaster.service";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Constants } from '../../../../shared/constants';
import { Subject, takeUntil } from 'rxjs';
import {
    CoordinatesModalComponent,
    CoordinatesModalType,
    GreenIcon
} from './coordinates-modal/coordinates-modal.component';
import * as L from "leaflet";

@Component({
    selector: 'app-property',
    templateUrl: './property.component.html',
    styleUrls: ['./property.component.scss']
})
export class PropertyComponent extends ProjectModel implements OnInit, OnDestroy {

    private step: number = 7;
    property!: IProjectProperty;
    cloned: IProjectProperty | undefined;
    map:any;
    @ViewChild('dt') table!: Table;
    private onDestroy$ = new Subject();
    public ref!: DynamicDialogRef;
    private modal: DynamicDialogConfig<CoordinatesModalType> = {
        ...Constants.MODAL.default,
        width: '90%'
    };
    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly propertyService: ProjectPropertyService,
        public readonly attachmentService: AttachmentService,
        private fb: FormBuilder,
        private tService: ToasterService,
        public readonly dialogService: DialogService,
        private readonly cartographyService: CartographyService,
        private crypto: CryptoService,
    ) {
        super(activatedRoute, projectService, tService, crypto);
    }

    ngOnInit(): void {
        this.readParams();
        this.setActive(this.step);
        this.service.get(this.params['id']).then((project) => {
            const {id, name, dataProperty} = project || {};
            this.currentProject = { ...project };
            if (dataProperty) this.property = {...dataProperty};
            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required],
            });
            if (!dataProperty) {
                this.property = {
                    cadastralFileId: undefined,
                    pointIds: [],
                    realFolioFileId: undefined,
                    registration: "",
                    projectId: parseInt(this.params['id']),
                    id: `new_${new Date().getTime()}`
                };

                setTimeout(() => {
                    this.table.initRowEdit(this.property);
                }, 500);
            }
            this.property.pointIds = this.property.points?.map(p => p.geoId );
            this.disableFormIfSubmitted();
            this.showContent = true;
            setTimeout(() => this.buildMap(), 500);
        });
    }
    onRowEditInit(obj: IProjectProperty) {
        if (!obj?.id) return;
        this.cloned = {...obj};
    }

    onRowEditSave(obj: IProjectProperty) {
        if (!obj?.id) return;

        const objForm = this.getItemForm(obj);
        if (objForm.valid) {
            delete this.cloned;

            if (obj?.id.toString().includes('new_')) {
                this.propertyService.store(objForm.value).then((resp) => {
                    const pointIds = this.property.pointIds;
                    this.property = { ...resp, pointIds };

                    this.toasterService.success('El Derecho Propetario fue creado satisfactoriamente.');
                    setTimeout(() => this.buildMap(), 500);
                });

            } else {
                const id = parseInt(obj.id.toString());
                this.propertyService.update(objForm.value, id).then((resp) => {
                    this.toasterService.success('El Derecho Propetario fue actualizado satisfactoriamente.');
                    setTimeout(() => this.buildMap(), 500);
                })
            }

        }
        else {
            this.toasterService.error('No se pudo guardar los datos son inválidos!');
        }
    }

    getItemForm(obj: IProjectProperty) {
        const realFolioFileId = this.property.realFolioFileId ? this.property.realFolioFileId : obj.realFolioFileId;
        const cadastralFileId = this.property.cadastralFileId ? this.property.cadastralFileId : obj.cadastralFileId;

        const objForm: FormGroup = this.fb.group({
            registration: [obj.registration, Validators.required],
            realFolioFileId: [realFolioFileId],
            cadastralFileId: [cadastralFileId],
            projectId: [obj.projectId, Validators.required],
            pointIds: [obj.pointIds]
        });
        return objForm;
    }

    onRowEditCancel(obj: IProjectProperty, index: number) {
        if (!obj?.id) return;

        if (this.cloned) {
            this.property = { ...this.cloned };
            delete this.cloned;
        }
    }

    onUploadedFolio(data: any) {
        const { files } = data;
        if (files.length && this.property) {
            this.property.realFolioFileId = files[0].id;
        }
    }

    onUploadedCadastral(data: any) {
        const { files } = data;
        if (files.length && this.property) {
            this.property.cadastralFileId = files[0].id;
        }
    }

    setCoordinates(obj: IProjectProperty) {
        this.modal.data = { geoIds: obj.pointIds, multi: true, enablePolygon: false };
        this.modal.header = 'Registrar Coordenadas';
        this.ref = this.dialogService.open(CoordinatesModalComponent, this.modal);
        this.ref.onClose.pipe(takeUntil(this.onDestroy$)).subscribe((resp) => {
            if (resp) {
                this.saveCoordinates(resp)
            }
        });
    }
    saveCoordinates(geoIds: number[]) {
        this.property.pointIds = geoIds;
    }

    buildMap() {
        if (this.map) {
            this.map.off();
            this.map.remove();
        };

        let center:any = [-16.2837065, -63.5493965];
        this.map = L.map('property-map', {
            center,
            zoom: 6
        });

        const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        });
        tiles.addTo(this.map);

        if (!this.property.pointIds?.length) return;
        this.cartographyService.getGeoLocations({ params: { id: this.property.pointIds}}).then((result) => {
            result?.map((r: any) => {
                const point = JSON.parse(r.geom);
                center = point.coordinates;
                L.geoJSON(point, { pointToLayer: function (feature, latlng) {
                        return L.marker(latlng, {
                            icon: GreenIcon
                        });
                    } }).addTo(this.map);
            });
            this.map.flyTo(center.reverse(), 13);
        });
    }

    ngOnDestroy() {
        this.cleanParams();
        this.onDestroy$.next(undefined);
        this.onDestroy$.complete();
    }
}
