<div class="topbar">
    <div class="topbar-logo">
        <a class="layout-topbar-logo" routerLink="/home">
            <img src="assets/images/logo.png" alt="logo" id="fonabosque-logo-img" />
        </a>
    </div>
    <div class="topbar-content">
        <form [formGroup]="geovisorForm" class="flex w-full">
            <div class="grid-nogutter flex flex-wrap w-full">
                <div class="lg:col-3 md:col-6 sm:col-12">
                    <ng-multiselect-dropdown
                        [placeholder]="'Departamento'"
                        [settings]="getCitiesDropdownSettings()"
                        [data]="cities"
                        formControlName="cityIds"
                    >
                    </ng-multiselect-dropdown>
                </div>
                <div class="lg:col-3 md:col-6 sm:col-12">
                    <ng-multiselect-dropdown
                        [placeholder]="'Provincia'"
                        [settings]="getProvincesDropdownSettings()"
                        [data]="provinces"
                        formControlName="provinceIds"
                    >
                    </ng-multiselect-dropdown>
                </div>
                <div class="lg:col-3 md:col-6 sm:col-12">
                    <ng-multiselect-dropdown
                        [placeholder]="'Municipio'"
                        [settings]="getTownsDropdownSettings()"
                        [data]="towns"
                        formControlName="townIds"
                    >
                    </ng-multiselect-dropdown>
                </div>
                <div class="col ml-3">
                    <button
                        pButton
                        type="button"
                        label="Filtrar"
                        (click)="onFilter()"
                        [disabled]="this.geovisorForm.invalid"
                    ></button>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="sidebar-left">
    <div class="sidebar-left-content">
        <div class="p-2">
            <p-card header="Reporte ITCP" *ngIf="approvedRejectedProjects.length > 0">
                <div class="grid-nogutter flex">
                    <div class="field sm:col-5">
                        <div #chartPDFRef>
                            <div class="mb-3">
                                <div class="flex">
                                    <span class="font-semibold w-6 text-right">Aprobados:</span>
                                    <span class="ml-2">{{ this.totalApproved }}</span>
                                </div>
                                <div class="flex">
                                    <span class="font-semibold w-6 text-right">Deshabilitado:</span>
                                    <span class="ml-2">{{ this.totalRejected }}</span>
                                </div>
                                <div class="flex">
                                    <span class="font-semibold w-6 text-right">Total: </span>
                                    <span class="ml-2">{{ this.totalApproved + this.totalRejected }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="field sm:col-7">
                        <p-chart type="pie" [data]="approvedRejectedChartData"></p-chart>
                    </div>
                </div>
            </p-card>
        </div>

        <p-card header="Presupuesto" *ngIf="approvedRejectedProjects.length > 0">
            <div #budgetBarChartPDF>
                <p-chart type="bar" [data]="financialChartData" [options]="stackedOptions"></p-chart>
            </div>
        </p-card>

        <div *ngIf="!approvedRejectedProjects.length && !filteredFlattedData.length" class="text-center mt-5">
            Use los filtros para obtener reportes de los diferentes proyectos
        </div>
    </div>
</div>
<div id="map-container">
    <div id="map-geovisor"></div>
</div>
