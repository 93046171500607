export * from './response.interface';
export { ProcessTypes } from './process.interface';
export { Permissions } from './permissions.interface';
export { EventType } from './event-type.interface';
export * from './attachment.interface';
export * from './settings.interface';
export * from './typology.interface';
export * from './cartography';
export * from "./component.interface";

