import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {Constants} from "../shared/constants";
import {AnnouncementsComponent, EdtpComponent, ItcpComponent, TrackingComponent} from "./components";

const routes: Routes = [
    { path: '', component: AnnouncementsComponent, data: { moduleNameCode: Constants.RESOURCES.REPORTS_ANNOUNCEMENTS } },
    { path: 'announcements', component: AnnouncementsComponent, data: { moduleNameCode: Constants.RESOURCES.REPORTS_ANNOUNCEMENTS } },
    { path: 'itcp', component: ItcpComponent, data: { moduleNameCode: Constants.RESOURCES.REPORTS_ITCP } },
    { path: 'edtp', component: EdtpComponent, data: { moduleNameCode: Constants.RESOURCES.REPORTS_EDTP } },
    { path: 'tracking', component: TrackingComponent, data: { moduleNameCode: Constants.RESOURCES.REPORTS_TRACKING } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
