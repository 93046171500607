import { Injectable } from '@angular/core';
import { BaseHttpService, HttpOptions } from 'src/app/shared/services/index';
import { lastValueFrom } from 'rxjs';
import { ICity, INeighborhood, IProvince, IStreet, ITown } from '../interfaces';
import { CoordinatesTable, GeometricType, ICoordinates } from '../../itcp';

@Injectable({
    providedIn: 'root',
})
export class CartographyService {
    constructor(private readonly _baseHttpService: BaseHttpService) {}

    async getCities(options?: HttpOptions): Promise<ICity[]> {
        return lastValueFrom(this._baseHttpService.get('cartography/cities', options));
    }

    async getProvinces(options?: HttpOptions): Promise<IProvince[]> {
        return lastValueFrom(this._baseHttpService.get(`cartography/provinces`, options));
    }

    async getTowns(options?: HttpOptions): Promise<ITown[]> {
        return lastValueFrom(this._baseHttpService.get(`cartography/towns`, options));
    }

    async getNeighborhoods(options?: HttpOptions): Promise<INeighborhood[]> {
        return lastValueFrom(this._baseHttpService.get(`cartography/neighborhoods`, options));
    }

    async getStreets(options?: HttpOptions): Promise<IStreet[]> {
        return lastValueFrom(this._baseHttpService.get(`cartography/streets`, options));
    }

    async findCoordinates(gid: number, table: CoordinatesTable) {
        return lastValueFrom(this._baseHttpService.get(`cartography/coordinates/${gid}/${table}`));
    }

    async findCoordinatesByGids(options?: HttpOptions) {
        return lastValueFrom(this._baseHttpService.get('cartography/coordinates', options));
    }

    async storeGeometry(data: any): Promise<any> {
        return lastValueFrom(this._baseHttpService.post('cartography/create/geometry', data));
    }

    async updateGeometry(data: any, id: number): Promise<any> {
        return lastValueFrom(this._baseHttpService.put(`cartography/update/geometry/${id}`, data));
    }

    async delete(id: number): Promise<boolean> {
        return lastValueFrom(this._baseHttpService.delete(`cartography/delete/geometry/${id}`));
    }

    async getGeoLocations(options?: HttpOptions) {
        return lastValueFrom(this._baseHttpService.get(`cartography/geolocations`, options));
    }
}
