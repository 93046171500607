import {Component, OnDestroy, OnInit} from '@angular/core';
import {ProjectEdtpModel} from "../../../models";
import {ActivatedRoute} from "@angular/router";
import {ProjectService} from "../../../../itcp";
import {ProjectBaseLineService} from "../../../services";
import {CryptoService, SettingsService} from "../../../../shared";
import {FormBuilder, Validators} from "@angular/forms";
import {ToasterService} from "../../../../shared/services/toaster.service";
import {Constants} from "../../../../shared/constants";
import {LayoutService} from "../../../../shared/services/app.layout.service";

@Component({
  selector: 'app-done',
  templateUrl: './done.component.html',
  styleUrls: ['./done.component.scss']
})
export class DoneComponent extends ProjectEdtpModel implements OnInit, OnDestroy {

    private step: number = 27;

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly baseLineService: ProjectBaseLineService,
        private readonly setService: SettingsService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private layoutService: LayoutService,
        private crypto: CryptoService,
    ) {
        super(activatedRoute, projectService, tService, setService, crypto);
    }

    ngOnInit(): void {
        this.layoutService.showMenuDesktop(true);
        this.readParams();
        this.setActive(this.step);
        this.service.get(this.params['id']).then((project) => {
            const {id, name} = project || {};
            this.currentProject = {...project};
            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required],
                status: [Constants.PROJECT_STATUSES.SUBMITTED],
            });

        }).finally(() => {
            this.setSteps();
            this.setActive(this.step); //We need to reload the steps and put as active the current step
            this.disableFormIfSubmitted();
            this.showContent = true;
        });

    }


    ngOnDestroy() {
        this.cleanParams();
    }
}
