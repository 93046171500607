export const Constants = Object.freeze({
    RESOURCES: {
        USERS: 'USUARIOS',
        ROLES: 'ROLES',
        SYSTEM_PERMISSIONS: 'PERMISOS_SISTEMA',
        USERS_ADMIN_MENU: 'USUARIOS_ADMIN_MENU',
        LOG_ACTIVITIES: 'LOG_ACTIVIDADES',
        ITCP_ADMIN_MENU: 'ITCP_ADMIN_MENU',
        ANNOUNCEMENTS: 'ANNOUNCEMENTS',
        ITCP_REQUESTS: 'ITCP_REQUESTS',
        ITCP_REGISTER: 'ITCP_REGISTER',
        PUBLIC: 'PUBLIC',
        ENTITIES: 'ENTIDADES',
        PROJECTS: 'PROJECTS',
        EDTP_MENU: 'EDTP_MENU',
        EDTP_ASIGNMENT: 'EDTP_ASIGNMENT',
        EDTP_FORM: 'EDTP_FORM',
        EDTP_EVAL: 'EDTP_EVAL',
        ITCP_ASSIGNMENT: 'ITCP_ASSIGNMENT',
        ITCP_EVAL: 'ITCP_EVAL',
        BUDGET_ITEMS: 'BUDGET_ITEMS',
        ITCP_RANKING: 'ITCP_RANKING',
        EDTP_RANKING: 'EDTP_RANKING',
        EDTP_SCHEDULER: 'EDTP_SCHEDULER',
        TRACKING_MENU: 'TRACKING_MENU',
        TRACKING_ASSIGNMENTS: 'TRACKING_ASSIGNMENTS',
        TRACKING_PROGRAMMER: 'TRACKING_PROGRAMMER',
        TRACKING_MONITORING: 'TRACKING_MONITORING',
        TRACKING_EVALUATION: 'TRACKING_EVALUATION',
        TRACKING_ORDERS: 'TRACKING_ORDERS',
        TRACKING_CHANGE_ORDER: 'TRACKING_CHANGE_ORDER',
        TRACKING_START_ORDER: 'TRACKING_START_ORDER',
        TRACKING_PROJECT_DETAILS: 'TRACKING_PROJECT_DETAILS',
        REPORTS_MENU: 'REPORTS_MENU',
        REPORTS_ANNOUNCEMENTS: 'REPORTS_ANNOUNCEMENTS',
        REPORTS_ITCP: 'REPORTS_ITCP',
        REPORTS_EDTP: 'REPORTS_EDTP',
        REPORTS_TRACKING: 'REPORTS_TRACKING',
        VISITS_MENU: 'VISITS_MENU',
        VISITS_FORESTATION_REFORESTATION: 'VISITS_FORESTATION_REFORESTATION',
        VISITS_INFRASTRUCTURE: 'VISITS_INFRASTRUCTURE',
        VISITS_PRODUCTION: 'VISITS_PRODUCTION',
        CLOSE_PROJECT: 'CLOSE_PROJECT',
        PROJECT_EXPOST: 'PROJECT_EXPOST',
        GEOVISOR: 'GEOVISOR',
    },
    RESOURCE_TYPE: {
        MODULE: 'MODULO',
        VIEW: 'VIEW',
    },
    PERMISSIONS: {
        isSuperAdmin: 'isSuperAdmin',
        canView: 'canView',
        canCreate: 'canCreate',
        canDelete: 'canDelete',
        canUpdate: 'canUpdate',
        isAdmin: 'isAdmin',
    },
    MODAL: {
        default: {
            width: '60%',
            contentStyle: {
                overflow: 'auto',
            },
            baseZIndex: 10000,
            closable: true,
        },
    },
    PROJECT_STATUSES: {
        DRAFT: 'DRAFT',
        STARTED: 'STARTED',
        ACTIVE: 'ACTIVE',
        COMPLETED: 'COMPLETED',
        SUBMITTED: 'SUBMITTED',
        VERIFIED: 'VERIFIED',
        VALUED_OK: 'VALUED_OK', //ITCP: score greater than 70
        VALUED_FAIL: 'VALUED_FAIL', //ITCP: score less or equal than 70
        EVALUATED: 'EVALUATED', //EDTP: assessment has been done
        NOTIFIED: 'NOTIFIED', //entity was notified
        APPROVED: 'APPROVED', //ITCP, EDTP user decides if project approves
        REJECTED: 'REJECTED', //ITCP, EDTP user decides if project is rejected
        ASSIGNED_ITCP: 'ASSIGNED_ITCP', //project was assigned(ITCP)
        ASSIGNED_EDTP: 'ASSIGNED_EDTP', //project was assigned(EDTP)
        ASSIGNED_TRACKING: 'ASSIGNED_TRACKING', //project was assigned(TRACKING)
        PROCESSING: 'PROCESSING', //Project is filling
        PROGRAMMING: 'PROGRAMMING', //Project is being programming (Tracking)
        PROGRAMMED: 'PROGRAMMED', //Project is programmed (Tracking)
        START_ORDERED: 'START_ORDERED', //Project start ordered (Tracking)
        CHANGE_ORDERED: 'CHANGE_ORDERED',
        REPORTING: 'REPORTING', //Project is being reporting (Monitoring)
        REPORTED: 'REPORTED', //Project has been reported (Monitoring)
        REPORT_EVALUATING: 'REPORT_EVALUATING', //Project is being evaluating (Monitoring)
        REPORT_EVALUATED: 'REPORT_EVALUATED', //Project has been evaluated (Monitoring)
        CONSOLIDATED: 'CONSOLIDATED', //If project status change to consolidated in stage tracking it means that a start date has been assigned to the project.
        CONSOLIDATED_SUBMITTED: 'CONSOLIDATED_SUBMITTED',
        CLOSED: 'CLOSED', //Project closed
    },
    PROJECT_ASSIGNMENT_STATUSES: {
        COMPLETED: 'COMPLETED',
        VALUED_OK: 'VALUED_OK', //score greater than 70
        VALUED_FAIL: 'VALUED_FAIL', //score less or equal than 70
        SUBMITTED: 'SUBMITTED',
    },
    ROLES: {
        SUPER_ADMIN: 'Super Admin',
        ADMIN: 'Admin',
        OPERATOR: 'Operador',
        REQUESTING_ENTITY: 'Entidad Solicitante',
        MAE: 'MAE',
        COORDINADOR: 'COORDINADOR',
        RESP_PLANIFICACION: 'RESP-PLANIFICACION',
        RESP_SEGUIMIENTO: 'RESP-SEGUIMIENTO',
        TEC_FIN_PLAN: 'TEC-FIN-PLAN',
        TEC_FIS_PLAN: 'TEC-FIS-PLAN',
        TEC_FIN_SEG: 'TEC-FIN-SEG',
        TEC_FIS_SEG: 'TEC-FIS-SEG',
        MAE_SOLICITANTE: 'MAE-SOLICITANTE',
        RESP_SOLICITANTE: 'RESP-SOLICITANTE',
        TEC_SOLICITANTE: 'TEC-SOLICITANTE',
    },
    SETTING_TYPES: {
        UOM: 'UOM',
        INFRASTRUCTURE_COMPONENT: 'INFRASTRUCTURE_COMPONENT',
        PRODUCTION_COMPONENT: 'PRODUCTION_COMPONENT',
        FORESTATION_COMPONENT: 'FORESTATION_COMPONENT',
        REFORESTATION_COMPONENT: 'REFORESTATION_COMPONENT',
        TRAINING_COMPONENT: 'TRAINING_COMPONENT',
        TECHNICAL_ASSISTANCE_COMPONENT: 'TECHNICAL_ASSISTANCE_COMPONENT',
        EQUIPMENT_COMPONENT: 'EQUIPMENT_COMPONENT',
        INFRASTRUCTURE_TIV_COMPONENT: 'INFRASTRUCTURE_TIV_COMPONENT',
        INVESTIGATION_COMPONENT: 'INVESTIGATION_COMPONENT',
        SOCIALIZATION_COMPONENT: 'SOCIALIZATION_COMPONENT',
        TECHNOLOGY_TRANSFER_COMPONENT: 'TECHNOLOGY_TRANSFER_COMPONENT',
    },
    COMPONENT_TYPE: {
        INFRASTRUCTURE: 'INFRASTRUCTURE',
        PRODUCTION: 'PRODUCTION',
        FORESTATION: 'FORESTATION',
        REFORESTATION: 'REFORESTATION',
        TRAINING: 'TRAINING',
        TECHNICAL_ASSISTANCE: 'TECHNICAL_ASSISTANCE',
        EQUIPMENT: 'EQUIPMENT',
        INFRASTRUCTURE_TIV: 'INFRASTRUCTURE_TIV',
        INVESTIGATION: 'INVESTIGATION',
        SOCIALIZATION: 'SOCIALIZATION',
        TECHNOLOGY_TRANSFER: 'TECHNOLOGY_TRANSFER',
    },
    TECHNICIAN_PERMISSIONS: {
        REVIEW: 'REVIEW',
        EVAL: 'EVAL',
        REVIEW_EVAL: 'REVIEW_EVAL',
        FORMULATE: 'FORMULATE',
        PROGRAMME: 'PROGRAMME',
    },
    TYPOLOGIES: {
        TYPOLOGY_II: 'TYPOLOGY_II',
        TYPOLOGY_III: 'TYPOLOGY_III',
        TYPOLOGY_IV: 'TYPOLOGY_IV',
        TYPOLOGY_V: 'TYPOLOGY_V',
    },
    MONTHS: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
    ],
    MONTHS_UNDEFINED: [
        'Mes 1',
        'Mes 2',
        'Mes 3',
        'Mes 4',
        'Mes 5',
        'Mes 6',
        'Mes 7',
        'Mes 8',
        'Mes 9',
        'Mes 10',
        'Mes 11',
        'Mes 12',
    ],
    TIMEZONE: 'America/La_Paz',
    PDF: {
        MAX_HEIGHT: 297,
        MAX_WIDTH: 210,
        MAX_TEXT_WIDTH: 190,
    },
});

export const DEFAULT_CONTENT_NOTIFICATION =
    ' Saludos, <b>{{contactName}},</b><br/><br/>\n' +
    '\n' +
    '              Queremos informarte que hemos completado la evaluación de tu proyecto y nos complace compartir los resultados contigo.<br/>\n' +
    '              Si tienes alguna pregunta o inquietud sobre los resultados de la evaluación, no dudes en comunicarte con nosotros.';
export const PROJECT_READ_ONLY = {
    PROGRAMMER: 'programmer_read_only',
    FORMULATE: 'formulation_read_only',
    MONITORING: 'monitoring_read_only',
};

export const MIME_TYPES = {
    COMPRESSED:
        'zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed rar,application/rar,application/x-rar,application/x-rar-compressed',
};
