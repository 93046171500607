<app-wizard
    [steps]='steps'
    [current]='currentStep'
    [bodyContentTpl]='projectForm'
    [nextDisabled]='this.formGroup?.invalid'
    (onClickNext)='save($event)'
    [layout]='true'
>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column' [formGroup]='formGroup'>
        <div class='grid'>
            <div class='field col-12'>
                <label>Beneficios esperados del Proyecto (ambiental, social y económico)</label>
                <textarea
                    [rows]='10'pInputTextarea [autoResize]='false'
                    formControlName='advantage'
                    placeholder='Beneficios esperados del Proyecto (ambiental, social y económico)'
                    class='text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                >
                </textarea>
                <div *ngIf="form.advantage.errors">
                    <p *ngIf="form.advantage.errors?.required && form.advantage.touched" class="text-danger">
                        *Campo requerido
                    </p>

                    <p *ngIf="form.advantage.errors?.maxlength" class="text-danger">
                        *Máximo de caracteres permitido es 3000
                    </p>
                </div>
            </div>

            <div class='field col-12'>
                <p-table [value]="beneficiaries" dataKey="id" (onEditComplete)='onEditComplete($event)'>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th class="bg-primary border-x-1" style="width:20%">Beneficiarios</th>
                            <th class="bg-primary border-x-1" style="width:16%">Hombres</th>
                            <th class="bg-primary border-x-1" style="width:16%">Mujeres</th>
                            <th class="bg-primary border-x-1" style="width:16%">Hombres(%) </th>
                            <th class="bg-primary border-x-1" style="width:16%">Mujeres(%) </th>
                            <th class="bg-primary border-x-1" style="width:16%">Total </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-beneficiary>
                        <tr>
                            <td>
                                <b>{{ beneficiary.beneficiaryType === 'DIRECT' ? 'Directo' : beneficiary.beneficiaryType === 'INDIRECT' ? 'Indirecto' : 'Total'}}</b>
                            </td>
                            <td *ngIf='!isSubmitted' [pEditableColumn]="beneficiary" [pEditableColumnField]="'men'">
                                <p-cellEditor *ngIf="beneficiary.beneficiaryType != 'TOTAL'">
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number"
                                               min='0'
                                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                                               [(ngModel)]="beneficiary.men" required [ngModelOptions]="{standalone: true}">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{beneficiary.men}}
                                    </ng-template>
                                </p-cellEditor>
                                <b *ngIf="beneficiary.beneficiaryType === 'TOTAL'">{{beneficiary.men}}</b>
                            </td>
                            <td *ngIf='isSubmitted'>
                                {{ beneficiary.men | number: '1.0-2'}}
                            </td>
                            <td *ngIf="!isSubmitted" [pEditableColumn]="beneficiary" [pEditableColumnField]="'women'">
                                <p-cellEditor *ngIf="beneficiary.beneficiaryType != 'TOTAL'">
                                    <ng-template pTemplate="input">
                                        <input pInputText
                                               type='number'
                                               min='0'
                                               class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                                               [(ngModel)]="beneficiary.women" required [ngModelOptions]="{standalone: true}">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{beneficiary.women | number: '1.0-2'}}
                                    </ng-template>
                                </p-cellEditor>
                                <b *ngIf="beneficiary.beneficiaryType === 'TOTAL'">{{beneficiary.women}}</b>
                            </td>
                            <td *ngIf='isSubmitted'>
                                {{ beneficiary.women}}
                            </td>
                            <td>
                                <span [class.total]="beneficiary.beneficiaryType ==='TOTAL'">
                                    {{beneficiary.menPercentage | number: '1.0-0' }} %
                                </span>
                            </td>
                            <td>
                                <span [class.total]="beneficiary.beneficiaryType ==='TOTAL'">
                                    {{beneficiary.womenPercentage | number: '1.0-0' }} %
                                </span>
                            </td>
                            <td>
                                <b>{{beneficiary.total}}</b>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</ng-template>

