import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectRequirementKeys, ProjectRequirementValues, ProjectService } from '../../../services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { AttachmentService, IAttachment, SettingsService } from '../../../../shared';
import { ProjectAssessmentModel } from '../../../models/project-assessment.model';
import { Table } from 'primeng/table';
import { ProjectRequirementService } from '../services/project-requirement.service';
import { IParsedPR, IProjectRequirement } from '../interfaces/project-requirement.interface';
import { LayoutService } from '../../../../shared/services/app.layout.service';

@Component({
  selector: 'app-requirement',
  templateUrl: './requirement.component.html',
  styleUrls: ['./requirement.component.scss'],
})
export class RequirementComponent extends ProjectAssessmentModel implements OnInit, OnDestroy {
  private step = 1;

  requirementValues = [...ProjectRequirementValues];

  requestFinancingFile: IAttachment | undefined;
  cloned: { [s: string]: IParsedPR } = {};
  _requirements: IProjectRequirement[] = [];
  parsedRequirements: IParsedPR[] = [];
  itcpFile: IAttachment | undefined;
  swornFile: IAttachment | undefined;

  @ViewChild('dtTable') table!: Table;

  constructor(
    private activatedRoute: ActivatedRoute,
    private projectService: ProjectService,
    private fb: FormBuilder,
    private tService: ToasterService,
    private settingService: SettingsService,
    private projectRequirementService: ProjectRequirementService,
    private attachmentService: AttachmentService,
    private readonly layoutService: LayoutService,
  ) {
    super(activatedRoute, projectService, tService);
  }

  get requirements() {
    return this._requirements;
  }

  set requirements(data: IProjectRequirement[]) {
    this.parsedRequirements = this.requirementValues.map((requirement) => {
      const relatedData = data.find((r) => r.key === requirement.key) || {};
      return { ...requirement, ...relatedData };
    });

    this._requirements = data;
  }

  ngOnInit(): void {
      this.layoutService.showMenuDesktop(false);
    this.readParams();
    this.setActive(this.step);
    const projectId = this.params['id'];

    this.projectService.get(projectId).then((project) => {
      const { id, name, itcpFile, swornFile, requestFinancingFile, requirements = [] } = project;
      this.currentProject = { ...project };

      this.requirements = [...requirements];
      if (itcpFile) this.itcpFile = itcpFile;
      if (swornFile) this.swornFile = swornFile;
      if (requestFinancingFile) this.requestFinancingFile = requestFinancingFile;

      this.formGroup = this.fb.group({
        id: [id],
        name: [name, Validators.required],
      });

      this.disableFormIfCompleted();
      this.showContent = true;
    });
  }

  saveData(key: string, checked: boolean, name: string) {
    this.formGroup.patchValue({ key, [name]: checked });
    // Invalid
    if (!this.formGroup.valid) {
      this.toasterService.error('Corrija los datos e inténtelo nuevamente');
      return;
    }

    const postData: IProjectRequirement = this.formGroup.value;

    this.projectService.upsertRequirement(postData, this.params['id']).then((resp) => {
      const { requirements } = resp || {};
      const expected = this.requirementValues.map((v) => v.key);
      this.currentProject = { ...resp };
      if (requirements) {
        const verified = requirements.filter(
          (r: any) => (r.verified || r.formulationVerified) && expected.includes(r.key)
        );

        // Change status when all has been verified
      }
    });
  }

  onChange(event: any, key: string, field: string) {
    this.saveData(key, event.value, field);
  }

  getDownloadFileUrl(key: any) {
    const file = this.getFile(key);
    if (!file) return null;

    const { key: fileKey } = file;
    if (!fileKey) return '#';

    return this.attachmentService.getFileUrl(fileKey);
  }

  getFilename(key: string) {
    const file = this.getFile(key);
    return file.filename;
  }

  getFile(key: string): any {
    switch (key) {
      case ProjectRequirementKeys.FINANCE_LETTER:
        return this.requestFinancingFile;
      case ProjectRequirementKeys.SWORN_DECLARATION:
        return this.swornFile;
      case ProjectRequirementKeys.SOCIAL_COMMITMENT_LETTER:
        return this.itcpFile;

      default:
        return null;
    }
  }

  onRowEditInit(obj: IParsedPR) {
    if (!obj?.key) return;
    this.cloned[obj.key] = { ...obj };
  }

  onRowEditSave(obj: IParsedPR) {
    if (!obj?.key) return;

    const objForm: FormGroup = this.fb.group({
      verified: [obj.verified, Validators.required],
      key: [obj.key, Validators.required],
      formulationVerified: [obj.formulationVerified, Validators.required],
      projectId: parseInt(this.params['id']),
    });

    this.projectService.upsertRequirement(objForm.value, parseInt(this.params['id'])).then((project) => {
      const { requirements = [] } = project;
      this.currentProject = { ...project };
      this.requirements = [...requirements];
      this.toasterService.success('Datos actualizados con exito');
    });
  }

  onRowEditCancel(obj: any, index: number) {
    if (!obj?.key) return;

    if (this.cloned[obj.key]) {
      this.parsedRequirements[index] = this.cloned[obj.id];
      delete this.cloned[obj.key];
    }
  }

  ngOnDestroy() {
    this.cleanParams();
  }
}
