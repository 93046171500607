<app-wizard
    [steps]='steps'
    [current]='currentStep'
    [bodyContentTpl]='projectForm'
    [nextDisabled]='this.formGroup?.invalid'
    (onClickNext)='save($event)'
    [layout]='true'
>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column' [formGroup]='formGroup'>
        <div class='grid'>

            <div class='field col-12'>
                <h3>
                    IDENTIFICACION DE POSIBLES RIESGOS DE DESASTRES
                </h3>
            </div>
            <div class="field col-12">
                <button
                    class='p-button p-button-success mb-2'
                    pButton pTooltip="Agregar Riesgo" tooltipPosition="top"
                    [disabled]='isSubmitted'
                    (click)='onNew()'
                >
                    <i class='pi pi-plus'></i>
                </button>
                <p-table [value]="risks" dataKey="id" editMode="row" [tableStyle]="{'min-width': '100%'}" #dt>
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width:40%">Riesgo</th>
                            <th style="width:40%">Nivel</th>
                            <th style="width:20%"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item let-editing="editing" let-ri="rowIndex">
                        <tr [pEditableRow]="item">
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-dropdown
                                            [options]="riskOptions"
                                            optionLabel="name"
                                            placeholder="Seleccione"
                                            optionValue="id"
                                            [filter]="true"
                                            [style]="{ width: '100%' }"
                                            (onChange)="onSelectRisk($event)"
                                            [(ngModel)]="item.riskId" [ngModelOptions]="{standalone: true}"
                                        >
                                        </p-dropdown>

                                        <input *ngIf="showOtherRisk || isOtherRisk(item.risk)"
                                               id="name"
                                               pInputText
                                               placeholder="Ingrese la descripción en este campo"
                                               type="text"
                                               [(ngModel)]="item.other" [ngModelOptions]="{standalone: true}"
                                               class="p-inputtext text-base text-color surface-overlay p-2 mt-4 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full other-risk">

                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ isOtherRisk(item.risk) ? 'Otros: ' + item.other : item.risk?.name}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <div class="flex justify-content-between align-items-center">
                                            <div>
                                                <p-radioButton class="ml-4 mb-2" name="grouplevels" [value]="levels.HIGH" [label]="levels.HIGH" [(ngModel)]="item.level" [ngModelOptions]="{standalone: true}"></p-radioButton>
                                                <p-radioButton class="ml-4 mb-2" name="grouplevels" [value]="levels.MEDIUM" [label]="levels.MEDIUM" [(ngModel)]="item.level" [ngModelOptions]="{standalone: true}"></p-radioButton>
                                            </div>
                                            <div>
                                                <p-radioButton class="ml-4 mb-2" name="grouplevels" [value]="levels.LOW" [label]="levels.LOW" [(ngModel)]="item.level" [ngModelOptions]="{standalone: true}"></p-radioButton>
                                                <p-radioButton class="ml-4 mb-2" name="grouplevels" [value]="levels.NONE" [label]="levels.NONE" [(ngModel)]="item.level" [ngModelOptions]="{standalone: true}"></p-radioButton>
                                            </div>
                                        </div>

                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{item.level}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>

                            <td>
                                <div *ngIf="!isSubmitted" class="flex align-items-center justify-content-center gap-2">
                                    <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(item)" class="p-button-rounded p-button-text"></button>
                                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(item)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
                                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(item, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</ng-template>

