import { ProjectModel, ProjectService } from '../../itcp';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from '../../shared/services/toaster.service';
import {CryptoService, ProjectFlow, SettingsService} from '../../shared';
import {
    IProjectGoalActivity,
    IProjectGoalActivityMonth,
    IProjectGoalResult
} from '../../edtp';
import {Constants, PROJECT_READ_ONLY} from '../../shared/constants';
import {IProjectActivityBudget, IProjectActivityBudgetMonth} from "./interfaces";
import * as dayjs from "dayjs";
import {GenericFunctions} from "../../shared/services/generic-functions";
type OptionYearType = '1SEM' | '2SEM' | 'YEARLY';

export abstract class ProjectProgrammerModel extends ProjectModel {

    protected settingService!: SettingsService;
    protected totalProjectDays: number = 0;

    optionsByYear!: {label: string; value: OptionYearType }[];
    optionsByMonth!: {label: string; value: number; disabled?: boolean }[];
    selectedOptionYear!: OptionYearType;
    selectedOptionMonth!: number;

    protected constructor(route: ActivatedRoute, service: ProjectService, tService: ToasterService, setService: SettingsService, crypto: CryptoService) {
        super(route, service, tService, crypto);
        this.settingService = setService;
    }

    checkEditableForm() {
        const editable: boolean = !this.isReadOnly(PROJECT_READ_ONLY.PROGRAMMER);
        if (editable) {
            this.setAsEditable();
        } else {
            this.setAsReadOnly();
        }
    }

    override setSteps() {

        this.steps = [
            {
                step: 1,
                text: 'Actividades',
                icon: 'pi pi-calendar',
                path: `/tracking/programmer/${this.params['id']}/schedule`,
            },
            {
                step: 2,
                text: 'Cronograma de Ejecución',
                icon: 'pi pi-calendar-plus',
                path: `/tracking/programmer/${this.params['id']}/activities`,
            },
            {
                step: 3,
                text: 'Programación Física General',
                icon: 'pi pi-sliders-v',
                path: `/tracking/programmer/${this.params['id']}/physical`,
            },
            {
                step: 4,
                text: 'Programación Física Mensual',
                icon: 'pi pi-sliders-h',
                path: `/tracking/programmer/${this.params['id']}/physical-details`,
            },
            {
                step: 5,
                text: 'Programación Financiera General',
                icon: 'pi pi-dollar',
                path: `/tracking/programmer/${this.params['id']}/financial`,
            },
            {
                step: 6,
                text: 'Programación Financiera Mensual',
                icon: 'pi pi-dollar',
                path: `/tracking/programmer/${this.params['id']}/financial-details`,
            },
        ]
    }

    calculateDaysActivities(goalResults: IProjectGoalResult[]) {
        this.totalProjectDays = 0;
        for(const res of goalResults) {
            res.days = 0;
            for(const act of res.activities) {
                act.days = act.weeksDuration * 7; //We assume a week have 7 days.
                res.days += act.days;
            }
            this.totalProjectDays += res.days;
        }
    }

    getAllActivitiesStartDate(goalResults: IProjectGoalResult[]){
        let activities: any = [];
        for (const res of goalResults) {
            activities = [
                ...activities,
                ...res.activities,
            ]
        }
        const leastDate = new Date(Math.min.apply(null, activities.map( (a: any) => {
            return new Date(a.startsDate);
        })));

        return leastDate;
    }

    getRangeFor(year: number, month: number) {
        if (!this.currentProject?.startOrder?.startOrderDate) return null;

        const selectedYearValue = this.selectableProjectYears.find(py => py.id === this.selectedProjectYear)?.yearValue;
        const startDate: any = dayjs(`${selectedYearValue}-${month}-1`, 'YYYY-M-D');

        const endDate = startDate.clone().endOf('month').endOf('day');
        return {
            startDate: startDate.toDate(),
            endDate: endDate.toDate(),
        };
    }

    getYearExpected(act: IProjectGoalActivity) {
        if (!act) return 0;
        let yearExpected = 0;
        const current = act.yearsProgrammed?.find(ey => ey.year === this.selectedProjectYear);
        if (current) yearExpected = current.expected
        return yearExpected;
    }

    initYearOptions() {
        this.optionsByYear = [
            { label: '1er Semestre', value: '1SEM'},
            { label: '2do Semestre', value: '2SEM'},
            { label: 'Anual', value: 'YEARLY'},
        ];

        this.selectedOptionYear = '1SEM';
    }

    generateMonthlyOptions() {
        this.optionsByMonth = [];

        let months = Constants.MONTHS_UNDEFINED;
        let startMonth = 0;
        if (this.currentProject?.startOrderDate) {
            months = Constants.MONTHS;
            startMonth = this.currentProject.startOrderDate.getMonth();
        }
        switch (this.selectedOptionYear) {
            case "1SEM":
                for (let i = 0; i < 6; i++) {
                    this.optionsByMonth.push({ label: months[i], value: i, disabled: this.selectedProjectYear === 1 && i < startMonth });
                }
                break;
            case "2SEM":
                for (let i = 6; i < 12; i++) {
                    this.optionsByMonth.push({ label: months[i], value: i, disabled: this.selectedProjectYear === 1 && i < startMonth});
                }
                break;
            case "YEARLY":
                for (let i = 0; i < 12; i++) {
                    this.optionsByMonth.push({ label: months[i], value: i, disabled: this.selectedProjectYear === 1 && i < startMonth});
                }
                break;
        }

        this.selectedOptionMonth = this.selectedProjectYear === 1 ? startMonth : 0;
    }

    setCurrentMonthByActivity(results: IProjectGoalResult[]) {
        for (const result of results) {
            for(const activity of result.activities) {
                activity.currentMonth = this.setActivityMonth(activity);
            }
        }
    }

    setActivityMonth(activity: IProjectGoalActivity) {
        if (!activity.id) return;

        //Programmer for activity year
        const yearProgrammed = this.getYearExpected(activity);

        const monthDetailsForCurrentYear = activity.monthDetails?.filter(md => md.year === this.selectedProjectYear)  || [];
        //Total programmed for this activity
        const programmed = monthDetailsForCurrentYear.reduce((total, md) => total + md.expected, 0) || 0;
        const available = yearProgrammed - programmed;
        //Total executed for this activity
        const executed = monthDetailsForCurrentYear.reduce((total, md) => total + md.executed, 0) || 0;

        let activityMonth: IProjectGoalActivityMonth = {
            id: `new_${new Date().getTime()}`,
            activityId: parseInt(activity.id.toString()),
            expected: 0,
            executed: 0,
            year: this.selectedProjectYear,
            month: this.selectedOptionMonth,
            yearExpected: yearProgrammed,
            totalProgrammed: programmed, //total programmed for months
            availableExpected: available,
            totalExecuted: executed, //total executed for months
        }

        let found;
        if (this.currentProject?.startOrder?.startOrderDate){
            const range = this.getRangeFor(this.selectedProjectYear, this.selectedOptionMonth + 1);
            if (range) {
                found = activity.monthDetails?.find(md => {
                    const sDate = GenericFunctions.parseDate(md.startsDate);
                    if (sDate && sDate >= range.startDate && sDate < range.endDate)
                        return (md);
                    return null;
                });
            }
        } else {
            found = monthDetailsForCurrentYear.find(md => md.month === this.selectedOptionMonth);
        }

        if (found) {
            activityMonth = {
                ...found,
                yearExpected: yearProgrammed,
                totalProgrammed: programmed - found.expected, //total programmed for months (subtract expected for current)
                totalExecuted: executed - found.executed, //total executed for months (subtract executed for current)
                availableExpected: (yearProgrammed - (programmed - found.expected)), //(subtract expected for current)
                files: found.verifiables?.map(f => f.file),
                verifiableIds: found.verifiables?.map(f => f.fileId),
            }
        }

        return activityMonth;
    }

    getYearAmounts(budget: IProjectActivityBudget): {ownerYear: number, entityYear: number, entityInKindYear: number} {
        if (!budget) return { ownerYear: 0, entityYear: 0, entityInKindYear: 0 };
        let ownerYear, entityYear, entityInKindYear;
        const current = budget.yearsProgrammed?.find(ey => ey.year === this.selectedProjectYear);
        ownerYear = current?.ownerAmount || 0;
        entityYear = current?.entityAmount || 0;
        entityInKindYear = current?.entityInKindAmount || 0;

        return {ownerYear, entityYear, entityInKindYear};
    }

    setCurrentMonthByActivityBudget(results: IProjectGoalResult[]) {
        for (const result of results) {
            for (const activity of result.activities) {
                const budgetIds = [];
                if (!activity.budgets) continue;
                for(const budget of activity.budgets) {
                    budget.currentMonth = this.setActivityBudgetMonth(budget);
                    if (budget.currentMonth) budgetIds.push(budget.currentMonth.activityBudgetId)
                }
                try {
                    activity.currentMonthBudget = activity.budgets[0]?.currentMonth;
                    if (activity.currentMonthBudget) activity.currentMonthBudget.activityBudgetIds = budgetIds;
                } catch (e) {
                    console.error(e);
                }
            }
        }
    }

    setActivityBudgetMonth(budget: IProjectActivityBudget) {
        if (!budget.id) return;

        //Programmer for activity budget year
        const {ownerYear, entityYear, entityInKindYear} = this.getYearAmounts(budget);
        const programmedForCurrentYear = budget.monthProgrammed?.filter(md => md.year === this.selectedProjectYear) || [];


        //Total programmed for this budget
        const programmedEntity = programmedForCurrentYear.reduce((total, md) => total + md.entityAmount, 0) || 0;
        const programmedEntityInKind = programmedForCurrentYear.reduce((total, md) => total + md.entityInKindAmount, 0) || 0;
        const programmedOwner = programmedForCurrentYear.reduce((total, md) => total + md.ownerAmount, 0) || 0;
        const availableOwner = ownerYear - programmedOwner;
        const availableEntity = entityYear - programmedEntity;
        const availableEntityInKind = entityInKindYear - programmedEntityInKind;
        //Total executed for this activity
        const ownerExecutedAmount = programmedForCurrentYear.reduce((total, md) => total + (md.ownerExecutedAmount || 0), 0) || 0;
        const entityExecutedAmount = programmedForCurrentYear.reduce((total, md) => total + (md.entityExecutedAmount || 0), 0) || 0;
        const entityInKindExecutedAmount = programmedForCurrentYear.reduce((total, md) => total + (md.entityInKindExecutedAmount || 0), 0) || 0;

        let budgetMonth: IProjectActivityBudgetMonth = {
            id: `new_${new Date().getTime()}`,
            activityBudgetId: parseInt(budget.id.toString()),
            year: this.selectedProjectYear,
            month: this.selectedOptionMonth,
            owner: 0,
            ownerAmount: 0,
            entity: 0,
            entityAmount: 0,
            entityInKind: 0,
            entityInKindAmount: 0,
            entityExecuted: 0,
            entityInKindExecuted: 0,
            ownerExecuted: 0,
            yearOwner: ownerYear,
            yearEntity: entityYear,
            yearEntityInKind: entityInKindYear,
            totalProgrammedOwner: programmedOwner,
            totalProgrammedEntity: programmedEntity,
            totalProgrammedEntityInKind: programmedEntityInKind,
            availableOwner,
            availableEntity,
            availableEntityInKind,
            ownerExecutedAmount,
            entityExecutedAmount,
            entityInKindExecutedAmount
        }

        let found;
        if (this.currentProject?.startOrder?.startOrderDate){
            const range = this.getRangeFor(this.selectedProjectYear, this.selectedOptionMonth + 1);
            if (range) {
                found = budget.monthProgrammed?.find(md => {
                    const sDate = GenericFunctions.parseDate(md.startsDate);
                    if (sDate && sDate >= range.startDate && sDate < range.endDate)
                        return (md);
                    return null;
                });
            }
        } else {
            found = programmedForCurrentYear.find(md => (md.month === this.selectedOptionMonth));
        }

        if (found) {
            budgetMonth = {
                ...found,
                yearOwner: ownerYear,
                yearEntity: entityYear,
                yearEntityInKind: entityInKindYear,
                totalProgrammedOwner: programmedOwner - found.ownerAmount, //total programmed for months (subtract expected for current)
                totalProgrammedEntity: programmedEntity - found.entityAmount, //total programmed for months (subtract expected for current)
                totalProgrammedEntityInKind: programmedEntityInKind - found.entityInKindAmount, //total programmed for months (subtract expected for current)
                totalExecutedOwner: ownerExecutedAmount - (found.ownerExecutedAmount || 0), //total executed for months (subtract executed for current)
                totalExecutedEntity: entityExecutedAmount - (found.entityExecutedAmount || 0), //total executed for months (subtract executed for current)
                totalExecutedEntityInKind: entityInKindExecutedAmount - (found.entityInKindExecutedAmount || 0), //total executed for months (subtract executed for current)
                availableOwner: (ownerYear - (programmedOwner - found.ownerAmount)), //(subtract expected for current)
                availableEntity: (entityYear - (programmedEntity - found.entityAmount)), //(subtract expected for current)
                availableEntityInKind: (entityInKindYear - (programmedEntityInKind - found.entityInKindAmount)), //(subtract expected for current)
                files: found.verifiables?.map(f => f.file),
                verifiableIds: found.verifiables?.map(f => f.fileId),
            }
        }

        return budgetMonth;
    }
}
