import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-draw';
import drawLocales from 'leaflet-draw-locales';
import { Subject, takeUntil } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

import { ICity, IProvince, ITown, INeighborhood, IStreet } from 'src/app/shared';
import { ProjectFlow } from './../../../shared/services/project.flow';
import { CartographyService } from './../../../shared/services/cartography.service';
import { IProject, ProjectService } from 'src/app/itcp';
import { Constants } from 'src/app/shared/constants';
import { Table } from 'jspdf-autotable';

drawLocales('es');

export type GeometricType = {
    type: string;
    coordinates: any[];
};

export type CoordinatesTable = 'CITY' | 'PROVINCE' | 'TOWN' | 'NEIGHBORHOOD' | 'STREET';

export const GreenIcon: any = L.icon({
    iconUrl: 'https://leafletjs.com/examples/custom-icons/leaf-green.png',
    shadowUrl: 'https://leafletjs.com/examples/custom-icons/leaf-shadow.png',

    iconSize: [38, 95], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62], // the same for the shadow
    popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
});

interface CustomLayer extends L.Layer {
    geoId?: number;
}

@Component({
    selector: 'app-geovisor',
    templateUrl: './geovisor.component.html',
    styleUrls: ['./geovisor.component.scss'],
})
export class GeovisorComponent implements OnInit {
    private onDestroy$ = new Subject();
    private map: any;
    private drawFullControl: any;
    private drawEditControl: any;
    private drawItems: any;
    features: L.FeatureGroup = new L.FeatureGroup<any>();
    geomIds: number[] = [];
    enablePolygon!: boolean;
    multi!: boolean;
    public showContent: boolean = false;
    public cities: ICity[] = [];
    public provinces: IProvince[] = [];
    public towns: ITown[] = [];
    public neighborhoods: INeighborhood[] = [];
    public streets: IStreet[] = [];
    reportTowns: any[] = [];
    loading = false;
    approvedRejectedChartData: any = {};
    beneficiariesChartData: any = {};
    financialChartData: any = {};
    citiesDropdownSettings: IDropdownSettings = {};
    townsDropdownSettings: IDropdownSettings = {};
    stateDropdownSettings: IDropdownSettings = {};
    coordinatesUTM = { x: undefined, y: undefined, zone: undefined, hemisphere: 'S' };
    geovisorForm!: FormGroup;
    geoIds: number[] = [];
    _approvedRejectedProjects: IProject[] = [];
    showData = false;
    flattedDataPerTown: any[] = [];
    filteredFlattedData: any[] = [];
    totalOwnerCost = 0;
    totalEntityCost = 0;
    totalOwnerEntityCost = 0;
    totalApproved = 0;
    totalRejected = 0;
    totalMenBeneficiaries = 0;
    totalWomenBeneficiaries = 0;
    totalBeneficiaries = 0;
    stackedOptions: any = {};

    @ViewChild('dtApprovedRejectedTable') approvedRejectedTable!: Table;
    @ViewChild('dtSpecific') specificTable!: Table;
    @ViewChild('dtTableAR') tableAR!: Table;
    @ViewChild('chartPDFRef') chartPDFRef!: ElementRef;
    @ViewChild('budgetBarChartPDF') budgetBarChartPDF!: ElementRef;
    @ViewChild('benefitedBarChartPDF') benefitedBarChartPDF!: ElementRef;

    constructor(
        private fb: FormBuilder,
        private readonly cartographyService: CartographyService,
        private readonly projectsService: ProjectService
    ) {}

    set approvedRejectedProjects(data: IProject[]) {
        const newGeoIds = [];
        for (const project of data) {
            const {
                iTCPRequest: {
                    entity: { town },
                },
            } = project as any;
            if (town) newGeoIds.push(town.gid);
        }

        this.geoIds = [...newGeoIds];
        this._approvedRejectedProjects = data;
        this.buildGeos(true);

        this.showData = false;
        this.flattedDataPerTown = [];
        this.filteredFlattedData = [];
        this.totalOwnerCost = 0;
        this.totalEntityCost = 0;
        this.totalOwnerEntityCost = 0;
        this.totalApproved = 0;
        this.totalRejected = 0;
        this.totalMenBeneficiaries = 0;
        this.totalWomenBeneficiaries = 0;
        this.totalBeneficiaries = 0;
        this._approvedRejectedProjects = data.map((project) => {
            return this.setProjectProperties(project) as any;
        });

        this.filteredFlattedData = this.flattedDataPerTown;
        this.sortDataPerTown();
        this.setApprovedRejectedProjectsChartData();
        this.showData = true;
    }

    setApprovedRejectedProjectsChartData() {
        this.approvedRejectedChartData = {
            labels: ['Aprobado', 'Deshabilitado'],
            datasets: [
                {
                    label: 'Reporte ITCP Aprobados - Deshabilitados',
                    data: [this.totalApproved, this.totalRejected],
                    backgroundColor: ['rgb(14,72,25)', 'rgb(60,140,64)'],
                    hoverOffset: 4,
                },
            ],
        };
        const ownerAmounts: any[] = [];
        const entityAmounts: any[] = [];
        const menBeneficiaries: any[] = [];
        const womenBeneficiaries: any[] = [];
        const labels: any[] = [];

        this.reportTowns.forEach(({ name: town }) => {
            const relevantData = this.filteredFlattedData.filter((d) => d.townName === town);
            const filteredTownNames = this.filteredFlattedData.map((f) => f.townName);
            let ownerAmount = 0;
            let entityAmount = 0;
            let menBeneficiary = 0;
            let womenBeneficiary = 0;
            relevantData.forEach((data) => {
                ownerAmount = ownerAmount + (data.ownerAmount || 0);
                entityAmount = entityAmount + (data.entityAmount || 0);
                menBeneficiary += data.menBeneficiary;
                womenBeneficiary += data.womenBeneficiary;
            });
            if (filteredTownNames.includes(town)) {
                labels.push(town);
                ownerAmounts.push(ownerAmount);
                entityAmounts.push(entityAmount);
                menBeneficiaries.push(menBeneficiary);
                womenBeneficiaries.push(womenBeneficiary);
            }
        });

        this.financialChartData = {
            labels: labels,
            datasets: [
                {
                    type: 'bar',
                    label: 'Presupuesto FONABOSQUE',
                    backgroundColor: '#42A5F5',
                    data: ownerAmounts,
                },
                {
                    type: 'bar',
                    label: 'Presupuesto ENTIDAD',
                    backgroundColor: '#09144f',
                    data: entityAmounts,
                },
            ],
        };

        this.beneficiariesChartData = {
            labels: labels,
            datasets: [
                {
                    type: 'bar',
                    label: 'Beneficiarios Varones',
                    backgroundColor: '#42A5F5',
                    data: menBeneficiaries,
                },
                {
                    type: 'bar',
                    label: 'Beneficiarios Mujeres',
                    backgroundColor: '#fa2f2f',
                    data: womenBeneficiaries,
                },
            ],
        };
    }

    private setProjectProperties(project: IProject) {
        const {
            iTCPRequest: {
                entity: { city, town, province },
            },
            budgets = [],
            beneficiaries = [],
            status,
        } = project as any;

        let budget: any = {};

        budget = budgets.length ? budgets[0] : ({} as any);
        if (this.isProjectApproved(project)) this.totalApproved++;
        else this.totalRejected++;

        const parsedTownData = {
            cityName: city?.dep,
            provinceName: province?.prov,
            townName: town?.mun,
            ownerAmount: budget?.ownerAmount,
            entityAmount: budget.entityAmount,
            totalAmount: (budget.ownerAmount || 0) + (budget.entityAmount || 0),
        };
        this.totalOwnerCost = this.totalOwnerCost + (budget?.ownerAmount || 0);
        this.totalEntityCost = this.totalEntityCost + (budget?.entityAmount || 0);
        this.totalOwnerEntityCost =
            this.totalOwnerEntityCost + (budget?.ownerAmount || 0) + (budget?.entityAmount || 0);

        this.flattedDataPerTown.push(parsedTownData);

        return { ...project, budget };
    }

    isProjectApproved(project: IProject) {
        return [...ProjectFlow.edtpStatusesForAssigned(), Constants.PROJECT_STATUSES.APPROVED].includes(
            project.status as any
        );
    }

    getProjectStatus(project: IProject) {
        return this.isProjectApproved(project) ? 'Aprobado' : 'Rechazado';
    }

    sortDataPerTown() {
        this.flattedDataPerTown.sort((a: any, b: any) => a.cityName.localeCompare(b.cityName));
        const townNames = this.flattedDataPerTown.map((town: any) => town.townName);
        townNames.sort((a: any, b: any) => a.localeCompare(b));
        this.reportTowns = [...new Set(townNames)].map((t) => ({ name: t }));
    }

    get approvedRejectedProjects() {
        return this._approvedRejectedProjects;
    }

    ngOnInit() {
        this.multi = false;
        this.enablePolygon = false;

        this.geovisorForm = this.fb.group({
            announcementIds: [[]],
            cityIds: [[]],
            provinceIds: [[]],
            townIds: [[]],
            statuses: [[]],
        });

        this.cartographyService
            .getCities()
            .then((resp) => {
                this.cities = [...resp];
                return this.cartographyService.getProvinces();
            })
            .then((resp) => {
                this.provinces = [...resp];
                return this.cartographyService.getTowns();
            })
            .then((resp) => {
                this.towns = [...resp];
                return this.cartographyService.getNeighborhoods();
            })
            .then((resp) => {
                this.neighborhoods = [...resp];
                return this.cartographyService.getStreets();
            })
            .then((resp) => {
                this.streets = [...resp];
            })
            .finally(() => {
                this.showContent = true;
            });

        this.stackedOptions = {
            tooltips: {
                mode: 'index',
                intersect: false,
            },
            responsive: true,
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true,
                },
            },
        };
    }

    get geovisorFormControls() {
        return this.geovisorForm.controls;
    }

    private initMap(): void {
        this.map = L.map('map-geovisor', {
            center: [-17.9137065, -63.5493965],
            zoomControl: false,
            zoom: 6,
        });

        const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        });
        tiles.addTo(this.map);

        this.buildGeos();

        L.control.zoom({ position: 'topright' }).addTo(this.map);

        this.addEvents();
    }

    getCitiesDropdownSettings() {
        return this.getDropdownSettings('gid', 'dep');
    }

    getProvincesDropdownSettings() {
        return this.getDropdownSettings('gid', 'prov');
    }

    getTownsDropdownSettings() {
        return this.getDropdownSettings('gid', 'mun');
    }

    getStateDropdownSettings() {
        return this.getDropdownSettings('name', 'name');
    }

    getDropdownSettings(idField: string, textField: string) {
        return {
            singleSelection: false,
            idField,
            textField,
            selectAllText: 'Todos',
            unSelectAllText: 'Ninguno',
            itemsShowLimit: 4,
            allowSearchFilter: true,
        };
    }

    addEvents() {
        const me = this;
        this.map.on(L.Draw.Event.CREATED, function (e: any) {
            const type = e.layerType,
                layer = e.layer;
            me.drawItems.addLayer(layer);
            me.features.addLayer(layer);

            if (!me.multi) {
                me.drawFullControl.remove();
                me.map.addControl(me.drawEditControl);
            }
        });

        me.map.on(L.Draw.Event.EDITED, function (e: any) {
            const layers = e.layers;
            layers.eachLayer(function (layer: any) {
                me.cartographyService.updateGeometry(layer.toGeoJSON(), layer.geoId).then();
            });
        });

        me.map.on(L.Draw.Event.DELETED, function (e: any) {
            const layers = e.layers;
            layers.eachLayer(function (layer: any) {
                me.geomIds = me.geomIds.filter((g) => g !== layer.geoId);
            });

            if (!me.multi) {
                me.drawEditControl.remove();
                me.map.addControl(me.drawFullControl);
            }
        });
    }

    buildLayerFromGeoJson(geom: any, store: boolean = false) {
        if (!geom) return;

        const layer = L.geoJSON(geom, { style: this.getITCPLayerStyle() });
        // const limits = layer.getBounds();
        // const center = limits.getCenter();
        // const zoom = this.map.getBoundsZoom(limits);
        // this.map.flyTo(center, zoom);
        layer.addTo(this.map);
        this.features.addLayer(layer);
    }

    resetMapLayers() {
        const map = this.map;
        map.eachLayer(function (layer: any) {
            map.removeLayer(layer);
        });

        const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        });
        tiles.addTo(this.map);
    }

    getITCPLayerStyle() {
        return {
            color: `#0e4819`,
            weight: 2,
            opacity: 0.7,
        };
    }

    getRandomStyle() {
        return {
            color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
            weight: 2,
            opacity: 0.7,
        };
    }

    buildGeos(needsReset = false) {
        const me = this;

        if (needsReset) this.resetMapLayers();

        if (!this.geoIds?.length) return;

        let center: any = [-16.2837065, -63.5493965];
        let zoom = 8;
        this.cartographyService.findCoordinatesByGids({ params: { gIds: [...this.geoIds] } }).then((result) => {
            result?.map((r: any) => {
                const geoId = r.id;
                this.geomIds.push(r.id);
                const geo = JSON.parse(r.geom);
                if (geo.type === 'Point') {
                    const layers = L.geoJSON(geo, {
                        pointToLayer: function (feature, latlng) {
                            return L.marker(latlng, {
                                icon: GreenIcon,
                            });
                        },
                    });
                    layers.eachLayer(function (l) {
                        const layer = l as CustomLayer;
                        layer.geoId = geoId;
                        me.drawItems.addLayer(layer);
                    });
                    layers.addTo(this.map);
                    center = geo.coordinates.reverse();
                } else if (geo.type === 'Polygon') {
                    const layers = L.geoJSON(geo);
                    const limits = layers.getBounds();
                    center = limits.getCenter();
                    zoom = me.map.getBoundsZoom(limits);
                    layers.eachLayer(function (l) {
                        const layer = l as CustomLayer;
                        layer.geoId = geoId;
                        me.drawItems.addLayer(layer);
                    });

                    layers.addTo(this.map);
                } else if (geo.type === 'MultiPolygon') this.buildLayerFromGeoJson(geo);
            });
            this.map.flyTo(center, zoom);
        });
    }

    onFilter() {
        this.loading = true;
        const postData: any = this.geovisorForm.value;
        let { cityIds = [], provinceIds = [], townIds = [] } = postData;
        cityIds = cityIds.map((a: any) => parseInt(a.gid));
        provinceIds = provinceIds.map((a: any) => parseInt(a.gid));
        townIds = townIds.map((a: any) => parseInt(a.gid));
        this.projectsService
            .getAll({
                params: {
                    cityIds,
                    provinceIds,
                    townIds,
                    status: [...ProjectFlow.itcpStatusesForRanking()],
                },
            })
            .then((resp) => (this.approvedRejectedProjects = resp));
    }

    ngAfterViewInit(): void {
        this.initMap();
    }

    filterCities(event: any) {
        this.cartographyService.getCities({ params: { query: event.query } }).then((resp) => {
            this.cities = [...resp];
        });
    }

    filterProvinces(event: any) {
        this.cartographyService.getProvinces({ params: { query: event.query } }).then((resp) => {
            this.provinces = [...resp];
        });
    }

    filterTowns(event: any) {
        this.cartographyService.getTowns({ params: { query: event.query } }).then((resp) => {
            this.towns = [...resp];
        });
    }
}
