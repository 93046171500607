<app-wizard [steps]='steps' [stepsSize]="5" [current]='currentStep' [bodyContentTpl]='projectForm'
    [nextDisabled]='this.formGroup?.invalid' (onClickNext)='save($event)' [layout]='true'>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column' [formGroup]='formGroup'>
        <div class='grid'>
            <div class="field col-12 text-2xl text-primary uppercase">
                {{currentStep?.text}}
            </div>

            <div class="field col-12">
                <p-selectButton [options]="selectableProjectYears" optionLabel="label" optionValue="id"
                    [(ngModel)]="selectedProjectYear" [ngModelOptions]="{standalone: true}"
                    (onChange)="onChangeSelectedProjectYear($event)">
                </p-selectButton>
                <p-selectButton [options]="optionsByMonth" [(ngModel)]="selectedOptionMonth"
                    [ngModelOptions]="{standalone: true}" (onChange)="loadActivities()">
                </p-selectButton>
            </div>

            <div class="field col-12">
                <p-table [value]="results" dataKey="id" editMode="row" [tableStyle]="{'min-width': '100%'}"
                    styleClass="p-datatable-gridlines" #dt>
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="bg-primary border-x-1 text-center" rowspan="2" style="width:60px">NRO.</th>
                            <th class="bg-primary border-x-1 text-center" rowspan="2" style="width:300px">ACTIVIDAD</th>
                            <th class="bg-primary border-x-1 text-center" rowspan="2">TIPO</th>
                            <th class="bg-primary border-x-1 text-center" colspan="4">PROGRAMADO</th>
                            <th class="bg-primary" rowspan="2" style="width:100px"></th>
                        </tr>
                        <tr>
                            <th class="bg-primary border-x-1 text-center">S11</th>
                            <th class="bg-primary border-x-1 text-center">S2</th>
                            <th class="bg-primary border-x-1 text-center">S3</th>
                            <th class="bg-primary border-x-1 text-center">S4</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-obj let-editing="editing" let-index="rowIndex">
                        <tr [pEditableRow]="obj">
                            <td colspan='100%' class='p-0 m-0'>
                                <app-activity-weekly [group]='obj' [canProgram]="!programmerReadOnly()"
                                    [selectedYear]="selectedProjectYear"></app-activity-weekly>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="100%">
                                <div class="w-full text-center font-bold">No se ha encontrado resultados</div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</ng-template>
