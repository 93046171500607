import { Component, OnInit, ViewChild } from '@angular/core';
import { Constants } from '../../../shared/constants';
import { IProject, IProjectAssignment, ProjectService } from '../../../itcp';
import { User } from '../../../core/user/interfaces';
import { ProjectAssignment } from '../../../edtp';
import { MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { UserService } from '../../../core/user/user.service';
import { ProjectAssignmentsService } from '../../../shared/services/project-assignments.service';
import { GenericFunctions } from '../../../shared/services/generic-functions';
import {ProcessTypes, ProjectFlow, ProjectStagesEnum} from '../../../shared';

@Component({
  selector: 'app-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.scss']
})
export class AssignmentComponent implements OnInit {
    resource = Constants.RESOURCES.TRACKING_ASSIGNMENTS;
    projects: IProject[] = []; //Project to assign
    financialTechs: User[] = []; //Users(TEC-FIN-SEG)
    physicalTechs: User[] = []; //Users(TEC-FIS-SEG)

    assignPhysicalTechs: User[] = [];
    assignFinancialTechs: User[] = [];

    assignedProjects: ProjectAssignment[] = [];
    assignedAndSelectedProjects: ProjectAssignment[] = [];
    storedAssignedProjects: IProject[] = [];

    loading: boolean = false;
    techniciansPermissions = [
        { label: 'Revisar', value: Constants.TECHNICIAN_PERMISSIONS.REVIEW },
        { label: 'Valorar', value: Constants.TECHNICIAN_PERMISSIONS.EVAL },
        { label: 'Programar', value: Constants.TECHNICIAN_PERMISSIONS.PROGRAMME },
        { label: 'Revisar y Valorar', value: Constants.TECHNICIAN_PERMISSIONS.REVIEW_EVAL },
    ];

    breadcrumbs: MenuItem[] = [
        { label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home'] },
        { label: ' Seguimiento', routerLink: ['/tracking'] },
        { label: ' Asignación de Técnicos' },
    ];
    @ViewChild('dt') table!: Table;

    constructor(
        private readonly projectService: ProjectService,
        private readonly userService: UserService,
        private readonly assignmentService: ProjectAssignmentsService
    ) {}

    getFullName(user: User): string {
        return GenericFunctions.getUserFullName(user);
    }

    ngOnInit(): void {
        this.loadAll();
    }

    loadAll() {
        this.loading = true;
        this.projectService
            .getAll({ params: {
                    status: ProjectFlow.trackingStatusesForAssignments(),
                    stage: ProjectStagesEnum.EDTP,
                }
            })
            .then((resp) => {
                this.projects = [...resp];
                return this.projectService.getAll({
                    params: { assignments: true,
                        status: ProjectFlow.trackingStatusesForAssigned(),
                        stage: ProjectStagesEnum.TRACKING,
                    },
                });
            })
            .then((resp) => {
                this.storedAssignedProjects = [...resp];
                return this.userService.getUsers(ProcessTypes.APROVE, [
                    Constants.ROLES.TEC_FIS_SEG,
                    Constants.ROLES.TEC_FIN_SEG,
                ]);
            })
            .then((resp) => {
                this.physicalTechs = resp?.filter((u) => u.usuarioRoles[0]?.rol?.nombreRol === Constants.ROLES.TEC_FIS_SEG);
                this.financialTechs = resp?.filter((u) => u.usuarioRoles[0]?.rol?.nombreRol === Constants.ROLES.TEC_FIN_SEG);
            })
            .finally(() => {
                this.loading = false;
                this.loadAssignments();
            });
    }

    onChange(event: any, assignedProject: ProjectAssignment, type: 'financial' | 'physical') {
        if (type === 'physical') {
            assignedProject.physicalTech = event.value;
        } else if (type === 'financial') {
            assignedProject.financialTech = event.value;
        }
    }

    loadAssignments() {
        this.assignedProjects = [];
        this.assignPhysicalTechs = [...this.physicalTechs];
        this.assignFinancialTechs = [...this.financialTechs];

        let index = 0;
        let financialIndex = 0;
        let physicalIndex = 0;
        for (let project of this.projects) {
            const financialTech = this.assignFinancialTechs[financialIndex];
            const physicalTech = this.assignPhysicalTechs[physicalIndex];

            if (project && financialTech && physicalTech) {
                const assigned: ProjectAssignment = {
                    id: index + 1,
                    project,
                    financialTech,
                    physicalTech,
                };

                this.assignedProjects.push(assigned);
            }
            index++;
            physicalIndex < this.assignPhysicalTechs.length ? physicalIndex++ : (physicalIndex = 0);
            financialIndex < this.assignFinancialTechs.length ? financialIndex++ : (financialIndex = 0);
        }
    }

    setFilter(event: any, type: string, query: string, column: string = ''): void {
        if (type === 'filterGlobal') {
            this.table.filterGlobal(event.target.value, query);
        } else if (type === 'filter') {
            this.table.filter(event.target.value, column, query);
        }
    }

    save() {
        this.assignmentService.store(this.assignedAndSelectedProjects, ProjectStagesEnum.TRACKING).then((resp) => {
            if (resp.length) this.loadAll();
        });
    }

    changePermissions(event: any, assignment: IProjectAssignment) {
        this.assignmentService.update(assignment).then();
    }

    onSelectionChange(value = []) {
        this.assignedAndSelectedProjects = [...value];
    }

    isAssignedTracking(status: string) {
        return status === Constants.PROJECT_STATUSES.ASSIGNED_TRACKING;
    }
}
