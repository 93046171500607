<div class="grid">
    <p-breadcrumb [model]="breadcrumbs"></p-breadcrumb>
    <div class="col-12">
        <div class="card">

            <div class="text-base mb-4 ">
                <b class="mr-2">NOMBRE DEL PROYECTO</b> <app-project-status [status]="currentProject?.status"></app-project-status>:
                <br/> {{currentProject?.name}}
            </div>

            <div class="p-4 mb-4 bg-gray-100 text-center arrow-container text-lg">
                <div class="arrow"></div>
                Ciclo de Evaluación Ex Post del Proyecto - <label class="text-red-500 font-bold">Momentos de Evaluación</label>
            </div>

            <div class="bg-green-50 p-4 flex gap-2 mb-4 justify-content-around align-items-center">
                <div class="p-4 font-bold text-center text-lg short">
                    <div class="arrow"></div>
                    <div class="less-arrow-left"></div>
                    Corto Plazo<br/>1 a 5 años
                </div>

                <div class="p-4 font-bold  text-center text-lg medium">
                    <div class="arrow"></div>
                    <div class="less-arrow-left"></div>
                    Mediano Plazo<br/>6 a 10 años
                </div>

                <div class="p-4 font-bold text-center text-lg large">
                    <div class="arrow"></div>
                    <div class="less-arrow-left"></div>
                    Largo Plazo<br/>más de 10 años
                </div>

            </div>

            <div class="p-4 mb-4 bg-gray-100 text-center arrow-container text-lg">
                <div class="arrow"></div>
                Ciclo de Vida del Proyecto - <label class="text-red-500 font-bold">Fase de Operación</label>
            </div>
            <p-table [value]="components" dataKey="id" editMode="row" [tableStyle]="{'min-width': '100%'}" #dt>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="bg-primary border-x-1">CICLO DE EVALUACIÓN EXPOS</th>
                        <th class="bg-primary border-x-1" >PORCENTAJE DE PRENDIMIENTO (%)</th>
                        <th class="bg-primary border-x-1" >PORCENTAJE DE MORTANDAD (%)</th>
                        <th class="bg-primary border-x-1" >NÚMERO DE REFALLOS EFECTUADOS</th>
                        <th class="bg-primary border-x-1" >DOCUMENTO DE EVALUACIÓN EXPOS</th>
                        <th class="bg-primary border-x-1" style="width:60px"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-obj let-editing="editing" let-ri="rowIndex">
                    <tr [pEditableRow]="obj">
                        <td class="bg-primary text-white">{{obj.title}}</td>
                        <td class='text-center'>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText
                                           required
                                           min="0"
                                           class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary  w-8rem'
                                           type="number" [(ngModel)]="obj.seizure" [ngModelOptions]="{standalone: true}">
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{obj.seizure | number: '1.0-2'}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td class='text-center'>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText
                                           required
                                           min="0"
                                           class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-8rem'
                                           type="number" [(ngModel)]="obj.mortality" [ngModelOptions]="{standalone: true}">
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{obj.mortality | number: '1.0-2'}}
                                </ng-template>
                            </p-cellEditor>
                        </td>

                        <td class='text-center'>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText
                                           required
                                           min="0"
                                           class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary  w-8rem'
                                           type="number" [(ngModel)]="obj.refaults" [ngModelOptions]="{standalone: true}">
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{obj.refaults}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td class='text-center'>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-overlayPanel #uploadFiles>
                                        <ng-template pTemplate="content">
                                            <div class="p-2">
                                                <app-input-file
                                                    [multiple]="true"
                                                    [maxFiles]="10"
                                                    [accept]="'application/pdf, image/jpg, image/jpeg, image/png ' + MimeTypes.COMPRESSED"
                                                    (onUploaded)="onUploadedFiles($event, obj)"
                                                >
                                                </app-input-file>
                                            </div>
                                        </ng-template>
                                    </p-overlayPanel>
                                    <button
                                        pButton pTooltip="Agregar Archivos"
                                        tooltipPosition="top"
                                        label="Seleccionar"
                                        icon='pi pi-upload'
                                        type="button"
                                        class="p-button-rounded p-button-text"
                                        (click)="uploadFiles.toggle($event)"
                                    ></button>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <div *ngIf="obj.files?.length">
                                        <p-overlayPanel #uploadedFiles>
                                            <div *ngFor="let item of obj.files;" class="mb-4">
                                                <a [href]="attachmentService.getFileUrl(item?.key)" class="hover:underline"
                                                   [download]="item.filename" target="_blank">
                                                    <i class="pi pi-download mr-2"></i>
                                                    {{item.filename}}
                                                </a>
                                            </div>
                                        </p-overlayPanel>

                                        <button
                                            pButton pTooltip="Ver Archivos"
                                            tooltipPosition="top"
                                            label="Archivos"
                                            icon='pi pi-eye'
                                            type="button"
                                            class="p-button-rounded p-button-text"
                                            (click)="uploadedFiles.toggle($event)"
                                        ></button>
                                    </div>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td>
                            <div class="flex align-items-center justify-content-center gap-2">
                                <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(obj, ri)" class="p-button-rounded p-button-text"></button>
                                <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(obj)" class="p-button-rounded p-button-text p-button-success mr-2" [disabled]="!getForm(obj)?.valid"></button>
                                <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(obj, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
