import {Component, OnInit, ViewChild} from '@angular/core';
import {Constants} from "../../../shared/constants";
import {IProject, ProjectService} from "../../../itcp";
import {MenuItem} from "primeng/api";
import {Table} from "primeng/table";
import {CredentialsService, ProjectFlow, ProjectStagesEnum} from "../../../shared";
import {PermissionService} from "../../../shared/services/permission.service";
import {User} from "../../../core/user/interfaces";
import {GenericFunctions} from "../../../shared/services/generic-functions";

@Component({
    selector: 'app-expost',
    templateUrl: './expost.component.html',
    styleUrls: ['./expost.component.scss']
})
export class ExpostComponent implements OnInit {
    resource = Constants.RESOURCES.PROJECT_EXPOST;
    projects: IProject[] = [];

    loading: boolean = false;
    breadcrumbs: MenuItem[] = [
        { label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home'] },
        { label: ' Seguimiento', routerLink: ['/tracking'] },
        { label: ' Momentos de Evaluación Ex Post' },
    ];
    @ViewChild('dt') table!: Table;

    constructor(
        private readonly credentialsService: CredentialsService,
        private readonly permissionService: PermissionService,
        private readonly projectService: ProjectService
    ) {
    }

    ngOnInit(): void {
        this.getAll();
    }

    getAll(): void {
        this.loading = true;
        this.projectService.getAll({
            params: {
                assignments: true,
                status: ProjectFlow.trackingStatusesForExpost(),
            }
        })
            .then((resp) => {
                this.projects = [...resp];
            })
            .finally(() => {
                this.loading = false;
            });
    }

    getFullName(user: User): string {
        return GenericFunctions.getUserFullName(user);
    }

    setFilter(event: any, type: string, query: string, column: string = ''): void {
        if (type === 'filterGlobal') {
            this.table.filterGlobal(event.target.value, query);
        } else if (type === 'filter') {
            this.table.filter(event.target.value, column, query);
        }
    }

    getRoute(projectId: number) {
        const route = `/tracking/expost/${projectId}/eval`;
        return [route];
    }
}
