import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from '../../shared';
import { IProjectC4 } from '../models/interfaces/project-c4.interface';

@Injectable({
  providedIn: 'root',
})
export class ProjectC4Service extends BaseService<IProjectC4> {
  constructor(private readonly httpService: BaseHttpService) {
    super(httpService, 'project-c4');
  }
}
