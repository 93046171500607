import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VisitsRoutingModule } from './visits-routing.module';
import {VisitFormModalComponent, VisitsComponent} from './components';
import {BreadcrumbModule} from "primeng/breadcrumb";
import {ToolbarModule} from "primeng/toolbar";
import {DirectiveModule} from "../shared/directives/directive.module";
import {TableModule} from "primeng/table";
import {ButtonModule} from "primeng/button";
import { VisitStatusComponent } from './components/visit-status/visit-status.component';
import {BadgeModule} from "primeng/badge";
import {InputTextModule} from "primeng/inputtext";
import {SkeletonModule} from "primeng/skeleton";
import {ReactiveFormsModule} from "@angular/forms";
import {AutoCompleteModule} from "primeng/autocomplete";
import {CalendarModule} from "primeng/calendar";
import {CheckboxModule} from "primeng/checkbox";
import {DropdownModule} from "primeng/dropdown";

@NgModule({
  declarations: [
    VisitsComponent,
    VisitStatusComponent,
      VisitFormModalComponent
  ],
    imports: [
        CommonModule,
        VisitsRoutingModule,
        BreadcrumbModule,
        ToolbarModule,
        DirectiveModule,
        TableModule,
        ButtonModule,
        BadgeModule,
        InputTextModule,
        SkeletonModule,
        ReactiveFormsModule,
        AutoCompleteModule,
        CalendarModule,
        CheckboxModule,
        DropdownModule,
    ]
})
export class VisitsModule { }
