import { ProjectService } from '../../itcp';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from '../../shared/services/toaster.service';
import {CryptoService, SettingsService} from '../../shared';
import {ProjectProgrammerModel} from "./project-programmer.model";
import {PROJECT_READ_ONLY} from "../../shared/constants";

export abstract class ProjectSummaryModel extends ProjectProgrammerModel{

    protected constructor(route: ActivatedRoute, service: ProjectService, tService: ToasterService, setService: SettingsService, crypto: CryptoService) {
        super(route, service, tService, setService, crypto);
    }

    override setSteps() {

        this.steps = [
            {
                step: 1,
                text: 'Resumen de Actividades',
                icon: 'pi pi-calendar-plus',
                path: `/tracking/project-details/${this.params['id']}/ends/activities`,
            },
            {
                step: 2,
                text: 'Resumen Físico',
                icon: 'pi pi-sliders-v',
                path: `/tracking/project-details/${this.params['id']}/ends/physical`,
            },
            {
                step: 3,
                text: 'Resumen Financiero',
                icon: 'pi pi-dollar',
                path: `/tracking/project-details/${this.params['id']}/ends/financial`,
            },
            {
                step: 4,
                text: 'Cierre del Proyecto',
                icon: 'pi pi-lock',
                path: `/tracking/project-details/${this.params['id']}/ends/done`,
            }
        ]
    }

    reporterReadOnly() {
        //Only can report if project is programmed.
        return this.isReadOnly(PROJECT_READ_ONLY.MONITORING) || !this.isProgrammed;
    }
}
