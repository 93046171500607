import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {
    IProjectGoalActivity,
    IProjectGoalResult,
    ProjectGoalActivityService
} from "../../../../../edtp";
import {Table} from "primeng/table";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToasterService} from "../../../../../shared/services/toaster.service";
import {ProjectActivityBudgetService} from "../../../../services";

@Component({
    selector: 'app-activity-weekly',
    templateUrl: './activities.component.html',
    styleUrls: ['./activities.component.scss']
})
export class WeekActivitiesComponent implements OnInit {
    @Input() group!: IProjectGoalResult;
    @Input() canProgram: boolean = false;
    @Input() selectedYear!: number;


    cloned: { [s: string]: IProjectGoalActivity; } = {};

    @ViewChild('dt') table!: Table;

    constructor(
        private readonly activityService: ProjectGoalActivityService,
        private readonly budgetService: ProjectActivityBudgetService,
        private fb: FormBuilder,
        private toasterService: ToasterService,
    ) {
    }

    ngOnInit(): void {
        if (!this.group.activities)
            this.group.activities = [];
    }

    onRowEditInit(obj: IProjectGoalActivity) {
        if (!obj?.id) return;
        this.cloned[obj.id] = {...obj};
    }

    getForm(obj: IProjectGoalActivity): any {
        if (!obj?.id) return;
        let form!: FormGroup;
        const { currentMonth } = obj;

        if (!currentMonth) return;

        form = this.fb.group({
            activityId: [currentMonth.activityId, Validators.required],
            year: [currentMonth.year, Validators.required],
            month: [currentMonth.month, Validators.required],
            week1: [currentMonth.week1],
            week2: [currentMonth.week2],
            week3: [currentMonth.week3],
            week4: [currentMonth.week4],
        });
        return form;
    }

    onRowEditSave(obj: IProjectGoalActivity) {
        if (!obj?.id) return;

        const { currentMonth } = obj;
        const form = this.getForm(obj);

        if (form?.valid) {
            delete this.cloned[obj.id];

            const index = this.group.activities.indexOf(obj);

            if (currentMonth?.id?.toString().includes('new_')) {
                this.activityService.storeActivityMonth(form.value).then((resp) => {
                    this.group.activities[index].currentMonth = {
                        ...this.group.activities[index].currentMonth,
                        ...resp,
                    };
                    this.toasterService.success('El Registro fue creado satisfactoriamente.');
                });

            } else {
                const id = currentMonth?.id ? parseInt(currentMonth?.id.toString()) : 0;
                this.activityService.updateActivityMonth(form.value, id).then((resp) => {
                    this.toasterService.success('El Registro fue actualizado satisfactoriamente.');
                })
            }

        } else {
            this.toasterService.error('No se pudo guardar, los datos son inválidos!');
        }
    }

    onRowEditCancel(obj: IProjectGoalActivity, index: number) {
        if (!obj?.id) return;

        if (this.cloned[obj.id]) {
            this.group.activities[index] = this.cloned[obj.id];
            delete this.cloned[obj.id];
        }

        if (obj?.id.toString().includes('new_')) {
            this.group.activities = this.group.activities.filter(o => o.id !== obj.id);
        }
    }
}
