<div class='w-full bg-gray-200'>
    <div class='flex flex-column gap-2 justify-content-start'>
        <div class="w-full  px-4 py-1">
            <span class="font-bold">{{group.goal?.description}}</span>
            <div class="text-sm">{{group.goal?.component?.name}}</div>
            <div class="text-xs">{{group.goal?.component?.description}}</div>
        </div>
        <div class="flex gap-4 justify-content-start align-items-center bg-gray-100">
            <div class="px-6 py-1">
                <b>Resultado Esperado:</b>
                <div>{{group.expectedResult}}</div>
            </div>
        </div>
    </div>
</div>

<p-table
    [value]="group.activities"
    dataKey="id"
    editMode="row"
    [tableStyle]="{'width': '100%'}"
    #dt>
    <ng-template pTemplate="header">
        <tr>
            <th class="bg-secondary border-x-1 text-center p-0" style="width:60px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="width:300px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="width:120px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="width:120px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="width:120px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="width:120px"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="width:120px"></th>
            <th class="bg-secondary p-0" style="width:100px"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-obj let-editing="editing" let-index="rowIndex">
        <tr [pEditableRow]="obj">
            <td style='width:60px'>{{index + 1}}</td>
            <td>
                {{obj.activity}}
            </td>
            <td class="text-center">
                {{obj.currentMonth.expected | number: '1.0-2'}}
            </td>
            <td class="text-center">
                {{ calcPMG(obj) | number: '1.0-2'}} %
            </td>
            <td class="text-center relative">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <div class="flex flex-nowrap gap-1 justify-content-center">
                            <input pInputText
                                   required
                                   [max]="obj.currentMonth.yearExpected - obj.currentMonth.totalExecuted"
                                   [min]="0"
                                   class='w-6rem p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary'
                                   type="number" [(ngModel)]="obj.currentMonth.executed" [ngModelOptions]="{standalone: true}">
                            <div>
                                <p-overlayPanel #op>
                                    <b>Programado:</b> {{obj.currentMonth.expected}}.<br>
                                    <b>Programado Presente Gestión:</b>  {{obj.currentMonth.yearExpected}}.<br>
                                    <b>Total Ejecutado:</b> {{obj.currentMonth.totalExecuted + obj.currentMonth.executed}}<br>
                                    <b>Disponible:</b> {{obj.currentMonth.yearExpected - obj.currentMonth.totalExecuted - obj.currentMonth.executed}}
                                </p-overlayPanel>

                                <button pButton pRipple type="button" pInitEditableRow icon="pi pi-info-circle" (click)="op.toggle($event)" class="p-button-rounded p-button-text"></button>
                            </div>
                        </div>

                        <div *ngIf="getForm(obj)?.invalid" class="text-left absolute left-0">
                            <p *ngIf="obj.currentMonth.executed < 0" class="text-danger text-sm">
                               Valor Inválido
                            </p>
                            <p *ngIf="obj.currentMonth.executed > (obj.currentMonth.yearExpected - obj.currentMonth.totalExecuted)" class="text-danger text-sm">
                                El valor máximo es: {{obj.currentMonth.yearExpected - obj.currentMonth.totalExecuted}}
                            </p>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.currentMonth.executed | number: '1.0-2'}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class="text-center">
                {{ calcEMG(obj) | number: '1.0-2'}} %
            </td>
            <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-overlayPanel #uploadFiles>
                            <ng-template pTemplate="content">
                                <div class="p-2">
                                    <app-input-file
                                        [multiple]="true"
                                        [maxFiles]="10"
                                        [accept]="'application/pdf, image/jpg, image/jpeg, image/png ' + MimeTypes.COMPRESSED"
                                        (onUploaded)="onUploadedVerifiable($event, obj)"
                                    >
                                    </app-input-file>
                                </div>
                            </ng-template>
                        </p-overlayPanel>
                        <button
                            pButton pTooltip="Agregar Archivos Verificables"
                            tooltipPosition="top"
                            label="Seleccionar"
                            icon='pi pi-upload'
                            type="button"
                            class="p-button-rounded p-button-text"
                            (click)="uploadFiles.toggle($event)"
                        ></button>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <div *ngIf="obj.currentMonth.files?.length">
                            <p-overlayPanel #uploadedFiles>
                                <div *ngFor="let item of obj.currentMonth.files;" class="mb-4">
                                    <a [href]="attachmentService.getFileUrl(item?.key)" class="hover:underline"
                                       [download]="item.filename" target="_blank">
                                        <i class="pi pi-download mr-2"></i>
                                        {{item.filename}}
                                    </a>
                                </div>
                            </p-overlayPanel>

                            <button
                                pButton pTooltip="Ver Archivos Verificables"
                                tooltipPosition="top"
                                label="Verificables"
                                icon='pi pi-eye'
                                type="button"
                                class="p-button-rounded p-button-text"
                                (click)="uploadedFiles.toggle($event)"
                            ></button>
                        </div>
                    </ng-template>
                </p-cellEditor>
            </td>
            <td>
                <div class="flex align-items-center justify-content-center gap-2">
                    <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(obj)" class="p-button-rounded p-button-text"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(obj)" class="p-button-rounded p-button-text p-button-success mr-2" [disabled]="getForm(obj)?.invalid"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(obj, index)" class="p-button-rounded p-button-text p-button-danger"></button>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
