import { FormControl } from '@angular/forms';

const REQUIRED_LENGTH = 8;

type PhoneNumberValidation = null | {
  phoneNumberLength?: boolean;
};

export class PhoneNumberLengthValidator {
  static phoneNumberValidator(control: FormControl): PhoneNumberValidation | null {
    const val = control.value;
    const out: PhoneNumberValidation = {};

    if (!(val && val?.length !== REQUIRED_LENGTH)) return null;

    out.phoneNumberLength = true;

    return out;
  }
}
