import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from 'src/app/shared/services';
import { IProjectBeneficiary } from '../models';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectBeneficiaryService extends BaseService<IProjectBeneficiary>{

    constructor(private readonly httpService: BaseHttpService) {
        super(httpService, 'project-beneficiaries');
    }

    async upsert(data: IProjectBeneficiary): Promise<IProjectBeneficiary> {
        return lastValueFrom(this._baseHttpService.post(`${this.namespace}/upsert`, data));
    }
}
