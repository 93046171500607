import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MenuItem} from "primeng/api";
import {Table} from "primeng/table";
import {ActivatedRoute} from "@angular/router";
import {IProject, ProjectService} from "../../../../itcp";
import {ToasterService} from "../../../../shared/services/toaster.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {IProjectExpost} from "../../../models";
import {ProjectExpostService} from "../../../services";
import {AttachmentService} from "../../../../shared";
import {MIME_TYPES} from "../../../../shared/constants";

@Component({
  selector: 'app-eval',
  templateUrl: './eval.component.html',
  styleUrls: ['./eval.component.scss']
})
export class EvalComponent implements OnInit,  OnDestroy {

    breadcrumbs: MenuItem[] = [
        { label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home'] },
        { label: ' Seguimiento', routerLink: ['/tracking'] },
        { label: ' Momentos de Evaluación Ex Post', routerLink: ['/tracking/expost'] },
        { label: ' Evaluación' }
    ];

    components: IProjectExpost[] = [];
    currentProject!: IProject;
    cloned: { [s: string]: IProjectExpost; } = {};
    private sub: any;
    private projectId!: number;
    loading!: boolean;
    MimeTypes = MIME_TYPES;

    @ViewChild('dt') table!: Table;
    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly expostService: ProjectExpostService,
        private tService: ToasterService,
        private fb: FormBuilder,
        public readonly attachmentService: AttachmentService,
    ) {}

    ngOnInit(): void {
        this.loading = true;
        this.readParams();
        this.projectService.get(this.projectId).then((resp) => {
            this.currentProject = { ... resp };
           return   this.expostService.getAll({ params: { projectId: this.projectId }});
        }).then((resp) => {
            this.initComponents(resp);
        }).finally(() => {
            this.loading = false;
        });
    }

    initComponents(items: IProjectExpost[]) {
        const c1 = items.find(i => i.cycle === 'SHORT') || {};
        const c2 = items.find(i => i.cycle === 'MEDIUMM') || {};
        const c3 = items.find(i => i.cycle === 'LARGE') || {};
        const item: IProjectExpost = {
            id: `new_add_${new Date().getTime()}_1`,
            title: 'CORTO PLAZO (1 a 5 AÑOS)',
            projectId: this.projectId,
            cycle: "SHORT",
            mortality: 0,
            refaults: 0,
            seizure: 0,
            ...c1
        }
        item.files = item.evalFiles?.map(f => f.file) || [];
        this.components.push(item);

        const item2: IProjectExpost = {
            id: `new_add_${new Date().getTime()}_2`,
            title: 'MEDIANO PLAZO (6 a 10 AÑOS)',
            projectId: this.projectId,
            cycle: "MEDIUMM",
            mortality: 0,
            refaults: 0,
            seizure: 0,
            ...c2,
        }
        item2.files = item2.evalFiles?.map(f => f.file) || [];
        this.components.push(item2);

        const item3: IProjectExpost = {
            id: `new_add_${new Date().getTime()}_3`,
            title: 'LARGO PLAZO (más de 10 AÑOS)',
            projectId: this.projectId,
            cycle: "LARGE",
            mortality: 0,
            refaults: 0,
            seizure: 0,
            ...c3,
        }
        item3.files = item3.evalFiles?.map(f => f.file) || [];
        this.components.push(item3);
    }

    onRowEditInit(obj: IProjectExpost, index: number) {
        this.cloned[index] = {...obj};
    }

    getForm(obj: IProjectExpost) {
        if (!obj?.id) return;
        const form: FormGroup = this.fb.group({
            projectId: [obj.projectId, Validators.required],
            mortality: [obj.mortality, [Validators.required, Validators.min(0)]],
            refaults: [obj.refaults, [Validators.required, Validators.min(0)]],
            seizure: [obj.seizure, [Validators.required, Validators.min(0)]],
            cycle: [obj.cycle, [Validators.required, Validators.min(0)]],
            evalFilesIds: [obj.evalFilesIds],
        });
        return form;
    }

    onRowEditSave(obj: IProjectExpost) {
        if (!obj?.id) return;

        const form = this.getForm(obj);

        if (form?.valid) {
            delete this.cloned[obj.id];

            if (obj?.id.toString().includes('new_')) {
                const index = this.components.indexOf(obj);
                this.expostService.store(form.value).then((resp) => {
                    this.components[index] = {
                        ...this.components[index],
                        ...resp,
                    };
                    this.tService.success('El Registro fue creado satisfactoriamente.');
                });

            } else {
                const id = parseInt(obj.id.toString());
                const index = this.components.indexOf(obj);
                this.expostService.update(form.value, id).then((resp) => {
                    this.components[index] = {
                        ...this.components[index],
                        ...resp,
                    };
                    this.tService.success('El Registro fue actualizado satisfactoriamente.');
                })
            }

        } else {
            this.tService.error('No se pudo guardar, los datos son inválidos!');
        }

    }

    onRowEditCancel(obj: IProjectExpost, index: number) {

        if (this.cloned[index]) {
            this.components[index] = this.cloned[index];
            delete this.cloned[index];
        }
    }

    onUploadedFiles(data: any, obj: IProjectExpost) {
        if (!obj) return;

        if (!obj.evalFilesIds?.length) obj.evalFilesIds = [];
        const { files } = data;

        if (files.length) {
            obj.evalFilesIds = [
                ...obj.evalFilesIds,
                ...files.map((f: any) => f.id),
            ];
            if (!obj.files) obj.files = [];
            obj.files = [
                ...obj.files,
                ...files
            ];
        }
    }

    readParams() {
        this.sub = this.activatedRoute.params.subscribe((params) => {
            this.projectId = parseInt(params['id']);
        });
    }

    cleanParams() {
        this.sub.unsubscribe();
    }

    ngOnDestroy() {
        this.cleanParams();
    }

}
