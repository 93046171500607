import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ConfirmationService, MenuItem} from "primeng/api";
import {Table} from "primeng/table";
import {Subject, takeUntil} from "rxjs";
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {Constants} from "../../../../shared/constants";
import {ToasterService} from "../../../../shared/services/toaster.service";
import {ProjectChangeOrderService} from "../../../services";
import {ChangeOrderType, IProjectChangeOrder} from "../../../models";
import {AttachmentService, CryptoService} from "../../../../shared";
import {ProjectModel, ProjectService} from "../../../../itcp";
import {LayoutService} from "../../../../shared/services/app.layout.service";
import { ActivatedRoute, Router } from '@angular/router';
import {FormBuilder, Validators} from "@angular/forms";
import {ChangeOrderFormModal} from "./form/modal.component";
import {PermissionService} from "../../../../shared/services/permission.service";

@Component({
    selector: 'app-change-order',
    templateUrl: './change-order.component.html',
    styleUrls: ['./change-order.component.scss']
})
export class ChangeOrderComponent extends ProjectModel implements OnInit, OnDestroy {

    resource = Constants.RESOURCES.TRACKING_CHANGE_ORDER;
    loading: boolean = false;
    orders!: IProjectChangeOrder[];
    isExternalRole: boolean = false;
    rejectModal: boolean = false;
    statusesClassNames: any = {
        REQUESTED: 'warning',
        APPROVED: 'success',
        REJECTED: 'danger',
        ERROR: 'danger',
    };
    statuses: any = {
        REQUESTED: 'SOLICITADO',
        APPROVED: 'APROBADO',
        REJECTED: 'RECHAZADO',
        ERROR: 'ERROR',
    };
    breadcrumbs: MenuItem[] = [
        { label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home'] },
        { label: ' Seguimiento', routerLink: ['/tracking'] },
        { label: ' Órdenes de Proceso', routerLink: ['/tracking/orders'] },
        { label: ' Ordenes de Cambio' },
    ];

    @ViewChild('dt') table!: Table;
    private onDestroy$ = new Subject();
    public ref!: DynamicDialogRef;
    private modal: DynamicDialogConfig<IProjectChangeOrder> = {
        ...Constants.MODAL.default,
    };

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly  changeOrderService: ProjectChangeOrderService,
        public readonly dialogService: DialogService,
        public readonly confirmationService: ConfirmationService,
        private readonly tService: ToasterService,
        public readonly attachmentService: AttachmentService,
        private crypto: CryptoService,
        private readonly layoutService: LayoutService,
        private fb: FormBuilder,
        public readonly permissionsService: PermissionService,
        private readonly router: Router,
    ) {
        super(activatedRoute, projectService, tService, crypto);
    }

    ngOnInit(): void {
        this.layoutService.showMenuDesktop(true);
        this.readParams();
        this.getAll();
        this.isExternalRole = this.permissionsService.isRequestingEntityRole();
    }

    getAll(): void {
        this.loading = true;
        this.service.get(this.params['id']).then((project) => {
            const {id, name } = project || {};
            this.currentProject = {...project};
            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required],
            });
            const projectId = parseInt(this.params['id']);
            return this.changeOrderService.getAll({ params: { projectId } });
        }).then((resp) => {
            if (resp)  this.orders = [...resp];
        }).finally(() => {
            this.showContent = true;
            this.loading = false;
        });
    }

    setFilter(event: any, type: string, query: string, column: string = ''): void {
        if (type === 'filterGlobal') {
            this.table.filterGlobal(event.target.value, query);
        } else if (type === 'filter') {
            this.table.filter(event.target.value, column, query);
        }
    }

    selectedItem(data: IProjectChangeOrder, type: string) {
        switch (type) {
            case 'delete':
                this.confirmationService.confirm({
                    message: '¿Se encuentra seguro de eliminar este registro?',
                    accept: () => {
                        this.service
                            .delete(data.id)
                            .then((resp: boolean) => {
                                if (resp) {
                                    this.toasterService.success('El registro fue eliminado con éxito.');
                                    this.reload();
                                }
                            })
                            .catch((err) => {
                                console.error(err);
                                this.toasterService.error(
                                    'Se ha producido un error inténtelo más tarde o contáctese con el Administrador.'
                                );
                            });
                    },
                });
                break;
            case 'edit':
                this.modal.data = data;
                this.modal.header = 'Actualizar Orden de Cambio';
                this.ref = this.dialogService.open(ChangeOrderFormModal, this.modal);
                this.ref.onClose.pipe(takeUntil(this.onDestroy$)).subscribe((resp) => {
                    if (resp) this.reload();
                });
                break;
            default:
                break;
        }
    }

    addNew() {
        this.modal.header = 'Nueva Orden de Cambio';
        this.modal.data = {
            id: 0,
            justification: "",
            projectId: parseInt(this.params['id'])};
        this.ref = this.dialogService.open(ChangeOrderFormModal, this.modal);
        this.ref.onClose.pipe(takeUntil(this.onDestroy$)).subscribe((resp) => {
            if (resp) this.reload();
        });
    }

    reload() {
        this.getAll();
    }

    approveReject(order: IProjectChangeOrder, approve: boolean) {
        if (!approve && !order.rejectedJustification) return;
        this.rejectModal = false;
        const status: ChangeOrderType = approve ? 'APPROVED' : 'REJECTED';

        if (approve) {
            this.confirmationService.confirm({
                message: '¿Se encuentra seguro de aprobar esta solicitud?',
                accept: () => {
                    order.processing = true;
                    this.changeOrderService.update({
                        ...order,
                        processing: undefined,
                        status
                    }, order.id).then((resp) => {
                        if (resp) order.status = resp.status;
                    }).catch((error) => {
                        console.error(error);
                        order.status = 'ERROR';
                    }).finally(() => {
                        order.processing = false;
                    });
                },
            });
        } else {
            order.processing = true;
            this.changeOrderService.update({
                ...order,
                processing: undefined,
                status
            }, order.id).then((resp) => {
                if (resp) order.status = resp.status;
            }).catch((error) => {
                console.error(error);
                order.status = 'ERROR';
            }).finally(() => {
                order.processing = false;
            });
        }
    }

    goToProgrammer(projectId: number) {
        const route = `tracking/programmer/${projectId}/physical`;
        this.router.navigate([route]).then();
    }

    get disabled(): boolean {
        return this.currentProject?.status === Constants.PROJECT_STATUSES.PROGRAMMING ||
            this.currentProject?.status === Constants.PROJECT_STATUSES.CLOSED;
    }

    ngOnDestroy(): void {
        this.cleanParams();
        this.onDestroy$.next(undefined);
        this.onDestroy$.complete();
    }
}
