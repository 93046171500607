import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { BaseHttpService } from 'src/app/shared/services';
import { Role } from './interfaces';

@Injectable({
    providedIn: 'root'
})
export class RoleService {

    constructor(private readonly _baseHttpService: BaseHttpService) {}

    public getRoles(): Promise<Role[]> {
        return lastValueFrom(this._baseHttpService.get('roles'));
    }

    async getByName(name: string): Promise<Role> {
        return lastValueFrom(this._baseHttpService.get(`roles/name/${name}`));
    }

    public storeRole(data: Role): Promise<Role> {
        return lastValueFrom(this._baseHttpService.post('roles', data));
    }

    public updateRole(data: Role, roleId: number): Promise<Role> {
        return lastValueFrom(this._baseHttpService.put(`roles/${roleId}`, data));
    }

    public deleteRole(roleId: number): Promise<boolean> {
        return lastValueFrom(this._baseHttpService.delete(`roles/${roleId}`));
    }
}
