<div class='w-full bg-green-100'>
    <div class='flex gap-2 justify-content-start align-items-center px-4 py-2'>
        <div class="px-4 py-1">{{index + 1 }}</div>
        <div class="px-4 py-1 w-20rem flex justify-content-start align-items-center flex-1">
            <span class="font-bold">{{activity.activity}}</span>
        </div>
    </div>
</div>

<p-table
    [value]="budgets"
    dataKey="id"
    editMode="row"
    [tableStyle]="{'min-width': '100%'}"
    #dt>
    <ng-template pTemplate="header">
        <tr *ngIf="budgets.length">
            <th class="bg-primary border-x-1 text-center p-0" style="min-width:60px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width:300px; max-width: 300px;"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 115px; max-width: 115px"></th>
            <th class="bg-primary border-x-1 text-center p-0" style="min-width: 120px;"></th>
            <th class="bg-primary p-0" style="width:100px"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-obj let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="obj">
            <td style='width:50px'>{{index + 1}}.{{ri + 1}}</td>
            <td width="300">
                <b>{{obj.budgetItemCode}}</b> - {{obj.budgetItem?.title}}
                <div class="text-sm mt-2">
                    {{obj.budgetItemDetails}}
                </div>
            </td>

            <td class="text-center">
                {{obj.currentMonth.ownerAmount | number:'1.2-2'}}
            </td>

            <td class="text-center">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <div class="flex flex-nowrap">
                            <input pInputText
                                   required
                                   [min]="0"
                                   [max]="obj.currentMonth.ownerAmount"
                                   class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-6rem'
                                   type="number" [(ngModel)]="obj.currentMonth.ownerExecutedAmount" [ngModelOptions]="{standalone: true}">
                            <div>
                                <p-overlayPanel #op>
                                    <b>Programado (Mes):</b> {{obj.currentMonth.ownerAmount | number: '1.2-2'}}.<br>
                                    <b>Programado (Gestión):</b>  {{obj.currentMonth.yearOwner | number: '1.2-2'}}.<br>
                                    <b>Disponible:</b> {{(obj.currentMonth.ownerAmount - obj.currentMonth.ownerExecutedAmount) | number: '1.2-2'}}<br>
                                    <b>Total Ejecutado:</b> {{(obj.currentMonth.totalExecutedOwner + obj.currentMonth.ownerExecutedAmount) | number: '1.2-2'}}
                                </p-overlayPanel>

                                <button pButton pRipple type="button" pInitEditableRow icon="pi pi-info-circle" (click)="op.toggle($event)" class="p-button-rounded p-button-text"></button>
                            </div>
                        </div>
                        <div *ngIf="getForm(obj)?.invalid" class="relative w-full">
                            <p *ngIf="obj.currentMonth.ownerExecutedAmount < 0" class="text-danger text-sm absolute">
                                {{'Valor mínimo: 0'}}.
                            </p>
                            <p *ngIf="obj.currentMonth.ownerExecutedAmount > (obj.currentMonth.ownerAmount || 0)" class="text-danger text-sm absolute">
                                {{'Valor máximo: '  + obj.currentMonth.ownerAmount || 0 }}.
                            </p>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.currentMonth.ownerExecutedAmount | number: '1.2-2'}}
                    </ng-template>
                </p-cellEditor>
            </td>

            <td class="text-center">
                {{ calcExecuted(obj.currentMonth.ownerExecutedAmount, obj.currentMonth.ownerAmount) | percent:'1.0-1'}}
            </td>
            <td>
                {{ obj.currentMonth.ownerAmount - obj.currentMonth.ownerExecutedAmount | number: '1.2-2'}}
            </td>
            <td class="text-center">
                {{obj.currentMonth.entityAmount | number: '1.2-2'}}
            </td>
            <td class='text-center'>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <div class="flex flex-nowrap">
                            <input pInputText
                                   required
                                   [min]="0"
                                   [max]="obj.currentMonth.entityAmount"
                                   class='w-6rem p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary'
                                   type="number" [(ngModel)]="obj.currentMonth.entityExecutedAmount" [ngModelOptions]="{standalone: true}">
                            <p-overlayPanel #op>
                                <b>Programado (Mes):</b> {{obj.currentMonth.entityAmount | number: '1.2-2'}}.<br>
                                <b>Programado (Gestión):</b>  {{obj.currentMonth.yearEntity | number: '1.2-2'}}.<br>
                                <b>Disponible:</b> {{(obj.currentMonth.entityAmount - obj.currentMonth.entityExecutedAmount) | number: '1.2-2'}}<br>
                                <b>Total Ejecutado:</b> {{(obj.currentMonth.totalExecutedEntity + obj.currentMonth.entityExecutedAmount) | number: '1.2-2'}}
                            </p-overlayPanel>

                            <button pButton pRipple type="button" pInitEditableRow icon="pi pi-info-circle" (click)="op.toggle($event)" class="p-button-rounded p-button-text"></button>
                        </div>
                        <div *ngIf="getForm(obj)?.invalid" class="relative w-full">
                            <p *ngIf="obj.currentMonth.entityExecutedAmount < 0" class="text-danger text-sm absolute">
                                {{'Valor mínimo: 0'}}.
                            </p>
                            <p *ngIf="obj.currentMonth.entityExecutedAmount > (obj.currentMonth.entityAmount || 0 )" class="text-danger text-sm absolute">
                                {{'Valor máximo: '  + obj.currentMonth.entityAmount || 0 }}.
                            </p>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.currentMonth.entityExecutedAmount | number: '1.2-2'}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class="text-center">
                {{ calcExecuted(obj.currentMonth.entityExecutedAmount, obj.currentMonth.entityAmount) | percent:'1.0-1'}}
            </td>
            <td class="text-center">
                {{ obj.currentMonth.entityAmount - obj.currentMonth.entityExecutedAmount | number: '1.2-2'}}
            </td>
            <td class="text-center">
                {{obj.currentMonth.entityInKindAmount | number: '1.2-2'}}
            </td>
            <td class='text-center'>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <div class="flex flex-nowrap">
                            <input pInputText
                                   required
                                   [min]="0"
                                   [max]="obj.currentMonth.entityInKindAmount"
                                   class='w-6rem p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary'
                                   type="number" [(ngModel)]="obj.currentMonth.entityInKindExecutedAmount" [ngModelOptions]="{standalone: true}">
                            <p-overlayPanel #op>
                                <b>Programado (Mes):</b> {{obj.currentMonth.entityInKindAmount | number: '1.2-2'}}.<br>
                                <b>Programado (Gestión):</b>  {{obj.currentMonth.yearEntityInKind | number: '1.2-2'}}.<br>
                                <b>Disponible:</b> {{(obj.currentMonth.entityInKindAmount - obj.currentMonth.entityInKindExecutedAmount) | number: '1.2-2'}}<br>
                                <b>Total Ejecutado:</b> {{(obj.currentMonth.totalExecutedEntityInKind + obj.currentMonth.entityInKindExecutedAmount) | number: '1.2-2'}}
                            </p-overlayPanel>

                            <button pButton pRipple type="button" pInitEditableRow icon="pi pi-info-circle" (click)="op.toggle($event)" class="p-button-rounded p-button-text"></button>
                        </div>
                        <div *ngIf="getForm(obj)?.invalid" class="relative w-full">
                            <p *ngIf="obj.currentMonth.entityInKindExecutedAmount < 0" class="text-danger text-sm absolute">
                                {{'Valor mínimo: 0'}}.
                            </p>
                            <p *ngIf="obj.currentMonth.entityInKindExecutedAmount > ( obj.currentMonth.entityInKindAmount || 0)" class="text-danger text-sm absolute">
                                {{'Valor máximo: '  + obj.currentMonth.entityInKindAmount || 0 }}.
                            </p>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.currentMonth.entityInKindExecutedAmount | number: '1.2-2'}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td class="text-center">
                {{ calcExecuted(obj.currentMonth.entityInKindExecutedAmount, obj.currentMonth.entityInKindAmount) | percent:'1.0-1'}}
            </td>
            <td class="text-center">
                {{ obj.currentMonth.entityInKindAmount - obj.currentMonth.entityInKindExecutedAmount | number: '1.2-2'}}
            </td>
            <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-overlayPanel #uploadFiles>
                            <ng-template pTemplate="content">
                                <div class="p-2">
                                    <app-input-file
                                        [multiple]="true"
                                        [maxFiles]="10"
                                        [accept]="'application/pdf, image/jpg, image/jpeg, image/png ' + MimeTypes.COMPRESSED"
                                        (onUploaded)="onUploadedVerifiable($event, obj)"
                                    >
                                    </app-input-file>
                                </div>
                            </ng-template>
                        </p-overlayPanel>
                        <button
                            pButton pTooltip="Agregar Archivos Verificables"
                            tooltipPosition="top"
                            label="Seleccionar"
                            icon='pi pi-upload'
                            type="button"
                            class="p-button-rounded p-button-text"
                            (click)="uploadFiles.toggle($event)"
                        ></button>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <div *ngIf="obj.currentMonth.files?.length">
                            <p-overlayPanel #uploadedFiles>
                                <div *ngFor="let item of obj.currentMonth.files;" class="mb-4">
                                    <a [href]="attachmentService.getFileUrl(item?.key)" class="hover:underline"
                                       [download]="item.filename" target="_blank">
                                        <i class="pi pi-download mr-2"></i>
                                        {{item.filename}}
                                    </a>
                                </div>
                            </p-overlayPanel>

                            <button
                                pButton pTooltip="Ver Archivos Verificables"
                                tooltipPosition="top"
                                label="Verificables"
                                icon='pi pi-eye'
                                type="button"
                                class="p-button-rounded p-button-text"
                                (click)="uploadedFiles.toggle($event)"
                            ></button>
                        </div>
                    </ng-template>
                </p-cellEditor>
            </td>
            <td>
                <div *ngIf="canReportExecution" class="flex align-items-center justify-content-center gap-2">
                    <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(obj)" class="p-button-rounded p-button-text"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(obj)" class="p-button-rounded p-button-text p-button-success mr-2" [disabled]="!getForm(obj)?.valid"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(obj, index)" class="p-button-rounded p-button-text p-button-danger"></button>
                </div>
            </td>
        </tr>
<!--        <tr *ngIf="budgets.length === ri + 1" class="bg-gray-100 font-bold">-->
<!--            <td class="text-center p-1" colspan="3">TOTAL</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem" >0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem" >0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="text-center p-1 w-6rem">0</td>-->
<!--            <td class="p-1" style="width:100px"></td>-->
<!--        </tr>-->
    </ng-template>
</p-table>
