<app-wizard
    [steps]='steps'
    [current]='currentStep'
    [bodyContentTpl]='projectForm'
    [nextDisabled]='this.formGroup?.invalid'
    (onFinish)='saveSkipValidation($event)'
    [layout]='true'
    [lastStep]='true'
>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column' [formGroup]='formGroup'>
        <div class='grid'>
            <div class="field col-12 text-2xl text-primary uppercase">
                {{currentStep?.text}}
            </div>

            <div class="field col-12">
                <app-weighting-content
                    [projectId]="currentProject?.id"
                    (onScoreChanged)="onScoreChanged($event)"
                ></app-weighting-content>
            </div>
        </div>
    </div>
</ng-template>
