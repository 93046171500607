<div class="grid">
    <p-breadcrumb [model]="breadcrumbs"></p-breadcrumb>
    <div class="col-12">
        <div class="card">
            <div class="text-base">
                <b class="mr-2">NOMBRE DEL PROYECTO</b> <app-project-status [status]="currentProject?.status"></app-project-status>:
                <br/> {{currentProject?.name}}
            </div>
            <p-toolbar styleClass="mb-4 w-full clean-toolbar">
                <div class="flex justify-content-between flex-column align-items-center sm:flex-row w-full">
                    <div class="p-input-icon-left mb-2 searcher">
                        <i class="pi pi-search"></i>
                        <input
                            pInputText
                            type="text"
                            class="w-full"
                            (input)="setFilter($event, 'filterGlobal', 'contains')"
                            placeholder="Buscar..."
                        />
                    </div>
                    <div class="my-2">
                        <button
                            appPermission
                            [resource]="resource"
                            [enableActions]="'canView'"
                            pButton
                            pRipple
                            label="Actualizar"
                            icon="pi pi-refresh"
                            class="p-button-info mr-2"
                            (click)="reload()"
                        ></button>
                        <button
                            *ngIf='!disabled'
                            appPermission
                            [resource]="resource"
                            [enableActions]="'canCreate'"
                            pButton
                            pRipple
                            label="Agregar"
                            icon="pi pi-plus"
                            class="p-button mr-2"
                            (click)="addNew()"
                        ></button>
                    </div>
                </div>
            </p-toolbar>
            <p-table
                #dt
                [value]="orders"
                styleClass="p-datatable-gridlines"
                [loading]="loading"
                [resizableColumns]="true"
                [paginator]="true"
                [rows]="6"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} al {last} de {totalRecords} items"
                [rowsPerPageOptions]="[6, 12, 24]"
                responsiveLayout="scroll"
                [filterDelay]="0"
                [globalFilterFields]="['title', 'description']"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th class="bg-primary border-x-1 text-center">NRO</th>
                        <th class="bg-primary border-x-1 text-center">FECHA SOLICITUD</th>
                        <th class="bg-primary border-x-1 text-center">JUSTIFICACIÓN</th>
                        <th class="bg-primary border-x-1 text-center">ESTADO</th>
                        <th *ngIf='!isExternalRole' class="bg-primary border-x-1 text-center">PROGRAMACIÓN</th>
                        <th class="bg-primary border-x-1 text-center"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                    <tr>
                        <td class="text-center" >{{ rowIndex + 1 }}</td>
                        <td class="text-center" >{{ item.createdAt | date: "yyyy-MM-dd, h:mm a" }}</td>
                        <td>
                            <div class="description-cell">{{ item.justification }}</div>
                            <a *ngIf="item.file" [href]="attachmentService.getFileUrl(item.file.key)" class="hover:underline"
                               [download]="item.filename" target="_blank">
                                <i class="pi pi-download mr-2"></i>
                                {{item.file.filename}}
                            </a>
                        </td>

                        <td class="text-center">
                            <div *ngIf="item.processing" class="px-8 py-4 text-center">
                                <label class="text-sm">Procesando.....</label>
                                <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
                            </div>
                            <div *ngIf="!item.processing" class="flex flex-nowrap align-items-center justify-content-center">
                                <p-badge [value]="statuses[item.status]" [severity]="statusesClassNames[item.status]"></p-badge>
                                <div *ngIf="item.status === 'REJECTED'">
                                    <p-overlayPanel #op>
                                        <div class="max-w-30rem">
                                            {{item.rejectedJustification}}
                                        </div>
                                    </p-overlayPanel>
                                    <button pButton type="button" icon="pi pi-info-circle" (click)="op.toggle($event)" class="p-button-rounded p-button-text"></button>
                                </div>
                            </div>
                        </td>
                        <td *ngIf='!isExternalRole'>
                            <button
                                    *ngIf="item.status !== 'REQUESTED'"
                                    pButton pRipple
                                    type="button"
                                    pTooltip="Ver Programación del estado actual en esta orden de cambio"
                                    tooltipPosition="top"
                                    label="Ver Programación Actual"
                                    (click)="goToProgrammer(item.projectId)"
                                    class="p-button-rounded p-button-text">

                            </button>
                            <br/>
                            <button
                                    *ngIf='item.oldProjectId'
                                    pButton pRipple
                                    type="button"
                                    pTooltip="Ver Programación del estado anterior a esta orden de cambio"
                                    tooltipPosition="top"
                                    label="Ver Programación Anterior"
                                    (click)="goToProgrammer(item.oldProjectId)"
                                    class="p-button-rounded p-button-text p-button-secondary">

                            </button>
                        </td>
                        <td class="text-center">
                            <div *ngIf="(item.status === 'REQUESTED' || item.status === 'ERROR') && !disabled">
                                <button
                                    *ngIf="!isExternalRole"
                                    pButton
                                    pRipple
                                    appPermission
                                    [resource]="resource"
                                    [enableActions]="'canUpdate'"
                                    pTooltip="Aprobar Order de Cambio"
                                    tooltipPosition="top"
                                    type="button"
                                    icon="pi pi-check"
                                    class="p-button p-button-success p-button-text p-button-rounded"
                                    (click)="approveReject(item, true)"
                                ></button>
                                <button
                                    *ngIf="!isExternalRole"
                                    pButton
                                    pRipple
                                    appPermission
                                    [resource]="resource"
                                    [enableActions]="'canUpdate'"
                                    pTooltip="Rechazar Orden de Cambio"
                                    tooltipPosition="top"
                                    type="button"
                                    icon="pi pi-times"
                                    class="p-button p-button-danger p-button-text p-button-rounded"
                                    (click)=" rejectModal = true"
                                ></button>

                                <p-dialog header="Header" [(visible)]="rejectModal" [modal]="true" [style]="{ width: '50vw' }">
                                    <ng-template pTemplate="header">
                                        <span class="text-xl font-bold">Justificación del Rechazo</span>
                                    </ng-template>
                                    <p class="p-4">
                                    <textarea
                                        [autofocus]="true"
                                        [rows]='10'
                                        pInputTextarea
                                        [autoResize]='false'
                                        [(ngModel)]="item.rejectedJustification" [ngModelOptions]="{standalone: true}"
                                        placeholder='Escriba la justificación aqui...'
                                        class='text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full'
                                    >
                                    </textarea>
                                    </p>
                                    <ng-template pTemplate="footer">
                                        <p-button (click)="rejectModal = false" label="Cancelar" styleClass="p-button-text"></p-button>
                                        <p-button icon="pi pi-times" (click)="approveReject(item, false)" label="Rechazar" styleClass="p-button-text p-button-danger" [disabled]="!item.rejectedJustification"></p-button>
                                    </ng-template>
                                </p-dialog>

                                <button
                                    *ngIf="isExternalRole"
                                    appPermission
                                    [resource]="resource"
                                    [enableActions]="'canUpdate'"
                                    pButton
                                    pTooltip="Editar"
                                    tooltipPosition="top"
                                    type="button"
                                    icon="pi pi-pencil"
                                    class="p-button-rounded p-button-success p-button-text"
                                    (click)="selectedItem(item, 'edit')"
                                ></button>
                                <button
                                    *ngIf="isExternalRole"
                                    appPermission
                                    [resource]="resource"
                                    [enableActions]="'canDelete'"
                                    pButton
                                    pTooltip="Eliminar"
                                    tooltipPosition="top"
                                    type="button"
                                    icon="pi pi-trash"
                                    class="p-button-rounded p-button-danger p-button-text"
                                    (click)="selectedItem(item, 'delete')"
                                ></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
