import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TrackingRoutingModule } from './tracking-routing.module';
import {
    AssignmentComponent,
    ProgrammerComponent,
    PhysicalComponent,
    PhysicalDetailsComponent,
    FinancialComponent,
    FinancialDetailsComponent,
    ProgrammerActivitiesComponent,
    MonitoringComponent,
    MonitoringPhysicalComponent,
    MonthActivitiesComponent,
    MonitoringFinancialComponent,
    ResultActivitiesComponent,
    MonitoringFSActivitiesComponent,
    EvaluationComponent,
    ProjectDetailsComponent,
    ReportPhysicalComponent,
    ReportFSActivitiesComponent,
    ReportFinancialComponent,
    ResultFNActivitiesComponent,
    ActivityBudgetComponent,
    ResultFNActivitiesMonthComponent,
    ActivityBudgetMonthComponent,
    WeekActivitiesComponent,
    MonitoringFinancialActivitiesComponent,
    MonitoringActivityBudgetsComponent,
    EvalFinancialActivitiesComponent,
    EvalActivityBudgetsComponent,
    ProcessOrdersComponent,
    ChangeOrderComponent,
    StartOrderComponent,
    ChangeOrderFormModal,
    ProjectDocsComponent,
    SummaryPhysicalComponent,
    SummaryFSActivitiesComponent,
    SummaryFinancialComponent,
    SummaryFinancialActivitiesComponent,
    SummaryActivityBudgetsComponent,
    SummaryActivitiesComponent,
    SummaryWeekActivitiesComponent, ProjectEndsComponent, ScheduleComponent, ExpostComponent, EvalComponent
} from './components';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabViewModule } from 'primeng/tabview';
import { ToolbarModule } from 'primeng/toolbar';
import { DirectiveModule } from '../shared/directives/directive.module';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { PublicModule } from '../public/public.module';
import { AutoFocusModule } from 'primeng/autofocus';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SkeletonModule } from 'primeng/skeleton';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { RadioButtonModule } from 'primeng/radiobutton';
import {BadgeModule} from "primeng/badge";
import {CalendarModule} from "primeng/calendar";
import {TabMenuModule} from "primeng/tabmenu";
import {SelectButtonModule} from "primeng/selectbutton";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {CustomFormModule} from "../shared";
import {CheckboxModule} from "primeng/checkbox";
import {ProgressBarModule} from "primeng/progressbar";
import {DialogModule} from "primeng/dialog";
import {EDTPModule} from "../edtp";

@NgModule({
    declarations: [
        AssignmentComponent,
        ProgrammerComponent,
        PhysicalComponent,
        ResultActivitiesComponent,
        PhysicalDetailsComponent,
        FinancialComponent,
        FinancialDetailsComponent,
        ProgrammerActivitiesComponent,
        MonitoringComponent,
        MonitoringPhysicalComponent,
        MonitoringFinancialComponent,
        MonthActivitiesComponent,
        MonitoringFSActivitiesComponent,
        EvaluationComponent,
        ReportPhysicalComponent,
        ReportFSActivitiesComponent,
        ReportFinancialComponent,
        ProcessOrdersComponent,
        ProjectDetailsComponent,
        ResultFNActivitiesComponent,
        ActivityBudgetComponent,
        ResultFNActivitiesMonthComponent,
        ActivityBudgetMonthComponent,
        WeekActivitiesComponent,
        MonitoringFinancialComponent,
        MonitoringFinancialActivitiesComponent,
        MonitoringActivityBudgetsComponent,
        EvalFinancialActivitiesComponent,
        EvalActivityBudgetsComponent,
        StartOrderComponent,
        ChangeOrderComponent,
        ChangeOrderFormModal,
        ProjectDocsComponent,
        SummaryPhysicalComponent,
        SummaryFSActivitiesComponent,
        SummaryFinancialComponent,
        SummaryFinancialActivitiesComponent,
        SummaryActivityBudgetsComponent,
        SummaryActivitiesComponent,
        SummaryWeekActivitiesComponent,
        ProjectEndsComponent,
        ScheduleComponent,
        ExpostComponent,
        EvalComponent,
    ],
    imports: [
        CommonModule,
        TrackingRoutingModule,
        BreadcrumbModule,
        TableModule,
        DropdownModule,
        ReactiveFormsModule,
        FormsModule,
        TabViewModule,
        ToolbarModule,
        DirectiveModule,
        InputTextModule,
        ButtonModule,
        PublicModule,
        AutoFocusModule,
        InputTextareaModule,
        SkeletonModule,
        AutoCompleteModule,
        RadioButtonModule,
        BadgeModule,
        CalendarModule,
        TabMenuModule,
        SelectButtonModule,
        OverlayPanelModule,
        CustomFormModule,
        CheckboxModule,
        ProgressBarModule,
        DialogModule,
        EDTPModule,
    ]
})
export class TrackingModule {
}
