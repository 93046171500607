import {Component, OnInit, ViewChild} from '@angular/core';
import {Constants} from "../../../shared/constants";
import {IProject, ProjectService} from "../../../itcp";
import {MenuItem} from "primeng/api";
import {Table} from "primeng/table";
import {CredentialsService, ProjectFlow, ProjectStagesEnum} from "../../../shared";
import {PermissionService} from "../../../shared/services/permission.service";
import {User} from "../../../core/user/interfaces";
import {GenericFunctions} from "../../../shared/services/generic-functions";
import {LayoutService} from "../../../shared/services/app.layout.service";

@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.scss']
})
export class EvaluationComponent implements OnInit {
    resource = Constants.RESOURCES.TRACKING_EVALUATION;
    projects: IProject[] = []; //Assigned projects

    loading: boolean = false;
    breadcrumbs: MenuItem[] = [
        { label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home'] },
        { label: ' Seguimiento', routerLink: ['/tracking'] },
        { label: ' Valoración de Reportes' },
    ];
    @ViewChild('dt') table!: Table;

    constructor(
        private readonly credentialsService: CredentialsService,
        private readonly permissionService: PermissionService,
        private readonly projectService: ProjectService,
        private readonly layoutService: LayoutService,
    ) {}

    ngOnInit(): void {
        this.layoutService.showMenuDesktop(true);
        this.getAll();
    }

    getAll(): void {
        this.loading = true;
        this.projectService.getAll({ params: {
                assignments: true,
                status: ProjectFlow.trackingStatusesForEvaluation(),
                stage: ProjectStagesEnum.TRACKING
            }})
            .then((resp) => {
                this.projects = [...resp];
            })
            .finally(() => {
                this.loading = false;
            });
    }

    setFilter(event: any, type: string, query: string, column: string = ''): void {
        if (type === 'filterGlobal') {
            this.table.filterGlobal(event.target.value, query);
        } else if (type === 'filter') {
            this.table.filter(event.target.value, column, query);
        }
    }

    getRoute(projectId: number) {
        const route = `/tracking/evaluation/${projectId}/physical`;
        return [route];
    }

    getFullName(user: User): string {
        return GenericFunctions.getUserFullName(user);
    }
}
