import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { IProjectGoalResult} from "../../../models";
import {Table} from "primeng/table";
import {ConfirmationService, MenuItem} from "primeng/api";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {IProjectGoal, ProjectService} from "../../../../itcp";
import {ActivatedRoute} from "@angular/router";
import {ToasterService} from "../../../../shared/services/toaster.service";
import {ProjectGoalResultService} from "../../../services";
import { ProjectProgrammerModel } from '../../../../tracking/models';
import {CryptoService, SettingsService} from '../../../../shared';

@Component({
    selector: 'app-activities',
    templateUrl: './activities.component.html',
    styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent  extends ProjectProgrammerModel implements OnInit {

    @Input() external: boolean = false;
    results: IProjectGoalResult[] = [];
    clonedResults: { [s: string]: IProjectGoalResult; } = {};
    goals: IProjectGoal[] = [];

    breadcrumbs: MenuItem[] = [
        { label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home'] },
        { label: ' EDTP', routerLink: ['/edtp'] },
        { label: ' Cronograma de Ejecución', routerLink: ['/edtp/scheduler'] },
        { label: ' Actividades' },
    ];
    @ViewChild('dt') table!: Table;

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly goalResultService: ProjectGoalResultService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private readonly confirmationService: ConfirmationService,
        private readonly settingsService: SettingsService,
        private crypto: CryptoService,
    ) {
        super(activatedRoute, projectService, tService, settingsService, crypto);
    }

    ngOnInit(): void {
        this.readParams();
        this.load();
    }

    load() {
        this.service.get(this.params['id']).then((project) => {
            const {id, name, goals} = project || {};
            this.currentProject = {...project};
            if (goals?.length) this.goals = [ ...goals ];
            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required],
            });
            const projectId = id || 0;
            return this.goalResultService.getAll({ params: { projectId }});
        }).then((resp) => {
            this.results = [ ...resp ];
        }).finally(() => {
            this.showContent = true;
        });
    }

    onNew() {
        const result: IProjectGoalResult = {
            id: `new_${new Date().getTime()}`,
            projectId: parseInt(this.params['id']),
            goalId: 0,
            expectedResult: '',
            activities: [],
        }
        this.results.push(result);
        this.table.initRowEdit(this.table.value[this.results.length - 1]);
    }

    onRowEditInit(obj: IProjectGoalResult) {
        if (!obj?.id) return;
        this.clonedResults[obj.id] = {...obj};
    }

    onRowEditCancel(obj: IProjectGoalResult, index: number) {
        if (!obj?.id) return;

        if (this.clonedResults[obj.id]) {
            this.results[index] = this.clonedResults[obj.id];
            delete this.clonedResults[obj.id];
        }

        if (obj?.id.toString().includes('new_')) {
            this.results = this.results.filter(g => g.id !== obj.id);
        }
    }

    onRowEditSave(obj: IProjectGoalResult) {
        if (!obj?.id) return;

        const form: FormGroup = this.fb.group({
            projectId: [obj.projectId, Validators.required],
            goalId: [obj.goalId, Validators.required],
            expectedResult: [obj.expectedResult, Validators.required],
        });

        if (form.valid) {
            delete this.clonedResults[obj.id];

            if (obj?.id.toString().includes('new_')) {
                const index = this.results.indexOf(obj);
                this.goalResultService.store(form.value).then((resp) => {
                    this.results[index] = resp;
                    this.toasterService.success('El Registro fue creado satisfactoriamente.');
                });

            } else {
                const id = parseInt(obj.id.toString());
                this.goalResultService.update(form.value, id).then((resp) => {
                    this.toasterService.success('El Registro fue actualizado satisfactoriamente.');
                })
            }

        } else {
            this.toasterService.error('No se pudo guardar, los datos son inválidos!');
        }

    }

    onRemove(obj: IProjectGoalResult) {
        if (!obj.id) return;

        const id = parseInt(obj.id.toString());
        this.confirmationService.confirm({
            message: 'Estas seguro de eliminar este registro?',
            accept: () => {
                this.goalResultService
                    .delete(id)
                    .then((resp: boolean) => {
                        if (resp) {
                            this.toasterService.success('Se Elimino correctamente!');
                            this.load();
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                        this.toasterService.error(err.error.message);
                    });
            },
        });
    }

    ngOnDestroy() {
        this.cleanParams();
    }

}
