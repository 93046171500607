import { IAnnouncement } from './announcement.interface';
import { IAttachment, ICity, IProvince, ITown } from '../../../shared';
import { IEntity } from './entity.interface';
import { TipoEntidad } from '../../../core/entidad/interfaces/tipo-entidad.interface';
import { IITCPRequestVerification } from './itcp-request-verification.interface';
import { User } from '../../../core/user/interfaces';
import { IProject } from './project.interface';
import { IProjectBeneficiary } from './project-beneficiary.interface';
import { IProjectBudget } from '../../../edtp/models/interfaces/project-budget.interface';

export interface IITCPRequest {
  id?: number;
  token?: string;
  announcement?: IAnnouncement;
  announcementCode: string;
  announcementId: number;
  entityTypeId?: number;
  entityType?: TipoEntidad;
  entityId?: number;
  entity?: IEntity;
  seoName?: string;
  seoIdentityFile?: IAttachment;
  seoIdentityFileId?: number;
  seoJobtitle?: string;
  seoMemoFile?: IAttachment;
  seoMemoFileId?: number;
  seoCellPhone?: string;
  leadName?: string;
  leadJobtitle?: string;
  leadMemoFile?: IAttachment;
  leadMemoFileId?: number;
  leadCellPhone?: string;
  leadEmail?: string;
  contactPhone?: string;
  contactEmail?: string;
  requestStatus?: string;
  verifications?: IITCPRequestVerification[];
  userId?: number;
  user?: User;
  projects?: IProject[];
  beneficiaries?: IProjectBeneficiary[];
  budgets?: IProjectBudget[];
  readOnly?: boolean;
}

export interface IITCPParsed extends IITCPRequest {
  state: string;
  project?: IProject;
  totalBeneficiaries?: number;
  budget?: IProjectBudget;
}
