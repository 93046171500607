import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IProjectBaseLine, IProjectC1, ProjectEdtpModel} from "../../../../models";
import {ActivatedRoute} from "@angular/router";
import {ProjectService} from "../../../../../itcp";
import {ProjectBaseLineService, TypologyType} from "../../../../services";
import {CryptoService, SettingsService} from "../../../../../shared";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToasterService} from "../../../../../shared/services/toaster.service";
import {Table} from "primeng/table";
import {Constants} from "../../../../../shared/constants";
import {ProjectC1Service} from "../../../../services/project-c1.service";

@Component({
  selector: 'app-t-iv',
  templateUrl: './t-iv.component.html',
  styleUrls: ['./t-iv.component.scss']
})
export class C1TIVComponent extends ProjectEdtpModel implements OnInit, OnDestroy {

    private step: number = 8;
    c1Items: IProjectC1[] = [];
    clonedC1Items: { [s: string]: IProjectC1; } = {};

    @ViewChild('dt') table!: Table;

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly baseLineService: ProjectBaseLineService,
        private readonly pc1Service: ProjectC1Service,
        private readonly setService: SettingsService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private crypto: CryptoService,
    ) {
        super(activatedRoute, projectService, tService, setService, crypto);
    }

    ngOnInit(): void {
        this.readParams();
        this.setActive(this.step);
        this.service.get(this.params['id']).then((project) => {
            const {id, name} = project || {};
            this.currentProject = {...project};
            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required]
            });
            const projectId = id || 0;
            return this.pc1Service.getAll({ params: { projectId, typology: Constants.TYPOLOGIES.TYPOLOGY_IV } });
        }).then((resp) => {
            this.c1Items = [ ...resp ];
            return this.loadComponents(this.componentsForTypologyIV());
        }).finally(() => {
            this.setSteps();
            this.setActive(this.step); //We need to reload the steps and put as active the current step
            this.setC1ForProject();
            this.disableFormIfSubmitted();
            this.showContent = true;
        });

    }

    setC1ForProject() {
        if (!this.currentProject?.baseLines?.length) return;
        // this.baseLines = this.currentProject.baseLines.filter(bl => bl.typology === Constants.TYPOLOGIES.TYPOLOGY_IV);
        // const included = this.c1Items.map(c1 => c1.baseLineId);
        // this.baseLines.map((bl, index) => {
        //     if (!included.includes(bl.id)) {//To prevent duplicates (items that were previously saved)
        //         const item: IProjectC1 = {
        //             id: `new_${new Date().getTime()}_${index}`,
        //             description: bl.component,
        //             projectId: bl.projectId,
        //             quantityRequired: bl.quantity,
        //             quantityYear1: 0,
        //             quantityYear2: 0,
        //             quantityYear3: 0,
        //             uom: bl.uom,
        //             typology: bl.typology,
        //             baseLineId: bl.id,
        //         }
        //         this.c1Items.push(item);
        //     }
        // });
    }

    onRowEditInit(obj: IProjectC1) {
        if (!obj?.id) return;
        this.clonedC1Items[obj.id] = {...obj};
    }

    onRowEditSave(obj: IProjectC1) {
        if (!obj?.id) return;

        const form: FormGroup = this.fb.group({
            projectId: [obj.projectId, Validators.required],
            description: [obj.description, Validators.required],
            quantityRequired: [obj.quantityRequired, [Validators.required, Validators.min(0)]],
            quantityYear1: [obj.quantityYear1, [Validators.required, Validators.min(0)]],
            quantityYear2: [obj.quantityYear2, [Validators.required, Validators.min(0)]],
            quantityYear3: [obj.quantityYear3, [Validators.required, Validators.min(0)]],
            uom: [obj.uom, Validators.required],
            typology: [obj.typology, Validators.required],
            baseLineId: [obj.baseLineId],
        });

        if (form.valid) {
            delete this.clonedC1Items[obj.id];

            if (obj?.id.toString().includes('new_')) {
                const index = this.c1Items.indexOf(obj);
                this.pc1Service.store(form.value).then((resp) => {
                    this.c1Items[index] = resp;
                    this.toasterService.success('El Registro fue creado satisfactoriamente.');
                });

            } else {
                const id = parseInt(obj.id.toString());
                this.pc1Service.update(form.value, id).then((resp) => {
                    this.toasterService.success('El Registro fue actualizado satisfactoriamente.');
                })
            }

        } else {
            this.toasterService.error('No se pudo guardar, los datos son inválidos!');
        }

    }

    onRowEditCancel(obj: IProjectC1, index: number) {
        if (!obj?.id) return;

        if (this.clonedC1Items[obj.id]) {
            this.c1Items[index] = this.clonedC1Items[obj.id];
            delete this.clonedC1Items[obj.id];
        }

        if (obj?.id.toString().includes('new_add')) {
            this.c1Items.pop();
        }
    }

    onNew() {
        const c1: IProjectC1 = {
            id: `new_add_${new Date().getTime()}`,
            description: '',
            projectId: parseInt(this.params['id']),
            quantityRequired: 0,
            quantityYear1: 0,
            quantityYear2: 0,
            quantityYear3: 0,
            uom: '-',
            typology: Constants.TYPOLOGIES.TYPOLOGY_IV,
        }

        this.c1Items.push(c1);
        this.table.initRowEdit(this.table.value[this.c1Items.length - 1]);
    }

    onSelectComponent(event: any, obj: IProjectC1) {
        const group: any = this.components.find(c => {
            return c.items.find(i => i.value === event.value)
        });

        const uom = this.unitsOfMeasure.find(uom => uom.key === group.value);
        if (uom) obj.uom = uom.value;
    }

    ngOnDestroy() {
        this.cleanParams();
    }
}
