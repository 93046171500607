import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from 'src/app/shared/services';
import { IAnnouncement, IITCPRequest, IITCPRequestVerification } from '../models';
import {lastValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ITCPRequestService extends BaseService<IITCPRequest>{

    constructor(private readonly httpService: BaseHttpService) {
        super(httpService, 'itcp-requests');
    }

    async getByToken(token: string): Promise<IITCPRequest> {
        return lastValueFrom(this._baseHttpService.get(`${this.namespace}/token/${token}`));
    }

    async getByIdEntityId(id: number, entityId: number): Promise<IITCPRequest> {
        return lastValueFrom(this._baseHttpService.get(`${this.namespace}/entity/${id}/${entityId}`));
    }

    async upsertVerification(data: IITCPRequestVerification, id: number): Promise<IITCPRequest> {
        return lastValueFrom(this._baseHttpService.put(`${this.namespace}/${id}/verification`, data));
    }

    async updatePartialData(data: IITCPRequest, updateData: Partial<IITCPRequest>, id: number) {
        const storeData: IITCPRequest = {
            announcementCode: data.announcementCode,
            announcementId: data.announcementId,
            token: data.token,
            ...updateData,
        };

        return this.update(storeData, id);
    }

    async setReadonly(id: number, readonly: boolean): Promise<IITCPRequest> {
        return lastValueFrom(this._baseHttpService.put(`${this.namespace}/${id}/readonly/${readonly}`, {}));
    }
}
