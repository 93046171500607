<p-breadcrumb [model]="breadcrumbs"></p-breadcrumb>
<p-dialog
    [closable]="false"
    [showHeader]="false"
    [visible]="generatingPDF"
    [modal]="true"
    [style]="{ width: '50vw' }"
    [draggable]="false"
    [resizable]="false"
>
    <div class="text-center py-6">Por favor espere mientras se genera el documento PDF .....</div>
</p-dialog>
<div class="container">
    <div class="grid">
        <form class="sm:col-12 d-flex align-items-center" [formGroup]="projectForm">
            <div class="grid">
                <div class="field lg:col-6 md:col-6 sm:col-12">
                    <ng-multiselect-dropdown
                        [placeholder]="'Departamento'"
                        [settings]="cityDropdownSettings"
                        [data]="cities"
                        formControlName="cityIds"
                    >
                    </ng-multiselect-dropdown>
                </div>
                <div class="field lg:col-4 md:col-6 sm:col-12">
                    <button
                        pButton
                        type="button"
                        label="Filtrar"
                        (click)="getAll()"
                        [disabled]="this.projectForm.invalid"
                    ></button>
                </div>
            </div>
        </form>
    </div>
</div>

<div *ngIf="!approvedRejectedProjects.length && !filteredFlattedData.length" class="text-center w-1">
    No existen datos
</div>

<p-tabView
    styleClass="tabview-custom"
    *ngIf="showData && (approvedRejectedProjects.length || filteredFlattedData.length)"
>
    <p-tabPanel *ngIf="approvedRejectedProjects.length">
        <ng-template pTemplate="header">
            <i class="pi pi-list"></i>
            <span class="ml-2">ITCP Aprobados / Rechazados</span>
        </ng-template>

        <div class="grid">
            <div class="field lg:col-4 md:col-6 sm:col-12">
                <button
                    pButton
                    type="button"
                    label="Exportar a PDF"
                    (click)="generateApprovedRejectedPDF()"
                    [disabled]="generatingPDF || !approvedRejectedProjects.length"
                ></button>
            </div>
        </div>

        <div class="container mt-3">
            <p-table
                #dtApprovedRejectedTable
                [value]="approvedRejectedProjects"
                styleClass="p-datatable-gridlines"
                [scrollable]="!generatingPDF"
                scrollHeight="400px"
                scrollDirection="both"
                [loading]="loading"
                currentPageReportTemplate="{first} al {last} de {totalRecords} items"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th *ngIf="!generatingPDF" class="bg-primary text-center" style="width: 50px">Nº</th>
                        <th *ngIf="!generatingPDF" class="bg-primary text-center" style="width: 200px">Usuario</th>
                        <th class="bg-primary text-center" style="width: 150px">Departamento</th>
                        <th class="bg-primary text-center" style="width: 200px">Provincia</th>
                        <th class="bg-primary text-center" style="width: 200px">Municipio</th>
                        <th *ngIf="!generatingPDF" class="bg-primary text-center" style="width: 200px">MAE</th>
                        <th class="bg-primary text-center" style="width: 200px">Nombre del proyecto</th>
                        <th class="bg-primary text-center" style="width: 100px">Beneficiario Varones</th>
                        <th class="bg-primary text-center" style="width: 100px">Beneficiario Mujeres</th>
                        <th class="bg-primary text-center" style="width: 100px">Total Beneficiarios</th>
                        <th class="bg-primary text-center" style="width: 200px">Presupuesto</th>
                        <th class="bg-primary text-center" style="width: 200px">Presupuesto Entidad</th>
                        <th class="bg-primary text-center" style="width: 200px">Presupuesto Total</th>
                        <th class="bg-primary text-center" style="width: 150px">Estado del ITCP</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-project let-ri="rowIndex">
                    <tr>
                        <td *ngIf="!generatingPDF" class="text-center overflow-hidden" style="width: 50px">
                            {{ ri + 1 }}
                        </td>
                        <td *ngIf="!generatingPDF" class="text-center overflow-hidden" style="width: 200px">
                            {{ project?.iTCPRequest?.user?.correo }}
                        </td>
                        <td class="text-center overflow-hidden" style="width: 150px">
                            {{ project?.iTCPRequest?.entity?.city?.dep }}
                        </td>
                        <td class="text-center overflow-hidden" style="width: 200px">
                            {{ project?.iTCPRequest?.entity?.province?.prov }}
                        </td>
                        <td class="text-center overflow-hidden" style="width: 200px">
                            {{ project?.iTCPRequest?.entity?.town?.mun }}
                        </td>
                        <td *ngIf="!generatingPDF" class="text-center overflow-hidden" style="width: 200px">
                            {{ project?.iTCPRequest?.seoName }}
                        </td>
                        <td class="text-center overflow-hidden" style="width: 200px">{{ project?.name }}</td>
                        <td class="text-center overflow-hidden" style="width: 100px">{{ project?.menBeneficiary }}</td>
                        <td class="text-center overflow-hidden" style="width: 100px">
                            {{ project?.womenBeneficiary }}
                        </td>
                        <td class="text-center overflow-hidden" style="width: 100px">
                            {{ project?.totalBeneficiaries }}
                        </td>
                        <td class="text-center overflow-hidden" style="width: 200px">
                            {{ project?.budget?.ownerAmount }}
                        </td>
                        <td class="text-center overflow-hidden" style="width: 200px">
                            {{ project?.budget?.entityAmount }}
                        </td>
                        <td class="text-center overflow-hidden" style="width: 200px">
                            {{
                                ((project?.budget?.ownerAmount || 0) + (project?.budget?.entityAmount || 0)).toFixed(2)
                            }}
                        </td>
                        <td class="text-center" style="width: 150px">{{ getProjectStatus(project) }}</td>
                    </tr>
                </ng-template>
            </p-table>

            <div class="grid mt-3" #chartPDFRef>
                <div class="sm:col-12">
                    <div *ngIf='!generatingPDF' class="text-lg font-semibold">
                        Listado de Municipio con ITCP aprobados total, por provincia y departamento con costo total del
                        Proyecto, monto cofinanciado por Fonabosque y monto del GAM en Bs
                    </div>
                </div>
                <div [ngClass]="{'field lg:col-4 md:col-6 sm:col-12': true, 'text-2xl': generatingPDF}">
                    <div class="mb-3">
                        <div class="flex">
                            <span class="font-semibold w-6 text-right">Aprobado:</span>
                            <span class="ml-2">{{ this.totalApproved }}</span>
                        </div>
                        <div class="flex">
                            <span class="font-semibold w-6 text-right">Deshabilitado:</span>
                            <span class="ml-2">{{ this.totalRejected }}</span>
                        </div>
                        <div class="flex">
                            <span class="font-semibold w-6 text-right">Total: </span>
                            <span class="ml-2">{{ this.totalApproved + this.totalRejected }}</span>
                        </div>
                    </div>
                </div>

                <div class="field lg:col-4 md:col-6 sm:col-12">
                    <p-chart type="pie" [data]="approvedRejectedChartData"></p-chart>
                </div>
            </div>

            <div class="grid mt-3">
                <div class="sm:col-12">
                    <p-table
                        #dtTableAR
                        [value]="flattedDataPerTown"
                        styleClass="p-datatable-gridlines"
                        [scrollable]="!generatingPDF"
                        scrollHeight="400px"
                        scrollDirection="both"
                        [loading]="loading"
                        currentPageReportTemplate="{first} al {last} de {totalRecords} items"
                    >
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="bg-primary text-center" style="width: 50px">Nº</th>
                                <th class="bg-primary text-center" style="width: 150px">Departamento</th>
                                <th class="bg-primary text-center" style="width: 200px">Provincia</th>
                                <th class="bg-primary text-center" style="width: 200px">Municipio</th>
                                <th class="bg-primary text-center" style="width: 200px">Presupuesto FONABOSQUE</th>
                                <th class="bg-primary text-center" style="width: 200px">Presupuesto ENTIDAD</th>
                                <th class="bg-primary text-center" style="width: 200px">Presupuesto TOTAL</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-data let-ri="rowIndex">
                            <tr>
                                <td class="text-center overflow-hidden" style="width: 50px">{{ ri + 1 }}</td>
                                <td class="text-center overflow-hidden" style="width: 150px">{{ data.cityName }}</td>
                                <td class="text-center overflow-hidden" style="width: 200px">
                                    {{ data.provinceName }}
                                </td>
                                <td class="text-center overflow-hidden" style="width: 200px">{{ data.townName }}</td>
                                <td class="text-center overflow-hidden" style="width: 200px">{{ data.ownerAmount }}</td>
                                <td class="text-center overflow-hidden" style="width: 200px">
                                    {{ data.entityAmount }}
                                </td>
                                <td class="text-center overflow-hidden" style="width: 200px">
                                    {{ ((data.ownerAmount || 0) + (data.entityAmount || 0)).toFixed(2) }}
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr>
                                <td colspan="4" style="width: 600px" class="text-right">Total</td>
                                <td style="width: 200px" class="text-center">{{ this.totalOwnerCost.toFixed(2) }}</td>
                                <td style="width: 200px" class="text-center">{{ this.totalEntityCost.toFixed(2) }}</td>
                                <td style="width: 200px" class="text-center">
                                    {{ this.totalOwnerEntityCost.toFixed(2) }}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>

                <div class="sm:col-12 mt-3" #budgetBarChartPDF>
                    <p-chart type="bar" [data]="financialChartData" [options]="stackedOptions"></p-chart>
                </div>
            </div>
        </div>
    </p-tabPanel>
    <p-tabPanel *ngIf="filteredFlattedData.length">
        <ng-template pTemplate="header">
            <i class="pi pi-list"></i>
            <span class="ml-2">Personas Beneficiadas</span>
        </ng-template>

        <div class="container mt-3">
            <form class="sm:col-12 d-flex align-items-center" [formGroup]="filterForm">
                <div class="grid">
                    <div class="field lg:col-4 md:col-6 sm:col-12">
                        <ng-multiselect-dropdown
                            [placeholder]="'Municipios'"
                            [settings]="townsDropdownSettings"
                            [data]="towns"
                            formControlName="towns"
                        >
                        </ng-multiselect-dropdown>
                    </div>
                    <div class="field lg:col-6 md:col-6 sm:col-12">
                        <button
                            pButton
                            type="button"
                            label="Filtrar"
                            (click)="filterByTowns()"
                            [disabled]="this.projectForm.invalid"
                        ></button>
                    </div>
                </div>
            </form>
        </div>

        <div class="grid">
            <div class="field lg:col-4 md:col-6 sm:col-12">
                <button
                    pButton
                    type="button"
                    label="Exportar a PDF"
                    (click)="generateBenefitedPeoplePDF()"
                    [disabled]="generatingPDF || !filteredFlattedData.length"
                ></button>
            </div>
        </div>

        <div class="font-semibold text-lg mb-4">
            Listado de Municipios total por provincia y departamento, que incluya &#35; personas beneficiadas por genero
        </div>

        <div class="container mt-3">
            <p-table
                #dtSpecific
                [value]="filteredFlattedData"
                styleClass="p-datatable-gridlines"
                [scrollable]="!generatingPDF"
                scrollHeight="400px"
                scrollDirection="both"
                [loading]="loading"
                currentPageReportTemplate="{first} al {last} de {totalRecords} items"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th class="bg-primary text-center" style="width: 50px">Nº</th>
                        <th class="bg-primary text-center" style="width: 150px">Departamento</th>
                        <th class="bg-primary text-center" style="width: 200px">Provincia</th>
                        <th class="bg-primary text-center" style="width: 200px">Municipio</th>
                        <th class="bg-primary text-center" style="width: 200px">Beneficiario Varones</th>
                        <th class="bg-primary text-center" style="width: 200px">Beneficiario Mujeres</th>
                        <th class="bg-primary text-center" style="width: 200px">Total Beneficiarios</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-ri="rowIndex">
                    <tr>
                        <td class="text-center overflow-hidden" style="width: 50px">{{ ri + 1 }}</td>
                        <td class="text-center overflow-hidden" style="width: 150px">{{ data.cityName }}</td>
                        <td class="text-center overflow-hidden" style="width: 200px">{{ data.provinceName }}</td>
                        <td class="text-center overflow-hidden" style="width: 200px">{{ data.townName }}</td>
                        <td class="text-center overflow-hidden" style="width: 200px">{{ data.menBeneficiary }}</td>
                        <td class="text-center overflow-hidden" style="width: 200px">{{ data.womenBeneficiary }}</td>
                        <td class="text-center overflow-hidden" style="width: 200px">{{ data.totalBeneficiaries }}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr>
                        <td colspan="4" style="width: 600px" class="text-right">Total</td>
                        <td style="width: 200px" class="text-center">{{ this.totalFilteredMenBeneficiaries }}</td>
                        <td style="width: 200px" class="text-center">{{ this.totalFilteredWomenBeneficiaries }}</td>
                        <td style="width: 200px" class="text-center">{{ this.totalFilteredBeneficiaries }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <div class="container mt-3" #benefitedBarChartPDF>
            <div class="sm:col-12">
                <p-chart type="bar" [data]="beneficiariesChartData" [options]="barChartOptions"></p-chart>
            </div>
        </div>
    </p-tabPanel>
</p-tabView>
