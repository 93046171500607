import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {
    IProjectGoalActivity,
    IProjectGoalResult,
    ProjectGoalActivityService
} from "../../../../../edtp";
import {IProjectGoal} from "../../../../../itcp";
import {Table} from "primeng/table";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToasterService} from "../../../../../shared/services/toaster.service";

@Component({
    selector: 'app-result-activities',
    templateUrl: './activities.component.html',
    styleUrls: ['./activities.component.scss']
})
export class ResultActivitiesComponent implements OnInit, OnChanges {
    @Input() group!: IProjectGoalResult;
    @Input() goals!: IProjectGoal[];
    @Input()  totalProjectDays: number = 0;
    @Input() selectedYear!: number;
    @Input() canProgram: boolean = false;
    @Output() onActivitiesChange = new EventEmitter<any>();

    cloned: { [s: string]: IProjectGoalActivity; } = {};
    selectedGroup!: IProjectGoal;

    @ViewChild('dt') table!: Table;

    constructor(
        private readonly activityService: ProjectGoalActivityService,
        private fb: FormBuilder,
        private toasterService: ToasterService,
    ) {
    }

    ngOnInit(): void {
        if (!this.group.activities)
            this.group.activities = [];
    }

    onRowEditInit(obj: IProjectGoalActivity) {
        if (!obj?.id) return;
        this.cloned[obj.id] = {...obj};
    }

    getForm(obj: IProjectGoalActivity) {
        if (!obj) return;
        const form: FormGroup = this.fb.group({
            projectId: [obj.projectId, Validators.required],
            resultId: [obj.resultId, Validators.required],
            activity: [obj.activity, Validators.required],
            indicator: [obj.indicator, Validators.required],
            uom: [obj.uom, Validators.required],
            verification: [obj.verification, Validators.required],
            programmerType: [obj.programmerType, Validators.required],
            generalExpected: [obj.generalExpected, [Validators.required, Validators.min(1)]],
            expectedYear: [obj.expectedYear, [Validators.required, Validators.min(1), Validators.max(obj.generalExpected || 1000000)]],
            year: [this.selectedYear, [Validators.required, Validators.min(0)]],
        });
        return form;
    }

    onRowEditSave(obj: IProjectGoalActivity) {
        if (!obj?.id) return;

        const form = this.getForm(obj);
        if (form?.valid) {
            delete this.cloned[obj.id];
            if (obj?.id.toString().includes('new_')) {
                this.activityService.store(form.value).then((resp) => {
                    this.onActivitiesChange.emit(resp);
                    this.toasterService.success('El Registro fue creado satisfactoriamente.');
                });

            } else {
                const id = parseInt(obj.id.toString());
                this.activityService.update(form.value, id).then((resp) => {
                    this.onActivitiesChange.emit(resp);
                    this.toasterService.success('El Registro fue actualizado satisfactoriamente.');
                })
            }
        } else {
            this.toasterService.error('No se pudo guardar, los datos son inválidos!');
        }
    }

    onRowEditCancel(obj: IProjectGoalActivity, index: number) {
        if (!obj?.id) return;

        if (this.cloned[obj.id]) {
            this.group.activities[index] = this.cloned[obj.id];
            delete this.cloned[obj.id];
        }

        if (obj?.id.toString().includes('new_')) {
            this.group.activities = this.group.activities.filter(o => o.id !== obj.id);
        }
    }

    calcWeighting(act: IProjectGoalActivity) {
        if (this.totalProjectDays <= 0 || !act?.days) return 0;
        act.weight = (act.days / this.totalProjectDays) * 100;
        return act.weight;
    }

    calcWeightingProgress(act: IProjectGoalActivity) {
        if (!(act.expectedYear && act.generalExpected && act.weight)) return 0;
        return ((act.expectedYear/act.generalExpected) * act.weight)
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['selectedYear']) {
            this.group.activities.map(act => {
                act.expectedYear = 0;
                if (act.yearsProgrammed?.length) {
                    for (const yp of act.yearsProgrammed) {
                        if (yp.year === this.selectedYear){
                            act.expectedYear = yp.expected;
                            break;
                        }
                    }
                }
            });
        }
    }
}
