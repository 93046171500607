<app-wizard
    [steps]='steps'
    [current]='currentStep'
    [bodyContentTpl]='projectForm'
    [nextDisabled]='this.formGroup?.invalid'
    (onClickNext)='save($event)'
    [layout]='true'
>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column' [formGroup]='formGroup'>
        <div class='grid'>
            <div class="field col-12 text-2xl text-primary uppercase">
                {{currentStep?.text}}
            </div>

            <div class="field col-12">
                <p-selectButton
                    [options]="selectableProjectYears"
                    optionLabel="label"
                    optionValue="id"
                    [(ngModel)]="selectedProjectYear"
                    [ngModelOptions]="{standalone: true}"
                    (onChange)="generateMonthAndLoadActivities()"
                >
                </p-selectButton>
                <p-selectButton
                    [options]="optionsByYear"
                    [(ngModel)]="selectedOptionYear"
                    [ngModelOptions]="{standalone: true}"
                    (onChange)="generateMonthAndLoadActivities()"
                >
                </p-selectButton>
                <p-selectButton
                    [options]="optionsByMonth"
                    [(ngModel)]="selectedOptionMonth"
                    [ngModelOptions]="{standalone: true}"
                    (onChange)="loadActivities()"
                >
                </p-selectButton>
            </div>

            <div class="field col-12">
                <p-table [value]="results" dataKey="id" editMode="row" [tableStyle]="{'min-width': '100%'}" #dt>
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="bg-primary border-x-1 text-center" style="width:60px">NRO.</th>
                            <th class="bg-primary border-x-1 text-center" style="width:300px">ACTIVIDAD</th>
                            <th class="bg-primary border-x-1 text-center" style="width:120px">PROGRAMADO</th>
                            <th class="bg-primary border-x-1 text-center" style="width:120px">P/MG (%)</th>
                            <th class="bg-primary border-x-1 text-center" style="width:120px">EJECUTADO</th>
                            <th class="bg-primary border-x-1 text-center" style="width:120px">E/MG (%)</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-obj let-editing="editing" let-index="rowIndex">
                        <tr [pEditableRow]="obj">
                            <td colspan="100%" class="p-0 m-0">
                                <app-summary-fs-activities
                                    [group]='obj'
                                    [selectedYear]="projectYears.indexOf(selectedProjectYear)"
                                ></app-summary-fs-activities>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="100%">
                                <div class="w-full text-center font-bold">No se ha encontrado resultados</div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</ng-template>

