import { IProject, IProjectGoal } from '../../../itcp';
import { ProgrammerType } from '../../services';
import { IAttachment } from '../../../shared';
import { User } from '../../../core/user/interfaces';
import { IProjectActivityBudget, IProjectActivityBudgetMonth } from '../../../tracking/models';

export interface IProjectGoalActivityMonth {
    id?: number | string;
    activityId: number;
    activity?: IProjectGoalActivity;
    month: number;
    year: number;
    expected: number;
    executed: number;
    startsDate?: Date;
    endsDate?: Date;
    yearExpected?: number;
    totalProgrammed?: number;
    availableExpected?: number;
    totalExecuted?: number;
    verifiableIds?: number[];
    files?: IAttachment[];
    verifiables?: any[];// returned by query to populate in files
    currentObservation?: string;
    observations?: { activityMonthId: number, observation: string, createdBy: User }[];
    week1?: boolean;
    week2?: boolean;
    week3?: boolean;
    week4?: boolean;
}

export interface IProjectGoalActivity {
    id?: number | string;
    resultId: number;
    projectId: number;
    result?: IProjectGoalResult;
    activity: string;
    indicator?: string;
    uom?: string;
    weeksDuration: number;
    verification?: string;
    programmerType: ProgrammerType;
    generalExpected?: number;
    expectedYear?: number;
    startsDate?: Date;
    endsDate?: Date;
    year?: number;
    days?: number; //It's calculated from Starts Date and Ends Date
    weight?: number;
    monthDetails?: IProjectGoalActivityMonth[];
    currentMonth?: IProjectGoalActivityMonth;
    currentMonthBudget?: IProjectActivityBudgetMonth;
    yearsProgrammed?: IProjectActivityYearProgrammed[];
    budgets?: IProjectActivityBudget[];
    project?: IProject;
}


export interface IProjectGoalResult {
    id?: number | string;
    goalId: number;
    projectId: number;
    goal?: IProjectGoal;
    expectedResult: string;
    activities: IProjectGoalActivity[];
    days?: number; //It's calculated from sum of activities
}

export interface IProjectActivityYearProgrammed {
    activityId: number;
    year: number;
    expected: number;
}
