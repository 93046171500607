import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from '../../shared';
import { IProjectC2t4 } from '../models/interfaces/project-c2t4.interface';

@Injectable({
  providedIn: 'root',
})
export class ProjectC2t4Service extends BaseService<IProjectC2t4> {
  constructor(private readonly httpService: BaseHttpService) {
    super(httpService, 'project-c2t4');
  }
}
