<div class="grid">
  <p-breadcrumb [model]="breadcrumbs"></p-breadcrumb>
  <div class="col-12">
    <div class="card">
      <p-toolbar styleClass="mb-4 w-full clean-toolbar">
        <div class="flex justify-content-between flex-column align-items-center sm:flex-row w-full">
          <div class="p-input-icon-left mb-2 searcher">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              class="w-full"
              (input)="setFilter($event, 'filterGlobal', 'contains')"
              placeholder="Buscar..."
            />
          </div>
          <div class="my-2">
            <button
              appPermission
              [resource]="resource"
              [enableActions]="'canView'"
              pButton
              pRipple
              label="Actualizar"
              icon="pi pi-refresh"
              class="p-button-info mr-2"
              (click)="reload()"
            ></button>
          </div>
        </div>
      </p-toolbar>
      <p-table
        #dtITCPRequest
        [value]="data"
        styleClass="p-datatable-gridlines"
        [loading]="loading"
        [resizableColumns]="true"
        [paginator]="true"
        [rows]="6"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} al {last} de {totalRecords} items"
        [rowsPerPageOptions]="[6, 12, 24]"
        responsiveLayout="scroll"
        [filterDelay]="0"
        [globalFilterFields]="['seoName', 'entityId']"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="bg-primary" *ngFor="let item of header" [pSortableColumn]="item.column">
              {{ item.label }}
              <p-sortIcon *ngIf="item.sort === true" [field]="item.column"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
          <tr>
            <td>{{ data.entity?.nombre }}</td>
            <td class="description-cell">
              <div class="description-cell">{{ data.seoName }}</div>
            </td>
            <td>
              <ul>
                <li><b>Correo electrónico: </b>{{ data.contactEmail }}</li>
              </ul>
            </td>
            <td>
              <button
                appPermission
                *ngIf="!isVerified(data)"
                [resource]="resource"
                [enableActions]="'canUpdate'"
                pTooltip="Verificar"
                tooltipPosition="top"
                type="button"
                class="p-button p-button-warning"
                (click)="selectedItem(data, 'verify')"
              >
                <i class="pi pi-list mr-2"></i> Verificar
              </button>
              <button
                appPermission
                *ngIf="isVerified(data)"
                [resource]="resource"
                [enableActions]="'canUpdate'"
                pTooltip="Verificado"
                tooltipPosition="top"
                type="button"
                class="p-button p-button-success"
                (click)="selectedItem(data, 'verify')"
              >
                <i class="pi pi-check mr-2"></i> Verificado
              </button>
            </td>
            <td>
              <button
                *ngIf="isVerified(data)"
                appPermission
                [resource]="resource"
                [enableActions]="'canUpdate'"
                pButton
                [pTooltip]="data.user ? 'Usuario Generado' : 'Generar Usuario'"
                tooltipPosition="top"
                type="button"
                [icon]="data.user ? 'pi pi-user' : 'pi pi-user-plus'"
                class="p-button-rounded p-button-warning p-button-text"
                (click)="selectedItem(data, 'generateUser')"
              ></button>

              <button
                  appPermission
                  [resource]="resource"
                  [enableActions]="'canView'"
                  pTooltip="Ver"
                  tooltipPosition="top"
                pButton
                class="p-button-rounded p-button-info p-button-text ml-1"
                  (click)='readRequest(data)'
              >
                <i class="pi pi-eye"></i>
              </button>

                <button
                    appPermission
                    [resource]="resource"
                    [enableActions]="'canUpdate'"
                    pTooltip="Editar"
                    tooltipPosition="top"
                    pButton
                    class="p-button-rounded p-button-success p-button-text ml-1"
                    (click)='editRequest(data)'
                >
                    <i class="pi pi-pencil"></i>
                </button>
              <button
                appPermission
                [resource]="resource"
                [enableActions]="'canDelete'"
                pButton
                pTooltip="Eliminar"
                tooltipPosition="top"
                type="button"
                icon="pi pi-times"
                class="p-button-rounded p-button-danger p-button-text"
                (click)="selectedItem(data, 'delete')"
              ></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
