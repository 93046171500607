import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from '../../shared';
import { ISpecie } from '../models/interfaces/specie.interface';

@Injectable({
  providedIn: 'root',
})
export class SpecieService extends BaseService<ISpecie> {
  constructor(private readonly httpService: BaseHttpService) {
    super(httpService, 'species');
  }
}
