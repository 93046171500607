import { ProjectService } from '../../itcp';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from '../../shared/services/toaster.service';
import {CryptoService, SettingsService} from '../../shared';
import {ProjectProgrammerModel} from "./project-programmer.model";
import {PROJECT_READ_ONLY} from "../../shared/constants";

export abstract class ProjectMonitoringModel extends ProjectProgrammerModel{

    protected constructor(route: ActivatedRoute, service: ProjectService, tService: ToasterService, setService: SettingsService, crypto: CryptoService) {
        super(route, service, tService, setService, crypto);
    }

    override setSteps() {

        this.steps = [
            {
                step: 1,
                text: 'Reporte Físico',
                icon: 'pi pi-sliders-v',
                path: `/tracking/monitoring/${this.params['id']}/physical`,
            },
            {
                step: 2,
                text: 'Reporte Financiero',
                icon: 'pi pi-dollar',
                path: `/tracking/monitoring/${this.params['id']}/financial`,
            }
        ]
    }

    reporterReadOnly() {
        //Only can report if project is programmed.
        return this.isReadOnly(PROJECT_READ_ONLY.MONITORING) || !this.isProgrammed;
    }
}
