import {Component, OnDestroy, OnInit} from '@angular/core';
import {ProjectModel} from "../../../models";
import {ActivatedRoute} from "@angular/router";
import {ProjectService} from "../../../services";
import {FormBuilder, Validators} from "@angular/forms";
import {ToasterService} from "../../../../shared/services/toaster.service";
import {CryptoService} from "../../../../shared";

@Component({
    selector: 'app-others',
    templateUrl: './others.component.html',
    styleUrls: ['./others.component.scss']
})
export class OthersComponent extends ProjectModel implements OnInit, OnDestroy {
    private step: number = 10;
    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private crypto: CryptoService,
    ) {
        super(activatedRoute, projectService, tService, crypto);
    }

    ngOnInit(): void {
        this.readParams();
        this.setActive(this.step);
        this.service.get(this.params['id']).then((project) => {
            const { id, name, details } = project || {};
            this.currentProject = { ...project };
            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required],
                details: [details, [Validators.required, Validators.maxLength(3000)]],
            });
            this.disableFormIfSubmitted();
            this.showContent = true;
        });
    }


    ngOnDestroy() {
        this.cleanParams();
    }
}
