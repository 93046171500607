<div class="card border-0">
    <div *ngIf="!showForm" class='text-center generating-user'>
        <div class="field col-12">
            <p-skeleton styleClass="mb-2"></p-skeleton>
            <p-skeleton height="2rem" styleClass="mb-2"></p-skeleton>
        </div>

        <div class="field col-12">
            <p-skeleton styleClass="mb-2"></p-skeleton>
            <p-skeleton height="2rem" styleClass="mb-2"></p-skeleton>
        </div>

        <div class="field col-12">
            <p-skeleton styleClass="mb-2"></p-skeleton>
            <p-skeleton height="2rem" styleClass="mb-2"></p-skeleton>
        </div>
    </div>
    <form *ngIf="showForm" class="form-signin" [formGroup]="formGroup">
        <div class="grid">
            <p-message *ngIf="emailSent" severity="success" text="El correo electrónico de activación ha sido enviado al usuario.">
            </p-message>
            <div class="field lg:col-12 md:col-12 sm:col-12">
                <label>Nombre</label>
                <input id="firstName"
                       pInputText
                       formControlName="primerNombre"
                       type="text"
                       class="text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full" />
            </div>

            <div class="field lg:col-12 md:col-12 sm:col-12">
                <label>Nombre de Usuario</label>
                <input id="username"
                       pInputText
                       formControlName="nombreUsuario"
                       type="text"
                       class="text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full" />
            </div>

            <div class="field lg:col-12 md:col-12 sm:col-12">
                <label>Correo Electrónico</label>
                <input id="email"
                       pInputText
                       formControlName="correo"
                       type="text"
                       class="text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full" />
            </div>
        </div>
    </form>
</div>
<div class="p-dialog-footer">
    <button *ngIf='config.data.user' pButton type="button" label="Re-enviar Activacion" class="p-button-warning" (click)="resendEmail()" [disabled]='emailSent'></button>
    <button pButton type="button" label="Cerrar" class="p-button-secondary" (click)="closeModal()"></button>
</div>
