import { Component, OnInit, ViewChild } from '@angular/core';
import { IProject } from '../../models';
import { Table } from 'primeng/table';
import { Subject, takeUntil } from 'rxjs';
import { GenericFunctions } from '../../../shared/services/generic-functions';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ToasterService } from '../../../shared/services/toaster.service';
import {PROJECT_SCORES, ProjectService} from '../../services';
import { Router } from '@angular/router';
import { Constants } from '../../../shared/constants';
import {ProjectFlow, ProjectStagesEnum, ProjectStatusType} from '../../../shared';
import { ModalComponent } from './modal/modal.component';
import {FormBuilder, Validators} from "@angular/forms";

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss'],
})
export class RankingComponent implements OnInit {
  loading = false;
  data: IProject[] = [];
  resource = Constants.RESOURCES.ITCP_RANKING;
  SCORES = PROJECT_SCORES;
  breadcrumbs: MenuItem[] = [
    { label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home'] },
    { label: ' ITCP', routerLink: ['/itcp'] },
    { label: ' Ranking de Proyectos' },
  ];

  @ViewChild('dtTable') table!: Table;
  private onDestroy$ = new Subject();
  public ref!: DynamicDialogRef;
  private modal: DynamicDialogConfig<IProject> = {
    ...Constants.MODAL.default,
  };

  constructor(
    private readonly genericFunctions: GenericFunctions,
    public readonly dialogService: DialogService,
    public readonly confirmationService: ConfirmationService,
    private readonly toasterService: ToasterService,
    private readonly service: ProjectService,
    private fb: FormBuilder,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.getAll();
  }

  getAll(): void {
    this.loading = true;

    this.service
      .getAll({
        params: {
          status: ProjectFlow.itcpStatusesForRanking(),
          stage: ProjectStagesEnum.ITCP,
        },
      })
      .then((resp) => {
        this.data = [...resp];
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => (this.loading = false));
  }

  reload() {
    this.getAll();
  }

  setFilter(event: any, type: string, query: string, column: string = ''): void {
    if (type === 'filterGlobal') {
      this.table.filterGlobal(event.target.value, query);
    } else if (type === 'filter') {
      this.table.filter(event.target.value, column, query);
    }
  }

  getState(obj: IProject) {
      const score = obj.score || 0;
    return score > this.SCORES.ITCP_APPROVE ? 'HABILITADO' : 'DESHABILITADO';
  }

  notifyProject(project: IProject) {
    this.modal.data = project;
    this.modal.header = 'Notificar Proyecto';
    this.ref = this.dialogService.open(ModalComponent, this.modal);
    this.ref.onClose.pipe(takeUntil(this.onDestroy$)).subscribe((resp) => {
      if (resp) this.reload();
    });
  }

  approveRejectProject(project: IProject, approve: boolean) {
      const status = approve ? Constants.PROJECT_STATUSES.APPROVED : Constants.PROJECT_STATUSES.REJECTED;
      const formGroup = this.fb.group({
          id: [project.id],
          name: [project.name, Validators.required],
          status: [status, Validators.required],
      });

      const postData: any = formGroup.value;

      if (!formGroup.value.id) return;
      this.service
          .update(postData, postData.id)
          .then((resp: IProject) => {
              project.status = status;
              this.toasterService.success('Los datos del Proyecto fueron actualizados con éxito');
          })
          .catch((err) => {
              console.error(err.error.message);
              this.toasterService.error('Se ha producido un error inténtelo más tarde o contáctese con el Administrador.');
          }).finally(() => {
          project.status = status;
      });
  }

  approved(status: ProjectStatusType) {
      return [
          Constants.PROJECT_STATUSES.APPROVED,
          Constants.PROJECT_STATUSES.CONSOLIDATED,
          Constants.PROJECT_STATUSES.CONSOLIDATED_SUBMITTED,
          Constants.PROJECT_STATUSES.ASSIGNED_EDTP].includes(status);
  }

  rejected(status: ProjectStatusType) {
      return Constants.PROJECT_STATUSES.REJECTED === status;
  }

  wasSentToEDTP(status: ProjectStatusType) {
      return [
          Constants.PROJECT_STATUSES.CONSOLIDATED,
          Constants.PROJECT_STATUSES.CONSOLIDATED_SUBMITTED,
          Constants.PROJECT_STATUSES.ASSIGNED_EDTP].includes(status);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(undefined);
    this.onDestroy$.complete();
  }
}
