
export interface IEdtpCompliance {
    id?: number | string;
    code: string;
    title: string;
    description: string;
    parentCode: string;
    children?: IEdtpCompliance[];
    parent?: IEdtpCompliance[];
}
