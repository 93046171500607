import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IProjectAct, ProjectModel } from '../../../models';
import { ActivatedRoute } from '@angular/router';
import { ProjectActService, ProjectService } from '../../../services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { Table } from 'primeng/table';
import {AttachmentService, CryptoService} from '../../../../shared';

@Component({
    selector: 'app-deals',
    templateUrl: './deals.component.html',
    styleUrls: ['./deals.component.scss']
})
export class DealsComponent extends ProjectModel implements OnInit, OnDestroy {
    private step: number = 6;
    acts: IProjectAct[] =[];
    private currentAct: IProjectAct | undefined;
    editing: boolean = false;
    cloned: { [s: string]: IProjectAct; } = {};
    @ViewChild('dt') table!: Table;
    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly actService: ProjectActService,
        public readonly attachmentService: AttachmentService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private crypto: CryptoService,
    ) {
        super(activatedRoute, projectService, tService, crypto);
    }

    ngOnInit(): void {
        this.readParams();
        this.setActive(this.step);
        this.service.get(this.params['id']).then((project) => {
            const { id, name, acts } = project || {};
            this.currentProject = { ...project };
            if (acts?.length) this.acts = acts;

            this.formGroup = this.fb.group({
                id: [id],
                name: [name, Validators.required],
            });
            this.disableFormIfSubmitted();
            this.showContent = true;
        });
    }

    onRowEditInit(act: IProjectAct) {
        this.currentAct = act;
        this.editing = true;
        if (!act?.id) return;
        this.cloned[act.id] = {...act};
    }

    getForm(act: IProjectAct) {
        if (!act?.id) return;
        const actFileId = this.currentAct?.actFileId ? this.currentAct.actFileId : act.actFileId;
        const actForm: FormGroup = this.fb.group({
            community: [act.community, Validators.required],
            actFileId: [actFileId],
            projectId: [act.projectId, Validators.required],
        });

        return actForm;
    }

    onRowEditSave(act: IProjectAct) {
        if (!act?.id) return;

        const actForm = this.getForm(act);

        if (actForm?.valid) {
            delete this.cloned[act.id];

            if (act?.id.toString().includes('new_')) {
                const index = this.acts.indexOf(act);
                this.actService.store(actForm.value).then((resp) => {
                    this.acts[index] = resp;
                    this.toasterService.success('El Acta de Compromiso fue creada satisfactoriamente.');
                });

            } else {
                const id = parseInt(act.id.toString());
                this.actService.update(actForm.value, id).then((resp) => {
                    this.toasterService.success('El Acta de Compromiso fue actualizada satisfactoriamente.');
                })
            }

        }
        else {
            this.toasterService.error('No se pudo guardar los datos son inválidos!');
        }

        this.editing = false;
    }

    onRowEditCancel(act: IProjectAct, index: number) {
        this.editing = false;
        this.currentAct = undefined;
        if (!act?.id) return;

        if (this.cloned[act.id]) {
            this.acts[index] = this.cloned[act.id];
            delete this.cloned[act.id];
        }

        if (act?.id.toString().includes('new_')) {
            this.acts.pop();
        }
    }

    onNew() {
        const a: IProjectAct = {
            community: '',
            actFileId: undefined,
            projectId: parseInt(this.params['id']),
            id: `new_${new Date().getTime()}`}

        this.acts.push(a)
        this.table.initRowEdit(this.table.value[this.acts.length - 1]);
        this.currentAct = this.table.value[this.acts.length - 1];
        this.editing = true;
    }

    onUploaded(data: any) {
        const { files } = data;
        if (files.length && this.currentAct) {
            this.currentAct.actFileId = files[0].id;
        }
    }

    ngOnDestroy() {
        this.cleanParams();
    }
}
