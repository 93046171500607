import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
    IProjectCompliance,
    IProjectComplianceGroup,
} from '../../../../models';
import { Table } from 'primeng/table';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from '../../../../../shared/services/toaster.service';
import {ProjectComplianceService} from "../../../../services";

@Component({
    selector: 'app-obs-group',
    templateUrl: './obs-group.component.html',
    styleUrls: ['./obs-group.component.scss']
})
export class ObsGroupComponent implements OnInit {
    @Input() group!: IProjectComplianceGroup;
    @Input() canEdit: boolean = false;

    clonedCompliances: { [s: string]: IProjectCompliance; } = {};

    @ViewChild('dt') table!: Table;
    constructor(
        private readonly complianceService: ProjectComplianceService,
        private fb: FormBuilder,
        private toasterService: ToasterService,
    ) {}

    ngOnInit(): void {
        if(!this.group.children)
            this.group.children = [];
    }

    onRowEditInit(obj: IProjectCompliance) {
        if (!obj?.id) return;
        this.clonedCompliances[obj.id] = {...obj};
    }

    onRowEditSave(obj: IProjectCompliance) {
        if (!obj?.id) return;

        const form = this.itemForm(obj);

        if (form.valid) {
            delete this.clonedCompliances[obj.id];

            if (obj?.id.toString().includes('new_')) {
                const index = this.group.children.indexOf(obj);
                this.complianceService.store(form.value).then((resp) => {
                    this.group.children[index] = resp;
                    this.toasterService.success('El Registro fue creado satisfactoriamente.');
                });

            } else {
                const id = parseInt(obj.id.toString());
                this.complianceService.update(form.value, id).then((resp) => {
                    this.toasterService.success('El Registro fue actualizado satisfactoriamente.');
                })
            }

        } else {
            this.toasterService.error('No se pudo guardar, los datos son inválidos!');
        }
    }

    onRowEditCancel(obj: IProjectCompliance, index: number) {
        if (!obj?.id) return;

        if (this.clonedCompliances[obj.id]) {
            this.group.children[index] = this.clonedCompliances[obj.id];
            delete this.clonedCompliances[obj.id];
        }

        if (obj?.id.toString().includes('new_')) {
            this.group.children = this.group.children.filter(o => o.id !== obj.id);
        }
    }

    itemForm(obj: IProjectCompliance) {
        const form: FormGroup = this.fb.group({
            projectId: [obj.projectId, Validators.required],
            groupId: [obj.groupId, Validators.required],
            compliance: [obj.compliance, Validators.required],
            submitted: [obj.submitted],
            observation: [obj.observation, Validators.required],
            fixed: [obj.fixed, Validators.required],
            fixingObservation: [obj.fixingObservation, Validators.required],
        });
        return form;
    }
}
