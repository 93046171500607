import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Constants } from '../shared/constants';
import {
    AssignmentComponent,
    FormulationComponent,
    EvalComponent,
    GoalsComponent,
    LineComponent,
    Component1Component,
    Component2Component,
    Component3Component,
    Component4Component,
    LineTIIComponent,
    LineTIIIComponent,
    LineTIVComponent,
    LineTVComponent,
    C1TIIComponent,
    C1TIVComponent,
    C1TVComponent,
    C2TIIComponent,
    C2TIVComponent,
    C2TVComponent,
    C3TIIComponent,
    C3TIIIComponent,
    C2TIIIComponent,
    C3TIVComponent,
    C1TIIIComponent,
    C3TVComponent,
    C4TIIComponent,
    C4TIIIComponent,
    C4TIVComponent,
    C4TVComponent,
    BudgetComponent,
    BudgetCompComponent,
    BudgetVipfeComponent,
    CostsComponent,
    StructureComponent,
    DoneComponent,
    AssessmentComponent,
    ObservationsComponent,
    CheckContentComponent,
    QuantitativeComponent,
    WeighingComponent,
    RankingComponent, SchedulerComponent, CheckContentObsComponent,
} from './components';
import {ActivitiesComponent} from "./components/scheduler/activities/activities.component";
import { CostEfficiencyComponent } from './components/eval/cost-efficiency/cost-efficiency.component';

const routes: Routes = [
    { path: '', component: AssignmentComponent, data: { moduleNameCode: Constants.RESOURCES.EDTP_ASIGNMENT }},
    { path: 'assignments', component: AssignmentComponent, data: { moduleNameCode: Constants.RESOURCES.EDTP_ASIGNMENT }},
    { path: 'form', component: FormulationComponent, data: { moduleNameCode: Constants.RESOURCES.EDTP_FORM }},
    {
        path: 'eval',
        data: { moduleNameCode: Constants.RESOURCES.EDTP_EVAL },
        children: [
            { path :'',  component: EvalComponent},
            { path: ':id/cost-efficiency', component: CostEfficiencyComponent }
        ],
    },
    {
        path: 'projects',
        data: { moduleNameCode: Constants.RESOURCES.EDTP_FORM },
        children: [
            { path: '', component: FormulationComponent },
            { path: ':id/goals', component: GoalsComponent },
            { path: ':id/line', component: LineComponent },
            { path: ':id/line/t-ii', component: LineTIIComponent },
            { path: ':id/line/t-iii', component: LineTIIIComponent },
            { path: ':id/line/t-iv', component: LineTIVComponent },
            { path: ':id/line/t-v', component: LineTVComponent },
            { path: ':id/c1', component: Component1Component },
            { path: ':id/c1/t-ii', component: C1TIIComponent },
            { path: ':id/c1/t-iii', component: C1TIIIComponent },
            { path: ':id/c1/t-iv', component: C1TIVComponent },
            { path: ':id/c1/t-v', component: C1TVComponent },
            { path: ':id/c2', component: Component2Component },
            { path: ':id/c2/t-ii', component: C2TIIComponent },
            { path: ':id/c2/t-iii', component: C2TIIIComponent },
            { path: ':id/c2/t-iv', component: C2TIVComponent },
            { path: ':id/c2/t-v', component: C2TVComponent },
            { path: ':id/c3', component: Component3Component },
            { path: ':id/c3/t-ii', component: C3TIIComponent },
            { path: ':id/c3/t-iii', component: C3TIIIComponent },
            { path: ':id/c3/t-iv', component: C3TIVComponent },
            { path: ':id/c3/t-v', component: C3TVComponent },
            { path: ':id/c4', component: Component4Component },
            { path: ':id/c4/t-ii', component: C4TIIComponent },
            { path: ':id/c4/t-iii', component: C4TIIIComponent },
            { path: ':id/c4/t-iv', component: C4TIVComponent },
            { path: ':id/c4/t-v', component: C4TVComponent },
            { path: ':id/budget', component: BudgetComponent },
            { path: ':id/budget-comp', component: BudgetCompComponent },
            { path: ':id/budget-vipfe', component: BudgetVipfeComponent },
            { path: ':id/costs', component: CostsComponent },
            { path: ':id/structure', component: StructureComponent },
            { path: ':id/done', component: DoneComponent },
        ],
    },
    {
        path: 'assessment',
        data: { moduleNameCode: Constants.RESOURCES.EDTP_EVAL },
        children: [
            { path: '', component: AssessmentComponent },
            { path: ':id/check-content', component: CheckContentComponent },
            { path: ':id/obs-step1', component: CheckContentObsComponent },
            { path: ':id/obs-step2', component: ObservationsComponent },
            { path: ':id/quantitative', component: QuantitativeComponent },
            { path: ':id/weighing', component: WeighingComponent },
        ],
    },
    { path: 'ranking', component: RankingComponent, data: { moduleNameCode: Constants.RESOURCES.EDTP_RANKING }},
    { path: 'scheduler', data: { moduleNameCode: Constants.RESOURCES.EDTP_SCHEDULER },
        children: [
            { path: '', component: SchedulerComponent },
            { path: ':id/activities', component: ActivitiesComponent },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class EDTPRoutingModule {
}
