import {Component, OnInit, ViewChild} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {shuffle} from 'lodash';
import {Table} from 'primeng/table';
import {IProject, IProjectAssignment, ProjectService} from '../../../itcp';
import {User} from '../../../core/user/interfaces';
import {UserService} from '../../../core/user/user.service';
import {Constants} from '../../../shared/constants';
import {ProcessTypes, ProjectFlow, ProjectStagesEnum} from '../../../shared';
import {GenericFunctions} from '../../../shared/services/generic-functions';
import {ProjectAssignment} from '../../services';
import {ProjectAssignmentsService} from '../../../shared/services/project-assignments.service';

@Component({
  selector: 'app-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.scss'],
})
export class AssignmentComponent implements OnInit {
  resource = Constants.RESOURCES.EDTP_ASIGNMENT;
  projects: IProject[] = []; //Project to assign
  financialTechs: User[] = []; //Users(TEC-FIN-PLAN)
  physicalTechs: User[] = []; //Users(TEC-FIS-PLAN)

  randomPhysicalTechs: User[] = [];
  randomFinancialTechs: User[] = [];

  assignedProjects: ProjectAssignment[] = [];
  assignedAndSelectedProjects: ProjectAssignment[] = [];
  storedAssignedProjects: IProject[] = [];

  loading: boolean = false;
  techniciansPermissions = [
    { label: 'Revisar', value: Constants.TECHNICIAN_PERMISSIONS.REVIEW },
    { label: 'Valorar', value: Constants.TECHNICIAN_PERMISSIONS.EVAL },
    { label: 'Formular', value: Constants.TECHNICIAN_PERMISSIONS.FORMULATE },
    { label: 'Revisar, Formular y Valorar', value: Constants.TECHNICIAN_PERMISSIONS.REVIEW_EVAL },
  ];

  breadcrumbs: MenuItem[] = [
    { label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home'] },
    { label: ' EDTP', routerLink: ['/edtp'] },
    { label: ' Asignación de Técnicos' },
  ];
  @ViewChild('dt') table!: Table;

  constructor(
    private readonly projectService: ProjectService,
    private readonly userService: UserService,
    private readonly assignmentService: ProjectAssignmentsService
  ) {}

  getFullName(user: User): string {
    return GenericFunctions.getUserFullName(user);
  }

  ngOnInit(): void {
    this.loadAll();
  }

  loadAll() {
    this.loading = true;
    //Any project than score is greater than 70 and/or has been notified
    this.projectService
      .getAll({ params: { status: ProjectFlow.edtpStatusesForAssignments() } })
      .then((resp) => {
        this.projects = [...resp];
        return this.projectService.getAll({
          params: { assignments: true, status: ProjectFlow.edtpStatusesForAssigned()},
        });
      })
      .then((resp) => {
        this.storedAssignedProjects = [...resp];
        return this.userService.getUsers(ProcessTypes.APROVE, [
          Constants.ROLES.TEC_FIN_PLAN,
          Constants.ROLES.TEC_FIS_PLAN,
        ]);
      })
      .then((resp) => {
        this.physicalTechs = resp?.filter((u) => u.usuarioRoles[0]?.rol?.nombreRol === Constants.ROLES.TEC_FIS_PLAN);
        this.financialTechs = resp?.filter((u) => u.usuarioRoles[0]?.rol?.nombreRol === Constants.ROLES.TEC_FIN_PLAN);
      })
      .finally(() => {
        this.loading = false;
        this.randomize();
      });
  }

  onChange(event: any, assignedProject: ProjectAssignment, type: 'financial' | 'physical') {
    if (type === 'physical') {
      assignedProject.physicalTech = event.value;
    } else if (type === 'financial') {
      assignedProject.financialTech = event.value;
    }
  }

  randomize() {
    this.assignedProjects = [];
    this.randomPhysicalTechs = shuffle(this.physicalTechs);
    this.randomFinancialTechs = shuffle(this.financialTechs);

    let index = 0;
    let financialIndex = 0;
    let physicalIndex = 0;
    for (let project of this.projects) {
      const financialTech = this.randomFinancialTechs[financialIndex];
      const physicalTech = this.randomPhysicalTechs[physicalIndex];

      if (project && financialTech && physicalTech) {
        const assigned: ProjectAssignment = {
          id: index + 1,
          project,
          financialTech,
          physicalTech,
        };

        this.assignedProjects.push(assigned);
      }
      index++;
      physicalIndex < this.randomPhysicalTechs.length ? physicalIndex++ : (physicalIndex = 0);
      financialIndex < this.randomFinancialTechs.length ? financialIndex++ : (financialIndex = 0);
    }
  }

  setFilter(event: any, type: string, query: string, column: string = ''): void {
    if (type === 'filterGlobal') {
      this.table.filterGlobal(event.target.value, query);
    } else if (type === 'filter') {
      this.table.filter(event.target.value, column, query);
    }
  }

  save() {
    this.assignmentService.store(this.assignedAndSelectedProjects, ProjectStagesEnum.EDTP, false).then((resp) => {
      if (resp.length) this.loadAll();
    });
  }

  changePermissions(event: any, assignment: IProjectAssignment) {
    this.assignmentService.update(assignment).then();
  }

  onSelectionChange(value = []) {
    this.assignedAndSelectedProjects = [...value];
  }
}
