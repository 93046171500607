import { Component, OnDestroy, OnInit } from '@angular/core';
import { BudgetSummaryHelper, ProjectEdtpModel } from '../../../models';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../../itcp';
import { BudgetSummaryType, ComponentType, FinancingStructureType, ProjectBudgetService } from '../../../services';
import { CryptoService, SettingsService } from '../../../../shared';
import { FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { LayoutService } from '../../../../shared/services/app.layout.service';
import { IProjectBudget } from '../../../models/interfaces/project-budget.interface';

@Component({
    selector: 'app-structure',
    templateUrl: './structure.component.html',
    styleUrls: ['./structure.component.scss'],
})
export class StructureComponent extends ProjectEdtpModel implements OnInit, OnDestroy {
    private step: number = 26;
    projectBudgetsComponents: FinancingStructureType[] = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly projectBudgetsService: ProjectBudgetService,
        private readonly setService: SettingsService,
        private fb: FormBuilder,
        private tService: ToasterService,
        private layoutService: LayoutService,
        private crypto: CryptoService
    ) {
        super(activatedRoute, projectService, tService, setService, crypto);
    }

    ngOnInit(): void {
        this.layoutService.showMenuDesktop(false);
        this.readParams();
        this.setActive(this.step);
        this.service
            .get(this.params['id'])
            .then((project) => {
                const { id, name } = project || {};
                this.currentProject = { ...project };
                this.formGroup = this.fb.group({
                    id: [id],
                    name: [name, Validators.required],
                });
                const projectId = id || 0;
                return this.projectBudgetsService.getAll({ params: { projectId } });
            })
            .then((resp) => {
                this.loadSummary(resp);
            })
            .finally(() => {
                this.setSteps();
                this.setActive(this.step); //We need to reload the steps and put as active the current step
                this.disableFormIfSubmitted();
                this.showContent = true;
            });
    }

    loadSummary(budgets: IProjectBudget[]) {
        BudgetSummaryHelper.setBudgetAmounts(budgets);
        this.addC1Summary(budgets.filter((b) => b.componentType === 'COMPONENT1'));
        this.addC2Summary(budgets.filter((b) => b.componentType === 'COMPONENT2'));
        this.addC3Summary(budgets.filter((b) => b.componentType === 'COMPONENT3'));
        this.addC4Summary(budgets.filter((b) => b.componentType === 'COMPONENT4'));
        this.addC5Summary(budgets.filter((b) => b.componentType === 'COMPONENT5'));
        this.addAdminSummary(budgets.filter((b) => b.componentType === 'ADMINISTRATION'));
        this.addAuditSummary(budgets.filter((b) => b.componentType === 'AUDIT'));
    }

    private buildFinancingStructureItem(type: ComponentType) {
        console.log('PR', this.currentProject);
        const year1: BudgetSummaryType = {
            description: '',
            entity: 0,
            entityInKind: 0,
            owner: 0,
            total: 0,
        };

        const year2: BudgetSummaryType = {
            description: '',
            entity: 0,
            entityInKind: 0,
            owner: 0,
            total: 0,
        };

        const year3: BudgetSummaryType = {
            description: '',
            entity: 0,
            entityInKind: 0,
            owner: 0,
            total: 0,
        };

        const totals: BudgetSummaryType = {
            description: '',
            entity: 0,
            entityInKind: 0,
            owner: 0,
            total: 0,
        };

        const item: FinancingStructureType = {
            description: '',
            type,
            totals,
            year1,
        };

        if (this.isValidYear(2)) item.year2 = year2;
        if (this.isValidYear(3)) item.year3 = year3;

        return item;
    }

    private addC1Summary(budgets: IProjectBudget[]) {
        const item: FinancingStructureType = this.buildFinancingStructureItem('COMPONENT1');
        item.description = 'COMPONENTE NRO. 1: CONSTRUCCIÓN DE LA INFRAESTRUCTURA PRODUCTIVA';
        budgets.map((b) => {
            if (b.componentType === 'COMPONENT1') {
                item.year1.owner += b.ownerY1;
                item.year1.entity += b.entityY1;
                item.year1.entityInKind += b.entityInKindY1;
                item.year1.total += b.ownerY1 + b.entityY1 + b.entityInKindY1;
                if (item.year2) {
                    item.year2.owner += b.ownerY2;
                    item.year2.entity += b.entityY2;
                    item.year2.entityInKind += b.entityInKindY2;
                    item.year2.total += b.ownerY2 + b.entityY2 + b.entityInKindY2;
                }
                if (item.year3) {
                    item.year3.owner += b.ownerY3;
                    item.year3.entity += b.entityY3;
                    item.year3.entityInKind += b.entityInKindY3;
                    item.year3.total += b.ownerY3 + b.entityY3 + b.entityInKindY3;
                }

                item.totals.owner += b.totals.owner;
                item.totals.entity += b.totals.entity;
                item.totals.entityInKind += b.totals.entityInKind;
                item.totals.total += b.totals.total;
            }
        });

        this.projectBudgetsComponents.push(item);
    }

    private addC2Summary(budgets: IProjectBudget[]) {
        const item: FinancingStructureType = this.buildFinancingStructureItem('COMPONENT2');
        item.description = 'COMPONENTE NRO. 2: PRODUCCIÓN DE PLANTINES';
        budgets.map((b) => {
            if (b.componentType === 'COMPONENT2') {
                item.year1.owner += b.ownerY1;
                item.year1.entity += b.entityY1;
                item.year1.entityInKind += b.entityInKindY1;
                item.year1.total += b.ownerY1 + b.entityY1 + b.entityInKindY1;
                if (item.year2) {
                    item.year2.owner += b.ownerY2;
                    item.year2.entity += b.entityY2;
                    item.year2.entityInKind += b.entityInKindY2;
                    item.year2.total += b.ownerY2 + b.entityY2 + b.entityInKindY2;
                }

                if (item.year3) {
                    item.year3.owner += b.ownerY3;
                    item.year3.entity += b.entityY3;
                    item.year3.entityInKind += b.entityInKindY3;
                    item.year3.total += b.ownerY3 + b.entityY3 + b.entityInKindY3;
                }
                item.totals.owner += b.totals.owner;
                item.totals.entity += b.totals.entity;
                item.totals.entityInKind += b.totals.entityInKind;
                item.totals.total += b.totals.total;
            }
        });

        this.projectBudgetsComponents.push(item);
    }

    private addC3Summary(budgets: IProjectBudget[]) {
        const item: FinancingStructureType = this.buildFinancingStructureItem('COMPONENT3');
        item.description = 'COMPONENTE NRO. 3: REFORESTACIÓN E IMPLEMENTACIÓN DE SISTEMAS AGROFORESTALES';
        budgets.map((b) => {
            if (b.componentType === 'COMPONENT3') {
                item.year1.owner += b.ownerY1;
                item.year1.entity += b.entityY1;
                item.year1.entityInKind += b.entityInKindY1;
                item.year1.total += b.ownerY1 + b.entityY1 + b.entityInKindY1;
                if (item.year2) {
                    item.year2.owner += b.ownerY2;
                    item.year2.entity += b.entityY2;
                    item.year2.entityInKind += b.entityInKindY2;
                    item.year2.total += b.ownerY2 + b.entityY2 + b.entityInKindY2;
                }

                if (item.year3) {
                    item.year3.owner += b.ownerY3;
                    item.year3.entity += b.entityY3;
                    item.year3.entityInKind += b.entityInKindY3;
                    item.year3.total += b.ownerY3 + b.entityY3 + b.entityInKindY3;
                }
                item.totals.owner += b.totals.owner;
                item.totals.entity += b.totals.entity;
                item.totals.entityInKind += b.totals.entityInKind;
                item.totals.total += b.totals.total;
            }
        });

        this.projectBudgetsComponents.push(item);
    }
    private addC4Summary(budgets: IProjectBudget[]) {
        const item: FinancingStructureType = this.buildFinancingStructureItem('COMPONENT4');
        item.description = 'COMPONENTE NRO. 4: CAPACITACIÓN';
        budgets.map((b) => {
            if (b.componentType === 'COMPONENT4') {
                item.year1.owner += b.ownerY1;
                item.year1.entity += b.entityY1;
                item.year1.entityInKind += b.entityInKindY1;
                item.year1.total += b.ownerY1 + b.entityY1 + b.entityInKindY1;

                if (item.year2) {
                    item.year2.owner += b.ownerY2;
                    item.year2.entity += b.entityY2;
                    item.year2.entityInKind += b.entityInKindY2;
                    item.year2.total += b.ownerY2 + b.entityY2 + b.entityInKindY2;
                }

                if (item.year3) {
                    item.year3.owner += b.ownerY3;
                    item.year3.entity += b.entityY3;
                    item.year3.entityInKind += b.entityInKindY3;
                    item.year3.total += b.ownerY3 + b.entityY3 + b.entityInKindY3;
                }
                item.totals.owner += b.totals.owner;
                item.totals.entity += b.totals.entity;
                item.totals.entityInKind += b.totals.entityInKind;
                item.totals.total += b.totals.total;
            }
        });

        this.projectBudgetsComponents.push(item);
    }
    private addC5Summary(budgets: IProjectBudget[]) {
        const item: FinancingStructureType = this.buildFinancingStructureItem('COMPONENT5');
        item.description = 'COMPONENTE NRO. 5: ASISTENCIA TÉCNICA';
        budgets.map((b) => {
            if (b.componentType === 'COMPONENT5') {
                item.year1.owner += b.ownerY1;
                item.year1.entity += b.entityY1;
                item.year1.entityInKind += b.entityInKindY1;
                item.year1.total += b.ownerY1 + b.entityY1 + b.entityInKindY1;

                if (item.year2) {
                    item.year2.owner += b.ownerY2;
                    item.year2.entity += b.entityY2;
                    item.year2.entityInKind += b.entityInKindY2;
                    item.year2.total += b.ownerY2 + b.entityY2 + b.entityInKindY2;
                }

                if (item.year3) {
                    item.year3.owner += b.ownerY3;
                    item.year3.entity += b.entityY3;
                    item.year3.entityInKind += b.entityInKindY3;
                    item.year3.total += b.ownerY3 + b.entityY3 + b.entityInKindY3;
                }
                item.totals.owner += b.totals.owner;
                item.totals.entity += b.totals.entity;
                item.totals.entityInKind += b.totals.entityInKind;
                item.totals.total += b.totals.total;
            }
        });

        this.projectBudgetsComponents.push(item);
    }
    private addAdminSummary(budgets: IProjectBudget[]) {
        const item: FinancingStructureType = this.buildFinancingStructureItem('ADMINISTRATION');
        item.description = 'ADMINISTRACIÓN';
        budgets.map((b) => {
            if (b.componentType === 'ADMINISTRATION') {
                item.year1.owner += b.ownerY1;
                item.year1.entity += b.entityY1;
                item.year1.entityInKind += b.entityInKindY1;
                item.year1.total += b.ownerY1 + b.entityY1 + b.entityInKindY1;

                if (item.year2) {
                    item.year2.owner += b.ownerY2;
                    item.year2.entity += b.entityY2;
                    item.year2.entityInKind += b.entityInKindY2;
                    item.year2.total += b.ownerY2 + b.entityY2 + b.entityInKindY2;
                }

                if (item.year3) {
                    item.year3.owner += b.ownerY3;
                    item.year3.entity += b.entityY3;
                    item.year3.entityInKind += b.entityInKindY3;
                    item.year3.total += b.ownerY3 + b.entityY3 + b.entityInKindY3;
                }
                item.totals.owner += b.totals.owner;
                item.totals.entity += b.totals.entity;
                item.totals.entityInKind += b.totals.entityInKind;
                item.totals.total += b.totals.total;
            }
        });

        this.projectBudgetsComponents.push(item);
    }
    private addAuditSummary(budgets: IProjectBudget[]) {
        const item: FinancingStructureType = this.buildFinancingStructureItem('AUDIT');
        item.description = 'AUDITORÍA';
        budgets.map((b) => {
            if (b.componentType === 'AUDIT') {
                item.year1.owner += b.ownerY1;
                item.year1.entity += b.entityY1;
                item.year1.entityInKind += b.entityInKindY1;
                item.year1.total += b.ownerY1 + b.entityY1 + b.entityInKindY1;

                if (item.year2) {
                    item.year2.owner += b.ownerY2;
                    item.year2.entity += b.entityY2;
                    item.year2.entityInKind += b.entityInKindY2;
                    item.year2.total += b.ownerY2 + b.entityY2 + b.entityInKindY2;
                }

                if (item.year3) {
                    item.year3.owner += b.ownerY3;
                    item.year3.entity += b.entityY3;
                    item.year3.entityInKind += b.entityInKindY3;
                    item.year3.total += b.ownerY3 + b.entityY3 + b.entityInKindY3;
                }
                item.totals.owner += b.totals.owner;
                item.totals.entity += b.totals.entity;
                item.totals.entityInKind += b.totals.entityInKind;
                item.totals.total += b.totals.total;
            }
        });

        this.projectBudgetsComponents.push(item);
    }

    ngOnDestroy() {
        this.cleanParams();
    }
}
