import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {Constants} from "../shared/constants";
import {
    BasicInfoComponent,
    ContactInfoComponent,
    DoneComponent, ITCPRequestComponent,
    MaeInfoComponent,
    ProjectManagerInfoComponent
} from "./components";

const routes: Routes = [
    {
        path: 'itcp-request/:code',
        data: { moduleNameCode: Constants.RESOURCES.PUBLIC },
        children: [
            { path: '', component: ITCPRequestComponent },
            { path: 'basic-info', component: BasicInfoComponent },
            { path: 'mae-info', component: MaeInfoComponent },
            { path: 'project-manager-info', component: ProjectManagerInfoComponent },
            { path: 'contact-info', component: ContactInfoComponent },
            { path: 'done', component: DoneComponent }
        ]
    }

];


@NgModule({
  imports: [RouterModule.forChild(routes)]
})
export class PublicRoutingModule { }
