import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ProjectService} from "../../../../itcp";
import {StepsEDTPBuilder} from "../../../models/steps-edtp.builder";

@Component({
    selector: 'app-line',
    templateUrl: './line.component.html',
    styleUrls: ['./line.component.scss']
})
export class LineComponent implements OnInit, OnDestroy {
    private sub: any;
    private pId: number = 0;
    loading: boolean = true;
    lineSteps: any [] = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        private service: ProjectService,
        private readonly router: Router,
    ) {
    }

    ngOnInit(): void {
        this.sub = this.activatedRoute.params.subscribe((params) => {
            this.pId = params['id'];
        });
        this.service.get(this.pId).then((project) => {
            const stepsBuilder = new StepsEDTPBuilder(this.pId);
            const typologies = project.typologies?.map(t => t.key);
            const steps: any[] = stepsBuilder.buildSteps(typologies);
            this.lineSteps = steps.filter(s => s.path.includes('line'));
        }).finally(() => {
            this.loading = false;
            if (this.lineSteps.length)
                this.router.navigate([this.lineSteps[0].path]).then();
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

}
