import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';

import {Table} from "primeng/table";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
    IProjectGoalActivity,
} from "../../../../../../edtp";
import {ToasterService} from "../../../../../../shared/services/toaster.service";
import {IProjectActivityBudget} from "../../../../../models";
import {ProjectActivityBudgetService} from "../../../../../services";
import {BudgetItemService} from "../../../../../../shared";
import {IBudgetItem} from "../../../../../../shared/interfaces/budget-item.interface";

@Component({
    selector: 'app-activity-budgets',
    templateUrl: './budgets.component.html',
    styleUrls: ['./budgets.component.scss']
})
export class ActivityBudgetComponent implements OnInit, OnChanges {
    @Input() index: number = 0;
    @Input() activity!: IProjectGoalActivity;
    @Input() budgets: IProjectActivityBudget[] = [];
    @Input() selectedYear!: number;
    @Input() totalProjectDays!: number;
    @Input() editMode: boolean = false;
    @Input() canSave: boolean = false;
    @Input() canProgram: boolean = false;
    @Output() onCancel = new EventEmitter<any>();
    @Output() onSave = new EventEmitter<any>();
    @Output() onEdit = new EventEmitter<any>();
    @Output() onRemove = new EventEmitter<any>();

    budgetItems: IBudgetItem[] = [];
    loading: boolean = false;
    cloned: { [s: string]: IProjectActivityBudget; } = {};

    @ViewChild('dt') table!: Table;

    constructor(
        private readonly budgetService: ProjectActivityBudgetService,
        private readonly budgetItemsService: BudgetItemService,
        private fb: FormBuilder,
        private toasterService: ToasterService,
    ) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.budgetItemsService.getAll().then((resp) => {
            this.budgetItems = [ ...resp ];
        }).finally(() => {
            this.loading = false;
        });
    }


    saveGroup() {
        this.onSave.emit(this.activity);
        this.editMode = false;
    }

    editGroup() {
        this.onEdit.emit(this.activity);
    }
    removeGroup() {
        this.onRemove.emit(this.activity);
    }

    cancelEditGroup() {
        this.onCancel.emit(this.activity);
    }

    onRowEditInit(obj: IProjectActivityBudget) {
        if (!obj?.id) return;
        obj.ownerAmountYear ||= 0;
        obj.entityAmountYear ||= 0;
        obj.entityInKindAmountYear ||= 0;

        this.cloned[obj.id] = {...obj};
    }

    getForm(obj: IProjectActivityBudget) {
        if (!obj?.id) return;
        const form: FormGroup = this.fb.group({
            activityId: [obj.activityId, Validators.required],
            budgetItemCode: [obj.budgetItemCode, Validators.required],
            budgetItemDetails: [obj.budgetItemDetails, Validators.required],
            entity: [obj.entityAmount, [Validators.required, Validators.min(0)]],
            entityInKind: [obj.entityInKindAmount, [Validators.required, Validators.min(0)]],
            owner: [obj.ownerAmount, [Validators.required, Validators.min(0)]],
            ownerYear: [obj.ownerAmountYear, [Validators.required, Validators.min(0)]],
            entityYear: [obj.entityAmountYear, [Validators.required, Validators.min(0)]],
            entityInKindYear: [obj.entityInKindAmountYear, [Validators.required, Validators.min(0)]],
            year: [this.selectedYear, Validators.required],
        });
        return form;
    }

    onRowEditSave(obj: IProjectActivityBudget) {
        if (!obj?.id) return;

        const form = this.getForm(obj);
        if (form?.valid) {
            delete this.cloned[obj.id];
            const index = this.budgets.indexOf(obj);
            if (obj?.id.toString().includes('new_')) {
                this.budgetService.store(form.value).then((resp) => {
                    this.budgets[index] = {
                        ...this.budgets[index],
                        ...resp,
                    };
                    this.toasterService.success('El Registro fue creado satisfactoriamente.');
                });

            } else {
                const id = parseInt(obj.id.toString());
                this.budgetService.update(form.value, id).then((resp) => {
                    this.budgets[index] = {
                        ...this.budgets[index],
                        ...resp,
                    };
                    this.toasterService.success('El Registro fue actualizado satisfactoriamente.');
                })
            }
        } else {
            this.toasterService.error('No se pudo guardar, los datos son inválidos!');
        }
    }

    onRowEditCancel(obj: IProjectActivityBudget, index: number) {
        if (!obj?.id) return;

        if (this.cloned[obj.id]) {
            this.budgets[index] = this.cloned[obj.id];
            delete this.cloned[obj.id];
        }

        if (obj?.id.toString().includes('new_')) {
            this.budgets = this.budgets.filter(o => o.id !== obj.id);
        }
    }

    onNew() {
        const activityId = this.activity.id ? parseInt(this.activity.id.toString()) : 0;
        const ob: IProjectActivityBudget = {
            id: `new_${new Date().getTime()}`,
            activityId,
            budgetItemCode: '',
            budgetItemTitle: '',
            budgetItemDetails: '',
            owner: 0,
            entity: 0,
            entityInKind: 0,
            ownerAmount: 0,
            entityAmount: 0,
            entityInKindAmount: 0,
            ownerAmountYear: 0,
            entityAmountYear: 0,
            entityInKindAmountYear: 0,
            year: this.selectedYear
        }

        this.budgets.push(ob);
        this.table.initRowEdit(this.table.value[this.budgets.length - 1]);
    }

    filterBudgetItems(event: any) {
        this.budgetItemsService
            .getAll({ params: { query: event.query }})
            .then((resp) => {
                this.budgetItems = [ ...resp ];
            });
    }

    onSelectBudgetItem(event: IBudgetItem, obj: IProjectActivityBudget) {
        obj.budgetItemCode = event.code;
        obj.budgetItemTitle = event.title;
        obj.budgetItemDetails = event.description;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.budgets = this.activity.budgets || [];
        if (changes['selectedYear']) {
            this.budgets.map(b => {
                b.ownerAmountYear = 0;
                b.entityAmountYear = 0;
                b.entityInKindAmountYear = 0;
                if (b.yearsProgrammed?.length) {
                    for (const yp of b.yearsProgrammed) {
                        if (yp.year === this.selectedYear){
                            b.ownerAmountYear = yp.ownerAmount;
                            b.entityAmountYear = yp.entityAmount;
                            b.entityInKindAmountYear = yp.entityInKindAmount;
                            break;
                        }
                    }
                }
            });
        }
    }
}
