<app-wizard
    [steps]='steps'
    [current]='currentStep'
    [bodyContentTpl]='projectForm'
    [nextDisabled]='this.formGroup?.invalid'
    (onClickNext)='save($event)'
    [layout]='true'
>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column' [formGroup]='formGroup'>
        <div class='grid'>
            <div class='field col-12'>
                <h3>
                    OTROS ASPECTOS QUE SE CONSIDEREN NECESARIOS, DE ACUERDO A LAS CARACTERISTICAS Y COMPLEJIDAD DEL PROYECTO
                </h3>
            </div>
            <div class='field col-12'>
                <label>Detalle</label>
                <textarea
                    [autofocus]="true"
                    [rows]='12'pInputTextarea [autoResize]='true'
                    formControlName='details'
                    placeholder='Detalles del Proyecto'
                    class='text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                >
                </textarea>
                <div *ngIf="form.details.errors">
                    <p *ngIf="form.details.errors?.required && form.details.touched" class="text-danger">
                        *Campo requerido
                    </p>

                    <p *ngIf="form.details.errors?.maxlength" class="text-danger">
                        *Máximo de caracteres permitido es 3000
                    </p>
                </div>
            </div>
        </div>
    </div>
</ng-template>
