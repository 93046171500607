
<app-wizard
    [steps]="steps"
    [current]="currentStep"
    [bodyContentTpl]="messageDone"
>
</app-wizard>

<ng-template #messageDone>
    <div class="message-description mb-8">
        <p>Felicidades ha completado el formulario satisfactoriamente.</p>
        <p>Una persona se contactará con Ud. proximamente.</p>
    </div>
</ng-template>
