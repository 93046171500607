<label>{{label}}</label>
<div class="custom-dropzone" [class.small]="small" ngx-dropzone [id]="id" [multiple]="multiple" [accept]="accept" (change)="onChange($event)" [disabled]="uploading">
    <ngx-dropzone-label>
        <div class="grid w-full">
            <div class="icon {{small ? 'sm:col-12' : 'sm:col-3' }}" title="Selecciones un archivo o arrastrelo aquí">
                <i class="pi pi-cloud-upload"></i>
            </div>
            <div class="description {{small ? 'sm:col-12' : 'sm:col-9' }}">
                <h5>Seleccione {{ multiple ? 'archivos o arrastrelos ' : 'un archivo o arrastrelo ' }} aquí</h5>
                <small>Tamaño {{ multiple ? 'de cada archivo ' : 'del archivo ' }} no debe ser más de 50MB</small>
            </div>
        </div>

    </ngx-dropzone-label>
    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="remove(f)" class="custom-dropzone-preview">
        <ngx-dropzone-label>
            <div class="preview-icon">
                <i class="{{fileIcon(f.type)}}"></i>
            </div>
            <div class="preview-file-label">
                {{ f.name }}
            </div>
        </ngx-dropzone-label>
    </ngx-dropzone-preview>

    <div class="uploading {{ uploading ? 'active' : ''}}">
        <p>
            <p-progressSpinner [style]="{width: '50px', height: '50px', display:'block', marginTop:'20px'}">
            </p-progressSpinner>
            <span>Cargando Archivos</span>
        </p>
    </div>
</div>

<div *ngIf="errorMessage" class="text-danger">
    {{ errorMessage }}
</div>
