<app-wizard
    [steps]='steps'
    [current]='currentStep'
    [bodyContentTpl]='projectForm'
    [nextDisabled]='isSubmitted || sending'
    (onFinish)='onFinish($event)'
    [layout]='true'
    [lastStep]='true'
>
</app-wizard>

<ng-template #projectForm>
    <div class="message-description">
        <p>Felicidades ha completado el formulario satisfactoriamente.</p>
        <p class='description'>
           {{ isSubmitted ? 'El proyecto ha sido enviado para su revisión' : ' Porfavor haga click en el botón Enviar.'}}
        </p>

        <div *ngIf="sending" class="px-8 py-4">
            <label class="text-base">Enviando.....</label>
            <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
        </div>
    </div>
</ng-template>
