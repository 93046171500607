<app-wizard
    [steps]='steps'
    [current]='currentStep'
    [bodyContentTpl]='projectForm'
    [nextDisabled]='this.formGroup?.invalid'
    (onClickNext)='save($event)'
    [layout]='true'
>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column'>
        <div class='grid'>
            <div class="field col-12 text-2xl text-primary uppercase">
                {{currentStep?.text}}
            </div>

            <div class="field col-12">
                <p-table [value]="projectBudgetsComponents" dataKey="id" [tableStyle]="{width: '100%'}">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="bg-primary" style="width:5%">Nro.</th>
                            <th class="bg-primary" style="width:35%">DESCRIPCIÓN</th>
                            <th class="bg-primary" style="width:15%">FONABOSQUE</th>
                            <th class="bg-primary" style="width:15%">ENTIDAD EFECTIVO (Bs.)</th>
                            <th class="bg-primary" style="width:15%">ENTIDAD ESPECIE (Bs.)</th>
                            <th class="bg-primary" style="width:15%">PRESUPUESTO TOTAL (Bs.)</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-obj let-rowIndex="rowIndex">
                        <tr>
                            <td [class.bg-primary]='!obj.type'>{{ obj.type ? (rowIndex + 1) : ''}}</td>
                            <td [class.bg-primary]='!obj.type'>{{obj.description}}</td>
                            <td [class.bg-primary]='!obj.type'>{{obj.owner | number: '1.2'}}</td>
                            <td [class.bg-primary]='!obj.type'>{{obj.entity | number: '1.2'}}</td>
                            <td [class.bg-primary]='!obj.type'>{{obj.entityInKind | number: '1.2'}}</td>
                            <td [class.bg-primary]='!obj.type'><b>{{obj.total | number: '1.2'}}</b></td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</ng-template>
