import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { Subject } from 'rxjs';
import { MenuItem } from 'primeng/api';

import { IProject } from '../../models';
import { Constants } from '../../../shared/constants';
import { GenericFunctions } from '../../../shared/services/generic-functions';
import { ToasterService } from '../../../shared/services/toaster.service';
import { ProjectService } from '../../services';
import { PermissionService } from '../../../shared/services/permission.service';
import {ProjectFlow, ProjectStagesEnum} from "../../../shared";

@Component({
  selector: 'app-project-assessment',
  templateUrl: './project-assessment.component.html',
  styleUrls: ['./project-assessment.component.scss'],
})
export class ProjectAssessmentComponent implements OnInit, OnDestroy {
  loading = false;
  data: IProject[] = [];
  resource = Constants.RESOURCES.ITCP_EVAL;
  public breadcrumbs: MenuItem[] = [
    { label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home'] },
    { label: ' ITCP', routerLink: ['/itcp'] },
    { label: ' Valoracion de Proyectos' },
  ];

  public header: Array<any> = [];

  @ViewChild('dtProjects') projectsTable!: Table;
  private onDestroy$ = new Subject();

  constructor(
    private readonly genericFunctions: GenericFunctions,
    private readonly toasterService: ToasterService,
    private readonly service: ProjectService,
    private readonly router: Router,
    private readonly permissionsService: PermissionService,
  ) {}

  ngOnInit(): void {
    this.getAll();
  }

  getAll(): void {
    this.loading = true;

    this.service
      .getAll({ params: { assignments: true, status: ProjectFlow.itcpStatusesForAssessments(), stage: ProjectStagesEnum.ITCP } })
      .then((projects) => {
        this.data = [...projects];
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => (this.loading = false));
  }

  setFilter(event: any, type: string, query: string, column: string = ''): void {
    if (type === 'filterGlobal') {
      this.projectsTable.filterGlobal(event.target.value, query);
    } else if (type === 'filter') {
      this.projectsTable.filter(event.target.value, column, query);
    }
  }

  retrieveProjectAssessment(id: number): void {
    const route = `/itcp/project-assessments/${id}/requirements`;
    this.router.navigate([route]).then();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(undefined);
    this.onDestroy$.complete();
  }
}
