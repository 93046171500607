import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Constants} from "../../../../shared/constants";
import {ConfirmationService, MenuItem} from "primeng/api";
import {Table} from "primeng/table";
import { ProjectService} from "../../../../itcp";
import {ActivatedRoute} from "@angular/router";
import {ProjectChangeOrderService, ProjectStartOrderService} from "../../../services";
import {DialogService} from "primeng/dynamicdialog";
import {ToasterService} from "../../../../shared/services/toaster.service";
import {AttachmentService, CryptoService, IAttachment, SettingsService} from "../../../../shared";
import {IProjectChangeOrder, IProjectStartOrder, ProjectProgrammerModel} from "../../../models";
import {IProjectGoalResult, ProjectGoalResultService} from "../../../../edtp";
import {GenericFunctions} from "../../../../shared/services/generic-functions";
import {StartOrderLabels} from "../../orders/start-order/start-order.component";

@Component({
    selector: 'app-docs',
    templateUrl: './docs.component.html',
    styleUrls: ['./docs.component.scss']
})
export class ProjectDocsComponent extends ProjectProgrammerModel implements OnInit, OnDestroy {

    resource = Constants.RESOURCES.PROJECTS;
    loading: boolean = false;
    documents : {reference: string; file: IAttachment}[] = [];
    results: IProjectGoalResult[] = [];
    startOrder!: IProjectStartOrder;
    changeOrders!: IProjectChangeOrder[];

    breadcrumbs: MenuItem[] = [
        { label: ' Inicio', icon: 'pi pi-home', routerLink: ['/home'] },
        { label: ' Seguimiento', routerLink: ['/tracking'] },
        { label: ' Detalles del Proyecto', routerLink: ['/tracking/projecct-details'] },
        { label: ' Repositorio Documental' },
    ];

    @ViewChild('dt') table!: Table;

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly  changeOrderService: ProjectChangeOrderService,
        public readonly dialogService: DialogService,
        public readonly confirmationService: ConfirmationService,
        private readonly tService: ToasterService,
        private readonly setService: SettingsService,
        public readonly attachmentService: AttachmentService,
        private readonly goalResultService: ProjectGoalResultService,
        private readonly startOrderService: ProjectStartOrderService,
        private crypto: CryptoService,
    ) {
        super(activatedRoute, projectService, tService, setService, crypto);
    }

    ngOnInit(): void {
        this.readParams();
        this.load();
    }

    load(): void {
        this.loading = true;
        this.service.get(this.params['id']).then((project) => {
            const {id, name } = project || {};
            this.currentProject = {...project};
        }).then(() => {
            const projectId = this.currentProject?.id || 0;
            let params: any = { projectId };

            return this.goalResultService.getAll({ params } );
        }).then((resp) => {
           if (resp) this.results = [ ...resp ];
            const projectId = this.currentProject?.id || 0;
           return this.startOrderService.get(projectId);
        }).then((resp) => {
            if (resp) this.startOrder = { ...resp };
            const projectId = this.currentProject?.id || 0;
            return this.changeOrderService.getAll({ params: { projectId } });
        }).then((resp) => {
            if (resp)  this.changeOrders = [...resp];
        }).finally(() => {
            this.loadAttachments();
            this.showContent = true;
            this.loading = false;
        });
    }

    loadAttachments() {
        if (!this.currentProject) return;
        this.documents = [];
        this.loadITCPAttachments();
        this.loadProjectAttachments();
        this.loadActivitiesFSAttachments();
        this.loadOrdersAttachments();
    }

    loadITCPAttachments() {
        if (this.currentProject?.iTCPRequest) {
            const { announcement, seoIdentityFile, seoMemoFile, leadMemoFile } = this.currentProject.iTCPRequest || {};
            if (announcement && announcement.file) {
                this.documents.push({
                    reference: 'Convocatoria',
                    file: announcement.file,
                });
            }

            if (seoIdentityFile) {
                this.documents.push({ reference: 'Carnet de Identidad de la MAE', file: seoIdentityFile });
            }

            if (seoMemoFile) {
                this.documents.push({ reference: 'Documento de Designación de la MAE', file: seoMemoFile });
            }

            if (leadMemoFile) {
                this.documents.push({ reference: 'Memoradum de Designación como Responsable Técnico del Proyecto', file: leadMemoFile });
            }
        }
    }

    loadProjectAttachments() {
        const { swornFile, itcpFile, requestFinancingFile, notificationFile, edtpNotificationFile, acts, dataProperty } = this.currentProject || {};

        if (swornFile) {
            this.documents.push({ reference: 'Declaración Jurada', file: swornFile });
        }

        if (itcpFile) {
            this.documents.push({ reference: 'ITCP Foliado y Rubricado', file: itcpFile });
        }

        if (requestFinancingFile) {
            this.documents.push({ reference: 'Solicitud de Financiamiento', file: requestFinancingFile });
        }

        if (notificationFile) {
            this.documents.push({ reference: 'Adjunto de la Notificación en etapa ITCP', file: notificationFile });
        }

        if (edtpNotificationFile) {
            this.documents.push({ reference: 'Adjunto de la Notificación en etapa EDTP', file: edtpNotificationFile });
        }

        if (acts) {
            acts.map(a => {
                if (a.actFile) {
                    this.documents.push({
                        reference: `Acta de Compromiso ${a.community}`,
                        file: a.actFile,
                    });
                }
            });
        }

        if (dataProperty) {
            const { realFolioFile, cadastralFile } = dataProperty;
            if (realFolioFile) {
                this.documents.push({ reference: 'Folio Real', file: realFolioFile });
            }
            if (cadastralFile) {
                this.documents.push({ reference: 'Plano Catastral', file: cadastralFile });
            }
        }

    }
    loadActivitiesFSAttachments() {
        if (!this.results) return;

        for (const result of this.results) {
            for(const activity of result.activities) {
                activity.monthDetails?.map(md => {
                    const month = md.startsDate ? Constants.MONTHS[GenericFunctions.parseDate(md.startsDate).getMonth()] : Constants.MONTHS_UNDEFINED[md.month];
                    md.verifiables?.map(v => {
                        this.documents.push({
                            reference: `${activity.activity} - ${month} (verificables)`,
                            file: v.file,
                        })
                    });

                });

                activity.budgets?.map(budget => {
                    budget.monthProgrammed?.map(mp => {
                        const month = mp.startsDate ? Constants.MONTHS[GenericFunctions.parseDate(mp.startsDate).getMonth()] : Constants.MONTHS_UNDEFINED[mp.month];
                        mp.verifiables?.map(v => {
                            this.documents.push({
                                reference: `${activity.activity} - Partida ${budget.budgetItemCode} - ${month} (verificables)`,
                                file: v.file,
                            })
                        });
                    });
                })
            }
        }
    }
    loadOrdersAttachments() {
        this.startOrder.verifiables?.map(v => {
           this.documents.push({
               reference: `Orden de Inicio (${StartOrderLabels[v.orderType]})`,
               file: v.file
           });
        });

        this.changeOrders?.map(cho => {
            if (cho.file) {
                this.documents.push({
                    reference: `Orden de Cambio: ${cho.justification}`,
                    file: cho.file
                });
            }
        });
    }
    setFilter(event: any, type: string, query: string, column: string = ''): void {
        if (type === 'filterGlobal') {
            this.table.filterGlobal(event.target.value, query);
        } else if (type === 'filter') {
            this.table.filter(event.target.value, column, query);
        }
    }

    ngOnDestroy() {
        this.cleanParams();
    }

}
