<div class="grid">
  <p-breadcrumb [model]="breadcrumbs"></p-breadcrumb>
  <div class="col-12">
    <div class="card">
      <p-toolbar styleClass="mb-4 w-full clean-toolbar">
        <div class="flex justify-content-between flex-column align-items-center sm:flex-row w-full">
          <div class="p-input-icon-left mb-2 searcher">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              class="w-full"
              (input)="setFilter($event, 'filterGlobal', 'contains')"
              placeholder="Buscar..."
            />
          </div>
          <div class="my-2">
            <button
              appPermission
              [resource]="resource"
              [enableActions]="'canView'"
              pButton
              pRipple
              label="Actualizar"
              icon="pi pi-refresh"
              class="p-button-info mr-2"
              (click)="reload()"
            ></button>
            <button
              appPermission
              [resource]="resource"
              [enableActions]="'canCreate'"
              pButton
              pRipple
              label="Agregar"
              icon="pi pi-plus"
              class="p-button mr-2"
              (click)="addNew()"
            ></button>
          </div>
        </div>
      </p-toolbar>
      <p-table
        #dtAnnouncement
        [value]="data"
        styleClass="p-datatable-gridlines"
        [loading]="loading"
        [resizableColumns]="true"
        [paginator]="true"
        [rows]="6"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} al {last} de {totalRecords} items"
        [rowsPerPageOptions]="[6, 12, 24]"
        responsiveLayout="scroll"
        [filterDelay]="0"
        [globalFilterFields]="['title', 'description']"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="bg-primary" *ngFor="let item of header" [pSortableColumn]="item.column">
              {{ item.label }}
              <p-sortIcon *ngIf="item.sort === true" [field]="item.column"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
          <tr>
            <td>{{ data.title }}</td>
            <td class="description-cell">
              <div class="description-cell">{{ data.description }}</div>
            </td>
            <td>{{ data.published ? 'Si' : 'No' }}</td>
            <td class="text-center">
              <p-button
                label="{{ isExpired(data.dueDate) ? 'Expirado' : 'Vigente' }}: {{ data.dueDate | date: 'yyyy-MM-dd' }}"
                icon="{{ isExpired(data.dueDate) ? 'pi pi-exclamation-circle' : 'pi pi-check' }}"
                styleClass="{{ isExpired(data.dueDate) ? 'p-button-danger' : 'p-button-success' }}"
                *ngIf="data.dueDate"
              ></p-button>
            </td>
            <td class="text-center">
             {{data.yearsLimit}}
            </td>
            <td>
              <button
                appPermission
                [resource]="resource"
                [enableActions]="'canUpdate'"
                pButton
                pTooltip="Editar"
                tooltipPosition="top"
                type="button"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-button-text"
                (click)="selectedItem(data, 'edit')"
              ></button>
              <button
                appPermission
                [resource]="resource"
                [enableActions]="'canDelete'"
                pButton
                pTooltip="Eliminar"
                tooltipPosition="top"
                type="button"
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger p-button-text"
                (click)="selectedItem(data, 'delete')"
              ></button>

                <button
                    *ngIf="data.published && data.publishingCode"
                    appPermission
                    [resource]="resource"
                    [enableActions]="'canView'"
                    pButton
                    pTooltip="Ver enlace de la convocatoria"
                    tooltipPosition="top"
                    type="button"
                    icon="pi pi-external-link"
                    class="p-button-rounded p-button-success p-button-text"
                    (click)=" data.showPublicLink = true"
                ></button>
                <p-dialog header="Enlace de la Convocatoria" [(visible)]="data.showPublicLink" [style]="{'min-width': '50vw'}">
                    <div class='p-4 flex justify-content-center align-items-center gap-2'>
                        <code>
                            {{getLink(data.publishingCode)}}
                        </code>
                        <button class='p-button p-button-info' (click)='copyLink(getLink(data.publishingCode))'>
                            Copiar
                        </button>
                    </div>
                </p-dialog>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
