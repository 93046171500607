import { Injectable } from '@angular/core';
import {BaseHttpService, HttpOptions} from 'src/app/shared/services';
import {ISettings, ITypology} from "../interfaces";
import {lastValueFrom} from "rxjs";
import {IRisk} from "../interfaces/risk.interface";
import {IComponent} from "../interfaces/component.interface";

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
    private namespace:string;
    constructor(private readonly _baseHttpService: BaseHttpService) {
        this.namespace = 'settings';
    }

    async getAll(options?: HttpOptions): Promise<ISettings[]> {
        return lastValueFrom(this._baseHttpService.get(this.namespace, options));
    }

    async get(id: number): Promise<ISettings> {
        return lastValueFrom(this._baseHttpService.get(`${this.namespace}/${id}`));
    }

    async getByKey(key: number, type: string): Promise<ISettings> {
        return lastValueFrom(this._baseHttpService.get(`${this.namespace}/${key}/${type}`));
    }

    async getAllTypologies(options?: HttpOptions): Promise<ITypology[]> {
        return lastValueFrom(this._baseHttpService.get(`${this.namespace}/typologies`, options));
    }

    async getAllRisks(options?: HttpOptions): Promise<IRisk[]> {
        return lastValueFrom(this._baseHttpService.get(`${this.namespace}/risks`, options));
    }

    async getAllComponents(options?: HttpOptions): Promise<IComponent[]> {
        return lastValueFrom(this._baseHttpService.get(`${this.namespace}/components`, options));
    }
}
