<app-wizard
    [steps]='steps'
    [current]='currentStep'
    [bodyContentTpl]='projectForm'
    [nextDisabled]='this.formGroup?.invalid'
    (onClickNext)='save($event)'
    [layout]='true'
>
</app-wizard>

<ng-template #projectForm>
    <div *ngIf='showContent' class='project-form flex justify-content-center flex-column' [formGroup]='formGroup'>
        <div class='grid'>
            <div class="field col-12 text-2xl text-primary uppercase">
                {{currentStep?.text}}
            </div>

            <div class="field col-12">
                <button
                    class='p-button p-button-success'
                    pButton pTooltip="Agregar Grupo" tooltipPosition="top"
                    [disabled]="!isSubmitted"
                    (click)='onNew()'
                >
                    <i class="pi pi-plus"></i>
                </button>
                <p-table [value]="groups" dataKey="id" editMode="row" [tableStyle]="{'width': '200%'}" #dt>
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="bg-primary border-x-1 text-center" rowspan='2' style="width:60px">NRO.</th>
                            <th class="bg-primary border-x-1 text-center" rowspan='2' style="width: 250px">ESTRUCTURA DE FINANCIAMIENTO DEL EDTP</th>
                            <th class="bg-primary border-x-1 text-center" rowspan='2'>PONDERACIÓN</th>
                            <th class="bg-primary border-x-1 text-center" rowspan='2'>PUNTAJE MÁXIMO</th>
                            <th class="bg-primary border-x-1 text-center" >No Presenta</th>
                            <th class="bg-primary border-x-1 text-center" >No Satisfactorio</th>
                            <th class="bg-primary border-x-1 text-center" >Regular</th>
                            <th class="bg-primary border-x-1 text-center" >Satisfactorio</th>
                            <th class="bg-primary border-x-1 text-center" >Muy Satisfactorio</th>
                            <th class="bg-primary border-x-1 text-center" colspan='2'>RESULTADO PUNTAJE</th>
                            <th class="bg-primary border-x-1 text-center" colspan='2'>REFERENCIALES PARA MÁXIMO PUNTAJE</th>
                            <th class="bg-primary border-x-1 text-center" style="width: 200px">OBSERVACIONES</th>
                            <th class="bg-primary" rowspan='2'  style="width:80px"></th>
                        </tr>
                        <tr>
                            <th class="bg-primary border-x-1 text-center">0</th>
                            <th class="bg-primary border-x-1 text-center">1</th>
                            <th class="bg-primary border-x-1 text-center">2</th>
                            <th class="bg-primary border-x-1 text-center">3</th>
                            <th class="bg-primary border-x-1 text-center">4</th>
                            <th class="bg-primary border-x-1 text-center"  scope="colgroup">ACUM</th>
                            <th class="bg-primary border-x-1 text-center"  scope="colgroup">DISTRIB</th>
                            <th class="bg-primary border-x-1 text-center"  scope="colgroup">ACUM</th>
                            <th class="bg-primary border-x-1 text-center"  scope="colgroup">DISTRIB</th>
                            <th class="bg-primary border-x-1 text-center">Categorización de la Calificación</th>
                        </tr>
                        <tr>
                            <th class="bg-blue-100 border-0 text-center"></th>
                            <th class="bg-blue-100 border-0 text-center"></th>
                            <th class="bg-blue-100 border-0 text-center"></th>
                            <th class="bg-blue-100 border-0 text-center"></th>
                            <th class="bg-blue-100 border-0 text-center"></th>
                            <th class="bg-blue-100 border-0 text-center"></th>
                            <th class="bg-blue-100 border-0 text-center"></th>
                            <th class="bg-blue-100 border-0 text-center"></th>
                            <th class="bg-blue-100 border-0 text-center"></th>
                            <th class="bg-blue-100 border-0 text-center p-0"  scope="colgroup">{{accumTotals.scoreAccum }}</th>
                            <th class="bg-blue-100 border-0 text-center p-0"  scope="colgroup">{{accumTotals.scoreDist | number: '1.0-2'}}</th>
                            <th class="bg-blue-100 border-0 text-center p-0"  scope="colgroup">{{accumTotals.maxScoreAccum | number: '1.0-2'}}</th>
                            <th class="bg-blue-100 border-0 text-center p-0"  scope="colgroup">{{accumTotals.maxScoreDist | number: '1.0-2'}}</th>
                            <th class="bg-blue-100 border-0 text-center"></th>
                            <th class="bg-blue-100 border-0 text-center"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-obj let-editing="editing" let-index="rowIndex">
                        <tr [pEditableRow]="obj" class="border-0">
                            <td colspan='15' class='p-0 m-0 border-0'>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <app-score
                                            [group]='obj'
                                            [defaultCompliances]="defaultCompliances"
                                            [editMode]='true'
                                            [canEdit]="isSubmitted"
                                            (onSave)='onRowEditSave($event)'
                                            (onEdit)='onRowEditInit($event)'
                                            (onCancel)='onRowEditCancel($event, index)'
                                        >
                                        </app-score>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <app-score
                                            [group]='obj'
                                            [defaultCompliances]="defaultCompliances"
                                            [editMode]='false'
                                            [canEdit]="isSubmitted"
                                            [totalGroups]="accumTotals"
                                            (onChangeAccumTotals)="setAccumTotals($event)"
                                            (onChangeDistTotals)="setDistTotals($event)"
                                            (onRemove)="onRemove($event)"
                                        >
                                        </app-score>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</ng-template>
