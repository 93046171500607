import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import 'jspdf-autotable';

import { fonabosqueLogo, mdmaaLogo } from './constants';
import { Constants } from '../../constants';
import { getDateTimeFormatted } from '../../../reports/utils/dataUtils';
import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align';

export enum PDFOrientation {
    PORTRAIT = 'portrait',
    LANDSCAPE = 'landscape'
}

export class PdfBuilder {
    private doc: jsPDF;
    private orientation: PDFOrientation;
    private readonly title: string;
    private readonly boletin: string;
    private _currentHeight: number;
    private leftMargin = 10;
    private height: number;
    private width: number;

    constructor(title: string, boletin = 'CONVOCATORIA', orientation: PDFOrientation = PDFOrientation.PORTRAIT) {
        this.title = title.toUpperCase();
        this.boletin = 'REPORTE - ' + boletin;
        this.doc = new jsPDF(orientation, 'mm', 'a4');
        this._currentHeight = 0;
        this.orientation = orientation;
        this.width = orientation == PDFOrientation.PORTRAIT ? Constants.PDF.MAX_WIDTH : Constants.PDF.MAX_HEIGHT;
        this.height = orientation == PDFOrientation.PORTRAIT ? Constants.PDF.MAX_HEIGHT : Constants.PDF.MAX_WIDTH;
        this.init();
    }

    init() {
        // this.doc.addFileToVFS('Montserrat.ttf', montserratFont);
        // this.doc.addFont('Montserrat-normal.ttf', 'Montserrat', 'normal');
        // this.doc.setFont('Montserrat');
        this.addHeader();
    }

    get currentHeight(): number {
        return this._currentHeight;
    }

    set currentHeight(value: number) {
        this._currentHeight = value;
    }

    async addImage(htmlNativeElement: any, text?: string): Promise<void> {
        const { offsetWidth: elementWidth, offsetHeight: elementHeight } = htmlNativeElement;
        const canvas = await this.getCanvas(htmlNativeElement);
        const data = canvas.toDataURL('image/png');
        const correctedImageWidth = this.isLandscape() ? this.width - 40 : this.width;
        const scale = Math.min(correctedImageWidth / elementWidth, this.height / elementHeight);
        const elementScaledHeight = elementHeight * scale;
        let textLines = text || '';
        let textDimensions = { w: 0, h: 0 };
        const fontSize = 14;
        if (text) {
            textLines = this.doc.splitTextToSize(text, this.width - 15, { fontSize });
            textDimensions = this.doc.getTextDimensions(textLines, { maxWidth: this.width - 15, fontSize });
        }
        if (this.currentHeight + elementScaledHeight + textDimensions.h > this.height - 10) {
            this.doc.addPage();
            this.currentHeight = 10;
        }
        if (text) {
            this.doc.setFontSize(fontSize);
            this.doc.text(textLines, 10, this.currentHeight + 10);
            this.currentHeight += textDimensions.h + 5;
        }
        this.doc.addImage(data, 'PNG', this.leftMargin, this.currentHeight + 5, (elementWidth * scale) - 20, elementHeight * scale);
        this.currentHeight += elementScaledHeight + 5;
    }

    addTable(htmlNativeElement: any, text?: string, extraOptions = {}): void {
        let textLines = text || '';
        const fontSize = 14;
        let textDimensions = { w: 0, h: 0 };
        if (text) {
            textLines = this.doc.splitTextToSize(text, this.width - 15, { fontSize: fontSize + 2 });
            textDimensions = this.doc.getTextDimensions(textLines, {
                fontSize: fontSize + 2,
                maxWidth: this.width - 15
            });
        }
        if (this.currentHeight + 5 + textDimensions.h > this.height - 40) {
            this.doc.addPage();
            this.currentHeight = 10;
        }
        if (text) {
            this.doc.setFontSize(fontSize);
            this.doc.setFont('helvetica', 'bold');
            this.currentHeight += 5;
            this.doc.text(textLines, 10, this.currentHeight);
            this.doc.setFont('helvetica', 'normal');
            this.currentHeight += textDimensions.h;
        }
        // @ts-ignore
        this.doc.autoTable({
            html: `#${htmlNativeElement.id}`,
            startY: this.currentHeight,
            headStyles: {
                fillColor: [11, 117, 114],
                fontSize: 7,
                fontColor: 'white'
            },
            styles: {
                fontSize: 6,
                lineColor: [222, 226, 230],
                lineWidth: 0.1
            },
            ...extraOptions
        });

        // @ts-ignore
        this.currentHeight = this.doc.lastAutoTable.finalY + 2;
    }

    addText(text: string, fontSize = 10) {
        const textLines = this.doc.splitTextToSize(text, this.width - 15, { fontSize });
        const textDimensions = this.doc.getTextDimensions(textLines, { fontSize, maxWidth: this.width - 15 });
        if (this.currentHeight + textDimensions.h > this.height - 15) {
            this.doc.addPage();
            this.currentHeight = 10;
        }
        this.doc.setFontSize(fontSize);
        this.doc.setFont('helvetica', 'italic');
        this.doc.text(textLines, this.leftMargin, this.currentHeight + 5);
        this.currentHeight += textDimensions.h + 5;
        this.doc.setFont('helvetica', 'normal');
    }

    generate(fileName: string): void {
        this.doc.save(`${fileName}_${getDateTimeFormatted()}.pdf`);
    }

    private addHeader(): void {
        const mainLogo = fonabosqueLogo;
        const secondLogo = mdmaaLogo;
        this.doc.addImage(mainLogo, 'PNG', 10, 10, 37, 15);
        const secondLogoX = this.width - 35;
        this.doc.addImage(secondLogo, 'PNG', secondLogoX, 3, 27, 28);
        this.doc.setLineWidth(1);
        this.doc.setDrawColor('#30933b');
        this.doc.line(10, 33, this.width - 10, 33);
        this.currentHeight = 36;
        this.addTitle();
    }

    private addTitle() {
        let fontSize = 8;
        this.doc.setFont('courier', 'bold');
        const mdmaaTitle = 'MINISTERIO DE MEDIO AMBIENTE Y AGUA';
        const fondoNacionalText = 'FONDO NACIONAL DE DESAROLLO FORESTAL';
        const mdmaaWidth = this.doc.getStringUnitWidth(mdmaaTitle) * fontSize / this.doc.internal.scaleFactor;
        const fondoNacionalWidth = this.doc.getStringUnitWidth(fondoNacionalText) * fontSize / this.doc.internal.scaleFactor;
        this.doc.setFontSize(fontSize);
        const pageWidth = this.doc.internal.pageSize.getWidth();

        this.doc.text(mdmaaTitle, (pageWidth - mdmaaWidth) / 2, this.currentHeight + 2);
        this.doc.text(fondoNacionalText, (pageWidth - fondoNacionalWidth) / 2, this.currentHeight + 5);

        this.doc.setFontSize(12);
        fontSize = 12;

        const boletinTitleWidth = this.doc.getStringUnitWidth(this.boletin) * fontSize / this.doc.internal.scaleFactor;
        const titleWidth = this.doc.getStringUnitWidth(this.title) * fontSize / this.doc.internal.scaleFactor;
        this.doc.text(this.boletin, (pageWidth - boletinTitleWidth) / 2, this.currentHeight + 10);
        this.doc.text(this.title, (pageWidth - titleWidth) / 2, this.currentHeight + 15);

        this.doc.setFont('helvetica', 'normal');
        this.currentHeight += 22;
    }

    private isLandscape() {
        return this.orientation == PDFOrientation.LANDSCAPE;
    }

    private async getCanvas(element: HTMLElement): Promise<HTMLCanvasElement> {
        return html2canvas(element);
    }
}