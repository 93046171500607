<div class='w-full bg-gray-200'>
    <div *ngIf="!editMode" class='flex flex-column gap-2 justify-content-start'>
        <div class="w-full  px-4 py-1">
            <span class="font-bold">{{group.goal?.description}}</span>
            <div class="text-sm">{{group.goal?.component?.name}}</div>
            <div class="text-xs">{{group.goal?.component?.description}}</div>
        </div>
        <div class="flex gap-4 justify-content-start align-items-center bg-gray-100">
            <div class="px-6 py-1">
                <b>Resultado Esperado:</b>
                <div>{{group.expectedResult}}</div>
            </div>
            <div class="flex justify-content-start align-items-center gap-1">
                <button
                    class='p-button p-button-success p-button-rounded p-button-text'
                    [disabled]="!canProgram"
                    pButton pTooltip="Agregar Actividad para este Resultado de Objetivo {{canProgram}}" tooltipPosition="top"
                    (click)='onNew()'
                >
                    <i class="pi pi-plus"></i>
                </button>

                <button pButton pRipple type="button" pTooltip="Eliminar" tooltipPosition="top" icon="pi pi-trash" (click)="removeGroup()" [disabled]="!canProgram" class="p-button-rounded p-button-text p-button-danger"></button>
            </div>
        </div>
    </div>

    <div *ngIf='editMode' class='flex gap-4 justify-content-start align-items-center px-4 py-2'>
        <div class='w-20rem'>
            <p-dropdown
                [options]="goals"
                placeholder="Seleccione"
                [style]="{'width':'100%'}"
                scrollHeight="200px"
                [filter]="true"
                filterBy="value"
                optionLabel="description"
                optionValue="id"
                (onChange)='onSelectItem($event)'
            >
                <ng-template let-item pTemplate="selectedItem">
                    <div class="text-sm">
                        <span class="font-bold">{{item.description}}.</span>
                        <div>{{item?.component?.name}}</div>
                        <div class="text-xs">{{item?.component?.description}}</div>
                    </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                    <div class="text-sm">
                        <span class="font-bold">{{item.description}}.</span>
                        <div>{{item?.component?.name}}</div>
                        <div class="text-xs">{{item?.component?.description}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="flex-1">
            <textarea pInputText
                   [pAutoFocus]="true"
                   placeholder='Escriba el resultado esperado del objetivo aquí....'
                   required
                   (keydown.enter)="saveGroup()"
                   class='p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full'
                      type="text" [(ngModel)]="group.expectedResult" [ngModelOptions]="{standalone: true}">

            </textarea>
        </div>

        <div>
            <button
                pButton
                pRipple
                type="button"
                pSaveEditableRow
                icon="pi pi-check"
                (click)="saveGroup()"
                class="p-button-rounded p-button-text p-button-success mr-2">
            </button>
            <button pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="cancelEditGroup()" class="p-button-rounded p-button-text p-button-danger"></button>
        </div>
    </div>
</div>

<p-table
    [value]="group.activities"
    dataKey="id"
    editMode="row"
    [tableStyle]="{'width': '100%'}"
    styleClass="p-datatable-gridlines"
    #dt>
    <ng-template pTemplate="header">
        <tr>
            <th class="bg-secondary border-x-1 text-center p-0" style="width:60px"></th>
            <th class="bg-secondary border-x-1 text-center p-0"></th>
            <th class="bg-secondary border-x-1 text-center p-0" style="width:100px"></th>
            <th class="bg-secondary p-0" style="width:100px"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-obj let-editing="editing" let-index="rowIndex">
        <tr [pEditableRow]="obj">
            <td style='width:50px'>{{index + 1}}</td>
            <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <textarea
                            [autofocus]="true"
                            [rows]='2'
                            pInputTextarea
                            [autoResize]='false'
                            placeholder='Descripción de la actividad'
                            [(ngModel)]="obj.activity"
                            [ngModelOptions]="{standalone: true}"
                            class='text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full'
                        ></textarea>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.activity}}
                    </ng-template>
                </p-cellEditor>
            </td>

            <td class="text-center">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-dropdown
                            [options]="programmerTypes"
                            [(ngModel)]="obj.programmerType"
                            [ngModelOptions]="{ standalone: true }"
                            [style]="{ width: '100%' }"
                        >
                        </p-dropdown>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{obj.programmerType}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td>
                <div *ngIf="canProgram" class="flex align-items-center justify-content-center gap-2">
                    <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(obj)" class="p-button-rounded p-button-text"></button>
                    <button *ngIf="!editing"  pButton pRipple type="button" pTooltip="Eliminar" tooltipPosition="top" icon="pi pi-trash" (click)="onRowRemove(obj)" class="p-button-rounded p-button-text p-button-danger"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(obj)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(obj, index)" class="p-button-rounded p-button-text p-button-danger"></button>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
