export enum ProjectC3t4Activity {}
export const ProjectC3t4Activities = [
  {
    value: 'BUILT',
    label: 'Infraestructura Construida',
  },
  {
    value: 'IMPROVED',
    label: 'Infraestructura Mejorada',
  },
  {
    value: 'REFURBISHED',
    label: 'Infraestructura Reparada',
  },
];
